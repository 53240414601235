import { reactive, toRaw, unref } from "vue";
// import CountryPortraitsApi from "@/api/CountryPortraitsApi";
import { compareTableValues } from "../../../helpers/SortHelpers";

const SORT_ASC = "asc";
const SORT_DESC = "desc";

class CountryPortraitsStatCategoryTableController {
  get isLoading() {
    return false;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get hiddenContent() {
    return true;
  }

  get initiallyHiddenContent() {
    return true;
  }

  get filters() {
    if (this._state) {
      return this._state.filters;
    }
    return null;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    if (this._state) {
      return this._state.selection;
    }
    return null;
  }

  get notations() {
    return this._state?.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    if (this._state) {
      return this._state.notationListModel;
    }
    return null;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  constructor(translator, categoryIndex) {
    this._translator = translator;
    this._categoryIndex = categoryIndex;
  }

  init({ countries, indicatorStatCategoriesForTable, countriesForSelect }) {
    if (typeof countries !== "undefined" && countries.length === 6) {
      this.indicatorScoreColumn = indicatorStatCategoriesForTable;
      this.indicatorStatColumn = indicatorStatCategoriesForTable;
      this._countries = countries;
      this._countryList = toRaw(countriesForSelect);
      this._columns = this._getColumnDefinition();
      this._state = reactive({
        isLoading: true,
        filters: {},
        sorting: null,
        hiddenContent: true,
        selection: {
          selectionMode: "none",
          canSelect: false,
        },
        notations: this.statCategorieValuesForTable(),
        notationListModel: {
          columns: this._columns,
          visibleColumns: this._columns,
          createRow: (notation) => {
            return this._columns.map(({ key, type, ...column }, index) => ({
              key,
              type,
              filterOptions: column.filterOptions,
              displayName: column.displayName,
              visible: true,
              value: column.valueBuilder ? column.valueBuilder(notation[index]) : notation[key],
              classes: {},
              additionalData: {},
            }));
          },
        },
      });
      this.isLoading = false;
    }
  }

  updateCountries({ countries }) {
    this._countries = countries;
    this.columns = this._getColumnDefinition(this._countries[0][1].stat_categories);
    this.notations = this.statCategorieValuesForTable();
  }

  getNotations() {
    return this.indicatorScoreColumn[0].indicators.codes;
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  _getCountryName(countryId) {
    const countryInfo = this._countryList.filter((country) => country.value === countryId);
    const countryLabel = countryInfo[0].label.split("|")[1]?.substring(1) || countryInfo[0].label;
    return countryLabel;
  }

  _getCountryColumnDefinitions() {
    const countryDefinitions = this._countries;
    const newColumn = [];

    if (countryDefinitions.length) {
      Array.from(countryDefinitions).map((country) => {
        const countryName = this._getCountryName(country[0]);

        newColumn.push({
          key: countryName,
          visible: true,
          displayName: countryName,
          type: "valueWithContext",
          valueBuilder: (notation) => {
            return notation;
          },
        });
      });
    }

    return newColumn;
  }

  _getColumnDefinition() {
    const countryColumnDefinitions = this._getCountryColumnDefinitions();
    return [
      {
        key: "numbers",
        visible: true,
        displayName: "",
        type: "textWithTooltip",
        valueBuilder: (notation, key) => {
          return notation;
        },
      },
      {
        key: this.indicatorScoreColumn[this._categoryIndex].name,
        visible: true,
        displayName: this.indicatorScoreColumn[this._categoryIndex].name,
        type: "textWithTooltip",
        valueBuilder: (notation) => {
          return notation;
        },
        tooltip: this.indicatorScoreColumn[this._categoryIndex].info,
      },
      ...countryColumnDefinitions,
    ];
  }

  // Transforms the data structure from countries[index].stat_categories[this._category_index] to stat_categories[this._category_index].countries[index]
  statCategorieValuesForTable() {
    const rows = [];
    this._countries[0][1].stat_categories[this._categoryIndex]?.forEach((_, index) => {
      const row = [
        {
          text: this.indicatorScoreColumn[this._categoryIndex].indicators.codes[index],
          tooltip: this.indicatorScoreColumn[this._categoryIndex].indicators.infos[index],
        },
        {
          text: this.indicatorScoreColumn[this._categoryIndex].indicators.names[index],
          tooltip: this.indicatorScoreColumn[this._categoryIndex].indicators.infos[index],
        },
      ];
      this._countries.forEach((country) => {
        row.push(country[1].stat_categories[this._categoryIndex][index]);
      });
      rows.push(row);
    });

    return rows;
  }

  sortColumn() {
    const { direction, column: columnKey } = this.sorting;
    const notationValueAccessor = (notation) => {
      let value;
      switch (columnKey) {
        case "trade_flow_percentage":
        case "rank":
          value = +notation[columnKey];
          break;
        case "name":
          value = notation[columnKey];
          break;
        default:
          value = +notation[columnKey]?.value;
          break;
      }

      return value || 0;
    };

    this.notations = compareTableValues(this.notations, notationValueAccessor, direction);
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;

    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: SORT_ASC };
      return;
    }
    if (this._state?.sorting?.direction === SORT_ASC) {
      this._state.sorting.direction = SORT_DESC;
    } else {
      this._state.sorting = null;
    }
  }

  async _getExcelFile(params) {
    // await this._api.getExcelFile(params);
  }
}

export default CountryPortraitsStatCategoryTableController;
