<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <div class="data-table--unit-cell">
      <span class="data-table--unit-name">
        {{ value.unit }}
      </span>
      <WithTooltip
        v-if="value.unit_converted_from"
        :tooltip="tooltip"
      >
        <span class="data-table--unit-tag">
          <Icon
            class="icon"
            name="exchange"
            :fixed-width="true"
            variant="regular"
            :aria-label="tooltip"
          />
        </span>
      </WithTooltip>
    </div>
  </td>
</template>

<script>
export default {
  name: 'UnitColumn',
  props: {
    value: {
      type: Object,
      required: true,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { hover: false };
  },
  computed: {
    tooltip() {
      const specificTranslationKey = `components.notation_list.${this.value.i18nPrefix}.columns.unit.tooltip`;
      const fallbackTranslationKey = 'components.notation_list.columns.unit.tooltip';
      const key = this.$te(specificTranslationKey)
        ? specificTranslationKey
        : fallbackTranslationKey;
      return this.$t(key, {
        unit: this.value.unit,
        unit_converted_from: this.value.unit_converted_from,
        preferred_currency: this.value.preferred_currency?.symbol,
      });
    },
  },
};
</script>
