const throttleFunction = (fn, minDelayBetweenCallsInMs) => {
  let lastCall = null;
  let deferredCallTimeout = null;

  const nextCallAllowed = () => lastCall + minDelayBetweenCallsInMs;
  const callFn = (...allArguments) => {
    fn(...allArguments);
    lastCall = Date.now();
  };

  return (...args) => {
    clearTimeout(deferredCallTimeout);
    deferredCallTimeout = null;

    if (lastCall === null || Date.now() >= nextCallAllowed()) {
      callFn(...args);
    } else {
      deferredCallTimeout = setTimeout(() => callFn(...args), nextCallAllowed() - Date.now());
    }
  };
};

export { throttleFunction };
