function groupElementsBy({ elements, groupingFn }) {
  const resultMap = {};
  // eslint-disable-next-line no-return-assign
  const addElementToGroup = ({ element, group }) => (resultMap[group] ??= []).push(element);
  const addElementToCorrectGroup = (element) => addElementToGroup(
    { element, group: groupingFn(element) },
  );

  elements.forEach((element) => addElementToCorrectGroup(element));
  return resultMap;
}

export { groupElementsBy };
