export default {

  install() {
    document.addEventListener("DOMContentLoaded", () => {
      this.installWithin(document);
    });

    document.addEventListener("turbolinks:load", (event) => {
      this.installWithin(document);
    });

    document.addEventListener("X-Content-Injected", (event) => {
      this.installWithin(event.detail);
    });
  },

  installWithin(parent) {
    // skip for non dom nodes, e.g. "<!-- v-if -->"
    if (!parent.querySelectorAll) {
      return;
    }

    (parent.querySelectorAll("div.nested-fields") || []).forEach(($container) => {
      this.installRemoveLinks($container);
    });

    (parent.querySelectorAll("a.link-to-add") || []).forEach(($link) => {
      this.installAddLinks($link);
    });

    (parent.querySelectorAll(".sort-controls a[data-direction]") || []).forEach(($link) => {
      this.installSortLinks($link);
    });
  },

  async installAddLinks(parent) {
    if (parent.nested_form) {
      return;
    }
    parent.nested_form = true;

    parent.addEventListener("click", async (event) => {
      this.formAction(event, "build_child", { object_name: parent.dataset.objectName });
    });
  },

  installRemoveLinks(parent) {
    if (parent.nested_form) {
      return;
    }
    parent.nested_form = true;

    (parent.querySelectorAll("a.link-to-remove") || []).forEach(($linkToRemove) => {
      $linkToRemove.addEventListener("click", async (event) => {
        this.formAction(event, "remove_child", { object_name: $linkToRemove.dataset.objectName });
      });
    });
  },

  installSortLinks(link) {
    if (link.nested_form) {
      return;
    }
    link.nested_form = true;

    link.addEventListener("click", (event) => {
      this.formAction(event, "reorder", { object_name: link.dataset.objectName, direction: link.dataset.direction });
    });
  },

  async formAction(event, action, additionalData) {
    event.preventDefault();
    const form = event.target.closest("form");

    const formData = new FormData(form);

    if (additionalData) {
      for (const [key, value] of Object.entries(additionalData)) {
        formData.append(key, value);
      }
    }

    const result = await fetch(`${form.action}/${action}`, {
      method: "POST", headers: {
        "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")
      }, body: formData
    });

    const formWrapper = document.querySelector("#form_wrapper");

    formWrapper.innerHTML = await result.text();

    let customEvent = new CustomEvent("X-Content-Injected", { detail: formWrapper });
    document.dispatchEvent(customEvent);
  },
};
