import i18n from '../config/i18n';
import { NullOrUndefined } from './index';

export const formatThousands = (value, decimals = 0) => `${i18n.n(value / 1e3, 'decimal', {
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
})}k`;

export const formatMillions = (value, decimals = 0) => `${i18n.n(value / 1e6, 'decimal', {
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
})}M`;

export const formatPercent = (value, decimals = 0) => `${i18n.n(value * 100, 'decimal', {
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
})}%`;

export const formatTickNumbers = (value) => {
  if (Math.abs(value) >= 1e6) return formatMillions(value);
  if (Math.abs(value) >= 1e3) return formatThousands(value);
  return i18n.n(value);
};

export const formatFixedPrecision = (value, fractionDigits = 2) => i18n.n(value, 'decimal', {
  minimumFractionDigits: fractionDigits,
  maximumFractionDigits: fractionDigits,
});

export const gradualPrecisionFor = (value) => (Math.abs(value) < 0.01 ? 4 : 2);

export const formatGradualPrecision = (value, { referenceValue } = {}) => {
  const reference = NullOrUndefined(referenceValue) ? value : referenceValue;
  return formatFixedPrecision(value, gradualPrecisionFor(reference));
};
