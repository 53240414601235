import { createI18n } from 'vue-i18n';

// TODO: refactor this to use dynamic imports, instead of listing each import separately
import en from '../../../config/locales/en.yml';
import de from '../../../config/locales/de.yml';

const locales = [en, de];
const messages = locales.reduce((aggr, each) => Object.assign(aggr, each), {});
// obtain the current locale from the html.lang attribute
const currentLocale = document.documentElement.lang;

const numberFormats = {
  en: {
    decimal: {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    integer: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  de: {
    decimal: {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    integer: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};

export const i18nPlugin = createI18n({
  legacy: false,
  locale: currentLocale || 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
  numberFormats,
});

// decorate the $n formatter, to avoid errors when
// value is null or NaN
const numberFormat = i18nPlugin.global.n;
i18nPlugin.global.n = (...args) => {
  if (args[0] === null || Number.isNaN(args[0])) {
    return 'NaN';
  }
  return numberFormat(...args);
};

export default i18nPlugin.global;
