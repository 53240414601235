<template>
  <div
    v-exclude-from-print
    class="page-header"
    data-test="dashboard-header"
  >
    <div class="page-header--column">
      <h1 class="page-header--title">
        <ToggleIcon
          v-if="dashboard.can_toggle_favorite"
          class="dashboard-page--favorite-toggle"
          :model-value="dashboard.is_favorite"
          name="star"
          :aria-label="toggleFavoriteLabel"
          @update:modelValue="onToggleFavorite"
        />
        <a href="?">
          {{ dashboard.full_name }}
        </a>
      </h1>
      <ul class="page-header--badge-list">
        <ProductGroupBadge
          v-for="productGroup in relevantProductGroupsForUser(dashboard.product_groups || [], user)"
          :key="productGroup.id"
          :product-group="productGroup"
          @click="openProductGroupReportFor(productGroup, dashboard.product_groups)"
        />
      </ul>
    </div>
    <div class="page-header--column no-grow is-right-aligned wrap-reverse">
      <slot />
      <DashboardTabs :dashboard="dashboard" />
    </div>
  </div>
</template>

<script>
import ProductGroupBadge from '@/components/ProductGroupBadge/Component.vue';
import {
  relevantProductGroupsForUser,
  openProductGroupReportFor,
} from '@/helpers/ProductGroupHelpers';
import { ToggleDashboardFavorite } from '@/helpers/DashboardHelpers';
import DashboardTabs from '@/components/DashboardTabs/Component.vue';
import {
  EventBus,
  DashboardFavoriteChangeEvent,
} from '@/events';

export default {
  name: 'DashboardHeader',
  components: {
    ProductGroupBadge,
    DashboardTabs,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relevantProductGroupsForUser,
      openProductGroupReportFor,
      isFavoriteObserver: EventBus.on(DashboardFavoriteChangeEvent.EventName,
        this.onFavoriteChanged.bind(this)),
    };
  },
  computed: {
    toggleFavoriteLabel() {
      return this.dashboard.is_favorite
        ? this.$t('components.dashboards.remove_from_favorites')
        : this.$t('components.dashboards.add_to_favorites');
    },
  },
  beforeUnmount() {
    this.isFavoriteObserver.off();
  },
  methods: {
    onToggleFavorite(toggled) {
      return ToggleDashboardFavorite(this.dashboard, toggled);
    },
    onFavoriteChanged(event) {
      if (event.dashboardId === this.dashboard.id) {
        // eslint-disable-next-line vue/no-mutating-props
        this.dashboard.is_favorite = event.isFavorite;
      }
    },
  },
};
</script>
