<template>
  <div
    ref="root"
    class="dashboard-search"
    data-test="dashboard-search"
  >
    <div
      v-if="isOpen"
      class="dashboard-search--overlay"
      @click="close()"
    />
    <div
      class="dashboard-search--container"
      :class="{'is-open': isOpen}"
    >
      <DashboardSearchInput
        ref="input"
        v-model="searchText"
        :is-loading="isOpen && searchService.isLoading"
        :tags="selectedTags.tags"
        aria-owns="dashboard-search-results"
        :aria-expanded="isOpen"
        :aria-activedescendant="selection.selectedDomElementId"
        :placeholder="$t('apps.dashboard_search.placeholder')"
        @input="open"
        @focus="open"
        @remove-tag="onRemoveTag"
        @next="selectNextDescendant"
        @prev="selectPrevDescendant"
        @submit="submit"
        @close="close"
      />
      <ScrollArea
        v-if="isOpen"
        ref="scrollArea"
        class="dashboard-search--scrollarea"
        :max-height="400"
      >
        <DashboardSearchList
          id="dashboard-search-results"
          :suggestions="searchService.suggestedTags"
          :items="searchService.searchResults"
          :selected-id="selection.selectedItemId"
          :has-expanded-suggestions="searchService.hasExpandedSuggestions"
          :total-suggestion-count="searchService.totalSuggestionCount"
          @select-tag="onSelectTag"
          @focus-item="onFocusItem"
          @collapse-suggestions="collapseSuggestions"
          @expand-suggestions="expandSuggestions"
        >
          <template #footer>
            <DashboardSearchFooter
              v-if="showFooter"
              :selected-tags="selectedTags.tags"
              :search-text="searchText"
            />
            <InfiniteScroll
              v-else-if="searchService.canLoadMore"
              @trigger="searchService.more()"
            />
          </template>
        </DashboardSearchList>
      </ScrollArea>
    </div>
  </div>
</template>

<script>
import {
  DashboardSearchInput,
  DashboardSearchList,
  DashboardSearchFooter,
  InfiniteScroll,
} from '@/components';
import { TrapFocus } from '@/helpers/Accessibility';
import {
  TagSelection,
  DashboardSearchResultListController,
  DashboardSearchService,
} from '@/helpers/DashboardSearch';

export default {
  name: 'DashboardSearch',
  components: {
    InfiniteScroll,
    DashboardSearchInput,
    DashboardSearchList,
    DashboardSearchFooter,
  },
  data() {
    const searchService = new DashboardSearchService();
    const selection = new DashboardSearchResultListController(searchService);
    return {
      searchText: '',
      isOpen: false,
      selectedTags: TagSelection(),
      focusTrap: null,
      showFooter: false,
      searchService,
      selection,
    };
  },
  computed: {
    isLoading() {
      return this.searchService.isLoading;
    },
  },
  watch: {
    searchText() {
      this.search();
    },
    isLoading() {
      if (!this.isLoading) {
        this.showFooter = this.searchText !== '' && !this.searchService?.canLoadMore;
      }
    },
  },
  mounted() {
    this.focusTrap = TrapFocus(this.$refs.root);
  },
  beforeUnmount() {
    this.focusTrap.free();
  },
  methods: {
    clear() {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.errors = null;
      this.suggestedTags = [];
      this.isLoadingFirstPage = false;
    },
    close() {
      this.isOpen = false;
      this.focusTrap.free();
      this.clear();
    },
    open(event) {
      if (this.isOpen) return;
      if (event.target) {
        event.target.select();
      }
      this.isOpen = true;
      this.search();
      this.focusTrap.trap();
    },
    onRemoveTag(tag) {
      if (this.selectedTags.remove(tag)) {
        this.search();
      }
    },
    onSelectTag(tag) {
      if (this.selectedTags.add(tag)) {
        this.searchText = '';
        this.search();
        this.$refs.input.focus();
      }
    },
    onFocusItem(item) {
      this.selection.select(item);
    },
    search() {
      this.searchService.search(this.searchText, this.selectedTags, () => {
        this.resetSelectedIndex();
      });
    },
    selectNextDescendant() {
      if (this.selection.next()) {
        this.scrollSelectedElementIntoView();
      }
    },
    selectPrevDescendant() {
      if (this.selection.previous()) {
        this.scrollSelectedElementIntoView();
      }
    },
    resetSelectedIndex() {
      this.selection.reset();
      this.$refs.scrollArea?.scrollToTop();
    },
    scrollSelectedElementIntoView() {
      this.$refs.scrollArea?.scrollIntoView(this.selection.selectedDomElement, { margin: 10 });
    },
    submit() {
      this.selection.submit();
    },
    // when we toggle the suggestions, we reset the current selection
    // to prevent ending up with an invalid selection
    expandSuggestions() {
      this.searchService.expandSuggestions();
      this.selection.selectByIndex(0);
      this.$refs.input.focus();
    },
    collapseSuggestions() {
      this.searchService.collapseSuggestions();
      this.selection.selectByIndex(0);
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>