<template>
  <div class="chart-config-header">
    <h1 class="page-header--title">
      {{ title }}
    </h1>
    <div>
      <ButtonComponent
        variant="ternary"
        @click="cancel"
      >
        {{ $t('apps.chart_config.cancel') }}
      </ButtonComponent>
      <ButtonComponent
        icon="check"
        variant="primary"
        @click="confirmSettings()"
      >
        {{ $t('apps.chart_config.confirm') }}
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import Turbolinks from 'turbolinks';
import NotificationController from '@/controllers/NotificationController';

export default {
  name: 'ChartConfigurationHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    viewUrl: {
      type: String,
      required: true,
    },
    validationError: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirmSettings() {
      if (this.validationError !== null) {
        NotificationController.error(this.validationError);
      } else {
        Turbolinks.visit(this.viewUrl);
      }
    },
    cancel() {
      window.history.back();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>