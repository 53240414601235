/**
 * Get Selected Dimension From HrScore
 * @description Returns the HR score object for the selected dimension from an array of HR scores.
 * @param {Array} hrScores - An array of HR score objects containing name and value properties.
 * @param {Object} selectedDimension - An object representing the selected dimension, containing a label property.
 * @param {string} selectedDimension.value.label - The label of the selected dimension.
 * @returns {Object} The HR score object for the selected dimension.
 *
 * @example
 * Example usage:
 * const hrScores = [
 *   { name: 'Communication', value: 80 },
 *   { name: 'Leadership', value: 75 },
 *   { name: 'Problem Solving', value: 90 },
 * ];
 * const selectedDimension = { value: { label: 'Communication' } };
 * const currentHrScore = getSelectedDimensionFromHrScore(hrScores, selectedDimension);
 * currentHrScore will be { name: 'Communication', value: 80 }
 *
 * @default getSelectedDimensionFromHrScore
 */

export const getSelectedDimensionFromHrScore = (hrScores, selectedDimension) => {
  const currentHrScore = hrScores.filter(
    (hrScore) => hrScore.name === selectedDimension.value.label,
  );
  return currentHrScore[0];
};

export default getSelectedDimensionFromHrScore;
