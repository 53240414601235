import { reactive } from 'vue';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { CursorPagination } from '@/services';

export const GetDomIdForListItem = (item) => `${item.type}-${Base64.encode(item.id.toString())}`;

const transformResult = (item) => ({
  ...item,
  // because we are using market and product names as ids
  // they might not be unique, which is why we use a key
  // for identification
  key: GetDomIdForListItem(item),
  is_favorite: item.type === 'favorite_dashboard',
});

const transformMultipleResults = (items) => items.map(transformResult);

const tagForRequest = (tag) => ({
  id: tag.id,
  type: tag.type,
});

export const COLLAPSED_SUGGESTION_COUNT = 3;
export const PREFERRED_SUGGESTIONS_PER_TYPE = COLLAPSED_SUGGESTION_COUNT - 1;

// This is an intermediate solution to provide better search suggestions. It will
// likely be replaced once we have time for a more elaborate solution. This is the idea:
// 1) return tags first which match the search term uppercased:
//    this is supposed to favor tags with iso codes (DE, EN, ...) that match the search term
// 2) return at least one tag of each type, if possible
// 3) keep the sort order (alphabetical) for the rest of the suggestions
export const orderSuggestionsByRelevance = (searchTerm, tags) => {
  const searchTermUpper = searchTerm?.toUpperCase() || '';
  const tagsWithIndexAndScore = [...tags].map((tag, index) => ({
    ...tag,
    index,
    score: tag.name.includes(searchTermUpper) ? 0 : 1,
  }));
  const orderedByIsoCode = tagsWithIndexAndScore
    .sort((a, b) => (a.score === b.score ? (a.index - b.index) : (a.score - b.score)));

  const types = new Set(orderedByIsoCode.map((tag) => tag.type));
  const hasOnlyOneType = types.size <= 1;
  if (hasOnlyOneType) return orderedByIsoCode.slice(0, COLLAPSED_SUGGESTION_COUNT);

  const suggestionsPerType = { market: 0, product: 0 };
  const selectedTags = [];
  orderedByIsoCode.forEach((tag) => {
    if (selectedTags.length >= COLLAPSED_SUGGESTION_COUNT) return;
    if (suggestionsPerType[tag.type] < PREFERRED_SUGGESTIONS_PER_TYPE) {
      selectedTags.push(tag);
      suggestionsPerType[tag.type] += 1;
    }
  });
  return selectedTags;
};

export class DashboardSearchService {
  constructor() {
    this._debounceTimeout = null;
    this._cancelToken = axios.CancelToken.source();
    this._state = reactive({
      isLoadingFirstPage: false,
      hasExpandedSuggestions: false,
      error: null,
      suggestedTags: [],
      pagination: null,
      currentSearchTerm: null,
    });
  }

  get hasExpandedSuggestions() {
    return this._state.hasExpandedSuggestions;
  }

  get totalSuggestionCount() {
    return this._state.suggestedTags.length;
  }

  get hasMoreResults() {
    return this._state.pagination?.hasMoreResults || false;
  }

  get canLoadMore() {
    return this.hasMoreResults && !this.isLoading;
  }

  get _topSuggestedTags() {
    return orderSuggestionsByRelevance(this._state.currentSearchTerm,
      this._state.suggestedTags);
  }

  get suggestedTags() {
    if (this.hasExpandedSuggestions) {
      return this._state.suggestedTags;
    }
    return this._topSuggestedTags;
  }

  get searchResults() {
    return this._state.pagination?.entries || [];
  }

  get error() {
    return this._state.error;
  }

  get isLoading() {
    return this._state.isLoadingFirstPage || this._state.pagination?.isLoading;
  }

  _cancelPendingSearch() {
    clearTimeout(this._debounceTimeout);
    if (this._cancelToken) {
      this._cancelToken.cancel();
    }
    this._cancelToken = axios.CancelToken.source();
  }

  _createCursorPagination(data, requestParams) {
    return new CursorPagination({
      url: '/api/dashboard_search/next_page',
      params: requestParams,
      cursor: data.paginated_results.next_cursor,
      entries: transformMultipleResults(data.paginated_results.entries),
      errorHandler: (error) => { this._state.error = error; },
      transformEntry: transformResult,
    });
  }

  search(query, selectedTags, callback) {
    const DEBOUNCE = 500;
    this._state.isLoadingFirstPage = true;
    this._cancelPendingSearch();
    const requestParams = {
      query,
      scopes: selectedTags.tags.map(tagForRequest),
    };
    const requestOptions = { cancelToken: this._cancelToken.token };
    this._debounceTimeout = setTimeout(() => {
      const baseUrl = '/api/dashboard_search';
      axios.post(baseUrl, requestParams, requestOptions)
        .then((response) => response.data)
        .then((data) => {
          this._state.error = null;
          this._state.suggestedTags = transformMultipleResults(data.suggested_scopes || []);
          this._state.pagination = this._createCursorPagination(data, requestParams);
          this._state.currentSearchTerm = query;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this._state.error = error;
        })
        .finally(() => {
          callback();
          this._state.hasExpandedSuggestions = false;
          this._state.isLoadingFirstPage = false;
        });
    }, DEBOUNCE);
  }

  more() {
    if (this.isLoading || !this._state.pagination) return;
    this._state.pagination.next();
  }

  clear() {
    this._cancelPendingSearch();
    this._state.isLoadingFirstPage = false;
    this._state.errors = null;
    this._state.suggestedTags = [];
    this._state.pagination = null;
    this._state.hasCollapsedSuggestions = true;
  }

  collapseSuggestions() {
    this._state.hasExpandedSuggestions = false;
  }

  expandSuggestions() {
    this._state.hasExpandedSuggestions = true;
  }
}

export const TagSelection = () => {
  const state = {
    tags: [],
    _findTagIndex(tag) {
      return this.tags.findIndex((t) => t.key === tag.key);
    },
    add(tag) {
      const index = this._findTagIndex(tag);
      if (index >= 0) return false;
      this.tags.push(tag);
      return true;
    },
    remove(tag) {
      const index = this._findTagIndex(tag);
      if (index < 0) return false;
      this.tags.splice(index, 1);
      return true;
    },
  };
  return state;
};

export class DashboardSearchResultListController {
  constructor(dashboardSearchService) {
    this._searchService = dashboardSearchService;
    this._state = reactive({
      selectedKey: this._startKey,
    });
  }

  get _indexOffsetForResults() {
    return this._searchService.suggestedTags.length;
  }

  get _startKey() {
    return this._searchService.searchResults[0]?.key
        || this._searchService.suggestedTags[0]?.key;
  }

  _keyToItem(key) {
    return this._searchService.suggestedTags.find((tag) => tag.key === key)
        || this._searchService.searchResults.find((res) => res.key === key);
  }

  _indexToItem(index) {
    if (index < this._searchService.suggestedTags.length) {
      return this._searchService.suggestedTags[index];
    }
    const resultIndex = index - this._indexOffsetForResults;
    return this._searchService.searchResults[resultIndex];
  }

  _itemToIndex(item) {
    const index = this._itemToTagIndex(item);
    if (index >= 0) return index;
    return this._itemToResultIndex(item);
  }

  _itemToTagIndex(item) {
    return this._searchService.suggestedTags.findIndex((each) => each.key === item.key);
  }

  _itemToResultIndex(item) {
    const index = this._searchService.searchResults.findIndex((each) => each.key === item.key);
    if (index < 0) return -1;
    return this._indexOffsetForResults + index;
  }

  get selectedItem() {
    return this._keyToItem(this._state.selectedKey);
  }

  get selectedItemId() {
    return this._state.selectedKey;
  }

  get selectedDomElement() {
    return document.getElementById(this._state.selectedKey);
  }

  get selectedDomElementId() {
    return this._state.selectedKey;
  }

  select(item) {
    this._state.selectedKey = item.key;
  }

  selectByIndex(index) {
    const nextItem = this._indexToItem(index);
    if (!nextItem) return false;
    this._state.selectedKey = nextItem.key;
    return true;
  }

  next() {
    const nextIndex = this._itemToIndex(this.selectedItem) + 1;
    return this.selectByIndex(nextIndex);
  }

  previous() {
    const nextIndex = this._itemToIndex(this.selectedItem) - 1;
    return this.selectByIndex(nextIndex);
  }

  submit() {
    if (this.selectedDomElement) {
      this.selectedDomElement.click();
    }
  }

  reset() {
    this._state.selectedKey = this._startKey;
  }
}
