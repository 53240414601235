import i18n from '@/config/i18n';

class SortOption {
  constructor(column, direction, i18nPrefix) {
    this._column = column;
    this._direction = direction;
    this._i18nPrefix = i18nPrefix;
  }

  static buildList(i18nPrefix, options) {
    return options.map((option) => new SortOption(option[0], option[1], i18nPrefix));
  }

  get column() {
    return this._column;
  }

  get direction() {
    return this._direction;
  }

  get i18nPrefix() {
    return this._i18nPrefix;
  }

  get key() {
    return `${this.column}_${this.direction}`;
  }

  get value() {
    return i18n.t(`${this.i18nPrefix}.${this.key}`);
  }

  get forApi() {
    return {
      column: this.column,
      direction: this.direction,
    };
  }
}

export default SortOption;
