import { reactive } from 'vue';
import ApiHelpers from '@/api/ApiHelpers';

export default class LoadingService {
  static start(...loadArgs) {
    const service = new LoadingService();
    service.load(...loadArgs);
    return service;
  }

  constructor() {
    this._state = reactive({
      isLoading: false,
      error: null,
      data: null,
    });
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get error() {
    return this._state.error;
  }

  get data() {
    return this._state.data;
  }

  async load(dataPromise, { errorHandler = ApiHelpers.handleError } = {}) {
    try {
      this._state.error = null;
      this._state.isLoading = true;
      this._state.data = await dataPromise;
    } catch (error) {
      this._state.data = null;
      this._state.error = error;
      if (errorHandler) {
        errorHandler(error);
      }
    } finally {
      this._state.isLoading = false;
    }
  }
}
