<template>
  <div :style="{width: `${width}px`, height: `${height}px`}">
    <svg
      class="bar-chart"
      :width="width"
      :height="height"
    >
      <g :transform="`translate(0, ${verticalPadding})`">
        <rect
          v-for="(shade, index) in shades"
          :key="`shade-${index}`"
          class="bar-chart--shade"
          :x="shade.x"
          :y="shade.y"
          :width="shade.width"
          :height="shade.height"
        />
        <rect
          v-for="(shade, index) in highlights"
          :key="`highlight-${index}`"
          class="bar-chart--highlight"
          :x="shade.x"
          :y="shade.y"
          :width="shade.width"
          :height="shade.height"
        />
      </g>
      <g :transform="`translate(${leftPadding}, ${verticalPadding})`">
        <PlotGrid
          :x-scale="scaleX"
          :width="innerWidth"
          :height="innerHeight"
          :config="bottomAxisConfig"
        />
        <PlotAxis
          class="bar-chart--axis"
          :top="innerHeight"
          :scale="scaleX"
          :config="bottomAxisConfig"
          orientation="bottom"
          @resize="updateBottomPadding"
        />
        <HorizontalBar
          v-for="(bar, index) in bars"
          :key="`${bar.y}-${index}`"
          class="bar-chart--bar"
          :x="bar.x"
          :y="bar.y"
          :width="bar.width"
          :height="bar.height"
          :text-position="bar.textPosition"
          :outer-label="bar.outerLabel"
          :inner-label="bar.innerLabel"
        />
        <rect
          class="bar-chart--border"
          :x="0.5"
          :y="0.5"
          :width="innerWidth - 1"
          :height="innerHeight"
        />
        <PlotAxis
          class="bar-chart--axis"
          :scale="scaleY"
          orientation="left"
          :config="leftAxisConfig"
          @resize="updateLeftPadding"
        />
      </g>
      <g
        v-for="(shade, index) in highlights"
        :key="`highlight-stroke-${index}`"
        :transform="`translate(0, ${verticalPadding})`"
      >
        <line
          class="bar-chart--highlight-stroke"
          :x1="shade.x"
          :x2="shade.x + shade.width"
          :y1="shade.y"
          :y2="shade.y"
        />
        <line
          class="bar-chart--highlight-stroke"
          :x1="shade.x"
          :x2="shade.x + shade.width"
          :y1="shade.y + shade.height"
          :y2="shade.y + shade.height"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';
import AxisConfig from '../../models/charts/AxisConfig';
import PlotAxis from './Components/PlotAxis.vue';
import PlotGrid from './Components/PlotGrid.vue';
import HorizontalBar from './HorizontalBar.vue';
import { formatTickNumbers, formatPercent } from '../../helpers/NumberFormats';

export default {
  name: 'HorizontalBarChart',
  components: {
    PlotAxis,
    PlotGrid,
    HorizontalBar,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    bandwidth: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    highlight: {
      type: Array,
      default: () => [],
    },
    fractionDigits: {
      type: Number,
      default: 0,
    },
    showPercentages: {
      type: Boolean,
      default: true,
    },
    verticalTickFormat: {
      type: Function,
      default: (tick) => tick,
    },
  },
  data() {
    return {
      leftPadding: 0,
      bottomPadding: 0,
      verticalPadding: 5,
      bottomAxisConfig: AxisConfig.default({
        tickCount: 6,
        tickFilter: (value, idx, count) => idx > 0 && idx < count - 1,
        tickFormat: formatTickNumbers,
      }),
      leftAxisConfig: AxisConfig.default({
        tickFormat: this.verticalTickFormat,
      }),
    };
  },
  computed: {
    innerWidth() {
      return this.width - this.leftPadding;
    },
    innerHeight() {
      return this.bandwidth * this.data.length;
    },
    height() {
      return this.innerHeight + 2 * this.verticalPadding + this.bottomPadding;
    },
    rangeX() {
      return [0, this.innerWidth];
    },
    rangeY() {
      return [0, this.innerHeight];
    },
    domainX() {
      // In order to prevent text labels from overflowing,
      // we estimate the required text width in domain space.
      // We do that by first approximating the required number
      // of pixels and then converting them to domain space.
      const extraPaddingPx = 10;
      const pxPerCharacterApproximation = 8;
      const largestAbsoluteNumber = Math.max(...this.data.map((d) => isFinite(d.x) ? Math.abs(d.x) : 0));
      const maximumCharacterCount = this.$n(largestAbsoluteNumber).length;
      const requiredPaddingPx = maximumCharacterCount * pxPerCharacterApproximation;
      const relativeRequiredPadding = (requiredPaddingPx + extraPaddingPx) / this.innerWidth;
      const requiredDomainScale = 1 / (1 - relativeRequiredPadding);

      const minValue = d3.min(this.data, (d) => d.x);
      const maxValue = d3.max(this.data, (d) => d.x);
      const startDomain = Math.min(0, minValue * requiredDomainScale);
      const endDomain = Math.max(0, maxValue * requiredDomainScale);
      return [startDomain, endDomain];
    },
    domainY() {
      return this.data.map((d) => d.y);
    },
    scaleX() {
      return d3.scaleLinear()
        .range(this.rangeX)
        .domain(this.domainX);
    },
    scaleY() {
      return d3.scaleBand()
        .range(this.rangeY)
        .domain(this.domainY);
    },
    path() {
      return d3.line()
        .x((d) => this.scaleX(d.x))
        .y((d) => this.scaleY(d.y));
    },
    barwidth() {
      return this.scaleY.bandwidth() * 0.5;
    },
    bands() {
      return this.data.map((d) => ({
        x: 0,
        y: this.scaleY(d.y),
        width: this.innerWidth + this.leftPadding - 1,
        height: this.scaleY.bandwidth(),
        label: d.y,
      }));
    },
    shades() {
      return this.bands.filter((d, idx) => idx % 2 === 0);
    },
    highlights() {
      return this.bands.filter((d) => this.highlight.indexOf(d.label) >= 0);
    },
    bars() {
      const domainIsNegative = this.domainX[0] < 0 && this.domainX[1] <= 0;
      const totalSum = d3.sum(this.data, (d) => d.x);

      return this.data.filter((d) => d.x !== undefined).map((d) => {
        const x = this.scaleX(0);
        const y = this.scaleY(d.y) + this.scaleY.bandwidth() / 2 - this.barwidth / 2;
        const width = this.scaleX(d.x) - this.scaleX(0);
        const height = this.barwidth;
        const textPosition = width < 0 || domainIsNegative ? 'left' : 'right';
        const outerLabel = typeof d.x !== 'number' || !isFinite(d.x) ? '' : this.$n(d.x, 'decimal', {
          minimumFractionDigits: this.fractionDigits,
          maximumFractionDigits: this.fractionDigits,
        });
        const innerLabel = this.showPercentages
          ? formatPercent(d.x / totalSum)
          : null;
        return {
          x, y, width, height, textPosition, outerLabel, innerLabel,
        };
      });
    },
  },
  methods: {
    updateLeftPadding(size) {
      if (this.leftPadding !== size) {
        this.leftPadding = size;
      }
    },
    updateBottomPadding(size) {
      if (this.bottomPadding !== size) {
        this.bottomPadding = size;
      }
    },
  },
};
</script>
