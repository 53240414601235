<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <WithTooltip :tooltip="value.tooltip">
      <a
        class="data-table--link"
        :href="value.url"
        @click="$event.stopPropagation()"
      >
        {{ value.text }}
      </a>
    </WithTooltip>
  </td>
</template>

<script>

const validateValue = (value) => ('text' in value && 'url' in value);

export default {
  name: 'LinkColumn',
  props: {
    value: {
      type: Object,
      required: true,
      validator: validateValue,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
