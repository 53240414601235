<template>
  <DropdownMenu>
    <DropdownMenuButton
      icon="save"
      @click="controller.saveDefaultFilters()"
    >
      {{ $t('components.search_header.save_filters') }}
    </DropdownMenuButton>

    <DropdownMenuButton
      icon="redo-alt"
      @click="controller.loadDefaultFilters()"
    >
      {{ $t('components.search_header.load_filters') }}
    </DropdownMenuButton>

    <DropdownMenuButton
      icon="times"
      :disabled="!controller.filtersActive"
      @click="controller.clearFilters()"
    >
      {{ $t('components.search_header.clear_filters') }}
    </DropdownMenuButton>
  </DropdownMenu>
</template>

<script>
export default {
  name: 'FilterActionDropdown',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
