<template>
  <PrintLayoutInstance
    v-if="$media === 'print'"
    :caption="caption"
    :header-only-first-page="headerOnlyFirstPage"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayoutInstance>
</template>

<script>
import PrintLayoutInstance from './Instance/Component.vue';

// This component only wraps around the PrintLayoutInstance
// component in order to hide it when media is not 'print'
export default {
  name: 'PrintLayout',
  components: {
    PrintLayoutInstance,
  },
  props: {
    caption: {
      type: String,
      default: null,
    },
    headerOnlyFirstPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
