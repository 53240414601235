<script>
/**
 * This component is responsible for rendering the cells in the
 * `Commodity Risk` column group in the `ExpandableColumnsTable`.
 *
 * It will display the calculated score for the commodity risk in a single dimension.
 * It also includes the logic for showing the adjusted tooltips. These will show
 * the actual source scores that have been used by the server to calculate the commodity risk.
 */

import HrScore from "@/components/NotationList/NotationTable/Columns/HrScoresColumn/HrScore"
import TopFiveCountryCellTooltip from "@/components/NotationList/NotationTable/Columns/TopFiveCountry/TopFiveCountryCellTooltip"

import { digJson } from '@/utils/digJson'

export default {
  props: {
    supplyChainItem: {
      type: Object
    },
    dimension: {
      type: Object
    },
  },

  components: {
    HrScore,
    TopFiveCountryCellTooltip
  },

  computed: {
    calculatedScore() {
      return {
        ...this.supplyChainItem.calculated_scores[this.dimension.value],
        ...{ name: `${this.$t("apps.supply_chain.groups.commodity_risk")}<br/> (${this.$t("apps.supply_chain.column_tooltips.commodity_risk_scores")})` }
      }
    },

    /**
     * Maps the possible values in +calculated_score_sources+ to a title and the actual
     * location of the scores inside the json response.
     */
    sourceMapping() {
      return {
        country_of_demand: {
          jsonLookup: "country_of_demand.hr_scores",
          label: this.$t("apps.supply_chain.calculated_scores.country_risk")
        },
        country_of_production: {
          jsonLookup: "country_of_production.hr_scores",
          label: this.$t("apps.supply_chain.calculated_scores.country_risk")
        },
        sector: {
          jsonLookup: "sector.scores",
          label: this.$t("apps.supply_chain.calculated_scores.sector_risk")
        },
        weighted_human_rights_scores: {
          jsonLookup: "weighted_human_rights_scores",
          label: this.$t("apps.supply_chain.calculated_scores.country_risk")
        }
      }
    },

    tooltipData() {
      return {
        hrScores: [this.calculatedScore, ...this.sourceScores],
        title: this.dimension.label
      }
    },

    /**
     * Returns the actual scores that were used to calculate the commodity risk.
     */
    sourceScores() {
      return this.supplyChainItem.calculated_score_sources.map((sourceName)=>{
        let jsonLookup = `${this.sourceMapping[sourceName].jsonLookup}.${this.dimension.value}`
        let score = digJson(this.supplyChainItem, jsonLookup)

        if (!score) { return }

        return {
          ...score,
          ...{ name: this.sourceMapping[sourceName].label }
        }
      }).filter(Boolean)
    }
  }
}
</script>

<template>
  <td class="data-table--body-cell">
    <WithTooltip y-alignment="auto" tag="div">
      <HrScore :value="calculatedScore"/>
      <template #custom>
        <TopFiveCountryCellTooltip :data="tooltipData" />
      </template>
    </WithTooltip>
  </td>
</template>

<style scoped lang="scss">
</style>
