<template>
  <div class="dropdown-menu">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>