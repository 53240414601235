import Combobox from '@github/combobox-nav'
import generateUuid from '@/utils/uuid'

export default {
  data() {
    return {
      // this property is used for determining which icons should be displayed
      // to the user. It has nothing to do with the actual events/actions
      comboboxEnterAction: "selectAll"
    }
  },
  computed: {
    // unique dom node id is required for @github/combobox-nav
    comboboxUuid() {
      return `u-${generateUuid().slice(0, 6)}`
    },

    allItemsDeselected() {
      return !this.filteredItems.find((item) => item.selected)
    }
  },

  methods: {
    startCombobox() {
      if(!this.comboboxInput) {
        console.error('comboboxInput missing', this)
        return
      }
      if(!this.comboboxList) {
        console.error('comboboxList missing', this)
        return
      }

      if (!this.combobox) {
        this.combobox = new Combobox(this.comboboxInput, this.comboboxList)
        this.setupEnterActionPreview()

        // scroll to the very top (showing the input) if the first item is focussed
        this.comboboxList.addEventListener('combobox-select', (event) => {
          if (event.target.matches(':first-child')) {
            let modal = event.target.closest('.modal')
            if (modal) {
              modal.scrollTop = 0
            }
          }
        })
      }
      this.combobox.start()
    },

    stopCombobox() {
      this.combobox?.stop()
    },

    comboboxOnEnter() {
      if (this.comboboxList.querySelector("[aria-selected]")) {
        return
      }

      this.toggleAll(this.allItemsDeselected)
    },

    toggleItem(item) {
      item.selected = !item.selected;
      this.$emit('change');
    },

    toggleAll(selected) {
      this.filteredItems.forEach((item)=>{
        if (!item.unavailable) {
          item.selected = selected;
        }
      })

      this.$emit('change');
      this.comboboxEnterAction = this.allItemsDeselected ? "selectAll" : "deselectAll"
    },

    // Add callbacks to the combobox when an item or the input is focused
    setupEnterActionPreview() {
      // focus in a single item
      this.comboboxList.addEventListener('combobox-select', (event) => {
        this.comboboxEnterAction = "toggleItem"
      })

      // Focus moves back to the input, no item is selected.
      // Unfortunately, combobox does not have a callback for this so we're
      // overriding the clearSelection method
      let originalClearSelection = this.combobox.clearSelection
      this.combobox.clearSelection = ()=>{
        this.comboboxEnterAction = this.allItemsDeselected ? "selectAll" : "deselectAll"
        originalClearSelection.bind(this.combobox).apply()
      }
    }
  }
}
