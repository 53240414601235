<template>
  <div class="card chart--card">
    <div class="chart">
      <QuotesChartAxisLabel
        :label="unit"
        axis="primary"
      />
      <div class="chart--plot">
        <LineChart
          :chart-data="{ datasets: datasets }"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/LineChart/Component.vue';
import QuotesChartAxisLabel from '@/components/QuotesChart/AxisLabel/Component.vue';
import ChartOptions from './ChartOptions';

export default {
  name: 'ChartView',
  components: {
    LineChart,
    QuotesChartAxisLabel,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return ChartOptions({
        axisType: 'x',
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>