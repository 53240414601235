import { defineStore } from 'pinia';
import NotationAnalysesApi from '@/api/NotationAnalysesApi';
import PaginationService from '@/services/PaginationService';

export const useNotationAnalysesStore = defineStore('notationAnalyses', {
  state: () => ({
    hasError: false,
    pagination: {},
  }),
  getters: {
    savedAnalyses() {
      return this.pagination.entries;
    },
    createMultiLineChartUrl() {
      return '/multi-line-chart-configuration';
    },
    createSeasonLineChartUrl() {
      return '/season-line-chart-configuration';
    },
    hasMoreResults() {
      return this.pagination.hasNextPage;
    },
    isLoading() {
      return this.pagination.isLoading;
    },
  },
  actions: {
    async initialize() {
      this.pagination = this.createNotationAnalysesPagination();
      await this.pagination.fetchFirstPage();
    },
    async deleteAnalysis(savedAnalysisId) {
      await NotationAnalysesApi().deleteAnalysis(savedAnalysisId);
      await this.pagination.fetchFirstPage();
    },
    loadNextPage() {
      this.pagination.fetchNextPage();
    },
    async duplicateAnalysis(analysis) {
      await NotationAnalysesApi().duplicateAnalysis({
        id: analysis.id,
        title: analysis.title,
      });
      this.initialize();
    },
    createNotationAnalysesPagination() {
      return new PaginationService({
        url: '/api/notation_analyses',
        method: 'GET',
        errorHandler: () => {
          this.hasError = true;
        },
      });
    },
  },
});
