<template>
  <div
    v-if="savedAnalyses.length === 0 && !isLoading && !hasError"
    class="notation-analyses--error"
  >
    {{ $t('apps.notation_analyses.no_analyses_saved') }}
  </div>
  <div
    v-else
    class="notation-analyses"
  >
    <ul>
      <NotationAnalysesListItem
        v-for="analysis in savedAnalyses"
        :key="analysis.id"
        :analysis="analysis"
        @delete-analysis="(savedAnalysisId) => $emit('delete-analysis', savedAnalysisId)"
        @duplicate-analysis="
          (analysis)=> $emit('duplicate-analysis', analysis)"
      />
    </ul>
    <div
      v-if="isLoading"
      class="notation-analyses--spinning-loader"
    >
      <SpinningLoader />
    </div>
    <InfiniteScroll
      v-else-if="hasMoreResults"
      id="more"
      @trigger="() => $emit('load-next-page')"
    />
    <div
      v-if="hasError"
      class="notation-analyses--error"
    >
      {{ $t('apps.notation_analyses.error') }}
    </div>
  </div>
</template>

<script>
import { InfiniteScroll } from '@/components';
import NotationAnalysesListItem from './NotationAnalysesListItem/Component.vue';

export default {
  name: 'NotationAnalysesList',
  components: {
    NotationAnalysesListItem,
    InfiniteScroll,
  },
  props: {
    savedAnalyses: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    hasMoreResults: {
      type: Boolean,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['delete-analysis', 'load-next-page', 'duplicate-analysis'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>