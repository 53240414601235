<template>
  <div
    v-click-outside="close"
    class="app-header--menu"
  >
    <div class="app-header--user">
      <NavigationUserMenuButton
        :user="user"
        @click="toggle"
      />

      <ul
        class="app-header--user-menu"
        :class="{'is-open': isOpen}"
      >
        <li v-if="user.is_editor">
          <NavigationUserMenuChangeRoleButton :user="user" />
        </li>
        <NavigationUserMenuItem
          v-if="user.editor_permissions && $features.ANALYST_CORNER"
          href="/product-group-reports"
          :text="$t('layouts.app_header.product_group_reports')"
        >
          <template #icon>
            <ProductGroupTrendIcon
              trend="none"
              variant="menu"
            />
          </template>
        </NavigationUserMenuItem>
        <NavigationUserMenuItem
          v-if="user.editor_permissions"
          icon="folders"
          href="/publications"
          :text="$t('layouts.app_header.publications')"
        />
        <NavigationUserMenuItem
          v-if="user.editor_permissions"
          icon="newspaper"
          href="/news"
          :text="$t('layouts.app_header.news')"
        />

        <NavigationUserMenuItem
          v-if="user.admin_permissions"
          class="app-header--user-menu-entry-with-separator"
          icon="cog"
          href="/avo"
          data-turbolinks="false"
          :text="$t('layouts.app_header.admin')"
        />

        <li class="app-header--user-menu-entry-with-separator">
          <NavigationUserMenuChangeLocaleButton />
        </li>
        <NavigationUserMenuItem
          v-if="user.has_market_prices_subscription"
          icon="search"
          :text="$t('layouts.app_header.search')"
          @click="openSearchModal"
          :disabled="!user.can_export_raw_data"
        />
        <NavigationUserMenuItem
          v-if="user.has_market_prices_subscription"
          icon="coins"
          :text="$t('layouts.app_header.currency')"
          :user="user"
          @click="openCurrencyModal"
        />
        <NavigationUserMenuItem
          v-if="$features.SUBSCRIPTIONS_MANAGEMENT"
          href="/subscriptions"
          icon="envelope"
          :text="$t('layouts.app_header.manage_subscriptions')"
        />
        <NavigationUserMenuItem
          icon="lock-alt"
          :text="$t('layouts.app_header.reset_password')"
          href="https://sspr.hgs-info.net/resetpassword/"
          :target-blank="true"
          :turbolinks="false"
        />
        <NavigationUserMenuItem
          icon="sign-out"
          href="/logout"
          :turbolinks="false"
          :text="$t('layouts.app_header.logout')"
          @click="clearStorage"
        />
        <NavigationUserMenuFooter />
      </ul>
    </div>
  </div>
</template>

<script>
import ProductGroupTrendIcon from '@/components/ProductGroupTrendIcon/Component.vue';
import ModalWindowController from '@/controllers/ModalWindowController';
import NavigationUserMenuChangeRoleButton from './ChangeRoleButton/Component.vue';
import NavigationUserMenuChangeLocaleButton from './ChangeLocaleButton/Component.vue';
import NavigationUserMenuFooter from './Footer/Component.vue';
import NavigationUserMenuItem from './Item/Component.vue';
import NavigationUserMenuButton from './Button/Component.vue';
import StorageService from '../../../../services/StorageService/StorageService';
import {
  KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS,
} from '../../../../services/StorageService/StorageConst';

export default {
  name: 'NavigationUserMenu',
  components: {
    NavigationUserMenuChangeRoleButton,
    NavigationUserMenuChangeLocaleButton,
    ProductGroupTrendIcon,
    NavigationUserMenuFooter,
    NavigationUserMenuItem,
    NavigationUserMenuButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    clearStorage() {
      const storageService = new StorageService();
      storageService.remove(KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS);
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    openSearchModal() {
      this.close();
      ModalWindowController.show('TopTermSearchModal');
    },
    openCurrencyModal() {
      this.close();
      ModalWindowController.show('CurrencyModal', {
        props: {
          user: this.user,
        },
      });
    },
  },
};
</script>
