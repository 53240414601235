<template>
  <PrintLayout :header-only-first-page="true">
    <template #header>
      <PrintLayoutInstanceDefaultHeader class="low-margin-bottom" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayout>
  <div class="portraits__container portraits--sticky">
    <div class="portraits__row mt-2">
      <h1>
        {{ t("apps.human_rights.country_portraits.headline_one") }} |
        {{ t("apps.human_rights.country_portraits.headline_two") }}
      </h1>
    </div>
    <div class="portraits__row hr-legend mt-2 justify-right">
      <HumanRightsRiskLegend :column-amount="2" wrapper-classes="w-100" />
    </div>
    <div v-if="initialLoad" class="portraits__row">
      <ExtendableTableHeader>
        <table class="data-table">
          <tbody class="data-table--body">
          <tr>
            <td class="data-table--body-cell">
              <SpinningLoader text="" v-if="tableController.isLoading" />
            </td>
            <td class="data-table--body-cell">
              <strong>{{ t("apps.country_portraits.tables.header.cta_dropdowns") }} &rsaquo;</strong>
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry01"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry02"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry03"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry04"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry05"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
            <td class="data-table--body-cell">
              <AutoComplete
                  v-model="selectedCountry06"
                  :options="countriesForSelect"
                  :access-key="(c) => c.value"
                  :access-value="(c) => c.label"
                  :description="t('apps.country_portraits.country')"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </ExtendableTableHeader>
    </div>
  </div>
  <div v-if="!initialLoad" class="portraits__container">
    <SpinningLoader class="mt-2" />
  </div>
  <div v-else class="portraits__container country-portraits-table-wrapper">
    <div class="portraits__row mb-2">
      <NotationList
        :sticky-margin-zero-top="true"
        :controller="tableController"
        class="portraits__data-table--head-red portraits__data-table--clickable portraits__data-table--highlighted scores-indicators-table"
      />
    </div>
    <div class="portraits__column content-space">
      <h4>{{ t("apps.human_rights.country_portraits.first_indicators_headline") }}</h4>
      <p>{{ t("apps.human_rights.country_portraits.first_indicators_description") }}</p>
    </div>
    <div
      class="portraits__row pb-2 pt-2 portraits__scrollable-content"
      v-for="(controller, index) in tableStatCategoryControllers"
      :key="`statCategory${index}`"
    >
      <NotationList
        :key="tableKey.key"
        :controller="controller"
        :sticky-margin-zero-top="true"
        :class="`portraits__data-table--head-${generatedTableColorList[index]} mt-2`"
      />
    </div>
    <!-- World Governance Indicators (WGI) -->
    <div class="portraits__row content-space row-with-legend">
      <div class="portraits__column w-50">
        <h4>{{ t("apps.human_rights.country_portraits.second_indicators_headline") }}</h4>
        <p>{{ t("apps.human_rights.country_portraits.second_indicators_description_one") }}</p>
        <p>{{ t("apps.human_rights.country_portraits.second_indicators_description_three") }}</p>
      </div>
    </div>
    <div class="portraits__row pb-12vh">
      <NotationList
        :key="tableKey.key"
        :controller="tableScoreCategoryController"
        :sticky-margin-zero-top="true"
        class="portraits__data-table--head-gray"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { AutoComplete, SpinningLoader } from "@/components/generic";
import { HumanRightsRiskLegend, ExtendableTableHeader, NotationList } from "@/components";
import CountryPortraitsApi from "@/api/CountryPortraitsApi";
import PrintLayout from "../../components/PrintLayout/Component.vue";
import PrintLayoutInstanceDefaultHeader from "../../components/PrintLayout/Instance/DefaultHeader/Component.vue";
import CountryPortraitsStatCategoryTableController from "./controllers/CountryPortraitsStatCategoryTableController";
import PortraitsHRScoreTableController from "@/apps/Portraits/controllers/PortraitsHRScoreTableController";
import CountryPortraitsScoreCategoryTableController from "./controllers/CountryPortraitsScoreCategoryTableController";
import { usePortraitsDropdowns } from "@/apps/Portraits/composables/usePortraitsDropdowns";
import Turbolinks from "turbolinks";

const initialLoad = ref(false);
const isLoading = ref(false);

const { t } = useI18n();

const generatedTableColorList = ["gray-dark", "green", "red", "blue", "gray", "gray"];

const { countriesForSelect, hrDimensionsForTable, indicatorScoreCategoriesForTable, indicatorStatCategoriesForTable, worldId } =
  defineProps({
    countriesForSelect: {
      type: Array,
      default: () => [],
    },
    hrDimensionsForTable: {
      type: [Array, Object],
      default: () => [],
    },
    indicatorStatCategoriesForTable: {
      type: Array,
      default: () => [],
    },
    indicatorScoreCategoriesForTable: {
      type: Array,
      default: () => [],
    },
    worldId: {
      type: Number,
      required: true,
    },
  });

const countryData = reactive({ countries: [] });

const tableKey = reactive({ key: 0 });

const {
  selectedSubject01: selectedCountry01,
  selectedSubject02: selectedCountry02,
  selectedSubject03: selectedCountry03,
  selectedSubject04: selectedCountry04,
  selectedSubject05: selectedCountry05,
  selectedSubject06: selectedCountry06,
} = usePortraitsDropdowns(...Array(6).fill(worldId));

const tableController = new PortraitsHRScoreTableController(t);
const tableScoreCategoryController = new CountryPortraitsScoreCategoryTableController(t, 0);
const tableStatCategoryControllers = indicatorStatCategoriesForTable.map(
  (category, index) => new CountryPortraitsStatCategoryTableController(t, index)
);

const tableHRInit = () => {
  tableController.init({
    subjects: countryData.countries,
    hrDimensionsForTable,
    columnTranslationKey: "apps.country_portraits.tables.hr_score",
  });
  tableStatCategoryControllers.forEach((controller) => {
    controller.init({
      countries: countryData.countries,
      indicatorStatCategoriesForTable,
      countriesForSelect,
    });
  });
  tableScoreCategoryController.init({
    countries: countryData.countries,
    indicatorScoreCategoriesForTable,
    countriesForSelect,
  });
  initialLoad.value = true;
  tableController.isLoading = false;
};

const changeTables = () => {
  tableScoreCategoryController.updateCountries({
    countries: countryData.countries,
  });
  tableStatCategoryControllers.forEach((controller) => {
    controller.updateCountries({
      countries: countryData.countries,
    });
  });
  tableController.updateSubjects(countryData.countries);
  tableKey.key = Turbolinks.uuid();
  tableController.isLoading = false;
};

const getCountryData = (order, country) => {
  tableController.isLoading = true;
  CountryPortraitsApi()
    .getCountryData(country)
    .then((response) => {
      countryData.countries[order] = [country, response];
      if (initialLoad.value === true) {
        changeTables();
      }
    });
};

const countryList = [
  selectedCountry01,
  selectedCountry02,
  selectedCountry03,
  selectedCountry04,
  selectedCountry05,
  selectedCountry06,
];

countryList.forEach((country, index) => {
  getCountryData(index, country.value);
});

watch(selectedCountry01, (country) => {
  getCountryData(0, country);
});
watch(selectedCountry02, (country) => getCountryData(1, country));
watch(selectedCountry03, (country) => getCountryData(2, country));
watch(selectedCountry04, (country) => getCountryData(3, country));
watch(selectedCountry05, (country) => getCountryData(4, country));
watch(selectedCountry06, (country) => getCountryData(5, country));
watch(countryData, () => {
  // TODO make debouncer from helper work
  if (countryData.countries.length === 6) {
    if (initialLoad.value === false) {
      tableHRInit();
    }
  }
});
</script>

<style lang="scss">
@import "@/apps/Portraits/style.screen.scss";
.country-portraits-table-wrapper .data-table {
  thead.data-table--sticky-margin {
    top: 225px;
    z-index: 2;
  }
}
</style>

<style scoped lang="scss">
@import "@/apps/Portraits/style-scoped.screen.scss";
</style>
