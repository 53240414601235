import { onMounted, ref, unref } from 'vue';
import yellowFlag from '../../../../../../assets/images/topFiveCountry/yellowFlag.svg';
import redFlag from '../../../../../../assets/images/topFiveCountry/redFlag.svg';

const getFlagIconByCount = (flagCount) => {
  if (flagCount === 1) {
    return yellowFlag
  } else if (flagCount > 1) {
    return redFlag
  }
}
// #FIXME: Duplicate business logic. See app/models/human_rights/score.rb
const getFlagCount = (_scores) => {
  const flagThreshold = 3.1;

  const flagCount = _scores.filter((score) => score.value >= flagThreshold).length;
  return flagCount;
};

const useHrScoreFlagIcon = (hrScores) => {
  const hrScoreFlagIcon = ref(null);

  const getFlagIcon = (_scores) => {
    const scores = unref(_scores);
    if (!scores) { return undefined; }
    const flagCount = getFlagCount(scores);
    return getFlagIconByCount(flagCount);
  };

  const getFlagIconReactive = () => {
    hrScoreFlagIcon.value = getFlagIcon(hrScores);
  };

  onMounted(() => {
    if (hrScores) getFlagIconReactive();
  });

  return { hrScoreFlagIcon, getFlagIcon, getFlagIconReactive };
};

export {
  getFlagIconByCount,
  useHrScoreFlagIcon as default
}


