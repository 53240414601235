// Use this directive to exclude elements from print.
// You can either use it without a condition, as
//  <div v-exclude-from-print />
// or you can conditionally exclude an element like
// .<div v-exclude-from-print="$isMailExport" />

const ATTRIBUTE_NAME = 'data-exclude-from-print';

const updateDataAttribute = (el, binding) => {
  el.removeAttribute(ATTRIBUTE_NAME);
  if (binding.value !== false) {
    el.toggleAttribute(ATTRIBUTE_NAME, true);
  }
};

export default {
  beforeMount: updateDataAttribute,
  updated: updateDataAttribute,
  unmounted(el) {
    el.removeAttribute(ATTRIBUTE_NAME);
  },
};
