<template>
  <div
    class="dialog--window"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('components.currency_modal.title') }}
      </div>
    </div>
    <form
      class="dialog--main"
      @submit="save"
    >
      <div
        v-if="$features.CURRENCY_CONVERSION"
        class="dialog--main-box"
      >
        <div class="currency-modal--form-content">
          <div class="form--box">
            <label
              for="currency-conversion-box"
              class="form--label"
            >
              {{ $t('components.currency_modal.label') }}
            </label>
            <CurrencyComboBox
              id="currency-conversion-box"
              v-model="preferredCurrencyId"
            />
          </div>
        </div>
        <p class="currency-modal--description">
          {{ $t('components.currency_modal.description') }}
        </p>
        <ul class="dialog--action-list">
          <li class="dialog--action-list-item">
            <ButtonComponent
              variant="ternary"
              @click="cancel"
            >
              {{ $t('components.modals.cancel') }}
            </ButtonComponent>
          </li>
          <li class="dialog--action-list-item">
            <ButtonComponent
              type="submit"
              variant="primary"
              :disabled="isSaving"
            >
              {{ $t('apps.publications.edit_modal.update') }}
            </ButtonComponent>
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
import { CurrencyComboBox } from '@/components';
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';

export default {
  name: 'CurrencyModal',
  components: {
    CurrencyComboBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'close',
    'save',
  ],
  data() {
    return {
      isSaving: false,
      preferredCurrencyId: this.user.preferred_currency_unit?.id,
    };
  },
  computed: {
    userUpdatedParams() {
      return {
        preferred_currency_unit_id: this.preferredCurrencyId,
      };
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    async save(e) {
      e.preventDefault();

      try {
        this.isSaving = true;
        await axios.put('/api/user', {
          user: this.userUpdatedParams,
        });
        this.$emit('close');
        window.location.reload();
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>