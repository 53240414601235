import moment from 'moment';

export const DEFAULT_GRAPH_START_DATE = moment().subtract(5, 'years').startOf('year');
export const DEFAULT_GRAPH_MIN_DATE = moment('1999-01-01');
export const defaultGraphLimits = () => ({
  minDate: DEFAULT_GRAPH_MIN_DATE,
  maxDate: moment(),
  startDate: DEFAULT_GRAPH_START_DATE,
  endDate: moment(),
});
