// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Gem & Vendor Imports
//---------------------
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Lib Imports
//------------

// App Component Imports
//----------------------

import moment from 'moment';
import axios from 'axios';
import Qs from 'qs';
import {
  ProductGroupReportsApp,
  IntroApp,
  SearchApp,
  WatchListApp,
  DashboardApp,
  PublicationsApp,
  DashboardTradeFlowsApp,
  ModalWindowApp,
  ToastNotificationsApp,
  NavigationApp,
  SubscriptionsApp,
  NewsApp,
  DashboardSearchApp,
  DashboardNewsApp,
  DashboardPublicationsApp,
  ShowNewsApp,
  ShowPublicationApp,
  ShowProductGroupReportApp,
  MultiLineChartConfigurationApp,
  MultiLineChartViewApp,
  VideoTutorialsApp,
  NotationAnalysesApp,
  SeasonLineChartConfigurationApp,
  SeasonLineChartViewApp,
  DashboardOverviewExportApp,
  CountryRiskMapApp,
  CommodityRisksMapApp,
  CountryPortraitsApp,
  SectorPortraitsApp,
} from '@/apps';
import mountVueApp from '@/config/mountVueApp';
import '@/plugins/ChartjsPrint';
import i18n from '@/config/i18n';
import { setupFontAwesome } from '@/config/fontawesome';
import { onTrixAttachmentAdd } from '@/helpers/TrixAttachmentUpload';

require('@/config/polyfill-resize-observer');
require('core-js/stable');
require('regenerator-runtime/runtime');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

require('@/components/app_header');

setupFontAwesome();

const setLocale = (locale) => {
  // update html tag, because turbolinks doesn't do that
  document.documentElement.setAttribute('lang', locale);
  i18n.locale = locale;
  moment.locale(locale);
};

document.addEventListener('turbolinks:load', () => {
  const currentLocale = document.querySelector('meta[name="language"]').content;
  setLocale(currentLocale);

  const xcsrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  axios.defaults.headers.common = {
    'X-CSRF-Token': xcsrfToken,
  };
});

mountVueApp('vue-product-group-reports-app', ProductGroupReportsApp);
mountVueApp('vue-modal-window-app', ModalWindowApp);
mountVueApp('vue-toast-notifications-app', ToastNotificationsApp);
mountVueApp('vue-intro-app', IntroApp);
mountVueApp('vue-watch-list-app', WatchListApp);
mountVueApp('vue-search-app', SearchApp);
mountVueApp('vue-publications-app', PublicationsApp);
mountVueApp('vue-dashboard-app', DashboardApp);
mountVueApp('vue-dashboard-trade-flows-app', DashboardTradeFlowsApp);
mountVueApp('vue-navigation-app', NavigationApp);
mountVueApp('vue-subscriptions-app', SubscriptionsApp);
mountVueApp('vue-news-app', NewsApp);
mountVueApp('vue-dashboard-search-app', DashboardSearchApp);
mountVueApp('vue-dashboard-news-app', DashboardNewsApp);
mountVueApp('vue-dashboard-publications-app', DashboardPublicationsApp);
mountVueApp('vue-show-news-app', ShowNewsApp);
mountVueApp('vue-show-publication-app', ShowPublicationApp);
mountVueApp('vue-show-product-group-report-app', ShowProductGroupReportApp);
mountVueApp('vue-multi-line-chart-configuration-app', MultiLineChartConfigurationApp);
mountVueApp('vue-multi-line-chart-view-app', MultiLineChartViewApp);
mountVueApp('vue-video-tutorials-app', VideoTutorialsApp);
mountVueApp('vue-notation-analyses-app', NotationAnalysesApp);
mountVueApp('vue-season-line-chart-configuration-app', SeasonLineChartConfigurationApp);
mountVueApp('vue-season-line-chart-view-app', SeasonLineChartViewApp);
mountVueApp('vue-dashboard-overview-export-app', DashboardOverviewExportApp);
mountVueApp('vue-country-risk-map-app', CountryRiskMapApp);
mountVueApp('vue-commodity-risk-map-app', CommodityRisksMapApp);
mountVueApp('vue-country-portraits-app', CountryPortraitsApp);
mountVueApp('vue-sector-portraits-app', SectorPortraitsApp);

import HumanRightsRisks from '@/components/human-rights-risks'
mountVueApp('human-rights-risks', HumanRightsRisks)

import SimpleAutocomplete from '@/components/SimpleAutocomplete'
mountVueApp('simple-autocomplete', SimpleAutocomplete)

import Icon from '@/components/generic/Icon'
mountVueApp('icon', Icon)

import Modal from '@/components/generic/Modal'
mountVueApp('modal-dialog', Modal)

// If a link or form with data-remote has data-modal set, we will
// open the response in a modal. The content of the data-modal property
// will be passed as additional classes to the modal.
// Example:
//  <a href="/some-path" data-remote data-modal="is-wide">Open in modal</a>
//
document.addEventListener('ajax:success', (event)=>{
  const [,,{ responseText, status }] = event.detail
  if (event.target.dataset.hasOwnProperty("modal")) {
    Modal.open(responseText, {class: event.target.dataset.modal})
  } else if (status >= 200 && status < 300) {
    Modal.close()
  }
})

import { WithTooltip } from '@/components/generic';
mountVueApp('with-tooltip', WithTooltip)

import SupplyChainTable from '@/components/SupplyChainTable'
mountVueApp('supply-chain-table', SupplyChainTable)

import NestedForms from '@/helpers/NestedForms'
NestedForms.install()

import Teaser from '@/components/generic/Teaser'
mountVueApp('teaser', Teaser)

import PrintIntro from '@/components/generic/PrintIntro'
mountVueApp('print-intro', PrintIntro)

/**
 * If a link with data-open-modal is clicked, we're opening a modal dialog with
 * ModalWindowController
 */
import ModalWindowController from '@/controllers/ModalWindowController';
document.addEventListener("click", (event)=>{
  if (event.target.dataset.openModal) {
    event.preventDefault()
    let modalProps = {}
    if (event.target.dataset.modalProps) {
      modalProps = JSON.parse(event.target.dataset.modalProps)
    }
    ModalWindowController.show(event.target.dataset.openModal, { props: modalProps })
  }
})

// Close modal dialogs before navigating to a new page
document.addEventListener("turbolinks:before-cache", () => {
  ModalWindowController.close()
})

// Keep track of pending axios requests.
window.pendingAxiosRequests = 0;
axios.interceptors.request.use((config) => {
  window.pendingAxiosRequests += 1;
  return config;
});
axios.interceptors.response.use((response) => {
  window.pendingAxiosRequests -= 1;
  return response;
}, (error) => {
  window.pendingAxiosRequests -= 1;
  throw error;
});

// Encode query params the way rails expects them.
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => Qs.stringify(params, {
    arrayFormat: 'brackets',
    skipNulls: true,
  });

  return config;
});

require('trix');

// Workaround while Direct Upload is not supported by SetOps:
// Use custom upload logic instead of hook set by @rails/actiontext.

// require('@rails/actiontext');
// eslint-disable-next-line no-restricted-globals
addEventListener('trix-attachment-add', onTrixAttachmentAdd);

require('trix');
require('@rails/actiontext');
