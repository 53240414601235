const HostUrl = window.location.origin;

export const Root = () => HostUrl;

export const ShowProductGroupReport = (id) => `${HostUrl}/product-group-reports/${id}`;

export const ShowPublication = (id) => `${HostUrl}/publications/${id}`;

export const ShowNews = (id) => `${HostUrl}/news/${id}`;

export default {
  Root,
  ShowProductGroupReport,
  ShowPublication,
  ShowNews,
};
