<template>
  <div class="dropdown-footer-tooltip">
    <div class="dropdown-footer-tooltip__icon">
      <Icon
        name="info-circle"
        variant="regular"
      />
    </div>
    <div class="dropdown-footer-tooltip__name">
      <slot />
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">
.dropdown-footer-tooltip {
  display: flex;
  border-top: 1px solid $color-border-near-light;
  padding: $space-s;
  color: $color-text-near-dark;
  @include font-size('14/20');

  &__name {
    margin-left: $space-s;
    font-weight: 300;
  }
}
</style>
