<template>
  <div class="form--box">
    <label
      v-if="label"
      class="form--label"
      :for="uid"
    >
      {{ label }}

      <IconTooltipComponent
        v-if="tooltip"
        :tooltip="tooltip"
      />
    </label>

    <select
      :id="uid"
      ref="input"
      class="form--select"
      :value="modelValue"
      :placeholder="placeholder"
      :name="name"
      :required="required"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus', $event)"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="option.id == modelValue"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';

export default {
  name: 'SelectInput',
  mixins: [HasUid],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'focus',
  ],
  watch: {
    options(newOptions) {
      this.onOptionsChanged(newOptions);
    },
  },
  mounted() {
    this.onOptionsChanged(this.options);
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onOptionsChanged(newOptions) {
      // When no value is selected the browsers select element picks the first
      // one, so we want to reflect that to the component user transparently.
      if (this.modelValue === null && newOptions.length > 0) {
        this.$emit('update:modelValue', newOptions[0].id);
      }
    },
  },
};
</script>
