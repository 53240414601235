<template>
  <ul class="page-header--action-list">
    <li class="page-header--action-item">
      <ButtonComponent
        icon="newspaper"
        @click="editorialController.showCreateNewsModal()"
      >
        {{ $t('apps.publications.header_actions.create_news') }}
      </ButtonComponent>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NewsHeaderActions',
  props: {
    editorialController: {
      type: Object,
      required: true,
    },
  },
};
</script>
