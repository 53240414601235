<template>
  <ul class="kpi-list">
    <li class="kpi-list--latest-date">
      {{ formattedLatestDate }}
    </li>
    <li
      v-if="showAbsolutePrices"
      class="kpi-list--latest-value"
    >
      {{ formattedLatestValue }}
    </li>
    <li
      v-for="kpi in kpiList"
      :key="kpi.title"
      class="kpi-list--item"
      :aria-label="kpi.title"
    >
      <KeyPerformanceIndicator
        v-if="showAbsolutePrices"
        :value="kpi.value"
        :base-value="kpi.base"
        :base-value-precision="baseValuePrecision"
      />
      <KeyPerformanceIndicator
        v-else
        :value="kpi.value"
      />
    </li>

    <li class="kpi-list--item">
      <ValueRangeRibbon
        class="kpi-list--value-range-ribbon"
        :value="currentValueRange"
      />
    </li>
  </ul>
</template>

<script>
import KeyPerformanceIndicator from '@/components/KeyPerformanceIndicator/Component.vue';
import ValueRangeRibbon from '@/components/ValueRangeRibbon/Component.vue';
import { formatGradualPrecision } from '@/helpers/NumberFormats';
import { formatCompactDateWithTimescope } from '@/helpers/DateFormats';

export default {
  name: 'NotationDetailsKpiList',
  components: {
    KeyPerformanceIndicator,
    ValueRangeRibbon,
  },
  props: {
    timescope: {
      type: String,
      default: null,
    },
    keyPerformanceIndicators: {
      type: Object,
      default: () => {},
    },
    baseValuePrecision: {
      type: Number,
      default: 2,
    },
    latestValue: {
      type: String,
      required: true,
    },
    latestDate: {
      type: String,
      required: true,
    },
    currentValueRange: {
      type: String,
      required: true,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    kpiList() {
      const visibleKPIs = ['last_year', 'last_month', 'last_week', 'last_day'];
      const result = visibleKPIs.map((kpi) => ({
        title: this.$t(`components.notation_kpi_list.${kpi}`),
        tooltip: this.$t(`components.notation_kpi_list.tooltips.${kpi}`),
        value: Number.parseFloat(this.keyPerformanceIndicators[kpi]?.growth),
        base: Number.parseFloat(this.keyPerformanceIndicators[kpi]?.value),
      }));
      return result;
    },
    formattedLatestDate() {
      return formatCompactDateWithTimescope(this.latestDate, this.timescope);
    },
    formattedLatestValue() {
      const num = Number.parseFloat(this.latestValue);
      if (Number.isNaN(num)) {
        return 'N/A';
      }
      return formatGradualPrecision(num);
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>