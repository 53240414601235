<template>
  <span
    class="notation-color-icon"
    :class="slotClass"
  />
</template>

<script>
const SlotKinds = ['primary', 'secondary'];

export default {
  name: 'NotationSlotIcon',
  props: {
    slotKind: {
      type: String,
      required: true,
      validator: (value) => SlotKinds.includes(value),
    },
  },
  computed: {
    slotClass() {
      return `is-${this.slotKind}`;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>