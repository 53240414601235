<template>
  <td
    class="data-table--body-cell is-right-aligned"
    v-on="eventListeners"
  >
    <SpinningLoader
      v-if="isLoading"
      :text="null"
    />
    <span
      v-else-if="formattedCorrelation"
      class="correlation-column"
      :class="cssModifier"
    >
      {{ formattedCorrelation }}
    </span>
  </td>
</template>

<script>
import NotationApi from '@/api/NotationApi';
import ForecastService from '@/services/Forecast/Service';

export default {
  name: 'CorrelationColumn',
  props: {
    value: {
      type: Object,
      required: true,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      primaryQuotes: null,
      secondaryQuotes: null,
    };
  },
  computed: {
    X() {
      if (!this.primaryQuotes) return null;
      return this.primaryQuotes.map((q) => ({ x: q.date, y: q.value }));
    },
    Y() {
      if (!this.secondaryQuotes) return null;
      return this.secondaryQuotes.map((q) => ({ x: q.date, y: q.value }));
    },
    correlation() {
      if (!this.X || !this.Y) {
        return null;
      }
      try {
        const correlations = ForecastService.correlation({
          X: this.X,
          Y: this.Y,
          timescopeX: this.value.primaryTimescope,
          timescopeY: this.value.secondaryTimescope,
        });
        const index = this.value.timeShift - 1;
        return correlations[index];
      } catch (error) {
        return null;
      }
    },
    formattedCorrelation() {
      const num = Number.parseFloat(this.correlation);
      if (Number.isNaN(num)) {
        return null;
      }
      const number = this.$n(num, 'decimal', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return num > 0 ? `+${number}` : number;
    },
    cssModifier() {
      const confidence = ForecastService.Confidence
        .forCorrelation(this.correlation);
      switch (confidence) {
        case ForecastService.Confidence.High: return 'is-high-confidence';
        case ForecastService.Confidence.Medium: return 'is-medium-confidence';
        default: return 'is-low-confidence';
      }
    },
    hasValidTimescopes() {
      const ValidTimescopes = ['daily', 'weekly', 'monthly'];
      return ValidTimescopes.includes(this.value.primaryTimescope)
          && ValidTimescopes.includes(this.value.secondaryTimescope);
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (!this.hasValidTimescopes) {
          this.primaryQuotes = null;
          this.secondaryQuotes = null;
          return;
        }
        this.updateQuotes();
      },
    },
  },
  methods: {
    async updateQuotes() {
      this.isLoading = true;
      try {
        this.primaryQuotes = await NotationApi()
          .quotes(this.value.primaryNotationId)
          .then((quotes) => quotes?.values);
        this.secondaryQuotes = await NotationApi()
          .quotes(this.value.secondaryNotationId)
          .then((quotes) => quotes?.values);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>