document.addEventListener('turbolinks:load', () => {
  const userMenuToggleEl = document.querySelector('[data-user="toggle"]');
  let userMenuEl;

  if (userMenuToggleEl) {
    userMenuEl = document.querySelector('[data-user="menu"]');

    const documentClickListener = (event) => {
      if (!userMenuEl.contains(event.target) && !userMenuToggleEl.contains(event.target)) {
        userMenuEl.classList.remove('is-open');
        document.removeEventListener('click', documentClickListener);
      }
    };

    userMenuToggleEl.addEventListener('click', (e) => {
      e.preventDefault();

      if (userMenuEl.classList.contains('is-open')) {
        userMenuEl.classList.remove('is-open');
        document.removeEventListener('click', documentClickListener);
      } else {
        userMenuEl.classList.add('is-open');
        document.addEventListener('click', documentClickListener);
      }
    });
  }

  // Links to legal pages open in a new tab, which means that the
  // user menu will not get closed automatically. Instead we close
  // the menu manually when a link in the footer gets clicked.
  const userMenuFooterLinks = document.querySelectorAll('.app-header--user-menu-footer-link a');
  userMenuFooterLinks.forEach((elem) => {
    elem.addEventListener('click', () => {
      if (userMenuEl) {
        userMenuEl.classList.remove('is-open');
      }
    });
  });

  const dashboardMenuEl = document.querySelector('[data-dashboard="menu"]');
  const dashboardMenuToggleEl = document.querySelector('[data-dashboard="toggle"]');

  const closeDashboardDropdownOnClickOutside = (event) => {
    if (!dashboardMenuEl.contains(event.target) && !dashboardMenuToggleEl.contains(event.target)) {
      dashboardMenuEl.classList.remove('is-open');
      document.removeEventListener('click', closeDashboardDropdownOnClickOutside);
    }
  };

  if (dashboardMenuToggleEl) {
    dashboardMenuToggleEl.addEventListener('click', () => {
      if (dashboardMenuEl.classList.contains('is-open')) {
        dashboardMenuEl.classList.remove('is-open');
        document.removeEventListener('click', closeDashboardDropdownOnClickOutside);
      } else {
        dashboardMenuEl.classList.add('is-open');
        document.addEventListener('click', closeDashboardDropdownOnClickOutside);
      }
    });
  }
});
