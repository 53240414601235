<template>
  <button class="autocomplete--item-link">
    <i class="autocomplete--icon fal fa-tag fa-fw" />
    <span
      v-for="(term, index) in highlights"
      :key="index"
      class="autocomplete--text"
      :class="{'is-highlighted': term.highlight}"
    >{{ term.text }}</span>
  </button>
</template>

<script>
export default {
  name: 'AutoCompleteItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    highlights() {
      const DELIMITER = '**';
      const pattern = new RegExp(`(${this.escapeRegExp(this.query)})`, 'ig');
      const highlightedName = this.item.name
        .replace(pattern, (match) => `${DELIMITER}${match}${DELIMITER}`);
      const highlights = highlightedName.split(DELIMITER)
        .map((term, index) => ({ text: term, highlight: index % 2 === 1 }));
      return highlights;
    },
  },
  methods: {
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
  },
};
</script>
