<script>
import TableHeadCell from "@/components/NotationList/NotationTable/TableHeadCell";
import { compareTableValues, onColumnSortClick } from "@/helpers/SortHelpers";

import CellComponent from "./CellComponent"
import RowComponent from "./RowComponent"

export default {
  components: {
    TableHeadCell,
    CellComponent,
    RowComponent
  },

  props: {
    collection: {
      type: Array,
      default: [],
    },

    defaultSort: {
      type: Function
    },

    columnDefinitions: {
      type: Array,
    },

    showGroupExpander: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      sortedCollection: this.collection,
      sorting: {
        column: null,
        order: null,
      },
    };
  },

  computed: {
    visibleColumnDefinitions() {
      return this.columnDefinitions.filter((columnDefinition) => columnDefinition.state.visible)
    }
  },

  watch: {
    sorting: {
      deep: true,
      handler(newSorting, oldSorting) {
        // reset order flag for previous column
        if (oldSorting.column && oldSorting.column != newSorting.column) {
          oldSorting.column.sortOrder = null
        }

        this.sortedCollection = this.sortCollection(this.sortedCollection)
      },
    },
  },

  methods: {
    sortCollection(collection) {
      return compareTableValues(
        collection,
        (item) => {
          // apply same sort also to children if there are any
          if (item.children?.length) {
            item.children = this.sortCollection(item.children)
          }

          if (this.sorting.column) {
            // sorting by an explicit column
            return this.sorting.column.sortValue(item);
          }
          if (this.defaultSort) {
            // reset sorting if a callback is specified
            return this.defaultSort(item)
          }
          // fallback to sorting by the first column
          return this.columnDefinitions[0].columns[0].sortValue(item)
        },
        this.sorting.order
      );
    },

    visibilityStatusAllGroups(visibility) {
      return this.columnDefinitions.every((columnGroup)=>{
        return !columnGroup.expandable || columnGroup.expanded == visibility
      })
    },

    expandAll(isVisible) {
      this.columnDefinitions.forEach((columnGroup)=>{
        if (!columnGroup.expandable) { return }
        columnGroup.expanded = isVisible
      })
      if (!isVisible) {
        this.scrollToStart()
      }
    },

    setColumnGroupExpanded(columnGroup, isExpanded, options={}) {
      columnGroup.expanded = isExpanded;

      if (options.scroll) {
        this.$nextTick(()=>{
          this.scrollToColumnGroup(columnGroup)
        })
      }
    },

    scrollToColumnGroup(columnGroup) {
      let scrollContainer = document.querySelector('.app-main--content')
      let node = this.$refs[`column-group-${columnGroup.uuid}`]
      if (!node[0]) { return }

      let leftBound = scrollContainer.scrollLeft || 0
      let rightBound = leftBound + scrollContainer.offsetWidth
      let outOfViewRight = node[0].offsetLeft + node[0].offsetWidth > rightBound
      let outOfViewLeft = node[0].offsetLeft + node[0].offsetWidth < leftBound
      if (outOfViewRight || outOfViewLeft) {
        scrollContainer.scrollTo(node[0].offsetLeft, scrollContainer.scrollTop || 0)
      }
    },

    scrollToStart() {
      let scrollContainer = document.querySelector('.app-main--content')
      scrollContainer.scrollTo(0, scrollContainer.scrollTop || 0)
    },

    sortColumnClicked(clickedColumn) {
      let { order, column } = onColumnSortClick({
        clickedColumn: clickedColumn,
        currentColumn: this.sorting.column,
        currentOrder: this.sorting.order,
        compare: (col) => col?.uuid,
      });

      if (column) {
        column.sortOrder = order;
      } else {
        clickedColumn.sortOrder = order;
      }
      this.sorting = { column, order };
    },
  },
};
</script>

<template>
  <div class="sticky-section full-vw sticky-vertical" v-if="showGroupExpander">
    <a
      :class="['expander expand-all', { expanded: visibilityStatusAllGroups(true) }]"
      @click="expandAll(true)"
    >{{ $t("apps.expandable_column_table.show_all_groups") }}</a>
    <a
      :class="['expander expand-all', { expanded: visibilityStatusAllGroups(false) }]"
      @click="expandAll(false)"
    >{{ $t("apps.expandable_column_table.hide_all_groups") }}</a>
    <br>
    <template
      v-for="(columnGroup, groupIndex) of visibleColumnDefinitions"
      :key="`expander-${columnGroup.uuid}`"
    >
      <a
        :class="[`expander expander-${groupIndex}`, { expanded: columnGroup.expanded }]"
        v-if="columnGroup.expandable"
        @click="setColumnGroupExpanded(columnGroup, !columnGroup.expanded, { scroll: !columnGroup.expanded })"
        v-html="columnGroup.label"
      ></a>

    </template>
  </div>
  <div class="expandable-columns-table-wrapper">
    <table class="data-table expandable-columns-table">
      <thead class="data-table--head data-table--sticky-margin" :class="{'with-expander': showGroupExpander}">
        <tr>
          <template v-for="(columnGroup, groupIndex) of visibleColumnDefinitions" :key="`group-${columnGroup.uuid}`">
            <td
              :class="[
                'column-group-header',
                `column-group-header-${groupIndex}`,
                { 'is-centered': !columnGroup.expanded },
              ]"
              :ref="`column-group-${columnGroup.uuid}`"
              :colspan="columnGroup.expanded ? columnGroup.columns.length : columnGroup.shortColumnCount"
            >
              <a
                v-if="showGroupExpander && columnGroup.expandable"
                class="expand-control"
                @click="setColumnGroupExpanded(columnGroup, !columnGroup.expanded)"
              >
                <Icon
                  class="column-group-toggle"
                  :name="columnGroup.expanded ? 'minus-square' : 'plus-square'"
                  variant="regular"
                  size="lg"
                /><span v-if="columnGroup.expanded" class="ml-xs" v-html="columnGroup.label"></span>
              </a>
              <span v-else class="expand-control-fallback">{{ columnGroup.label }}</span>
            </td>
          </template>
        </tr>

        <tr>
          <template v-for="(columnGroup, groupIndex) of visibleColumnDefinitions" :key="`head-${columnGroup.uuid}`">
            <template v-if="columnGroup.expanded">
              <TableHeadCell
                v-for="(column, columnIndex) in columnGroup.columns"
                :key="`head-${column.uuid}`"
                :title="column.header"
                :is-sortable="column.sortable"
                :sort-direction="column.sortOrder"
                :tooltip="column.tooltip"
                @click="sortColumnClicked(column)"
                :class="`group-${groupIndex} group-column column-${columnIndex}`"
                linkTag="a"
              ></TableHeadCell>
            </template>
            <TableHeadCell
              v-else
              :colspan="columnGroup.shortColumnCount"
              :title="columnGroup.label"
              :is-sortable="columnGroup.shortSortableColumn.sortable"
              :sort-direction="columnGroup.shortSortableColumn.sortOrder"
              @click="sortColumnClicked(columnGroup.shortSortableColumn)"
              :class="`group-${groupIndex} group-column column-0`"
              linkTag="a"
            />
          </template>
        </tr>
      </thead>

      <tbody>
        <RowComponent
          v-for="record of sortedCollection"
          :key="`item-${record.id}`"
          :record="record"
          :columnDefinitions="visibleColumnDefinitions"
        >
        </RowComponent>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
$expanders-size: 20px;
$expanders-spacing: 5px;
$border-color: #b0bbcc;

html[media=print] {
  .expert-mode-toggle {
    display: none;
  }
  .expandable-columns-table {
    width: 100%;
    font-size: 80%;
    table-layout: initial;
    margin-bottom: 30px;

    .hr-score img, .flags-cell-content img, .flags-cell-content .flag-spacer {
      width: 8px;
    }

    .column-group-header {
      background: none;
    }

    .data-table--head-cell {
      &:first-child { display: table-cell; }
      &:not(:last-child) {
        padding-right: 1px;
        box-shadow: inset 0 -2px $color-border-near-dark, inset 0 1px $color-border-near-light, inset -1px 0 $color-border-muted;
      }

      &:last-child {
        box-shadow: inset 0 -2px $color-border-near-dark, inset 0 1px $color-border-near-light,;
      }
    }

    .data-table--body-cell {
      &:first-child { display: table-cell; }
      &:not(:last-child) {
        border-right: 1px solid #b0bbcc;
      }
      font-size: 80%;
      max-width: 200px;
      border-bottom: 1px solid #dde3ed;
      padding: 5px;
      font-weight: 300;
    }

    // Rotated header cells
    // Puppeteer does not render table headers on every page if they
    // are rendered using "writing-mode". To get around that, we're using
    // transform and rotate in combination with the padding hack for
    // sizing/positioning the labels. Based on a solution here:
    // https://stackoverflow.com/a/47860039
    .center-rotated-wrapper {
      max-width: 20px;
      margin: auto;
    }
    .rotation-wrapper-outer {
      display: table;
      margin: auto;
    }
    .rotation-wrapper-inner {
      padding: 50% 0;
      height: 0;
    }
    .data-table--head-cell-content-print {
      display: block;
      transform-origin: top left;
      transform: rotate(-90deg) translate(-100%);
      margin-top: -50%;
      line-height: 0;
      white-space: nowrap;
    }
  }
}

.expandable-columns-table {
  table-layout: fixed;
  width: auto;
  min-width: 100%;
  box-sizing: content-box;

  // thick border at the beginning of each group
  .column-0 {
    border-left: 2px solid $border-color;
  }

  // thick border at the end
  .group-column:last-child {
    border-right: 3px solid $border-color;
  }

  thead th {
    // rotate header cells
    vertical-align: bottom;

    a {
      writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      text-decoration: none;
      transform: rotate(180deg);
      margin: auto;
      width: auto;

      &.is-clickable .data-table--head-cell-title {
        margin-bottom: 5px;
      }
    }
  }

  // limit normals cells to a max width and a single line to get a clean
  // overall table look
  tbody .default-cell {
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: auto;
  }
}

.column-group-header {
  background-color: $body-background;
  border-left: 2px solid $border-color;
  border-right: 1px solid $border-color;
  &:last-child {
    border-right: 3px solid $border-color;
  }
  .expand-control,
  .expand-control-fallback {
    padding: 8px;
    opacity: 0.7;
    display: block;
  }
  a.expand-control:hover {
    opacity: 1;
  }
  padding-bottom: 8px;
  position: relative;

  // use :after to render a virtual border to avoid diagonal border issues at the edges
  &:after {
    content: "";
    display: block;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

$colors: #accbc3, #bbcbe1, #fecdba, #f6b6c2, #9fcfca, #d4d2e1, #fedfbf, #d7d7d7, #f4cbcf, #e6f0f0;

@for $i from 1 through length($colors) {
  $color: nth($colors, $i);
  // Add a thick, colored border below each column group header
  .column-group-header.column-group-header-#{$i - 1}:after {
    background-color: $color;
  }

  // expand/collapse controls with matching colors
  .sticky-section .expander-#{$i - 1} {
    border-bottom: 3px solid $color;
    &:before {
      color: $color;
    }
  }
}

.expandable-columns-table .data-table--head {
  top: 0;
  &.with-expander {
    top: 65px;
  }
}

$sticky-section-outer-spacing: 20px;
.sticky-section {
  position: sticky;
  left: $sticky-section-outer-spacing;
  z-index: 11;
  width: 100%;
  max-width: 100%;

  @media print {
    & {
      max-width: 100% !important;
    }
  }

  &.full-vw {
    width: calc(100vw - (2*$sticky-section-outer-spacing));
    max-width: calc(100vw - (2*$sticky-section-outer-spacing));
  }

  &.sticky-vertical {
    z-index: 10;
    height: fit-content;

    top: 0;
    padding-top: 20px;
    margin-top: -20px;
    padding-bottom: 13px;
    background-color: $body-background;

    // add some squares on the left and right to avoid the table
    // to scroll below the sticky element
    &:before, &:after {
      background-color: $body-background;
      position: absolute;
      top: 0;
      bottom: 0;
      width: $sticky-section-outer-spacing;
      content: "";
    }
    &:before {
      left: -$sticky-section-outer-spacing;
    }
    &:after {
      right: -$sticky-section-outer-spacing;
    }
  }

  .expand-all {
    margin-bottom: 10px;
  }
  .expander {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 0.9rem;

    //disabled
    opacity: 0.3;
    &:hover {
      text-decoration: none;
      opacity: 0.5;
    }
    &.expanded {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    &:before {
      content: "●";
      padding-right: 5px;
    }
  }
}
.page-wrapper {
  width: fit-content; // required for sticky table tools
}
</style>
