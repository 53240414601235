<template>
  <div
    class="custom-loading-spinner"
  >
    <Icon
      class="custom-loading-spinner-icon"
      name="spinner"
      :spin="true"
      size="lg"
    />
    <p class="custom-loading-spinner__text">
      {{ t('components.search_results.is_loading') }}
    </p>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<style lang="scss">
.custom-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  &__text {
     margin-left: 10px;
   }
}
</style>
