import * as d3 from 'd3';
import moment from 'moment';

const aggregateArray = (data, groupBy, aggregateBy) => {
  const withGroupKey = data.map((d) => ({ key: groupBy(d), ...d }));
  const grouped = withGroupKey.reduce((groups, d) => {
    groups[d.key] = groups[d.key] || [];
    groups[d.key].push(d);
    return groups;
  }, {});
  const aggregated = Object.keys(grouped).map((key) => aggregateBy(grouped[key]));
  return aggregated;
};

const averageXY = (samples) => ({
  x: d3.mean(samples, (d) => d.x),
  y: d3.mean(samples, (d) => d.y),
});

const groupByFormat = (format) => (sample) => moment(sample.x).format(format);

const resampleTimeseries = (data, format) => aggregateArray(data, groupByFormat(format), averageXY);

const defaultGraphColors = () => ([
  'rgb(221, 0, 41)',
  'rgb(115, 114, 114)',
  'rgb(140, 0, 25)',
  'rgb(217, 150, 148)',
  'rgb(180, 180, 180)',
]);

const valuesForYAxis = (axis, datasets, graphLimits) => {
  const datasetsForAxis = datasets.filter((dataset) => dataset.yAxisID === axis);
  const quotesForAxis = datasetsForAxis.map((dataset) => dataset.data).flat()
    .filter((quote) => moment(quote.x) > moment(graphLimits.startDate)
      && moment(quote.x) < moment(graphLimits.endDate));
  return quotesForAxis.map((quote) => quote.y);
};

export {
  aggregateArray,
  resampleTimeseries,
  defaultGraphColors,
  valuesForYAxis,
};
