/**
 * Sets the minimal radius size.
 *
 * @remarks
 * This function takes a radius value and checks if it is smaller than the minimal radius. If so, it returns the minimal radius; otherwise, it returns the provided radius value.
 *
 * @param radius - The radius value to be checked.
 * @returns The minimal radius value.
 *
 * @example
 * ```javascript
 * const setMinimalRadiusSize = (radius, minimalRadius = 10) => {
 *   return radius < minimalRadius ? minimalRadius : radius;
 * };
 *
 * const radius = 3;
 * const result = setMinimalRadiusSize(radius); // returns 5
 * ```
 *
 */

export const setMinimalRadiusSize = (radius, minimalRadius = 4) => {
  if (radius < minimalRadius) {
    return minimalRadius;
  }
  return radius;
};

export default setMinimalRadiusSize;
