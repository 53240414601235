<template>
  <div
    ref="root"
    v-click-outside="hideOptions"
    tabindex="-1"
    class="combobox"
    :class="{'combobox--full-width': isFullWidth, 'is-open': isOpen && !disabled}"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`option-list-${uid}`"
    @keydown.up.down="$refs.input.focus()"
  >
    <div
      class="combobox--default-button"
      :class="{'form--is-disabled': disabled}"
    >
      <input
        :id="id"
        ref="input"
        :value="modelValue"
        type="text"
        class="combobox--input"
        role="searchbox"
        aria-autocomplete="list"
        :aria-label="label"
        :aria-controls="`option-list-${uid}`"
        :aria-expanded="isOpen"
        :aria-labelledby="ariaLabelledby"
        aria-multiline="false"
        :placeholder="modelValue === null ? placeholder : modelValue"
        :required="required"
        :disabled="disabled"
        autocomplete="off"
        @input="onInputChanged"
        @focus="showOptions"
        @blur="onBlur"
        @keydown.up.prevent="focusPrevious"
        @keydown.down.prevent="focusNext"
        @keydown.enter.prevent="onEnter"
      >
      <button
        type="button"
        :class="iconClass"
        :aria-label="iconLabel"
        @click="onIconClicked"
      >
        <Icon
          :name="iconName"
          variant="regular"
          :fixed-width="true"
        />
      </button>
    </div>

    <div
      v-if="isOpen && !disabled"
      class="combobox--popup"
    >
      <ul
        :id="`option-list-${uid}`"
        ref="options"
        class="combobox--options-list"
        :class="{'is-small': isSmall}"
        role="listbox"
        :aria-activedescendant="`option-${uid}-${focussedKey}`"
      >
        <li
          v-for="option in filteredOptions"
          :key="`option-${option}`"
        >
          <button
            :id="`option-${uid}-${option}`"
            :ref="getRefNameForKey(option)"
            type="button"
            class="combobox--option"
            :class="{'has-focus': option === focussedKey}"
            :aria-labelledby="ariaLabelledby"
            :aria-selected="option === focussedKey"
            role="option"
            @mousemove="focusOption(option)"
            @click="selectOption(option)"
          >
            <slot
              name="option"
              :option="option"
            >
              <ComboBoxOption>
                {{ option }}
              </ComboBoxOption>
            </slot>
          </button>
        </li>
      </ul>
      <div
        v-if="$slots.footer"
        class="combobox--footer"
      >
        <slot
          name="footer"
          :focussed-option="focussedOption"
          :current-option="modelValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';
import scrollToElement from '@/helpers/ScrollToElement';

export default {
  name: 'AutoCompleteFreeText',
  mixins: [HasUid],
  props: {
    id: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    allowEmptyValue: {
      type: Boolean,
      default: false,
    },
    filterFunction: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      focussedKey: this.modelValue,
    };
  },
  computed: {
    focussedOption() {
      return this.filteredOptions.find((o) => o === this.focussedKey) || null;
    },
    hasLockedValue() {
      return this.modelValue && !this.isOpen;
    },
    iconName() {
      if (!this.allowEmptyValue) {
        return 'chevron-down';
      }
      return this.hasLockedValue ? 'times' : 'search';
    },
    iconClass() {
      return this.allowEmptyValue ? 'combobox--icon' : 'combobox--caret';
    },
    iconLabel() {
      if (!this.allowEmptyValue) {
        return this.$t('components.generic.autocomplete.toggle');
      }
      return this.hasLockedValue
        ? this.$t('components.generic.autocomplete.clear')
        : this.$t('components.generic.autocomplete.focus');
    },
    defaultFilter() {
      return (input, option) => {
        const value = option;
        return input === null || value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      };
    },
    actualFilter() {
      return this.filterFunction || this.defaultFilter;
    },
    filteredOptions() {
      return this.options.filter((o) => this.actualFilter(this.modelValue, o));
    },
  },
  watch: {
    focussedOption() {
      this.$nextTick(() => this.adjustScrollPosition());
    },
    filteredOptions() {
      const focussedIndex = this.filteredOptions.findIndex(
        (o) => o === this.focussedKey,
      );
      if (focussedIndex === -1) {
        const option = this.filteredOptions[0];
        this.focussedKey = option || null;
      }
    },
  },
  methods: {
    getRefNameForKey(key) {
      return `option-${key}`;
    },
    adjustScrollPosition() {
      const focusedRef = this.getRefNameForKey(this.focussedKey);
      const focusItem = this.$refs[focusedRef];
      if (!focusItem) return;
      scrollToElement(this.$refs.options, focusItem);
    },
    showOptions() {
      this.focussedKey = this.modelValue;
      this.isOpen = true;
    },
    hideOptions() {
      this.isOpen = false;
      this.$refs.input.blur();
    },
    hideOptionsAndFocus() {
      this.hideOptions();
      this.$nextTick(() => this.$refs.root.focus());
    },
    selectOption(option) {
      this.$emit('update:modelValue', option);
      this.$nextTick(() => this.hideOptionsAndFocus());
    },
    focusOption(option) {
      if (!option) return;
      this.focussedKey = option;
    },
    focusPrevious() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => o === this.focussedKey,
      );
      const nextIndex = Math.max(0, currentIndex - 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    focusNext() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => o === this.focussedKey,
      );
      const nextIndex = Math.min(this.filteredOptions.length - 1, currentIndex + 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    onEnter() {
      if (this.focussedOption) {
        this.selectOption(this.focussedOption);
      }
      this.$nextTick(() => this.hideOptionsAndFocus());
    },
    onBlur(event) {
      // only hide the popup if the new focus element is not a descendant
      // of the root element
      const newFocusElement = event.relatedTarget
                           || event.explicitOriginalTarget
                           || document.activeElement;
      if (!this.$refs.root.contains(newFocusElement)) {
        this.hideOptions();
      }
    },
    onIconClicked() {
      if (!this.allowEmptyValue) {
        this.toggleOptionsAndFocus();
      } else {
        this.focusAndShowOptions();
      }
    },
    toggleOptionsAndFocus() {
      if (this.isOpen) {
        this.hideOptionsAndFocus();
      } else {
        this.focusAndShowOptions();
      }
    },
    focusAndShowOptions() {
      this.$refs.input.focus();
    },
    onInputChanged(event) {
      if (this.disabled) return;
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
