<template>
  <table class="subscriptions--table">
    <SubscriptionsTableHead />
    <SubscriptionsTableSection
      :subscriptions="generalSubscriptions"
      @set-interval="setInterval"
    />
    <SubscriptionsTableSection
      :subscriptions="publicationSubscriptions"
      @set-interval="setInterval"
    />
    <SubscriptionsTableSection
      :subscriptions="publicDashboardSubscriptions"
      :removable-rows="true"
      @set-interval="setInterval"
    />
    <SubscriptionsTableSection
      :subscriptions="privateDashboardSubscriptions"
      :removable-rows="true"
      @set-interval="setInterval"
    />
  </table>
</template>

<script>
import SubscriptionsTableHead from '../SubscriptionsTableHead/Component.vue';
import SubscriptionsTableSection from '../SubscriptionsTableSection/Component.vue';

export default {
  name: 'SubscriptionsTable',
  components: {
    SubscriptionsTableHead,
    SubscriptionsTableSection,
  },
  props: {
    generalSubscriptions: {
      type: Array,
      default: () => [],
    },
    publicDashboardSubscriptions: {
      type: Array,
      default: () => [],
    },
    privateDashboardSubscriptions: {
      type: Array,
      default: () => [],
    },
    publicationSubscriptions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['set-interval'],
  methods: {
    setInterval(changeObject) {
      this.$emit('set-interval', changeObject);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>