/**
 * A ColumnGroup is a collection of columns that can be expanded and collapsed and
 * is used by ExpandableColumnsTable to group columns together.
 *
 * A can have a specific representation in the collapsed mode (e.g. custom value or
 * custom column(s)).
 *
 * Refer to components/SupplyChainTable/ColumnDefinitions.js for examples on how a
 * ColumnGroup can be configured.
 */

import uuid from '@/utils/uuid'
import { reactive } from 'vue';

import Column from './Column'
import ColumnGroupVisibilityStore from './ColumnGroupVisibilityStore'

export default class ColumnGroup {
  
  constructor({
    id,
    columns,
    expandable,
    expanded,
    label,
    shortValue,
    tooltipTitle,
    cellClasses,
    shortColumns,
    flags
  }) {
    this.id = id
    this.uuid = uuid()

    this.expandable = expandable
    if (expandable == undefined) {
      this.expandable = true
    }

    if (expanded == undefined) {
      expanded = ColumnGroupVisibilityStore.get(this.id)
    }

    this.state = reactive({
      expanded: expanded == undefined ? true : expanded,
      visible: true
    })

    this._label = label

    this.columns = columns || []
    this.shortValue = shortValue
    this.flags = flags
    this.tooltipTitle = tooltipTitle // check if still required
    this.cellClassesCallback = cellClasses
    this._shortColumns = shortColumns || [0]

    this.totalHrScoreColumnIndex = this.columns.findIndex((column) => {
      return column.constructor.className == "ScoreColumn" && column.isTotalDimension
    })
  }

  /**
   * Returns the total hr score column if it exists in the given columns
   */
  get totalHrScoreColumn() {
    if (this.totalHrScoreColumnIndex < 0) { return }
    return this.columns[this.totalHrScoreColumnIndex]
  }

  get label() {
    let label = this._label
    if (typeof label == "function") {
      label = label(this)
    }

    return label
  }

  cellClasses(columnIndex, record) {
    let classes = []
    if (this.cellClassesCallback) {
      classes.push(this.cellClassesCallback(record))
    }

    if (this.columns[columnIndex]?.cssClasses) {
      classes.push(this.columns[columnIndex]?.cssClasses(record))
    }

    return classes.join(' ')
  }

  shortLabel(record) {
    if (this.shortValue) {
      return this.shortValue(record) || '-'
    }
    return this.shortColumns[0].render(record) || '-'
  }

  get shortColumnCount(){
    return this._shortColumns.length
  }

  get shortColumns(){
    return this._shortColumns.map ((index)=>{
      return this.columns[index]
    })
  }

  get shortSortableColumn(){
    return this.columns[this._shortColumns[0]]
  }

  get expanded(){ return this.state.expanded }
  set expanded(value){
    ColumnGroupVisibilityStore.set(this.id, value)
    this.state.expanded = value
  }

}
