<template>
  <td :class="renderClasses(value)" v-on="eventListeners">
    <div v-if="value !== null && value.hasOwnProperty('context')">
      {{ value.value }} <span>({{ value.context }})</span>
    </div>
    <div v-if="value !== null && value.hasOwnProperty('context') === false">
        {{ value.notation || value }}
      </div>
  </td>
</template>

<script>
import { isString } from '@vue/shared';

export default {
  name: 'ValueWithContext',
  props: {
    value: {
      type: [Object, String],
      default: "",
    },
    eventListeners: {
      type: Object,
      default: {},
    },
  },
  methods: {
    renderClasses: (value) => {
      let classes = []
      classes.push('data-table--body-cell');

      if (isString(value)){
        classes.push('text-left');
      }
      return classes;
    }
  }
};
</script>
<style lang="scss" scoped>
.data-table--body-cell {
  text-align: right;
  span {
    font-size: 10px;
  }
  &.text-left {
    text-align: left;
  }
}
</style>
