<template>
  <div class="autocomplete">
    <ul class="search-register--index-list">
      <li
        v-for="item in controller.indices"
        :key="item"
        class="search-register--index"
        :class="{'is-selected': controller.currentLetter == item}"
      >
        <!-- eslint-disable vue/no-mutating-props -->
        <button
          class="search-register--index-button"
          @click="controller.currentLetter = item"
        >
          {{ item.toUpperCase() }}
        </button>
        <!-- eslint-enable vue/no-mutating-props -->
      </li>
    </ul>

    <ul
      ref="container"
      class="autocomplete--list"
    >
      <li
        v-if="controller.isLoading"
        key="loading"
      >
        <div class="autocomplete--item-link">
          <i class="autocomplete--icon fal fa-spinner fa-spin fa-fw" />
          <span class="autocomplete--text">
            {{ $t('components.search_registry.loading') }}
          </span>
        </div>
      </li>
      <li
        v-else-if="controller.currentEntries.length === 0"
        key="no-entries"
      >
        <div class="autocomplete--item-link">
          <i class="autocomplete--icon fal fa-exclamation-circle fa-fw" />
          <span class="autocomplete--text">
            {{ $t('components.search_registry.no_entries') }}
          </span>
        </div>
      </li>
      <li
        v-for="(item, index) in controller.currentEntries"
        v-else
        :key="item.id"
        class="autocomplete--item"
        :class="{'is-selected': index === controller.currentIndex}"
        @mousemove="controller.setCurrentIndexWithLimits(index)"
        @mousedown="$emit('submit')"
      >
        <AutoCompleteItem
          :item="item"
          :query="controller.currentQuery"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import AutoCompleteItem from './AutoCompleteItem.vue';

export default {
  name: 'SearchRegistry',
  components: {
    AutoCompleteItem,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit'],
  watch: {
    'controller.currentIndex': function adjustScrollForSelectedItem() {
      const { container } = this.$refs;
      const rowHeight = container.firstElementChild.clientHeight;
      const currentTop = this.controller.currentIndex * rowHeight;
      const currentBottom = currentTop + rowHeight;
      if (currentBottom > container.scrollTop + container.clientHeight) {
        container.scrollTop = currentBottom - container.clientHeight;
      } else if (currentTop < container.scrollTop) {
        container.scrollTop = currentTop;
      }
    },
  },
};
</script>
