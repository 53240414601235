import axios from "axios";

const fetchTradeFlowQuotes = (dashboardId, params) => {
  return axios
    .get(`/api/dashboards/${dashboardId}/trade_flows`, {
      params: {
        year: params.year,
        classification_id: params.classificationId,
        source: params.source,
        reporter_id: params.reporterId,
        partner_id: params.partnerId,
      },
    })
};

const fetchTradeFlowReporters = (dashboardId, params) => {
  return axios
    .get(`/api/dashboards/${dashboardId}/trade_flows/reporters`, {
      params: {
        year: params.year,
        source: params.source,
        classification_id: params.classificationId,
      },
    })
};

const TradeFlowsApi = () => {
  const classifications = (dashboardId) =>
    axios
      .get(`/api/dashboards/${dashboardId}/trade_flows/classifications`)

  return {
    classifications,
    quotes: fetchTradeFlowQuotes,
    reportingCountries: fetchTradeFlowReporters,
  };
};

export default TradeFlowsApi;
