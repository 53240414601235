<template>
  <TableHeadCell
    :title="displayName"
    :filter-active="controller.filters[columnKey].isActive"
    :is-sortable="true"
    :sort-direction="sortDirection"
    :sort-on-click="false"
    :tooltip="tooltip"
  >
    <template #filter>
      <FilterWithSearch
        :title="displayName"
        :filter-values="controller.filters[columnKey].values"
        :is-sortable="true"
        :sort-direction="sortDirection"
        @change="controller.onFiltersChanged()"
        @apply-sorting="setColumnSort"
      />
    </template>
  </TableHeadCell>
</template>

<script>
import TableHeadCell from '../TableHeadCell.vue';
import FilterWithSearch from '../FilterWithSearch.vue';

export default {
  name: 'TextFilterColumnHead',
  components: {
    TableHeadCell,
    FilterWithSearch,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: '',
    },
    controller: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    sortDirection() {
      if (this.controller.sorting != null && this.controller.sorting.column === this.columnKey) {
        return this.controller.sorting.direction;
      }
      return 'unsorted';
    },
  },
  methods: {
    setColumnSort(direction) {
      this.controller.setColumnSort(this.columnKey, direction);
      this.controller.onSortingChanged();
    },
  },
};
</script>
