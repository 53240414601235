<template>
  <td class="data-table--body-cell mini-chart">
    <SizeProvider
      v-if="hasData"
      class="mini-chart--size-provider"
    >
      <template #default="{ width, height }">
        <MiniChart
          :width="width"
          :height="height"
          :start-date="startDate"
          :end-date="endDate"
          :data="chartData"
        />
      </template>
    </SizeProvider>
  </td>
</template>

<script>
import SizeProvider from '@/components/Charts/SizeProvider.vue';
import MiniChart from '@/components/Charts/MiniChart.vue';
import { resampleTimeseries } from '@/helpers/ChartHelpers';

const oneYearAgo = (from) => new Date(from.getFullYear() - 1, from.getMonth(), from.getDate());

export default {
  name: 'MiniChartColumn',
  components: {
    SizeProvider,
    MiniChart,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const now = new Date();
    return {
      startDate: oneYearAgo(now),
      endDate: now,
    };
  },
  computed: {
    quotes() {
      const primaryQuotes = this.value.primaryNotationQuotes;
      const secondaryQuotes = this.value.secondaryNotationQuotes;
      return [primaryQuotes, secondaryQuotes].filter((quotes) => !!quotes);
    },
    chartData() {
      const quotes = (this.quotes || []).map((dataset) => {
        const chartDataset = dataset.map((q) => ({ x: Date.parse(q.date), y: q.value }));
        return resampleTimeseries(chartDataset, 'MM/YYYY');
      });
      return quotes;
    },
    hasData() {
      return this.chartData.flat().length > 1;
    },
  },
};
</script>
