<template>
  <div
    class="dialog--window"
    :class="{'is-wide': isNewsPublication }"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ title }}
      </div>
    </div>
    <form
      class="dialog--main"
      @submit="save"
    >
      <div class="dialog--main-box">
        <PublicationsEditForm
          :publication="formData"
          :content-url="contentUrl"
        />
        <ul class="dialog--action-list">
          <li class="dialog--action-list-item">
            <ButtonComponent
              variant="ternary"
              @click="cancel"
            >
              {{ $t('components.modals.cancel') }}
            </ButtonComponent>
          </li>
          <li class="dialog--action-list-item">
            <ButtonComponent
              type="submit"
              variant="primary"
              :disabled="!canSubmit"
            >
              {{ $t('apps.publications.edit_modal.update') }}
            </ButtonComponent>
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import PublicationsEditForm from '../EditForm/Component.vue';

export default {
  name: 'PublicationsEditModal',
  components: {
    PublicationsEditForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'close',
    'save',
  ],
  data() {
    return {
      isSaving: false,
      formData: {
        file_name: this.publication.file_name,
        kind: this.publication.kind,
        display_name: this.publication.display_name,
        published_at: this.publication.published_at
          ? new Date(this.publication.published_at)
          : new Date(),
        locale: this.publication.locale,
        for_external: this.publication.for_external,
        product_group_ids: this.publication?.product_groups.map((g) => g.id) || [],
        region_ids: this.publication?.regions.map((r) => r.id) || [],
        source: this.publication.source,
        content: null,
      },
    };
  },
  computed: {
    isNewsPublication() {
      return this.formData.kind === 'news';
    },
    hasValidContent() {
      return this.formData.content !== null || !this.isNewsPublication;
    },
    canSubmit() {
      return !this.isSaving && this.hasValidContent;
    },
    isExistingPublication() {
      return this.publication.id !== undefined;
    },
    contentUrl() {
      return this.publication.id
        ? `/api/publications/${this.publication.id}/content_for_edit`
        : undefined;
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    async save(e) {
      e.preventDefault();
      if (!this.canSubmit) {
        return;
      }

      try {
        this.isSaving = true;
        await this.createOrUpdate();
        this.$emit('close');
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
    createOrUpdate() {
      return this.isExistingPublication ? this.update() : this.create();
    },
    create() {
      return axios.post('/api/publications', {
        publication: this.formData,
      }).then((response) => {
        this.$emit('save');
        return response;
      });
    },
    update() {
      return axios.put(`/api/publications/${this.publication.id}`, {
        publication: this.formData,
      }).then((response) => {
        this.$emit('save', response.data);
        return response;
      });
    },
  },
};
</script>
