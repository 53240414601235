import { reactive } from 'vue';
import axios from 'axios';
import i18n from '@/config/i18n';
import ApiHelpers from '@/api/ApiHelpers';
import ModalWindowController from '@/controllers/ModalWindowController';
import { ProductGroupsApi } from '@/api/ProductGroupsApi';
import { CaseInsensitiveTextSearch } from '@/helpers';

class ProductGroupReportsController {
  // state will be needed when filtering/sorting is added
  // eslint-disable-next-line no-unused-vars
  constructor(user, state) {
    this._user = user;
    this._productGroupsApi = ProductGroupsApi();
    this._state = reactive({
      isLoading: true,
      entries: [],
      textFilter: '',
    });

    this.loadEntries();
  }

  get entries() {
    return this._state.entries;
  }

  get textFilter() {
    return this._state.textFilter;
  }

  set textFilter(value) {
    this._state.textFilter = value;
  }

  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filteredEntries() {
    return this.entries
      .filter((entry) => [
        entry.product_group.name_de,
        entry.product_group.name_en,
      ].some((name) => CaseInsensitiveTextSearch(this.textFilter, name)));
  }

  async loadEntries() {
    try {
      this.isLoading = true;
      const result = await axios.get('/api/product_group_reports', {
      });
      this._state.entries = result.data;
    } catch (error) {
      ApiHelpers.handleError(error);
      this._state.entries = [];
    } finally {
      this.isLoading = false;
    }
  }

  edit(entry) {
    ModalWindowController.show('ProductGroupReportsEditModal', {
      props: {
        controller: this,
        entry,
      },
    });
  }

  updateEntry(newEntryFromApi) {
    this._state.entries = this.entries.map((oldEntry) => {
      if (oldEntry.product_group.id === newEntryFromApi.product_group.id) {
        return newEntryFromApi;
      }
      return oldEntry;
    });
  }

  deleteAfterConfirmation(entry) {
    ModalWindowController.show('ConfirmationModal', {
      props: {
        text: i18n.t('apps.product_group_reports.list.delete_confirm_text', {
          product_group: entry.product_group.name,
        }),
      },
      events: {
        confirm: () => { this._delete(entry); },
      },
    });
  }

  async _delete(entry) {
    try {
      const url = `/api/product_group_reports/${entry.product_group.id}`;
      const response = await axios.delete(url);
      this.updateEntry(response.data);
    } catch (error) {
      ApiHelpers.handleError(error);
    }
  }
}

export default ProductGroupReportsController;
