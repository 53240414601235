<template>
  <div class="chart-legend--wrapper">
    <div
      v-if="showTitle()"
      class="chart-legend--title-row"
    >
      <h2 class="chart-legend--title">
        {{ axisDescription }}
      </h2>
      <div :class="`chart-legend--kpi-title-list ${showAbsolutePrices ? '' : 'chart-legend--kpi-title-list--space-left'}`">
        <h2
          v-for="kpi in kpiTitleList"
          :key="kpi.title"
          class="chart-legend--kpi-title-list-item"
        >
          <WithTooltip :tooltip="kpi.tooltip">
            {{ kpi.title }}
          </WithTooltip>
        </h2>
      </div>
    </div>
    <ul>
      <li
        v-for="entry in entries"
        :key="entry"
      >
        <ChartLegendItem
          v-if="showItem(entry)"
          class="chart-legend--item"
          :notation="entry.notation"
          :notation-color="entry.color"
          :unit="entry.unit"
          :unit-converted-from="entry.unitConvertedFrom"
          :is-selected="entry.selected"
          :show-index-view="showIndexView"
          :with-quotes="entry.withQuotes"
          :show-absolute-prices="showAbsolutePrices"
          @update:is-selected="(selectedLine) => $emit('set-line-selected', {
            notationID: entry.notation.id,
            selected: selectedLine,
          })"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ChartLegendItem from './ChartLegendItem/Component.vue';

export default {
  name: 'ChartLegendWithKpis',
  components: {
    ChartLegendItem,
  },
  props: {
    entries: {
      type: Array,
      default: null,
    },
    axisDescription: {
      type: String,
      default: null,
    },
    showIndexView: {
      type: Boolean,
      required: true,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['set-line-selected'],
  computed: {
    kpiTitleList() {
      const kpiList = ['latest_date', 'latest_value', 'last_year', 'last_month', 'last_week', 'last_day', 'current_value_range'];
      if (!this.showAbsolutePrices) { kpiList.splice(1, 1); } // remove latest_value
      const titleList = kpiList.map((kpi) => ({
        title: this.$t(`components.notation_kpi_list.${kpi}`),
        tooltip: this.$t(`components.notation_kpi_list.tooltips.${kpi}`),
      }));
      return titleList;
    },
  },
  methods: {
    showTitle() {
      return this.entries.filter((entry) => this.showItem(entry)).length > 0;
    },
    showItem(entry) {
      return this.$media !== 'print' || (entry.withQuotes && entry.selected);
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>