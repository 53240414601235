import i18n from '@/config/i18n';

const defaultValueCallback = (column, notation) => notation[column.key];

const defaultDisplayNameCallback = (model) => (column) => (
  i18n.t(`attributes.${model}.${column.key}`));

const ColumnConfiguration = (model, key, type, filterOptions, config = {}, eventListeners = {}) => {
  const state = {
    visible: true,
    data: {},
    eventListeners,
    config: Object.assign(
      {
        displayNameCallback: defaultDisplayNameCallback(model),
        valueCallback: defaultValueCallback,
      },
      config,
    ),
  };
  return {
    get key() { return key; },
    get displayName() { return state.config.displayNameCallback(this); },
    get type() { return type; },
    get filterOptions() { return config.noSortOrFilter ? 'none' : filterOptions; },
    get visible() { return state.visible; },
    set visible(value) { state.visible = value; },
    get data() { return state.data; },
    addData(dataKey, value) { state.data[dataKey] = value; },
    get eventListeners() { return state.eventListeners; },
    get valueCallback() { return state.config.valueCallback; },
    get tooltip() {
      const translationKey = `components.${model}_list.${state.config.i18nPrefix}.tooltips.${key}`;
      if (state.config.i18nPrefix && i18n.te(translationKey)) return i18n.t(translationKey);
      const fallbackTranslationKey = `components.${model}_list.tooltips.${key}`;
      if (i18n.te(fallbackTranslationKey)) return i18n.t(fallbackTranslationKey);
      return undefined;
    },
    get filterPopupIsWide() { return state.config.filterPopupIsWide; },
  };
};

export { ColumnConfiguration };
