<template>
  <div class="subscriptions-title">
    <Icon
      name="envelope"
      variant="regular"
      class="subscriptions-title--icon"
    />
    <h1>
      {{ $t('page_titles.subscriptions') }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionsTitle',
};
</script>

<style scoped lang="scss">
@import './style.screen.scss';
</style>
