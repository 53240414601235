<template>
  <ul class="filter-for-enums--checkbox-list" role="listbox" ref="listbox">
    <li
      v-for="(item, index) in items"
      :key="item.key"
      :role="item.unavailable ? null : 'option'"
      :id="`${uuid}-${index}`"
      class="filter-for-enums--checkbox-list-item"
      :class="listItemClasses(item, index)"
      @click="clickItem(item, $event)"
    >
      <Icon
        class="filter-for-enums--checkbox-icon-checked"
        name="check"
      />
      <span class="filter-for-enums--checkbox-label">
        {{ item.name }}
      </span>

      <span>
        <span class="show-on-highlight keyboard-key">⏎</span>
      </span>

    </li>
  </ul>
</template>

<script>
import generateUuid from '@/utils/uuid'

export default {
  name: 'FilterList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    highlightedIndex: {
      type: Number,
      default: null
    }
  },
  emits: ['click-item'],
  computed: {
    uuid() {
      return `u-${generateUuid().slice(0, 6)}`
    }
  },
  methods: {
    listItemClasses(item, index) {
      return {
        'is-highlighted': index == this.highlightedIndex,
        'is-selected': item.selected,
        'is-unavailable': item.unavailable,
      };
    },
    clickItem(item, $event) {
      $event.stopPropagation(); // TODO: this shouldn't be required
      if (item.unavailable) {
        return
      }
      this.$emit('click-item', item);
    },
  },
};
</script>
