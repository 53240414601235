<template>
  <li class="app-header--user-menu-footer">
    <a
      href="/legal-notice"
      class="app-header--user-menu-footer-link"
    >
      {{ $t('layouts.app_header.legal_notice') }}
    </a>
    <a
      href="/terms-of-use"
      class="app-header--user-menu-footer-link"
    >
      {{ $t('layouts.app_header.terms') }}
    </a>
    <a
      href="/privacy"
      class="app-header--user-menu-footer-link"
    >
      {{ $t('layouts.app_header.privacy') }}
    </a>
  </li>
</template>

<script>
export default {
  name: 'NavigationUserMenuFooter',
};
</script>
