import axios from 'axios';
import Cache from '@/services/Cache';
import moment from 'moment';
import ApiHelpers from './ApiHelpers';

const QuotesCache = new Cache();

const NotationApi = () => {
  const findNotation = (id, params) => axios
    .post(`/api/notations/${id}`, { params })
    .then((response) => response.data);

  const transformQuote = (quote) => ({
    date: moment(quote.date).toDate(),
    value: quote.value,
  });

  const quotesFromApi = (notationId, {
    startDate,
    toCurrency,
    toUnitComposition,
    customDate,
    withKeyPerformanceIndicators,
    indexPeriod,
  } = {}) => {
    const params = {
      start_date: ApiHelpers.dateToString(startDate),
      to_currency_unit_id: toCurrency,
      to_unit_composition: toUnitComposition,
      with_key_performance_indicators: withKeyPerformanceIndicators,
      custom_date: ApiHelpers.dateToString(customDate),
      index_period: indexPeriod,
    };
    return axios.get(`/api/notations/${notationId}/quotes`, { params }).then((response) => ({
      unit: response.data.unit,
      quotes: response.data.quotes.map(transformQuote),
      unitConvertedFrom: response.data.unit_converted_from,
      originalUnit: response.data.original_unit,
      keyPerformanceIndicators: response.data.key_performance_indicators,
    }));
  };

  const quotesPerYear = (notationId, {
    years,
    toUnitComposition,
    startMonthId,
  } = {}) => {
    const params = {
      years,
      to_unit_composition: toUnitComposition,
      start_month_id: startMonthId,
    };
    return axios.get(`/api/notations/${notationId}/quotes_yearly`, { params }).then((response) => ({
      unit: response.data.unit,
      quotes: response.data.grouped_quotes.map((groupedQuote) => ({
        title: groupedQuote.title,
        quotes: groupedQuote.quotes.map(transformQuote),
        beginDate: moment(groupedQuote.begin_date).toDate(),
        endDate: moment(groupedQuote.end_date).toDate(),
      })),
      unitConvertedFrom: response.data?.unit_converted_from
        ? response.data.unit_converted_from : null,
      originalUnit: response.data?.original_unit ? response.data.original_unit : null,
    }));
  };

  const quotes = (notationId, {
    startDate,
    toCurrency,
    toUnitComposition,
    customDate,
    withKeyPerformanceIndicators = true,
    indexPeriod,
  } = {}) => {
    const cacheKey = [
      notationId,
      ApiHelpers.dateToString(startDate),
      toCurrency,
      toUnitComposition?.numerator_unit_id,
      toUnitComposition?.denominator_unit_id,
      ApiHelpers.dateToString(customDate),
      withKeyPerformanceIndicators,
      indexPeriod?.month_id,
      indexPeriod?.year,
    ].join('-');
    return QuotesCache.fetch(cacheKey, () => quotesFromApi(notationId, {
      startDate,
      toCurrency,
      toUnitComposition,
      customDate,
      withKeyPerformanceIndicators,
      indexPeriod,

    }));
  };

  const addToWatchList = (notation) => axios.post('/api/watch_list/add_notation', {
    notation_id: notation.id,
  });

  const deleteFromWatchList = (notation) => axios.delete('/api/watch_list/notation', {
    params: { notation_id: notation.id },
  });

  const createNewDashboard = (dashboard, notations) => axios
    .post('/api/dashboards', {
      dashboard,
      notation_ids: notations.map((n) => n.id),
    });

  const addToDashboard = (dashboardId, notations) => axios
    .post(`/api/dashboards/${dashboardId}/add_notations`, {
      notation_ids: notations.map((n) => n.id),
    });

  const deleteFromDashboard = (dashboardId, notations) => axios
    .delete(`/api/dashboards/${dashboardId}/notations`, {
      params: { notation_ids: notations.map((n) => n.id) },
    });

  return {
    findNotation,
    quotes,
    addToWatchList,
    deleteFromWatchList,
    createNewDashboard,
    addToDashboard,
    deleteFromDashboard,
    quotesPerYear,
  };
};

export default NotationApi;
