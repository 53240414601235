<script>
import { WithTooltip } from "@/components/generic";
import TopFiveCountryCellTooltip from '@/components/NotationList/NotationTable/Columns/TopFiveCountry/TopFiveCountryCellTooltip';

import HrScoresColumn from "../NotationList/NotationTable/Columns/HrScoresColumn/HrScoresColumn";
import TotalHrScoreColumn from '../NotationList/NotationTable/Columns/TotalHrScoreColumn/TotalHrScoreColumn';

import CellExpandableControls from "./CellExpandableControls"
import FlagsColumn from "./FlagsColumn"
import CalculatedScoresColumn from "./CalculatedScoresColumn"
import FlagsColumnContent from "./FlagsColumnContent"
import EditSupplyChainItemCell from "../SupplyChainTable/EditSupplyChainItemCell"

export default {
  components: {
    CellExpandableControls,
    WithTooltip,
    TopFiveCountryCellTooltip,
    HrScoresColumn,
    TotalHrScoreColumn,
    FlagsColumn,
    FlagsColumnContent,
    CalculatedScoresColumn,
    EditSupplyChainItemCell
  },

  props: {
    columnGroup: {
      type: Object,
      required: true
    },
    groupIndex: {
      type: Number,
      required: true
    },
    column: {
      type: Object,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    record: {
      type: Object,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    css() {
      return [
        'data-table--body-cell group-column',
        `group-${this.groupIndex} column-${this.columnIndex}`,
        this.columnGroup.cellClasses(this.columnIndex, this.record)
      ]
    },
    textTooltip() {
      if (this.hrTooltipData) { return }
      if (this.column.cellTooltip) {
        return this.column.cellTooltip(this.record)
      }

      let cellContent = this.column.render(this.record)
      if (!cellContent || cellContent.length < 40) { return }

      return cellContent
    },
    showTooltip() {
      return !this.column.component && (this.hrTooltipData || this.textTooltip)
    },
    hrTooltipData() {
      if (!this.hrTooltipDataCallback) { return }

      let result = this.hrTooltipDataCallback(this.record)
      if (result && result.hrScores && result.hrScores.length) {
        return result
      }
    },
    hrTooltipDataCallback() {
      // If a group is collapsed and the columnGroup has an index for the total score column,
      // showing a tooltip with those values
      if (this.short && this.columnGroup.totalHrScoreColumn) {
        return this.columnGroup.totalHrScoreColumn.hrTooltipData
      }
      return this.column.hrTooltipData
    },
    cellContent() {
      if (this.short) {
        return this.columnGroup.shortLabel(this.record)
      } else {
        return this.column.render(this.record)
      }
    }
  }
}
</script>

<template>

  <component
    v-bind="column.component ? column.props(record) : {}"
    :is="column.component || 'td'"
    :class="css"
    :event-listeners="{}"
  >

    <CellExpandableControls v-if="column.showExpandableControls" :record="record"/>

    <WithTooltip
      y-alignment="auto"
      tag="div"
      v-if="showTooltip"
      :tooltip="textTooltip"
    >
      <div class="is-flex">
        <span class="default-cell flex-grow-1">{{ cellContent }}</span>
        <component
          v-if="column.suffixComponent"
          :is="column.suffixComponent"
          v-bind="column.props(record)"
        />
      </div>
      <template v-if="hrTooltipData" #custom>
        <TopFiveCountryCellTooltip :data="hrTooltipData" />
      </template>
    </WithTooltip>

    <span
      v-else-if="!column.component"
      class="default-cell"
    >{{ cellContent }}</span>
  </component>
</template>

<style lang="scss">

</style>
