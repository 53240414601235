<template>
  <li class="dashboard-tab--item">
    <a
      :href="tab.href"
      :class="{'is-active': isActive}"
      class="dashboard-tab--button"
      :disabled="tab.disabled || undefined"
    >
      {{ tab.name }}
    </a>
  </li>
</template>

<script>
export default {
  name: 'DashboardTab',
  props: {
    tab: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>