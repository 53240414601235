<template>
  <ul class="print-meta-table">
    <li class="print-meta-table--item">
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.source_label') }}
      </p>
      <p class="print-meta-table--value">
        {{ source }}
      </p>
    </li>

    <li class="print-meta-table--item is-growing">
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.classification_label') }}
      </p>
      <p class="print-meta-table--value">
        {{ (classification || {}).code }} - {{ (classification || {}).name }}
      </p>
    </li>

    <li class="print-meta-table--item">
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.year_label') }}
      </p>
      <p class="print-meta-table--value">
        {{ year }}
      </p>
    </li>

    <li
      v-if="reporterName"
      class="print-meta-table--item"
    >
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.reporting_country_label') }}
      </p>
      <p class="print-meta-table--value">
        {{ reporterName }}
      </p>
    </li>

    <li
      v-if="partnerName"
      class="print-meta-table--item"
    >
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.partner_country_label') }}
      </p>
      <p class="print-meta-table--value">
        {{ partnerName }}
      </p>
    </li>

    <li
      v-if="balance"
      class="print-meta-table--item"
    >
      <p class="print-meta-table--caption">
        {{ $t('components.trade_flows.balance.label') }}
      </p>
      <p class="print-meta-table--value">
        {{ balanceString }}
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DashboardTradeFlowsPrintMetaTable',
  props: {
    reporterName: {
      type: String,
      default: null,
    },
    partnerName: {
      type: String,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
    balance: {
      type: Number,
      default: null,
    },
    source: {
      type: String,
      default: 'UNComtrade',
    },
    classification: {
      type: Object,
      default: null,
    },
  },
  computed: {
    balanceString() {
      if (this.balance === null) {
        return null;
      }
      const i18nKey = this.balance > 0 ? 'surplus' : 'deficit';
      return this.$t(`components.trade_flows.balance.${i18nKey}`);
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>