<template>
  <div
    class="vertical-spacer"
    :class="classModifier"
    :style="styleModifier"
  />
</template>

<script>

const Sizes = [
  'xxs',
  'xs',
  's',
  'sm',
  'm',
  'l',
  'xl',
  'xxl',
];

export default {
  name: 'VerticalSpacer',
  props: {
    size: {
      type: String,
      default: 's',
      validator: (v) => Sizes.includes(v),
    },
    grow: {
      type: Number,
      default: null,
    },
    shrink: {
      type: Number,
      default: null,
    },
  },
  computed: {
    classModifier() {
      return [
        `size-${this.size}`,
      ].join(' ');
    },
    styleModifier() {
      return [
        this.grow !== null ? `flex-grow: ${this.grow}` : null,
        this.shrink !== null ? `flex-shrink: ${this.grow}` : null,
      ].join(';');
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>