import EventBase from './EventBase';

class MarkPublicationAsReadEvent extends EventBase {
  constructor({ publication }) {
    super();
    this._data = {
      publication,
    };
  }

  static get EventName() {
    return 'HGS::MarkPublicationAsReadEvent';
  }

  get publication() {
    return this._data.publication;
  }
}

export default MarkPublicationAsReadEvent;
