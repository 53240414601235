<template>
  <div
    class="dialog--window"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('components.clone_dashboard_modal.heading') }}
      </div>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <form @submit="create($event)">
          <TextInput
            ref="nameInput"
            v-model="dashboard.name"
            v-focus
            :required="true"
            :label="$t('attributes.dashboard.name')"
            @focus="$event.target.select()"
          />
          <p
            v-if="formError.message"
            class="form--error"
          >
            {{ formError.message }}
          </p>

          <ul class="dialog--action-list">
            <li class="dialog--action-list-item">
              <ButtonComponent
                variant="ternary"
                @click="cancel()"
              >
                {{ $t('components.modals.cancel') }}
              </ButtonComponent>
            </li>
            <li class="dialog--action-list-item">
              <ButtonComponent
                type="submit"
                variant="primary"
              >
                {{ $t('components.clone_dashboard_modal.clone') }}
              </ButtonComponent>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloneDashboardModal',
  props: {
    initialDashboard: {
      type: Object,
      required: true,
    },
    formError: {
      type: Object,
      default: () => null,
    },
  },
  emits: [
    'cancel',
    'clone',
    'close',
  ],
  data() {
    return {
      dashboard: {
        name: this.$t('components.clone_dashboard_modal.name_input', {
          name: this.initialDashboard.full_name,
        }),
      },
    };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    create(e) {
      e.preventDefault();
      this.$emit('clone', this.dashboard);
    },
  },
};
</script>
