import { reactive } from 'vue';

const COLUMN_TRANSLATION_KEY = 'apps.human_rights.commodity_risk_map.table.column';

class WeightedScoresTableController {
  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filters() {
    return this._state.filters;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    return this._state.selection;
  }

  get notations() {
    return this._state.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    return this._state.notationListModel;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get ready() {
    return !!this._state
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  constructor(translator) {
    this._translator = translator;
  }

  init(hrScores) {
    this._mappedHrScores = this._getMappedHrScores(hrScores);
    this.hrScores = hrScores;
    this._columns = this._getColumnDefinition(hrScores);

    this._state = reactive({
      isLoading: false,
      filters: {},
      sorting: null,
      selection: {
        selectionMode: 'none',
        canSelect: false,
      },
      notations: [this._mappedHrScores],
      notationListModel: {
        columns: this._columns,
        visibleColumns: this._columns,
        createRow: (notation) => {
          return this._columns.map(({ key, type, ...column }) => ({
            key,
            type,
            filterOptions: column.filterOptions,
            displayName: column.displayName,
            visible: true,
            value: column.valueBuilder ? column.valueBuilder(notation, key) : notation[key],
            classes: {},
            additionalData: {},
          }))
        },
      },
    });
  }

  updateHrScores(hrScores) {
    if (!hrScores?.length) { return }

    if (!this._columns) {
      return this.init(hrScores)
    }

    this._hrScores = this._getMappedHrScores(hrScores);
    this.hrScores = hrScores;
    this.notations = [this._hrScores];
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  _getMappedHrScores(hrScores) {
    return hrScores.reduce((acc, curr) => {
      acc[`dimension-${curr.key}`] = curr;
      return acc;
    }, {})
  }

  _getColumnDefinition(_dimensions) {
    const [total, ...dimensions] = _dimensions;

    if (!total) { return }

    return [
      {
        key: 'dimension-0',
        visible: true,
        displayName: total.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'totalHrScoresColumn',
        valueBuilder: (notation, key) => ({dimensions: this.hrScores, total: notation[key], withFlagIcon: true}),
      },
      ...dimensions.map((d) => ({
        key: `dimension-${d.key}`,
        visible: true,
        displayName: d.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'hrScoresColumn',
      })),
    ];
  }
}

export default WeightedScoresTableController;
