import EnumFilter from './filters/EnumFilter';
import TextFilter from './filters/TextFilter';

const TextFieldNames = ['dashboard_name'];

const createFilterForField = (fieldName, initialValue) => {
  if (TextFieldNames.includes(fieldName)) {
    return TextFilter(initialValue);
  }
  return EnumFilter(initialValue);
};

const NotationFilters = (initial) => {
  const fieldNames = Object.keys(initial);

  const filters = {
    get forRequest() {
      const requestFilters = {};
      fieldNames.forEach((fieldName) => {
        let filterValue = this[fieldName].forRequest
        if (filterValue?.length || typeof filterValue == "object") {
          requestFilters[fieldName] = filterValue
        }
      });
      return requestFilters;
    },

    get anyActive() {
      return fieldNames.filter((fieldName) => this[fieldName].isActive)
        .length > 0;
    },

    clear() {
      fieldNames.forEach((fieldName) => {
        this[fieldName].clear();
      });
    },
  };

  fieldNames.forEach((fieldName) => {
    filters[fieldName] = createFilterForField(fieldName, initial[fieldName]);
  });

  return filters;
};

export default NotationFilters;
