<template>
  <TableHeadCell
    ref="root"
    :title="displayName"
    :filter-active="controller.filters[columnKey].isActive"
    :tooltip="tooltip"
  >
    <template #filter>
      <FilterByText
        :filter-object="controller.filters[columnKey]"
        @change="controller.onFiltersChanged()"
        @apply="controller.onFiltersChanged(); $refs.root.close()"
      />
    </template>
  </TableHeadCell>
</template>

<script>
import TableHeadCell from '../TableHeadCell.vue';
import FilterByText from '../FilterByText.vue';

export default {
  name: 'TextFilterColumnHead',
  components: {
    TableHeadCell,
    FilterByText,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: () => null,
    },
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
