<template>
  <ul :class="`tab-bar ${themeClass}`">
    <li
      v-for="tab in tabs"
      :key="tab.value"
      class="tab-bar--item"
    >
      <WithTooltip
        tag="div"
        :tooltip="tab.tooltip"
      >
        <component
          :is="isLinkTab(tab) ? 'a' : 'button'"
          :href="tab.disabled ? null : tab.href"
          class="tab-bar--button"
          :class="{'is-active': isCurrentTab(tab)}"
          :disabled="tab.disabled || undefined"
          @click="onTabClicked(tab)"
        >
          <slot
            name="tab-content"
            :tab="tab"
          >
            <Icon
              v-if="tab.icon"
              class="tab-bar--icon"
              variant="regular"
              :name="tab.icon"
            />
            {{ tab.name }}
          </slot>
        </component>
      </WithTooltip>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    tabs: {
      type: Object,
      default: () => null,
    },
    darkStyle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    themeClass() {
      return this.darkStyle ? 'is-dark-theme' : 'is-light-theme';
    },
  },
  methods: {
    isLinkTab(tab) {
      return !!tab.href;
    },
    isCurrentTab(tab) {
      if (this.isLinkTab(tab)) {
        const basePath = window.location.pathname.split('?');
        return basePath[0] === tab.href;
      }

      return tab.value === this.modelValue;
    },
    onTabClicked(tab) {
      if (!tab.disabled) {
        this.$emit('update:modelValue', tab.value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>