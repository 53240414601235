<template>
    <div
      v-show="title === selectedTabTitle"
      class="button-tab-content"
      :class="{ 'button-tab-content--padded': withPadding }"
    >
      <slot />
    </div>
</template>

<script setup>
import { inject } from 'vue';

defineProps({
  title: String,
  headline: String,
  icon: String,
  withPadding: {
    type: Boolean,
    default: false,
  },
});
const selectedTabTitle = inject('selectedTabTitle');
</script>

<style lang="scss">
.button-tab-content {
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;

  &--padded {
    padding: 0 20px 20px 20px;
  }

  @media print {
    .button-tab {
      height: auto;
    }

    .button-tab {
      height: 0;
    }
  }
}

</style>
