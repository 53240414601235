<template>
  <Teaser class="commodityriskmap-export-button" scope="export" user-attribute="can_export_human_rights_data">
    <ComboBox
            :model-value="currentIndex"
            :options="options"
            :label="ariaLabel"
            :disabled="isLoading || isSaving"
            :display-text-override="comboBoxDisplayTextOverride"
            :controller="controller"
            @update:modelValue="selectExportFormat"
    />
  </Teaser>
</template>

<script>
  import i18n from '@/config/i18n';
  import axios from 'axios';
  import ApiHelpers from '@/api/ApiHelpers';
  import PdfExportController from '@/components/generic/PdfExportButton/Controller';

  const EXPORT_FILEFORMATS = ['pdf_map', 'pdf_table', 'pdf_chart', 'excel'];

  export default {
    name: 'CommodityRiskMapExportButtonComponent',
    props: {
      ariaLabel: {
        type: String,
        default: i18n.t('apps.human_rights.commodity_risk_map.export_button.options.default_label'),
      },
      dashboardId: {
        type: Number,
        default: null,
      },
      controller: {
        type: Object,
        default: null,
      },
      year: {
        type: Number,
        required: true,
      },
      countryOfDemandId: {
        type: Number,
        default: null,
      },
      classificationId: {
        type: Number,
        default: null,
      },
      limit: {
        type: Object,
        default: null,
      }
    },
    data() {
      return {
        isLoading: false,
        isSaving: false,
        keys: EXPORT_FILEFORMATS,
      };
    },
    computed: {
      options() {
        return this.keys.map((key) => this.$t(`apps.human_rights.commodity_risk_map.export_button.options.${key}`));
      },
      currentIndex() {
        return this.keys.indexOf(this.mailExportRythm);
      },
      currentOption() {
        return this.options[this.currentIndex];
      },
      comboBoxDisplayTextOverride() {
        if (this.isLoading) {
          return this.$t('apps.human_rights.commodity_risk_map.export_button.is_loading');
        } if (this.isSaving) {
          return this.$t('apps.human_rights.commodity_risk_map.export_button.is_saving');
        }
        return this.$t('apps.human_rights.commodity_risk_map.export_button.options.default_label');
      },
    },
    beforeMount() {
    },
    methods: {
      selectExportFormat(index) {
        const currentExportFormat = EXPORT_FILEFORMATS[index];
        switch (currentExportFormat) {
          case 'pdf_map':
            this.exportPDF(0);
            break;
           case 'pdf_table':
             this.exportPDF(1);
             break;
          case 'pdf_chart':
            this.exportPDF(2);
            break;
          case 'excel':
            this.exportXLSX();
            break;
        }
      },
      exportPDF(tabPosition) {
        const params = new URLSearchParams(window.location.search);
        params.set('tab_position', tabPosition);
        const path = `${window.location.pathname}?${params.toString()}`;
        PdfExportController.exportAsPdf(path);
      },
      // TODO: Wenn keine Classification gesetzt ist, dann geht es halt nicht
      exportXLSX() {
        const params = {
          country_of_demand_id: this.countryOfDemandId,
          classification_id: this.classificationId,
          limit: this.limit,
          year: this.year,
        };
        this.controller._getExcelFile(params);
      },
    },
  };
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>