export const TrapFocus = (element) => {
  const focusableSelector = [
    'button',
    '[href]',
    'input',
    'select',
    'textarea',
    '[tabindex]:not([tabindex="-1"])',
  ].join(', ');

  const getFocusableElements = () => [...element.querySelectorAll(focusableSelector)]
    .filter((elem) => !elem.hasAttribute('disabled'))
    .filter((elem) => elem.tabIndex !== -1);

  const eventListener = (event) => {
    const isTabKey = event.key === 'Tab' || event.keyCode === 9;
    if (!isTabKey) return;

    const elements = getFocusableElements();
    const firstElement = elements[0];
    const lastElement = elements[elements.length - 1];

    const isFirstElementFocused = document.activeElement === firstElement;
    const isLastElementFocused = document.activeElement === lastElement;
    if (isFirstElementFocused && event.shiftKey) {
      event.preventDefault();
      lastElement.focus();
    } else if (isLastElementFocused) {
      event.preventDefault();
      firstElement.focus();
    }
  };

  const trap = () => {
    document.addEventListener('keydown', eventListener);
    const firstFocusElement = getFocusableElements()[0];
    if (firstFocusElement) {
      firstFocusElement.focus();
    }
  };

  const free = () => {
    document.removeEventListener('keydown', eventListener);
  };

  return { trap, free };
};
