import { reactive } from 'vue';

const ModalWindowController = () => {
  const state = reactive({
    component: null,
  });

  return {
    get component() {
      return state.component;
    },
    show(component, options = {}) {
      state.component = {
        name: component,
        props: options.props || {},
        listeners: options.events || {},
      };
    },
    close() {
      state.component = null;
    },
  };
};

export default ModalWindowController();
