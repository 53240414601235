export default function(importExportValues, translator) {
  if (!importExportValues) {
    return
  }

  let exportValues = importExportValues.export
  let importValues = importExportValues.import

  return `
    &middot; ${ translator("components.trade_flows.import_volume") }: ${ importValues?.formatted || "-" } <br>
    &middot; ${ translator("components.trade_flows.export_volume") }: ${ exportValues?.formatted || "-" }
  `
}
