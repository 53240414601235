<template>
  <div class="dropdown-menu--item">
    <div class="dropdown-menu--caption">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenuCaption',
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>