<script>
import HrScoresColumn from '../NotationList/NotationTable/Columns/HrScoresColumn/HrScoresColumn'
import TotalHrScoreColumn from '../NotationList/NotationTable/Columns/TotalHrScoreColumn/TotalHrScoreColumn';
import FlagsColumn from '@/components/ExpandableColumnsTable/FlagsColumn'

export default {
  components: {
    HrScoresColumn,
    TotalHrScoreColumn,
    FlagsColumn
  },

  props: {
    record: { type: Object },
    row: { type: Number },

    i18n: { type: String },
    title: { type: String },
    kind: { type: String },
    type: { type: String },
    prefix: { type: String },
    sortBy: { type: String },
    payload: { type: String },
    filterable: { type: Boolean, default: false }
  },
  inject: ['columns', 'checkConfigLoaded'],

  created() {
    if (this.row == 0 && !this.configLoaded) {
      this.columns.push(this.config)
    }
  },

  methods: {
    jsonLookup(item, jsonKey) {
      if (!jsonKey) { return }

      let value = item
      jsonKey.split('.').forEach((key)=>{
        if (!value) {
          return
        }
        value = value[key]
      });

      return value
    }
  },

  computed: {
    configLoaded() {
      return this.checkConfigLoaded()
    },

    label() {
      let rawLabel = this.config.displayValue(this.record)
      if (this.type == "datetime") {
        return moment(rawLabel).format("ddd, DD.MM.YYYY, HH:mm")
      }
      return rawLabel
    },

    // Support HR specific columns
    customProps() {
      if (!this.kind) {
        return
      }

      if (this.kind == 'FlagsColumn') {
        return {
          flagCount: this.record[this.i18n]
        }
      }

      let parsedPayload = JSON.parse(this.payload)
      if (this.kind == 'HrScoresColumn') {
        return {
          value: this.record.scores[parsedPayload.dimension]
        }
      }

      if (this.kind == 'TotalHrScoreColumn') {
        return {
          value: {
            dimensions: this.record.scores,
            total: this.record.scores[parsedPayload.dimension],
            withFlagIcon: true,
            withTooltip: true
          }
        }
      }
    },

    config() {
      return {
        // alias to match column config interface for notation tables
        valueBuilder: function(item) { return this.displayValue(item) },

        displayValue: (item) => {
          let parts = [this.jsonLookup(item, this.i18n)]
          if (this.prefix) {
            parts.unshift(item[this.prefix])
            return parts.join(' | ')
          }

          // make sure to not cast to string values
          return parts[0]
        },

        sortValue: (item) => {
          if (this.sortBy) {
            return this.jsonLookup(item, this.sortBy)
          }
          return this.config.displayValue(item)
        },

        key: this.i18n,
        i18n: this.i18n,
        title: this.title,
        filterable: this.filterable
      }
    }
  }
}
</script>

<template>
  <component
    v-if="kind"
    :is="kind"
    :class="`data-table--body-cell table-cell-${i18n}`"
    :event-listeners="{}"
    v-bind="customProps"
  />
  <td v-else :class="`data-table--body-cell table-cell-${i18n}`">
    <slot v-if="$slots.default"/>
    <span v-else>
      {{ label }}
    </span>
  </td>
</template>

<style lang="scss">
</style>
