<template>
  <div>
    <div>
      <h2 class="select-notation--title">
        {{ dashboard.full_name }}
      </h2>
      <div class="select-notation--list">
        <NotationList
          :controller="controller"
          :sticky-margin-zero-top="true"
        />
      </div>
    </div>
  </div>
  <ul class="select-notation--footer">
    <li class="select-notation--count-label">
      {{ checkedNotationsCount }}/{{ selectableNotationsCount }}
      {{ $t('components.add_to_multi_line_chart_config_modal.notations') }}
    </li>
    <li>
      <div class="dialog--action-list-item">
        <ButtonComponent
          variant="ternary"
          @click="onClickCancel"
        >
          {{ $t('components.modals.cancel') }}
        </ButtonComponent>
        <ButtonComponent
          variant="ternary"
          @click="onClickBack"
        >
          {{ $t('components.add_to_multi_line_chart_config_modal.change_dashboard') }}
        </ButtonComponent>
        <ButtonComponent
          variant="primary"
          :is-disabled="!canSubmit"
          @click="onSubmit"
        >
          {{ addNotationsButtonTitle }}
        </ButtonComponent>
      </div>
    </li>
  </ul>
</template>

<script>
import NotationList from '@/components/NotationList/NotationList.vue';
import i18n from '@/config/i18n';
import ChartConfigController from '../ChartConfigController';

export default {
  name: 'SelectNotationsForm',
  components: {
    NotationList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      default: null,
    },
    notationsCount: {
      type: Number,
      default: null,
    },
    maxNotationsCount: {
      type: Number,
      default: 5,
    },
  },
  emits: [
    'submit',
    'back',
    'cancel',
  ],
  data() {
    return {
      controller: null,
    };
  },
  computed: {
    checkedNotationsCount() {
      return this.controller.checkedNotations.length;
    },
    selectableNotationsCount() {
      return this.maxNotationsCount > 1 ? this.maxNotationsCount - this.notationsCount : 1;
    },
    canSubmit() {
      return this.checkedNotationsCount > 0
      && this.checkedNotationsCount <= this.selectableNotationsCount;
    },
    addNotationsButtonTitle() {
      return this.maxNotationsCount > 1 ? i18n.t('components.add_to_multi_line_chart_config_modal.add_notations_button')
        : i18n.t('apps.season_line_chart_config.select_notation');
    },
  },
  beforeMount() {
    this.controller = new ChartConfigController(this.dashboard, this.user,
      { isMailExport: this.$isMailExport, media: this.$media });
  },
  methods: {
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickBack() {
      this.$emit('back');
    },
    onSubmit() {
      if (this.canSubmit) {
        this.$emit('submit', {
          notations: this.controller.checkedNotations,
          dashboardId: this.dashboard.id,
        });
      }
    },
  },
  provide() {
    return {
      user: this.user,
    }
  }
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>