<template>
  <ComboBoxOption class="combobox--default-option combobox-option">
    {{ value }}
    <Icon
      v-if="isPreferredDashboardCurrency"
      name="thumbtack"
      :fixed-width="true"
      variant="regular"
      :aria-label="ariaLabelForIcon"
    />
  </ComboBoxOption>
</template>

<script>

export default {
  name: 'CurrencyComboBoxOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    accessKey: {
      type: Function,
      required: true,
    },
    accessValue: {
      type: Function,
      required: true,
    },
    preferredDashboardCurrencyId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    key() {
      return this.accessKey(this.option);
    },
    value() {
      return this.accessValue(this.option);
    },
    isPreferredDashboardCurrency() {
      return this.preferredDashboardCurrencyId === this.option.id;
    },
    preferredDashboardCurrencyName() {
      return this.option?.name;
    },
    ariaLabelForIcon() {
      return this.$t('components.currency_combobox.preferred_dashboard_currency_option', { preferredDashboardCurrency: this.preferredDashboardCurrencyName });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>