<template>
  <div
    class="split-page-layout"
    :class="cssModifier"
  >
    <div class="split-page-layout--top">
      <div class="split-page-layout--inner-top">
        <slot name="top" />
        <VerticalSpacer :size="spacing" />
        <VerticalSeparator v-if="withSeparator" />
      </div>
    </div>
    <div class="split-page-layout--bottom">
      <VerticalSpacer
        v-if="withSeparator"
        :size="spacing"
      />
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'SplitPageLayout',
  props: {
    screenSize: {
      type: String,
      default: null,
    },
    spacing: {
      type: String,
      default: 'm',
    },
    withSeparator: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const cssModifier = computed(() => (props.screenSize !== null ? `size-${props.screenSize}` : null));
    return { cssModifier };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>