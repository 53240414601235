const EnumFilter = (initial) => ({
  values: initial,

  get isActive() {
    let active = false;
    this.values.forEach((value) => {
      active = active || value.selected;
    });
    return active;
  },

  get forRequest() {
    if (!this.isActive) return undefined;
    let selectedValues = this.values.filter((v) => v.selected)

    return selectedValues.map((v) => v.value);
  },

  clear() {
    this.values.forEach((value) => value.selected = false);
  },
});

export default EnumFilter;
