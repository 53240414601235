<template>
  <img
    class="intro-logo"
    :src="source"
  >
</template>

<script>
import { useLocalizedImage } from '@/helpers/I18nHelpers';
import IntroLogoImageEn from '@/../assets/images/hgs-logo-with-vision-desc-en.svg';
import IntroLogoImageDe from '@/../assets/images/hgs-logo-with-vision-desc-de.svg';

const IntroLogoImages = {
  en: IntroLogoImageEn,
  de: IntroLogoImageDe,
};

export default {
  name: 'LocalizedBannerLogo',
  setup() {
    const source = useLocalizedImage(IntroLogoImages);
    return {
      source,
    };
  },
};
</script>
