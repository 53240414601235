// Triggers a callback when a click event occurs
// outside of the associated component. Example:
//  <div v-click-outside="callback" />
export default {
  beforeMount(el, binding) {
    /* eslint-disable no-param-reassign */
    const callback = binding.value;
    el['__v-click_outside'] = (event) => {
      // Detect a click outside of el if the event target is not contained in el.
      // Also ensure that the event target is still in the current DOM at all,
      // i.e. contained by body.
      // This is a workaround for an issue that popped up in specs where the SHOW MORE
      // button in the intro page publications form wrapped it's caret-icon to a new
      // line and capybara clicked exactly in the center.
      // The event.target then was the <svg> elemnt of the caret.
      // The button click expanded the card and toggled the caret icon.
      // Vue seems to haved renderd this change thus removing the original <svg>
      // from the dom before dispatching the click event on body here.
      // Then the card (el here) did not contain the <svg> anymore and the click
      // was misclassified as a "click-outside" and the card was collapsed again.
      //
      // This workaround now could cause the same effect in the reverse situation:
      // When another card is expanded clicking on that exact spot to get the <svg> as
      // target might now lead to the click outside of the other card would be ignored.
      // Since this does not affect specs, we choose this option.
      if (document.body.contains(event.target) && !el.contains(event.target)) {
        callback(event);
      }
    };
    document.body.addEventListener('click', el['__v-click_outside']);
    /* eslint-enable */
  },
  beforeUnmount(el) {
    document.body.removeEventListener('click', el['__v-click_outside']);
  },
};
