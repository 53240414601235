<template>
  <div class="loading-leaf-list">
    <SpinningLoader
      v-if="isLoading"
      class="loading-leaf-list--loader"
    />
    <DashboardAndClusterListLeafList
      :dashboards="dashboards"
      :show-favorites="showFavorites"
      :use-link-tags="useLinkTags"
      :selection="selection"
      :requires-write-permissions="requiresWritePermissions"
      @click-dashboard="onClick"
    />
  </div>
</template>

<script>
import DashboardAndClusterListLeafList from '../LeafList/Component.vue';

export default {
  name: 'DashboardAndClusterListLoadingLeafList',
  components: {
    DashboardAndClusterListLeafList,
  },
  props: {
    cluster: {
      type: Object,
      required: true,
    },
    showFavorites: {
      type: Boolean,
      default: false,
    },
    useLinkTags: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Object,
      default: () => null,
    },
    requiresWritePermissions: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click-dashboard',
  ],
  computed: {
    isLoading() {
      return this.cluster.isLoadingDashboards;
    },
    dashboards() {
      return this.cluster.dashboards;
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click-dashboard', { ...event, cluster: this.cluster });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>