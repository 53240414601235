/**
 * This is a small adapter class that acts as an interface between
 * the existing javascript table controllers and a Vue component that
 * does not have such a controller at all.
 */

class ControllerAdapter {
  constructor(vue) {
    this.vue = vue
  }

  get rawCollection() {
    if (!this._rawCollection) {
      this._rawCollection = this.vue.collection
    }
    return this._rawCollection
  }

  set notations(collection) {
    this.vue.collection = collection
  }

  get filters() {
    if (!this._filters) {
      this._filters = {}
      this.applyFilterOptions(this.vue.columns, this._filters)
    }
    return this._filters
  }
}

import FilterableControllerMixin from "@/utils/FilterableControllerMixin"
FilterableControllerMixin.apply(ControllerAdapter)

export default ControllerAdapter
