export default {
  props: {
    title: { type: String },
    path: { type: String }
  },
  inject: ['exports'],

  created() {
    this.exports.push(this.config)
  },

  computed: {
    config() {
      return {
        title: this.title,
        path: this.path,
      }
    }
  },

  render() {}
}
