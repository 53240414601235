<template>
  <span
    class="search-link"
    :class="cssModifier"
    @mousemove="$emit('mousemove', $event)"
  >
    <ToggleIcon
      v-if="canToggleFavorite"
      class="search-link--icon"
      :model-value="item.is_favorite"
      :fixed-width="true"
      :aria-label="toggleFavoriteLabel"
      name="star"
      @update:modelValue="onToggleFavorite"
    />
    <a
      :id="item.key"
      class="search-link--link"
      tabindex="-1"
      :href="href"
    >
      {{ item.name }}
    </a>
  </span>
</template>

<script>
import { ToggleDashboardFavorite } from '@/helpers/DashboardHelpers';
import {
  EventBus,
  DashboardFavoriteChangeEvent,
} from '@/events';

export default {
  name: 'DashboardSearchListLink',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    canToggleFavorite: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['mousemove'],
  computed: {
    href() {
      return `/dashboards/${this.item.id}`;
    },
    cssModifier() {
      return this.isActive ? 'is-active' : null;
    },
    toggleFavoriteLabel() {
      const args = { dashboard: this.item.name };
      return this.item.is_favorite
        ? this.$t('components.dashboard_search_list.remove_from_favorites', args)
        : this.$t('components.dashboard_search_list.add_to_favorites', args);
    },
  },
  methods: {
    onToggleFavorite(value) {
      ToggleDashboardFavorite(this.item, value)
        .then(() => {
          EventBus.emit(new DashboardFavoriteChangeEvent({
            dashboardId: this.item.id,
            isFavorite: value,
          }));
        });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>