<template>
  <div class="rich-text-modal-icon">
    <Icon :name="name" />
  </div>
</template>

<script>
export default {
  name: 'RichTextModalIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>