<template>
  <div
    class="dialog--window is-wide"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ heading }}
      </div>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <form @submit="save">
          <SpinningLoader v-if="isLoading" />
          <ProductGroupReportsEditForm
            v-else-if="initialFormData"
            ref="form"
            :initial-entry="initialFormData"
          />

          <ul class="dialog--action-list">
            <li class="dialog--action-list-item">
              <ButtonComponent
                variant="ternary"
                @click="cancel"
              >
                {{ $t('components.modals.cancel') }}
              </ButtonComponent>
            </li>
            <li class="dialog--action-list-item">
              <ButtonComponent
                type="submit"
                variant="primary"
                :disabled="!canSubmit"
              >
                {{ $t('components.modals.save') }}
              </ButtonComponent>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import ProductGroupReportsEditForm from '../EditForm/Component.vue';

export default {
  name: 'ProductGroupReportsEditModal',
  components: {
    ProductGroupReportsEditForm,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: true,
      canSubmit: false,
      initialContent: null,
    };
  },
  computed: {
    heading() {
      return this.$t('apps.product_group_reports.edit_modal.title', {
        product_group: this.entry.product_group.name,
      });
    },
    initialFormData() {
      if (!this.initialContent) return null;
      return {
        ...this.entry,
        content_for_edit: this.initialContent.content,
      };
    },
  },
  beforeMount() {
    if (!this.entry.published_at) {
      this.isLoading = false;
      this.canSubmit = true;
      this.initialContent = {
        content: null,
      };
    } else {
      this.loadContent();
    }
  },
  methods: {
    async loadContent() {
      try {
        const response = await axios.get(`/api/product_group_reports/${this.entry.product_group.id}/content_for_edit`);
        this.initialContent = response.data;
        this.canSubmit = true;
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit('close');
    },
    async save(e) {
      e.preventDefault();

      try {
        this.canSubmit = false;
        const response = await axios.put(`/api/product_group_reports/${this.entry.product_group.id}`, {
          product_group_report: this.$refs.form.entry,
        });
        this.controller.updateEntry(response.data);
        this.$emit('close');
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.canSubmit = true;
      }
    },
  },
};
</script>
