<template>
  <div class="chart-config-form--configuration">
    <TextInput
      :model-value="title"
      :label="$t('apps.chart_config.name')"
      class="chart-config-form--configuration-item"
      :required="true"
      @update:modelValue="(value) => $emit('update:title', value)"
    />
    <AxisFormCombobox
      :model-value="startMonthId"
      :label="$t('apps.season_line_chart_config.start_month')"
      class="chart-config-form--configuration-item"
      :options="months"
      :placeholder="$t('apps.chart_config.selectable')"
      @update:modelValue="(value) => $emit('update:start-month-id', value)"
    />
    <label class="axis-form--label">
      {{ $t('apps.season_line_chart_config.years_to_be_included') }}
    </label>
    <div class="chart-config--scrollbox">
      <div
        v-for="year in yearsWithQuotes"
        :key="year"
        class="chart-config--scrollbox-text"
      >
        <input
          :id="`season-line-chart-config-year-${year}`"
          type="checkbox"
          :value="year"
          :name="year"
          class="chart-config--checkbox"
          :checked="selectedYears.includes(year)"
          @input="(e) => $emit('update:selected-years', e.target.value)"
        >
        <label
          :for="`season-line-chart-config-year-${year}`"
        >
          {{ year }}
        </label>
      </div>
    </div>
    <AxisFormCombobox
      v-if="showCurrencyCombobox"
      :model-value="compositeUnit.currency_unit_id"
      :label="$t('apps.chart_config.currency')"
      :placeholder="$t('apps.chart_config.selectable')"
      class="chart-config-form--configuration-item"
      :options="availableCurrencies"
      @update:modelValue="(currencyUnitID) => $emit('update:composite-unit', {
        ...compositeUnit, currency_unit_id: currencyUnitID})"
    />
    <AxisFormCombobox
      v-if="showVolumeUnitCombobox"
      :model-value="compositeUnit.volume_unit_id"
      :label="$t('apps.chart_config.volume_unit')"
      :placeholder="$t('apps.chart_config.selectable')"
      class="chart-config-form--configuration-item"
      :options="availableVolumeUnits"
      @update:modelValue="(volumeUnitID) => $emit('update:composite-unit', {
        ...compositeUnit, volume_unit_id: volumeUnitID})"
    />
  </div>
</template>

<script>
import { AxisFormCombobox } from '@/components';
import { months } from '@/helpers/I18nHelpers';
import {
  hasCurrencyComponent,
  hasVolumeComponent,
} from '@/helpers/UnitCompositionHelpers';

export default {
  name: 'ChartConfigurationForm',
  components: {
    AxisFormCombobox,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    startMonthId: {
      type: Number,
      default: null,
    },
    selectedYears: {
      type: Array,
      default: null,
    },
    yearsWithQuotes: {
      type: Array,
      default: null,
    },
    compositeUnit: {
      type: Object,
      default: null,
    },
    availableVolumeUnits: {
      type: Object,
      required: true,
    },
    availableCurrencies: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'update:title',
    'update:start-month-id',
    'update:composite-unit',
    'update:selected-years',
  ],
  data() {
    return {
      months: months(),
    };
  },
  computed: {
    showCurrencyCombobox() {
      return hasCurrencyComponent(this.compositeUnit);
    },
    showVolumeUnitCombobox() {
      return hasVolumeComponent(this.compositeUnit);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>