<template>
  <div
    v-if="isOpen"
    class="dialog"
  >
    <div
      class="dialog--overlay"
      @click="close()"
    />

    <component
      :is="component.name"
      v-if="isOpen"
      v-bind="component.props"
      v-on="component.listeners"
      @close="close()"
    />
  </div>
</template>

<script>
import ConfirmationModal from '@/components/generic/ConfirmationModal.vue';
import ModalWindowController from '@/controllers/ModalWindowController';
// TODO: don't include components from DashboardsApp, instead find a way to
// only import those modals from inside the DashboardsApp
import {
  EditDashboardModal,
  CloneDashboardModal,
  PublishDashboardModal,
} from '@/apps/Dashboard/components';
import {
  PublicationsEditModal,
  PublicationsUploadModal,
  NewsModal,
  PublicationModal,
} from '@/apps/Publications/components';
import {
  ProductGroupReportsEditModal,
} from '@/apps/ProductGroupReports/components';
import ProductGroupReportModal from '@/components/ProductGroupReportModal/Component.vue';
import RichTextModal from '@/components/RichTextModal/Component.vue';
import TopTermSearchModal from '@/components/TopTermSearchModal/Component.vue';
import AddNotationsToDashboardModal from '@/components/AddNotationsToDashboardModal/Component.vue';
import CurrencyModal from '@/components/CurrencyModal/Component.vue';
import AddNotationsToChartConfigModal from '@/components/AddNotationsToChartConfigModal/Component.vue';
import DashboardHeaderModal from '@/components/DashboardHeaderModal/Component.vue';
import NotationAnalysisNamePromptModal from '@/components/NotationAnalysisNamePromptModal/Component.vue';
import SourceDisclaimerModal from '@/components/SourceDisclaimerModal/Component.vue';

export default {
  name: 'ModalWindowApp',
  components: {
    ConfirmationModal,
    EditDashboardModal,
    CloneDashboardModal,
    CurrencyModal,
    PublishDashboardModal,
    PublicationsEditModal,
    PublicationsUploadModal,
    ProductGroupReportsEditModal,
    ProductGroupReportModal,
    NewsModal,
    PublicationModal,
    RichTextModal,
    TopTermSearchModal,
    AddNotationsToDashboardModal,
    AddNotationsToChartConfigModal,
    DashboardHeaderModal,
    NotationAnalysisNamePromptModal,
    SourceDisclaimerModal
  },
  computed: {
    component() {
      return ModalWindowController.component;
    },
    isOpen() {
      return this.component != null;
    },
  },
  methods: {
    close() {
      ModalWindowController.close();
    },
  },
};
</script>
