<template>
  <ul>
    <li
      v-for="dashboard in dashboardsWithWritePermissions"
      :key="dashboard.id"
      class="cluster-list--item"
      @mouseenter="onFocusItem(dashboard)"
      @mouseleave="onBlurItem(dashboard)"
    >
      <DashboardAndClusterListLeafListItem
        :dashboard="dashboard"
        :show-favorites="showFavorites"
        :use-link-tag="useLinkTags"
        :is-selected="isSelected(dashboard)"
        @click="onClick"
      />
    </li>
  </ul>
</template>

<script>
import DashboardAndClusterListLeafListItem from './Item/Component.vue';

export default {
  name: 'DashboardAndClusterListLeafList',
  components: {
    DashboardAndClusterListLeafListItem,
  },
  props: {
    dashboards: {
      type: Array,
      default: () => [],
    },
    showFavorites: {
      type: Boolean,
      default: false,
    },
    useLinkTags: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Object,
      default: () => null,
    },
    requiresWritePermissions: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'focus-item',
    'blur-item',
    'click-dashboard',
  ],
  computed: {
    dashboardsWithWritePermissions() {
      if (this.requiresWritePermissions) {
        return this.dashboards.filter((d) => d.can_update);
      }
      return this.dashboards;
    },
  },
  methods: {
    onFocusItem(dashboard) {
      this.$emit('focus-item', dashboard);
    },
    onBlurItem(dashboard) {
      this.$emit('blur-item', dashboard);
    },
    onClick(event) {
      this.$emit('click-dashboard', event);
    },
    isSelected(dashboard) {
      return this.selection?.dashboard?.id === dashboard.id;
    },
  },
};
</script>
