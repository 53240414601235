const DefaultAnnotationConfig = {
  color: '#DD0029',
  lineWidth: 2,
};

const renderVerticalLine = (chart, annotation) => {
  const context = chart.chart.ctx;

  const xscale = chart.scales['x-axis-1'];
  const yscale = chart.scales['y-axis-1'];

  const { x } = annotation;
  const relativeX = (x - xscale.min) / (xscale.max - xscale.min);
  const canvasX = xscale.left + relativeX * (xscale.width);

  // render vertical line
  if (relativeX >= 0 && relativeX <= 1) {
    context.beginPath();
    context.strokeStyle = DefaultAnnotationConfig.color;
    context.lineWidth = DefaultAnnotationConfig.lineWidth;
    context.moveTo(canvasX, yscale.top);
    context.lineTo(canvasX, yscale.bottom);
    context.stroke();
  }
  // write label
  // context.fillStyle = '#CB001D88';
  // context.textAlign = 'center';
  // context.fillText(`${x}`, canvasX, (yscale.bottom - yscale.top) / 2 + yscale.top);
};

const renderHorizontalLine = (chart, annotation) => {
  if (annotation.left === null) return;

  const context = chart.chart.ctx;

  const xscale = chart.scales['x-axis-1'];
  const yscale = chart.scales[annotation.yAxis];

  const x = Math.max(annotation.left, xscale.min);
  const { y } = annotation;

  const relativeX = (x - xscale.min) / (xscale.max - xscale.min);
  const canvasX = xscale.left + relativeX * xscale.width;

  const relativeY = (y - yscale.min) / (yscale.max - yscale.min);
  const canvasY = yscale.bottom - relativeY * (yscale.height);

  // render horizontal line
  if (relativeX <= 1 && y >= yscale.min && y <= yscale.max) {
    try {
      context.beginPath();

      context.setLineDash([10, 4]);

      context.strokeStyle = annotation.color;
      context.lineWidth = DefaultAnnotationConfig.lineWidth;
      context.moveTo(canvasX, canvasY);
      context.lineTo(xscale.right, canvasY);
      context.stroke();
    } finally {
      context.setLineDash([]);
    }
  }
};

const renderBox = (chart, annotation) => {
  const context = chart.chart.ctx;

  const xscale = chart.scales['x-axis-1'];
  const yscale = chart.scales['y-axis-1'];

  const { xMin, xMax } = annotation;
  let relativeXMin = (xMin - xscale.min) / (xscale.max - xscale.min);
  let relativeXMax = (xMax - xscale.min) / (xscale.max - xscale.min);

  // Clip box left and right if it goes out of viewport
  relativeXMin = Math.max(0, relativeXMin);
  relativeXMax = Math.min(1, relativeXMax);

  // Don't draw anything if entire box is out of viewport
  if (relativeXMax < 0 || relativeXMin > 1) return;

  const width = (relativeXMax - relativeXMin) * (xscale.width);
  const canvasXMin = xscale.left + relativeXMin * (xscale.width);
  const height = yscale.bottom - yscale.top;

  context.font = 'bold 12px sans-serif';
  const textWidth = context.measureText(annotation.label).width;

  const labelPaddingPx = 2;
  const labelBorderOffset = textWidth / 2 + labelPaddingPx;
  const canvasXMiddle = canvasXMin + width / 2;

  let labelXMiddle = canvasXMiddle;
  labelXMiddle = Math.max(labelXMiddle, xscale.left + labelBorderOffset);
  labelXMiddle = Math.min(labelXMiddle, xscale.right - labelBorderOffset);

  const labelXMin = labelXMiddle - (textWidth / 2);
  const labelYMin = yscale.top + 2;

  // Draw box and label
  context.beginPath();
  context.fillStyle = annotation.color;
  context.fillRect(canvasXMin, yscale.top, width, height);
  context.fillStyle = ('black');
  context.fillText(annotation.label, labelXMin, labelYMin);

  context.stroke();
};

export default {
  afterDatasetsDraw(chart) {
    if (chart.config.data.annotations) {
      chart.config.data.annotations.forEach((annotation) => {
        switch (annotation.type) {
          case 'VerticalLine':
            return renderVerticalLine(chart, annotation);
          case 'HorizontalLine':
            return renderHorizontalLine(chart, annotation);
          case 'Box':
            return renderBox(chart, annotation);
          default:
            throw new Error(`Unknown annotation type ${annotation.type}`);
        }
      });
    }
  },
};
