<template>
  <NotationDetailsTagListItem
    class="tag-list--item"
    :is-highlighted="showConvertedFromUnit"
    :tooltip="$t('attributes.notation.unit')"
  >
    {{ text }}
    <span
      v-if="showConvertedFromUnit"
      class="is-unobtrusive"
    >
      {{ convertedFromUnitText }}
    </span>
  </NotationDetailsTagListItem>
</template>

<script>
import NotationDetailsTagListItem from '../Item/Component.vue';

export default {
  name: 'NotationDetailsTagListUnitItem',
  components: {
    NotationDetailsTagListItem,
  },
  props: {
    unit: {
      type: String,
      default: null,
    },
    unitConvertedFrom: {
      type: String,
      default: null,
    },
  },
  computed: {
    showConvertedFromUnit() {
      return !!this.unit && !!this.unitConvertedFrom;
    },
    text() {
      if (!this.unit) return '&ndash;';
      return this.unit;
    },
    convertedFromUnitText() {
      return this.$t('components.notation_details.tag_list.from_unit', {
        unit: this.unitConvertedFrom,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>