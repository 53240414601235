<template>
  <div class="filter">
    <!-- eslint-disable vue/no-mutating-props -->
    <TextInput
      v-model="controller.textFilter"
      class="filter--name-input"
      :label="$t('apps.product_group_reports.filters.name_label')"
      :placeholder="$t('apps.product_group_reports.filters.name_placeholder')"
    />
    <!-- eslint-enable vue/no-mutating-props -->
  </div>
</template>

<script>
export default {
  name: 'ProductGroupReportsFilter',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>