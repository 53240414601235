<template>
  <ButtonWithDropdown
    ref="buttonWithDropdown"
    variant="secondary"
    icon="caret-down"
    icon-variant="light"
    :is-disabled="!canExportAny"
  >
    {{ $t('components.notation.buttons.export') }}

    <template #dropdown>
      <DropdownMenu>
        <DropdownMenuButton
          v-if="canExportPrimary"
          @click="saveToExcel(primaryNotationDetails)"
        >
          <template #icon>
            <NotationColorIcon
              slot-kind="primary"
            />
          </template>
          {{ $t('components.notation.buttons.excel_export_primary') }}
        </DropdownMenuButton>
        <DropdownMenuButton
          v-if="canExportSecondary"
          @click="saveToExcel(secondaryNotationDetails)"
        >
          <template #icon>
            <NotationColorIcon
              slot-kind="secondary"
            />
          </template>
          {{ $t('components.notation.buttons.excel_export_secondary') }}
        </DropdownMenuButton>
      </DropdownMenu>
    </template>
  </ButtonWithDropdown>
</template>

<script>
import NotationColorIcon from '@/components/NotationColorIcon/Component.vue';
import NotationExcelExport from '@/services/NotationExcelExport';
import WatchListApi from "../../../apps/WatchList/api/WatchListApi";

export default {
  name: 'NotationCardFooterExportButton',
  components: {
    NotationColorIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    graphLimits: {
      type: Object,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    selectedCurrencyId: {
      type: Number,
      default: null,
    },
    whitelistedSources: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    canExportPrimary() {
      return this.graphLimits && !!this.primaryNotationDetails?.hasLoaded;
    },
    canExportSecondary() {
      return this.graphLimits && !!this.secondaryNotationDetails?.hasLoaded;
    },
    canExportAny() {
      return !this.disabled && (this.canExportPrimary || this.canExportSecondary);
    },
  },
  methods: {
    saveToExcel({ notation, data: { quotes, unit } }) {
      if (this.disabled) return;
      const filteredQuotes = quotes.filter((quote) => (
        new Date(quote.date) >= new Date(this.graphLimits.startDate)
        && new Date(quote.date) <= new Date(this.graphLimits.endDate)));
      NotationExcelExport.SaveToExcel({
        notation,
        quotes: filteredQuotes,
        unit,
        whitelistedSources: this.whitelistedSources,
      });
    }
  },
};
</script>
