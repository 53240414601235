<template>
  <div class="form--box">
    <div class="form--checkbox-wrapper">
      <input
        :id="uid"
        type="checkbox"
        :checked="modelValue"
        :required="required"
        :disabled="disabled"
        @change="onChange"
      >
      <label
        v-if="label"
        class="form--checkbox-label"
        :for="uid"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';

export default {
  name: 'CheckboxInput',
  mixins: [HasUid],
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
  },
};
</script>
