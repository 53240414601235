<template>
  <div
    class="trix-editor"
    :class="{'is-disabled': disabled}"
  >
    <trix-editor
      :id="editorId"
      ref="trix"
      class="trix-content"
      :input="uid"
      :contenteditable="!disabled"
      data-direct-upload-url="/rails/active_storage/direct_uploads"
      data-blob-url-template="/rails/active_storage/blobs/:signed_id/:filename"
      @trix-change="trixChanged"
      @keydown="trixKeyDown"
    />
    <input
      :id="uid"
      ref="input"
      type="hidden"
      :disabled="disabled"
      :value="currentValue"
    >
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';

export default {
  name: 'TrixRails',
  mixins: [HasUid],
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editorId: {
      type: String,
      default: null,
    },
  },
  emits: ['input'],
  data() {
    return {
      currentValue: this.value || '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const content = this.value || '';
        if (content !== this.currentValue) {
          this.currentValue = content;
          this.$refs.trix.editor.loadHTML(this.currentValue);
          this.$refs.trix.editor.setSelectedRange(this.getContentEndPosition());
        }
      },
    },
    disabled() {
      this.setEditable(!this.disabled);
    },
  },
  mounted() {
    this.setEditable(!this.disabled);
  },
  methods: {
    trixChanged(event) {
      this.currentValue = event.target.value;
      this.$emit('input', this.currentValue);
    },
    trixKeyDown(event) {
      if (event.key === 'Enter' && event.getModifierState('Control')) {
        event.preventDefault();
        this.$refs.input.form?.requestSubmit();
      }
    },
    getContentEndPosition() {
      return this.$refs.trix.editor.getDocument().toString().length - 1;
    },
    setEditable(editable) {
      this.$refs.trix.contentEditable = editable;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>