<template>
  <div class="legend-item--details">
    <div class="legend-item--wrapper">
      <h2 class="legend-item--title">
        <span class="legend-item--icon">
          <ChartLegendIcon
            :color="notationColor"
            :is-selected="isSelected"
            :class="{'legend-item--reduced-opacity': indexQuotesNotAvailable}"
            :not-found="notation.notFound"
            @update:is-selected="(selectedLine) => $emit('update:is-selected', selectedLine)"
          />
        </span>
        <template v-if="notation.notFound === true">
          <span class="legend-item--not-found">
            {{ $t('apps.multi_line_chart_view.notation_not_found') }}
          </span>
        </template>
        <template v-else>
          <span :class="{'legend-item--reduced-opacity': indexQuotesNotAvailable}">
            <span
              v-if="classPrefix"
              class="legend-item--class"
            >
              {{ classPrefix }}
            </span>
            <span class="legend-item--name">
              {{ notation.name }}
            </span>
          </span>
        </template>
        <IconTooltipComponent
          v-if="indexQuotesNotAvailable"
          :tooltip="$t('apps.multi_line_chart_view.index_view_tooltip')"
          class="legend-item--index-tooltip"
        />
      </h2>
      <template v-if="notation.notFound === true">
        <p class="legend-item--not-found-description">
          {{ $t('apps.multi_line_chart_view.not_found_description') }}
        </p>
      </template>
      <NotationDetailsTagList
        v-else
        :notation="notation"
        :class="{'legend-item--reduced-opacity': indexQuotesNotAvailable}"
        :unit-converted-from="unitConvertedFrom"
        :unit="unit"
      />
    </div>
    <KpiList
      class="legend-item--kpi-wrapper"
      :key-performance-indicators="notation.key_performance_indicators"
      :timescope="notation.timescope"
      :latest-value="notation.latest_value"
      :latest-date="notation.latest_date"
      :show-absolute-prices="showAbsolutePrices"
      :current-value-range="notation.current_value_range"
    />
  </div>
</template>

<script>
import NotationDetailsTagList from '@/components/NotationDetails/TagList/Component.vue';
import { configureClassPrefix } from '@/helpers/ClassPrefixHelpers';
import { ChartLegendIcon } from '@/components';
import KpiList from './KpiList/Component.vue';

export default {
  name: 'ChartLegendItem',
  components: {
    NotationDetailsTagList,
    ChartLegendIcon,
    KpiList,
  },
  props: {
    notation: {
      type: Object,
      default: null,
    },
    notationColor: {
      type: String,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
    unitConvertedFrom: {
      type: String,
      default: null,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    showIndexView: {
      type: Boolean,
      required: true,
    },
    withQuotes: {
      type: Boolean,
      required: true,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:is-selected'],
  computed: {
    classPrefix() {
      return configureClassPrefix(
        this.notation.class_system,
        this.notation.class_code,
      );
    },
    indexQuotesNotAvailable() {
      return this.showIndexView && !this.withQuotes;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>