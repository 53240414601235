<template>
  <div class="axis-form--selection-group">
    <label
      v-if="label"
      class="axis-form--label"
    >
      {{ label }}
    </label>

    <AutoComplete
      :options="options"
      :model-value="modelValue"
      :access-key="(o) => o.id"
      :access-value="(o) => o.name"
      :placeholder="placeholder"
      :disabled="disabled"
      :is-full-width="true"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script>

export default {
  name: 'AxisFormCombobox',
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>