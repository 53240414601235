import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import { stringifyStable } from '@/helpers';
import setTimeoutPromise from '@/helpers/setTimeoutPromise';
import GetFeatureSwitches from '@/config/GetFeatureSwitches';

// When using currency conversion, each request for dashboard notations needs to load
// all the notations and convert all the KPIs to allow for sorting.
// To avoid repeating this work for each page, we decided to instead return all data
// in a single page.
// Since rendering becomes very slow for lots of columns we still want to use endless-
// scrolling pagination, which this repository emulates.
// It loads all data with the first request but only returns a first page full of entries.
// Subsequent requests for the following pages are just sliced from the original response.
//
// When no currenct conversion is requested in the params, we just send the request
// directly and use backend-pagination.
class DashboardNotationsRepository {
  #url;

  #currentCacheKey;

  #apiResponse;

  constructor(dashboard) {
    this.#url = `/api/dashboards/${dashboard.id}`;
    this.#currentCacheKey = null;
    this.#apiResponse = null;
  }

  async notations(requestPayload, cancelToken) {
    // Use backend-pagination when not using currency conversion
    const tableConversionEnabled = GetFeatureSwitches().CURRENCY_CONVERSION_DASHBOARD_TABLE;
    const currencyConversionUsed = !!requestPayload.to_currency_unit_id;
    if (!tableConversionEnabled || !currencyConversionUsed) {
      this.#currentCacheKey = null;
      this.#apiResponse = null;
      return this.#fetchNotations(requestPayload, cancelToken);
    }

    const payload = this.#transformPayloadToLoadAllPages(requestPayload);
    const key = stringifyStable(payload);

    if (key === this.#currentCacheKey) {
      // Make loading happen asynchronously to make loading spinner show up
      await setTimeoutPromise(0);
      return this.#slicePage(requestPayload.page);
    }
    this.#currentCacheKey = key;
    this.#apiResponse = null;
    this.#apiResponse = await this.#fetchNotations(payload, cancelToken);
    return this.#slicePage(requestPayload.page);
  }

  #slicePage(page) {
    const perPage = 50;
    const startIndex = page * perPage;
    const endIndex = (page + 1) * perPage;
    const hasMoreResults = endIndex < this.#apiResponse.data.entries.length;
    const data = {
      entries: this.#apiResponse.data.entries.slice(startIndex, endIndex),
      next_page: hasMoreResults ? page + 1 : null,
    };
    return { ...this.#apiResponse, data };
  }

  /* eslint-disable-next-line class-methods-use-this */
  #transformPayloadToLoadAllPages(payload) {
    return ApiHelpers.removeNullKeys({ ...payload, page: undefined });
  }

  #fetchNotations(payload, cancelToken) {
    return axios.post(`${this.#url}/notations`, payload, {
      cancelToken,
    });
  }
}

export default DashboardNotationsRepository;
