<template>
  <div>
    <div class="filter-dialog--head-section">
      <p class="filter-dialog--head-section-title">
        {{ $t('components.notation_filters.captions.sort_direction') }}
      </p>
    </div>

    <div class="filter-dialog--section no-vpadding no-hpadding">
      <ul class="filter-for-enums--checkbox-list">
        <li
          v-for="option in sortOptions"
          :key="option"
          class="filter-for-enums--checkbox-list-item"
          :class="classForDirection(option)"
          @click="changeDirection(option)"
        >
          <Icon
            class="filter-for-enums--checkbox-icon-checked"
            name="check"
          />
          <i18n-t
            tag="span"
            class="filter-for-enums--checkbox-label"
            :keypath="`components.notation_filters.buttons.sort_${option}`"
          >
            <template #icon>
              <Icon name="long-arrow-right" />
            </template>
          </i18n-t>
          <Icon
            class="filter-for-enums--checkbox-icon-unchecked"
            name="times"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const SORT_DIRECTIONS = ['unsorted', 'asc', 'desc'];
const validateSortDirection = (value) => SORT_DIRECTIONS
  .indexOf(value) !== -1;

export default {
  name: 'FilterSortSection',
  props: {
    direction: {
      type: String,
      default: 'unsorted',
      validator: validateSortDirection,
    },
  },
  emits: ['change'],
  data() {
    return {
      sortOptions: ['asc', 'desc'],
    };
  },
  methods: {
    changeDirection(direction) {
      const newDirection = direction === this.direction
        ? 'unsorted'
        : direction;
      this.$emit('change', newDirection);
    },
    classForDirection(direction) {
      return direction === this.direction
        ? 'is-selected'
        : undefined;
    },
  },
};
</script>
