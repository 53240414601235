class NotationChartStyle {
  constructor({ yAxisID, backgroundColor, borderColor }) {
    this.yAxisID = yAxisID;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
  }
}

export const NotationChartStyles = {
  PRIMARY: new NotationChartStyle(
    {
      yAxisID: 'y-axis-1',
      backgroundColor: 'rgba(255, 184, 37, 0.067)',
      borderColor: 'rgb(255, 184, 37)',
    },
  ),
  SECONDARY: new NotationChartStyle(
    {
      yAxisID: 'y-axis-2',
      backgroundColor: 'rgba(204, 211, 222, 0.067)',
      borderColor: 'rgb(204, 211, 222)',
    },
  ),
};

const quoteToChartPoint = (quote) => ({ x: quote.date, y: quote.value });

export class NotationChartDescription {
  #quotes;

  #chartStyle;

  #unit;

  #unitConvertedFrom;

  #convertedData;

  constructor({ notationDetails, chartStyle }) {
    const { quotes, unit, unitConvertedFrom } = notationDetails?.data || {};
    this.#quotes = quotes;
    this.#unit = unit;
    this.#unitConvertedFrom = unitConvertedFrom;
    this.#chartStyle = chartStyle;
  }

  get convertedData() {
    this.#convertedData ??= this.#quotes?.map(quoteToChartPoint);
    return this.#convertedData;
  }

  empty() {
    return !this.#quotes;
  }

  toDataSet() {
    if (this.empty()) {
      return {};
    }

    return {
      ...this.#chartStyle,
      unit: this.#unit,
      data: this.convertedData,
      lineTension: 0,
      borderWidth: 2,
      showLine: true,
      borderDash: this.#unitConvertedFrom ? [10, 5] : undefined,
    };
  }
}
