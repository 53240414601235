<template>
  <ul class="dashboard-bar">
    <DashboardTab
      :tab="dashboardTab"
      :is-active="isCurrentTab(dashboardTab)"
    />
    <template
      v-if="$features.PUBLICATIONS"
    >
      <DashboardTabWithPopup
        :tab="publicationsTab"
        :item-loader="publicationsLoader"
        :is-active="isCurrentTab(publicationsTab)"
        :translations="$tm('components.welcome_banner.publications')"
      >
        <template #item="slotProps">
          <WelcomeBannerItemForPublication :item="slotProps.item" />
        </template>
      </DashboardTabWithPopup>
      <DashboardTabWithPopup
        :tab="newsTab"
        :item-loader="newsLoader"
        :translations="$tm('components.welcome_banner.news')"
        :is-active="isCurrentTab(newsTab)"
      >
        <template #item="slotProps">
          <WelcomeBannerItemForNews :item="slotProps.item" />
        </template>
      </DashboardTabWithPopup>
      <DashboardTab
        :tab="tradeFlowsTab"
        :is-active="isCurrentTab(tradeFlowsTab)"
      />
    </template>
  </ul>
</template>

<script>
import { ref } from 'vue';
import { useNewsUnreadCount, usePublicationUnreadCount } from '@/helpers/PublicationHelpers';
import LoadingService from '@/services/LoadingService';
import WelcomeBannerItemForNews from '@/apps/Intro/components/WelcomeBanner/Item/ForNews/Component.vue';
import WelcomeBannerItemForPublication from '@/apps/Intro/components/WelcomeBanner/Item/ForPublication/Component.vue';
import {
  fetchPublicationsForProductGroups,
} from '@/api/ProductGroupsApi';
import DashboardTab from './DashboardTab/Component.vue';
import DashboardTabWithPopup from './DashboardTabWithPopup/Component.vue';

export default {
  name: 'DashboardTabs',
  components: {
    DashboardTab,
    DashboardTabWithPopup,
    WelcomeBannerItemForNews,
    WelcomeBannerItemForPublication,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newsUnreadCount: this.dashboard.unread_news_count,
      publicationUnreadCount: this.dashboard.unread_publication_count,
      observers: [],
      publicationsLoader: new LoadingService(),
      newsLoader: new LoadingService(),
      newsUnreadCountObserver: null,
      publicationUnreadCountObserver: null,
    };
  },
  computed: {
    dashboardTab() {
      return {
        name: this.$t('apps.dashboards.tabs.dashboard'),
        href: `/dashboards/${this.dashboard.id}`,
      };
    },
    newsTab() {
      return {
        name: this.$t('apps.dashboards.tabs.news'),
        href: `/dashboards/${this.dashboard.id}/news`,
        disabled: !this.dashboard.has_news,
        unreadCount: this.newsLoader.data?.unreadCount || 0,
      };
    },
    publicationsTab() {
      return {
        name: this.$t('apps.dashboards.tabs.publications'),
        href: `/dashboards/${this.dashboard.id}/publications`,
        disabled: !this.dashboard.has_publications,
        unreadCount: this.publicationsLoader.data?.unreadCount || 0,
      };
    },
    tradeFlowsTab() {
      return {
        name: this.$t('apps.dashboards.tabs.trade_flows'),
        href: `/dashboards/${this.dashboard.id}/trade-flows`,
      };
    },
  },
  beforeMount() {
    this.loadPublicationsAndNews();
  },
  beforeUnmount() {
    this.newsUnreadCountObserver?.off();
    this.publicationUnreadCountObserver?.off();
  },
  methods: {
    loadPublicationsAndNews() {
      const dashboardProductGroups = this.dashboard.product_groups || [];
      // Calling load immediately (not just after productGroups have loaded), to show loading state
      // in both publication and news cards while the productGroups are loading.
      this.publicationsLoader.load(this.loadReactivePublications(dashboardProductGroups));
      this.newsLoader.load(this.loadReactiveNews(dashboardProductGroups));
    },
    async loadReactiveNews(productGroups) {
      const data = await fetchPublicationsForProductGroups('/api/news', productGroups);
      const unreadCountRef = ref(data.meta.unread_count);
      this.newsUnreadCountObserver?.off();
      this.newsUnreadCountObserver = useNewsUnreadCount(unreadCountRef);
      return {
        entries: data.entries,
        unreadCount: unreadCountRef,
      };
    },
    async loadReactivePublications(productGroups) {
      const data = await fetchPublicationsForProductGroups('/api/publications', productGroups);
      const unreadCountRef = ref(data.meta.unread_count);
      this.publicationUnreadCountObserver?.off();
      this.publicationUnreadCountObserver = usePublicationUnreadCount(unreadCountRef);
      return {
        entries: data.entries,
        unreadCount: unreadCountRef,
      };
    },
    isLinkTab(tab) {
      return !!tab.href;
    },
    isCurrentTab(tab) {
      const basePath = window.location.pathname.split('?');

      return basePath[0] === tab.href;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>