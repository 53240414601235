<template>
  <button
    class="bar-item"
    :class="{'is-active': isActive}"
    :disabled="notClickable"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'StatusBreadcrumbsItem',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    notClickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>