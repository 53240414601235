<template>
  <div class="item">
    <p class="item--title">
      <WithTooltip :tooltip="tooltip">
        {{ title }}
      </WithTooltip>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NotationDetailsKpiListItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>