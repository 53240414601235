<template>
  <DropdownMenuButton
    icon="chart-line"
    :disabled="notationIds.length > 5"
    @click="startAnalysis()"
  >
    {{ buttonText }}
  </DropdownMenuButton>
</template>

<script>
import Turbolinks from 'turbolinks';

export default {
  props: {
    notationIds: {
      type: Array,
      required: true,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    buttonText() {
      return this.$t('components.search_header.batch_actions.start_multi_line_chart_analysis');
    },
  },
  methods: {
    startAnalysis() {
      const notationQueryString = this.notationIds.map((id) => `notation_id[]=${id}`).join('&');
      const dashboardQueryString = this.dashboardId ? `&dashboard_id=${this.dashboardId}` : '';
      Turbolinks.visit(`/multi-line-chart-configuration?${notationQueryString}${dashboardQueryString}`);
    },
  },
};
</script>
