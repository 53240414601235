<template>
  <ButtonWithDropdown
    icon="ellipsis-v"
    variant="ternary"
    size="large"
    padding="none"
    class="notation-analysis--actions"
    :icon-fixed-width="true"
    dropdown-alignment="right"
    :has-text="false"
  >
    <template #dropdown>
      <DropdownMenu>
        <DropdownMenuButton
          icon="chart-line"
          @click="$emit('show-chart')"
        >
          {{ $t('apps.notation_analyses.display_chart') }}
        </DropdownMenuButton>
        <DropdownMenuButton
          icon="trash-alt"
          @click="deleteAnalysis()"
        >
          {{ $t('apps.notation_analyses.delete_analysis') }}
        </DropdownMenuButton>
        <DropdownMenuButton
          icon="copy"
          @click="onDuplicateAnalysis()"
        >
          {{ $t('apps.notation_analyses.duplicate_analysis') }}
        </DropdownMenuButton>
      </DropdownMenu>
    </template>
  </ButtonWithDropdown>
</template>

<script>
import ModalWindowController from '@/controllers/ModalWindowController';
import i18n from '@/config/i18n';

export default {
  name: 'NotationAnalysisListItemActions',
  props: {
    analysisTitle: {
      type: String,
      required: true,
    },
  },
  emits: ['show-chart', 'delete-analysis', 'duplicate-analysis'],
  methods: {
    deleteAnalysis() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.$t('apps.notation_analyses.delete_analysis_confirmation', { title: this.analysisTitle }),
        },
        events: {
          confirm: () => this.$emit('delete-analysis'),
        },
      });
    },
    onDuplicateAnalysis() {
      ModalWindowController.show('NotationAnalysisNamePromptModal', {
        props: {
          initialTitle: `${this.analysisTitle} - ${i18n.t('apps.notation_analyses.copy')}`,
        },
        events: {
          save: (title) => {
            this.$emit('duplicate-analysis', title);
          },
        },
      });
    },
  },
};
</script>
