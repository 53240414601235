<template>
  <SpinningLoader v-if="controller.isLoading" />
  <div
    v-else-if="controller.filteredEntries.length === 0"
    class="list--empty-message"
  >
    {{ $t('apps.product_group_reports.list.empty') }}
  </div>
  <ul
    v-else
    class="list"
  >
    <ProductGroupReportsListItem
      v-for="entry in controller.filteredEntries"
      :key="entry.id"
      :controller="controller"
      :entry="entry"
    />
  </ul>
</template>

<script>
import ProductGroupReportsListItem from './Item/Component.vue';

export default {
  name: 'ProductGroupReportsList',
  components: {
    ProductGroupReportsListItem,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>