<script>
import PrintLayout from "@/components/PrintLayout/Component.vue";
import PrintLayoutInstanceDefaultHeader from "@/components/PrintLayout/Instance/DefaultHeader/Component.vue";

export default {
  components: {
    PrintLayout,
    PrintLayoutInstanceDefaultHeader
  }
}
</script>

<template>
  <PrintLayout
    :header-only-first-page="true"
  >
    <template #header>
      <PrintLayoutInstanceDefaultHeader class="low-margin-bottom">
        <template #content>
          <p>{{ $t('generic.for_internal_use_only') }}</p>
        </template>
      </PrintLayoutInstanceDefaultHeader>
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayout>
</template>
