<template>
  <ul class="date-selection-bar">
    <li
      v-for="range in ranges"
      :key="range.valueInDays"
      class="date-selection-bar--item"
    >
      <component
        :is="'button'"
        class="date-selection-bar--button"
        :class="{'is-active': isCurrentRange(range)}"
        @click="onRangeClicked(range)"
      >
        <slot
          name="tab-content"
          :range="range"
        >
          {{ $t(`components.date_range_button.${range.name}_months`) }}
        </slot>
      </component>
    </li>
  </ul>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateSelectionBar',
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    ranges: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['update:modelValue', 'select'],
  methods: {
    isCurrentRange(range) {
      return range.valueInDays === this.modelValue;
    },
    onRangeClicked(range) {
      this.$emit('update:modelValue', range.valueInDays);
      this.onSelected(range.valueInDays);
    },
    onSelected(days) {
      const startDate = moment().subtract(days, 'days').startOf('day');
      this.$emit('select', startDate);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>