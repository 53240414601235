<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    {{ formattedValue }}
  </td>
</template>

<script>
import { formatCompactDateWithTimescope } from '../../../../helpers/DateFormats';

export default {
  name: 'DateColumn',
  props: {
    value: {
      type: Object,
      required: true,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedValue() {
      return formatCompactDateWithTimescope(this.value.date, this.value.timescope);
    },
  },
};
</script>
