import moment from 'moment';
import i18n from '@/config/i18n';
import GetFeatureSwitches from '@/config/GetFeatureSwitches';
import { ColumnConfiguration } from './Columns';

const mapObject = (object, fn) => Object.keys(object)
  .reduce((aggr, key) => {
    aggr[key] = fn(object[key]);
    return aggr;
  }, {});

const objectLookup = (object, keys) => keys
  .reduce((aggr, key) => ((aggr && aggr[key]) ? aggr[key] : null), object);

const batchSelectValueCallback = (column, notation) => ({
  name: notation.isChecked ? 'check-square' : 'square',
  variant: notation.isChecked ? 'solid' : 'regular',
});

const dateWithTimescopeValueCallback = (column, notation) => ({
  date: notation.latest_date,
  timescope: notation.timescope,
});

const hiddenForCustomisationValueCallback = (column, notation) => ({
  name: notation.is_hidden_for_customisation ? 'eye-slash' : 'eye',
  variant: notation.is_hidden_for_customisation ? 'regular' : 'solid',
});

const hiddenForClientsValueCallback = (column, notation) => ({
  name: notation.is_hidden_for_clients ? 'user-slash' : 'user',
  variant: notation.is_hidden_for_clients ? 'solid' : 'regular',
});

const watchedValueCallback = (column, notation) => ({
  name: 'binoculars',
  variant: notation.is_watched ? 'solid' : 'regular',
});

const nameValueCallback = (column, notation) => ({
  name: notation.name,
  class_system: notation.class_system,
  class_code: notation.class_code,
  id: notation.id,
});

const dashboardNameValueCallback = (column, notation) => ({
  name: notation.dashboard_name.name,
  product_groups: notation.dashboard_name.product_groups,
  own_view_active: notation.own_view_active,
});

const sourceValueCallback = (column, notation) => ({
  name: notation.source,
  own_calculation: notation.own_calculation,
  disclaimer: notation.source_disclaimer,
});

const unitValueCallback = (i18nPrefix) => (column, notation) => ({
  unit: notation.unit,
  unit_converted_from: notation.unit_converted_from,
  preferred_currency: notation.preferred_currency,
  i18nPrefix,
});

const kpiValueCallback = (key) => (column, notation) => ({
  latest_value: notation.latest_value,
  kpi: notation.key_performance_indicators[key],
});

const aggregateKpiValueCallback = (key) => (column, notation) => ({
  latest_value: notation.latest_value,
  kpi: objectLookup(notation, ['key_performance_indicators', 'custom_date', key]),
});

const translateValueCallback = (column, notation) => {
  const value = notation[column.key];
  return {
    original: value,
    translation: i18n.t(`components.notation_list.columns.${column.key}.${value}`),
  };
};

// When an event is triggered, only the event itself is
// passed to the event handler. To also pass the notation
// we create a closure function that passes the event and
// the notation to the callback.
// If no event listeners have been specified we return null,
// because it seems to drastically improve render performance.
const createEventListenersForNotation = (column, notation) => (
  Object.keys(column.eventListeners).length > 0
    ? mapObject(column.eventListeners, (fn) => (event) => fn(notation, event))
    : {});

const displayNameWithDate = (key) => (column) => (
  i18n.t(key, {
    date: moment(column.data.date).format('ll'),
  }));

/* eslint-disable no-multi-spaces */
const NotationListColumns = (i18nPrefix, noSortOrFilter) => {
  const ColumnConfig = (key, type, filterOptions, specificConfig = {}) => ColumnConfiguration(
    'notation', key, type, filterOptions,
    Object.assign(
      {
        i18nPrefix,
        noSortOrFilter,
      },
      specificConfig,
    ),
  );

  const CorrelationColumnConfig = (timeShift) => ColumnConfig(`correlation_${timeShift}m`,
    'correlation',
    'none',
    {
      valueCallback: (column, notation, data) => ({
        primaryNotationId: data.primaryNotationId,
        primaryTimescope: data.primaryTimescope,
        secondaryNotationId: notation.id,
        secondaryTimescope: notation.timescope,
        timeShift,
      }),
    });

  const CorrelationConfigWithFeatureSwitch = (shift) => (GetFeatureSwitches().DASHBOARD_CORRELATIONS
    ? CorrelationColumnConfig(shift)
    : undefined);

  return [
    ColumnConfig('batch_select', 'icon', 'empty', {
      valueCallback: batchSelectValueCallback,
    }),
    ColumnConfig('is_hidden_for_customisation', 'icon', 'empty', {
      valueCallback: hiddenForCustomisationValueCallback,
    }),
    ColumnConfig('is_hidden_for_clients', 'icon', 'empty', {
      valueCallback: hiddenForClientsValueCallback,
    }),
    ColumnConfig('watched', 'icon', 'empty', {
      valueCallback: watchedValueCallback,
    }),
    ColumnConfig('sub_term', 'text', 'sortAndFilter'),
    ColumnConfig('dashboard_name', 'dashboardName', 'textFilter', {
      valueCallback: dashboardNameValueCallback,
    }),
    ColumnConfig('name', 'name', 'searchFilter', {
      valueCallback: nameValueCallback,
      filterPopupIsWide: true,
    }),
    ColumnConfig('reporter', 'text', 'searchFilter'),
    ColumnConfig('partner', 'text', 'searchFilter'),
    ColumnConfig('flow', 'text', 'enumFilter'),
    ColumnConfig('indicator', 'text', 'enumFilter'),
    ColumnConfig('timescope', 'text', 'enumFilter'),
    ColumnConfig('source', 'source', 'enumFilter', {
      valueCallback: sourceValueCallback,
    }),
    ColumnConfig('attribute_economy', 'text', 'enumFilter'),
    ColumnConfig('dataset', 'dataset', 'enumFilter'),
    ColumnConfig('attribute_unit', 'text', 'enumFilter'),
    ColumnConfig('latest_date', 'date', 'sort', {
      valueCallback: dateWithTimescopeValueCallback,
    }),
    ColumnConfig('latest_value', 'number', 'sort'),
    ColumnConfig('unit', 'unit', 'enumFilter', {
      valueCallback: unitValueCallback(i18nPrefix),
    }),
    ColumnConfig('yoy_percent', 'kpi', 'sort', {
      valueCallback: kpiValueCallback('last_year'),
    }),
    ColumnConfig('mom_percent', 'kpi', 'sort', {
      valueCallback: kpiValueCallback('last_month'),
    }),
    ColumnConfig('wow_percent', 'kpi', 'sort', {
      valueCallback: kpiValueCallback('last_week'),
    }),
    ColumnConfig('dod_percent', 'kpi', 'sort', {
      valueCallback: kpiValueCallback('last_day'),
    }),
    ColumnConfig('custom_dod_percent', 'kpi', 'sort', {
      displayNameCallback: displayNameWithDate('attributes.notation.custom_dod_percent'),
      valueCallback: kpiValueCallback('custom_date'),
    }),
    ColumnConfig('custom_dod_percent_over_min', 'kpi', 'sort', {
      displayNameCallback: displayNameWithDate('attributes.notation.custom_dod_percent_over_min'),
      valueCallback: aggregateKpiValueCallback('vs_min'),
    }),
    ColumnConfig('custom_dod_percent_over_max', 'kpi', 'sort', {
      displayNameCallback: displayNameWithDate('attributes.notation.custom_dod_percent_over_max'),
      valueCallback: aggregateKpiValueCallback('vs_max'),
    }),
    ColumnConfig('custom_dod_percent_over_avg', 'kpi', 'sort', {
      displayNameCallback: displayNameWithDate('attributes.notation.custom_dod_percent_over_avg'),
      valueCallback: aggregateKpiValueCallback('vs_avg'),
    }),
    ColumnConfig('current_value_range', 'valueRange', 'sort', {
      valueCallback: translateValueCallback,
    }),
    CorrelationConfigWithFeatureSwitch(1),
    CorrelationConfigWithFeatureSwitch(2),
    CorrelationConfigWithFeatureSwitch(3),
    ColumnConfig('preview', 'chart', 'none', {
      valueCallback: (column, notation) => ({
        primaryNotationQuotes: notation.quotes_1y,
        secondaryNotationQuotes: notation.secondary_quotes_1y,
      }),
    }),
  ].filter((cc) => cc !== undefined);
};
/* eslint-enable no-multi-spaces */

const NotationListCell = (columnConfiguration, notation) => {
  const state = {
    key: columnConfiguration.key,
    type: columnConfiguration.type,
    filterOptions: columnConfiguration.filterOptions,
    displayName: columnConfiguration.displayName,
    visible: columnConfiguration.visible,
    eventListeners: createEventListenersForNotation(columnConfiguration, notation),
  };
  state.value = columnConfiguration.valueCallback(state, notation, columnConfiguration.data);
  return state;
};

const NotationListRow = (columns, notation) => columns
  .map((c) => NotationListCell(c, notation));

const NotationListModel = (i18nPrefix, noSortOrFilter) => {
  const columns = NotationListColumns(i18nPrefix, noSortOrFilter);

  const addColumn = (column, index = 0) => {
    columns.splice(index, 0, column);
  };

  const columnForKey = (key) => {
    const index = columns.findIndex((c) => c.key === key);
    return index !== -1 ? columns[index] : null;
  };

  const setColumnEventListener = (columnKey, event, callback) => {
    const column = columnForKey(columnKey);
    if (column) {
      column.eventListeners[event] = callback;
    }
  };

  const setColumnVisible = (columnKey, visible) => {
    const column = columnForKey(columnKey);
    if (column) {
      column.visible = visible;
    }
  };

  const setColumnData = (columnKey, dataKey, dataValue) => {
    const column = columnForKey(columnKey);
    if (column) {
      column.addData(dataKey, dataValue);
    }
  };

  return {
    get columns() { return columns; },
    get visibleColumns() { return columns.filter((c) => c.visible); },
    addColumn,
    setColumnEventListener,
    setColumnVisible,
    setColumnData,
    createRow(notation) {
      return NotationListRow(this.visibleColumns, notation);
    },
  };
};

export default NotationListModel;
