<template>
  <div class="card chart--card">
    <div
      class="chart"
      :class="{'is-updating': isUpdating}"
    >
      <SpinningLoader v-if="isUpdating" />
      <template v-else>
        <QuotesChartAxisLabel
          v-if="hasLeftAxis"
          :label="primaryNotationUnit"
          axis="primary"
        />
        <div class="chart--plot">
          <LineChart
            :chart-data="{ datasets: datasets, annotations: annotations }"
            :options="chartOptions"
            :graph-limits="graphLimits"
            @select-date="onSelectIndexDate"
          />
        </div>
        <QuotesChartAxisLabel
          v-if="hasRightAxis"
          :label="secondaryNotationUnit"
          axis="secondary"
        />
      </template>
    </div>
    <div
      v-exclude-from-print
      class="chart--date-range"
    >
      <div class="chart--interaction">
        <DateSelectionBar
          v-model="currentRange"
          :ranges="ranges"
          @update:modelValue="onRangeChanged"
          @select="changeRangeDates"
        />
        <div class="chart--index-view">
          <template v-if="showIndexView">
            <AutoComplete
              :options="months"
              class="chart--index-month"
              :model-value="indexMonthId"
              :access-key="(o) => o.id"
              :access-value="(o) => o.name"
              :is-small="true"
              @update:modelValue="(indexMonthId) => $emit('update:index-month-id', indexMonthId)"
            />
            <SpinBox
              :model-value="indexYear"
              class="chart--index-year"
              :min="earliestQuoteDateYear"
              :label="$t('apps.multi_line_chart_view.index_year')"
              :max="new Date().getFullYear()"
              @update:modelValue="(indexYear) => $emit('update:index-year', indexYear)"
            />
          </template>
          <ButtonComponent
            :icon="showIndexView ? 'toggle-on' : 'toggle-off'"
            :variant="showIndexView ? 'secondary' : 'ternary'"
            :is-toggled="showIndexView"
            @click="() => $emit('update:index-view', !showIndexView)"
          >
            {{ $t('apps.multi_line_chart_view.index_view') }}
          </ButtonComponent>
        </div>
      </div>
      <VueSlider
        v-model="slider.value"
        class="chart--date-range-slider"
        :min="slider.minimumValue"
        :max="slider.maximumValue"
        :min-range="slider.minimumRange"
        :enable-cross="false"
        :tooltip-formatter="slider.tooltipFormatter"
        @change="updateCurrentRange"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { reactive } from 'vue';
import DateSlider from '@/models/DateSlider';
import LineChart from '@/components/LineChart/Component.vue';
import ChartOptions from '@/components/QuotesChart/ChartOptions';
import QuotesChartAxisLabel from '@/components/QuotesChart/AxisLabel/Component.vue';
import moment from 'moment';
import { lastMonthsInDays } from '@/utils/DateUtils';
import { months } from '@/helpers/I18nHelpers';
import { valuesForYAxis } from '@/helpers/ChartHelpers';
import DateSelectionBar from './DateSelectionBar/Component.vue';

export default {
  name: 'ChartView',
  components: {
    LineChart,
    QuotesChartAxisLabel,
    VueSlider,
    DateSelectionBar,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    selectedGraphLimits: {
      type: Object,
      required: true,
    },
    primaryNotationUnit: {
      type: [String, null],
      required: true,
    },
    secondaryNotationUnit: {
      type: [String, null],
      required: true,
    },
    selectedRange: {
      type: Number,
      default: null,
    },
    selectedEarliestQuoteDate: {
      type: [Object, null],
      required: true,
    },
    showIndexView: {
      type: Boolean,
      default: false,
    },
    indexMonthId: {
      type: Number,
      default: null,
    },
    indexYear: {
      type: Number,
      default: null,
    },
    indexPeriod: {
      type: Object,
      default: null,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    earliestQuoteDateYear: {
      type: [Number, null],
      required: true,
    },
  },
  emits: [
    'update:graph-limits',
    'update:range',
    'update:index-view',
    'update:index-month-id',
    'update:index-year',
    'change-index-date',
  ],
  data() {
    const graphLimits = reactive({
      minDate: moment(this.selectedGraphLimits.minDate),
      maxDate: moment(this.selectedGraphLimits.maxDate),
      startDate: moment(this.selectedGraphLimits.startDate),
      endDate: moment(this.selectedGraphLimits.endDate),
    });

    return {
      graphLimits,
      slider: DateSlider(graphLimits),
      currentRange: this.selectedRange,
      hasError: false,
      months: months(),
    };
  },
  computed: {
    chartOptions() {
      let yAxisOneValues = valuesForYAxis('y-axis-1', this.datasets, this.graphLimits);
      let yAxisTwoValues = valuesForYAxis('y-axis-2', this.datasets, this.graphLimits);

      if (this.showIndexView) {
        yAxisOneValues = yAxisOneValues.concat(yAxisTwoValues);
        yAxisTwoValues = yAxisOneValues;
      }

      return ChartOptions({
        xMin: this.graphLimits.startDate,
        xMax: this.graphLimits.endDate,
        yAxisOneMin: yAxisOneValues.length > 0 ? Math.min(...yAxisOneValues) : null,
        yAxisOneMax: yAxisOneValues.length > 0 ? Math.max(...yAxisOneValues) : null,
        yAxisTwoMin: yAxisTwoValues.length > 0 ? Math.min(...yAxisTwoValues) : null,
        yAxisTwoMax: yAxisTwoValues.length > 0 ? Math.max(...yAxisTwoValues) : null,
        drawSecondaryYAxisLines: this.datasets.filter((dataset) => dataset.yAxisID === 'y-axis-1').length === 0,
        axisType: 'x',
      });
    },
    ranges() {
      const startDate = this.selectedEarliestQuoteDate !== null
        ? this.selectedEarliestQuoteDate : this.graphLimits.minDate;
      const maxRangeInDays = Math.floor((this.graphLimits.maxDate).diff(startDate, 'days', true));
      return [
        { name: 12, valueInDays: lastMonthsInDays(12) },
        { name: 18, valueInDays: lastMonthsInDays(18) },
        { name: 24, valueInDays: lastMonthsInDays(24) },
        { name: 36, valueInDays: lastMonthsInDays(36) },
        { name: 'max', valueInDays: maxRangeInDays },
      ];
    },
    hasLeftAxis() {
      return this.primaryNotationUnit !== null;
    },
    hasRightAxis() {
      return this.secondaryNotationUnit !== null;
    },
    annotations() {
      const result = [];

      if (this.showIndexView === true && this.datasets.length > 0) {
        result.push({
          type: 'Box',
          xMin: this.indexPeriod.startDate,
          xMax: this.indexPeriod.endDate,
          color: 'rgba(0, 0, 0, 0.102)',
          label: this.primaryNotationUnit !== null
            ? this.primaryNotationUnit : this.secondaryNotationUnit,
        });
      }

      return result;
    },
  },
  watch: {
    graphLimits: {
      deep: true,
      handler() {
        this.$emit('update:graph-limits', { ...this.graphLimits });
      },
    },
    currentRange: {
      deep: true,
      handler() {
        this.$emit('update:range', this.currentRange);
      },
    },
  },
  methods: {
    onSelectIndexDate(date) {
      return this.showIndexView === true ? this.$emit('change-index-date', date)
        : null;
    },
    changeRangeDates(newStartDate) {
      this.graphLimits.startDate = moment(newStartDate);
      this.graphLimits.endDate = this.graphLimits.maxDate;
    },
    onRangeChanged(range) {
      this.currentRange = range;
    },
    updateCurrentRange() {
      this.currentRange = null;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>