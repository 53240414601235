import {
  generalOptions,
  tooltipOptions,
  xAxisOptions,
  yAxisOptions,
} from '@/helpers/ChartOptionsHelpers';

const ChartOptions = ({
  xMin,
  xMax,
  yAxisOneMin,
  yAxisOneMax,
  yAxisTwoMin,
  yAxisTwoMax,
  drawSecondaryYAxisLines,
  fontSize,
  axisType,
}) => ({
  ...generalOptions(),
  tooltips: tooltipOptions({
    axisType,
  }),
  scales: {
    xAxes: xAxisOptions({
      xMin,
      xMax,
      fontSize,
    }),
    yAxes: yAxisOptions({
      yAxisOneMin,
      yAxisOneMax,
      yAxisTwoMin,
      yAxisTwoMax,
      drawSecondaryYAxisLines,
    }),
  },
});

export default ChartOptions;
