<template>
  <tbody class="subscriptions--table-section">
    <SubscriptionsTableRow
      v-for="(value, index) in subscriptions"
      :key="value.key"
      :subscription-key="value.key"
      :subscription-name="value.name"
      :subscription-type="value.group"
      :interval="value.interval"
      :removable="removableRows || value.removable"
      :available-intervals="value.availableIntervals"
      @set-interval="setInterval"
    >
      <th
        v-if="index === 0"
        class="subscriptions--table-header"
        :rowspan="subscriptions.length"
      >
        {{ value.header }}
      </th>
    </SubscriptionsTableRow>
  </tbody>
</template>

<script>
import SubscriptionsTableRow from '../SubscriptionsTableRow/Component.vue';

export default {
  components: {
    SubscriptionsTableRow,
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
    removableRows: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-interval'],
  methods: {
    setInterval(changeObject) {
      this.$emit('set-interval', changeObject);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>