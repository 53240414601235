<template>
  <td
      class="data-table--body-cell"
      v-on="eventListeners"
  >
    <div class="data-table--source-cell">
      <span class="data-table--source-name">
        {{ value.name }}
      </span>
      <WithTooltip
          v-if="value.own_calculation"
          :tooltip=" $t('components.notation_list.columns.source.tooltip', { source: value.name })"
      >
        <span class="data-table--source-tag">
          HGS
        </span>
      </WithTooltip>
      <a
          class="data-table--icon-link"
          @click.stop="openModal"
          v-if="value.disclaimer"
      >
        <Icon
            name="info-circle"
            variant="solid"
        />
      </a>
    </div>
  </td>
</template>

<script>
import Icon from "../../../generic/Icon.vue";
import ModalWindowController from '@/controllers/ModalWindowController';
import ButtonComponent from "../../../generic/ButtonComponent.vue";

export default {
  name: 'SourceColumn',
  components: {ButtonComponent, Icon},
  props: {
    value: {
      type: Object,
      required: true,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openModal() {
      ModalWindowController.show('SourceDisclaimerModal', {
        props: {
          title: this.value.name,
          text: this.value.disclaimer,
        },
        events: {
          close: () => { ModalWindowController.close(); },
        },
      });
    },
  },
  data() {
    return {hover: false};
  },

};
</script>
