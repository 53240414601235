<template>
  <tbody class="data-table--body">
    <TableRow
      v-for="notation in notations"
      :key="`${notation.sub_term || notation.dashboard_id}-${notation.id}`"
      :model="model"
      :notation="notation"
      :can-click="true"
      @select="onSelect(notation)"
    />
  </tbody>
</template>

<script>
import TableRow from '@/components/NotationList/NotationTable/TableRow.vue';

export default {
  name: 'BodyComponent',
  components: {
    TableRow,
  },
  props: {
    notations: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'select',
  ],
  methods: {
    onSelect(notation) {
      this.$emit('select', notation);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>