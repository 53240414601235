<template>
  <div>
    <TextInput
      ref="nameInput"
      v-model="dashboard.name"
      v-focus
      :label=" $t('attributes.dashboard.name') "
      :required="true"
      @focus="$event.target.select()"
    />
  </div>
</template>

<script>
export default {
  name: 'PrivateDashboardForm',
  props: {
    initialDashboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dashboard: {
        name: this.initialDashboard.name,
      },
    };
  },
};
</script>
