<template>
  <div class="chart-legend--wrapper">
    <div class="chart-legend--title">
      <div>
        <span
          v-if="classPrefix"
          class="chart-legend--class"
        >
          {{ classPrefix }}
        </span>
        <span>
          {{ notation.name }}
        </span>
      </div>
      <NotationDetailsTagList
        :notation="notation"
        :unit-converted-from="unitConvertedFrom"
        :unit="unit"
      />
    </div>
    <ul class="chart-legend--item-list">
      <li
        v-for="year in quotesPerYear"
        :key="`season-line-chart-config-year-${year}`"
        class="chart-legend--item"
      >
        <ChartLegendItem
          v-if="showItem(year)"
          :title="year.title"
          :is-selected="year.selected"
          :color="year.color"
          @update:is-selected="(selectedYear) => $emit('set-year-selected', {
            title: year.title,
            selected: selectedYear,
          })"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { configureClassPrefix } from '@/helpers/ClassPrefixHelpers';
import NotationDetailsTagList from '@/components/NotationDetails/TagList/Component.vue';
import ChartLegendItem from './ChartLegendItem/Component.vue';

export default {
  name: 'ChartLegend',
  components: {
    NotationDetailsTagList,
    ChartLegendItem,
  },
  props: {
    quotesPerYear: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    unitConvertedFrom: {
      type: [null, String],
      required: true,
    },
    notation: {
      type: Object,
      required: true,
    },
  },
  emits: ['set-year-selected'],
  computed: {
    classPrefix() {
      return configureClassPrefix(
        this.notation.class_system,
        this.notation.class_code,
      );
    },
  },
  methods: {
    showItem(year) {
      return this.$media !== 'print' || year.selected;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>