<template>
  <PrintLayout
    :header-only-first-page="true"
  >
    <template #header>
      <PrintLayoutInstanceDefaultHeader class="low-margin-bottom" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayout>
  <div class="risk-map__container">
    <div class="risk-map__row risk-map__headline">
      <h1>
        {{ t("apps.human_rights.commodity_risk_map.title_one") }} |
        {{ t("apps.human_rights.commodity_risk_map.title_two") }}
      </h1>
    </div>
    <div class="risk-map__row risk-map__dimensions-combobox">
      <AutoComplete
        ref="autocompleteClassification"
        v-model="selectedClassificationId"
        v-model:filter="selectedSectorClassificationId"
        class="risk-map__classification-select"
        :options="classificationOptions"
        :access-key="(c) => c ? c.value : null"
        :access-value="(c) => c.label.list"
        :description="t('apps.intro.human_rights_risks.dropdown.classifications')"
        :is-async="true"
        :placeholder="t('apps.human_rights.commodity_risk_map.commodity_dropdown.placeholder')"
        :no-options-text="t('apps.human_rights.commodity_risk_map.commodity_dropdown.no_options')"
        @update:query="onClassificationQueryUpdate"
      >
        <template #footer="{ focussedOption }">
          <DropdownFooterTooltip v-if="focussedOption">
            {{ focussedOption.label.footer }}
          </DropdownFooterTooltip>
        </template>
      </AutoComplete>

      <ComboBox2
        v-model="selectedDimension"
        class="top-five-filter__combo-box risk-map__dimensions-select"
        :description="t('apps.intro.human_rights_risks.dropdown.dimensions')"
        :options="hrDimensionsForSelect"
        @update="getSelectedDimensionLabel"
      />
      <AutoComplete
        v-model="selectedCountry"
        :options="countriesForSelect"
        :access-key="(c) => c.value"
        :access-value="(c) => c.label"
        :description="t('apps.intro.human_rights_risks.dropdown.countries')"
      />
      <ComboBox2
        v-model="selectedYear"
        :description="t('apps.intro.human_rights_risks.dropdown.years')"
        :options="yearsForSelect"
      />
      <ComboBox2
        v-if="isSelectedCountryWorld"
        v-model="selectedExportLimit"
        class="top-five-filter__combo-box"
        :description="t('apps.intro.human_rights_risks.dropdown.limit')"
        :description-tooltip="t('apps.intro.human_rights_risks.dropdown.limit_tooltip')"
        :options="exportLimitsForSelect"
      />
      <ComboBox2
        v-else
        v-model="selectedImportLimit"
        class="top-five-filter__combo-box"
        :description="t('apps.intro.human_rights_risks.dropdown.limit')"
        :options="importLimitsForSelect"
      />
      <CommodityRiskMapExportButtonComponent v-if="selectedClassificationId"
                                             :countryOfDemandId="selectedCountry"
                                             :classificationId="selectedClassificationId"
                                             :limit="selectedLimitValue.value"
                                             :year="selectedYear.value"
                                             v-exclude-from-print="true"
                                             :controller="tableController"
      />
    </div>

    <div class="risk-map__row hr-legend">
      <HumanRightsRiskLegend :column-amount="2" wrapper-classes="w-100" />
    </div>

    <div class="risk-map__row risk-map__row--full-height">
      <div class="risk-map__column risk-map__map">
        <ButtonTabs :hint="t(noDataHint)">
          <ButtonTab
            :title="t('apps.human_rights.commodity_risk_map.button_tabs.map')"
            icon="globe-americas"
          >
            <div
              ref="root"
              class="any-chart-map__container"
            >
              <CustomLoadingSpinner v-if="isLoading" />
            </div>
            <CountryMapToolbar
              class="risk-map__toolbar"
              :zoom-level="zoomLevel"
              @mouse-up="stopMoveMap"
              @mouse-down="moveMap"
              @zoom-input="zoomInputChangeHandler"
            />
          </ButtonTab>
          <ButtonTab
            :title="t('apps.human_rights.commodity_risk_map.button_tabs.table')"
            :with-padding="true"
            icon="table"
          >
            <template v-if="geoData.top_countries.length > 0">

              <h1>
                {{ t(firstDynamicHeadline, { cn: geoData.classification_name, country: selectedCountryLabel }) }} - {{ selectedLimit }}
              </h1>

              <NotationList
                :sticky-margin-zero-top="true"
                :controller="tableController"
                class="country-data-table"
              >
                <template #sticky-header>
                  <StickyHeadRow
                    class="thick-border-bottom"
                    v-if="calculatedScoresController.hrScores && calculatedScoresController.hrScores.length"
                    :controller="calculatedScoresController"
                    :mainController="tableController"
                  >
                    <template #label>
                      <strong>{{ t('apps.human_rights.commodity_risk_map.sticky_headers.calculated_scores') }}</strong>
                    </template>
                  </StickyHeadRow>

                  <StickyHeadRow
                    class="thick-border-bottom"
                    v-if="sector && sector.scores && sector.scores.length"
                    :controller="sectorScoresController"
                    :mainController="tableController"
                    :prefix="t('apps.human_rights.commodity_risk_map.sticky_headers.sector_risks')"
                    :label="`${sector.code} | ${sector.name}`"
                  />

                  <StickyHeadRow
                    v-if="geoData.country_of_demand"
                    :controller="countryOfDemandTableController"
                    :mainController="tableController"
                  >
                    <template #label="{ options }">
                      <WithTooltip>
                        {{ t('apps.human_rights.commodity_risk_map.sticky_headers.country_risks') }}:
                        {{ options.iso_code }} ({{ options.balance }})
                        <template #custom v-if="countryOfDemandImportExportTooltip">
                          <div v-html="countryOfDemandImportExportTooltip"></div>
                        </template>
                      </WithTooltip>
                    </template>
                  </StickyHeadRow>

                  <StickyHeadRow
                    :controller="weightedScoresTableController"
                    :mainController="tableController"
                    :label="isSelectedCountryWorld ? t('apps.human_rights.commodity_risk_map.weighted_headings.export') : t('apps.human_rights.commodity_risk_map.weighted_headings.import')"
                  />
                </template>
              </NotationList>
            </template>
          </ButtonTab>
          <template v-slot:additional-content>
            <WithTooltip>
              <div v-if="sector" class="risk-map__sector">
                <div class="risk-map__sector-box"
                     :style="sector.scores[0] ? {backgroundColor: COUNTRY_COLOR_MAP[sector.scores[0].grade], color: 'white'} : {backgroundColor: 'inherit', color: 'inherit', border: '1px solid lightgray'}">
                  {{ t("attributes.sector.name") }}:
                  {{ sector.code }} | {{ sector.name }} |
                  {{ t("apps.human_rights.commodity_risk_map.human_rights_score")
                  }}: {{ sector.scores[0] ? sector.scores[0].value : "-" }}
                </div>
              </div>
              <template #custom>
                <TopFiveCountryCellTooltip :data="{hrScores: sector.scores, title: sectorLabel}" v-if="sector.scores[0]" />
                <div v-else>
                  {{ t("apps.human_rights.commodity_risk_map.no_data") }}
                </div>
              </template>
            </WithTooltip>
          </template>
        </ButtonTabs>
      </div>
    </div>
    <div class="risk-map__data-hint">
      <p>
        {{ t("apps.human_rights.data_hint") }}
      </p>
    </div>
    <template>
      <div v-if="bubbleChartHrScores[0]">
        <TopFiveCountryCellTooltip
          ref="shadowTooltip"
          :key="Turbolinks.uuid()"
          class="anychart-tooltip"
          :data="getBubbleChartTooltipData()"
        />
      </div>
    </template>
  </div>
</template>
<script setup>
import {
  computed, onMounted, onUnmounted, ref, toRaw, unref, watch, nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ButtonTab, ButtonTabs, CustomLoadingSpinner, NotationList
} from "@/components";
import { AutoComplete, ComboBox2 } from "@/components/generic";
import Turbolinks from "turbolinks";
import { isTypeNumber } from "@/helpers/IsTypeNumber";
import HumanRightsRiskLegend from "../../components/HumanRightsRisk/HumanRightsRiskLegend/Component.vue";
import CountryMapToolbar from "../../components/CountryMap/CountryMapToolbar/Component.vue";
import { MapService, COUNTRY_COLOR_MAP } from "../../services/MapService/MapService";
import CommodityRisksApi from "./CommodityRisksApi";
import { useHrScoreUtil } from "../../components/NotationList/NotationTable/Columns/TopFiveCountry/useHrScoreUtil";
import CommodityRiskMapTableController from "./controller/CommodityRiskMapTableController";
import CountryOfDemandTableController from "./controller/CountryOfDemandTableController";
import WeightedScoresTableController from "./controller/WeightedScoresTableController";
import { useCommodityDropdowns } from "./composables/useCommodityDropdowns";
import { WORLD_VALUE } from "../Intro/constants/world-value";
import DropdownFooterTooltip from "@/components/DropdownFooterTooltip";
import { getQueryParam } from "../../services/UrlStateStore";
import QUERY_PARAM from "../Intro/constants/commodity-query-params";
import TopFiveCountryCellTooltip
  from "../../components/NotationList/NotationTable/Columns/TopFiveCountry/TopFiveCountryCellTooltip.vue";
import {
  transformBubbleChartData,
  getCurrentTooltipData,
} from "./controller/CommodityRiskMapBubbleChartController";
import CommodityRiskMapExportButtonComponent from "./components/CommodityRiskMapExportButton/Component.vue";
import PrintLayout from "../../components/PrintLayout/Component.vue";
import PrintLayoutInstanceDefaultHeader from "../../components/PrintLayout/Instance/DefaultHeader/Component.vue";
import convertToLocaleString from "../../utils/ConvertToLocaleString";
import StickyHeadRow from '@/components/NotationList/NotationTable/StickyHeadRow.vue';
import importExportValuesTooltipContent from "@/components/HumanRightsRisk/ImportExportValuesTooltipContent"

const {
  hrDimensionsForSelect,
  countriesForSelect,
  exportLimitsForSelect,
  importLimitsForSelect,
  yearsForSelect,
  yearsDefaultValue
} = defineProps({
  hrDimensionsForSelect: {
    type: Array,
    default: () => []
  },
  countriesForSelect: {
    type: Array,
    default: () => []
  },
  dashboards: {
    type: Array,
    default: () => []
  },
  importLimitsForSelect: {
    type: Array,
    default: () => []
  },
  exportLimitsForSelect: {
    type: Array,
    default: () => []
  },
  yearsForSelect: {
    type: Array,
    default: () => []
  },
  yearsDefaultValue: {
    type: Number,
    default: 0
  },
  classificationsForSelect: {
    type: Array,
    default: () => []
  }
});

const { t, locale } = useI18n();

const initialLoad = ref(true);
const isLoading = ref(false);
const geoData = ref({
  top_countries: [],
  weighted_hr_scores_world: [],
  classification_name: ""
});
const countryOfDemandImportExportTooltip = ref({
  value: null
});
const bubbleChartData = ref({
  value: null
});

const bubbleChartDataRaw = ref({
  value: null
});

const bubbleChartHrScores = ref({
  value: null
});

const bubbleChartAxisValues = ref({
  value: null
});

const bubbleChartTooltipPriceText = ref({
  value: null
});

const bubbleChartTooltipAdditionalText = ref({
  value: null
});

const root = ref(null);
const zoomLevel = ref(1);
const shadowTooltip = ref(null);

const autocompleteClassification = ref();

const mapService = new MapService(unref(root));
const commodityRisksApi = new CommodityRisksApi();
const tableController = new CommodityRiskMapTableController(t);
const countryOfDemandTableController = new CountryOfDemandTableController(t);
const weightedScoresTableController = new WeightedScoresTableController(t);
const sectorScoresController = new WeightedScoresTableController(t);
const calculatedScoresController = new WeightedScoresTableController(t);

const sector = ref(null);

const {
  selectedCountry,
  selectedDimension,
  selectedYear,
  selectedImportLimit,
  selectedExportLimit,
  selectedClassificationId,
  selectedSectorClassificationId,
  classificationOptions
} = useCommodityDropdowns({
  hrDimensionsForSelect,
  yearsForSelect,
  importLimitsForSelect,
  exportLimitsForSelect,
  yearsDefaultValue,
  countriesForSelect
});


const getCountry = (countryValue) => countriesForSelect.find((c) => c.value === countryValue);

const getSelectedDimensionLabel = computed(() => {
  const currentDimensionLabel = selectedDimension.value.label;
  const firstChar = currentDimensionLabel.charAt(0);
  let currentLabel = isTypeNumber(firstChar) ? currentDimensionLabel.substring(2).toString() : currentDimensionLabel;

  if (currentLabel.charAt(0) === " ") {
    currentLabel = currentLabel.substring(1);
  }

  return currentLabel;
});

const getAutocompleteValue = computed(() => {
  if (typeof autocompleteClassification.value !== "undefined") {
    return autocompleteClassification.value.currentValue;
  }
  return "";
});
const selectedCountryLabel = computed(() => getCountry(selectedCountry.value).label.split(" | ")[1]);
const isSelectedCountryWorld = computed(() => selectedCountry.value === WORLD_VALUE);
const dynamicTableHeadlineBaseKey = "apps.human_rights.commodity_risk_map.table.dynamic_headline";
const firstDynamicHeadline = computed(() => (isSelectedCountryWorld.value
  ? `${dynamicTableHeadlineBaseKey}.first.export`
  : `${dynamicTableHeadlineBaseKey}.first.import`));
const selectedLimit = computed(() => (isSelectedCountryWorld.value ? selectedExportLimit.value.label : selectedImportLimit.value.label));
const selectedLimitValue = computed(() => (isSelectedCountryWorld.value ? selectedExportLimit.value : selectedImportLimit.value));
const noDataHint = computed(() => {
  if (isLoading.value || initialLoad.value) return ""; // don't show hint while loading
  if (geoData.value.top_countries?.length === 0 && !!selectedClassificationId.value) { // Classification is selected but no data
    return "apps.human_rights.commodity_risk_map.no_data";
  } else if (!selectedClassificationId.value && selectedSectorClassificationId.value) { // Sector is selected but no classification
    return "apps.human_rights.commodity_risk_map.no_classification";
  } else {
    return "";
  }
});


const onZoomLevelChange = (newZoomLevel) => zoomLevel.value = newZoomLevel;
const onClassificationQueryUpdate = async (query) => {
  // TODO: Add CN/Nace filter
  classificationOptions.value = await commodityRisksApi.fetchClassifications({
    query,
    sector_id: selectedSectorClassificationId.value,
  });
};

const moveMap = (direction) => mapService.moveMap(direction);
const stopMoveMap = () => mapService.stopMoveMap();
const zoomInputChangeHandler = (newZoomLevel) => {
  mapService.zoom(newZoomLevel);
  zoomLevel.value = newZoomLevel;
};

const getCountriesData = (params) => {
  isLoading.value = true;
  commodityRisksApi.fetchCountriesData(params)
    .then((data) => {
      countryOfDemandImportExportTooltip.value = importExportValuesTooltipContent(data.country_of_demand?.value?.import_export_values, t)
      geoData.value = data;
      sector.value = data.sector;
      sectorScoresController.updateHrScores(data.sector.scores || [])
      calculatedScoresController.updateHrScores(data.calculated_scores || [])
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const getCommondityBubbleChartData = (params) => {
  isLoading.value = true;
  commodityRisksApi.fetchCommodityBubbleChartData(params)
    .then((data) => {
      const { countries, min_price: minPrice, max_price: maxPrice } = data;
      bubbleChartData.value = transformBubbleChartData(countries, selectedDimension);
      bubbleChartDataRaw.value = countries;
      bubbleChartAxisValues.value = { yMin: minPrice, yMax: maxPrice };
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const getBubbleChartTooltipData = () => {
  const { hr_scores: hrScores, name, is_country_of_demand: isCountryOfDemand, balance } = bubbleChartHrScores.value[0];

  const refAdditionalText = bubbleChartTooltipAdditionalText.value;
  const additionalText = isCountryOfDemand ? [refAdditionalText.shift()] : refAdditionalText;

  const balanceKey = "apps.human_rights.commodity_risk_map.bubblechart.tooltip.balance";

  const tooltipData = {
    hrScores, currentCountry: name, additionalText, balance, isCountryOfDemand, balanceKey
  };
  return tooltipData;
};

const getHumanRightsTooltipTemplate = function() {
  const { data, hrScoreFlagIcon, getFlagIconReactive } = useHrScoreUtil(this.value.hr_scores);
  const { dimensions, total } = toRaw(data);
  getFlagIconReactive();
  const flagIcon = unref(hrScoreFlagIcon);

  return mapService.humanRightsTooltipTemplate({
    flagIcon,
    total,
    dimensions,
    importExportValues: this.value.import_export_values,
    value: this.value,
    translator: t,
    tradeFlowPercent: this.value.trade_flow_percentage?.toFixed(1),
    isSelectedCountryWorld: isSelectedCountryWorld.value,
    selectedCountry: selectedCountryLabel.value
  });
};

const getBubbleChartTooltipPriceText = (params) => {
  const { yAxisPrefix, yAxisTitle } = params;
  const floatPrice = parseFloat(bubbleChartHrScores.value[0].price.toFixed(2));
  bubbleChartTooltipPriceText.value = `${yAxisPrefix} ${floatPrice.toLocaleString(locale)} ${yAxisTitle}`;

  return bubbleChartTooltipPriceText.value;
};

const sectorLabel = computed(() => {
  return `${sector.value.code} | ${sector.value.name}`
})


const getTooltipTradeFlowPercentCellRow = (params) => {
  const { tradeFlowPercent, countryisExporter } = params;
  const numberInPercent = parseFloat(tradeFlowPercent, 10);
  const percentageTradeflowKey = "components.human_rights_risk_tooltip.percantage_tradeflow";
  const tooltipHTMLLine =
    `<span>${t(`${percentageTradeflowKey}.${countryisExporter}`, { selectedCountry: selectedCountryLabel.value })}</span>
                 <span>${numberInPercent.toLocaleString(locale, { minimumFractionDigits: 1 })} %</span>`;
  return tooltipHTMLLine;
};

const setupOptions = async () => {
  const classificationId = getQueryParam(QUERY_PARAM.CLASSIFICATION, null);
  const sectorClassificationId = getQueryParam(QUERY_PARAM.SECTOR_CLASSIFICATION, null);

  classificationOptions.value = [];

  if (classificationId) {
    classificationOptions.value.push(await commodityRisksApi.fetchClassifications({ classification_id: classificationId }));
  }
  if (sectorClassificationId) {
    classificationOptions.value.push(await commodityRisksApi.fetchClassifications({ classification_id: sectorClassificationId }));
  }
};

const bubbleChartOptions = () => {
  const { yMax, yMin } = bubbleChartAxisValues.value;
  const bubblechartTranslation = "apps.human_rights.commodity_risk_map.bubblechart";
  const bubbleChartTitleXAxis = `${t(`${bubblechartTranslation}.scales.x_axes.title`)} ${getSelectedDimensionLabel.value}`;
  const countryisExporter = isSelectedCountryWorld.value ? "export" : "import";
  const yAxisPrefix = t(`${bubblechartTranslation}.texts.${countryisExporter}`);
  const yAxisTitle = t(`${bubblechartTranslation}.texts.currency`);
  const bubbleChartTitleYAxis = `${yAxisPrefix} ${yAxisTitle}`;

  return {
    tooltips: {
      enabled: false,
      position: "cursor",
      callbacks: {
        label: function(tooltipItem, data) {
          const currentTooltipData = getCurrentTooltipData(tooltipItem, data);
          const bubbleDataRawCopy = bubbleChartDataRaw.value;
          const tooltipHRScores = bubbleDataRawCopy.filter(country => country.iso_code == currentTooltipData[0].label);
          bubbleChartHrScores.value = tooltipHRScores;
          bubbleChartTooltipPriceText.value = getBubbleChartTooltipPriceText({ yAxisPrefix, yAxisTitle });
          const tradeFlowPercent = bubbleChartHrScores.value[0].trade_flow_percentage.toFixed(1);
          bubbleChartTooltipAdditionalText.value = [bubbleChartTooltipPriceText.value, getTooltipTradeFlowPercentCellRow({
            tradeFlowPercent,
            countryisExporter
          })];
        }
      },
      custom: async function(context) {
        // Tooltip Element
        let tooltipEl = document.getElementById("anychart-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "anychart-tooltip";
          tooltipEl.innerHTML = "<table></table>";
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          tooltipEl.style.display = "none";
          return;
        } else {
          tooltipEl.style.display = "block";
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        await nextTick();

        // Set Text
        if (tooltipModel.body) {
          let tableRoot = tooltipEl.querySelector("table");
          const anyChartTooltipContent = document.querySelector(".anychart-tooltip");

          if (anyChartTooltipContent) {
            tableRoot.innerHTML = anyChartTooltipContent.innerHTML;
          }
        }

        const position = document.querySelector(".chartjs-render-monitor").getBoundingClientRect();

        // Display, position, and set styles for font
        const tooltipStyle = tooltipEl.style;
        tooltipStyle.fontFamily = "Ubuntu";
        tooltipStyle.fontSize = "14px";
        tooltipStyle.lineHeight = "1.42857142";
        tooltipStyle.border = "1px solid lightgray";
        tooltipStyle.borderRadius = "3px";
        tooltipStyle.padding = "10px 10px";
        tooltipStyle.boxShadow = "0 0 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.05)";
        tooltipStyle.textAlign = "left";
        tooltipStyle.color = "rgb(89, 96, 111)";
        tooltipStyle.opacity = 1;
        tooltipStyle.background = "white";
        tooltipStyle.position = "absolute";
        tooltipStyle.minWidth = "310px";
        const tooltipHeight = 380;
        tooltipStyle.left = position.left + window.pageXOffset + tooltipModel.caretX + 20 + "px";
        tooltipStyle.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipHeight + "px";
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    layout: {
      padding: {
        left: 0,
        right: 30,
        top: 0,
        bottom: 30
      }
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          fontSize: 15,
          display: true,
          labelString: bubbleChartTitleXAxis
        },
        position: "top",
        ticks: {
          callback: (label) => parseFloat(label).toLocaleString(locale),
          reverse: true,
          min: 0,
          max: 4,
          suggestedMin: 0,
          suggestedMax: 4,
          stepSize: 0.5
        }
      }],
      yAxes: [{
        scaleLabel: {
          fontSize: 15,
          display: true,
          labelString: bubbleChartTitleYAxis
        },
        position: "right",
        ticks: {
          callback: (label) => convertToLocaleString(label, locale, { maximumFractionDigits: 0 }),
          reverse: true,
          min: yMin,
          max: yMax,
          suggestedMin: yMin,
          suggestedMax: yMax
        }
      }]
    }
  };
};

const initializeMap = () => {
  mapService.init({
    geoData: [],
    bubbleChartData: [],
    url: "",
    idFieldValue: "iso_a3",
    mapName: "world",
    zoomOnWheel: true,
    container: unref(root),
    withCustomTooltip: true,
    customTooltipData: {
      generateTooltipBody: getHumanRightsTooltipTemplate
    },
    callback: async () => {
      mapService.mouseWheelZoomChangeAddListener(onZoomLevelChange);
      mapService.moveBtnMouseUpDocumentHandler();

      await setupOptions();

      if (selectedClassificationId.value || selectedSectorClassificationId.value) {
        commodityRisksApi
          .fetchSector(selectedClassificationId.value || selectedSectorClassificationId.value, { year: selectedYear.value.value })
          .then((data) => {
            sector.value = data;
          })
          .catch(() => {
            sector.value = null;
          })
        ;
      } else {
        sector.value = null;
      }

      if (selectedClassificationId.value) {
        // get country map data
        await getCountriesData({
          classification_id: selectedClassificationId.value,
          limit: isSelectedCountryWorld.value ? selectedExportLimit.value.value : selectedImportLimit.value.value,
          country_of_demand_id: selectedCountry.value,
          year: selectedYear.value.value
        });
        // get bubble chart data
        await getCommondityBubbleChartData({
          classification_id: selectedClassificationId.value,
          limit: isSelectedCountryWorld.value ? selectedExportLimit.value.value : selectedImportLimit.value.value,
          country_of_demand_id: selectedCountry.value,
          year: selectedYear.value.value
        });
      }
      initialLoad.value = false;
    }
  });
};

watch(
  [selectedSectorClassificationId, selectedClassificationId, selectedYear],
  async ([sectorClassification, classification, year]) => {
    if (classification || sectorClassification) {
      commodityRisksApi
        .fetchSector(sectorClassification || classification, { year: selectedYear.value.value })
        .then((data) => {
          sector.value = data;
        });
    } else {
      sector.value = null;
    }
  }
);

watch(
  [selectedCountry, selectedYear, selectedImportLimit, selectedExportLimit, selectedClassificationId],
  async ([country, year, importLimit, exportLimit, classification]) => {
    if (classification) {
      await getCountriesData({
        classification_id: classification,
        limit: country === WORLD_VALUE ? exportLimit.value : importLimit.value,
        country_of_demand_id: country,
        year: year.value
      });
      await getCommondityBubbleChartData({
        classification_id: classification,
        limit: isSelectedCountryWorld.value ? selectedExportLimit.value.value : selectedImportLimit.value.value,
        country_of_demand_id: selectedCountry.value,
        year: selectedYear.value.value
      });
    }
  }
);

watch(selectedDimension, (dimension) => {
  mapService.updateMapItemColorsByDimension(dimension.value);
  bubbleChartData.value = transformBubbleChartData(bubbleChartDataRaw.value, selectedDimension);
});

watch(geoData, (currentState, prevState) => {
  const {
    top_countries: topCountries,
    country_of_demand: countryOfDemand,
    weighted_hr_scores_world: weightedHrScoresWorld
  } = currentState;

  const countriesForMap = [
    ...topCountries,
    ...(countryOfDemand ? [{ ...countryOfDemand, value: { ...countryOfDemand.value, isCountryOfDemand: true } }] : [])
  ];

  mapService.updateDataSet(countriesForMap);
  mapService.updateMapItemColorsByDimension(selectedDimension.value.value);

  if (topCountries.length > 0) {
    tableController.updateCountries({
      countries: topCountries,
      isWorldSelected: isSelectedCountryWorld.value
    });
    weightedScoresTableController.updateHrScores(weightedHrScoresWorld);
  }

  if (countryOfDemand) {
    countryOfDemandTableController.updateCountryOfDemand([countryOfDemand]);
  }
});

onMounted(async () => {
  document.addEventListener("geodata-loaded", initializeMap);
});

onUnmounted(() => {
  mapService.destroy();
  document.removeEventListener("geodata-loaded", initializeMap);
});

</script>

<style lang="scss">
.combobox--sector {
  background: gray;
}

.anychart-tooltip {
  font-family: 'Ubuntu';
  @include font-size('14/20');
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: $space-s $space-s;
  box-shadow: 0 0 2px rgba($color-background-dark, 0.2), 0 4px 4px rgba($color-background-dark, 0.15), 0 8px 8px rgba($color-background-dark, 0.05);
  text-align: left;
  color: $color-text-unobtrusive;
  background-color: $color-background-light;
}

// just a tmp solution
.anychart-credits {
  display: none;
}

.weighted-scores-row {
  display: flex;
  margin-top: 45px;

  &__heading {
    color: $color-text-unobtrusive;
    min-width: 223px;
    max-width: 223px;
  }
}

.country-data-table {
  .data-table--body-cell:first-child {
    width: 103px;
    min-width: 103px;
    max-width: 103px;
  }

  .data-table--body-cell:nth-child(2) {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}

@media print {
  .combobox--caret {
    display: none;
  }
  .risk-map__row input {
    border: 0;
  }

  .any-chart-map__zoom-input-wrapper {
    display: none;
  }

  .hrr-legend-box {
    box-shadow: none;
  }
  .button-tab-content {
    overflow: visible;
  }
  .data-table__container {
    overflow: visible;
  }
  .data-table--body {
    overflow: visible;
  }

  .weighted-scores-row {
    justify-content: space-between;
    font-size: 65%;

    .data-table--head-cell {
      white-space: break-spaces;
    }
  }
  .country-data-table {
    .data-table--body-cell:first-child {
      min-width: 0;
      max-width: 100%;
    }

    .data-table--body-cell:nth-child(2) {
      min-width: 0;
      max-width: 100%;
    }
  }
}

.risk-map {
  &__sector {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 15px;
    }
  }

  &__sector-box {
    padding: $space-s $space-m;
    width: 100%;
    border-radius: 2px;
  }

  &__dimensions-combobox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__classification-select {
    width: 100%;
  }

  &__dimensions-select {
    max-width: fit-content;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    overflow: hidden;
  }

  &__toolbar {
    z-index: 1;
  }

  &__row {
    display: flex;
    gap: 12px;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &--full-height {
      height: 100%;
      position: relative;
      overflow-y: auto;
    }
  }

  &__column {
    flex-direction: column;
  }

  &__map {
    flex-grow: 1;
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 12px #e7e7e7;
    overflow: hidden;
    @media print {
      & {
        overflow: visible;
      }
    }

    h1 {
      font-weight: 700;
      margin: 20px 0 20px 0;
    }
  }


  &__headline {
    @include font-size('20/25');
  }
}

.any-chart-map {
  &__container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.sticky-head-row.thick-border-bottom td {
  border-bottom-width: 3px;
}

@media print {

  .risk-map__map {
    .button-tabs__head {
      display: none;
    }

    h1 {
      margin: 10px 0 10px 0;
    }
  }

  .risk-map__row {
    flex-direction: column-reverse;
  }

  .risk-map__row {
    height: 100%;
    position: relative;
    overflow-y: auto;
  }

  .risk-map__container {
    padding-top: 0;
  }

  // @TODO print styles for new legend position
  .risk-map__row.hr-legend {
    display: flex;
    flex-direction: row;
    flex: auto;
  }

  .risk-map__dimensions-combobox {
    flex-direction: row;
  }

  .any-chart-map {
    &__container {
      height: 340px;
    }
  }
}
</style>
