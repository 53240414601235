<template>
    <div class="search-header">
    <span class="search-header--description-list">
      <DashboardTypeTabsComponent
              :dashboard-type="dashboardType"
              @update:dashboardType="$emit('update:dashboardType', $event)"
      />
      <IconTooltipComponent
              v-if="isNotHumanRightsRisk"
              class="page-header--tooltip"
              :tooltip="$t('components.intro.dashboard_overview_tooltip')"
      />
    </span>
        <ul
                v-if="isNotHumanRightsRisk"
                class="search-header--action-list"
        >
            <li
                    v-if="showListView"
                    class="search-header--action-list-item"
            >
                <ButtonComponent
                        variant="primary"
                        icon="columns"
                        :is-disabled="isLoadingOrHasNoData"
                        @click="onToggleDetails(!showDetails)"
                >
                    {{ toggleDetailsLabel }}
                </ButtonComponent>
            </li>
            <li class="search-header--action-list-item">
                <PdfExportButtonComponent :text="$t('components.generic.pdf_export_button.for_internal_use')" />
            </li>
            <li class="search-header--action-list-item">
                <MailExportButtonComponent
                        v-if="isShowingFavorites"
                        mail-export-type="IntroSubscription"
                />
                <MailExportButtonComponent
                        v-else
                        mail-export-type="IndicatorSubscription"
                />
            </li>
            <li
                    v-if="$features.TILE_VIEW"
                    class="search-header--action-list-item"
            >
                <ButtonComponent
                        variant="primary"
                        icon="th-large"
                        @click="onToggleListView(!showListView)"
                >
                    {{ toggleViewLabel }}
                </ButtonComponent>
            </li>
        </ul>
    </div>
</template>

<script>
import MailExportButtonComponent from '@/components/MailExportButton/Component.vue';
import { DashboardTypes } from '@/apps/Intro/IntroAppController';
import DashboardTypeTabsComponent from './DashboardTypeTabs/Component.vue';

export default {
  name: 'ViewHeaderComponent',
  components: {
    MailExportButtonComponent,
    DashboardTypeTabsComponent,
  },
  props: {
    dashboardType: {
      type: String,
      required: true,
    },
    showListView: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'toggle-list-view',
    'toggle-details',
    'update:dashboardType',
  ],
  computed: {
    isLoadingOrHasNoData() {
      return this.isLoading || !this.hasData;
    },
    toggleDetailsLabel() {
      return this.showDetails
          ? this.$t('components.search_header.hide_details')
          : this.$t('components.search_header.show_details');
    },
    toggleViewLabel() {
      return this.showListView
          ? this.$t('components.intro.buttons.show_tile_view')
          : this.$t('components.intro.buttons.show_list_view');
    },
    isShowingFavorites() {
      return this.dashboardType === DashboardTypes.Favorites;
    },
    isNotHumanRightsRisk() {
      return this.dashboardType !== DashboardTypes.HumanRightsRisks;
    },
  },
  methods: {
    onToggleListView(showListView) {
      this.$emit('toggle-list-view', showListView);
    },
    onToggleDetails(showDetails) {
      this.$emit('toggle-details', showDetails);
    },
  },
};
</script>
