<template>
  <g class="chart--grid">
    <g
      v-if="xScale"
      ref="xAxis"
    />
    <g
      v-if="yScale"
      ref="yAxis"
    />
  </g>
</template>

<script>
import * as d3 from 'd3';
import AxisConfig from '../../../models/charts/AxisConfig';

export default {
  name: 'PlotGrid',
  props: {
    xScale: {
      type: Function,
      default: null,
    },
    width: {
      type: Number,
      default: 0,
    },
    yScale: {
      type: Function,
      default: null,
    },
    height: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => AxisConfig.default(),
    },
  },
  watch: {
    xScale() { this.renderVerticalGrid(); },
    height() { this.renderVerticalGrid(); },
    yScale() { this.renderHorizontalGrid(); },
    width() { this.renderHorizontalGrid(); },
  },
  mounted() {
    this.renderVerticalGrid();
    this.renderHorizontalGrid();
  },
  methods: {
    renderVerticalGrid() {
      if (this.xScale) {
        const axis = d3.axisBottom(this.xScale);
        Object.assign({}, this.config, {
          innerTickSize: this.height,
          outerTickSize: 0,
          tickFormat: '',
        }).createAxis(axis, this.$refs.xAxis);
      } else {
        d3.select(this.$refs.xAxis).selectAll('g').remove();
      }
    },
    renderHorizontalGrid() {
      if (this.yScale) {
        const axis = d3.axisLeft(this.yScale);
        Object.assign({}, this.config, {
          innerTickSize: -this.width,
          outerTickSize: 0,
          tickFormat: '',
        }).createAxis(axis, this.$refs.yAxis);
      } else {
        d3.select(this.$refs.yAxis).selectAll('g').remove();
      }
    },
  },
};
</script>
