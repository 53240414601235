import { defineStore } from 'pinia';
import NotationApi from '@/api/NotationApi';
import UnitsApi from '@/api/UnitsApi';
import axios from 'axios';
import UrlStateStore from '@/services/UrlStateStore';
import i18n from '@/config/i18n';

export const useSeasonLineChartConfigurationStore = defineStore('seasonLineChartConfiguration', {
  state: () => ({
    units: [],
    config: {},
    savedAnalysisId: null,
    isLoading: false,
    hasError: false,
    notation: null,
    yearsWithQuotes: null,
    volumeUnitCategory: null,
    compatibleUnits: {},
  }),
  getters: {
    validationError() {
      if (this.config.notationID === null) {
        return i18n.t('apps.season_line_chart_config.error_message.no_notation_selected');
      }
      if (this.config.selectedYears.length === 0) {
        return i18n.t('apps.season_line_chart_config.error_message.no_year_selected');
      }
      if (this.config.selectedYears.length > 5) {
        return i18n.t('apps.season_line_chart_config.error_message.select_max_five_years');
      }
      return null;
    },
    viewUrl() {
      return `season-line-chart-view${UrlStateStore.queryStringForState({ config: this.config, savedAnalysisId: this.savedAnalysisId })}`;
    },
    availableCurrencies() {
      return this.units?.currency ? Object.values(this.units?.currency) : [];
    },
    availableVolumeUnits() {
      return this.volumeUnitCategory !== null
        ? Object.values(this.units[this.volumeUnitCategory]) : [];
    },
    notationForAssignmentTable() {
      if (this.notation === null) { return []; }
      return [{
        ...this.notation,
        axis: 'left',
        color: null,
      }];
    },
  },
  actions: {
    fetchNotation(notationID) {
      NotationApi()
        .findNotation(notationID)
        .then((notation) => {
          this.notation = notation;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.notation = {
              notationID,
              notFound: true,
            };
          } else {
            throw error;
          }
        });
    },
    fetchYearsWithQuotes(notationID) {
      return axios.get(`/api/notations/${notationID}/years_with_quotes`)
        .then((response) => {
          this.yearsWithQuotes = response.data.sort().reverse();
        });
    },
    setSavedAnalysisId(savedAnalysisId) {
      this.savedAnalysisId = savedAnalysisId;
    },
    removeNotationFromConfig() {
      this.notation = null;
      this.config.notationID = null;
    },
    fetchUnits() {
      return UnitsApi.unitsGroupedByCategory({})
        .then((response) => {
          this.units = response;
        });
    },
    fetchCompatibleUnits() {
      return axios.get(`/api/notations/compatible_units?notation_ids[]=${this.config.notationID}`)
        .then((response) => {
          this.compatibleUnits = response.data;
        });
    },
    setVolumeUnitCategory() {
      if (this.config.compositeUnit.composition_category === 'price') {
        this.volumeUnitCategory = Object.keys(this.compatibleUnits
          .price.notations_per_volume_unit_categories).join();
      }
      if (this.config.compositeUnit.composition_category === 'volume') {
        this.volumeUnitCategory = Object.keys(this.compatibleUnits
          .volume.notations_per_volume_unit_categories).join();
      }
    },
    setCompositeUnit() {
      if (!this.notation?.unit_composition) {
        return
      }

      if (this.config.compositeUnit.composition_category === null) {
        this.config.compositeUnit.composition_category = this.notation.unit_composition.category;

        if (this.config.compositeUnit.composition_category === 'volume') {
          this.config.compositeUnit.volume_unit_id = this.notation.unit_composition
            .numerator_unit_id;
        }
        if (this.config.compositeUnit.composition_category === 'value') {
          this.config.compositeUnit.currency_unit_id = this.notation.unit_composition
            .numerator_unit_id;
        }
        if (this.config.compositeUnit.composition_category === 'price') {
          this.config.compositeUnit.currency_unit_id = this.notation.unit_composition
            .numerator_unit_id;
          this.config.compositeUnit.volume_unit_id = this.notation.unit_composition
            .denomiator_unit_id;
        }
      }
    },
    updateSelectedYears(year) {
      const yearToInt = parseInt(year, 10);
      if (this.config.selectedYears.includes(yearToInt)) {
        this.config.selectedYears = this.config.selectedYears.filter(
          (selectedYear) => selectedYear !== yearToInt,
        );
      } else {
        this.config.selectedYears.push(yearToInt);
      }
      this.config.selectedYears.sort().reverse();
    },
    newConfiguration(notationID, dashboardID) {
      const config = {
        title: null,
        notationID,
        dashboardIdOfLastAddedNotation: dashboardID,
        startMonthId: 1,
        selectedYears: [],
        compositeUnit: {
          composition_category: null,
          currency_unit_id: null,
          volume_unit_id: null,
        },
      };
      this.setConfiguration(config);
    },
    async setConfiguration(config) {
      try {
        this.isLoading = true;
        this.config = config;
        if (this.config.notationID !== null) {
          const firstPromises = [];
          firstPromises.push(this.fetchNotation(this.config.notationID));
          firstPromises.push(this.fetchYearsWithQuotes(this.config.notationID));
          firstPromises.push(this.fetchCompatibleUnits());
          await Promise.all(firstPromises);
          const secondPromises = [];
          secondPromises.push(this.fetchUnits());
          await Promise.all(secondPromises);
          this.setCompositeUnit();
          this.setVolumeUnitCategory();
          if (this.config.title === null) {
            this.config.title = this.notation.name;
            this.config.selectedYears = this.yearsWithQuotes.slice(0, 5);
          }
        }
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
