import { reactive } from 'vue';

const COLUMN_TRANSLATION_KEY = 'apps.human_rights.commodity_risk_map.table.column';

class CountryOfDemandTableController {
  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filters() {
    return this._state.filters;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    return this._state.selection;
  }

  get notations() {
    return this._state.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    return this._state.notationListModel;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get ready() {
    return !!this._state
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  constructor(translator) {
    this._translator = translator;
  }

  init(countries) {
    this._countries = this._getMappedCountries(countries);
    this._columns = this._getColumnDefinition(this._countries[0].hr_scores);

    this._state = reactive({
      isLoading: false,
      filters: {},
      sorting: null,
      selection: {
        selectionMode: 'none',
        canSelect: false,
      },
      notations: this._countries,
      notationListModel: {
        columns: this._columns,
        visibleColumns: this._columns,
        createRow: (notation) => this._columns.map(({ key, type, ...column }) => ({
          key,
          type,
          filterOptions: column.filterOptions,
          displayName: column.displayName,
          visible: true,
          value: column.valueBuilder ? column.valueBuilder(notation, key) : notation[key],
          classes: {},
          additionalData: {},
        })),
      },
    });
  }

  updateCountryOfDemand(countries) {
    if (!this._countries) {
      return this.init(countries)
    }
    this._countries = this._getMappedCountries(countries);
    this.notations = this._countries;
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  /**
     * Remaps country response structure
     *
     * @param countries { id, value: { name, iso_code, dimensions: {...} } }
     * @returns { name, iso_code, dimension-*: { ... }}
     * @private
     */
  _getMappedCountries(countries) {
    const countryValues = countries.map((c) => c.value);
    return countryValues.map((c) => c.hr_scores.reduce((acc, curr) => {
      acc[`dimension-${curr.key}`] = curr;
      return acc;
    }, c));
  }

  _getColumnDefinition(_dimensions) {
    const [total, ...dimensions] = _dimensions;
    return [
      {
        key: 'iso_code',
        visible: true,
        displayName: this._translator(`${COLUMN_TRANSLATION_KEY}.country_of_demand`),
        type: 'text',
        classes: 'data-table--head-cell-title-wrapper',
      },
      {
        key: 'balance',
        visible: true,
        displayName: 'Balance',
        type: 'text',
        valueBuilder: (notation) => this._translator(`apps.intro.human_rights_risks.table_column.balance.${notation.balance}`),
      },
      {
        key: 'dimension-0',
        visible: true,
        displayName: total.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'totalHrScoresColumn',
        valueBuilder: (notation, key) => ({ dimensions: notation.hr_scores, total: notation[key], withFlagIcon: true }),
      },
      ...dimensions.map((d) => ({
        key: `dimension-${d.key}`,
        visible: true,
        displayName: d.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'hrScoresColumn',
      })),
    ];
  }
}

export default CountryOfDemandTableController;
