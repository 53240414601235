<template>
  <Card class="notation-card">
    <div
      v-if="$slots.header"
      class="notation-card--header"
    >
      <slot name="header" />
    </div>
    <NotationCardPlaceholder v-if="showPlaceholderWidget" />
    <NotationCardBody
      v-else
      :selected-graph-limits="selectedGraphLimits"
      :show-dashboard-header-modal-button="showDashboardHeaderModalButton"
      class="notation-card--body"
      :primary-notation-details="primaryDetails"
      :secondary-notation-details="secondaryDetails"
      :selected-date="selectedDate"
      :selected-currency-id="selectedCurrencyId"
      :dashboard-header-modal-title="dashboardHeaderModalTitle"
      :show-date-slider="showDateSlider"
      :for-internal-use="forInternalUse"
      @change-date="$emit('change-selected-date', $event)"
      @update:selectedGraphLimits="$emit('update:selectedGraphLimits', $event)"
    />
    <div
      v-if="$slots.footer"
      class="notation-card--footer"
    >
      <slot
        name="footer"
        :primary-notation-details="primaryDetails"
        :secondary-notation-details="secondaryDetails"
      />
    </div>
  </Card>
</template>

<script>
import {
  computed,
  toRefs,
} from 'vue';

import useNotationDetails from '@/models/NotationDetails';
import NotationCardBody from './Body/Component.vue';
import NotationCardPlaceholder from './Placeholder/Component.vue';

export default {
  name: 'NotationCard',
  components: {
    NotationCardBody,
    NotationCardPlaceholder,
  },
  props: {
    primaryNotationLoader: {
      type: Object,
      default: null,
    },
    secondaryNotationLoader: {
      type: Object,
      default: null,
    },
    showDateSlider: {
      type: Boolean,
      default: true,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    selectedCurrencyId: {
      type: [String, Number],
      default: null,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
    dashboardHeaderModalTitle: {
      type: String,
      required: true,
    },
    showDashboardHeaderModalButton: {
      type: Boolean,
      default: true,
    },
    forInternalUse: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change-selected-date', 'update:selectedGraphLimits'],
  setup(props) {
    const {
      primaryNotationLoader,
      secondaryNotationLoader,
      selectedCurrencyId,
      selectedDate,
    } = toRefs(props);

    const primaryDetails = useNotationDetails(
      primaryNotationLoader, selectedCurrencyId, selectedDate,
    );

    const secondaryDetails = useNotationDetails(
      secondaryNotationLoader, selectedCurrencyId, selectedDate,
    );

    const showPlaceholderWidget = computed(
      () => !primaryDetails?.hasLoaded,
    );

    return {
      primaryDetails,
      secondaryDetails,
      showPlaceholderWidget,
    };
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>