import axios from 'axios';
import ApiHelpers from './ApiHelpers';

const TopTermSearchApi = (topTermId) => {
  const availableFilters = (selectedFilters) => axios.post(`/api/top_terms/${topTermId}/filters`, {
    selected_filters: selectedFilters,
  }).then((response) => response.data);

  const notations = (filters, sorting, comparisonDate, showCustomize, { page = 0 } = {}) => {
    const payload = ApiHelpers.removeNullKeys({
      filters,
      sorting,
      compare_to_date: ApiHelpers.dateToString(comparisonDate),
      page,
    });
    return axios.post(`/api/top_terms/${topTermId}/notations`, payload)
      .then(ApiHelpers.transformNotationResponse);
  };

  const saveDefaultFilters = (selectedFilters) => axios.post('/api/top_terms/filters/save', {
    selected_filters: selectedFilters,
  });

  return {
    availableFilters,
    notations,
    saveDefaultFilters,
  };
};

export default TopTermSearchApi;
