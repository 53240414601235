<script>
import CellComponent from "./CellComponent"

export default {
  name: "RowComponent",
  components: {
    CellComponent
  },

  props: {
    record: {
      type: Object,
      required: true
    },
    columnDefinitions: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      default: 0
    }
  }
}
</script>

<template>
  <tr :class="`data-table--body-row data-table-depth-${depth}`">
    <template v-for="(columnGroup, groupIndex) of columnDefinitions" :key="columnGroup.uuid">
      <template v-if="columnGroup.expanded">
        <CellComponent
          @click="column.onClick(record)"
          v-for="(column, columnIndex) in columnGroup.columns"
          :key="`cell-${column.uuid}-${record.id}`"
          :columnGroup="columnGroup"
          :groupIndex="groupIndex"
          :column="column"
          :columnIndex="columnIndex"
          :record="record"
        ></CellComponent>
      </template>

      <CellComponent
        @click="column.onClick(record)"
        v-else
        v-for="(column, columnIndex) in columnGroup.shortColumns"
        :key="`short-${groupIndex}-${column.uuid}`"
        :columnGroup="columnGroup"
        :groupIndex="groupIndex"
        :column="column"
        :columnIndex="columnIndex"
        :record="record"
        :short="true"
      ></CellComponent>
    </template>
  </tr>
  <RowComponent
    v-if="record.expanded"
    v-for="child of record.children"
    :key="`item-${child.id}`"
    :record="child"
    :columnDefinitions="columnDefinitions"
    :depth="depth + 1"
  >
  </RowComponent>
</template>

<style lang="scss">

</style>
