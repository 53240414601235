<template>
  <Teaser class="countryriskmap-export-button" scope="export" user-attribute="can_export_human_rights_data">
    <ComboBox
      :model-value="currentIndex"
      :options="options"
      :label="ariaLabel"
      :disabled="isLoading || isSaving"
      :display-text-override="comboBoxDisplayTextOverride"
      :controller="controller"
      @update:modelValue="selectExportFormat"
    />
  </Teaser>
</template>

<script>
import i18n from '@/config/i18n';
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import PdfExportController from '@/components/generic/PdfExportButton/Controller';

const EXPORT_FILEFORMATS = ['pdf_map', 'pdf_table', 'excel']; //, 'pdf_table', 'excel'];

export default {
  name: 'HumanRightsRisksExportButtonComponent',
  props: {
    ariaLabel: {
      type: String,
      default: i18n.t('apps.country_risk_map.export_button.options.default_label'),
    },
    mailExportType: {
      type: String,
      required: true,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
    controller: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      keys: EXPORT_FILEFORMATS,
    };
  },
  computed: {
    options() {
      return this.keys.map((key) => this.$t(`apps.country_risk_map.export_button.options.${key}`));
    },
    currentIndex() {
      return this.keys.indexOf(this.mailExportRythm);
    },
    currentOption() {
      return this.options[this.currentIndex];
    },
    comboBoxDisplayTextOverride() {
      if (this.isLoading) {
        return this.$t('apps.country_risk_map.export_button.is_loading');
      } if (this.isSaving) {
        return this.$t('apps.country_risk_map.export_button.is_saving');
      }
      return this.$t('apps.country_risk_map.export_button.options.default_label');
    },
  },
  beforeMount() {
  },
  methods: {
    selectExportFormat(index) {
      const currentExportFormat = EXPORT_FILEFORMATS[index];
      switch (currentExportFormat) {
        case 'pdf_map':
          this.exportPDF(0);
          break;
       case 'pdf_table':
         this.exportPDF(1);
         break;
        case 'excel':
          this.exportXLSX();
          break;
      }
    },
    exportPDF(tabPosition, forInternalUse = true) {
      const path = forInternalUse ? `${window.location.pathname}?tab_position=${tabPosition}` : `${window.location.pathname}?${this.forExternalPdfExport()}`;
      PdfExportController.exportAsPdf(path);
    },
    exportXLSX() {
      this.controller._getExcelFile();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>