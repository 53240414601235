import EventBase from './EventBase';

class OwnViewChangeEvent extends EventBase {
  constructor({ hasOwnView, ownViewActive }) {
    super();
    this._data = {
      hasOwnView,
      ownViewActive,
    };
  }

  static get EventName() {
    return 'HGS::OwnViewChangeEvent';
  }

  get hasOwnView() {
    return this._data.ownViewActive;
  }

  get ownViewActive() {
    return this._data.ownViewActive;
  }
}

export default OwnViewChangeEvent;
