<template>
  <td
    class="data-table--body-cell is-right-aligned"
    v-on="eventListeners"
  >
    {{ formattedValue }}
  </td>
</template>

<script>
import { formatGradualPrecision } from '@/helpers/NumberFormats';

export default {
  name: 'NumberColumn',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedValue() {
      const num = Number.parseFloat(this.value);
      if (Number.isNaN(num)) {
        return 'N/A';
      }
      return formatGradualPrecision(num);
    },
  },
};
</script>
