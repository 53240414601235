<template>
  <div class="body">
    <div class="body--chart">
      <QuotesChart
        :selected-graph-limits="selectedGraphLimits"
        :primary-notation-details="primaryNotationDetails"
        :secondary-notation-details="secondaryNotationDetails"
        :show-date-slider="showDateSlider"
        :selected-date="selectedDate"
        @update:selectedGraphLimits="$emit('update:selectedGraphLimits', $event)"
        @change-date="setSelectedDate"
      />
      <ButtonComponent
        v-if="showDashboardHeaderModalButton"
        v-exclude-from-print
        variant="primary"
        class="body--dashboard-header-button"
        icon="expand-alt"
        @click="openDashboardHeaderModal"
      />
    </div>
    <div class="body--scrollarea">
      <div
        v-if="primaryNotationDetails.notation && primaryNotationDetails.hasLoaded"
        class="body--column is-first"
      >
        <NotationDetails
          slot-kind="primary"
          :notation="primaryNotationDetails.notation"
          :selected-date="selectedDate"
          :show-absolute-prices="forInternalUse"
          v-bind="primaryNotationDetails.data"
        />
      </div>
      <div
        v-if="secondaryNotationDetails.notation && secondaryNotationDetails.hasLoaded"
        class="body--column is-second"
      >
        <NotationDetails
          slot-kind="secondary"
          class="is-secondary"
          :notation="secondaryNotationDetails.notation"
          :selected-date="selectedDate"
          :show-absolute-prices="forInternalUse"
          v-bind="secondaryNotationDetails.data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotationDetails from '@/components/NotationDetails/Component.vue';
import QuotesChart from '@/components/QuotesChart/Component.vue';
import ModalWindowController from '@/controllers/ModalWindowController';

export default {
  name: 'NotationCardBody',
  components: {
    NotationDetails,
    QuotesChart,
  },
  props: {
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: null,
    },
    showDateSlider: {
      type: Boolean,
      default: true,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
    dashboardHeaderModalTitle: {
      type: String,
      required: true,
    },
    showDashboardHeaderModalButton: {
      type: Boolean,
      default: true,
    },
    selectedCurrencyId: {
      type: [String, Number],
      default: null,
    },
    forInternalUse: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change-date', 'update:selectedGraphLimits'],
  setup(_props, { emit }) {
    const setSelectedDate = (date) => {
      emit('change-date', date);
    };
    return {
      setSelectedDate,
    };
  },
  methods: {
    openDashboardHeaderModal() {
      ModalWindowController.show('DashboardHeaderModal', {
        props: {
          primaryNotationDetails: this.primaryNotationDetails,
          secondaryNotationDetails: this.secondaryNotationDetails,
          selectedGraphLimits: this.selectedGraphLimits,
          selectedDate: this.selectedDate,
          title: this.dashboardHeaderModalTitle,
          selectedCurrencyId: this.selectedCurrencyId,
        },
        events: {
          'update:selectedGraphLimits': (graphLimits) => {
            this.$emit('update:selectedGraphLimits', graphLimits);
          },
          'change-date': (date) => {
            this.setSelectedDate(date);
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>