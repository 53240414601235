<template>
  <component
    :is="tag"
    :type="type"
    :class="buttonClass"
    :disabled="isDisabled"
    :aria-label="ariaLabel"
    @click="$emit('click', $event)"
  >
    <slot name="icon">
      <Icon
        v-if="icon"
        class="button-icon"
        :name="icon"
        :variant="iconVariant"
        :fixed-width="iconFixedWidth"
      />
    </slot>
    <slot />
  </component>
</template>

<script>

const ButtonVariants = ['primary', 'secondary', 'tertiary', 'ternary', 'primary-inverted'];
const isValidVariant = (variant) => ButtonVariants.includes(variant);

const ButtonSizes = [null, 'large'];
const isValidSize = (size) => ButtonSizes.includes(size);

const ButtonPaddings = [null, 'vertical', 'none'];
const isValidPadding = (padding) => ButtonPaddings.includes(padding);

export default {
  name: 'ButtonComponent',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
      validator: isValidVariant,
    },
    size: {
      type: String,
      default: null,
      validator: isValidSize,
    },
    padding: {
      type: String,
      default: null,
      validator: isValidPadding,
    },
    icon: {
      type: String,
      default: null,
    },
    iconVariant: {
      type: String,
      default: 'light',
    },
    iconFixedWidth: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    // Detecting slot presence might sometimes be tricky (v-if etc), so it's
    // also possible to force set this
    hasText: {
      type: Boolean,
      default: null
    }
  },
  emits: ['click'],
  computed: {
    buttonClass() {
      let hasText = !!this.$slots.default
      if (this.hasText != null) {
        hasText = this.hasText
      }
      return [
        `button-${this.variant}`,
        this.isDisabled ? 'is-disabled' : '',
        this.isToggled ? 'is-toggled' : '',
        this.size ? `is-${this.size}` : '',
        this.padding ? `has-pad-${this.padding}` : '',
        hasText ? 'has-text' : '',
      ].join(' ');
    },
  },
};
</script>
