<template>
  <li class="item">
    <WithTooltip
      :tooltip="tooltip"
      tag="div"
    >
      <div
        class="item--content"
        :class="{'is-highlighted': isHighlighted}"
      >
        <slot />
      </div>
    </WithTooltip>
  </li>
</template>

<script>
export default {
  name: 'NotationDetailsTagListItem',
  props: {
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>