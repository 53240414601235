<template>
  <div>
    <div class="form--columns">
      <TextInput
        v-model="dashboard.cluster_name_en"
        :label="$t('attributes.dashboard.cluster_name') + ' (EN)'"
        :required="true"
        @focus="$event.target.select()"
      />
      <TextInput
        v-model="dashboard.name_en"
        v-focus
        :label="$t('attributes.dashboard.name') + ' (EN)'"
        :required="true"
        @focus="$event.target.select()"
      />
    </div>
    <div class="form--columns">
      <TextInput
        v-model="dashboard.cluster_name_de"
        :label="$t('attributes.dashboard.cluster_name') + ' (DE)'"
        :required="true"
        @focus="$event.target.select()"
      />
      <TextInput
        v-model="dashboard.name_de"
        :label="$t('attributes.dashboard.name') + ' (DE)'"
        :required="true"
        @focus="$event.target.select()"
      />
    </div>
    <MultiSelectInput
      v-model="dashboard.product_group_ids"
      :options="productGroups"
      :label="$t('attributes.dashboard.product_groups')"
      :required="true"
      :access-key="(pg) => pg.id"
      :access-value="(pg) => pg.name"
      :tooltip="$t('attributes.dashboard.product_groups_tooltip')"
    />
    <SelectInput
      v-model="dashboard.subscription_package_id"
      :label="$t('attributes.dashboard.subscription_package')"
      :required="true"
      :options="subscriptionPackages"
      :tooltip="$t('attributes.dashboard.subscription_package_tooltip')"
    />
  </div>
</template>

<script>
import { ProductGroupsApi } from '@/api/ProductGroupsApi';
import SubscriptionPackagesApi from '@/api/SubscriptionPackagesApi';

export default {
  name: 'PublishedDashboardForm',
  props: {
    initialDashboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dashboard: {
        cluster_name_en: this.initialDashboard.cluster_name_en,
        cluster_name_de: this.initialDashboard.cluster_name_de,
        name_en: this.initialDashboard.name_en,
        name_de: this.initialDashboard.name_de,
        product_group_ids: (this.initialDashboard.product_groups || []).map((pg) => pg.id),
        subscription_package_id: (this.initialDashboard.subscription_package || {}).id,
      },
      productGroups: [],
      subscriptionPackages: [],
    };
  },
  beforeMount() {
    new ProductGroupsApi().productGroups().then((productGroups) => {
      this.productGroups = productGroups;
    });
    new SubscriptionPackagesApi().index().then((subscriptionPackages) => {
      this.subscriptionPackages = subscriptionPackages;
    });
  },
};
</script>
