<template>
  <div>
    <DropdownMenuButton
      icon="plus"
      @click="onSelectExistingDashboard"
    >
      {{ $t('components.search_header.batch_actions.add_to_dashboard') }}
    </DropdownMenuButton>
  </div>
</template>

<script>
import ModalWindowController from '@/controllers/ModalWindowController';

export default {
  name: 'AddNotationsToDashboardDropdownButton',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSelectExistingDashboard() {
      ModalWindowController.show('AddNotationsToDashboardModal', {
        props: {
          count: this.controller.checkedNotations.length,
          showClusters: this.controller.user.editor_permissions,
        },
        events: {
          close: () => { ModalWindowController.close(); },
          'add-to-dashboard': (dashboard) => {
            this.controller.addCheckedNotationsToExistingDashboard(dashboard)
              .then(() => { ModalWindowController.close(); });
          },
          'create-dashboard': (dashboard) => {
            this.controller.createNewDashboard(dashboard)
              .then(() => { ModalWindowController.close(); });
          },
        },
      });
    },
  },
};
</script>
