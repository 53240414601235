import { reactive } from 'vue';
import axios from 'axios';
import { NotNullOrUndefined } from '@/helpers';

export default class PaginationService {
  constructor({
    url = null,
    method = null,
    requestFunction = axios,
    transformEntry = (entry) => entry,
    errorHandler = (error) => { throw error; },
  }) {
    this._url = url;
    this._method = method?.toUpperCase();
    this._requestFunction = requestFunction;
    this._transformEntry = transformEntry;
    this._cancelToken = axios.CancelToken.source();
    this._errorHandler = errorHandler;
    this._params = {};
    // NOTE: Never reassign entries. Then other services
    // and controllers can keep hold of them and will
    // be updated through Vue's reactivity system.
    this._state = reactive({
      isLoading: false,
      nextPage: null,
      meta: null,
      entries: [],
    });
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get hasNextPage() {
    return NotNullOrUndefined(this._state.nextPage);
  }

  get meta() {
    return this._state.meta;
  }

  get entries() {
    return this._state.entries;
  }

  get _requestOptions() {
    const options = {
      url: this._url,
      method: this._method,
      cancelToken: this._cancelToken.token,
    };
    const paramsWithPage = { ...this._params, page: this._state.nextPage };
    if (this._method === 'GET') {
      options.params = paramsWithPage;
    } else {
      options.data = paramsWithPage;
    }
    return options;
  }

  _clearData() {
    // clear the array by setting its length to zero to keep references to the array intact
    this._state.entries.length = 0;
    this._state.meta = null;
  }

  cancel() {
    this._cancelToken.cancel('Cancelled by user');
    this._cancelToken = axios.CancelToken.source();
    this._state.isLoading = false;
  }

  reset() {
    this.cancel();
    this._state.isLoading = false;
    this._state.nextPage = null;
    this._clearData();
  }

  fetchFirstPage(params = {}, { clearBeforeFetch = true } = {}) {
    this.cancel();

    if (clearBeforeFetch) {
      this._clearData();
    }

    // remove any reactivity by converting to json and back
    this._params = JSON.parse(JSON.stringify(params));
    this._state.nextPage = 0;
    this._state.isLoading = false;
    return this.fetchNextPage({ isFirstPage: true });
  }

  fetchNextPage({ isFirstPage = false } = {}) {
    if (this.isLoading || !this.hasNextPage) {
      return Promise.resolve(false);
    }
    this._state.isLoading = true;
    return this._requestFunction(this._requestOptions)
      .then((response) => this._onResponse(response, { isFirstPage }))
      .catch((error) => this._onError(error));
  }

  _onError(error) {
    if (axios.isCancel(error)) {
      return true;
    }
    this._state.nextPage = null;
    this._state.isLoading = false;
    this._errorHandler(error);
    return false;
  }

  _onResponse(response, { isFirstPage = false } = {}) {
    if (isFirstPage) {
      this._clearData();
      this._state.meta = response.data.meta;
    }
    this._state.entries.push(...response.data.entries.map(this._transformEntry));
    this._state.nextPage = response.data.next_page;
    this._state.isLoading = false;
    return true;
  }
}
