<template>
  <div class="instance">
    <div
      ref="header"
      :class="`instance--header ${headerOnlyFirstPage ? 'instance--header__only_first_page' : ''}`"
    >
      <slot name="header">
        <PrintLayoutInstanceDefaultHeader :caption="caption" />
      </slot>
    </div>
    <div
      ref="footer"
      class="instance--footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import PrintLayoutInstanceDefaultHeader from "./DefaultHeader/Component.vue";

// The print layout header and footer use `position: fixed` in
// order to be repeated on every page. This removes them from
// the flow of the page, though, which is why we have wrapped
// the entire page content into a table with a header and footer.
// The purpose of the table header and footer is to pad the content
// so that the true header and footer content doesn't overlap
// with it.
// To make sure that the padding matches the required size of
// the page header and footer, this component observes the header
// and footer size and dynamically adjusts the table header and
// footer size accordingly.
export default {
  name: "PrintLayoutInstance",
  components: {
    PrintLayoutInstanceDefaultHeader,
  },
  props: {
    caption: {
      type: String,
      default: null,
    },
    headerOnlyFirstPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerSizeObserver: new ResizeObserver(this.resizePrintHeader),
      footerSizeObserver: new ResizeObserver(this.resizePrintFooter),
    };
  },
  mounted() {
    this.headerSizeObserver.observe(this.$refs.header);
    this.resizePrintHeader();
    this.footerSizeObserver.observe(this.$refs.footer);
    this.resizePrintFooter();
  },
  beforeUnmount() {
    this.headerSizeObserver.disconnect();
    this.footerSizeObserver.disconnect();
  },
  methods: {
    resizePrintHeader() {
      const printHeaderElem = document.querySelector(".print-page--header-space");
      if (printHeaderElem) {
        const { height } = this.$refs.header.getBoundingClientRect();
        printHeaderElem.style.height = `${height}px`;
      }
    },
    resizePrintFooter() {
      const printFooterElem = document.querySelector(".print-page--footer-space");
      if (printFooterElem) {
        const { height } = this.$refs.footer.getBoundingClientRect();
        printFooterElem.style.height = `${height}px`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>