<template>
  <div class="config-table--head">
    <div class="data-table--head-cell config-table--axes-column">
      <h2 class="data-table--head-cell-content">
        {{ $t('apps.chart_config.table_head_axis') }}
      </h2>
    </div>
    <div class="data-table--head-cell config-table--notations-column">
      <h2 class="data-table--head-cell-content">
        {{ $t('apps.chart_config.table_head_notation') }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartNotationAssignmentTableHead',
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>