<template>
  <div class="news-filters">
    <div class="filters--input-group">
      <label
        id="product-group-filter-label"
        class="filters--input-label"
      >
        {{ $t('apps.publications.filters.product_groups') }}
      </label>
      <!-- eslint-disable vue/no-mutating-props -->
      <AutoCompleteMulti
        v-model="controller.selectedProductGroups"
        aria-labelledby="product-group-filter-label"
        :options="controller.productGroupOptions"
        :access-key="(g) => g.id"
        :access-value="(g) => g.name"
        :filter-function="filterFunction"
        :allow-empty-value="true"
        :placeholder="$t('apps.publications.filters.product_groups_placeholder')"
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </div>
    <div class="filters--input-group">
      <label
        id="region-filter-label"
        class="filters--input-label"
      >
        {{ $t('apps.publications.filters.regions') }}
        <IconTooltipComponent
          :tooltip="$t('apps.publications.filters.regions_tooltip_news')"
        />
      </label>
      <!-- eslint-disable vue/no-mutating-props -->
      <AutoCompleteMulti
        v-model="controller.selectedRegions"
        aria-labelledby="region-filter-label"
        :options="controller.regionOptions"
        :access-key="(r) => r.id"
        :access-value="(r) => r.name"
        :filter-function="filterFunction"
        :allow-empty-value="true"
        :placeholder="$t('apps.publications.filters.regions_placeholder')"
      />
    </div>
    <div class="filters--input-group">
      <label
        id="fulltext-filter-label"
        class="filters--input-label"
      >
        {{ $t('apps.publications.filters.fulltext') }}
      </label>
      <FulltextSearch
        v-model="controller.enteredFulltext"
        aria-labelledby="fulltext-filter-label"
        :placeholder="$t('apps.publications.filters.fulltext_placeholder')"
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </div>

    <div class="filters--spacer" />
    <div class="filters--input-group">
      <label
        id="sort-label"
        class="filters--input-label"
      >
        {{ $t('apps.publications.filters.sort') }}
      </label>
      <!-- eslint-disable vue/no-mutating-props -->
      <AutoComplete
        v-model="controller.selectedSortDirection"
        aria-labelledby="sort-label"
        :options="controller.sortDirectionOptions"
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </div>
  </div>
</template>

<script>
import FulltextSearch from '@/components/FulltextSearch/Component.vue';

export default {
  name: 'NewsFilter',
  components: {
    FulltextSearch,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  methods: {
    filterFunction(input, option) {
      return input !== null
          && (option.name_de.toLowerCase().indexOf(input.toLowerCase()) >= 0
          || option.name_en.toLowerCase().indexOf(input.toLowerCase()) >= 0);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>