<template>
  <div
    class="publications page is-narrow"
  >
    <div
      v-exclude-from-print
      class="page-header"
    >
      <h1 class="page-header--title">
        {{ $t('page_titles.product_group_reports') }}
      </h1>
    </div>

    <ProductGroupReportsFilter :controller="controller" />

    <ProductGroupReportsList :controller="controller" />
  </div>
</template>

<script>
import ProductGroupReportsController from './Controller';
import ProductGroupReportsList from './components/List/Component.vue';
import ProductGroupReportsFilter from './components/Filter/Component.vue';

export default {
  name: 'ProductGroupReportsApp',
  components: {
    ProductGroupReportsList,
    ProductGroupReportsFilter,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      controller: null,
    };
  },
  beforeMount() {
    this.controller = new ProductGroupReportsController(this.user, this.initialState);
  },
};
</script>
