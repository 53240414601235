<template>
  <div>
    <ButtonComponent
      variant="ternary"
      icon="undo"
      :disabled="!areButtonsEnabled"
      @click="$emit('reset')"
    >
      {{ $t('apps.subscriptions.buttons.reset') }}
    </ButtonComponent>
    <ButtonComponent
      variant="primary"
      icon="save"
      :disabled="!areButtonsEnabled"
      @click="$emit('save')"
    >
      {{ $t('apps.subscriptions.buttons.save_settings') }}
    </ButtonComponent>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionsSettingsControl',
  props: {
    areButtonsEnabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['save', 'reset'],
};
</script>
