<script>
export default {
  props: {
    supplyChainItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    href() {
      return `/supply_chain_items/${this.supplyChainItem.id}/edit`
    }
  }
}
</script>

<template>
  <td>
    <a
      :href="href"
      data-modal="is-overflowable is-wide align-top"
      data-remote="true"
    >
      <Icon name="pen"/>
    </a>
  </td>
</template>

<style lang="scss">

</style>
