<template>
  <ComboBoxOption class="kind-option">
    {{ value }}
    <img
      v-if="showIcon"
      class="kind-option--icon"
      :src="iconSource"
    >
  </ComboBoxOption>
</template>

<script>
import { IconForKind } from '@/helpers/PublicationHelpers';

export default {
  name: 'PublicationFiltersKindOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    accessKey: {
      type: Function,
      required: true,
    },
    accessValue: {
      type: Function,
      required: true,
    },
  },
  computed: {
    key() {
      return this.accessKey(this.option);
    },
    value() {
      return this.accessValue(this.option);
    },
    iconSource() {
      return IconForKind(this.key);
    },
    showIcon() {
      return this.key !== 'any';
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>