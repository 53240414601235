<template>
  <DashboardPageLayout :separator="true">
    <template #top>
      <DashboardHeader
        :dashboard="reactiveDashboard"
        :user="user"
      />
      <PublicationsFilters :controller="controller" />
    </template>
    <template #bottom>
      <PublicationsList :controller="controller" />
    </template>
  </DashboardPageLayout>
</template>

<script>
import { DashboardPageLayout } from '@/components/Layouts';
import { DashboardHeader } from '@/components';
// TODO: move to components directory
import PublicationsController from '@/apps/Publications/Controller';
import PublicationsFilters from '@/apps/Publications/components/Filters/Component.vue';
import PublicationsList from '@/apps/Publications/components/List/Component.vue';

export default {
  name: 'DashboardPublicationsApp',
  components: {
    DashboardPageLayout,
    DashboardHeader,
    PublicationsFilters,
    PublicationsList,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reactiveDashboard: this.dashboard,
      controller: new PublicationsController(this.user, {
        forDashboardId: this.dashboard.id,
        enableEditing: false,
        initialFilters: {
          sort: 'is_unread_desc',
          ...this.initialState?.filters,
        },
      }),
    };
  },
  beforeUnmount() {
    this.controller.beforeUnmount();
  },
};
</script>
