<template>
  <td
    class="data-table--body-cell cannot-click"
    @click="cellClicked"
  >
    <div class="dashboard-name-column">
      <button
        ref="dashboardNameButton"
        class="dashboard-name-column--name"
      >
        {{ value.name }}
      </button>
      <ul class="dashboard-name-column--badge-list">
        <ProductGroupBadge
          v-for="productGroup in relevantProductGroupsForUser(value.product_groups || [], user)"
          :key="productGroup.id"
          :product-group="productGroup"
          class="dashboard-name-column--badge"
          @click="openProductGroupReportFor(productGroup, value.product_groups)"
        />
      </ul>
    </div>
    <span
      v-if="value.own_view_active"
      class="dashboard-name-column--own-view-badge"
    >
      {{ $t('components.notation_list.columns.dashboard_name.own_view_active') }}
    </span>
  </td>
</template>

<script>
import ProductGroupBadge from '@/components/ProductGroupBadge/Component.vue';
import {
  relevantProductGroupsForUser,
  openProductGroupReportFor,
} from '@/helpers/ProductGroupHelpers';

export default {
  name: 'DashboardNameColumn',
  components: {
    ProductGroupBadge,
  },
  inject: ['user'],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relevantProductGroupsForUser,
      openProductGroupReportFor,
    };
  },
  methods: {
    cellClicked(event) {
      if (!this.$refs.dashboardNameButton.contains(event.target)) {
        event.stopPropagation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>