import Column from "./Column";

export default class ScoreColumn extends Column {
  static className = "ScoreColumn"

  static buildCollection({
    dimensions,
    ...options
  }) {
    return dimensions.map((dimension)=>{
      return new ScoreColumn({
        dimension: dimension,
        ...options
      })
    })
  }

  constructor(options) {
    options.component ||= options.dimension.value === 0 ? 'TotalHrScoreColumn' : 'HrScoresColumn'
    options.getValue ||= (item) => {
      let scores = this.getScores(item)
      if (!scores) { return '-' }
      return scores[this.dimension.value]?.value
    }

    options.hrTooltipData ||= (item) => {
      let scores = this.getScores(item)
      if (!scores) { return }
      return {
        hrScores: scores,
        title: this.tooltipTitle && this.tooltipTitle(item),
      }
    }

    // return props that will be passed to the component
    options.getProps ||= (item) => {
      let scores = this.getScores(item)
      if (this.isTotalDimension) {
        return {
          value: {
            dimensions: scores,
            total: scores && scores[this.dimension.value],
            withFlagIcon: true,
            title: this.tooltipTitle && this.tooltipTitle(item),
            withTooltip: true
          }
        }
      } else if(scores) {
        return { value: scores[this.dimension.value] }
      }
    }

    super(options)
    this.dimension = options.dimension
    this.getScores = options.getScores
    this.tooltipTitle = options.tooltipTitle
  }

  get header() {
    return this.dimension.label
  }

  get isTotalDimension() {
    return this.dimension.value === 0
  }
}
