/**
 * Adjusts the container.scrollTop so that element
 * is completely visible.
 */
export default (container, element) => {
  if (typeof container?.getBoundingClientRect != "function") {
    return
  }
  const containerRect = container.getBoundingClientRect();
  const containerTop = containerRect.top;
  const containerBottom = containerTop + containerRect.height;

  const currentElement = element[0]?.nodeType === 1 ? element[0] : element;

  if (typeof currentElement?.getBoundingClientRect != "function") {
    return
  }

  const elementRect = currentElement.getBoundingClientRect();
  const elementTop = elementRect.top;
  const elementBottom = elementTop + elementRect.height;

  if (elementTop < containerTop) {
    const delta = elementTop - containerTop;
    container.scrollTop += delta;
  } else if (elementBottom > containerBottom) {
    const delta = elementBottom - containerBottom;
    container.scrollTop += delta;
  }
  return null;
};
