<template>
  <p class="trade-flow--empty-hint">
    {{ $t('components.trade_flows.empty_hint') }}
  </p>
</template>

<script>
export default {
  name: 'EmptyTradeFlows',
};
</script>
