<template>
  <TableHeadCell
    :title="displayName"
    :is-sortable="true"
    :sort-direction="sortDirection"
    :tooltip="tooltip"
    @click="cycleColumnSort()"
  />
</template>

<script>
import TableHeadCell from '../TableHeadCell.vue';

export default {
  name: 'SortColumnHead',
  components: {
    TableHeadCell,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: '',
    },
    controller: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    sortDirection() {
      if (this.controller.sorting != null && this.controller.sorting.column === this.columnKey) {
        return this.controller.sorting.direction;
      }
      return 'unsorted';
    },
  },
  methods: {
    cycleColumnSort() {
      this.controller.cycleColumnSort(this.columnKey);
      this.controller.updateNotationsAndUserState();
    },
  },
};
</script>
