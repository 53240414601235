<template>
  <div class="notation-details">
    <div class="notation-details--head">
      <NotationDetailsTitle
        :class="titleCssModifier"
        :name="notation.name"
        :class-code="notation.class_code"
        :class-system="notation.class_system"
        :slot-kind="slotKind"
        @mousemove="onTitleMouseEnter"
        @mouseleave="onTitleMouseLeave"
      />
      <NotationDetailsTagList
        class="notation-details--tags"
        :notation="notation"
        :unit="unit"
        :unit-converted-from="unitConvertedFrom"
      />
    </div>

    <NotationDetailsKpiList
      v-if="keyPerformanceIndicators"
      class="notation-details--kpi-list"
      :timescope="notation.timescope"
      :key-performance-indicators="keyPerformanceIndicators"
      :base-value-precision="latestValuePrecision"
      :show-absolute-prices="showAbsolutePrices"
    />
    <NotationDetailsLatestValue
      v-if="showAbsolutePrices && latestQuote"
      :value="latestQuote.value"
      :date="latestQuote.date"
      :unit="unit || notation.unit"
      :timescope="notation.timescope"
    />
  </div>
</template>

<script>
import { gradualPrecisionFor } from '@/helpers/NumberFormats';
import NotationDetailsTitle from './Title/Component.vue';
import NotationDetailsKpiList from './KpiList/Component.vue';
import NotationDetailsTagList from './TagList/Component.vue';
import NotationDetailsLatestValue from './LatestValue/Component.vue';

export default {
  name: 'NotationDetails',
  components: {
    NotationDetailsTitle,
    NotationDetailsKpiList,
    NotationDetailsTagList,
    NotationDetailsLatestValue,
  },
  props: {
    slotKind: {
      type: String,
      required: true,
    },
    notation: {
      type: Object,
      required: true,
    },
    quotes: {
      type: Array,
      default: () => [],
    },
    keyPerformanceIndicators: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
    unitConvertedFrom: {
      type: String,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const isScreen = this.$media === 'screen';

    return {
      expandTitle: !isScreen,
      clampTitle: isScreen,
      clampTimout: null,
    };
  },
  computed: {
    titleCssModifier() {
      const modifiers = [];
      if (this.expandTitle) {
        modifiers.push('is-expanded');
      }
      if (this.clampTitle) {
        modifiers.push('is-clamped');
      }
      return modifiers.join(' ');
    },
    latestQuote() {
      return this.quotes[this.quotes.length - 1];
    },
    latestValuePrecision() {
      return gradualPrecisionFor(this.latestQuote?.value);
    },
  },
  methods: {
    onTitleMouseEnter() {
      this.clearClampTimeout();
      this.expandTitle = true;
      this.clampTitle = false;
    },
    onTitleMouseLeave() {
      this.expandTitle = false;
      this.clearClampTimeout();
      this.clampTimeout = setTimeout(() => {
        this.clampTitle = true;
      }, 500);
    },
    clearClampTimeout() {
      if (this.clampTimeout) {
        clearTimeout(this.clampTimeout);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>