const unitCompositionParams = (compositeUnit) => {
  switch (compositeUnit.composition_category) {
    case 'price':
      return {
        numerator_unit_id: compositeUnit.currency_unit_id,
        denominator_unit_id: compositeUnit.volume_unit_id,
      };
    case 'value':
      return {
        numerator_unit_id: compositeUnit.currency_unit_id,
      };
    case 'volume':
      return {
        numerator_unit_id: compositeUnit.volume_unit_id,
      };
    default:
      return null;
  }
};

export {
  unitCompositionParams,
};
