<template>
  <g>
    <rect
      ref="bar"
      class="bar-chart--bar"
      :x="barPositionX"
      :y="y"
      :width="barWidth"
      :height="height"
    />
    <!-- Using dy="0.35em" to offset labels just like d3 does for the tick labels. -->
    <text
      v-if="outerLabel"
      class="bar-chart--label"
      :x="outerLabelPosition.x"
      :y="outerLabelPosition.y"
      :text-anchor="outerLabelTextAnchor"
      dy="0.35em"
    >
      {{ outerLabel }}
    </text>
    <text
      v-if="innerLabel"
      ref="innerLabel"
      class="bar-chart--percentage"
      :style="innerLabelStyles"
      :x="innerLabelPosition.x"
      :y="innerLabelPosition.y"
      :text-anchor="innerLabelTextAnchor"
      dy="0.35em"
    >
      {{ innerLabel }}
    </text>
  </g>
</template>

<script>
export default {
  name: 'HorizontalBar',
  props: {
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    textPosition: {
      type: String,
      default: 'right',
      validator: (v) => ['left', 'right'].indexOf(v) >= 0,
    },
    textMargin: {
      type: Number,
      default: 5,
    },
    outerLabel: {
      type: String,
      default: null,
    },
    innerLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      displayInnerLabel: false,
    };
  },
  computed: {
    barPositionX() {
      return this.width >= 0
        ? this.x
        : this.x + this.width;
    },
    barWidth() {
      return Math.abs(this.width);
    },
    textPositionX() {
      return this.x + this.width;
    },
    textPositionY() {
      return this.y + this.height / 2;
    },
    innerLabelPosition() {
      const x = this.textPosition === 'left'
        ? this.textPositionX + this.textMargin
        : this.textPositionX - this.textMargin;
      return { x, y: this.textPositionY };
    },
    outerLabelPosition() {
      const x = this.textPosition === 'left'
        ? this.textPositionX - this.textMargin
        : this.textPositionX + this.textMargin;
      return { x, y: this.textPositionY };
    },
    innerLabelTextAnchor() {
      return this.textPosition === 'left' ? 'start' : 'end';
    },
    outerLabelTextAnchor() {
      return this.textPosition === 'left' ? 'end' : 'start';
    },
    innerLabelStyles() {
      return this.displayInnerLabel
        ? {}
        : { visibility: 'hidden' };
    },
  },
  updated() {
    this.updateInnerLabelVisibility();
  },
  methods: {
    updateInnerLabelVisibility() {
      if (!this.$refs.bar || !this.$refs.innerLabel) {
        this.displayInnerLabel = false;
        return;
      }
      const barRect = this.$refs.bar.getBoundingClientRect();
      const innerLabelRect = this.$refs.innerLabel.getBoundingClientRect();
      let innerLabelInBounds;
      if (this.textPosition === 'right') {
        innerLabelInBounds = innerLabelRect.left >= (barRect.left + this.textMargin - 1);
      } else if (this.textPosition === 'left') {
        innerLabelInBounds = innerLabelRect.right <= (barRect.right - this.textMargin + 1);
      }
      this.displayInnerLabel = innerLabelInBounds;
    },
  },
};
</script>
