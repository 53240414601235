<template>
  <div
    v-if="comparisonDate === null"
    class="search-header--action-list-item"
  >
    <ButtonComponent
      v-click-outside="() => { showDatePickerPopup = false }"
      variant="primary"
      icon="calendar"
      :is-disabled="isDisabled"
      class="date-picker-button"
      @click="() => { showDatePickerPopup = true }"
    >
      {{ $t('components.search_header.compare_to_date') }}

      <DatePickerPopup
        v-if="showDatePickerPopup"
        class="date-picker-popup"
        :class="`${yAlignment}-aligned`"
        :date="comparisonDate"
        @select-date="onDateSelect"
      />
    </ButtonComponent>
  </div>
  <div
    v-else
    class="search-header--action-list-item"
  >
    <!-- :key property on the button is required to
          prevent @click from being triggered twice -->
    <ButtonComponent
      :key="'closeDatePicker'"
      variant="primary"
      icon="calendar-times"
      :is-disabled="isDisabled"
      @click="onDateSelect(null)"
    >
      {{ $t('components.search_header.end_compare_to_date') }}
    </ButtonComponent>
  </div>
</template>

<script>
import DatePickerPopup from '@/components/DatePickerPopup/Component.vue';

export default {
  name: 'DatePickerButton',
  components: {
    DatePickerPopup,
  },
  props: {
    comparisonDate: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    yAlignment: {
      type: String,
      default: 'bottom',
    },
  },
  emits: ['update:comparisonDate'],
  data() {
    return {
      showDatePickerPopup: false,
    };
  },
  methods: {
    onDateSelect(date) {
      this.$emit('update:comparisonDate', date);
      this.showDatePickerPopup = false;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>