<template>
  <DropdownMenuButton
    icon="chart-line"
    :disabled="notationIds.length > 1"
    @click="startAnalysis()"
  >
    {{ $t('components.search_header.batch_actions.start_season_line_chart_analysis') }}
  </DropdownMenuButton>
</template>

<script>
import Turbolinks from 'turbolinks';

export default {
  props: {
    notationIds: {
      type: Array,
      required: true,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    startAnalysis() {
      const notationQueryString = this.notationIds.map((id) => `notation_id=${id}`);
      const dashboardQueryString = this.dashboardId ? `&dashboard_id=${this.dashboardId}` : '';
      Turbolinks.visit(`/season-line-chart-configuration?${notationQueryString}${dashboardQueryString}`);
    },
  },
};
</script>
