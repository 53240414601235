<template>
  <div class="metadata">
    <span
      v-for="item in metadata"
      :key="item.value.name"
      class="metadata--item"
      :aria-label="item.value.name"
    >
      {{ `${item.value.name} ${item.value.value}` }}
    </span>
  </div>
</template>

<script>
import moment from 'moment';
import { computed } from 'vue';
import i18n from '@/config/i18n';

export default {
  name: 'NotationAnalysesListItemMetadata',
  props: {
    createdAt: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const createdAt = computed(() => ({
      name: i18n.t('apps.notation_analyses.created_at'),
      value: props.createdAt ? moment(props.createdAt).format('ll') : null,
    }));
    const updatedAt = computed(() => ({
      name: i18n.t('apps.notation_analyses.updated_at'),
      value: props.updatedAt ? moment(props.updatedAt).format('ll') : null,
    }));
    const metadata = computed(() => [
      createdAt,
      updatedAt,
    ].filter((item) => !!item.value.value));

    return { metadata };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>