<template>
  <TabBar
    :model-value="selectedDashboardType"
    :tabs="tabs"
    :dark-style="true"
    @update:modelValue="$emit('update:dashboardType', $event)"
  />
</template>

<script>
import { DashboardTypes } from '@/apps/Intro/IntroAppController';
import { TabBar } from '@/components/generic';

export default {
  name: 'DashboardTypeTabs',
  components: { TabBar },
  inject: ['user'],
  props: {
    dashboardType: {
      type: String,
      required: true,
    },
  },
  emits: [
    'update:dashboardType',
  ],
  computed: {
    hasHumanRightsSubscription() {
      return this.user.has_human_rights_subscription;
    },
    selectedDashboardType() {
      /**
       * In case the permission expires during the session, we redirect the user to the default dashboard type
       */
      if (this.dashboardType === DashboardTypes.HumanRightsRisks && !this.hasHumanRightsSubscription) {
        this.$emit('update:dashboardType', DashboardTypes.Favorites);
        return DashboardTypes.Favorites;
      }

      return this.dashboardType;
    },
    tabs() {
      return [{
        name: this.$t('components.intro.dashboard_type_tabs.favorites'),
        value: DashboardTypes.Favorites,
      }, {
        name: this.$t('components.intro.dashboard_type_tabs.indicators'),
        value: DashboardTypes.Indicators,
      },
      {
        name: this.$t('components.intro.dashboard_type_tabs.human_rights_risks.tab_title'),
        value: DashboardTypes.HumanRightsRisks,
        disabled: !this.hasHumanRightsSubscription,
        tooltip: this.hasHumanRightsSubscription ? null : this.$t('components.intro.dashboard_type_tabs.human_rights_risks.missing_subscription'),
      }];
    },
  },
};
</script>
