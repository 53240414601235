<template>
  <td class="data-table--body-cell" v-on="props.eventListeners">
    <WithTooltip y-alignment="auto" tag="div" v-if="total">
      <HrScore
        class="total-hr-score--content"
        :value="total"
        :dimensions="dimensions"
        :withFlagIcon="withFlagIcon"
      />

      <template v-if="value.withTooltip" #custom>
        <TopFiveCountryCellTooltip :data="tooltipData" />
      </template>
    </WithTooltip>
    <div v-else class="is-centered">-</div>
  </td>
</template>

<script setup>
import { computed, watch } from 'vue';
import WithTooltip from '../../../../generic/WithTooltip/Component.vue';
import TopFiveCountryCellTooltip from '../TopFiveCountry/TopFiveCountryCellTooltip.vue';
import HrScore from '../HrScoresColumn/HrScore'

const props = defineProps(['value', 'eventListeners']);

const total = computed(() => props.value?.total);
const dimensions = computed(() => props.value?.dimensions);
const tooltipData = computed(() => ({
  hrScores: dimensions.value,
  title: props.value?.title,
  currentCountry: props.value?.currentCountry
}));
const withFlagIcon = computed(() => props.value?.withFlagIcon);
</script>

<style lang="scss">
.total-hr-score--content {
  justify-content: left !important;
  display: flex;
  width: 50px;
  margin: auto;
}
</style>
