import axios from 'axios';

const apiUrl = '/api/human_rights/sector_portraits/';

const SectorPortraitsApi = () => ({
  getSectorData(sector_id) {
    return axios.get(`${apiUrl}${sector_id}`)
      .then((response) => response.data);
  },
});

export default SectorPortraitsApi;
