<template>
  <ul class="item-list">
    <li
      v-for="item in items"
      :key="item.id"
    >
      <DashboardSearchListLink
        :item="item"
        :is-active="selectedId === item.key"
        :can-toggle-favorite="canToggleFavorites"
        @mousemove="onFocus(item)"
      />
    </li>
  </ul>
</template>

<script>
import DashboardSearchListLink from '../Link/Component.vue';

export default {
  name: 'DashboardSearchListItemList',
  components: {
    DashboardSearchListLink,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: [Number, String],
      default: null,
    },
    canToggleFavorites: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'focus',
  ],
  methods: {
    onFocus(item) {
      this.$emit('focus', item);
    },
  },
};
</script>
