<template>
  <div
    class="key-performance-indicator is-small"
    :class="classForValue"
  >
    <div
      class="key-performance-indicator--value"
    >
      {{ formattedValue }}
      <Icon
        v-if="icon"
        class="key-performance-indicator--icon"
        :variant="icon.variant"
        :name="icon.name"
      />
    </div>
    <div
      v-if="formattedBaseValue"
      class="key-performance-indicator--base"
    >
      <span class="key-performance-indicator--prefix">
        {{ $t('components.notation_kpi.from') }}
      </span>
      {{ formattedBaseValue }}
    </div>
  </div>
</template>

<script>
import { formatFixedPrecision } from '@/helpers/NumberFormats';

const isInvalidValue = (value) => value === null || value === undefined || Number.isNaN(value);

export default {
  name: 'KeyPerformanceIndicator',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    baseValue: {
      type: Number,
      default: undefined,
    },
    postfix: {
      type: String,
      default: '%',
    },
    baseValuePrecision: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    icon() {
      if (this.isNeutral || Number.isNaN(this.value)) return null;
      return {
        name: this.value > 0 ? 'arrow-alt-up' : 'arrow-alt-down',
        variant: 'solid',
      };
    },
    asAbsoluteString() {
      if (isInvalidValue(this.value)) return 'N/A';
      return this.$n(Math.abs(this.value), 'decimal', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
    isNeutral() {
      return !this.asAbsoluteString.match(/[1-9]/);
    },
    prefix() {
      if (this.isNeutral) return '';
      return this.value > 0 ? '+ ' : '- ';
    },
    formattedValue() {
      if (isInvalidValue(this.value)) return 'N/A';
      return `${this.prefix}${this.asAbsoluteString}${this.postfix}`;
    },
    formattedBaseValue() {
      if (isInvalidValue(this.baseValue)) return null;
      return formatFixedPrecision(this.baseValue, this.baseValuePrecision);
    },
    classForValue() {
      if (this.isNeutral || isInvalidValue(this.value)) return null;
      return this.value > 0 ? 'is-positive' : 'is-negative';
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>