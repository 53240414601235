<template>
  <DashboardSearchListSection>
    <template #title>
      {{ $t('components.dashboard_search_list.section_title_suggested') }}
      <button
        v-if="isExpanded"
        class="suggestions--button"
        @click="toggle"
      >
        {{ buttonLabel }}
        <Icon name="angle-up" />
      </button>
    </template>
    <DashboardSearchListTagList
      :tags="items"
      :orientation="orientation"
      :can-remove="true"
      :selected-id="selectedId"
      @select="$emit('select', $event)"
      @focus="$emit('focus', $event)"
    >
      <template
        v-if="canExpand && !isExpanded"
        #trailing
      >
        <button
          class="suggestions--button"
          @click="toggle"
        >
          {{ buttonLabel }}
          <Icon name="angle-down" />
        </button>
      </template>
    </DashboardSearchListTagList>
  </DashboardSearchListSection>
</template>

<script>
import DashboardSearchListSection from '../Section/Component.vue';
import DashboardSearchListTagList from '../TagList/Component.vue';

export default {
  name: 'DashboardSearchListSuggestions',
  components: {
    DashboardSearchListSection,
    DashboardSearchListTagList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: [Number, String],
      default: null,
    },
    isExpanded: {
      type: Boolean,
      default: true,
    },
    totalItemCount: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'focus',
    'select',
    'collapse',
    'expand',
  ],
  computed: {
    canExpand() {
      return this.isExpanded || this.totalItemCount > this.items.length;
    },
    orientation() {
      return this.isExpanded ? 'vertical' : 'horizontal';
    },
    buttonLabel() {
      const count = this.totalItemCount - this.items.length;
      return this.isExpanded
        ? this.$t('components.dashboard_search_list.show_less_suggestions')
        : this.$t('components.dashboard_search_list.show_more_suggestions', { count });
    },
  },
  methods: {
    toggle() {
      if (this.isExpanded) {
        this.$emit('collapse');
      } else {
        this.$emit('expand');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>