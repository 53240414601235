<template>
  <div :class="`button-tabs ${tabClasses[tabPosition]}`">
    <div class="button-tabs__head">
      <ul class="button-tabs__header">
        <li
          v-for="{title, icon} in tabs"
          :key="title"
          :class="{'selected-tab': selectedTabTitle === title}"
          @click="setSelectedTabTitle(title)"
        >
          <Icon
            :name="icon"
            variant="solid"
          />
          {{ title }}
        </li>
        <li
          v-if="hint"
          class="button-tabs__hint"
        >
          {{ hint }}
        </li>
      </ul>
      <div>
        <slot name="additional-content" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import {
  computed,
  ref, useSlots
} from "vue";
import { getQueryParam, setQueryParam } from "../../services/UrlStateStore";

export default {
  name: "ButtonTabs",
  provide() {
    return {
      selectedTabTitle: computed(() => this.selectedTabTitle)
    };
  },
  props: {
    hint: {
      type: String,
      default: null
    },
    tabClasses: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const slots = useSlots();
    const tabs = slots.default().map((tab) => ({ title: tab.props.title, icon: tab.props.icon }));
    const tabPosition = getQueryParam("tab_position") || 0;
    return {
      tabs,
      selectedTabTitle: tabs[tabPosition]?.title,
      tabPosition
    };
  },
  methods: {
    // sets the selected tab title in the url and in the component
    setSelectedTabTitle(title) {
      this.selectedTabTitle = title;
      this.tabPosition = this.tabs.findIndex((tab) => tab.title === title);
      // Set query param to tab title index
      setQueryParam("tab_position", this.tabPosition);
    }
  }
};
</script>

<style lang="scss">
.button-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__head {
    padding: $space-s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #b9b9b9;
    align-items: center;
  }

  &__header {
    display: flex;

    li {
      max-height: 50px;
      min-width: 80px;
      padding: 7px 15px;
      &.with-link {
        padding: 0;
        &:not(.selected-tab):hover {
          background: rgba(253, 242, 239, 0.5);
          a {
            cursor: pointer;
          }
        }
        a {
          cursor: default;
          text-decoration: none;
          display: inline-block;
          padding: 7px 15px;
        }
      }
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex-wrap: wrap;

      &.selected-tab {
        color: #CB2B33;
        background: #FDF2EF;
        border-radius: 3px;
      }
    }
  }

  &__hint {
    color: #CB2B33;
    pointer-events: none;
  }
}

@media print {
  .button-tabs {
    &__header {
      display: none;
    }

    height: auto;

    &__autoheight {
      height: auto;
    }

    &__minheight {
      height: 380px;
    }
  }
}
</style>
