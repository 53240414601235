<template>
  <SpinningLoader
    v-if="isLoading"
    class="chart--loading-spinner"
  />
  <div
    v-else-if="hasError"
    class="chart--not-found"
  >
    <h1 class="chart--not-found-title">
      {{ $t('apps.multi_line_chart_view.error_title') }}
    </h1>
  </div>

  <SplitPageLayout
    v-else
    screen-size="medium"
    :with-separator="$media !== 'print'"
  >
    <template #top>
      <p
        v-if="forInternalUse && showKpis"
        class="print-only"
      >
        {{ $t('generic.for_internal_use_only') }}
      </p>
      <PrintLayout :caption="title" />
      <ChartViewHeader
        v-exclude-from-print
        :title="title"
        :config="config"
        :saved-analysis-id="savedAnalysisId"
        :save-config-disabled="saveConfigDisabled"
        :is-saving="isSaving"
        :config-url="configUrl"
        :show-kpis="showKpis"
        @show-kpis="toggleShowKpis"
        @save="saveConfiguration"
      />
    </template>
    <template #bottom>
      <ChartView
        :index-year="index.year"
        :index-month-id="index.monthId"
        :datasets="datasets"
        :selected-graph-limits="graphLimits"
        :primary-notation-unit="primaryNotationUnit"
        :secondary-notation-unit="secondaryNotationUnit"
        :selected-range="currentRange"
        :selected-earliest-quote-date="selectedEarliestQuoteDate"
        :show-index-view="showIndexView"
        :earliest-quote-date-year="earliestQuoteDateYear"
        :index-period="indexPeriod"
        :is-updating="isUpdating"
        @update:graph-limits="updateGraphLimits"
        @update:range="updateCurrentRange"
        @update:index-view="setIndexView"
        @update:index-month-id="updateIndexMonthId"
        @update:index-year="updateIndexYear"
        @change-index-date="changeIndexDate"
      />
      <div
        v-if="showKpis"
        class="card chart--legend-with-kpis"
      >
        <ChartLegendWithKpis
          :entries="notationsLeftAxis"
          :axis-description="$t('apps.multi_line_chart_view.legend_y_axis_left')"
          :show-index-view="showIndexView"
          :show-absolute-prices="forInternalUse"
          @set-line-selected="setLineSelected"
        />
        <ChartLegendWithKpis
          :entries="notationsRightAxis"
          :axis-description="$t('apps.multi_line_chart_view.legend_y_axis_right')"
          :show-index-view="showIndexView"
          :show-absolute-prices="forInternalUse"
          @set-line-selected="setLineSelected"
        />
      </div>
      <div
        v-else
        class="card chart--legend"
      >
        <ChartLegend
          :entries="notationsLeftAxis"
          :axis-description="$t('apps.multi_line_chart_view.legend_y_axis_left')"
          :show-index-view="showIndexView"
          @set-line-selected="setLineSelected"
        />
        <ChartLegend
          :entries="notationsRightAxis"
          :axis-description="$t('apps.multi_line_chart_view.legend_y_axis_right')"
          :show-index-view="showIndexView"
          @set-line-selected="setLineSelected"
        />
      </div>
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import { computed } from 'vue';
import UrlStateStore from '@/services/UrlStateStore';
import {
  ChartViewHeader,
  PrintLayout,
} from '@/components';
import Turbolinks from 'turbolinks';
import { useChartViewStore } from './store';
import ChartView from './ChartView/Component.vue';
import ChartLegend from './ChartLegend/Component.vue';
import ChartLegendWithKpis from './ChartLegendWithKpis/Component.vue';

export default {
  name: 'MultiLineChartViewApp',
  components: {
    SplitPageLayout,
    ChartViewHeader,
    ChartView,
    ChartLegend,
    PrintLayout,
    ChartLegendWithKpis,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    forInternalUse: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useChartViewStore();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    Turbolinks.controller
      .replaceHistoryWithLocationAndRestorationIdentifier('?', Turbolinks.uuid());
    let savedAnalysisId = null;
    if (urlParams.get('saved_analysis_id') !== null) {
      savedAnalysisId = parseInt(urlParams.get('saved_analysis_id'), 10);
    } else {
      savedAnalysisId = UrlStateStore.get('savedAnalysisId', null);
    }
    UrlStateStore.set('savedAnalysisId', savedAnalysisId);

    const initialConfig = UrlStateStore.get('config', null);
    const showKpis = UrlStateStore.get('showKpis', false);
    const index = UrlStateStore.get('index', {
      monthId: null,
      year: null,
    });

    store.setConfiguration(initialConfig, savedAnalysisId, index, showKpis);

    const updateGraphLimits = (graphLimits) => {
      store.setGraphLimits(graphLimits);
    };
    const updateCurrentRange = (range) => {
      store.setCurrentRange(range);
    };
    const setLineSelected = (selectedLine) => {
      store.setLineSelected(selectedLine);
    };
    const saveConfiguration = (title) => {
      store.saveConfiguration(title);
    };

    const toggleShowKpis = (updatedShowKpis) => {
      store.toggleShowKpis(updatedShowKpis);
    };

    const setIndexView = (showIndexView) => {
      store.setIndexView(showIndexView);
    };

    const updateIndexMonthId = (indexMonthId) => {
      store.updateIndexMonthId(indexMonthId);
    };

    const updateIndexYear = (indexYear) => {
      store.updateIndexYear(indexYear);
    };

    const changeIndexDate = (indexDate) => {
      store.changeIndexDate(indexDate);
    };

    return {
      title: computed(() => store.title),
      datasets: computed(() => store.datasets),
      graphLimits: computed(() => store.selectedGraphLimits),
      primaryNotationUnit: computed(() => store.primaryNotationUnit),
      secondaryNotationUnit: computed(() => store.secondaryNotationUnit),
      notationsRightAxis: computed(() => store.notationsRightAxis),
      notationsLeftAxis: computed(() => store.notationsLeftAxis),
      isLoading: computed(() => store.isLoading),
      currentRange: computed(() => store.currentRange),
      hasError: computed(() => store.hasError),
      config: computed(() => store.config),
      selectedEarliestQuoteDate: computed(() => store.selectedEarliestQuoteDate),
      savedAnalysisId: computed(() => store.savedAnalysisId),
      saveConfigDisabled: computed(() => store.saveConfigDisabled),
      isSaving: computed(() => store.isSaving),
      configUrl: computed(() => store.configUrl),
      showKpis: computed(() => store.showKpis),
      showIndexView: computed(() => store.showIndexView),
      indexPeriod: computed(() => store.indexPeriod),
      isUpdating: computed(() => store.isUpdating),
      earliestQuoteDateYear: computed(() => store.earliestQuoteDateYear),
      index: computed(() => store.index),
      updateGraphLimits,
      updateCurrentRange,
      setLineSelected,
      saveConfiguration,
      toggleShowKpis,
      setIndexView,
      updateIndexMonthId,
      updateIndexYear,
      changeIndexDate,
    };
  },
  watch: {
    savedAnalysisId: {
      deep: true,
      handler() {
        UrlStateStore.set('savedAnalysisId', this.savedAnalysisId);
      },
    },
    showKpis: {
      deep: true,
      handler() {
        UrlStateStore.set('showKpis', this.showKpis);
      },
    },
    index: {
      deep: true,
      handler() {
        UrlStateStore.set('index', this.index);
      },
    },
  },
  provide() {
    return {
      user: this.user,
    };
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>