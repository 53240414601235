<template>
  <thead
    class="data-table--head"
    :class="{'data-table--sticky-margin': stickyMarginZeroTop}"
  >
    <transition-group
      name="data-table-column-animation"
      tag="tr"
      class="data-table--head-row"
    >
      <GenericColumnHead
        v-for="(column, index) in model.columns.filter((c) => c.visible)"
        :key="column.key"
        :class="{
          'has-popup-right-aligned': hasPopUpRightAligned(column, index),
          'is-top-aligned': stickyMarginZeroTop,
          'border-top': stickyMarginZeroTop,
          [column.classes]: column.classes,
        }"
        :filter-options="column.filterOptions"
        :column-key="column.key"
        :display-name="column.displayName"
        :tooltip="column.tooltip"
        :filter-popup-is-wide="column.filterPopupIsWide"
        :controller="controller"
        @update="{showColumns}"
      />
    </transition-group>

    <slot name="sticky-header"></slot>
  </thead>
</template>

<script>
import GenericColumnHead from './ColumnHeads/GenericColumnHead.vue';
export default {
  name: 'TableHead',
  components: {
    GenericColumnHead,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    stickyMarginZeroTop: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    hasPopUpRightAligned(column, index) {
      const visibleColumnsCount = this.model.visibleColumns.length;
      return index >= visibleColumnsCount - 3;
    },
  },
};
</script>
