<template>
  <div class="item">
    <div class="item--icon-wrapper">
      <img
        class="item--icon"
        :src="iconSource"
      >
      <div
        v-if="item.unread"
        class="item--icon-unread-marker"
        role="status"
        :aria-label="$t('generic.unread')"
      />
    </div>
    <div class="item--header">
      <div class="item--title">
        <button
          class="item--title-button"
          @click="showPublication"
        >
          {{ item.display_name }}
        </button>
      </div>
      <p class="item--date">
        {{ publishedAt }}
      </p>
    </div>
  </div>
</template>

<script>
import { ViewPublication, IconForKind, FormatPublicationDate } from '@/helpers/PublicationHelpers';

export default {
  name: 'WelcomeBannerPublicationItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    publishedAt() {
      return FormatPublicationDate(this.item.published_at);
    },
    iconSource() {
      return IconForKind(this.item.kind);
    },
  },
  methods: {
    showPublication() {
      ViewPublication(this.item);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>