<template>
  <thead
    class="data-table--head"
    :class="{'data-table--sticky-margin': stickyMarginZeroTop}"
  >
    <GenericColumnHead
      v-for="(column, index) in columns.filter((c) => c.visible)"
      :key="column.key"
      :class="classForColumn(column, index)"
      :column-key="column.key"
      :display-name="column.displayName"
      :filter-options="column.filterOptions"
      :tooltip="column.tooltip"
      :controller="controller"
    />
  </thead>
</template>

<script>
import GenericColumnHead from '@/components/NotationList/NotationTable/ColumnHeads/GenericColumnHead.vue';

export default {
  name: 'HeadComponent',
  components: {
    GenericColumnHead,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    controller: {
      type: Object,
      required: true,
    },
    stickyMarginZeroTop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    classForColumn(column, index) {
      return index >= this.columns.length - 3
        ? { 'has-popup-right-aligned': true }
        : { };
    },
  },
};
</script>
