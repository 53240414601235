<template>
  <div class="search-header">
    <h3 class="search-header--caption">
      <Icon
        v-if="controller.isLoading"
        class="search-header--is-loading"
        name="spinner"
        :spin="true"
      />
      {{ caption }}
    </h3>
    <ul class="search-header--action-list">
      <li
        v-if="controller.checkedNotations.length > 0"
        class="search-header--action-list-item"
      >
        <ButtonWithDropdown
          variant="primary"
          icon="caret-down"
          :is-disabled="controller.isLoading"
        >
          {{ $t('components.search_header.batch_actions.button') }}

          <template #dropdown>
            <DropdownMenu>
              <AddNotationDoDashboardDropdownButton :controller="controller" />
              <MultiLineChartAnalysisDropdownButton
                :notation-ids="controller.checkedNotations.map((notation) => notation.id)"
              />
              <SeasonLineChartAnalysisDropdownButton
                :notation-ids="controller.checkedNotations.map((notation) => notation.id)"
              />
            </DropdownMenu>
          </template>
        </ButtonWithDropdown>
      </li>
      <li class="search-header--action-list-item">
        <ButtonComponent
          variant="primary"
          icon="columns"
          :is-disabled="controller.isLoading"
          @click="controller.toggleDetailColumns()"
        >
          {{
            controller.showDetailColumns
              ? $t('components.search_header.hide_details')
              : $t('components.search_header.show_details')
          }}
        </ButtonComponent>
      </li>
      <li class="search-header--action-list-item">
        <Teaser class="humanrightsrisks-export-button" scope="export">
          <ButtonWithDropdown
            variant="primary"
            icon="caret-down"
            :is-disabled="controller.isPdfExportButtonDisabled"
          >
            {{ $t('components.generic.pdf_export_button.dropdown_label') }}
            <template #dropdown>
              <DropdownMenu>
                <DropdownMenuButton
                  icon="chart-line"
                  icon-variant="light"
                  :disabled="isDownloading"
                  :closes-popup="false"
                  @click="onPdfExportClick(true)"
                >
                  {{ exportInternalButton }}
                </DropdownMenuButton>
                <DropdownMenuButton
                  icon="chart-line"
                  icon-variant="light"
                  :disabled="isDownloading"
                  :closes-popup="false"
                  @click="onPdfExportClick(false)"
                >
                  {{ exportExternalButton }}
                </DropdownMenuButton>
                <DropdownMenuButton
                    icon="table"
                    icon-variant="light"
                    :disabled="isDownloading || !controller.config.canExportAllToExcel"
                    :closes-popup="false"
                    @click="onExcelExportClick()"
                >
                  {{ $t('components.generic.excel_export_button.table_export') }}
                </DropdownMenuButton>
              </DropdownMenu>
            </template>
          </ButtonWithDropdown>
        </Teaser>
      </li>
      <DatePickerButton
        :comparison-date="controller.comparisonDate"
        :is-disabled="controller.isLoading"
        @update:comparisonDate="onDateSelect"
      />
      <li
        v-if="controller.showDefaultFilterActions"
        class="search-header--action-list-item"
      >
        <ButtonWithDropdown
          variant="primary"
          icon="caret-down"
          :is-disabled="controller.isLoading"
          dropdown-alignment="right"
        >
          {{ $t('components.search_header.filter_dropdown') }}
          <template #dropdown>
            <FilterActionDropdown :controller="controller" />
          </template>
        </ButtonWithDropdown>
      </li>
      <li
        v-else
        class="search-header--action-list-item"
      >
        <ButtonComponent
          variant="primary"
          icon="times"
          :is-disabled="!controller.filtersActive || controller.isLoading"
          @click="controller.clearFilters()"
        >
          {{ $t('components.search_header.clear_filters') }}
        </ButtonComponent>
      </li>

      <li
        v-if="controller.showWatchListSubscriptionButton"
        class="search-header--action-list-item"
      >
        <MailExportButtonComponent
          mail-export-type="WatchListSubscription"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {
  AddNotationDoDashboardDropdownButton,
  MultiLineChartAnalysisDropdownButton,
  SeasonLineChartAnalysisDropdownButton,
  DatePickerButton,
} from '@/components';
import FilterActionDropdown from '@/components/FilterActionDropdown.vue';
import MailExportButtonComponent from '@/components/MailExportButton/Component.vue';
import PdfExportController from '@/components/generic/PdfExportButton/Controller';

export default {
  name: 'NotationListHeader',
  components: {
    DatePickerButton,
    AddNotationDoDashboardDropdownButton,
    FilterActionDropdown,
    MailExportButtonComponent,
    MultiLineChartAnalysisDropdownButton,
    SeasonLineChartAnalysisDropdownButton,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    controller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExportingExcel: false,
    }
  },
  computed: {
    isDownloading() {
      return PdfExportController.inProgress || this.isExportingExcel;
    },
    exportInternalButton() {
      return this.isDownloading ? this.$t('components.generic.pdf_export_button.in_progress_label') : this.$t('components.generic.pdf_export_button.for_internal_use');
    },
    exportExternalButton() {
      return this.isDownloading ? this.$t('components.generic.pdf_export_button.in_progress_label') : this.$t('components.generic.pdf_export_button.for_external_use');
    },
  },
  methods: {
    onDateSelect(date) {
      // eslint-disable-next-line vue/no-mutating-props
      this.controller.comparisonDate = date;
    },
    onPdfExportClick(forInternalUse = true) {
      const path = forInternalUse ? '' : `${window.location.pathname}?${this.forExternalPdfExport()}`;
      PdfExportController.exportAsPdf(path);
    },
    forExternalPdfExport() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('for_internal_use', '0');
      return searchParams.toString();
    },
    onExcelExportClick() {
      this.isExportingExcel = true;
      this.controller.exportAllToExcel(() => this.isExportingExcel = false)
    },
  },
};
</script>
