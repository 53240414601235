<template>
  <ul class="page-header--action-list">
    <template v-if="controller.canEdit">
      <li class="page-header--action-item">
        <ButtonComponent
          icon="file-upload"
          @click="uploadClicked"
        >
          {{ $t('apps.publications.header_actions.upload_files') }}
        </ButtonComponent>
      </li>
    </template>
  </ul>
</template>

<script>
import ModalWindowController from '@/controllers/ModalWindowController';

export default {
  name: 'PublicationsHeaderActions',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  methods: {
    uploadClicked() {
      ModalWindowController.show('PublicationsUploadModal', {
        events: {
          close: () => {
            this.controller.loadFilterOptions();
            this.controller.loadPublications();
          },
        },
      });
    },
  },
};
</script>
