import { NotNullOrUndefined } from '@/helpers';

export const filterProperties = (obj, predicate) => Object.fromEntries(
  Object.entries(obj).filter(
    ([key, value]) => predicate({ key, value }),
  ),
);

export const filterEmptyProperties = (obj) => filterProperties(obj, ({ value }) => (
  NotNullOrUndefined(value)));
