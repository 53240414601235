import { reactive } from 'vue';
import { compareTableValues, SORT_ASC, SORT_DESC } from '../../../helpers/SortHelpers';
import CountryAppApi from "../../CountryRiskMap/CountryAppApi";

class CountryRiskMapTableController {
  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filters() {
    return this._state.filters;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    return this._state.selection;
  }

  get notations() {
    return this._state.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  set collection(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    return this._state.notationListModel;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  get rawCollection() {
    return this._countries
  }

  constructor(countries, translator) {
    this._translator = translator;
    this._countries = this._getMappedCountries(countries);
    this._columns = this._getColumnDefinition(this._countries[0].hr_scores);
    this._api = new CountryAppApi();

    this._state = reactive({
      isLoading: false,
      filters: {},
      sorting: null,
      selection: {
        selectionMode: 'none',
        canSelect: false,
      },
      notations: this._countries,
      notationListModel: {
        columns: this._columns,
        visibleColumns: this._columns,
        createRow: (notation) => this._columns.map(({ key, type, ...column }) => ({
          key,
          type,
          filterOptions: column.filterOptions,
          displayName: column.displayName,
          visible: true,
          value: column.valueBuilder ? column.valueBuilder(notation, key) : notation[key],
          classes: {},
          additionalData: {},
        })),
      },
    });

    this.applyFilterOptions(this._columns, this._state.filters)
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation() {}

  /**
   * Remaps country response structure
   *
   * @param countries { id, value: { name, iso_code, dimensions: {...} } }
   * @returns { name, iso_code, dimension-*: { ... }}
   * @private
   */
  _getMappedCountries(countries) {
    const countryValues = countries.map((c) => c.value);
    return countryValues.map((c) => c.hr_scores.reduce((acc, curr) => {
      acc[`dimension-${curr.key}`] = curr;
      return acc;
    }, c));
  }

  _getColumnDefinition(_dimensions) {
    const [total, ...dimensions] = _dimensions;
    return [
      {
        key: 'iso_code',
        visible: true,
        displayName: this._translator('generic.country'),
        classes: 'data-table--head-cell-min-width',
        type: 'text',
        filterOptions: 'searchFilter',
        filterable: true,
        valueBuilder: (notation) => `${notation.iso_code} | ${notation.name}`,
      },
      {
        key: 'dimension-0',
        visible: true,
        displayName: total.name,
        classes: 'data-table--head-cell-title-wrapper',
        filterOptions: 'sort',
        type: 'totalHrScoresColumn',
        valueBuilder: (notation, key) => ({ dimensions: notation.hr_scores, total: notation[key], withFlagIcon: true, withTooltip: true }),
      },
      ...dimensions.map((d) => ({
        key: `dimension-${d.key}`,
        visible: true,
        filterOptions: 'sort',
        displayName: d.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'hrScoresColumn',
      })),
    ];
  }

  updateNotationsAndUserState() {
    if (this.sorting) {
      this.sortColumn();
    } else {
      this.notations = [...this._countries];
    }
  }

  sortColumn() {
    const { direction, column: columnKey } = this.sorting;
    const notationValueAccessor = (notation) => {
      let value;
      switch (columnKey) {
        case 'iso_code': value = notation.name;
          break;
        default: value = +notation[columnKey].value;
          break;
      }

      return value || 0;
    };

    this.notations = compareTableValues(this.notations, notationValueAccessor, direction);
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;

    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: SORT_ASC };
      return;
    }
    if (this._state?.sorting?.direction === SORT_ASC) {
      this._state.sorting.direction = SORT_DESC;
    } else {
      this._state.sorting = null;
    }
  }

  async _getExcelFile() {
    await this._api.getExcelFile();
  }
}

import FilterableControllerMixin from "@/utils/FilterableControllerMixin"
FilterableControllerMixin.apply(CountryRiskMapTableController)

export default CountryRiskMapTableController;
