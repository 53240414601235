<template>
  <div class="summary">
    <PublicationsUploadModalSummaryForAction
      :controller="controller"
      action="create"
    />
    <PublicationsUploadModalSummaryForAction
      :controller="controller"
      action="replace"
    />
    <div
      v-if="controller.createStats.failed > 0 || controller.replaceStats.failed > 0"
      class="summary--line for-failures"
    >
      <Icon
        class="summary--icon"
        name="times"
        :fixed-width="true"
      />
      {{
        $t('apps.publications.upload_modal.summary.failures',
            controller.createStats.failed + controller.replaceStats.failed)
      }}
    </div>
  </div>
</template>

<script>
import PublicationsUploadModalSummaryForAction from './ForAction/Component.vue';

export default {
  name: 'PublicationsUploadModalSummary',
  components: {
    PublicationsUploadModalSummaryForAction,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>