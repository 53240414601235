import UrlStateStore from '@/services/UrlStateStore';
import ModalWindowController from '@/controllers/ModalWindowController';
import FileSaver from 'file-saver';
import ApiHelpers from '@/api/ApiHelpers';
import axios from 'axios';
import IconHgsPublication from '@/apps/Publications/images/icon-hgs-publication.svg';
import IconMiscPublication from '@/apps/Publications/images/icon-misc-publication.svg';
import moment from 'moment';
import { EventBus, MarkPublicationAsReadEvent } from '@/events';

export const DefaultPublicationsState = {
  filters: {
    productGroups: [],
    regions: [],
    kind: 'any',
    sort: 'published_at_desc',
  },
};

export const DefaultNewsState = {
  filters: {
    selectedProductGroups: [],
    selectedRegions: [],
    selectedSortDirection: 'published_at_desc',
  },
};

export const NewsPaginationConfig = {
  url: '/api/news',
  method: 'get',
  errorHandler: ApiHelpers.handleError,
};

export const PathToPublicationsWithFilters = (filters) => {
  const state = {
    ...DefaultPublicationsState,
    filters: { ...DefaultPublicationsState.filters, ...filters },
  };
  return `/publications${UrlStateStore.queryStringForState(state)}`;
};

export const PathToNewsWithFilters = (filters) => {
  const state = {
    ...DefaultNewsState,
    filters: { ...DefaultNewsState.filters, ...filters },
  };
  return `/news${UrlStateStore.queryStringForState(state)}`;
};

const useGenericUnreadCount = (unreadCountRef, filter) => {
  const observer = EventBus.on(MarkPublicationAsReadEvent.EventName, (event) => {
    if (!filter(event.publication)) return;
    unreadCountRef.value -= 1;
  });
  return observer;
};

const isNews = (publication) => publication.kind === 'news';

export const useNewsUnreadCount = (unreadCountRef) => (
  useGenericUnreadCount(unreadCountRef, isNews)
);

export const usePublicationUnreadCount = (unreadCountRef) => (
  useGenericUnreadCount(unreadCountRef, (p) => !isNews(p))
);

export const MarkAsRead = async (publication) => {
  if (!publication.unread) return;

  await axios.post(`/api/publications/${publication.id}/mark_as_read`);
  publication.unread = false;

  EventBus.emit(new MarkPublicationAsReadEvent({
    publication,
  }));
};

const MarkAllAsRead = (url) => async (filters) => {
  const response = await axios.post(url, filters);
  const publicationsMarkedAsRead = response.data;
  publicationsMarkedAsRead.forEach((publication) => {
    EventBus.emit(new MarkPublicationAsReadEvent({
      publication,
    }));
  });
};

export const MarkAllPublicationsAsRead = MarkAllAsRead('/api/publications/mark_all_as_read');
export const MarkAllNewsAsRead = MarkAllAsRead('/api/news/mark_all_as_read');

export const ViewNews = (publication) => {
  if (publication.kind !== 'news') return;
  MarkAsRead(publication);
  ModalWindowController.show('NewsModal', {
    props: {
      publication,
    },
    events: {
      close: () => { ModalWindowController.close(); },
    },
  });
};

export const ViewPublication = (publication) => {
  ModalWindowController.show('PublicationModal', {
    props: {
      publication,
    },
    events: {
      close: () => { ModalWindowController.close(); },
    },
  });
};

export const FormatPublicationDate = (date) => (date
  ? moment(date).format('ll')
  : null);

export const DownloadPublication = async (publication) => {
  if (!publication.has_attachment) return;
  MarkAsRead(publication);
  try {
    const url = `/api/publications/${publication.id}/download`;
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    const filename = decodeURIComponent(response.headers['content-disposition'].match(/filename\*=UTF-8''(.*)$/)[1]);
    FileSaver.saveAs(response.data, filename);
  } catch (error) {
    ApiHelpers.handleError(error);
  }
};

export const IconForKind = (kind) => {
  if (kind === 'other') return IconMiscPublication;
  return IconHgsPublication;
};
