<template>
  <DashboardPageLayout>
    <template #top>
      <p
        class="for-print"
      >
        {{ $t('generic.for_internal_use_only') }}
      </p>
      <WelcomeBannerComponent v-exclude-from-print />
      <PrintLayout>
        <template #header v-if="controller.dashboardType !== 'human_rights_risks'">
          <IntroPrintHeader :title="printTitle" />
        </template>
        <template #footer v-if="controller.dashboardType !== 'human_rights_risks'">
          <IntroPrintFooter :title="printTitle" />
        </template>
      </PrintLayout>

      <ViewHeaderComponent
        v-model:dashboardType="controller.dashboardType"
        v-exclude-from-print
        :show-list-view="controller.showListView"
        :show-details="controller.showDetailColumns"
        :is-loading="controller.isLoading"
        :has-data="controller.dashboards.length > 0"
        :user="user"
        @toggle-list-view="onToggleListView"
        @toggle-details="onToggleDetails"
      />
    </template>
    <template #bottom>
      <HumanRightsRisks
        v-if="controller.dashboardType === 'human_rights_risks' && hasHumanRightsSubscription"
        :controller="humanRisksController"
        :countries="countriesForSelect"
        :dimensions="hrDimensionsForSelect"
        :years-for-select="yearsForSelect"
        :years-default-value="yearsDefaultValue"
      />
      <ListViewComponent
        v-else-if="controller.showListView"
        :controller="controller"
      />
      <TileViewComponent
        v-else
        :controller="controller"
      />

      <FavouriteCreationHintComponent
        v-if="controller.showFavouriteCreationHint"
      />
    </template>
  </DashboardPageLayout>
</template>

<script>
import { DashboardPageLayout } from '@/components/Layouts';
import { PrintLayout } from '@/components';
import {
  WelcomeBannerComponent,
  ViewHeaderComponent,
  ListViewComponent,
  TileViewComponent,
  FavouriteCreationHintComponent,
  IntroPrintHeader,
  IntroPrintFooter,
  HumanRightsRisks,
} from './components';
import IntroAppController from './IntroAppController';
import HumanRightsRisksController from './HumanRightsRisksController';

export default {
  name: 'IntroApp',
  components: {
    DashboardPageLayout,
    PrintLayout,
    WelcomeBannerComponent,
    ViewHeaderComponent,
    ListViewComponent,
    TileViewComponent,
    FavouriteCreationHintComponent,
    IntroPrintHeader,
    IntroPrintFooter,
    HumanRightsRisks,
  },
  provide() {
    return {
      user: this.user,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    favoriteDashboardCount: {
      type: Number,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    countriesForSelect: {
      type: Array,
      default: () => [],
    },
    hrDimensionsForSelect: {
      type: Array,
      default: () => [],
    },
    yearsForSelect: {
      type: Array,
      default: () => [],
    },
    yearsDefaultValue: {
      type: Number,
    },
    commodityRiskMapPath: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      controller: new IntroAppController({
        state: this.initialState,
        print: this.$media === 'print',
        favoriteDashboardCount: this.favoriteDashboardCount,
      }),
      humanRisksController: new HumanRightsRisksController(
        this.countriesForSelect,
        this.hrDimensionsForSelect,
        this.yearsForSelect,
        this.$t,
        this.yearsForSelect.find((y) => y.value === this.yearsDefaultValue),
        this.commodityRiskMapPath,
      ),
    };
  },
  computed: {
    printTitleForFavorites() {
      return this.$t('apps.intro.print_title_for_favorites', { name: this.user.display_name });
    },
    printTitleForIndicators() {
      return this.$t('apps.intro.print_title_for_indicators', { name: this.user.display_name });
    },
    printTitleForHumanRightsRisks() {
      return this.$t('apps.intro.print_title_for_human_rights_risks', { name: this.user.display_name });
    },
    printTitle() {
      if (this.controller.showingFavorites) {
        return this.printTitleForFavorites;
      } else if (this.controller.showingIndicators) {
        return this.printTitleForIndicators;
      } else {
        return this.printTitleForHumanRightsRisks;
      }
    },
    hasHumanRightsSubscription() {
      return this.user.has_human_rights_subscription;
    },
  },
  beforeUnmount() {
    this.controller.destroy();
  },
  methods: {
    onToggleListView(showListView) {
      this.controller.showListView = showListView;
    },
    onToggleDetails(showDetails) {
      this.controller.showDetailColumns = showDetails;
    },
  },
};
</script>
