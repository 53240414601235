<template>
  <form
    action="/locale"
    method="POST"
  >
    <button
      class="app-header--user-link"
      name="locale"
      :value="nextLocale"
    >
      <Icon
        class="icon"
        name="globe"
        :fixed-width="true"
        variant="regular"
      />
      {{ $t('layouts.change_locale_button.change_locale') }}
    </button>
    <input
      type="hidden"
      :value="csrfToken"
      name="authenticity_token"
    >
  </form>
</template>

<script>
import { GetCSRFToken } from '@/helpers/FormHelpers';
import i18n from '@/config/i18n';

export default {
  name: 'NavigationUserMenuChangeLocaleButton',
  computed: {
    csrfToken() {
      return GetCSRFToken();
    },
    nextLocale() {
      return i18n.locale === 'en' ? 'de' : 'en';
    },
  },
};
</script>
