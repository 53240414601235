import yellowFlag from '@/../assets/images/topFiveCountry/yellowFlag.svg';
import redFlag from '@/../assets/images/topFiveCountry/redFlag.svg';

const FLAGS = {
  red: redFlag,
  yellow: yellowFlag,
};

const COLORS = {
  black: '#000000',
  blue: 'rgb(0, 136, 204)',
  white: {
    default: '#ffffff',
    transparent: 'rgba(255, 255, 255, 0.8)',
  },
};
const redFlagImage = new Image();
redFlagImage.src = FLAGS.red;

const yellowFlagImage = new Image();
yellowFlagImage.src = FLAGS.yellow;

const flags = {
    red: redFlagImage,
    yellow: yellowFlagImage
}

const renderText = (chart, element, index) => {
  const context = chart.chart.ctx;
  const { x, y } = chart.getDatasetMeta(0).data[index]._model;
  const { r, label, flag, isCountryOfDemand } = element;
  const padding = 11;

  const labelTextWidth = context.measureText(label).width;

  const imageSize = flag !== null ? 10 : 0;
  const labelContentWidth = labelTextWidth + imageSize + padding;

  // Draw Box
  context.beginPath();
  context.fillStyle = isCountryOfDemand ? COLORS.blue : COLORS.white.transparent;
  context.roundRect(x - labelContentWidth / 2, y + r + 5, labelContentWidth, 20, 2);
  context.fill();
  if (isCountryOfDemand) {
    context.strokeStyle = COLORS.white.default;
  }
  context.stroke();

  // Draw text
  context.fillStyle = isCountryOfDemand ? COLORS.white.default : COLORS.black;
  context.font = 'normal 10px sans-serif';
  context.fillText(label, x - labelContentWidth / 2 + 5, y + r + 18);

  // Draw flag (optional)
  if (flag !== null) {
    context.drawImage(flags[flag], x + labelContentWidth / 2 - imageSize - 3, y + r + 10, imageSize, imageSize)
  }
};

export default {
  afterDatasetsDraw(chart) {
    const chartData = chart.config.data.datasets[0].data;
    if (chartData.value !== null) {
      chartData.forEach((element, index) => renderText(chart, element, index));
    }
  },
};
