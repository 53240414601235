const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    return timeout;
  };
};

export default debounce;
