import Anychart from 'anychart';
import { IMPORT_EXPORT_COLOR } from '../../config/colors';
import debounce from '../../helpers/debounce';

export const COUNTRY_COLOR_MAP = {
  A: '#016634',
  B: '#028D37',
  C: '#91C01E',
  D: '#FFEB00',
  E: '#F8B231',
  F: '#E85A0A',
  G: '#BC1623',
  disabled: '#BCBCBC80',
  countryOfDemand: '#0088CC',
};

const COUNTRY_BORDER_COLOR = '#666';

export const MAP_MOVE_LEFT = 'left';
export const MAP_MOVE_RIGHT = 'right';
export const MAP_MOVE_TOP = 'top';
export const MAP_MOVE_BOTTOM = 'bottom';

const moveDirectionsMap = {
  [MAP_MOVE_LEFT]: [50, 0],
  [MAP_MOVE_RIGHT]: [-50, 0],
  [MAP_MOVE_TOP]: [0, 50],
  [MAP_MOVE_BOTTOM]: [0, -50],
};

export class MapService {
  constructor() {
    this.chart = Anychart;
    this._map = this.chart.map();
    this.dataSet = {};
    this.moveInterval = null;
    this.mapContainer = null;

    this._initGeoData();
  }

  async _initGeoData() {
    const countries = await fetch('/countries.json');
    const parsedCountries = await countries.json();
    window.anychart.maps = { world: parsedCountries };
    document.dispatchEvent(new CustomEvent('geodata-loaded'));
  }

  moveMap(direction) {
    this.moveInterval = setInterval(() => {
      this._map.move(...moveDirectionsMap[direction]);
    }, 30);
  }

  stopMoveMap() {
    clearInterval(this.moveInterval);
    this.moveInterval = null;
  }

  zoom(value) {
    this._map.zoomTo(value);
  }

  generateCustomTooltip({
    generateTooltipTitle = () => {
    }, generateTooltipBody,
  }) {
    this.series.tooltip().useHtml(true);
    this.series.tooltip().separator().enabled(false);
    this.series.tooltip().titleFormat(generateTooltipTitle);

    this.series.tooltip().format(generateTooltipBody);
  }

  updateMapItemColorsByDimension(selectedDimensionKey) {
    const updatedMapData = this.dataSet.data().map((country) => {
      let countryGrade;
      if (country.value.isCountryOfDemand) {
        countryGrade = 'countryOfDemand';
      } else {
        countryGrade = country.value.hr_scores.find((hrScore) => hrScore.key === selectedDimensionKey)?.grade || 'disabled';
      }

      return {
        ...country,
        fill: COUNTRY_COLOR_MAP[countryGrade],
      };
    });

    this.updateDataSet(updatedMapData);
  }

  moveBtnMouseUpDocumentHandler() {
    document.addEventListener('mouseup', () => {
      if (this.moveInterval) {
        this.stopMoveMap();
      }
    });
  }

  mouseWheelZoomChangeAddListener(zoomLevelUpdateHandler) {
    this.mapContainer.addEventListener('wheel', debounce(() => {
      const roundedZoom = +(this._map.getZoomLevel().toFixed(3));
      zoomLevelUpdateHandler(roundedZoom);
    }, 100));
  }

  destroy() {
    this.mapContainer.removeEventListener('mousewheel', () => {
    });
    document.removeEventListener('mouseup', () => {
    });
  }

  updateDataSet(dataSet) {
    this.dataSet.data(dataSet);
  }

  humanRightsTooltipTemplate({
    value,
    total,
    flagIcon,
    dimensions,
    importExportValues,
    translator,
    tradeFlowPercent,
    isSelectedCountryWorld,
    selectedCountry,
  }) {
    const percentageTradeflowKey = 'components.human_rights_risk_tooltip.percantage_tradeflow';
    const balanceKey = 'apps.intro.human_rights_risks.table_column.balance';

    const totalItem = `
      <div class="hr-dimensions__row hr-dimensions__list-item">
        <div class="hr-dimensions__name">
          <span>${total.name}</span>
        </div>
        <div class="hr-dimensions__score">
          <img
            class="hr-dimensions__score-icon"
            src="${total.icon}"
          >
          <span class="hr-dimensions__score-body">${total.value}</span>
          ${flagIcon ? `<img
                class="hr-dimensions__score-flag"
                src="${flagIcon}">` : ''}
        </div>
      </div>
    `;

    let importExportInfo = ""
    if (importExportValues?.export) {
      importExportInfo = `
        <div class="hr-dimensions__row hr-dimensions__info-item">
          <span>&middot; ${ translator("components.trade_flows.import_volume") }</span>
          <span>
            ${importExportValues.import.formatted}
          </span>
        </div>

        <div class="hr-dimensions__row hr-dimensions__info-item">
          <span>&middot; ${ translator("components.trade_flows.export_volume") }</span>
          <span>
            ${importExportValues.export.formatted}
          </span>
        </div>
      `
    }

    const dimensionItems = dimensions.map((score) => `
        <div
          class="hr-dimensions__row hr-dimensions__list-item ${score.grade === 'low' ? 'hr-dimensions__list-item--danger' : ''}"
        >
          <div class="hr-dimensions__name">
            <span>${score.name}</span>
          </div>
          <div class="hr-dimensions__score">
            <img
              src="${score.icon}"
            >
            <span class="hr-dimensions__score-body">${score.value}</span>
          </div>
        </div>`).join('');

    return `
        <div class="hr-dimensions">
          <div class="hr-dimensions__info">
            <div class="hr-dimensions__row hr-dimensions__info-item">
              <strong>${translator('components.human_rights_risk_tooltip.headline')}</strong>
            </div>
            <div class="hr-dimensions__row hr-dimensions__info-item">
              <strong>${value.iso_code} | ${value.name}</strong>
              ${value.isCountryOfDemand && value.balance
    ? `<span style="color: ${IMPORT_EXPORT_COLOR[value.balance]}">
                    ${translator(`${balanceKey}.${value.balance}`)}
                </span>`
    : ''}
            </div>

            ${importExportInfo}

            ${tradeFlowPercent ? `
              <div class="hr-dimensions__row hr-dimensions__info-item">
            <span>${translator(isSelectedCountryWorld
    ? `${percentageTradeflowKey}.export`
    : `${percentageTradeflowKey}.import`, { selectedCountry })}</span>
                <span>${tradeFlowPercent} %</span>
              </div>
            ` : ''}
          </div>
          <div class="hr-dimensions__list">
            ${totalItem}
            <hr>
            ${dimensionItems}
          </div>
        </div>
    `;
  }

  init(mapData) {
    this.dataSet = this.chart.data.set(mapData.geoData);
    this.series = this._map.choropleth(this.dataSet);
    this.series.geoIdField(mapData.idFieldValue);
    this.series.stroke(COUNTRY_BORDER_COLOR);
    this.mapContainer = mapData.container;

    if (mapData.withCustomTooltip) {
      this.generateCustomTooltip(mapData.customTooltipData);
    }

    this._map.interactivity().zoomOnMouseWheel(mapData.zoomOnWheel);

    this._map.geoData(this.chart.maps[mapData.mapName]);
    this._map.container(this.mapContainer).draw();

    if (mapData?.callback) {
      mapData.callback();
    }
  }
}
