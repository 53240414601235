import { reactive } from 'vue';
import TradeFlowsApi from '@/apps/DashboardTradeFlows/api/TradeFlowsApi';
import UrlStateStore from '@/services/UrlStateStore';
import TradeFlowDataProvider from '@/apps/DashboardTradeFlows/controllers/TradeFlowDataProvider';

class DashboardTradeFlowsForCountryController {
  constructor(dashboardId, classification, source, year, countries, worldData) {
    this._dashboardId = dashboardId;
    this._countries = countries;
    this._worldData = worldData;
    this._countryDataProvider = new TradeFlowDataProvider(dashboardId);
    this._reportingCountriesRequestId = null;
    this._state = reactive({
      isLoadingReportingCountries: true,
      reportingCountries: [],
      currentReporter: null,
      currentPartnerId: null,
      classification,
      year,
      source,
    });
    this._updateReportingCountries();

    const partnerId = UrlStateStore.get('partner');
    this._state.currentPartnerId = partnerId || this.defaultPartner?.id;
  }

  _updateCurrentReporter() {
    const noReporterSelected = !this.currentReporter;
    const selectedReporterExists = this.reportingCountries
      .find((country) => country.id === this.currentReporter?.id) !== null;
    if (noReporterSelected || !selectedReporterExists) {
      const reporterId = UrlStateStore.get('reporter');
      const defaultReporter = this.reportingCountries.find((country) => country.id === reporterId)
                          || this.defaultReporter;
      this.currentReporter = defaultReporter;
    } else {
      this._updateData();
    }
  }

  _updateData() {
    this._countryDataProvider.setApiParams({
      reporterId: this.currentReporterId,
      classificationId: this.classification?.id,
      source: this.source,
      year: this.year,
    });
  }

  _updateReportingCountries() {
    const requestId = new Date() + Math.random();
    this._reportingCountriesRequestId = requestId;
    this._state.isLoadingReportingCountries = true;
    this._state.reportingCountries = [];
    TradeFlowsApi().reportingCountries(this._dashboardId, {
      classificationId: this.classification?.id,
      source: this.source,
      year: this.year,
    }).then((response) => {
      if (!this._reportingCountriesRequestId === requestId) return;
      this._state.reportingCountries = response.data.countries || [];
    }).finally(() => {
      if (!this._reportingCountriesRequestId === requestId) return;
      this._updateCurrentReporter();
      this._state.isLoadingReportingCountries = false;
    });
  }

  get defaultPartner() {
    return this.countries.find((c) => c.name === 'China');
  }

  get defaultReporter() {
    return this.topExportReportingCountry || this.reportingCountries[0];
  }

  get topExportReportingCountry() {
    const globalSurplusCountries = (this._worldData?.balance || [])
      .filter((entry) => entry.value > 0);
    const surplusForCountry = (countryId) => globalSurplusCountries
      .find((entry) => entry.country_id === countryId)?.value;
    const countriesWithExports = this.reportingCountries.map((country) => ({
      ...country,
      export: surplusForCountry(country.id),
    })).filter((country) => country.export !== undefined);
    countriesWithExports.sort((a, b) => b.export - a.export);
    return countriesWithExports[0];
  }

  get countries() {
    return this._countries;
  }

  get isLoadingHeaderData() {
    return this._state.isLoadingReportingCountries;
  }

  get isLoading() {
    return this._state.isLoadingReportingCountries
        || this._countryDataProvider.isLoading;
  }

  get countryDataProvider() {
    return this._countryDataProvider;
  }

  get currentReporter() {
    return this._state.currentReporter;
  }

  get currentReporterId() {
    return this.currentReporter?.id;
  }

  set currentReporter(country) {
    this._state.currentReporter = country;
    this._updateData();
    UrlStateStore.set('reporter', country?.id || -1);
  }

  setCurrentReporterById(id) {
    const reporter = this.reportingCountries.find((c) => c.id === id);
    this.currentReporter = reporter;
  }

  get currentPartner() {
    return this.partnerCountries.find((c) => c.id === this._state.currentPartnerId);
  }

  get currentPartnerId() {
    return this.currentPartner?.id;
  }

  set currentPartner(country) {
    this._state.currentPartnerId = country?.id;
    UrlStateStore.set('partner', country?.id || -1);
  }

  setCurrentPartnerById(id) {
    this.currentPartner = this.partnerCountries.find((c) => c.id === id);
  }

  get additionalCountryNames() {
    return [this.currentPartner?.name].filter((name) => name !== null);
  }

  get reportingCountries() {
    return this._state.reportingCountries;
  }

  get partnerCountries() {
    if (!this.countryDataProvider.hasData) return [];
    const countriesWithData = [].concat(this.countryDataProvider.data.import,
      this.countryDataProvider.data.export,
      this.countryDataProvider.data.balance).map((c) => c?.country_id);
    return this.countries.filter((c) => countriesWithData.includes(c.id));
  }

  get classification() {
    return this._state.classification;
  }

  set classification(classification) {
    this._state.classification = classification;
    this._updateReportingCountries();
  }

  get source() {
    return this._state.source;
  }

  set source(source) {
    this._state.source = source;
    this._updateReportingCountries();
  }

  get year() {
    return this._state.year;
  }

  set year(year) {
    this._state.year = year;
    this._updateReportingCountries();
  }
}

export default DashboardTradeFlowsForCountryController;
