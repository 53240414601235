<template>
  <div
    class="item"
    :class="{'is-selected': isSelected}"
  >
    <ToggleIcon
      v-if="showFavorites"
      class="item--icon"
      :model-value="dashboard.is_favorite"
      :fixed-width="true"
      name="star"
      :aria-label="toggleFavoriteLabel"
      @update:modelValue="toggleFavorite"
    />
    <a
      v-if="useLinkTag"
      class="item--link"
      :href="dashboard.href"
      @click="onClick"
    >
      {{ dashboard.name }}
    </a>
    <button
      v-else
      class="item--link"
      @click="onClick"
    >
      {{ dashboard.name }}
    </button>
  </div>
</template>

<script>
import DashboardApi from '@/api/DashboardApi';
import EventBus from '@/events/EventBus';
import DashboardFavoriteChangeEvent from '@/events/DashboardFavoriteChangeEvent';

export default {
  name: 'DashboardAndClusterListLeafListItem',
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    showFavorites: {
      type: Boolean,
      default: false,
    },
    useLinkTag: {
      type: Boolean,
      default: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    dashboardApi() {
      return new DashboardApi(this.dashboard);
    },
    toggleFavoriteLabel() {
      return this.dashboard.is_favorite
        ? this.$t('components.dashboards.remove_from_favorites')
        : this.$t('components.dashboards.add_to_favorites');
    },
  },
  methods: {
    toggleFavorite(value) {
      const apiRoute = value === true
        ? this.dashboardApi.addDashboardToFavorites
        : this.dashboardApi.removeDashboardFromFavorites;
      return apiRoute().then(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.dashboard.is_favorite = value;
        EventBus.emit(new DashboardFavoriteChangeEvent({
          dashboardId: this.dashboard.id,
          isFavorite: value,
        }));
      });
    },
    onClick(event) {
      this.$emit('click', {
        dashboard: this.dashboard,
        nativeevent: event,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>