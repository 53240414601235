<template>
  <div class="print-header">
    <div
      v-if="$features.PUBLICATIONS"
      class="print-header--logo-container"
    >
      <LocalizedBannerLogo class="print-header--logo" />
      <IntroPrintTitle :title="title" />
    </div>
    <WelcomeBanner
      v-else
      class="print-header--banner"
    />
  </div>
</template>

<script>
import LocalizedBannerLogo from '../LocalizedBannerLogo/Component.vue';
import WelcomeBanner from '../WelcomeBanner/Component.vue';
import IntroPrintTitle from '../PrintTitle/Component.vue';

export default {
  name: 'IntroPrintHeader',
  components: {
    WelcomeBanner,
    LocalizedBannerLogo,
    IntroPrintTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>