<template>
  <div class="card-footer">
    <NotationCardFooter
      :selection="controller.selection"
      :primary-notation-details="primaryNotationDetails"
      :secondary-notation-details="secondaryNotationDetails"
      :selected-date="selectedDate"
      :selected-currency-id="selectedCurrencyId"
      :graph-limits="controller.graphLimits"
      :can-export-raw-data="user.can_export_raw_data"
      :whitelisted-sources="whitelistedSources"
      @clear-secondary-selection="controller.clearSecondarySelection()"
    />
    <div
      v-if="$features.CURRENCY_CONVERSION"
      class="card-footer--currency-container"
    >
      <label
        for="currency-conversion-box"
        class="card-footer--currency-conversion-label"
      >
        {{ $t('apps.dashboards.dashboard_card_footer.currency_conversion_label') }}
      </label>
      <CurrencyComboBox
        id="currency-conversion-box"
        :model-value="selectedCurrencyId"
        :preferred-dashboard-currency-id="preferredDashboardCurrencyId"
        @update:modelValue="$emit('update:selectedCurrencyId', $event)"
      />
      <ButtonComponent
        variant="primary"
        icon="thumbtack"
        class="card-footer--currency-save-button"
        :aria-label="savePreferredDashboardCurrencyLabel"
        :is-disabled="isPreferredDashboardCurrencyButtonDisabled"
        @click="onSavePreferredDashboardCurrencyClicked"
      />
      <ButtonComponent
        v-if="isPreferredGlobalCurrencyButtonDisplayed"
        variant="primary"
        icon="coins"
        class="card-footer--preferred-currency-button"
        @click="selectPreferredGlobalCurrency"
      >
        {{ preferredGlobalCurrency.symbol }}
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import {
  NotationCardFooter,
  CurrencyComboBox,

} from '@/components';
import ModalWindowController from '@/controllers/ModalWindowController';
import { SetPreferredDashboardCurrency } from '@/helpers/DashboardHelpers';
import UnitsApi from '@/api/UnitsApi';

export default {
  name: 'DashboardCardFooter',
  components: {
    NotationCardFooter,
    CurrencyComboBox,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    selectedCurrencyId: {
      type: [String, Number],
      default: null,
    },
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    graphLimits: {
      type: Object,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    whitelistedSources: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'update:selectedCurrencyId',
  ],
  data() {
    return {
      preferredDashboardCurrency: this.controller.config.dashboard.preferred_currency_unit,
      preferredGlobalCurrency: this.user.preferred_currency_unit,
      currencies: [],
    };
  },
  computed: {
    isPreferredDashboardCurrencyButtonDisabled() {
      return this.selectedCurrencyId === this.preferredDashboardCurrency?.id;
    },
    selectedCurrency() {
      return this.currencies.find((c) => c.id === this.selectedCurrencyId);
    },
    preferredDashboardCurrencyId() {
      return this.preferredDashboardCurrency?.id;
    },
    modalText() {
      if (!this.preferredDashboardCurrency) {
        return this.$t('components.currency_combobox.confirm_text_add_currency', { selectedCurrency: this.selectedCurrency.symbol });
      } if (this.selectedCurrencyId === null) {
        return this.$t('components.currency_combobox.confirm_text_delete_currency', { preferredDashboardCurrency: this.preferredDashboardCurrency.symbol });
      }
      return this.$t('components.currency_combobox.confirm_text_change_currency', { selectedCurrency: this.selectedCurrency.symbol, preferredDashboardCurrency: this.preferredDashboardCurrency.symbol });
    },
    isPreferredGlobalCurrencyButtonDisplayed() {
      return this.preferredGlobalCurrency !== null;
    },
    savePreferredDashboardCurrencyLabel() {
      return this.$t('apps.dashboards.dashboard_card_footer.save_preferred_dashboard_currency_label', {
        currency: this.selectedCurrency?.symbol,
      });
    },
  },
  async beforeMount() {
    this.currencies = await UnitsApi.currencies();
  },
  methods: {
    selectPreferredGlobalCurrency() {
      this.$emit('update:selectedCurrencyId', this.preferredGlobalCurrency?.id);
    },
    async updatePreferredDashboardCurrency() {
      const { selectedCurrencyId } = this;
      SetPreferredDashboardCurrency({
        dashboard: this.controller.dashboard,
        preferredCurrencyUnitId: selectedCurrencyId,
      }).then(({ error }) => {
        if (!error) {
          this.preferredDashboardCurrency = this.currencies.find((c) => (
            c.id === selectedCurrencyId));
        }
      });
    },
    onSavePreferredDashboardCurrencyClicked() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.modalText,
        },
        events: {
          confirm: () => this.updatePreferredDashboardCurrency(),
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>