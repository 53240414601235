<template>
  <div class="chart-config-form--axis">
    <h1 class="chart-config-form--title">
      {{ $t(`apps.chart_config.${axisType}_axis`) }}
    </h1>
    <AxisFormCombobox
      :model-value="axisUnit.composition_category"
      :label="$t('apps.chart_config.composition_category')"
      :options="availableCompositionCategories"
      :placeholder="placeholder(categoryIsDisabled)"
      :disabled="categoryIsDisabled"
      @update:modelValue="(compositionCategory) => updateCompositionCategory(compositionCategory)"
    />
    <AxisFormCombobox
      :model-value="axisUnit.currency_unit_id"
      :label="$t('apps.chart_config.currency')"
      :placeholder="placeholder(currencyIsDisabled)"
      :options="availableCurrencies"
      :disabled="currencyIsDisabled"
      @update:modelValue="(currencyUnitID) => $emit('update:axis-composite-unit', {
        ...axisUnit, currency_unit_id: currencyUnitID})"
    />
    <AxisFormCombobox
      :model-value="axisUnit.volume_unit_id"
      :label="$t('apps.chart_config.volume_unit')"
      :placeholder="placeholder(volumeUnitIsDisabled)"
      :options="volumeUnitOptions"
      :disabled="volumeUnitIsDisabled"
      @update:modelValue="(volumeUnitID) => updateVolumeUnitID(volumeUnitID)"
    />
  </div>
</template>

<script>
import i18n from '@/config/i18n';
import { AxisFormCombobox } from '@/components';
import {
  hasCurrencyComponent,
  hasVolumeComponent,
} from '@/helpers/UnitCompositionHelpers';

export default {
  name: 'AxisForm',
  components: {
    AxisFormCombobox,
  },
  props: {
    axisType: {
      type: String,
      required: true,
    },
    axisUnit: {
      type: Object,
      required: true,
    },
    availableCompositionCategories: {
      type: Array,
      required: true,
    },
    availableCurrencies: {
      type: Array,
      default: null,
    },
    availableVolumeUnits: {
      type: Object,
      default: null,
    },
    priceUnitCategories: {
      type: Array,
      default: null,
    },
    volumeUnitCategories: {
      type: Array,
      default: null,
    },
  },
  emits: ['update:axis-composite-unit'],
  computed: {
    categoryIsDisabled() {
      return this.availableCompositionCategories.length === 0;
    },
    currencyIsDisabled() {
      return !hasCurrencyComponent(this.axisUnit);
    },
    volumeUnitIsDisabled() {
      return !hasVolumeComponent(this.axisUnit);
    },
    volumeUnitOptions() {
      let unitsPerCategory = [];

      if (this.axisUnit.composition_category === 'price') {
        unitsPerCategory = this.priceUnitCategories;
      } else if (this.axisUnit.composition_category === 'volume') {
        unitsPerCategory = this.volumeUnitCategories;
      }

      return unitsPerCategory.map(
        (category) => Object.values(this.availableVolumeUnits[category]),
      ).flat();
    },
  },
  methods: {
    placeholder(isDisabled) {
      return isDisabled ? i18n.t('apps.chart_config.not_selectable') : i18n.t('apps.chart_config.selectable');
    },
    findUnitCategory(volumeUnitID) {
      const selectedOption = this.volumeUnitOptions.find((option) => volumeUnitID === option.id);
      return selectedOption.category;
    },
    updateVolumeUnitID(volumeUnitID) {
      const volumeUnitCategory = this.findUnitCategory(volumeUnitID);

      this.$emit('update:axis-composite-unit', {
        ...this.axisUnit,
        volume_unit_id: volumeUnitID,
        volume_unit_category: volumeUnitCategory,
      });
    },
    updateCompositionCategory(compositionCategory) {
      this.$emit('update:axis-composite-unit', {
        composition_category: compositionCategory,
        currency_unit_id: null,
        volume_unit_id: null,
        volume_unit_category: null,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>