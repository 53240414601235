// Used as workaround while Direct Upload is not supported by SetOps.
// (S3 CORS settings are required for this)

import axios from 'axios';

export const onTrixAttachmentAdd = async (event) => {
  const { attachment } = event;

  if (!attachment.file) return;

  const formData = new FormData();
  formData.append('data', attachment.file);
  formData.append('filename', attachment.file.name);

  const response = await axios.post('/api/blobs', formData, {
    onUploadProgress: (e) => {
      attachment.setUploadProgress((e.loaded * 100) / e.total);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const blob = response.data;

  attachment.setAttributes({
    sgid: blob.sgid,
    url: blob.url,
  });
};
