<template>
  <div
    ref="root"
    tabindex="-1"
    class="combobox"
    v-click-outside="onClickOutside"
    :class="{'combobox--full-width': isFullWidth, 'is-open': isOpen && !disabled}"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`option-list-${uid}`"
    @keydown.up.down="$refs.input.focus()"
  >
    <div
      class="combobox--default-button"
      :class="{'form--is-disabled': disabled}"
    >
      <div class="combobox--input-wrapper">
        <span
          v-if="description"
          class="combobox--description"
        >{{ description }}</span>
        <div class="combobox--filter-wrapper">
          <div class="filter" v-if="currentFilter">
            <Icon
              name="filter"
              variant="regular"
            />
            <span>{{ currentFilter.label.list }}</span>
            <button
              type="button"
              class="filter--clear"
              @click="clearFilter"
            >
              <Icon
                name="times"
                variant="regular"
              />
            </button>
          </div>
          <input
            autofill="off"
            autocomplete="off"
            :id="id"
            ref="input"
            :value="isOpen ? currentInput : currentValue"
            type="text"
            class="combobox--input"
            role="searchbox"
            aria-autocomplete="list"
            :aria-label="label"
            :aria-controls="`option-list-${uid}`"
            :aria-expanded="isOpen"
            :aria-labelledby="ariaLabelledby"
            aria-multiline="false"
            :placeholder="modelValue === null ? placeholder : currentValue"
            :required="required"
            :disabled="disabled"
            @input="onInputChanged"
            @focus="showOptions"
            @blur="onBlur"
            @keydown.up.prevent="focusPrevious"
            @keydown.down.prevent="focusNext"
            @keydown.enter.prevent="acceptCurrentOption"
            @keydown.delete="clearFilterIfAtStart"
          >
        </div>
      </div>
      <button
        type="button"
        :class="iconClass"
        :aria-label="iconLabel"
        @click="onIconClicked"
      >
        <Icon
          :name="iconName"
          variant="regular"
          :fixed-width="true"
        />
      </button>
    </div>

    <div
      v-if="isOpen && !disabled"
      class="combobox--popup"
      @click="clickedInsidePopup"
    >
      <slot name="header"/>
      <p
        v-if="noOptionsText && ((options.length === 0) || (currentFilter && options.filter((opt) => !opt.filter ).length === 0))"
        class="combobox--no-options"
      >
        {{ noOptionsText }}
      </p>
      <ul
        v-if="!isLoading"
        :id="`option-list-${uid}`"
        ref="options"
        class="combobox--options-list"
        :class="{'is-small': isSmall}"
        role="listbox"
        :aria-activedescendant="`option-${uid}-${focussedKey}`"
      >
        <li
          v-for="option in filteredOptions"
          :key="`option-${accessKey(option)}`"
        >
          <button
            :id="`option-${uid}-${accessKey(option)}`"
            :ref="getRefNameForKey(accessKey(option))"
            type="button"
            :class="{'has-focus': accessKey(option) === focussedKey, 'combobox--option': !option.filter}"
            :aria-labelledby="ariaLabelledby"
            :aria-selected="accessKey(option) === focussedKey"
            role="option"
            @mousemove="focusOption(option)"
            @click="selectOption(option)"
            v-if="!currentFilter || !option.filter"
          >
            <slot
              name="option"
              :option="option"
              :access-key="accessKey"
              :access-value="accessValue"
              v-if="option.filter"
            >
              <div class="filter-option">
                <div :class="{'has-focus': accessKey(option) === focussedKey, 'filter': true}">
                  <div class="icon">
                    <Icon
                      name="filter"
                      variant="regular"
                    />
                  </div>
                  {{ accessValue(option) }}
                </div>
              </div>
            </slot>
            <slot
              name="option"
              :option="option"
              :access-key="accessKey"
              :access-value="accessValue"
              v-else
            >
              <ComboBoxOption>
                {{ accessValue(option) }}
              </ComboBoxOption>
            </slot>
          </button>
        </li>
        <slot name="list-end" />
        <InfiniteScroll
          tag="li"
          v-if="infiniteScroll"
          @trigger="triggerInfiniteScroll"
        />
      </ul>
      <div
        v-if="$slots.footer"
        class="combobox--footer"
      >
        <slot
          name="footer"
          :focussed-option="focussedOption"
          :current-option="currentOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasUid from "@/mixins/HasUid";
import scrollToElement from "../../helpers/ScrollToElement";
import InfiniteScroll from "../InfiniteScroll/Component";

export default {
  name: "AutoComplete",
  mixins: [HasUid],
  components: {
    InfiniteScroll
  },
  props: {
    id: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    filter: {
      type: Number,
      default: null
    },
    selectedRecord: {
      type: Object,
      default: null,
    },
    accessKey: {
      type: Function,
      default: (o) => o.key
    },
    accessValue: {
      type: Function,
      default: (o) => o.value
    },
    allowEmptyValue: {
      type: Boolean,
      default: false
    },
    filterFunction: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    ariaLabelledby: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isAsync: {
      type: Boolean,
      default: false
    },
    asyncEventDebounce: {
      type: Number,
      default: 250
    },
    description: {
      type: String,
      default: null
    },
    noOptionsText: {
      type: String,
      default: null
    },
    minLength: {
      type: Number,
      default: 2
    },
    infiniteScroll: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:modelValue", "update:query", "update:filter", "trigger:infiniteScroll", "focus"
  ],
  data() {
    return {
      isOpen: false,
      focussedKey: this.modelValue,
      currentInput: "",
      isLoading: false,
      debounceTimer: null
    };
  },
  computed: {
    currentOption() {
      if (this.modelValue === null) return null;

      if (this.selectedRecord && `${this.accessKey(this.selectedRecord)}` === `${this.modelValue}`) {
        return this.selectedRecord
      }

      return this.options.find((o) => this.accessKey(o) === this.modelValue);
    },
    currentFilter() {
      if (this.filter === null) return null;
      return this.options.find((o) => this.accessKey(o) === this.filter);
    },
    currentValue() {
      return this.currentOption ? this.accessValue(this.currentOption) : null;
    },
    focussedOption() {
      return this.filteredOptions.find((o) => this.accessKey(o) === this.focussedKey);
    },
    isTextSuggestionVisible() {
      return this.isOpen
        && this.currentValue.indexOf(this.currentInput) === 0;
    },
    hasLockedValue() {
      return this.currentValue && !this.isOpen;
    },
    iconName() {
      if (!this.allowEmptyValue) {
        return "chevron-down";
      }
      return this.hasLockedValue ? "times" : "search";
    },
    iconClass() {
      return this.allowEmptyValue ? "combobox--icon" : "combobox--caret";
    },
    iconLabel() {
      if (!this.allowEmptyValue) {
        return this.$t("components.generic.autocomplete.toggle");
      }
      return this.hasLockedValue
        ? this.$t("components.generic.autocomplete.clear")
        : this.$t("components.generic.autocomplete.focus");
    },
    defaultFilter() {
      return (input, option) => {
        const value = `${this.accessValue(option)}`;
        return input !== null && value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      };
    },
    actualFilter() {
      return this.filterFunction || this.defaultFilter;
    },
    filteredOptions() {
      return this.isAsync ? this.options : this.options.filter((o) => this.actualFilter(this.currentInput, o));
    }
  },
  watch: {
    focussedOption() {
      this.$nextTick(() => this.adjustScrollPosition());
    },
    filteredOptions() {
      if (!this.isAsync) {
        const focussedIndex = this.filteredOptions.findIndex(
          (o) => this.accessKey(o) === this.focussedKey
        );
        if (focussedIndex === -1) {
          const option = this.filteredOptions[0];
          this.focussedKey = option ? this.accessKey(option) : null;
        }
      }
    },
    options() {
      if (this.isAsync && this.options.length > 0) {
        this.isLoading = false;
      }
    }
  },
  methods: {
    triggerInfiniteScroll() {
      this.$emit("trigger:infiniteScroll")
    },
    getRefNameForKey(key) {
      return `option-${key}`;
    },
    adjustScrollPosition() {
      const focusedRef = this.getRefNameForKey(this.focussedKey);
      const focusItem = this.$refs[focusedRef];
      if (!focusItem) return;
      scrollToElement(this.$refs.options, focusItem);
    },
    showOptions() {
      this.$emit("focus")
      this.focussedKey = this.modelValue;
      this.isOpen = true;
      this.debug = true
    },
    hideOptions() {
      this.isOpen = false;
      this.$refs.input.blur();
    },
    hideOptionsAndFocus() {
      this.hideOptions();
      this.$nextTick(() => this.$refs.root.focus());
    },
    selectOption(option) {
      if (option.filter) {
        this.$emit("update:filter", this.accessKey(option));
        this.showOptions();
        this.emitQuery();

        this.$nextTick(() => {
          this.currentInput = ""
          this.$emit("update:query", this.currentInput);
          this.$refs.input.focus();
        });
      } else {
        this.$emit("update:modelValue", this.accessKey(option));
        this.hideOptionsAndFocus();
      }
    },
    focusOption(option) {
      if (!option) return;
      this.focussedKey = this.accessKey(option);
    },
    focusPrevious() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => this.accessKey(o) === this.focussedKey
      );
      const nextIndex = Math.max(0, currentIndex - 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    focusNext() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => this.accessKey(o) === this.focussedKey
      );
      const nextIndex = Math.min(this.filteredOptions.length - 1, currentIndex + 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    acceptCurrentOption() {
      if (!this.focussedOption) return;
      this.selectOption(this.focussedOption);
    },

    closeOnBlur(event) {
      const newFocusElement = event.relatedTarget
          || event.explicitOriginalTarget
          || document.activeElement;

      return !this.$refs.root.contains(newFocusElement)
    },

    onClickOutside(event) {
      if (!this.isOpen) { return }
      // Clicking somewhere outside the input field will also trigger a blur
      // event. When a user leaves the input with a "native" blur event (e.g.
      // via tab) we want to close the dropdown. If the user clicked inside
      // the dropdown, we might want to keep it open.
      this.blurCausedByClick = true

      if (this.closeOnBlur(event)) {
        this.hideOptions()
      } else {
        setTimeout(()=>{
          this.$refs.input.focus()
          this.isOpen = true
        }, 0)
      }
    },

    // Clicks inside the popup (e.g. within the header or footer) should
    // not close the popup. But we need the same blur check as for outside
    // clicks
    clickedInsidePopup(event) {
      this.onClickOutside(event)
    },

    onBlur(event) {
      this.blurCausedByClick = false
      // if a click inside the dropdown happens after the blur
      // we want to keep the dropdown opened
      setTimeout(()=>{
        if (!this.blurCausedByClick) {
          this.hideOptions();
        }
        this.blurCausedByClick = false
      }, 500)
    },

    onIconClicked() {
      if (!this.allowEmptyValue) {
        this.toggleOptionsAndFocus();
        return;
      }
      if (this.hasLockedValue) {
        this.clearValue();
      } else {
        this.focusAndShowOptions();
      }
    },
    toggleOptionsAndFocus() {
      if (this.isOpen) {
        this.hideOptionsAndFocus();
      } else {
        this.focusAndShowOptions();
      }
    },
    focusAndShowOptions() {
      this.$refs.input.focus();
    },
    clearValue() {
      this.$emit("update:modelValue", null);
    },
    clearFilter() {
      this.$emit("update:filter", null);
      this.focusAndShowOptions();
    },
    clearFilterIfAtStart() {
      if (this.$refs.input.selectionStart === 0) {
        this.clearFilter();
        this.emitQuery();
      }
    },
    emitQuery() {
      this.$emit("update:query", this.currentInput);
      this.isLoading = true;
    },
    onInputChanged(event) {
      if (this.disabled) return;
      this.currentInput = event.target.value;

      if (this.isAsync) {
        const debounce = (callback, time) => {
          window.clearTimeout(this.debounceTimer);
          this.debounceTimer = window.setTimeout(callback, time);
        };

        debounce(() => {
          if (this.currentInput.length >= this.minLength) {
            this.emitQuery();
          }
        }, this.asyncEventDebounce);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.combobox {
  &--default-button {
    button {
      align-self: flex-end;
    }
  }

  &--input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      @media print {
        border: none;
      }
    }
  }

  &--filter-wrapper {
    display: flex;
    flex-direction: column;
    .filter {
      margin-bottom: 10px;
    }
  }

  .filter-option {
    padding: $space-s;
  }

  &--description {
    font-size: 10px;
    margin-bottom: 2px;
    color: #6B6B6B;
    z-index: 0;
  }

  &--no-options {
    margin: 10px;
  }

  .filter {
    //TODO use existing styles
    text-wrap: nowrap;
    display: flex;
    gap: $space-xs;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 4px 4px;
    margin-right: $space-s;
    @include font-size('14/14');
    color: $color-tag-text;
    background-color: $color-tag-background;
    width: fit-content;

    &--clear:hover {
      color: $color-text-unobtrusive;
    }

    &.has-focus {
      color: $color-tag-text-inverse;
      background-color: $color-tag-background-inverse;

      button {
        color: $color-tag-text-inverse;
        background-color: $color-tag-background-inverse;
      }
    }
  }
}
</style>
