<template>
  <li
    v-click-outside="onClickOutside"
    class="app-navigation--item"
    :class="{'is-current': highlight}"
  >
    <component
      :is="buttonTag"
      :href="href"
      :tooltip="tooltip"
      @click="$emit('click', $event)"
    >
      <WithTooltip
        :tooltip="tooltip"
        :delay="1000"
        class="app-navigation--link"
      >
        <img
          v-if="customIcon"
          :class="customIconClasses"
          :style="customIconStyles"
          :src="customIcon"
          class="app-navigation--link-custom-icon"
        >
        <Icon
          v-if="!customIcon"
          class="icon"
          variant="light"
          :name="icon"
        />
        {{ name }}
        <Icon
          v-if="showCaret"
          class="app-navigation--caret"
          variant="solid"
          name="caret-down"
        />
      </WithTooltip>
    </component>
    <slot />
  </li>
</template>

<script>

export default {
  name: 'NavigationItem',
  props: {
    name: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    customIcon: {
      type: String,
      required: false,
    },
    customIconClasses: {
      type: String,
      required: false,
      default: '',
    },
    customIconStyles: {
      type: Object,
      required: false,
      default: null,
    },
    showCaret: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'click-outside'],
  computed: {
    buttonTag() {
      return this.href ? 'a' : 'button';
    },
  },
  methods: {
    onClickOutside() {
      this.$emit('click-outside');
    },
  },
};
</script>
