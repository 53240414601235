<template>
  <div
    v-if="unreadCount > 0"
    class="total-unread-count"
  >
    <div
      class="total-unread-count--unread-marker"
      role="status"
      :aria-label="$t('generic.unread')"
    />
    <div class="total-unread-count--count">
      {{ $t('generic.unread_count', unreadCount) }}
    </div>
    <div class="total-unread-count--bull">
      &bull;
    </div>
    <ButtonComponent
      icon="check"
      variant="secondary"
      padding="none"
      @click="$emit('markAllAsRead')"
    >
      {{ $t('generic.mark_all_as_read') }}
    </ButtonComponent>
  </div>
</template>

<script>
export default {
  name: 'PublicationsTotalUnreadCount',
  props: {
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['markAllAsRead'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>