<template>
  <DashboardPageLayout class="dashboard">
    <template #top>
      <p
        class="for-print"
      >
        {{ $t('generic.for_internal_use_only') }}
      </p>
      <DashboardHeader
        :dashboard="dashboard"
        :user="user"
      >
        <DashboardHeaderActions
          :dashboard="dashboard"
          :controller="controller"
        />
      </DashboardHeader>

      <PrintLayout :caption="dashboard.full_name" />

      <NotationCard
        v-model:selected-graph-limits="controller.graphLimits"
        :primary-notation-loader="controller.selection.primary"
        :secondary-notation-loader="controller.selection.secondary"
        :selected-currency-id="selectedCurrencyId"
        :selected-date="controller.comparisonDate"
        :dashboard-header-modal-title="dashboard.full_name"
        @change-selected-date="changeSelectedDate"
      >
        <template #footer="{ primaryNotationDetails, secondaryNotationDetails }">
          <DashboardCardFooter
            v-model:selectedCurrencyId="selectedCurrencyId"
            v-exclude-from-print
            :graph-limits="controller.graphLimits"
            :controller="controller"
            :primary-notation-details="primaryNotationDetails"
            :secondary-notation-details="secondaryNotationDetails"
            :selected-date="controller.comparisonDate"
            :user="user"
            :whitelisted-sources="whitelistedSources"
            @update:selectedCurrencyId="onSelectedCurrencyChanged"
          />
        </template>
      </NotationCard>
      <DashboardListHeader
        :caption="controller.caption"
        :controller="controller"
        :dashboard="dashboard"
      />
    </template>

    <template #bottom>
      <div class="data-table--sticky-margin" />
      <NotationList :controller="controller" />
    </template>
  </DashboardPageLayout>
</template>

<script>
import UrlStateStore from '@/services/UrlStateStore';
import { DashboardPageLayout } from '@/components/Layouts';
import {
  PrintLayout,
  NotationCard,
  DashboardHeader,
} from '@/components';
import NotationList from '@/components/NotationList/NotationList.vue';
import { DashboardController } from './controllers';
import {
  DashboardListHeader,
  DashboardHeaderActions,
  DashboardCardFooter,
} from './components';

export default {
  name: 'DashboardApp',
  components: {
    PrintLayout,
    DashboardPageLayout,
    NotationCard,
    DashboardCardFooter,
    NotationList,
    DashboardListHeader,
    DashboardHeader,
    DashboardHeaderActions,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    showCustomised: {
      type: Boolean,
      default: false,
    },
    whitelistedSources: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      controller: null,
      selectedCurrencyId: UrlStateStore.get('selectedCurrencyId', this.dashboard.preferred_currency_unit?.id),
    };
  },
  beforeMount() {
    this.controller = new DashboardController(this.dashboard,
      this.showCustomised,
      this.user,
      this.initialState,
      {
        isMailExport: this.$isMailExport,
        media: this.$media,
        selectedCurrencyId: this.selectedCurrencyId,
      });
  },
  methods: {
    onSelectedCurrencyChanged(value) {
      UrlStateStore.set('selectedCurrencyId', value);
      this.controller.selectedCurrencyId = value;
    },
    changeSelectedDate(date) {
      this.controller.comparisonDate = date;
    },
  },
  provide() {
    return {
      user: this.user,
    };
  },
};
</script>
