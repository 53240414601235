<template>
  <div
    ref="root"
    class="scrollarea"
    :class="cssModifier"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>

const toStyleString = (name, value) => {
  const valueIsString = typeof value === 'string' || value instanceof String;
  if (valueIsString) {
    return `${name}: ${value}`;
  }
  return `${name}: ${value}px`;
};

export default {
  name: 'ScrollArea',
  props: {
    vertical: {
      type: Boolean,
      default: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    fixedHeight: {
      type: [Number, String],
      default: null,
    },
    maxHeight: {
      type: [Number, String],
      default: null,
    },
    fixedWidth: {
      type: [Number, String],
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    cssModifier() {
      const modifier = [];
      if (this.vertical) { modifier.push('is-vertical'); }
      if (this.horizontal) { modifier.push('is-horizontal'); }
      return modifier.join(' ');
    },
    style() {
      const style = [];
      if (this.fixedHeight !== null) {
        style.push(toStyleString('height', this.fixedHeight));
      }
      if (this.maxHeight !== null) {
        style.push(toStyleString('max-height', this.maxHeight));
      }
      if (this.fixedWidth !== null) {
        style.push(toStyleString('width', this.fixedWidth));
      }
      if (this.maxWidth !== null) {
        style.push(toStyleString('max-width', this.maxWidth));
      }
      return style.join('; ');
    },
  },
  methods: {
    scrollToTop({ smooth = false } = {}) {
      this.$refs.root.scrollTo({ top: 0, behavior: smooth ? 'smooth' : 'auto' });
    },
    scrollIntoView(element, { margin = 0 }) {
      // Element.scrollIntoView is not supported in some
      // browsers, which is why we use our own logic
      if (this.$refs.root.contains(element)) {
        const scrollAreaRect = this.$refs.root.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const elementRectTop = elementRect.top - margin;
        const elementRectBottom = elementRect.bottom + margin;
        if (elementRectTop < scrollAreaRect.top) {
          const delta = elementRectTop - scrollAreaRect.top;
          this.$refs.root.scrollTop += delta;
        } else if (elementRectBottom > scrollAreaRect.bottom) {
          const delta = elementRectBottom - scrollAreaRect.bottom;
          this.$refs.root.scrollTop += delta;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>