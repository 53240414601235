<template>
  <div class="chart-config--not-found">
    <h1 class="chart-config--not-found-title">
      {{ $t('apps.chart_config.error_title') }}
    </h1>
    <h2 class="chart-config--not-found-subtitle">
      {{ $t('apps.chart_config.error_subtitle') }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'ChartConfigurationNotFound',
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>