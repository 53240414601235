const validateTimescope = (timescope) => {
  const ValidTimescopes = ['yearly', 'monthly', 'weekly', 'daily'];
  if (!ValidTimescopes.includes(timescope)) {
    throw new Error(`Invalid timescope. Must be one of [${ValidTimescopes.join(', ')}]`);
  }
};

const createDateRange = (from, to, filter) => {
  const range = [];
  const start = new Date(from.getFullYear(), from.getMonth(), from.getDate());
  const end = new Date(to.getFullYear(), to.getMonth(), to.getDate());
  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    if (filter(date)) {
      range.push(new Date(+date));
    }
  }
  return range;
};

const isFirstOfMonth = (date) => date.getDate() === 1;

const isFirstOfJanuary = (date) => date.getMonth() === 0 && isFirstOfMonth(date);

const isMonday = (date) => date.getDay() === 1;

const createDateFilterForTimescope = (timescope) => {
  switch (timescope) {
    case 'yearly': return isFirstOfJanuary;
    case 'monthly': return isFirstOfMonth;
    case 'weekly': return isMonday;
    default: return () => true;
  }
};

const dateToString = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

const resampleTimeseries = (X, timescope) => {
  if (!Array.isArray(X)) {
    throw new Error('X must be an array.');
  }
  if (X.length < 2) {
    throw new Error('X must have at least 2 entries.');
  }
  validateTimescope(timescope);
  const dateFilter = createDateFilterForTimescope(timescope);
  if (!X.map((x) => x.x).every(dateFilter)) {
    throw new Error(`Timeseries contains invalid dates for timescope '${timescope}'.`);
  }

  const fromDate = X[0].x;
  const toDate = X[X.length - 1].x;
  const dateRange = createDateRange(fromDate, toDate, dateFilter);
  const valueForDate = X.reduce((obj, elem) => {
    obj[dateToString(elem.x)] = elem.y;
    return obj;
  }, {});
  return dateRange.map((date) => {
    const key = dateToString(date);
    const value = valueForDate[key];
    return {
      x: date,
      y: value === undefined ? null : value,
    };
  });
};

export default resampleTimeseries;
