<template>
  <div class="date-picker-popup">
    <DatePick
      ref="picker"
      :value="selectedDateValue"
      :has-input-element="false"
      :is-date-disabled="isDateDisabled"
      :weekdays="weekdays"
      :months="months"
      :previous-month-caption="$t('components.date_picker.previous')"
      :next-month-caption="$t('components.date_picker.next')"
      @input="onDateSelected"
    />
  </div>
</template>

<script>
import moment from 'moment';
import DatePick from './vue-date-pick/vueDatePick.vue';

const dropTimeFromDate = (date) => (
  new Date(date.getFullYear(), date.getMonth(), date.getDate()));

export default {
  name: 'DatePickerPopup',
  components: { DatePick },
  props: {
    date: {
      type: Date,
      default: () => new Date(),
    },
    availableDates: {
      type: Array,
      default: null,
    },
  },
  emits: ['select-date'],
  computed: {
    weekdays() {
      const weekdaysSundayFirst = moment.weekdaysShort();
      return weekdaysSundayFirst.slice(1).concat(weekdaysSundayFirst.slice(0, 1));
    },
    months() {
      return moment.months();
    },
    selectedDateValue() {
      if (this.date !== null) {
        const year = this.date.getFullYear();
        const month = this.date.getMonth() + 1;
        const day = this.date.getDate();
        return `${year}-${month}-${day}`;
      }
      return null;
    },
  },
  mounted() {
    this.showLatestAvailableDate();
  },
  methods: {
    onClearClicked() {
      this.$emit('select-date', null);
    },
    onDateSelected(date) {
      // This ensures we always emit a date at 0:00 in the browser timezone on the chosen date:
      const converted = new Date(`${date} 00:00`);
      this.$emit('select-date', converted);
    },
    isDateDisabled(date) {
      if (this.availableDates === null) return false;
      if (this.availableDates.length === 0) return true;
      const dates = this.availableDates
        .map(dropTimeFromDate)
        .map((d) => d.getTime());
      return dates.indexOf(date.getTime()) < 0;
    },
    showLatestAvailableDate() {
      const { picker } = this.$refs;
      if (this.availableDates === null) return;
      const date = this.availableDates[this.availableDates.length - 1];
      if (!picker || !date || this.date) return;
      picker.currentPeriod.month = date.getMonth();
      picker.currentPeriod.year = date.getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>