<script>
import debounce from '@/helpers/debounce';
import axios from 'axios';
import { CustomLoadingSpinner } from '@/components';
import TableHeadCell from '@/components/NotationList/NotationTable/TableHeadCell';
import { compareTableValues } from '@/helpers/SortHelpers';

import Filterable from './Filterable';
import ControllerAdapter from './ControllerAdapter';

import SearchFilterColumnHead from '@/components/NotationList/NotationTable/ColumnHeads/SearchFilterColumnHead'


export default {

  mixins: [Filterable],

  components: {
    TableHeadCell,
    SearchFilterColumnHead,
    CustomLoadingSpinner
  },

  inject: ['t'],

  props: {
    endpoint: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    i18nScope: {
      type: String,
      required: true
    },
    collectionKey: {
      type: String,
      default: 'records'
    },
    view: {
      type: String,
      default: undefined
    }
  },

  provide() {
    return {
      columns: this.columns,
      checkConfigLoaded: () => this.initiallyLoaded
    }
  },

  data() {
    return {
      unsorted: 'unsorted',
      sorting: {
        column: null,
        direction: null
      },

      columns: [],
      collection: [],
      json: null,
      isLoading: false,
      initiallyLoaded: false,
      filterOptions: {},
      controllerAdapter: new ControllerAdapter(this)
    }
  },

  created() {
    this.fetchDebounced = debounce(()=>{
      this.fetch()
    }, 100)
  },

  mounted() {
    this.fetchDebounced()

    // if there is a successful UJS request from an item within the table, we'll reload the table
    this.$refs.container.addEventListener('ajax:success', ()=>{
      this.fetch()
    })
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchDebounced()
      }
    },
    view: {
      handler() {
        this.fetchDebounced()
      }
    }
  },

  methods: {
    sortColumn(column) {
      if (this.sorting.column != column.i18n) {
        this.sorting.direction = 'asc'
        this.sorting.column = column.i18n
      } else if(this.sorting.direction == 'asc') {
        this.sorting.direction = 'desc'
      } else {
        this.sorting.direction = null
        this.sorting.column = null
      }

      if (this.sorting.direction) {
        this.sortTableCollection(column, this.sorting.direction)
      } else {
        // default sort order after reset
        this.sortTableCollection(this.columns[0], 'asc')
      }
    },

    sortTableCollection(column, direction) {
      this.collection = compareTableValues(this.collection, (item)=>{
        return column.sortValue(item)
      }, direction);
    },

    fetch() {
      if (!this.requiredFiltersPresent) {
        return
      }

      this.collection = []
      this.isLoading = true
      axios.get(this.endpoint, { params: {...this.filters, view: this.view} }).then((response)=>{
        this.json = response.data
        this.collection = this.json[this.collectionKey]
      }).finally((error) => {
        this.initiallyLoaded = true
        this.isLoading = false
      })
    }
  },

  computed: {
    blankSlate() {
      return this.initiallyLoaded && !this.isLoading && !this.collection.length
    }
  }

}
</script>

<template>
  <slot v-if="requiredFiltersPresent" name="title">
    <h1 v-if="title">{{ title }}</h1>
  </slot>

  <div v-if="requiredFiltersPresent" class="search-results show-first-column" ref="container">
    <CustomLoadingSpinner v-if="isLoading"/>
    <slot v-if="blankSlate" name="blank-slate">
      <div class="has-text-error">
        {{ t('apps.human_rights.commodity_risk_map.no_data') }}
      </div>
    </slot>
    <table
      v-else
      class="data-table"
      :class="{'is-loading': isLoading}"
    >
      <thead
        v-if="initiallyLoaded && !isLoading"
        class="data-table--head data-table--sticky-margin"
      >
        <tr class="data-table--head-row">
          <template
            v-for="(column, index) in columns"
            :key="`column-${index}`"
          >
            <SearchFilterColumnHead
              v-if="column.filterable"
              :column-key="column.i18n"
              :display-name="column.title || (column.i18n ? t(`attributes.${i18nScope}.${column.i18n}`) : null)"
              :controller="controllerAdapter"
            ></SearchFilterColumnHead>

            <TableHeadCell
              v-else
              :title="column.title || (column.i18n ? t(`attributes.${i18nScope}.${column.i18n}`) : null)"
              :is-sortable="true"
              :sort-direction="sorting.column == column.i18n ? sorting.direction : unsorted"
              @click="sortColumn(column)"
            ></TableHeadCell>
          </template>
        </tr>
      </thead>

      <tbody class="data-table--body is-selecting-none">
        <tr
          v-for="(record, index) in collection"
          :key="`record-${index}`"
          class="data-table--body-row"
        >
          <slot name="columns" :record="record" :row="index"></slot>
        </tr>
      </tbody>
    </table>
    <slot></slot>
  </div>
</template>

<style lang="scss">

.hrr-container {
  .search-results {
    height: 100%;

    .table-cell-name {
      min-width: 200px;
    }
    .data-table--head-row .data-table--head-cell-title {
      text-transform: uppercase;
      white-space: normal;
    }
    .custom-loading-spinner {
      height: 50px;
      background: none;
    }

    padding-bottom: 20px;

    @media print {
      .data-table {
        font-size: 60%;
        tr {
          &, * {
            page-break-inside: avoid;
          }
        }
      }
      .data-table--head-cell, .data-table--body-cell {
        padding: 3mm 0;
      }
      .table-cell-name {
        min-width: 0;
        max-width: 150px;
      }
      &.show-first-column {
        .data-table--head-cell:first-child, .data-table--body-cell:first-child {
          display: block !important;
        }
      }
    }
    overflow-y: auto;
  }
}
</style>
