<template>
  <ul class="page-header--action-list">
    <li
      v-if="controller.hasOwnView"
      class="page-header--action-item"
    >
      <ButtonComponent
        :icon="controller.showCustomised ? 'toggle-on' : 'toggle-off'"
        :variant="controller.showCustomised ? 'tertiary' : 'ternary'"
        :is-toggled="controller.showCustomised"
        @click="controller.toggleShowCustomised()"
      >
        {{ $t('components.dashboards.toggle_customised') }}
      </ButtonComponent>
    </li>
    <li class="page-header--action-item">
      <ButtonWithDropdown
        variant="ternary"
        icon="caret-down"
      >
        {{ $t('components.dashboards.options') }}
        <template #dropdown>
          <DashboardActionDropdown
            :dashboard="dashboard"
            :controller="controller"
          />
        </template>
      </ButtonWithDropdown>
    </li>
  </ul>
</template>

<script>
import DashboardActionDropdown from './DashboardActionDropdown.vue';

export default {
  name: 'DashboardHeaderActions',
  components: {
    DashboardActionDropdown,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
