<template>
  <ul class="dialog--action-list">
    <li
      v-if="controller.files.length > 0 && !controller.uploadStarted"
      class="dialog--action-list-item"
    >
      <FileSelectButton
        icon="plus"
        variant="ternary"
        button-padding="vertical"
        :is-disabled="controller.isLoading"
        :multiple="true"
        @select="controller.addFiles($event)"
      >
        {{ $t('apps.publications.upload_modal.add_files') }}
      </FileSelectButton>
    </li>
    <li class="dialog--action-list-spacer" />
    <li class="dialog--action-list-item">
      <ButtonComponent
        variant="ternary"
        @click="controller.cancel()"
      >
        {{ $t('components.modals.cancel') }}
      </ButtonComponent>
    </li>
    <li class="dialog--action-list-item">
      <ButtonComponent
        v-if="!controller.uploadFinished"
        :disabled="!controller.canStartUpload"
        variant="primary"
        @click="controller.startUpload()"
      >
        {{ $t('apps.publications.upload_modal.start_upload') }}
      </ButtonComponent>
      <ButtonComponent
        v-else
        variant="primary"
        @click="controller.cancel()"
      >
        {{ $t('apps.publications.upload_modal.done_button') }}
      </ButtonComponent>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PublicationsUploadModalFooterActions',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
