const renderBox = (chart, annotation) => {
  const context = chart.chart.ctx;

  const xscale = chart.scales['x-axis-0'];
  const yscale = chart.scales['y-axis-0'];

  const {
    xMin,
    xMax,
    yMin,
    yMax,
  } = annotation;

  let relativeXMin = (xMin - xscale.min) / (xscale.max - xscale.min);
  let relativeXMax = (xMax - xscale.min) / (xscale.max - xscale.min);
  let relativeYMin = (yMin - yscale.min) / (yscale.max - yscale.min);
  let relativeYMax = (yMax - yscale.min) / (yscale.max - yscale.min);
  // Clip box left and right if it goes out of viewport
  relativeXMin = Math.max(0, relativeXMin);
  relativeXMax = Math.min(1, relativeXMax);

  relativeYMin = Math.max(0, relativeYMin);
  relativeYMax = Math.min(1, relativeYMax);

  // Don't draw anything if entire box is out of viewport
  if (relativeXMax < 0 || relativeXMin > 1) return;

  const width = (relativeXMax - relativeXMin) * (xscale.width);
  const canvasXMin = xscale.left + relativeXMin * (xscale.width);
  const height = (relativeYMax - relativeYMin) * (yscale.height);

  context.font = 'bold 12px sans-serif';
  const textWidth = context.measureText(annotation.label).width;

  const labelPaddingPx = 2;
  const labelBorderOffset = textWidth / 2 + labelPaddingPx;
  const canvasXMiddle = canvasXMin + width / 2;

  let labelXMiddle = canvasXMiddle;
  labelXMiddle = Math.max(labelXMiddle, xscale.left + labelBorderOffset);
  labelXMiddle = Math.min(labelXMiddle, xscale.right - labelBorderOffset);

  const labelXMin = labelXMiddle - (textWidth / 2);
  const labelYMin = yscale.top + 2;

  // Draw box and label
  context.beginPath();
  context.fillStyle = annotation.color;
  context.fillRect(canvasXMin, yscale.top, width, height);
  context.fillStyle = ('black');
  context.fillText(annotation.label, labelXMin, labelYMin);

  context.stroke();
};

export default {
  beforeDraw(chart) {
    if (chart.config.data.annotations) {
      chart.config.data.annotations.forEach((annotation) => {
        switch (annotation.type) {
          case 'Box':
            return renderBox(chart, annotation);
          default:
            throw new Error(`Unknown annotation type ${annotation.type}`);
        }
      });
    }
  },
};
