<script>
export default {
  props: {
    record: {
      type: Object,
      required: true
    }
  },

  computed: {
    expandable() {
      return this.record.children?.length
    },
  }
}
</script>

<template>

  <span
    v-for="depth in record.depth"
    :key="`border-${depth}`"
    :class="`depth-indicator-border depth-${depth}`"
  ></span>
  <span v-if="record.depth > 0" :class="`depth-indicator-spacer depth-${record.depth}`"></span>
</template>

<style lang="scss">
$colors: #accbc3, #bbcbe1, #fecdba, #f6b6c2, #9fcfca, #d4d2e1, #fedfbf, #d7d7d7, #f4cbcf, #e6f0f0;

.expandable-cell {
  //position: relative;
  z-index: 0;

  .depth-indicator-border, .depth-indicator-spacer {
    width: 3px;
    height: 100%;
    float: left;
  }
  .depth-indicator-border {
    min-height: 36px;
    display: inline-block;
    margin-right: 8px;
    margin-left: 24px;
    margin-top: -10px;
    margin-bottom: -10px;
  }

  @for $i from 1 through 6 {
    & .depth-indicator-border.depth-#{$i} {
      background-color: nth($colors, $i);
      left: ($i - 1) * 20px;
    }
    & .depth-indicator-spacer.depth-#{$i} + .default-cell {
      max-width: 400px - ($i * 20px);
    }
  }

  // add +/- icon
  $nesting-space: 20px;
  $plus-minus-icon-size: 12px;
  .default-cell {
    padding-left: 10px + $plus-minus-icon-size;
  }
  &.depth-has-children {
    cursor: pointer;
    .default-cell {
      position: relative;
    }
    &:hover .default-cell:before {
      color: #222;
      border-color: #222;
    }
    &.depth-expanded .default-cell:before {
      content: "-";
    }
    .default-cell:before {
      content: "+";
      position: absolute;
      width: $plus-minus-icon-size + 2px;
      height: $plus-minus-icon-size;
      text-align: center;
      font-size: $plus-minus-icon-size;
      line-height: $plus-minus-icon-size;
      top: 0;
      left: 0;
      border-radius: 2px;
      border: 1px solid #b0bbcc;
      color: #b0bbcc;
    }
  }
}
</style>
