<template>
  <td
    class="data-table--body-cell"
    :class="classes"
  >
    <div class="top-five-country--row">
      <div class="top-five-country--col">
        <template v-if="value">
          <span
            class="top-five-country--iso-code"
          >{{ value.iso_code }}</span>
          <span>({{ convertToLocale(tradeFlowPercentage, locale, {minimumFractionDigits: 1, maximumFractionDigits: 1}) }}%)</span>
        </template>
        <span v-else>-</span>
      </div>

      <div class="top-five-country--col">
        <WithTooltip y-alignment="top">
          <span class="top-five-country__scores">
            <img
              v-if="hrScoreIcon"
              class="top-five-country__scores__grade"
              :src="hrScoreIcon"
            >
            <span>{{ convertToLocale(hrScore, locale, {minimumFractionDigits: 1, maximumFractionDigits: 1}) }}</span>
            <img
              v-if="hrScoreFlagIcon && isTotalDimension"
              class="top-five-country__scores__flag"
              :src="hrScoreFlagIcon"
            >
          </span>
          <template
            v-if="tooltipData"
            #custom
          >
            <TopFiveCountryCellTooltip :data="tooltipData" />
          </template>
        </WithTooltip>
      </div>
    </div>
  </td>
</template>

<script setup>

import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { convertToLocale } from '@/utils/ConvertToLocaleString';
import TopFiveCountryCellTooltip from './TopFiveCountryCellTooltip.vue';
import { useHrScoreIcon } from './hrScoreIcon';
import useHrScoreFlagIcon from './hrScoreFlagIcon';

const { locale } = useI18n();

const props = defineProps(['value', 'additionalData', 'classes']);

const isTotalDimension = computed(() => props.additionalData.selectedDimension === 0);
const tooltipData = computed(() => ({
  hrScores: props.value?.hr_scores,
  currentCountry: `${props.value?.iso_code} | ${props.value?.name}`,
}));
const tradeFlowPercentage = computed(() => props.value?.trade_flow_percentage?.toFixed(1));
const hrScore = computed(() => props.value?.hr_scores?.[props.additionalData.selectedDimension]?.value);
const grade = computed(() => props.value?.hr_scores?.[props.additionalData.selectedDimension]?.grade);

const { hrScoreIcon, getIconReactive } = useHrScoreIcon(grade);
const { hrScoreFlagIcon } = useHrScoreFlagIcon(props.value?.hr_scores || []);
const { classes } = props;

watch(grade, getIconReactive);

</script>

<style scoped lang="scss">
td {
  .top-five-country {
    &--row {
      display: flex;
      flex-direction: row;
      line-height: 1.2rem;
      justify-content: center;
    }

    &--col {
      display: flex;
      &:first-child {
        flex-grow: 1;
        margin-right: 6px;
      }

      &:last-child {
        justify-content: right;
      }
    }

    &--iso-code {
      margin-right: 5px;
    }

    &__scores {
      display: flex;
      align-items: center;
      width: 50px;

      &__grade {
       padding-right: 3px;
      }

      &__flag {
        padding-left: 2px;
      }

      span {
        min-width: 25px;
        text-align: center;
      }
    }
  }
}

@media print {
  td .top-five-country {
    &--row {
      display: block;
    }
    &--col:last-child {
      justify-content: left;
    }
  }
}
</style>

<style lang="scss">
@media print {
  .top-five-table .country-on-demand__content-wrapper {
    min-width: 0 !important;
    flex-direction: column !important;
  }
}
</style>
