<template>
  <DropdownMenu>
    <DropdownMenuButton
      v-if="dashboard.can_update"
      icon="save"
      :aria-label="$t('components.dashboards.save_dashboard')"
      @click="onSaveClicked"
    >
      {{ $t('components.dashboards.save_dashboard') }}
    </DropdownMenuButton>
    <template v-if="dashboard.can_edit_fallback_state">
      <DropdownMenuButton
        icon="level-down"
        :aria-label="$t('components.dashboards.save_fallback_state')"
        @click="onSaveFallbackClicked"
      >
        {{ $t('components.dashboards.save_fallback_state') }}
      </DropdownMenuButton>
      <DropdownMenuButton
        :disabled="!controller.canLoadDashboardFallbackState"
        icon="level-up"
        :aria-label="$t('components.dashboards.load_fallback_state')"
        @click="onLoadFallbackClicked"
      >
        {{ $t('components.dashboards.load_fallback_state') }}
      </DropdownMenuButton>
    </template>
    <DropdownMenuButton
      v-if="dashboard.can_update"
      icon="cog"
      :aria-label="$t('components.dashboards.edit_dashboard')"
      @click="onEditClicked"
    >
      {{ $t('components.dashboards.edit_dashboard') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      icon="clone"
      :aria-label="$t('components.dashboards.clone_dashboard')"
      @click="onCloneClicked"
    >
      {{ $t('components.dashboards.clone_dashboard') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      v-if="dashboard.can_publish"
      icon="users"
      :aria-label="$t('components.dashboards.publish_dashboard')"
      @click="onPublishClicked"
    >
      {{ $t('components.dashboards.publish_dashboard') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      v-if="dashboard.can_unpublish"
      icon="users-slash"
      :aria-label="$t('components.dashboards.unpublish_dashboard')"
      @click="onUnpublishClicked"
    >
      {{ $t('components.dashboards.unpublish_dashboard') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      v-if="dashboard.can_destroy"
      icon="trash-alt"
      :aria-label="$t('components.dashboards.delete_dashboard')"
      @click="onDeleteClicked()"
    >
      {{ $t('components.dashboards.delete_dashboard') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      v-if="dashboard.can_have_own_view && !controller.hasOwnView"
      icon="eye"
      :aria-label="$t('components.dashboards.create_customisation')"
      @click="onCreateCustomisationClicked()"
    >
      {{ $t('components.dashboards.create_customisation') }}
    </DropdownMenuButton>
    <DropdownMenuButton
      v-if="controller.hasOwnView"
      icon="trash-alt"
      :aria-label="$t('components.dashboards.delete_customisation')"
      @click="onDeleteCustomisationClicked()"
    >
      {{ $t('components.dashboards.delete_customisation') }}
    </DropdownMenuButton>
  </DropdownMenu>
</template>

<script>
import { reactive } from 'vue';
import ModalWindowController from '@/controllers/ModalWindowController';

export default {
  name: 'DashboardActionDropdown',
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    controller: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSaveClicked() {
      this.controller.saveDashboardState();
    },
    onSaveFallbackClicked() {
      this.controller.saveDashboardFallbackState();
    },
    onLoadFallbackClicked() {
      this.controller.loadDashboardFallbackState();
    },
    onEditClicked() {
      ModalWindowController.show('EditDashboardModal', {
        props: {
          initialDashboard: this.dashboard,
        },
        events: {
          save: (dashboard) => {
            this.controller.editDashboard(dashboard)
              .then(() => { ModalWindowController.close(); });
          },
        },
      });
    },
    onCloneClicked() {
      const formError = reactive({ message: null });
      ModalWindowController.show('CloneDashboardModal', {
        props: {
          initialDashboard: this.dashboard,
          formError,
        },
        events: {
          clone: (dashboard) => {
            this.controller.cloneDashboard(dashboard)
              .then(() => { ModalWindowController.close(); })
              .catch((error) => { formError.message = error.response.data.message; });
          },
        },
      });
    },
    onDeleteClicked() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.$t('components.dashboards.delete_dashboard_confirm_text', { name: this.dashboard.full_name }),
        },
        events: {
          confirm: () => { this.controller.deleteDashboard(this.dashboard); },
        },
      });
    },
    onCreateCustomisationClicked() {
      this.controller.saveCustomDashboardState();
    },
    onDeleteCustomisationClicked() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.$t('components.dashboards.delete_customisation_confirm_text', { name: this.dashboard.full_name }),
        },
        events: {
          confirm: () => { this.controller.deleteDashboardOwnView(this.dashboard); },
        },
      });
    },
    onPublishClicked() {
      const formError = reactive({ message: null });
      ModalWindowController.show('PublishDashboardModal', {
        props: {
          initialDashboard: this.dashboard,
          formError,
        },
        events: {
          publish: (dashboard) => {
            this.controller.publishDashboard(dashboard)
              .then(() => { ModalWindowController.close(); })
              .catch((error) => { formError.message = error.response.data.message; });
          },
        },
      });
    },
    onUnpublishClicked() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.$t('components.dashboards.unpublish_dashboard_confirm_text', { name: this.dashboard.full_name }),
        },
        events: {
          confirm: () => { this.controller.unpublishDashboard(); },
        },
      });
    },
  },
};
</script>
