<template>
  <transition-group
    name="toast-notification-animation"
    tag="ul"
    class="toast-notification-list"
  >
    <li
      v-for="notification in notifications"
      :key="notification.id"
      class="toast-notification-list--item"
    >
      <ToastNotification
        :message="notification.message"
        :message-type="notification.type"
        @close="closeNotification(notification.id)"
      />
    </li>
  </transition-group>
</template>

<script>
import NotificationController from '@/controllers/NotificationController';
import { ToastNotification } from './components';

export default {
  name: 'ToastNotificationsApp',
  components: { ToastNotification },
  computed: {
    notifications() {
      return NotificationController.notifications;
    },
  },
  mounted() {
    if (!window.FLASH_MESSAGES) { return }
    window.FLASH_MESSAGES.forEach((flashMessage) => {
      let translatedType = {
        "alert": "error",
        "notice": "info"
      }[flashMessage.type] || flashMessage.type
      NotificationController.add(translatedType, flashMessage.message);
    });
  },
  methods: {
    closeNotification(id) {
      NotificationController.remove(id);
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>