<template>
  <ul class="dashboard-search-list">
    <DashboardSearchListSuggestions
      v-if="suggestions.length"
      :items="suggestions"
      :selected-id="selectedId"
      :is-expanded="hasExpandedSuggestions"
      :total-item-count="totalSuggestionCount"
      @select="onSelectTag"
      @focus="onFocusItem"
      @expand="$emit('expand-suggestions')"
      @collapse="$emit('collapse-suggestions')"
    />
    <DashboardSearchListSection
      v-if="privateDashboards.length"
      :title="$t('components.dashboard_search_list.section_title_private')"
    >
      <DashboardSearchListItemList
        :items="privateDashboards"
        :selected-id="selectedId"
        @focus="onFocusItem"
      />
    </DashboardSearchListSection>
    <DashboardSearchListSection
      v-if="favoriteDashboards.length"
      :title="$t('components.dashboard_search_list.section_title_favorites')"
    >
      <DashboardSearchListItemList
        :items="favoriteDashboards"
        :selected-id="selectedId"
        :can-toggle-favorites="true"
        @focus="onFocusItem"
      />
    </DashboardSearchListSection>
    <DashboardSearchListSection
      v-if="publicDashboards.length"
      :title="$t('components.dashboard_search_list.section_title_published')"
    >
      <DashboardSearchListItemList
        :items="publicDashboards"
        :selected-id="selectedId"
        :can-toggle-favorites="true"
        @focus="onFocusItem"
      />
    </DashboardSearchListSection>
    <DashboardSearchListSection
      v-if="products.length"
      :title="$t('components.dashboard_search_list.section_title_products')"
    >
      <DashboardSearchListTagList
        :tags="products"
        orientation="vertical"
        :can-remove="true"
        :selected-id="selectedId"
        @select="onSelectTag"
        @focus="onFocusItem"
      />
    </DashboardSearchListSection>
    <li
      v-if="$slots.footer"
      class="dashboard-search-list--footer"
    >
      <slot name="footer" />
    </li>
  </ul>
</template>

<script>
import DashboardSearchListSuggestions from './Suggestions/Component.vue';
import DashboardSearchListSection from './Section/Component.vue';
import DashboardSearchListTagList from './TagList/Component.vue';
import DashboardSearchListItemList from './ItemList/Component.vue';

export default {
  name: 'DashboardSearchList',
  components: {
    DashboardSearchListSuggestions,
    DashboardSearchListSection,
    DashboardSearchListTagList,
    DashboardSearchListItemList,
  },
  props: {
    suggestions: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: [Number, String],
      default: null,
    },
    hasExpandedSuggestions: {
      type: Boolean,
      default: false,
    },
    totalSuggestionCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['select-tag', 'focus-item', 'expand-suggestions', 'collapse-suggestions'],
  computed: {
    privateDashboards() {
      return this.items.filter((i) => i.type === 'private_dashboard');
    },
    favoriteDashboards() {
      return this.items.filter((i) => i.type === 'favorite_dashboard');
    },
    publicDashboards() {
      return this.items.filter((i) => i.type === 'public_dashboard');
    },
    products() {
      return this.items.filter((i) => i.type === 'product');
    },
  },
  methods: {
    onSelectTag(tag) {
      this.$emit('select-tag', tag);
    },
    onFocusItem(item) {
      this.$emit('focus-item', item);
    },
  },
};
</script>
