<template>
  <td
    class="data-table--body-cell is-right-aligned"
    v-on="eventListeners"
  >
    <ValueRangeRibbon
      class="data-table--value-range"
      :value="value.original"
    />
  </td>
</template>

<script>
import ValueRangeRibbon from '@/components/ValueRangeRibbon/Component.vue';

export default {
  name: 'ValueRangeColumn',
  components: {
    ValueRangeRibbon,
  },
  props: {
    value: {
      type: Object,
      default: () => ({ original: '', translation: '' }),
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>