<template>
  <div
    v-if="stats.total > 0"
    class="summary--line"
  >
    <Icon
      class="summary--icon for-create"
      :class="iconClass"
      :name="iconName"
      variant="solid"
      :fixed-width="true"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'PublicationsUploadModalSummaryForAction',
  props: {
    controller: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  computed: {
    stats() {
      return this.controller.statsForAction(this.action);
    },
    iconName() {
      return {
        create: 'file-plus',
        replace: 'file-edit',
      }[this.action];
    },
    iconClass() {
      return `for-${this.action}`;
    },
    text() {
      if (this.controller.uploadStarted) {
        const totalText = this.$t(`${this.textKeyPrefix}_progress_total`, this.stats.total);
        return this.$t(`${this.textKeyPrefix}_progress`, this.stats.done, { total: totalText });
      }
      return this.$t(`${this.textKeyPrefix}_preview`, this.stats.total);
    },
    textKeyPrefix() {
      return `apps.publications.upload_modal.summary.${this.action}`;
    },
  },
};
</script>
