<template>
  <button
    class="header--title"
    @click="$emit('action')"
  >
    {{ title }}
  </button>
</template>

<script>

export default {
  name: 'NotationAnalysesListItemHeader',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  emits: ['action'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>