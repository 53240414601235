<template>

    <component
      :is="buttonTagName"
      :href="href"
      type="button"
      class="dropdown-menu--item dropdown-menu--button"
      :class="{'is-disabled': disabled}"
      :disabled="disabled"
      :data-closes-popup="closesPopup"
      @click="$emit('click', $event)"
    >
      <span
        v-if="icon || $slots.icon"
        class="dropdown-menu--button-icon"
      >
        <slot name="icon">
          <Icon
            v-if="icon"
            :name="icon"
            :variant="iconVariant"
            :fixed-width="true"
            class="dropdown-menu--icon"
          />
        </slot>
      </span>
      <slot />
    </component>
</template>

<script>
export default {
  name: 'DropdownMenuButton',
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconVariant: {
      type: String,
      default: 'solid',
    },
    disabled: {
      type: Boolean,
      default: null, // Avoid disabled="false" attributes
    },
    closesPopup: {
      type: Boolean,
      default: true,
    },
    href: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonTagName() {
      if (this.href) {
        return 'a'
      }

      return 'button'
    }
  },
  emits: ['click'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>