let globalCounter = 0;

const HasUid = {
  data() {
    const myUid = globalCounter;
    globalCounter += 1;
    return {
      uid: `uid-${myUid}`,
    };
  },
};

export default HasUid;
