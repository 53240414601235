<template>
  <ul class="kpi-list">
    <li
      v-for="kpi in kpiList"
      :key="kpi.title"
      class="kpi-list--item"
    >
      <NotationDetailsKpiListItem
        :title="kpi.title"
        :tooltip="kpi.tooltip"
      >
        <KeyPerformanceIndicator
          v-if="showAbsolutePrices"
          :value="kpi.value"
          :base-value="kpi.base"
          :base-value-precision="baseValuePrecision"
        />
        <KeyPerformanceIndicator
          v-else
          :value="kpi.value"
        />
      </NotationDetailsKpiListItem>
    </li>

    <li class="kpi-list--item">
      <NotationDetailsKpiListItem
        :title="$t('components.notation_kpi_list.current_value_range')"
        :tooltip="$t('components.notation_kpi_list.tooltips.current_value_range')"
      >
        <ValueRangeRibbon
          class="kpi-list--value-range-ribbon"
          :value="keyPerformanceIndicators.current_value_range"
        />
      </NotationDetailsKpiListItem>
    </li>
  </ul>
</template>

<script>
import moment from 'moment';
import KeyPerformanceIndicator from '@/components/KeyPerformanceIndicator/Component.vue';
import ValueRangeRibbon from '@/components/ValueRangeRibbon/Component.vue';
import NotationDetailsKpiListItem from './Item/Component.vue';

const getRelevantKpisForTimescope = (timescope) => ({
  daily: ['last_year', 'last_month', 'last_week', 'last_day'],
  weekly: ['last_year', 'last_month', 'last_week'],
  biweekly: ['last_year', 'last_month', 'last_week'],
  monthly: ['last_year', 'last_month'],
  quarterly: ['last_year', 'last_month'],
  yearly: ['last_year'],
})[timescope] || [];

export default {
  name: 'NotationDetailsKpiList',
  components: {
    KeyPerformanceIndicator,
    ValueRangeRibbon,
    NotationDetailsKpiListItem,
  },
  props: {
    timescope: {
      type: String,
      default: null,
    },
    keyPerformanceIndicators: {
      type: Object,
      default: () => {},
    },
    baseValuePrecision: {
      type: Number,
      default: 2,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedDateTitle() {
      const date = this.keyPerformanceIndicators.custom_date?.date;
      return this.$t('components.notation_kpi_list.custom_date', {
        date: moment(date).format('ll'),
      });
    },
    visibleKPIs() {
      return getRelevantKpisForTimescope(this.timescope);
    },
    hasCustomKpis() {
      return !!this.keyPerformanceIndicators.custom_date;
    },
    customKpis() {
      if (!this.hasCustomKpis) return null;
      return [{
        title: this.selectedDateTitle,
        tooltip: this.$t('components.notation_kpi_list.tooltips.custom_dod_percent'),
        value: this.keyPerformanceIndicators.custom_date.growth,
        base: this.keyPerformanceIndicators.custom_date.value,
      }, {
        title: this.$t('attributes.notation.custom_dod_percent_over_avg'),
        tooltip: this.$t('components.notation_list.tooltips.custom_dod_percent_over_avg'),
        value: this.keyPerformanceIndicators.custom_date_avg.growth,
        base: this.keyPerformanceIndicators.custom_date_avg.value,
      }];
    },
    kpiList() {
      const result = this.visibleKPIs.map((kpi) => ({
        title: this.$t(`components.notation_kpi_list.${kpi}`),
        tooltip: this.$t(`components.notation_kpi_list.tooltips.${kpi}`),
        value: Number.parseFloat(this.keyPerformanceIndicators[kpi]?.growth),
        base: Number.parseFloat(this.keyPerformanceIndicators[kpi]?.value),
      }));
      if (this.customKpis) result.push(...this.customKpis);
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>