<template>
  <ul>
    <li
      v-for="item in items"
      :ref="item.id"
      :key="item.id"
    >
      <button
        class="node-list--button"
        :class="{
          'is-focussed': isFocussedItem(item),
          'is-selected': isSelected(item),
        }"
        @mouseenter="onFocusItem($event, item)"
        @mouseleave="onBlurItem($event, item)"
      >
        <span class="node-list--item-name">
          {{ item.name }}
        </span>
        <Icon
          class="node-list--caret"
          name="caret-right"
          variant="solid"
        />
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DashboardAndClusterListNodeList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    focussedId: {
      type: [String, Number],
      default: null,
    },
    selection: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['focus-item', 'blur-item'],
  methods: {
    isFocussedItem(item) {
      return item.id === this.focussedId;
    },
    isSelected(item) {
      return item.id === this.selection?.cluster?.id;
    },
    onFocusItem(nativeEvent, item) {
      this.$emit('focus-item', { item, nativeEvent });
    },
    onBlurItem(nativeEvent, item) {
      this.$emit('blur-item', { item, nativeEvent });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>