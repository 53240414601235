<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <div class="link-with-map-link">
      <WithTooltip :tooltip="value.tooltip">
        <a
          class="data-table--link"
          :href="value.url"
          @click="$event.stopPropagation()"
        >
          {{ value.text }}
        </a>
      </WithTooltip>
      <div class="icon-links">
        <WithTooltip :tooltip="t('apps.human_rights.commodity_risk_map.button_tabs.map')">
          <a
            class="data-table--icon-link"
            :href="value.mapUrl"
          >
            <Icon
              name="globe-americas"
              variant="solid"
            />
          </a>
        </WithTooltip>

        <WithTooltip :tooltip="t('apps.human_rights.country_portraits.link')">
          <a
              class="data-table--icon-link"
              :href="value.portraitUrl"
          >
            <Icon
                name="image"
                variant="solid"
            />
          </a>
        </WithTooltip>
      </div>
    </div>
  </td>
</template>

<script setup>
import WithTooltip from '@/components/generic/WithTooltip/Component.vue';
import { useI18n } from 'vue-i18n';
import Icon from '../../../generic/Icon.vue';

const { t } = useI18n();

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  eventListeners: {
    type: Object,
    default: () => ({}),
  },
});

</script>

<style lang="scss" scoped>
.link-with-map-link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .data-table--icon-link {
    padding: 4px 6px;
  }

  .icon-links {
    display: flex;
    gap: 8px;
  }
}

@media print {
  .link-with-map-link .icon-links {
    display: none;
  }
}
</style>
