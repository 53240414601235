<template>
  <button
    role="checkbox"
    type="button"
    @click="onToggle"
  >
    <Icon
      :name="name"
      :variant="variant"
      :fixed-width="fixedWidth"
    />
  </button>
</template>

<script>
export default {
  name: 'ToggleIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
    'update:modelValue',
  ],
  computed: {
    variant() {
      return this.modelValue ? 'solid' : 'regular';
    },
  },
  methods: {
    onToggle(event) {
      this.$emit('click', event);
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>
