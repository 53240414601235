<template>
  <p class="trade-flow--empty-data-hint">
    {{ $t('components.trade_flows.no_data_hint') }}
  </p>
</template>

<script>
export default {
  name: 'TradeFlowsWithoutData',
};
</script>
