import { reactive, toRaw, unref } from "vue";
import { compareTableValues } from "../../../helpers/SortHelpers";

const SORT_ASC = "asc";
const SORT_DESC = "desc";

// generic table controller for the hr score table used in the country country and sector portraits
class PortraitsHRScoreTableController {
  get isLoading() {
    if (this._state) {
      return this._state.isLoading;
    }
    return null;
  }

  set isLoading(value) {
    if (this._state) {
      this._state.isLoading = value;
    }
  }

  get filters() {
    if (this._state) {
      return this._state.filters;
    }
    return null;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    if (this._state) {
      return this._state.selection;
    }
    return null;
  }

  get notations() {
    return this._state?.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get hiddenRows() {
    return this._state?.hiddenRows;
  }

  set hiddenRows(value) {
    this._state.hiddenRows = value;
  }

  get notationListModel() {
    if (this._state) {
      return this._state.notationListModel;
    }
    return null;
  }

  get hiddenRowsModel() {
    if (this._state) {
      return this._state.hiddenRowsModel;
    }
    return null;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  constructor(translator) {
    this._translator = translator;
  }

  init({ subjects, hrDimensionsForTable, columnTranslationKey }) {
    if (typeof subjects !== "undefined" && Object.keys(subjects).length === 6) {
      this.hrDimensionsForTable = hrDimensionsForTable;
      this.columnTranslationKey = columnTranslationKey;
      this._subjects = subjects;
      this._columns = this._getColumnDefinition();
      this._hiddenColumns = this._getHiddenColumnDefinition();
      this._state = reactive({
        isLoading: true,
        filters: {},
        sorting: null,
        selection: {
          selectionMode: "none",
          canSelect: false,
        },
        notations: this.getNotations(),
        hiddenRows: this.getHiddenRows(),
        notationListModel: {
          columns: this._columns,
          visibleColumns: this._columns,
          createRow: (notation) => {
            return this._columns.map(({ key, type, ...column }, index) => ({
              index,
              key,
              type,
              filterOptions: column.filterOptions,
              displayName: column.displayName,
              visible: true,
              hasHiddenRows: true,
              value: column.valueBuilder ? column.valueBuilder(notation[index]) : notation[key],
              classes: {},
              additionalData: {},
              eventListeners: {
                click: (e) => {
                  // Making the tr aware of the expanded state for showing hidden rows and rotating arrow
                  const tr = e.target
                    .closest("tr")
                  tr.classList.toggle("expanded")
                  tr.nextElementSibling.querySelector(".hidden-rows")
                    .classList.toggle("hidden-rows--hidden");
                },
              },
            }));
          },
        },
        hiddenRowsModel: {
          columns: this._hiddenColumns,
          visibleColumns: this._hiddenColumns,
          createRow: (notation) => {
            return this._hiddenColumns.map(({ key, type, ...column }, index) => ({
              index,
              key,
              type,
              filterOptions: column.filterOptions,
              displayName: column.displayName,
              visible: true,
              hasHiddenRows: true,
              value: column.valueBuilder ? column.valueBuilder(notation[index]) : notation[key],
              classes: {},
              additionalData: {},
            }));
          },
        },
      });
    }
    this.isLoading = false;
  }

  updateSubjects(subjects) {
    this._subjects = subjects;
    this.columns = this._getColumnDefinition(this._subjects[0][1].hr_scores);
    this.notations = this.getNotations();
    this.hiddenRows = this.getHiddenRows();
    this.isLoading = false;
  }

  // returns [codes, names, scores]
  getNotations() {
    const rows = [];
    this.hrDimensionsForTable.numbers.forEach((numberElement, index) => {
      const row = [numberElement, this.hrDimensionsForTable.names[index]];
      Object.values(this._subjects).forEach((subject) => {
        row.push({
          total: subject[1].hr_scores[index],
          dimensions: subject[1].hr_scores,
          withFlagIcon: typeof numberElement !== "number",
        }); // only show flag for the General HR Score
      });
      rows.push(row);
    });
    return rows;
  }

  getHiddenRows() {
    const rows = [];
    this.hrDimensionsForTable.indicators.forEach((indicators, scoreIndex) => {
      const indicatorRow = [];
      indicators.codes.forEach((code, indicatorIndex) => {
        const row = [
          { text: code, tooltip: indicators.infos[indicatorIndex] },
          { text: indicators.names[indicatorIndex], tooltip: indicators.infos[indicatorIndex] },
        ];
        Object.values(this._subjects).forEach((subject) => {
          const hrScore = subject[1].hr_scores[scoreIndex];
          if (hrScore?.indicators) {
            row.push(hrScore.indicators[indicatorIndex]);
          } else {
            row.push("");
          }
        });
        indicatorRow.push(row);
      });
      rows.push(indicatorRow);
    });
    return rows;
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  _getSubjectName(subjectId) {
    return Object.values(this._subjects).find((subject) => subject[0] === subjectId )[1].name;
  }

  _getSubjectColumnDefinitions() {
    const newColumn = [];
    Object.values(this._subjects).map((subject, index) => {
      const subjectName = this._getSubjectName(subject[0]);

      newColumn.push({
        key: subjectName,
        visible: true,
        displayName: subjectName,
        type: "totalHrScoresColumn",
        valueBuilder: (notation) => {
          return notation;
        },
      });
    });
    return newColumn;
  }

  _getColumnDefinition() {
    const subjectColumnDefinitions = this._getSubjectColumnDefinitions();
    return [
      {
        key: "numbers",
        visible: true,
        displayName: "",
        type: "text",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: this._translator(`${this.columnTranslationKey}.title`),
        visible: true,
        displayName: this._translator(`${this.columnTranslationKey}.title`),
        type: "textColumnWithIcon",
        valueBuilder: (notation) => {
          return {
            notation,
            text: notation,
            name: "fa-angle-down",
            modifiers: "fa-xl no-interaction",
          };
        },
      },
      ...subjectColumnDefinitions,
    ];
  }

  _getHiddenColumnDefinition() {
    return [
      {
        key: "numbers",
        visible: true,
        displayName: "",
        type: "textWithTooltip",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "name",
        visible: true,
        displayName: "",
        type: "textWithTooltip",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_1",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_2",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_3",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_4",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_5",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
      {
        key: "subject_6",
        visible: true,
        type: "valueWithContext",
        valueBuilder: (notation) => {
          return notation;
        },
      },
    ];
  }

  updateNotationsAndUserState() {
    if (this.sorting) {
      this.sortColumn();
    } else {
      this.notations = [...this._subjects];
    }
  }

  sortColumn() {
    const { direction, column: columnKey } = this.sorting;
    const notationValueAccessor = (notation) => {
      let value;
      switch (columnKey) {
        case "trade_flow_percentage":
        case "rank":
          value = +notation[columnKey];
          break;
        case "name":
          value = notation[columnKey];
          break;
        default:
          value = +notation[columnKey]?.value;
          break;
      }

      return value || 0;
    };

    this.notations = compareTableValues(this.notations, notationValueAccessor, direction);
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;

    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: SORT_ASC };
      return;
    }
    if (this._state?.sorting?.direction === SORT_ASC) {
      this._state.sorting.direction = SORT_DESC;
    } else {
      this._state.sorting = null;
    }
  }
}

export default PortraitsHRScoreTableController;
