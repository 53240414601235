<template>
  <tr
    class="data-table--body-row"
    :class="conditionalClasses"
    v-exclude-from-print="excludeFromPrint"
    @click="$emit('select', notation)"
  >
    <template
      v-for="cell in cells"
      :key="cell.key"
    >
      <GenericColumn
        v-if="cell.visible"
        :value="cell.value"
        :column-type="cell.type"
        :data-role="cell.key"
        :column-key="cell.key"
        :event-listeners="cell.eventListeners"
        :additional-data="cell.additionalData"
        :classes="cell.classes"
      />
    </template>
  </tr>
  <template v-if="hasHiddenRows" v-exclude-from-print="excludeFromPrint">
    <tr :key="getUUID">
      <td colspan="8">
        <tbody :class="conditionalHiddenClasses">
          <template
            v-for="row in hiddenCells">
            <tr>
              <template v-for="cell in row">
                <GenericColumn
                  v-if="cell.visible"
                  :value="cell.value"
                  :column-type="cell.type"
                  :data-role="cell.key"
                  :column-key="cell.key"
                  :event-listeners="cell.eventListeners"
                  :additional-data="cell.additionalData"
                  :classes="cell.classes"
                />
              </template>
            </tr>
          </template>
        </tbody>
      </td>
    </tr>
  </template>
</template>

<script>
import GenericColumn from "./Columns/GenericColumn.vue";
import Turbolinks from "turbolinks";

export default {
  name: "TableRow",
  components: { GenericColumn },
  props: {
    model: {
      type: Object,
      required: true
    },
    hiddenRowsModel: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    notation: {
      type: Object,
      required: true
    },
    hiddenRows: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isSelectedForComparison: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    excludeFromPrint: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "select"
  ],
  computed: {
    cells() {
      return this.model.createRow(this.notation);
    },
    hiddenCells() {
      return this.hiddenRows.map((row) => this.hiddenRowsModel.createRow(row));
    },
    hasHiddenRows() {
      return this.cells[0].hasHiddenRows;
    },
    conditionalHiddenClasses() {
      const classes = [];
      if (this.hasHiddenRows) {
        classes.push("hidden-rows hidden-rows--hidden");
      }
      return classes.join(" ");
    },
    conditionalClasses() {
      const classes = [];
      if (this.isSelected) {
        classes.push("is-selected");
      } else if (this.isSelectedForComparison) {
        classes.push("is-selected-for-comparison");
      }

      if (this.canClick) {
        classes.push("can-click");
      }

      return classes.join(" ");
    }
  },
  methods: {
    getUUID() {
      return Turbolinks.uuid();
    }
  }
};
</script>
