<template>
  <div class="hr-dimensions">
    <div class="hr-dimensions__info">
      <div class="hr-dimensions__row hr-dimensions__info-item">
        <strong>{{ $t('components.human_rights_risk_tooltip.headline') }}</strong>
      </div>
      <div class="hr-dimensions__row hr-dimensions__info-item">
        <div>
          <strong v-html="subtitle"></strong>
          <p class="mt-xs" v-if="text" v-html="text"></p>
        </div>
        <span v-if="props.data.isCountryOfDemand && props.data.balance" 
              :style="`color: ${IMPORT_EXPORT_COLOR[props.data.balance]}`">
          {{ t(`${props.data.balanceKey}.${props.data.balance}`) }}
        </span>
      </div>
      <div
        v-if="props.data.additionalText"
        v-for="lines in props.data.additionalText"
        :key="lines"
        class="hr-dimensions__row hr-dimensions__info-item"
        v-html="DOMPurify.sanitize(lines)"
      />
    </div>
    <div class="hr-dimensions__list">
      <div class="hr-dimensions__row hr-dimensions__list-item">
        <div class="hr-dimensions__name" v-html="total.name"></div>
        <div class="hr-dimensions__score">
          <img class="hr-dimensions__score-icon" :src="total.icon"/>
          <span class="hr-dimensions__score-body">{{ convertToLocale(total.value, locale, {minimumFractionDigits: 1, maximumFractionDigits: 1}) }}</span>
          <img
            v-if="hrScoreFlagIcon"
            class="hr-dimensions__score-flag"
            :src="hrScoreFlagIcon"
          />
        </div>
      </div>
      <hr>
      <div
        v-for="dimension of dimensions"
        :key="dimension.key"
        class="hr-dimensions__row hr-dimensions__list-item"
        :class="{'hr-dimensions__list-item--danger': dimension.value >= 3.1}"
      >
        <div class="hr-dimensions__name">
          {{ dimension.name }}
        </div>
        <div class="hr-dimensions__score">
          <img :src="dimension.icon"/>
          <span class="hr-dimensions__score-body">{{ parseFloat(dimension.value).toLocaleString(locale, { minimumFractionDigits: 1 })}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useHrScoreUtil } from './useHrScoreUtil';
import { IMPORT_EXPORT_COLOR } from '../../../../../config/colors';
import DOMPurify from 'dompurify';
import convertToLocale from "../../../../../utils/ConvertToLocaleString";

const { t, locale } = useI18n();

const props = defineProps(['data']);

const {
  data: hrScoreData,
  hrScoreFlagIcon,
} = useHrScoreUtil(props.data.hrScores);

const { dimensions, total } = hrScoreData;

const subtitle = computed(() => {
  if (props.data.currentCountry) {
    console.warn('passing currentCountry for the tooltip title is deprecated')
    return props.data.currentCountry
  }
  return props.data.title
})

const text = computed(() => {
  return props.data.text
})
</script>

<style lang="scss">
.hr-dimensions__row {
  border-radius: 2px;
  margin: 0 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  min-width: 310px;
}

.hr-dimensions {
  min-width: 310px;
  padding-top: 5px;

  &__name {
    max-width: 320px;
  }
  &__info-item {
    padding: 0 5px;

    div {
      justify-content: space-between;
    }
  }

  &__list-item {
    padding: 5px;

    &--danger {
      background-color: #FFE0E0;
    }

    &:nth-child(3){
      margin-top: 10px;
    }
  }

  &__score {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 5px;

    &-body {
      min-width: 25px;
      text-align: right;
    }

    &-flag {
      position: absolute;
      right: -20px;
    }
  }
}
</style>
