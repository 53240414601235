<template>
  <NavigationItem
    :name="name"
    :icon="icon"
    :highlight="highlight"
    :show-caret="true"
    :tooltip="tooltip"
    :custom-icon="customIcon"
    :custom-icon-classes="customIconClasses"
    :custom-icon-styles="customIconStyles"
    @click="toggleDropdown"
    @click-outside="closeDropdown"
  >
    <transition name="dropdown-animation">
      <div
        v-if="showDropdown"
        class="app-navigation--dropdown"
        :class="{ 'auto-width': autoWidth }"
      >
        <slot />
      </div>
    </transition>
  </NavigationItem>
</template>

<script>
import NavigationItem from './NavigationItem.vue';

export default {
  name: 'NavigationDropdownItem',
  components: {
    NavigationItem,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    customIcon: {
      type: String
    },
    customIconClasses: {
      type: String,
      required: false,
      default: '',
    },
    customIconStyles: {
      type: Object,
      required: false,
      default: null,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown(event) {
      event.preventDefault();
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>
