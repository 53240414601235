<template>
  <div class="fulltext-search">
    <input
      ref="input"
      :value="modelValue"
      aria-labelledby="ariaLabelledby"
      type="text"
      class="fulltext-search--input"
      :placeholder="placeholder"
      @input="onInput"
    >
    <button
      type="button"
      :name="iconName"
      @click="onIconClicked"
    >
      <Icon
        :name="iconName"
        variant="regular"
        :fixed-width="true"
      />
    </button>
  </div>
</template>

<script>

export default {
  name: 'FulltextSearch',
  props: {
    ariaLabelledby: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    iconName() {
      return this.modelValue !== '' ? 'times' : 'search';
    },
  },
  methods: {
    onIconClicked() {
      this.$emit('update:modelValue', '');
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>