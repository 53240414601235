import { ref, watch } from 'vue';
import { WORLD_VALUE } from '../../Intro/constants/world-value';
import { getQueryParam, getQueryParamAsNumber, setQueryParam } from '../../../services/UrlStateStore';
import QUERY_PARAM from '../../Intro/constants/commodity-query-params';

const getOptionByValue = (options, value, fallback = null) => options.find((o) => o.value === value) || fallback;

export const useCommodityDropdowns = ({
  hrDimensionsForSelect,
  yearsForSelect,
  importLimitsForSelect,
  exportLimitsForSelect,
  yearsDefaultValue,
}) => {
  const lastLimitIndex = importLimitsForSelect.length - 1;

  const selectedCountry = ref(+getQueryParam(QUERY_PARAM.COUNTRY, WORLD_VALUE));
  const selectedClassificationId = ref(parseInt(getQueryParam(QUERY_PARAM.CLASSIFICATION, null)) || null);
  const selectedSectorClassificationId = ref(parseInt(getQueryParam(QUERY_PARAM.SECTOR_CLASSIFICATION, null)) || null);
  const selectedDimension = ref(getOptionByValue(hrDimensionsForSelect, +getQueryParam(QUERY_PARAM.DIMENSION), hrDimensionsForSelect[0]));
  const selectedYear = ref(getOptionByValue(yearsForSelect, +getQueryParam(QUERY_PARAM.YEAR, yearsDefaultValue), yearsForSelect[0]));
  const selectedImportLimit = ref(
    getOptionByValue(
      importLimitsForSelect,
      getQueryParamAsNumber(QUERY_PARAM.LIMIT),
      importLimitsForSelect[lastLimitIndex],
    ),
  );
  const selectedExportLimit = ref(
    getOptionByValue(
      exportLimitsForSelect,
      getQueryParamAsNumber(QUERY_PARAM.LIMIT),
      exportLimitsForSelect[lastLimitIndex],
    ),
  );

  const classificationOptions = ref([]);

  watch(selectedCountry, (country) => setQueryParam(QUERY_PARAM.COUNTRY, country));
  watch(selectedDimension, (dimension) => setQueryParam(QUERY_PARAM.DIMENSION, dimension.value));
  watch(selectedYear, (year) => setQueryParam(QUERY_PARAM.YEAR, year.value));
  watch(selectedClassificationId, (classification) => setQueryParam(QUERY_PARAM.CLASSIFICATION, classification));
  watch(selectedSectorClassificationId, (classification) => {
    selectedClassificationId.value = null;
    setQueryParam(QUERY_PARAM.SECTOR_CLASSIFICATION, selectedSectorClassificationId.value);
  });
  watch(selectedImportLimit, (importLimit) => setQueryParam(QUERY_PARAM.LIMIT, importLimit.value));
  watch(selectedExportLimit, (exportLimit) => setQueryParam(QUERY_PARAM.LIMIT, exportLimit.value));

  return {
    selectedCountry,
    selectedDimension,
    selectedYear,
    selectedImportLimit,
    selectedExportLimit,
    selectedClassificationId,
    selectedSectorClassificationId,
    classificationOptions,
  };
};
