const subscribers = {};

const getRandomId = () => `${new Date().getTime()}-${Math.random()}`;

const off = (eventName, callback) => {
  if (!subscribers[eventName]) return;
  subscribers[eventName] = subscribers[eventName].filter((cb) => cb !== callback);
};

class EventBus {
  constructor(id) {
    this.id = id || getRandomId();
  }

  on(eventName, callback, { skipOwnEvents = true } = {}) {
    const conditionalCallback = (payload) => {
      const isOwnEvent = payload.senderId === this.id;
      if (skipOwnEvents && isOwnEvent) return;
      callback(payload.event);
    };
    subscribers[eventName] = subscribers[eventName] || [];
    subscribers[eventName].push(conditionalCallback);
    return {
      off: () => { off(eventName, conditionalCallback); },
    };
  }

  static on(eventName, callback) {
    return new EventBus().on(eventName, callback);
  }

  emit(event) {
    const payload = { event, senderId: this.id };
    if (!subscribers[event.EventName]) return;
    subscribers[event.EventName].forEach((callback) => callback(payload));
  }

  static emit(event) {
    new EventBus().emit(event);
  }
}

export default EventBus;
