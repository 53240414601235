<template>
  <ButtonWithDropdown icon="calendar-alt">
    <template #dropdown>
      <DropdownMenu>
        <DropdownMenuButton @click="onMaxSelected">
          {{ $t('components.date_range_button.max_months') }}
        </DropdownMenuButton>
        <DropdownMenuButton
          v-for="range in ranges"
          :key="range"
          @click="onSelected(range)"
        >
          {{ $t(`components.date_range_button.${range}_months`) }}
        </DropdownMenuButton>
      </DropdownMenu>
    </template>
  </ButtonWithDropdown>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateRangeButton',
  emits: ['select', 'select-max'],
  data() {
    return {
      ranges: [36, 24, 18, 12],
    };
  },
  methods: {
    onMaxSelected() {
      this.$emit('select-max');
    },
    onSelected(months) {
      const startDate = moment().subtract(months, 'months').startOf('day');
      this.$emit('select', startDate);
    },
  },
};
</script>
