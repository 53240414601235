<template>
  <div class="bubble-chart">
    <canvas
      :id="uid"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import Chart from 'chart.js';
import HasUid from '@/mixins/HasUid';
import AnnotateChart from '@/plugins/AnnotateChartBeforeDraw';
import ElementLabelChart from '@/plugins/ElementLabelChart';

Chart.defaults.global.defaultFontColor = '#000000';

export default {
  name: 'BubbleChart',
  mixins: [HasUid],
  props: {
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    chartAnnotations: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    graphLimits: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['select-date'],
  computed: {
    chartOptions() {
      const defaultOptions = {
        onClick: this.onClick.bind(this),
      };
      return { ...defaultOptions, ...this.options };
    },
  },
  watch: {
    chartData() {
      this.renderChart();
    },
    options() {
      this.renderChart();
    },
  },
  beforeMount() {
    // The chartInstance has to be non-reactive to prevent
    // errors when the chart gets re-rendered. Therefore,
    // we don't use a data attribute.
    this.chartInstance = null;
  },
  beforeUnmount() {
    this.destroyChart();
  },
  mounted() {
    this.renderChart();
  },
  updated() {
    this.renderChart();
  },
  methods: {
    destroyChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },
    renderChart() {
      this.destroyChart();
      const chartElement = document.getElementById(this.uid);
      if (!chartElement) return;
      const ctx = chartElement.getContext('2d');
      if (!ctx) return;
      Chart.Tooltip.positioners.cursor = (elements, position) => position;
      this.chartInstance = new Chart(ctx, {
        type: 'bubble',
        data: this.chartData,
        options: this.chartData.options,
        plugins: [AnnotateChart, ElementLabelChart],
      });
    },
    onClick() {
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>