const NullKpi = () => ({
  date: null,
  value: null,
  growth: null,
});

// returns null for all notation properties
const NullNotation = () => ({
  id: null,
  is_watched: null,
  dashboard_id: null,
  dashboard_name: null,
  name: null,
  code: null,
  source: null,
  dataset: { id: null, name: null },
  unit: null,
  timescope: null,
  flow: null,
  attribute_unit: null,
  reporter: null,
  partner: null,
  attribute_economy: null,
  indicator: null,
  latest_date: null,
  latest_value: null,
  current_value_range: null,
  class_system: null,
  class_code: null,
  own_calculation: null,
  sub_term: null,
  key_performance_indicators: {
    last_year: NullKpi(),
    last_month: NullKpi(),
    last_week: NullKpi(),
    last_day: NullKpi(),
  },
});

export default NullNotation;
