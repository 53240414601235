<template>
  <div
    class="product-group-trend-icon"
    :class="trendClasses"
  >
    <div
      v-if="isUnread"
      class="product-group-trend-icon--unread-marker"
      :aria-label="$t('generic.unread')"
      aria-role="status"
    />
  </div>
</template>

<script>
export default {
  name: 'ProductGroupTrendIcon',
  props: {
    trend: {
      type: String,
      required: true,
      validator: (value) => ['none', 'good', 'neutral', 'bad'].includes(value),
    },
    variant: {
      type: String,
      required: true,
      validator: (val) => ['badge', 'list', 'title', 'menu'].includes(val),
    },
    isUnread: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    trendClasses() {
      const classes = [`for-${this.variant}`, `is-${this.trend}`];
      return classes.join(' ');
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>