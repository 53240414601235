<script>
import { getFlagIconByCount } from "@/components/NotationList/NotationTable/Columns/TopFiveCountry/hrScoreFlagIcon"

export default {
  props: {
    flagCount: {
      type: Number,
      required: true
    },

    showNumber: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hrScoreFlagIcon() {
      return getFlagIconByCount(this.flagCount)
    }
  }
}
</script>

<template>
  <div class="flags-cell-content">
    <img v-if="hrScoreFlagIcon" :src="hrScoreFlagIcon" />
    <span v-else class="flag-spacer"></span>

    <span v-if="showNumber">{{ flagCount }}</span>
  </div>
</template>

<style lang="scss">
.flags-cell-content {
  width: 30px;
  display: flex;
  justify-content: space-evenly;
  img, .flag-spacer {
    width: 15px;
    margin-right: 5px;
  }
}
</style>
