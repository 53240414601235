<template>
  <SplitPageLayout screen-size="narrow">
    <template #top>
      <div
        v-exclude-from-print
        class="notation-analyses--header"
      >
        <h1 class="page-header--title">
          {{ $t('page_titles.notation_analyses') }}
        </h1>
        <ButtonWithDropdown
          variant="primary"
          icon="caret-down"
        >
          {{ $t('apps.notation_analyses.create_chart') }}
          <template #dropdown>
            <DropdownMenu>
              <DropdownMenuButton
                icon="chart-line"
                @click="openMultiLineChartConfig()"
              >
                {{ $t('apps.notation_analyses.multi_line_chart') }}
              </DropdownMenuButton>
              <DropdownMenuButton
                icon="chart-line"
                @click="openSeasonLineChartConfig()"
              >
                {{ $t('apps.notation_analyses.season_line_chart') }}
              </DropdownMenuButton>
            </DropdownMenu>
          </template>
        </ButtonWithDropdown>
      </div>
    </template>
    <template #bottom>
      <NotationAnalysesList
        :saved-analyses="savedAnalyses"
        :has-more-results="hasMoreResults"
        :is-loading="isLoading"
        :has-error="hasError"
        @delete-analysis="deleteAnalysis"
        @duplicate-analysis="duplicateAnalysis"
        @load-next-page="loadNextPage"
      />
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import { computed } from 'vue';
import Turbolinks from 'turbolinks';
import { useNotationAnalysesStore } from './store';
import NotationAnalysesList from './NotationAnalysesList/Component.vue';

export default {
  name: 'NotationAnalyses',
  components: {
    SplitPageLayout,
    NotationAnalysesList,
  },
  setup() {
    const store = useNotationAnalysesStore();

    store.initialize();

    const deleteAnalysis = (savedAnalysisId) => {
      store.deleteAnalysis(savedAnalysisId);
    };

    const duplicateAnalysis = (analysis) => {
      store.duplicateAnalysis(analysis);
    };

    const loadNextPage = () => {
      store.loadNextPage();
    };

    return {
      savedAnalyses: computed(() => store.savedAnalyses),
      isLoading: computed(() => store.isLoading),
      hasError: computed(() => store.hasError),
      createMultiLineChartUrl: computed(() => store.createMultiLineChartUrl),
      createSeasonLineChartUrl: computed(() => store.createSeasonLineChartUrl),
      hasMoreResults: computed(() => store.hasMoreResults),
      deleteAnalysis,
      loadNextPage,
      duplicateAnalysis,
    };
  },
  methods: {
    openMultiLineChartConfig() {
      Turbolinks.visit(this.createMultiLineChartUrl);
    },
    openSeasonLineChartConfig() {
      Turbolinks.visit(this.createSeasonLineChartUrl);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>