const matchesOneOf = (actual, expected) => (Array.isArray(expected)
  ? expected.includes(actual)
  : expected === actual);

const matchesResource = (actual, expected) => {
  if (expected === undefined) return true;
  return matchesOneOf(actual, expected);
};

export default class RoutingParams {
  constructor({ controller, action, id } = {}) {
    this.controller = controller;
    this.action = action;
    this.resourceID = parseInt(id, 10);
  }

  matches(actions, resourceIDs) {
    return this.controller === 'pages'
      && matchesOneOf(this.action, actions)
      && matchesResource(this.resourceID, resourceIDs);
  }
}
