import axios from 'axios';
import Cache from '@/services/Cache';
import { groupElementsBy } from '@/utils/ArrayUtilities';

const CurrencyCache = new Cache();

const fetchUnitsFromApi = ({ categories }) => axios.get('/api/units/selectables', {
  params: {
    categories,
  },
}).then((response) => response.data);

const unitsGroupedByCategory = (apiParameters) => fetchUnitsFromApi(apiParameters)
  .then((units) => groupElementsBy({ elements: units, groupingFn: (unit) => unit.category }));

const currencies = () => {
  const daysSinceEpoch = Math.floor(new Date() / 1000 / 60 / 24);
  return CurrencyCache.fetch(daysSinceEpoch, () => fetchUnitsFromApi({ categories: ['currency'] }));
};

export default {
  currencies,
  units: fetchUnitsFromApi,
  unitsGroupedByCategory,
};
