<template>
  <div
    class="trade-flow--chart-caption"
  >
    <h3 class="trade-flow--chart-label">
      {{ caption }}
      <IconTooltipComponent
        v-if="tooltip"
        :tooltip="tooltip"
      />
    </h3>
    <div
      v-if="unit"
      class="trade-flow--unit-and-sort-by"
    >
      <p class="trade-flow--chart-unit-label">
        {{ $t('components.trade_flows.indicator_in_unit', { indicator, unit }) }}
      </p>
      <button
        v-if="showSortIndicator"
        class="trade-flow--sort-button"
        type="button"
        :class="{'is-selected': sortOrder !== null}"
        :aria-label="$t('components.trade_flows.sort_by_indicator', { indicator })"
        @click="onSortClicked"
      >
        <Icon
          class="trade-flow--sort-icon"
          :name="sortOrderIcon"
          variant="regular"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeFlowChartColumnHeader',
  props: {
    indicator: {
      type: String,
      required: true,
    },
    flow: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
    showSortIndicator: {
      type: Boolean,
      default: false,
    },
    sortOrder: {
      type: String,
      default: null,
      validator: (order) => [null, 'asc', 'desc'].includes(order),
    },
    defaultSortOrder: {
      type: String,
      default: 'desc',
      validator: (order) => ['asc', 'desc'].includes(order),
    },
  },
  emits: ['sort'],
  computed: {
    nextSortOrder() {
      if (this.sortOrder === null) {
        return this.defaultSortOrder;
      }
      return this.sortOrder === 'desc' ? 'asc' : 'desc';
    },
    sortOrderIcon() {
      const currentSortOrder = this.sortOrder === null
        ? this.defaultSortOrder
        : this.sortOrder;
      return currentSortOrder === 'desc'
        ? 'sort-amount-up'
        : 'sort-amount-down';
    },
  },
  methods: {
    onSortClicked() {
      this.$emit('sort', {
        indicator: this.indicator,
        order: this.nextSortOrder,
      });
    },
  },
};
</script>
