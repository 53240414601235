// parse feature switches from body dataset
const readFeaturesFromBodyTag = () => {
  const body = document.querySelector('body');
  if (!body) return {};
  return JSON.parse(body.dataset?.featureSwitches || '{}');
};

export default () => {
  const featureAttributes = readFeaturesFromBodyTag();
  return Object.keys(featureAttributes).reduce((aggr, key) => {
    aggr[key.toUpperCase()] = featureAttributes[key];
    return aggr;
  }, {});
};
