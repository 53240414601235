<template>
  <div>
    <p class="section--title">
      {{ title }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DashboardAndClusterListSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>