<template>
  <span
    class="chart-legend-icon--frame"
    :style="{borderColor: color}"
    @click="!notFound ? $emit('update:is-selected', !isSelected) : null"
  >
    <span
      class="chart-legend-icon--box"
      :style="{backgroundColor: isSelected && !notFound ? color : null}"
    />
  </span>
</template>

<script>
export default {
  name: 'ChartLegendIcon',
  props: {
    color: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    notFound: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:is-selected'],
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>