<script>
/**
 * This is the main component for displaying the supply chain table.
 */

import buildColumnDefinitions from "./ColumnDefinitions";
import ExpandableColumnsTable from "../ExpandableColumnsTable";
import { HumanRightsRiskLegend } from "@/components";
import IconTooltipComponent from "@/components/generic/IconTooltip/Component.vue";
import Toggle from "@vueform/toggle";
import ColumnGroupVisibilityStore from "@/components/ExpandableColumnsTable/ColumnGroupVisibilityStore"

export default {
  components: {
    ExpandableColumnsTable,
    HumanRightsRiskLegend,
    IconTooltipComponent,
    Toggle
  },

  props: {
    collection: {
      type: Array,
      default: [],
    },

    dimensions: {
      type: Array,
    },

    supplyChain: {
      type: Object
    },

    slots: {
      type: Object,
      default: {}
    }
  },

  watch: {
    expertMode() {
      ColumnGroupVisibilityStore.set("expert-mode", this.expertMode)
      this.setColumnGroupVisibility()
    }
  },

  mounted() {
    // default A4 max width won't fit
    document.body.classList.add("print-body-full-width")

    this.setColumnGroupVisibility()

    document.addEventListener('ajax:success', (event)=>{
      // Standard XMLHttpRequest does not have a responseJson property :/
      const [_data, status, xhr] = event.detail
      if(xhr.getResponseHeader("content-type").indexOf("json") < 0) {
        return
      }

      try {
        let json = JSON.parse(xhr.responseText)
        if (json && json.supply_chain_item) {
          // replace matching item in our collection
          this.replaceSupplyChainItem(this.internalCollection, json.supply_chain_item)
        }
      } catch (e) {
        console.error("Could not parse json")
      }
    })
  },

  methods: {
    // Replace a supply chain item with an updated json object. This
    // will recursively go through the collection and all the child items
    replaceSupplyChainItem(collection, newItem) {
      if (!collection) { return }

      collection.forEach((item, index) => {
        this.replaceSupplyChainItem(item.children, newItem)
        if (item.id == newItem.id) {
          // depth is only available on index action
          newItem.depth = item.depth
          collection[index] = newItem
        }
      })
    },

    setColumnGroupVisibility() {
      let idsToToggle = ["sector", "country_of_demand", "top_5_countries_of_supply", "weighted_risks", "country_of_production", "relevant_country_of_supply", "commodity_risk"]

      this.columnDefinitions.forEach((columnDefinition)=>{
        if (!this.expertMode && columnDefinition.id == "commodity_risk") {
          // commodity_risks stay visible in normal mode.
          // However, this should not be stored in local storage
          ColumnGroupVisibilityStore.disabled(()=>{
            columnDefinition.expanded = true
          })
          return
        }

        if (idsToToggle.indexOf(columnDefinition.id) < 0) {
          return
        }

        columnDefinition.state.visible = this.expertMode
        if (this.expertMode) {
          columnDefinition.expanded = ColumnGroupVisibilityStore.get(columnDefinition.id, { default: true })
        }
      })
    }
  },

  data() {
    // scope local storage stuff to the current supply chain
    ColumnGroupVisibilityStore.namespace = `supplyChain:${this.supplyChain.id}.`

    return {
      expertMode: ColumnGroupVisibilityStore.get("expert-mode") || false,
      columnDefinitions: buildColumnDefinitions(this.dimensions, this.$t),
      internalCollection: this.collection,
      defaultSort: (supplyChainItem)=>{
        return supplyChainItem.sort_order
      }
    }
  },

  computed: {
    hasAnyRecords() {
      return this.internalCollection.length
    },
    titleTooltip() {
      return this.slots.titleTooltip
    }
  }
}
</script>

<template>
  <div class="sticky-section supply-chain-details-wrapper full-vw is-flex">
    <div class="is-flex flex-column title-wrapper">
      <h1 class="page-title">
        {{ $t("layouts.app_navigation.human_rights.supply_chain") }}
        <span v-if="supplyChain">"{{ supplyChain.name }}"</span>
        <IconTooltipComponent
          v-if="titleTooltip"
          :tooltip="titleTooltip"
        />
      </h1>

      <div data-exclude-from-print v-html="slots.default"></div>

      <div class="flex-grow-1"></div>
      <label class="expert-mode-toggle" v-if="hasAnyRecords">
        <Toggle v-model="expertMode" labelledby="expert-mode-label" :aria-label="$t('apps.supply_chain.expert_mode')" />
        <span @click="expertMode = !expertMode" class="clickable">{{ $t("apps.supply_chain.expert_mode") }}</span>
      </label>
    </div>
    <div
      v-if="hasAnyRecords"
      class="is-flex flex-grow-1 human-rights-wrapper"
    >
      <HumanRightsRiskLegend :include-flag-legend="true" />
    </div>
  </div>
  <br>

  <ExpandableColumnsTable
    v-if="hasAnyRecords"
    :columnDefinitions="columnDefinitions"
    :defaultSort="defaultSort"
    :collection="internalCollection"
    :showGroupExpander="expertMode"
  ></ExpandableColumnsTable>

  <p v-else class="has-text-info mt-m">{{ $t("supply_chains.show.blank_slate") }}</p>

  <p v-if="hasAnyRecords" class="my-m">
    <br>
    {{ $t("apps.human_rights.data_hint") }}
  </p>
</template>

<style lang="scss">
.supply-chain-details-wrapper {
  @media print {
    flex-direction: column;
    .title-wrapper {
      display: block;
    }
    h1.page-title {
      margin-bottom: 0;
    }
  }
}
.expert-mode-toggle {
  font-size: 0.9rem;
  display: inline-block;
  .toggle-container {
    margin-right: 10px;
  }
}

.clickable {
  cursor: pointer;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
