<template>
  <i18n-t
    keypath="components.notation_details.latest_value"
    tag="p"
    class="latest-value"
  >
    <template #value>
      <strong>
        {{ valueString }}
      </strong>
      {{ unit }}
    </template>
    <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
    <template #date>{{ dateString }}</template>
  </i18n-t>
</template>

<script>
import { computed } from 'vue';
import { formatDateWithTimescope } from '@/helpers/DateFormats';
import { formatGradualPrecision } from '@/helpers/NumberFormats';

export default {
  name: 'NotationDetailsLatestValue',
  props: {
    value: {
      type: Number,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    timescope: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dateString = computed(() => formatDateWithTimescope(props.date,
      props.timescope));
    const valueString = computed(() => formatGradualPrecision(props.value));
    return { dateString, valueString };
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>