const TextFilter = (initial) => ({
  query: initial.query,

  get isActive() {
    return !!this.query;
  },

  get forRequest() {
    if (!this.isActive) return undefined;
    return {
      query: this.query,
    };
  },

  clear() {
    this.query = '';
  },
});

export default TextFilter;
