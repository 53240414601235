<template>
  <div class="autocomplete">
    <ul
      ref="container"
      class="autocomplete--list"
    >
      <li
        v-for="(item, index) in controller.suggestions"
        :key="index"
        class="autocomplete--item"
        :class="{'is-selected': index === controller.currentIndex}"
        @mousemove="controller.setCurrentIndexWithLimits(index)"
        @mousedown="$emit('submit')"
      >
        <AutoCompleteItem
          :item="item"
          :query="controller.currentQuery"
        />
      </li>
    </ul>
    <AutoCompleteFooter
      :controller="controller"
    />
  </div>
</template>

<script>
import AutoCompleteItem from './AutoCompleteItem.vue';
import AutoCompleteFooter from './AutoCompleteFooter.vue';

export default {
  name: 'AutoComplete',
  components: {
    AutoCompleteItem,
    AutoCompleteFooter,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit'],
  watch: {
    'controller.currentIndex': function adjustScrollForSelectedItem() {
      const { container } = this.$refs;
      const rowHeight = container.firstElementChild.clientHeight;
      const currentTop = this.controller.currentIndex * rowHeight;
      const currentBottom = currentTop + rowHeight;
      if (currentBottom > container.scrollTop + container.clientHeight) {
        container.scrollTop = currentBottom - container.clientHeight;
      } else if (currentTop < container.scrollTop) {
        container.scrollTop = currentTop;
      }
    },
  },
};
</script>
