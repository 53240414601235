<template>
  <div
    v-if="!controller.isLoading && controller.publications.length === 0"
    class="list--empty-message"
  >
    {{ $t('apps.publications.list.empty') }}
  </div>
  <div v-else>
    <PublicationsTotalUnreadCount
      :unread-count="controller.unreadCount"
      @mark-all-as-read="controller.markAllAsRead()"
    />
    <ul class="list">
      <PublicationsListItem
        v-for="publication in controller.publications"
        :key="publication.id"
        :controller="controller"
        :publication="publication"
      />
    </ul>
    <SpinningLoader v-if="controller.isLoading" />
    <InfiniteScroll
      v-else-if="controller.hasMoreResults"
      id="more"
      @trigger="controller.loadNextPage()"
    />
  </div>
</template>

<script>
import {
  InfiniteScroll,
  PublicationsTotalUnreadCount,
} from '@/components';
import PublicationsListItem from './Item/Component.vue';

export default {
  name: 'PublicationsList',
  components: {
    PublicationsListItem,
    InfiniteScroll,
    PublicationsTotalUnreadCount,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>