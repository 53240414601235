import EventBase from './EventBase';

class PreferredDashboardCurrencyChangeEvent extends EventBase {
  constructor({ dashboard, preferredCurrencyIsoCode }) {
    super();
    this._data = {
      dashboard,
      preferredCurrencyIsoCode,
    };
  }

  static get EventName() {
    return 'HGS::PreferredDashboardCurrencyChangeEvent';
  }

  get dashboard() {
    return this._data.dashboard;
  }

  get preferredCurrencyIsoCode() {
    return this._data.preferredCurrencyIsoCode;
  }
}

export default PreferredDashboardCurrencyChangeEvent;
