<template>
  <span>
    <button
      variant="primary"
      icon="link"
      class="copy-link-button"
      @click="copyLinkToClipboard"
    >
      {{ $t('components.generic.copy_link_button.label') }}
      <Icon
        v-if="isCopied"
        name="check"
        variant="regular"
      />
      <Icon
        v-else
        name="link"
        variant="regular"
      />
    </button>
  </span>
</template>

<script>
export default {
  name: 'CopyLinkButton',
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    async copyLinkToClipboard() {
      await navigator.clipboard.writeText(this.url);
      this.isCopied = true;
      setTimeout(() => { this.isCopied = false; }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>