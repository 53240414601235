<template>
  <div ref="root">
    <slot
      v-if="isMounted"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
export default {
  name: 'SizeProvider',
  data() {
    return {
      width: 0,
      height: 0,
      isMounted: false,
      sizeObserver: new ResizeObserver(this.onResize),
    };
  },
  mounted() {
    this.sizeObserver.observe(this.$refs.root);
    this.registerBeforePrintHook();
    this.onResize();
    this.isMounted = true;
  },
  beforeUnmount() {
    this.sizeObserver.disconnect();
  },
  methods: {
    registerBeforePrintHook() {
      if (window.matchMedia) {
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener((listener) => {
          if (listener.matches) {
            this.onResize();
          }
        });
      }
      window.onbeforeprint = this.onResize;
    },
    onResize() {
      if (!this.$refs.root) {
        // might got unmounted
        return
      }
      const style = window.getComputedStyle(this.$refs.root, null);
      const horizontalPadding = parseFloat(style.getPropertyValue('padding-left'))
        + parseFloat(style.getPropertyValue('padding-right'));
      this.width = this.$refs.root.clientWidth - horizontalPadding;
      const verticalPadding = parseFloat(style.getPropertyValue('padding-top'))
        + parseFloat(style.getPropertyValue('padding-bottom'));
      this.height = this.$refs.root.clientHeight - verticalPadding;
    },
  },
};
</script>
