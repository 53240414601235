import axios from 'axios';

const LIMIT_TOTAL = 15;

const fetchFavoriteProductGroups = () => axios.get('/api/product_groups/favorites')
  .then((response) => response.data);

const fetchPublicationsForProductGroups = (url, productGroups) => {
  if (productGroups.length === 0) {
    return Promise.resolve({
      entries: [],
      meta: { unread_count: 0 },
    });
  }

  return axios.get(url, {
    params: {
      product_group_ids: productGroups.map((group) => group.id),
      limit: LIMIT_TOTAL,
      sort: { column: 'published_at', direction: 'desc' },
    },
  }).then((response) => response.data);
};

const ProductGroupsApi = () => ({
  productGroups() {
    return axios.get('/api/product_groups')
      .then((response) => response.data);
  },

  report(productGroupId) {
    return axios.get(`/api/product_groups/${productGroupId}/report`)
      .then((response) => response.data);
  },
});

export {
  fetchFavoriteProductGroups,
  fetchPublicationsForProductGroups,
  ProductGroupsApi,
};
