<template>
  <div
    v-if="hasData"
    class="trade-flow--chart"
  >
    <div class="trade-flow--chart-header">
      <TradeFlowChartColumnHeader
        v-for="indicator in indicators"
        :key="`chart-header-${flow}-${indicator}`"
        :indicator="indicator"
        :flow="flow"
        :caption="columnHeaderConfigurations[indicator].caption"
        :tooltip="tooltip"
        :unit="columnHeaderConfigurations[indicator].unit"
        :show-sort-indicator="showSortIndicator"
        :sort-order="columnHeaderConfigurations[indicator].sortOrder"
        :default-sort-order="columnHeaderConfigurations[indicator].defaultSortOrder"
        @sort="onSortClicked"
      />
    </div>

    <div class="trade-flow--chart-body">
      <SizeProvider
        v-for="indicator of indicators"
        :key="`chart-body-${flow}-${indicator}`"
        :class="`trade-flow--chart-column ${cssClassForFlow}-${indicator}`"
      >
        <template #default="{ width }">
          <HorizontalBarChart
            :width="width"
            :bandwidth="25"
            :data="chartData[indicator]"
            :highlight="includeCountries"
            :show-percentages="indicator === 'value'"
            :fraction-digits="indicator === 'price' ? 2 : 0"
            :vertical-tick-format="displayTickWithRank"
          />
        </template>
      </SizeProvider>
    </div>

    <div class="trade-flow--chart-footer">
      <p
        v-for="indicator of indicators"
        :key="`chart-footer-${flow}-${indicator}`"
        class="trade-flow--chart-footnote"
      >
        <template v-if="indicator === 'value' && flow !== 'balance'">
          {{ $t('components.trade_flows.total') }}
          {{ $n(total[indicator], 'integer') }}
          {{ data.units[indicator] }}
          &bull;
          {{ $t('components.trade_flows.proportion_of_top_to_total', { n: top }) }}
          {{ $n(proportionOfTop[indicator], 'integer') }}%
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import HorizontalBarChart from '@/components/Charts/HorizontalBarChart.vue';
import SizeProvider from '@/components/Charts/SizeProvider.vue';
import TradeFlowChartColumnHeader from './TradeFlowChartColumnHeader.vue';

export default {
  name: 'TradeFlowChart',
  components: {
    SizeProvider,
    HorizontalBarChart,
    TradeFlowChartColumnHeader,
  },
  props: {
    caption: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    flow: {
      type: String,
      required: true,
    },
    indicators: {
      type: Array,
      default: () => ['value', 'volume', 'price'],
    },
    top: {
      type: Number,
      default: 20,
    },
    includeCountries: {
      type: Array,
      default: () => [],
    },
    includeRestOfWorld: {
      type: Boolean,
      default: true,
    },
    sort: {
      type: Object,
      default: () => ({
        indicator: 'value',
        order: 'desc',
      }),
    },
  },
  emits: ['sort'],
  computed: {
    cssClassForFlow() {
      if (this.flow !== 'balance') return this.flow;
      const isImport = this.top < 0;
      return isImport ? 'net-import' : 'net-export';
    },
    sortOperator() {
      let reverseSort = 1;
      if (this.top < 0) {
        reverseSort *= -1;
      }
      if (this.sort.order === 'asc') {
        reverseSort *= -1;
      }
      return (a, b) => reverseSort * (b[this.sort.indicator] - a[this.sort.indicator]);
    },
    filterOperator() {
      return this.top >= 0
        ? (d) => d[this.sort.indicator] >= 0
        : (d) => d[this.sort.indicator] < 0;
    },
    dataForFlow() {
      if (!this.data) return [];
      return this.data[this.flow] || [];
    },
    ranksForCountries() {
      const defaultIndicator = this.indicators[0];
      const sortOperator = this.top >= 0
        ? (a, b) => b[defaultIndicator] - a[defaultIndicator]
        : (a, b) => a[defaultIndicator] - b[defaultIndicator];
      const sortedByDefault = this.dataForFlow.concat().sort(sortOperator);
      return sortedByDefault.reduce((obj, country, idx) => ({
        ...obj,
        [country.country_name]: idx + 1,
      }), {});
    },
    sortedData() {
      return this.dataForFlow.concat().sort(this.sortOperator);
    },
    filteredData() {
      return this.sortedData.filter(this.filterOperator);
    },
    preparedData() {
      return this.indicators.reduce((aggr, indicator) => {
        aggr[indicator] = this.filteredData
          .map((d) => ({ x: d[indicator], y: d.country_name }));
        return aggr;
      }, {});
    },
    total() {
      return this.indicators.reduce((aggr, indicator) => {
        const totalSum = d3.sum(this.preparedData[indicator], (d) => d.x);
        aggr[indicator] = totalSum;
        return aggr;
      }, {});
    },
    topCountryNames() {
      return this.sortedData.map((c) => c.country_name)
        .slice(0, Math.abs(this.top));
    },
    selectOperator() {
      const visibleCountries = this.topCountryNames.concat(this.includeCountries);
      return (c) => visibleCountries.indexOf(c.y) >= 0;
    },
    selectedCountries() {
      return this.indicators.reduce((aggr, indicator) => {
        aggr[indicator] = this.preparedData[indicator].filter(this.selectOperator);
        return aggr;
      }, {});
    },
    restOfWorldCountries() {
      return this.indicators.reduce((aggr, indicator) => {
        aggr[indicator] = this.preparedData[indicator].filter((c) => !this.selectOperator(c));
        return aggr;
      }, {});
    },
    restOfWorld() {
      return this.indicators.reduce((aggr, indicator) => {
        let value = null;
        if (indicator === 'price') {
          const countriesWithValue = this.restOfWorldCountries.value.map((c) => c.y);
          const countriesWithVolume = this.restOfWorldCountries.volume.map((c) => c.y);
          const forPrice = (c) => countriesWithValue.indexOf(c.y) >= 0
            && countriesWithVolume.indexOf(c.y) >= 0;
          const valueSum = d3.sum(this.restOfWorldCountries.value.filter(forPrice), (d) => d.x);
          const volumeSum = d3.sum(this.restOfWorldCountries.volume.filter(forPrice), (d) => d.x);
          value = valueSum / volumeSum;
        } else {
          value = d3.sum(this.restOfWorldCountries[indicator], (d) => d.x);
        }
        aggr[indicator] = {
          x: value,
          y: this.$t('components.trade_flows.rest_of_world'),
        };
        return aggr;
      }, {});
    },
    proportionOfTop() {
      const top20ByPrimaryIndicator = this.dataForFlow
        .filter((d) => this.ranksForCountries[d.country_name] <= this.top);

      return this.indicators.reduce((aggr, indicator) => {
        const total = this.total[indicator];
        const topCountriesSum = d3.sum(top20ByPrimaryIndicator, (d) => d[indicator]);
        aggr[indicator] = (topCountriesSum * 100) / total;
        return aggr;
      }, {});
    },
    chartData() {
      return this.indicators.reduce((aggr, indicator) => {
        const showRestOfWorld = this.includeRestOfWorld
          && this.restOfWorldCountries[indicator].length > 0;
        const restOfWorld = showRestOfWorld ? this.restOfWorld[indicator] : [];
        aggr[indicator] = this.preparedData.length === 0
          ? []
          : this.selectedCountries[indicator].concat(restOfWorld);
        return aggr;
      }, {});
    },
    hasData() {
      return this.filteredData.length > 0;
    },
    showSortIndicator() {
      return this.indicators.length > 1;
    },
    columnHeaderConfigurations() {
      const createConfig = (name, defaultSortOrder) => ({
        caption: this.indicators.indexOf(name) === 0 ? this.caption : null,
        unit: (this.data?.units || {})[name],
        sortOrder: this.sort.indicator === name ? this.sort.order : null,
        defaultSortOrder,
      });
      return {
        value: createConfig('value', 'desc'),
        volume: createConfig('volume', 'desc'),
        price: createConfig('price', 'asc'),
      };
    },
  },
  methods: {
    onSortClicked(sort) {
      this.$emit('sort', sort);
    },
    displayTickWithRank(tick) {
      const rank = this.ranksForCountries[tick];
      return rank ? `${rank}. ${tick}` : tick;
    },
  },
};
</script>
