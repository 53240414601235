import Turbolinks from 'turbolinks';
import { reactive } from 'vue';
import NotationApi from '@/api/NotationApi';
import i18n from '@/config/i18n';
import LogApi from '@/api/LogApi';
import NotationFilters from '@/models/NotationFilters';
import NotationListModel from '@/models/NotationListModel';
import ApiHelpers from '@/api/ApiHelpers';
import { NullOrUndefined } from '@/helpers';
import NotificationController from '@/controllers/NotificationController';
import UrlStateStore, { setQueryParam, serializeDateForUrl, serializeDatesForUrl } from '@/services/UrlStateStore';
import moment from 'moment';
import { defaultGraphLimits } from '@/components/QuotesChart/Defaults';
import { throttleFunction } from '@/utils/FunctionUtilities';
import SelectionController from './SelectionController';

const serializeState = (state) => ({
  filters: state.filters.forRequest,
  sorting: state.sorting,
  comparisonDate: serializeDateForUrl(state.comparisonDate),
  graphLimits: serializeDatesForUrl(state.graphLimits),
  selection: state.selection.serializedState,
});

// URL changes are rate limited in Firefox to 200 calls per 10s
// which would allow one call every 50ms
// throttling by 500ms per call should avoid any problems
const updateUrlState = throttleFunction((state) => {
  const serializedState = serializeState(state);
  Object.keys(serializedState).forEach((key) => {
    UrlStateStore.set(key, serializedState[key]);
  });
  setQueryParam('customised', JSON.stringify(state.showCustomised));
}, 500);

class BaseController {
  constructor(config, user, initialState, extraState = {},
    { isMailExport = false, media = 'screen' }) {
    this.config = config;

    this.isMailExport = isMailExport;
    this.isMediaPrint = media.toLowerCase() === 'print';

    this.notationApi = NotationApi();

    this.pagination = config.pagination;

    this.state = reactive(Object.assign({
      user,
      selection: null,
      filters: null,
      sorting: null,
      comparisonDate: null,
      graphLimits: {
        ...defaultGraphLimits(),
      },
      showDetailColumns: false,
    }, extraState));
    this.state.selection = SelectionController(this.pagination.entries,
      this.state,
      this.notationApi);
    this.loadState(initialState);
  }

  selectNotation(notation) {
    this.state.selection.select(notation);
    this.onStateChangedByUser();
  }

  clearSecondarySelection() {
    this.state.selection.clearSecondarySelection();
    this.onStateChangedByUser();
  }

  get paginationParams() {
    return {
      filters: this.state.filters.forRequest,
      sorting: this.state.sorting,
      compare_to_date: ApiHelpers.dateToString(this.state.comparisonDate),
      customised: this.state.showCustomised,
      ...this.additionalPaginationParams,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get additinalPaginationParams() {
    return {};
  }

  async loadRemainingPages() {
    if (!this.pagination.hasNextPage) {
      return;
    }
    await this.pagination.fetchNextPage();
    await this.loadRemainingPages();
  }

  static scrollToTop() {
    const scrollContainer = document.querySelector('.page--split-bottom');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }

  // Loads notations and selects notations from the result
  // depending on the selectionState argument.
  // This also updates the URL state on success, so I am
  // a bit confused where the difference to updateNotationsAndUserState()
  // really is. Is the only difference that the dashboard's
  // customisation isn't touched?
  async updateNotationsWithoutState({ selectionState = null } = {}) {
    LogApi.onSearchUpdate(this.config.topTerm ? this.config.topTerm.id : null,
      this.state.filters.forRequest,
      this.state.sorting,
      this.state.comparisonDate,
      this.state.graphLimits);

    await this.pagination.fetchFirstPage(this.paginationParams, {
      // Keep results for notation table while reloading to avoid columns
      // resizing wildly switching empty list and back.
      // Instead, the table body gets greyed out to indicate loading.
      clearBeforeFetch: false,
    });


    if (selectionState) {
      this.state.selection.loadSerializedState(selectionState);
    } else if (!this.isMailExport && !this.state.selection.primary.notation) {
      this.state.selection.select(this.notations[0], { force: true });
    }
    updateUrlState(this.state);
    BaseController.scrollToTop();

    if (this.isMediaPrint) {
      this.loadRemainingPages();
    }
  }

  // Updates notations in reaction to a change by the user.
  // The difference is that this also updates the URL state
  // and anything else that depends on user changes (e.g.
  // the dashboard customisations).
  updateNotationsAndUserState() {
    this.onStateChangedByUser();
    this.updateNotationsWithoutState();
  }

  loadNextPage() {
    if (!this.isLoadingFilters) {
      this.pagination.fetchNextPage();
    }
  }

  loadFiltersAndUpdate(selectedFilters, { selectionState = null } = {}) {
    this.config.notationListApi.availableFilters(selectedFilters, this.state.showCustomised)
      .then((filters) => { this.state.filters = NotationFilters(filters); })
      .then(() => this.updateNotationsWithoutState({ selectionState }));
  }

  loadState(newState) {
    let newFilters = null;
    if (newState !== null) {
      this.state.showContainer = newState.showContainer;
      this.state.sorting = newState.sorting;
      this.state.comparisonDate = newState.comparisonDate
        ? new Date(newState.comparisonDate)
        : null;
      newFilters = newState.filters;
    } else {
      this.state.showContainer = false;
      this.state.sorting = null;
      this.state.comparisonDate = null;
      newFilters = null;
      this.state.selection.clear();
    }
    if (newState?.graphLimits) {
      this.state.graphLimits = Object.fromEntries(Object.keys(newState.graphLimits).map(
        (key) => [key, moment(newState.graphLimits[key])],
      ));
    } else {
      this.state.graphLimits = {
        ...defaultGraphLimits(),
      };
    }
    this.loadFiltersAndUpdate(newFilters, { selectionState: newState && newState.selection });
  }

  onStateChangedByUser() {
    updateUrlState(this.state);
  }

  get user() {
    return this.state.user;
  }

  get selection() {
    return this.state.selection;
  }

  get notations() {
    return this.pagination.entries;
  }

  get detailColumns() {
    return this.config.detailColumns || ['attribute_unit', 'attribute_economy', 'dataset'];
  }

  get notationListModel() {
    const model = NotationListModel(this.config.i18nPrefix, this.config.noSortOrFilter);
    model.setColumnEventListener('batch_select', 'click', (notation, event) => {
      notation.isChecked = !notation.isChecked;
      event.stopPropagation();
    });
    model.setColumnEventListener('is_hidden_for_customisation', 'click', (notation, event) => {
      this.toggleDashboardNotationForCustomisation(notation);
      event.stopPropagation();
    });
    model.setColumnEventListener('is_hidden_for_clients', 'click', (notation, event) => {
      this.toggleDashboardNotationForUsers(notation);
      event.stopPropagation();
    });
    model.setColumnEventListener('watched', 'click', (notation, event) => {
      this.toggleWatched(notation);
      event.stopPropagation();
    });
    this.detailColumns.forEach((key) => model.setColumnVisible(key, this.state.showDetailColumns));
    [
      'custom_dod_percent',
      'custom_dod_percent_over_min',
      'custom_dod_percent_over_max',
      'custom_dod_percent_over_avg',
    ].forEach((key) => {
      model.setColumnVisible(key, this.comparisonDate !== null);
      model.setColumnData(key, 'date', this.comparisonDate);
    });
    [1, 2, 3].forEach((n) => {
      model.setColumnData(`correlation_${n}m`, 'primaryNotationId', this.selection?.primary?.notation?.id);
      model.setColumnData(`correlation_${n}m`, 'primaryTimescope', this.selection?.primary?.notation?.timescope);
    });
    this.hiddenColumns.map((key) => model.setColumnVisible(key, false));
    return model;
  }

  get hiddenColumns() {
    return this.config.hiddenColumns;
  }

  get showDetailColumns() {
    return this.state.showDetailColumns;
  }

  set showDetailColumns(value) {
    this.state.showDetailColumns = value;
  }

  toggleDetailColumns() {
    this.state.showDetailColumns = !this.state.showDetailColumns;
  }

  get isLoadingFilters() {
    return NullOrUndefined(this.state.filters);
  }

  get isLoading() {
    return this.isLoadingFilters || this.pagination.isLoading;
  }

  get hasMoreResults() {
    return this.pagination.hasNextPage;
  }

  get filters() {
    return this.state.filters;
  }

  set filters(value) {
    this.state.filters = value;
  }

  get sorting() {
    return this.state.sorting;
  }

  set sorting(value) {
    this.state.sorting = value;
  }

  get comparisonDate() {
    return this.state.comparisonDate;
  }

  set comparisonDate(date) {
    this.state.comparisonDate = date;
    this.updateNotationsAndUserState();
  }

  get graphLimits() {
    return this.state.graphLimits;
  }

  set graphLimits(limits) {
    this.state.graphLimits = limits;
    this.onStateChangedByUser();
  }

  get showDateOverDateColumn() {
    return this.state.comparisonDate !== null;
  }

  get checkedNotations() {
    return this.notations.filter((notation) => notation.isChecked);
  }

  get isPdfExportButtonDisabled() {
    return this.isLoading || this.selection.empty;
  }

  toggleWatched(item) {
    const index = this.notations
      .findIndex((notation) => notation.id === item.id);
    const notation = this.notations[index];
    if (notation.is_watched) {
      this.notationApi.deleteFromWatchList(notation)
        .then(() => { notation.is_watched = false; });
    } else {
      // TODO: catch error if id undefined?
      this.notationApi.addToWatchList(notation)
        .then(() => { notation.is_watched = true; });
    }
  }

  uncheckAllNotations() {
    this.notations.forEach((n) => {
      n.isChecked = false;
    });
  }

  // TODO: create SortController
  cycleColumnSort(column) {
    const sortOnDifferentColumn = this.state?.sorting?.column !== column;
    if (sortOnDifferentColumn) {
      this.state.sorting = { column, direction: 'asc' };
      return;
    }
    if (this.state?.sorting?.direction === 'asc') {
      this.state.sorting.direction = 'desc';
    } else {
      this.state.sorting = null;
    }
  }

  setColumnSort(column, direction) {
    if (direction === 'unsorted') {
      this.state.sorting = null;
    } else {
      this.state.sorting = { column, direction };
    }
  }

  isNotationVisibleForPrint(notation) {
    const isPrimarySelection = this.selection.primary.notation
                            && this.selection.primary.notation.id === notation.id;
    const isSecondarySelection = this.selection.secondary.notation
                              && this.selection.secondary.notation.id === notation.id;
    return this.isMailExport
      || this.config.printAllNotations
      || isPrimarySelection
      || isSecondarySelection;
  }

  createNewDashboard(dashboard) {
    return this.notationApi.createNewDashboard(dashboard, this.checkedNotations)
      .then((response) => {
        this.uncheckAllNotations();
        Turbolinks.visit(`/dashboards/${response.data.id}`);
      });
  }

  addCheckedNotationsToExistingDashboard(dashboard) {
    return this.notationApi.addToDashboard(dashboard.id, this.checkedNotations)
      .then(() => {
        this.uncheckAllNotations();
        NotificationController.success(i18n.t('components.search_header.batch_actions.add_to_dashboard_success', { name: dashboard.full_name }));
      });
  }

  onSortingChanged() {
    this.updateNotationsAndUserState();
  }

  onFiltersChanged() {
    this.updateNotationsAndUserState();
  }

  saveDefaultFilters() {
    this.config.notationListApi.saveDefaultFilters(this.state.filters.forRequest)
      .then(() => NotificationController.success(i18n.t('components.search_header.save_filters_success_msg')))
      .catch(() => NotificationController.error(i18n.t('components.search_header.save_filters_error_msg')));
  }

  loadDefaultFilters() {
    this.loadFiltersAndUpdate();
  }

  clearFilters() {
    this.state.filters.clear();
    this.updateNotationsAndUserState();
  }

  get filtersActive() {
    return this.state.filters && this.state.filters.anyActive;
  }
}

export { BaseController, serializeState, updateUrlState };
