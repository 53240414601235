<template>
  <p class="print-footer">
    HGS Research &bull; {{ title }} &bull; {{ exportDate }}
  </p>
</template>

<script>
import moment from 'moment';

export default {
  name: 'IntroPrintFooter',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    exportDate() {
      return moment().format('LL');
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>