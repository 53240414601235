<template>
  <div class="page-header">
    <h1 class="page-header--title">
      {{ $t('page_titles.news') }}
    </h1>
    <NewsHeaderActions
      v-if="editorialController"
      :editorial-controller="editorialController"
    />
  </div>
</template>

<script>
import NewsHeaderActions from './Actions/Component.vue';

export default {
  name: 'NewsHeader',
  components: {
    NewsHeaderActions,
  },
  props: {
    editorialController: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>