import axios from 'axios';
import { BaseController } from '@/components/NotationListPage/BaseController';
import i18n from '@/config/i18n';
import PaginationService from '@/services/PaginationService';
import ApiHelpers from '@/api/ApiHelpers';
import WatchListApi from '../api/WatchListApi';

const WatchListControllerConfig = (user) => ({
  notationListApi: WatchListApi(),
  i18nPrefix: 'watch_list',
  hiddenColumns: [
    'is_hidden_for_customisation',
    'is_hidden_for_clients',
    'dashboard_name',
    'sub_term',
    'custom_dod_percent_over_min',
    'custom_dod_percent_over_max',
    'custom_dod_percent_over_avg',
    'current_value_range',
    'correlation_1m',
    'correlation_2m',
    'correlation_3m',
    'preview',
  ],
  pagination: new PaginationService({
    url: '/api/watch_list/notations',
    method: 'POST',
    transformEntry: (e) => ({ isChecked: false, ...e }),
    errorHandler: ApiHelpers.handleError,
  }),
  canExportAllToExcel: user.can_export_raw_data,
});

class WatchListController extends BaseController {
  constructor(user, initialState, { isMailExport, media, forInternalUse }) {
    const config = WatchListControllerConfig(user);
    super(config, user, initialState, {}, { isMailExport, media });
    this.showWatchListSubscriptionButton = true;
    this.forInternalUse = forInternalUse;
  }

  get mailExportRythm() {
    return this.user.watch_list_export_rythm;
  }

  set mailExportRythm(rythm) {
    axios.put('/api/user', {
      user: {
        watch_list_export_rythm: rythm,
      },
    }).then(() => {
      this.state.user.watch_list_export_rythm = rythm;
    });
  }

  get caption() {
    if (this.isLoading) {
      return i18n.t('components.watch_list.is_loading');
    }
    const count = this.notations.length;
    if (this.hasMoreResults) {
      return i18n.t('components.watch_list.result_count_plus', count);
    }
    return i18n.t('components.watch_list.result_count', count);
  }

  exportAllToExcel(callback) {
    if (this.disabled) return;
    WatchListApi().getExcelFromAPI(callback)
  }
}

export default WatchListController;
