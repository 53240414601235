<template>
  <div
    ref="root"
    v-click-outside="hideOptions"
    tabindex="-1"
    class="combobox"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`option-list-${uid}`"
    :class="{'is-open': isOpen}"
    @keydown.up.down="$refs.input.focus()"
  >
    <div class="combobox--default-button">
      <input
        ref="input"
        :value="isOpen ? currentInput : currentValue"
        type="text"
        class="combobox--input"
        role="searchbox"
        aria-autocomplete="list"
        :aria-label="label"
        :aria-controls="`option-list-${uid}`"
        :aria-expanded="isOpen"
        :aria-labelledby="ariaLabelledby"
        aria-multiline="false"
        :placeholder="modelValue.length === 0 ? placeholder : currentValue"
        :required="required"
        @input="onInputChanged"
        @focus="showOptions"
        @blur="onBlur"
        @keydown.up.prevent="focusPrevious"
        @keydown.down.prevent="focusNext"
        @keydown.enter.prevent="acceptCurrentOption"
      >
      <button
        type="button"
        :class="iconClass"
        :aria-label="iconLabel"
        @click="onIconClicked"
      >
        <Icon
          :name="iconName"
          variant="regular"
          :fixed-width="true"
        />
      </button>
    </div>

    <div
      v-if="isOpen"
      class="combobox--popup"
    >
      <ul
        :id="`option-list-${uid}`"
        ref="options"
        class="combobox--options-list"
        role="listbox"
        :aria-activedescendant="`option-${uid}-${focussedKey}`"
      >
        <li
          v-for="option in filteredOptions"
          :key="`option-${accessKey(option)}`"
        >
          <button
            :id="`option-${uid}-${accessKey(option)}`"
            :ref="getRefNameForKey(accessKey(option))"
            type="button"
            class="combobox--option"
            :class="{'has-focus': hasFocus(option), 'is-selected': isSelected(option)}"
            :aria-labelledby="ariaLabelledby"
            :aria-selected="accessKey(option) === focussedKey"
            role="option"
            @mousemove="focusOption(option)"
            @click="toggleOption(option)"
          >
            <slot
              name="option"
              :option="option"
            >
              <ComboBoxOption>
                <div
                  class="auto-complete-multi--option"
                  style="display: flex; flex-direction: row;"
                >
                  <span class="auto-complete-multi--option-text">
                    {{ accessValue(option) }}
                  </span>
                  <Icon
                    v-if="isSelected(option)"
                    :name="hasFocus(option) ? 'times' : 'check'"
                    :fixed-width="true"
                  />
                </div>
              </ComboBoxOption>
            </slot>
          </button>
        </li>
      </ul>
      <div
        v-if="$slots.footer"
        class="combobox--footer"
      >
        <slot
          name="footer"
          :focussed-option="focussedOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';
import scrollToElement from '@/helpers/ScrollToElement';

export default {
  name: 'AutoCompleteMulti',
  mixins: [HasUid],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    accessKey: {
      type: Function,
      default: (o) => o.key,
    },
    accessValue: {
      type: Function,
      default: (o) => o.value,
    },
    allowEmptyValue: {
      type: Boolean,
      default: false,
    },
    filterFunction: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      sortedOptions: [],
      focussedKey: null,
      currentInput: '',
    };
  },
  computed: {
    currentOptions() {
      if (this.options.length === 0) return [];
      return this.modelValue.map((key) => this.options.find((o) => this.accessKey(o) === key));
    },
    currentValue() {
      if (this.currentOptions.length === 0) return null;
      return this.currentOptions.map((o) => this.accessValue(o)).sort().join(', ');
    },
    focussedOption() {
      return this.filteredOptions.find((o) => this.accessKey(o) === this.focussedKey);
    },
    hasLockedValue() {
      return this.currentValue;
    },
    iconName() {
      if (!this.allowEmptyValue) {
        return 'chevron-down';
      }
      return this.hasLockedValue ? 'times' : 'search';
    },
    iconClass() {
      return this.allowEmptyValue ? 'combobox--icon' : 'combobox--caret';
    },
    iconLabel() {
      if (!this.allowEmptyValue) {
        return this.$t('components.generic.autocomplete.toggle');
      }
      return this.hasLockedValue
        ? this.$t('components.generic.autocomplete.clear')
        : this.$t('components.generic.autocomplete.focus');
    },
    defaultFilter() {
      return (input, option) => {
        const value = this.accessValue(option);
        return input !== null && value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      };
    },
    actualFilter() {
      return this.filterFunction || this.defaultFilter;
    },
    filteredOptions() {
      return this.options.filter((o) => this.actualFilter(this.currentInput, o));
    },
  },
  watch: {
    isOpen() {
      this.sortOptions();
    },
    focussedOption() {
      this.$nextTick(() => this.adjustScrollPosition());
    },
    filteredOptions() {
      const focussedIndex = this.filteredOptions.findIndex(
        (o) => this.accessKey(o) === this.focussedKey,
      );
      if (focussedIndex === -1) {
        const option = this.filteredOptions[0];
        this.focussedKey = option ? this.accessKey(option) : null;
      }
    },
  },
  methods: {
    sortOptions() {
      const selectedOptions = this.options.filter((o) => this.isSelected(o));
      const otherOptions = this.options.filter((o) => !this.isSelected(o));
      this.sortedOptions = selectedOptions.concat(otherOptions);
    },
    getRefNameForKey(key) {
      return `option-${key}`;
    },
    adjustScrollPosition() {
      const focusedRef = this.getRefNameForKey(this.focussedKey);
      const focusItem = this.$refs[focusedRef];
      if (!focusItem) return;
      scrollToElement(this.$refs.options, focusItem);
    },
    showOptions() {
      this.currentInput = '';
      this.focussedKey = null;
      this.isOpen = true;
    },
    hideOptions() {
      this.isOpen = false;
      this.$refs.input.blur();
    },
    hideOptionsAndFocus() {
      this.hideOptions();
      this.$nextTick(() => this.$refs.root.focus());
    },
    toggleOption(option) {
      const toggledKey = this.accessKey(option);
      const newValue = this.isSelected(option)
        ? this.modelValue.filter((k) => k !== toggledKey)
        : this.modelValue.concat([toggledKey]);

      this.$emit('update:modelValue', newValue);
      if (this.currentInput !== '') {
        this.currentInput = '';
        this.focussedKey = null;
      }
    },
    isSelected(option) {
      return this.currentOptions.find((o) => this.accessKey(o) === this.accessKey(option));
    },
    hasFocus(option) {
      return this.accessKey(option) === this.focussedKey;
    },
    focusOption(option) {
      if (!option) return;
      this.focussedKey = this.accessKey(option);
    },
    focusPrevious() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => this.accessKey(o) === this.focussedKey,
      );
      const nextIndex = Math.max(0, currentIndex - 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    focusNext() {
      const currentIndex = this.filteredOptions.findIndex(
        (o) => this.accessKey(o) === this.focussedKey,
      );
      const nextIndex = Math.min(this.filteredOptions.length - 1, currentIndex + 1);
      const nextOption = this.filteredOptions[nextIndex];
      this.focusOption(nextOption);
    },
    acceptCurrentOption() {
      this.toggleOption(this.focussedOption);
    },
    onBlur(event) {
      // only hide the popup if the new focus element is not a descendant
      // of the root element
      const newFocusElement = event.relatedTarget
                           || event.explicitOriginalTarget
                           || document.activeElement;
      if (!this.$refs.root.contains(newFocusElement)) {
        this.hideOptions();
      }
    },
    onIconClicked() {
      if (!this.allowEmptyValue) {
        this.toggleOptionsAndFocus();
        return;
      }
      if (this.hasLockedValue) {
        this.clearValue();
      } else {
        this.focusAndShowOptions();
      }
    },
    toggleOptionsAndFocus() {
      if (this.isOpen) {
        this.hideOptionsAndFocus();
      } else {
        this.focusAndShowOptions();
      }
    },
    focusAndShowOptions() {
      this.$refs.input.focus();
    },
    clearValue() {
      this.$emit('update:modelValue', []);
    },
    onInputChanged(event) {
      this.currentInput = event.target.value;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>