import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import {exportFromResponse} from "../../../helpers/ExportHelpers";

const WatchListApi = () => {
  const baseUrl = '/api/watch_list';
  const availableFilters = (selectedFilters) => axios.post(`${baseUrl}/filters`, {
    selected_filters: selectedFilters,
  }).then((response) => response.data);

  const notations = (filters, sorting, comparisonDate, showCustomised, {page = 0} = {}) => {
    const payload = ApiHelpers.removeNullKeys({
      filters,
      sorting,
      compare_to_date: ApiHelpers.dateToString(comparisonDate),
      page,
    });
    return axios.post(`${baseUrl}/notations`, payload)
        .then(ApiHelpers.transformNotationResponse);
  };

  const getExcelFromAPI = (callback) => axios.post(`${baseUrl}/notations.xlsx`, {},  {responseType: 'blob'}).then((response) => {
    exportFromResponse(response)
    callback()
  });

  return {
    availableFilters,
    notations,
    getExcelFromAPI,
    saveDefaultFilters: () => {
    },
  };
};

export default WatchListApi;
