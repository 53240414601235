import Turbolinks from 'turbolinks';
import { Base64 } from 'js-base64';
import moment from 'moment';

export const setQueryParam = (key, value) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (value === null || value === undefined) {
    queryParams.delete(key);
  } else {
    queryParams.set(key, value);
  }
  const queryString = `?${queryParams.toString()}`;
  Turbolinks.controller
    .replaceHistoryWithLocationAndRestorationIdentifier(queryString, Turbolinks.uuid());
};

export const setAllQueriesAsParam = (params, values) => {
  const currentQueryParams = new URLSearchParams(params);
  const newQueryParams = new URLSearchParams(values);

  const queryParams = new URLSearchParams({ ...Object.fromEntries(currentQueryParams), ...Object.fromEntries(newQueryParams) });
  const queryString = `?${queryParams.toString()}`;
  Turbolinks.controller
    .replaceHistoryWithLocationAndRestorationIdentifier(queryString, Turbolinks.uuid());
};

export const getQueryParam = (key, defaultValue = null) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key) || defaultValue;
};

export const getQueryParamAsNumber = (key, defaultValue = null) => +getQueryParam(key) || defaultValue;

// This is used to ensure that parsing this date back always results in a date at 00:00 in the
// local browser timezone.
// Use only for days, i.e. dates without (important) time information!
export const serializeDateForUrl = (date) => (date ? moment(date).format('YYYY-MM-DDT00:00:00.000') : date);

export const serializeDatesForUrl = (dates) => {
  if (!dates) {
    return dates;
  }

  const serialized = {};
  Object.keys(dates).forEach((key) => {
    serialized[key] = serializeDateForUrl(dates[key]);
  });
  return serialized;
};

class UrlStateStore {
  constructor() {
    this.reset();
  }

  reset() {
    const storedState = getQueryParam('state');
    this._state = storedState ? this.deserialize(storedState) : {};
  }

  // eslint-disable-next-line class-methods-use-this
  serialize(value) {
    return Base64.encode(JSON.stringify(value));
  }

  // eslint-disable-next-line class-methods-use-this
  deserialize(value) {
    return JSON.parse(Base64.decode(value));
  }

  queryStringForState(state) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('state', this.serialize(state));
    return `?${queryParams.toString()}`;
  }

  get queryString() {
    return this.queryStringForState(this._state);
  }

  saveStateToUrl() {
    const newUrl = this.queryString;
    // We cannot just use replaceState, since it cannot be interpreted by Turbolinks.
    // Open issues at Turbolinks:
    //   https://github.com/turbolinks/turbolinks/issues/219
    // This recommends using the following (undocumented) method instead:
    Turbolinks.controller
      .replaceHistoryWithLocationAndRestorationIdentifier(newUrl, Turbolinks.uuid());
  }

  replaceState(value) {
    this._state = value || {};
    this.saveStateToUrl();
  }

  deserializeState(serializedState) {
    const newState = serializedState
      ? (this.deserialize(serializedState) || {})
      : {};
    this.replaceState(newState);
  }

  clear(key) {
    this._state[key] = undefined;
    this.saveStateToUrl();
  }

  set(key, value) {
    this._state[key] = value;
    this.saveStateToUrl();
  }

  get(key, defaultValue = undefined) {
    const value = this._state[key];
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return value;
  }
}

const singletonInstance = new UrlStateStore();

document.addEventListener('turbolinks:load', () => {
  singletonInstance.reset();
});

export default singletonInstance;
