<template>
  <p
    v-if="isVisible"
    class="trade-flow--reporter-balance"
    :class="cssClass"
  >
    <Icon
      class="trade-flow--reporter-balance-icon"
      name="info-circle"
      variant="regular"
    />
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'SurplusOrDeficitLabel',
  props: {
    country: {
      type: Object,
      default: () => null,
    },
    worldData: {
      type: Object,
      default: () => null,
    },
    classification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    balance() {
      if (!this.worldData?.balance) return null;
      const balance = this.worldData.balance.find((c) => c.country_id === this.country?.id);
      return balance?.value;
    },
    isVisible() {
      return !!this.balance;
    },
    text() {
      if (!this.isVisible) return null;
      const i18nKey = this.balance < 0
        ? 'components.trade_flows.country_trade_flow_deficit'
        : 'components.trade_flows.country_trade_flow_surplus';
      return this.$t(i18nKey, {
        country: this.country.name,
        classification: `${this.classification.code} ${this.classification.name}`,
      });
    },
    cssClass() {
      if (!this.isVisible) return null;
      return this.balance < 0 ? 'is-deficit' : 'is-surplus';
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>