<template>
  <div>
    <div class="form--columns">
      <DateInput
        v-model="entry.published_at"
        :label="$t('attributes.product_group_report.published_at')"
        :required="true"
      />
      <DateInput
        v-model="entry.valid_to"
        :label="$t('attributes.product_group_report.valid_to')"
        :required="true"
      />
      <SelectInput
        v-model="entry.trend"
        :label="$t('attributes.product_group_report.trend')"
        :required="true"
        :options="optionsForTrend"
      />
    </div>
    <RichTextInput
      v-model="entry.content"
      :label="$t('attributes.product_group_report.content')"
    />
    <CheckboxInput
      v-model="entry.notify_users"
      :disabled="notifyUsersNonOptional"
      :label="notifyUsersLabel"
    />
  </div>
</template>

<script>
import { optionsForEnumerize } from '@/helpers/I18nHelpers';
import RichTextInput from '@/components/RichTextInput/Component.vue';

export default {
  name: 'ProductGroupReportsEditForm',
  components: {
    RichTextInput,
  },
  props: {
    initialEntry: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      entry: {
        content: this.initialEntry.content_for_edit,
        trend: this.initialEntry.trend,
        published_at: this.initialEntry.published_at
          ? new Date(this.initialEntry.published_at)
          : new Date(),
        valid_to: this.initialEntry.valid_to
          ? new Date(this.initialEntry.valid_to)
          : new Date(),
        notify_users: true,
      },
    };
  },
  computed: {
    optionsForTrend() {
      return optionsForEnumerize('enumerize.product_group_report.trend');
    },
    notifyUsersNonOptional() {
      // Cannot disable notifications for new reports
      return !this.initialEntry.published_at;
    },
    notifyUsersLabel() {
      return this.notifyUsersNonOptional
        ? this.$t('apps.product_group_reports.edit_form.notify_users_non_optional')
        : this.$t('apps.product_group_reports.edit_form.notify_users');
    },
  },
};
</script>
