<template>
  <DashboardPageLayout>
    <template #top>
      <PrintLayout :caption="printTitle" />

      <!-- eslint-disable vue/no-mutating-props -->
      <p
        v-if="forInternal"
        class="for-print"
      >
        {{ $t('generic.for_internal_use_only') }}
      </p>
      <NotationCard
        :selected-graph-limits="selectedGraphLimits"
        :primary-notation-loader="controller.selection.primary"
        :secondary-notation-loader="controller.selection.secondary"
        :selected-date="controller.comparisonDate"
        :dashboard-header-modal-title="printTitle"
        :for-internal-use="forInternal"
        @change-selected-date="(date) => controller.comparisonDate = date"
        @update:selectedGraphLimits="$emit('update:selectedGraphLimits', $event)"
      >
        <!-- eslint-enable vue/no-mutating-props -->
        <template #footer="{ primaryNotationDetails, secondaryNotationDetails }">
          <NotationCardFooter
            :selection="controller.selection"
            :primary-notation-details="primaryNotationDetails"
            :secondary-notation-details="secondaryNotationDetails"
            :graph-limits="selectedGraphLimits"
            :can-export-raw-data="user.can_export_raw_data"
            @clear-secondary-selection="controller.clearSecondarySelection()"
          />
        </template>
      </NotationCard>

      <NotationListHeader
        v-if="forInternal"
        :caption="controller.caption"
        :controller="controller"
      />
    </template>

    <template #bottom>
      <div
        v-exclude-from-print="!$isMailExport"
        class="data-table--sticky-margin"
      />
      <NotationList
        v-if="forInternal"
        :controller="controller"
      />
    </template>
  </DashboardPageLayout>
</template>

<script>
import { DashboardPageLayout } from '@/components/Layouts';
import {
  PrintLayout,
  NotationCardFooter,
} from '@/components';
import NotationCard from '@/components/NotationCard/Component.vue';
import NotationListHeader from '@/components/NotationList/NotationListHeader.vue';
import NotationList from '@/components/NotationList/NotationList.vue';

export default {
  name: 'NotationListPage',
  components: {
    PrintLayout,
    DashboardPageLayout,
    NotationCard,
    NotationCardFooter,
    NotationListHeader,
    NotationList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    controller: {
      type: Object,
      required: true,
    },
    printTitle: {
      type: String,
      required: true,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:selectedGraphLimits'],
  data() {
    return {
      isMailExport: false,
      forInternal: this.controller.forInternalUse ?? true,
    };
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.isMailExport = urlParams.get('mail_export') === '1';
  },
};
</script>
