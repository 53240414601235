<template>
  <AutoComplete
    v-model="state.selectedKey"
    class="currency-combo-box"
    :options="state.options"
    :model-value="modelValue"
    :access-key="(o) => o.id"
    :access-value="(o) => o.name"
    :allow-empty-value="true"
    :placeholder="placeholder"
    :marked-option="preferredDashboardCurrencyId"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #option="{ option, accessKey, accessValue }">
      <CurrencyComboBoxOption
        :option="option"
        :access-key="accessKey"
        :access-value="accessValue"
        :preferred-dashboard-currency-id="preferredDashboardCurrencyId"
      />
    </template>
  </AutoComplete>
</template>

<script>
import { reactive, onBeforeMount } from 'vue';
import UnitsApi from '@/api/UnitsApi';
import i18n from '@/config/i18n';
import CurrencyComboBoxOption from './Option/Component.vue';

export default {
  name: 'CurrencyComboBox',
  components: { CurrencyComboBoxOption },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    preferredDashboardCurrencyId: {
      type: [String, Number],
      default: null,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup() {
    const state = reactive({
      options: [],
      selectedKey: null,
    });
    const accessKey = (option) => option?.id;
    const accessValue = (option) => option?.name;
    const placeholder = i18n.t('components.currency_combobox.placeholder');
    onBeforeMount(async () => {
      state.options = await UnitsApi.currencies();
    });
    return {
      state,
      accessKey,
      accessValue,
      placeholder,
    };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>