import { BaseController } from '@/components/NotationListPage/BaseController';
import PaginationService from '@/services/PaginationService';
import ApiHelpers from '@/api/ApiHelpers';
import DashboardApi from '@/api/DashboardApi';
import DashboardNotationsRepository from '@/apps/Dashboard/controllers/DashboardNotationRepository';

const ChartConfigControllerConfig = (dashboard) => {
  const notationRepository = new DashboardNotationsRepository(dashboard);
  const requestFunction = (options) => (
    notationRepository.notations(options.data, options.cancelToken));
  return {
    dashboard,
    i18nPrefix: 'multi_line_chart_config_modal',
    notationListApi: DashboardApi(dashboard),
    printAllNotations: true,
    hiddenColumns: [
      'is_hidden_for_customisation',
      'is_hidden_for_clients',
      'watched',
      'sub_term',
      'dashboard_name',
      'timescope',
      'attribute_economy',
      'dataset',
      'attribute_unit',
      'latest_value',
      'yoy_percent',
      'mom_percent',
      'wow_percent',
      'dod_percent',
      'custom_dod_percent',
      'custom_dod_percent_over_min',
      'custom_dod_percent_over_max',
      'custom_dod_percent_over_avg',
      'current_value_range',
      'correlation_1m',
      'correlation_2m',
      'correlation_3m',
      'preview',
    ],
    pagination: new PaginationService({
      requestFunction,
      transformEntry: (e) => ({ isChecked: false, ...e }),
      errorHandler: ApiHelpers.handleError,
    }),
  };
};

class ChartConfigController extends BaseController {
  constructor(dashboard, user, { isMailExport, media }) {
    const config = ChartConfigControllerConfig(dashboard, user);
    const state = {
      selection: {
        primary: null,
        secondary: null,
      },
    };
    super(config, user, state, {}, { isMailExport, media });
  }
}

export default ChartConfigController;
