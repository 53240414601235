<template>
  <div
    class="input"
    :class="cssModifier"
  >
    <Icon
      class="input--icon"
      :name="isLoading ? 'spinner' : 'search'"
      variant="light"
      :spin="isLoading"
    />
    <div class="input--wrapper">
      <DashboardSearchInputTagList
        class="input--tag-list"
        :tags="tags"
        :wrap="ariaExpanded"
        @click-tag="removeTag"
      />
      <input
        ref="input"
        class="input--field"
        type="text"
        :value="modelValue"
        :aria-label="$t('apps.dashboard_search.label')"
        :placeholder="placeholder"
        :autofocus="autofocus"
        role="combobox"
        aria-autocomplete="list"
        :aria-expanded="ariaExpanded"
        aria-haspopup="true"
        :aria-owns="ariaOwns"
        :aria-activedescendant="ariaActivedescendant"
        @input="onInput"
        @focus="$emit('focus', $event)"
        @keydown="onKeyDown"
      >
    </div>
  </div>
</template>

<script>
import DashboardSearchInputTagList from './TagList/Component.vue';

export default {
  name: 'DashboardSearchInput',
  components: {
    DashboardSearchInputTagList,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    ariaExpanded: {
      type: Boolean,
      default: false,
    },
    ariaOwns: {
      type: String,
      default: null,
    },
    ariaActivedescendant: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
    'input',
    'focus',
    'next',
    'prev',
    'submit',
    'close',
    'remove-tag',
  ],
  computed: {
    cssModifier() {
      const modifier = [];
      if (this.ariaExpanded) modifier.push('is-open');
      return modifier.join(' ');
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onKeyDown(event) {
      const inputIsEmpty = this.modelValue === '';
      const backspacePressed = event.key === 'Backspace';
      if (inputIsEmpty && backspacePressed) {
        event.preventDefault();
        this.removeLastTag();
      } else if (event.key === 'ArrowDown') {
        this.$emit('next');
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        this.$emit('prev');
        event.preventDefault();
      } else if (event.key === 'Enter') {
        this.$emit('submit');
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.$emit('close');
        event.preventDefault();
      }
    },
    removeTag(tag) {
      this.$emit('remove-tag', tag);
    },
    removeLastTag() {
      if (this.tags.length === 0) return;
      const tag = this.tags[this.tags.length - 1];
      this.removeTag(tag);
    },
    onInput(event) {
      this.$emit('input', event);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>