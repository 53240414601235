<template>
  <div
    v-if="!pagination.isLoading && pagination.entries.length === 0"
    class="list--empty-message"
  >
    {{ $t('apps.publications.list.empty') }}
  </div>
  <div v-else>
    <PublicationsTotalUnreadCount
      :unread-count="unreadCount"
      @mark-all-as-read="$emit('markAllAsRead')"
    />
    <ul>
      <li
        v-for="item in pagination.entries"
        :key="item.id"
        class="news-list--item"
      >
        <NewsListItem
          :news="item"
          :editorial-controller="editorialController"
        />
      </li>
    </ul>
    <SpinningLoader v-if="pagination.isLoading" />
    <InfiniteScroll
      v-else-if="pagination.hasNextPage"
      @trigger="fetchMoreNews"
    />
  </div>
</template>

<script>
import {
  InfiniteScroll,
  PublicationsTotalUnreadCount,
} from '@/components';
import NewsListItem from './Item/Component.vue';

export default {
  name: 'NewsList',
  components: {
    InfiniteScroll,
    PublicationsTotalUnreadCount,
    NewsListItem,
  },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    editorialController: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['markAllAsRead'],
  computed: {
    unreadCount() {
      return this.pagination.meta?.unread_count;
    },
  },
  methods: {
    fetchMoreNews() {
      this.pagination.fetchNextPage();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>