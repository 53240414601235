const KEY_SPLITTER = '-';

export const DEFAULT_STORAGE = localStorage;
export const STORAGE_SPLITTER = '::';
export const STORAGE = {
  LOCAL: 'local',
};

export const storageMap = {
  [STORAGE.LOCAL]: localStorage,
};

const KEY_HUMAN_RIGHTS_RISKS = `${STORAGE.LOCAL}${STORAGE_SPLITTER}human_rights_risks${KEY_SPLITTER}`;

export const KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS = `${KEY_HUMAN_RIGHTS_RISKS}table_filters`;
export const KEY_HUMAN_RIGHTS_RISKS_MAP_FILTERS = `${KEY_HUMAN_RIGHTS_RISKS}country_risks_map_filters`;
