export default {

  props: {
    requires: {
      type: String
    }
  },

  methods: {
    getFilterLabel(filterName) {
      filterName
    }
  },

  computed: {
    requiredFiltersPresent () {
      return this.requiredFilters.every((filterName) => {
        return this.filters && this.filters[filterName]
      })
    },

    requiredFilters() {
      if (!this.requires) { return [] }

      return this.requires.split(',').map((item) => item.trim())
    }
  },

  data() {
    return {
      filters: {},
      filterLabels: {}
    }
  }

}
