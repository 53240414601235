<template>
  <ul :class="`tag-list is-${orientation}`">
    <li
      v-for="tag in tags"
      :key="tag.id"
      class="tag-list--item"
    >
      <DashboardSearchListTag
        :id="tag.key"
        :type="tag.type"
        :name="tag.name"
        :is-active="selectedId === tag.key"
        @click="onSelect(tag)"
        @mousemove="onFocus(tag)"
      />
    </li>
    <li
      v-if="$slots.trailing"
    >
      <slot name="trailing" />
    </li>
  </ul>
</template>

<script>
import DashboardSearchListTag from '../Tag/Component.vue';

export default {
  name: 'DashboardSearchListTagList',
  components: {
    DashboardSearchListTag,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator: (v) => ['horizontal', 'vertical'].includes(v),
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: [Number, String],
      default: null,
    },
  },
  emits: [
    'focus',
    'select',
  ],
  methods: {
    onSelect(tag) {
      this.$emit('select', tag);
    },
    onFocus(tag) {
      this.$emit('focus', tag);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>