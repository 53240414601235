const digJson = function(item, key) {
  if (!key) { return }

  let value = item
  key.split('.').forEach((key)=>{
    if (!value) { return }
    value = value[key]
  });

  return value
}

export { digJson }
