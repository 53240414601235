<template>
  <div
    class="modal-container"
    v-if="active || forceActive"
    @click="backgroundClicked"
    @keydown.esc="close"
  >
    <div class="modal" ref="body">
      <div v-if="customContent" v-html="customContent" class="pb-m"></div>
      <slot v-else/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      forceActive: false,
      customContent: null
    }
  },

  // Programmatically close a modal
  close() {
    let modal = document.querySelector("#modal-dialog")
    modal?.remove()
  },

  // Programmatically open a modal with a custom content
  // Example:
  //     import Modal from "components/generic/Modal"
  //     Modal.open("Hello!")
  open(content, props={}) {
    this.close()

    let modal = this.buildModal(props)
    modal.vue.customContent = content
    modal.vue.forceActive = true

    let customEvent = new CustomEvent('X-Content-Injected', { detail: modal.vue.$el })
    document.dispatchEvent(customEvent)
  },

  buildModal(props) {
    let modal = document.createElement("modal-dialog");
    modal.id = "modal-dialog";
    modal.dataset.props = JSON.stringify(props)

    let wrapper = document.createElement("div");
    wrapper.appendChild(modal)
    document.body.appendChild(wrapper)

    // make sure the modal is initialized properly as a custom web component
    let customEvent1 = new CustomEvent('X-Content-Injected', { detail: wrapper })
    document.dispatchEvent(customEvent1)

    return modal
  },

  mounted() {
    // explicitly close the modal when the user hits the escape key as
    // the "@keydown.esc" directive seems to not work properly in all browsers
    document.addEventListener("keydown", (event)=>{
      if (event.key === "Escape") {
        this.close()
      }
    })
  },

  watch: {
    active() {
      setTimeout(()=>{
        this.toggleFixedContentTop()
      }, 100)

      if (this.active) {
        // body.modal-open is used to remove position: sticky while modal is open
        // see /app/assets/stylesheets/generic-components/_data-table.scss:81
        document.body.classList.add("modal-open");
        setTimeout(()=>{
          // If there is an input with autofocus inside the
          // modal we make sure it keeps the focus while the
          // modal is visible
          let input = this.autoFocusInput()
          input?.focus()
          input?.addEventListener("blur", ()=>{
            setTimeout(()=>{
              input.focus()
            })
          })
        }, 0);
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  },

  methods: {
    autoFocusInput() {
      if (!this.$el.querySelector) { return }
      return this.$el.querySelector("input[autofocus]")
    },

    // setting the top to a fixed value makes sure that the modal is
    // not jumping if the content changes (e.g. while filtering with
    // an autocomplete)
    toggleFixedContentTop() {
      if (!this.$refs.body) {
        return
      }
      if (this.active) {
        this.$refs.body.style.minHeight = `${this.$refs.body.offsetHeight}px`
      } else {
        this.$refs.body.style.minHeight = ""
      }
    },

    backgroundClicked(event) {
      if (!event.target.isConnected) {
        // if an item has already been removed since the click,
        // we treat it as a click that should not close the modal
        return
      }
      if (this.$refs.body.contains(event.target)) {
        return
      }
      this.close()
    },
    close() {
      this.forceActive = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.modal-container {
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);

  .modal {
    margin: auto;
    min-height: 200px;
    min-width: 200px;
    max-width: 80vh;
    max-width: 650px;
    max-height: 80vh;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
  }
  &.is-overflowable .modal {
    overflow-y: visible;
  }
  &.is-wide .modal {
    min-width: 600px;
  }
  &.align-top .modal {
    margin-top: 10%;
  }
}
</style>
