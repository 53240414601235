<template>
  <div class="any-chart-map__toolbar">
    <div
      class="any-chart-map__move-buttons"
      :class="{'hidden': +zoomLevel === 1}"
    >
      <button
        @mouseup="stopMoveMap"
        @mousedown="moveMapLeft"
      >
        <Icon
          class="button-icon"
          name="arrow-left"
          variant="solid"
        />
      </button>
      <button
        @mouseup="stopMoveMap"
        @mousedown="moveMapTop"
      >
        <Icon
          class="button-icon"
          name="arrow-up"
          variant="solid"
        />
      </button>
      <button
        @mouseup="stopMoveMap"
        @mousedown="moveMapRight"
      >
        <Icon
          class="button-icon"
          name="arrow-right"
          variant="solid"
        />
      </button>
      <button
        @mouseup="stopMoveMap"
        @mousedown="moveMapBottom"
      >
        <Icon
          class="button-icon"
          name="arrow-down"
          variant="solid"
        />
      </button>
    </div>
    <div class="any-chart-map__zoom-input-wrapper">
      <input
        :class="{'active': zoomLevel > 1}"
        class="any-chart-map__zoom-input"
        type="range"
        min="1"
        max="10"
        step="0.001"
        :value="zoomLevel"
        @change="(event) => {
          $emit('zoom-input', event.target.value)
        }"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountryMapToolbar',
  props: {
    zoomLevel: {
      type: [String, Number],
      default: 1,
    },
  },
  emits: ['zoom-input', 'mouse-up', 'mouse-down'],
  methods: {
    moveMapTop() { this.$emit('mouse-down', 'top'); },
    moveMapLeft() { this.$emit('mouse-down', 'left'); },
    moveMapRight() { this.$emit('mouse-down', 'right'); },
    moveMapBottom() { this.$emit('mouse-down', 'bottom'); },
    stopMoveMap() { this.$emit('mouse-up'); },
  },
};
</script>

<style scoped lang="scss">
.any-chart-map {
  &__toolbar {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 95px;
    left: 15px;
  }
  &__zoom-input-wrapper {
    width: 118px;
    position: relative;
    transform: rotate(-90deg) translate(-50%, -115%);
  }
  &__zoom-input {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    &.active {
      &::-webkit-slider-runnable-track {
        background: #c9c9c9;
      }
      &::-moz-range-track {
        background: #c9c9c9;
      }
    }
    &::-webkit-slider-runnable-track {
      background: #e3e3e3;
      height: 20px;
      border-radius: 3px;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      transition: all 0.3s;
    }
    &::-moz-range-track {
      background: #e3e3e3;
      height: 20px;
      border-radius: 3px;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      transition: all 0.3s;
    }

    &::-webkit-slider-thumb
    {
      -webkit-appearance: none;
      appearance: none;
      width: 24px;
      height: 24px;
      margin-top: -2px;
      background: #d60e2a url('~images/zoom-icon.svg') no-repeat center center;
      border-radius: 3px;
      transform: rotate(180deg);
      transition: all 0.3s;
      &:hover {
        transform: rotate(180deg) scale(1.1);
        background-color: lighten(#d60e2a, 15);
      }
    }
    &::-moz-range-thumb {
      appearance: none;
      border: none;
      width: 24px;
      height: 24px;
      background: #d60e2a url('~images/zoom-icon.svg') no-repeat center center;
      border-radius: 3px;
      transform: rotate(180deg);
      transition: all 0.3s;
      &:hover {
        transform: rotate(180deg) scale(1.1);
        background-color: lighten(#d60e2a, 15);
      }
    }
   }

  &__move-buttons {
    width: 60px;
    height: 60px;
    position: relative;
    margin-bottom: 30px;
    &.hidden {
      visibility: hidden;
    }
    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      padding: 5px;
      position: absolute;
      background: #e3e3e3;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      transition: all 0.3s;
      &:active {
        background: #c9c9c9;
      }
      &:hover {
        background: #c9c9c9;
      }
      &:nth-child(1) {
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(45deg);
      }
      &:nth-child(2) {
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
      &:nth-child(3) {
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(45deg);
      }
      &:nth-child(4) {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
    & .button-icon {
      width: 10px;
      height: auto;
      transform: rotate(-45deg);
    }
  }
}
</style>
