<template>
  <div class="action">
    <Icon
      v-if="publication.isBusy"
      name="spinner"
      :spin="true"
      :fixed-width="true"
    />
    <template v-else>
      <ButtonWithDropdown
        v-if="controller.canEdit"
        icon="ellipsis-v"
        variant="ternary"
        size="large"
        padding="none"
        :button-aria-label="$t('apps.publications.list.actions')"
        :icon-fixed-width="true"
        dropdown-alignment="right"
      >
        <template #dropdown>
          <DropdownMenu>
            <DropdownMenuButton
              icon="arrow-down"
              :aria-label="actionLabel"
              @click="controller.download(publication)"
            >
              {{ actionLabel }}
            </DropdownMenuButton>
            <DropdownMenuButton
              icon="pencil"
              @click="controller.edit(publication)"
            >
              {{ $t("apps.publications.list.edit") }}
            </DropdownMenuButton>
            <DropdownMenuButton
              icon="trash-alt"
              @click="controller.deleteAfterConfirmation(publication)"
            >
              {{ $t("apps.publications.list.delete") }}
            </DropdownMenuButton>
          </DropdownMenu>
        </template>
      </ButtonWithDropdown>
      <ButtonComponent
        variant="ternary"
        size="large"
        padding="none"
        icon="arrow-circle-down"
        :icon-fixed-width="true"
        :aria-label="actionLabel"
        @click="controller.download(publication)"
      />
    </template>
  </div>
</template>

<script>
import i18n from "@/config/i18n";

export default {
  name: "PublicationsListItemActions",
  props: {
    controller: {
      type: Object,
      required: true
    },
    publication: {
      type: Object,
      required: true
    }
  },
  setup() {
    const actionLabel = i18n.t("apps.publications.list.download");
    return { actionLabel };
  }
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>