<template>
  <form @submit="submit($event)">
    <TextInput
      ref="nameInput"
      v-model="dashboard.name"
      :required="true"
      :label="$t('attributes.dashboard.name')"
      :autofocus="true"
    />

    <ul class="dialog--action-list">
      <li class="dialog--action-list-item">
        <ButtonComponent
          variant="ternary"
          @click="cancel()"
        >
          {{ $t('components.modals.cancel') }}
        </ButtonComponent>
      </li>
      <li class="dialog--action-list-item">
        <ButtonComponent
          type="submit"
          variant="primary"
          :is-disabled="!canSubmit"
        >
          {{ $t('components.add_to_dashboard_modal.create_button') }}
        </ButtonComponent>
      </li>
    </ul>
  </form>
</template>

<script>
export default {
  name: 'CreateDashboardForm',
  emits: [
    'cancel',
    'submit',
  ],
  data() {
    return {
      dashboard: {
        name: '',
      },
    };
  },
  computed: {
    canSubmit() {
      return this.dashboard.name.trim() !== '';
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit(e) {
      e.preventDefault();
      if (this.canSubmit) {
        this.$emit('submit', this.dashboard);
      }
    },
  },
};
</script>
