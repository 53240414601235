<template>
  <td class="data-table--body-cell" v-on="eventListeners">
    <span>{{ value.text }}</span>
    <i :class="value.modifiers">
      <Icon :name="value.name" :variant="value.variant" :fixed-width="true" class="notation-table--icon"
        :aria-label="value.ariaLabel" />
    </i>
  </td>
</template>

<script>
export default {
  name: 'TextColumnWithIcon',
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  position: relative;
}
span {
  margin-right: auto;
}
i {
  position: absolute;
  right: 0.5em;
  height: 10px;
  margin-top: -2px;
}

</style>
