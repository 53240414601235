<template>
  <SplitPageLayout
    screen-size="narrow"
    :with-separator="false"
  >
    <template #bottom>
      <ul>
        <li
          v-for="(tutorial, index) in videoTutorials"
          :key="index"
          class="video-tutorial--card"
        >
          <VideoTutorialCard
            v-bind="tutorial"
          />
        </li>
      </ul>
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import VideoTutorialCard from '@/components/VideoTutorialCard/Component.vue';

export default {
  name: 'VideoTutorialsApp',
  components: { VideoTutorialCard, SplitPageLayout },
  props: {
    videoTutorials: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>