<template>
  <div class="subscriptions--header">
    <SubscriptionsTitle />
    <SubscriptionsSettingsControl
      :are-buttons-enabled="areButtonsEnabled"
      @save="$emit('save')"
      @reset="$emit('reset')"
    />
  </div>
</template>

<script>
import SubscriptionsTitle from '../SubscriptionsTitle/Component.vue';
import SubscriptionsSettingsControl from '../SubscriptionsSettingsControl/Component.vue';

export default {
  name: 'SubscriptionsHeader',
  components: { SubscriptionsTitle, SubscriptionsSettingsControl },
  props: {
    areButtonsEnabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['save', 'reset'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>