<template>
  <li class="entry">
    <div
      class="entry--icon"
      :class="iconClass"
    >
      <Icon
        :name="iconName"
        variant="solid"
        :fixed-width="true"
      />
    </div>
    <div class="entry--main">
      <div class="entry--name">
        {{ file.file.name }}
      </div>
      <div class="entry--details">
        {{ detailsText }}
      </div>
    </div>
    <ButtonComponent
      v-if="file.status === null"
      class="entry--icon"
      variant="ternary"
      icon="times"
      :aria-label="$t('apps.publications.upload_modal.remove_file')"
      @click="remove()"
    />
    <template v-else-if="file.status === 'pending' || file.status === 'uploading'">
      <div
        v-if="file.progress !== null"
        class="entry--progress"
      >
        {{ $n(file.progress, 'integer') }}%
      </div>
      <SpinningLoader
        :text="null"
        class="entry--icon"
      />
    </template>
    <Icon
      v-else
      :name="statusIconName"
      class="entry--icon"
      :class="statusIconClass"
    />
  </li>
</template>

<script>
export default {
  name: 'PublicationsUploadModalFileListEntry',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  emits: ['remove'],
  computed: {
    iconClass() {
      return `for-${this.file.action}`;
    },
    iconName() {
      return {
        create: 'file-plus',
        replace: 'file-edit',
      }[this.file.action] || 'file';
    },
    statusIconClass() {
      if (!this.file.status) return null;
      return `is-${this.file.status}`;
    },
    statusIconName() {
      return {
        done: 'check',
        failed: 'times',
      }[this.file.status];
    },
    detailsText() {
      if (this.file.action === 'create') {
        return this.$t('apps.publications.upload_modal.preview_details.create');
      } if (this.file.action === 'replace') {
        if (this.file.matchingPublication.display_name) {
          return this.$t('apps.publications.upload_modal.preview_details.replace_named', {
            name: this.file.matchingPublication.display_name,
          });
        }
        return this.$t('apps.publications.upload_modal.preview_details.replace_unnamed');
      }
      return null;
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.file);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>