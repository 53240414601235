<template>
  <th
    class="data-table--head-cell"
    scope="col"
    :aria-label="displayName"
  >
    <IconTooltipComponent
      v-if="tooltip"
      class="data-table--head-cell-content is-centered"
      :tooltip="tooltip"
      y-alignment="bottom"
    />
  </th>
</template>

<script>
export default {
  name: 'EmptyColumnHead',
  props: {
    displayName: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>
