<template>
  <nav class="app-navigation">
    <ul class="app-navigation--list">
      <NavigationDropdownItem
        :name="$t('page_titles.human_rights')"
        icon="question-circle"
        :custom-icon="hrCustomIcon"
        :custom-icon-classes="'hr-nav-logo'"
        :auto-width="true"
      >
        <NavigationHumanRightsDropdown :human-rights="humanRights" :country-portraits-default-path="countryPortraitsDefaultPath" />
      </NavigationDropdownItem>
      <NavigationItem
        v-if="user.has_market_prices_subscription"
        href="/watch-list"
        :name="$t('page_titles.watch_list')"
        icon="binoculars"
        :highlight="isCurrentPage('watch_list')"
        :tooltip="$t('layouts.app_navigation.tooltips.watch_list')"
      />
      <NavigationItem
        v-if="user.has_market_prices_subscription"
        href="/analyses"
        :name="$t('layouts.app_navigation.notation_analyses')"
        icon="chart-line"
        :highlight="isCurrentPage('notation_analyses')"
        :tooltip="$t('layouts.app_navigation.tooltips.notation_analyses')"
      />
      <NavigationItem
        v-if="user.has_market_prices_subscription"
        :href="trainingsPath"
        :name="$t('layouts.app_navigation.trainings')"
        icon="chalkboard"
        :tooltip="$t('layouts.app_navigation.tooltips.trainings')"
      />
      <NavigationDropdownItem
        :name="$t('page_titles.help')"
        icon="question-circle"
        :auto-width="true"
      >
        <NavigationHelpDropdown :helpline-email="helplineEmail" />
      </NavigationDropdownItem>
    </ul>
    <NavigationUserMenu :user="user" />
  </nav>
</template>

<script>
import {
  PathToNewsWithFilters,
} from '@/helpers/PublicationHelpers';
import {
  NavigationItem,
  NavigationDropdownItem,
  NavigationHelpDropdown,
  NavigationHumanRightsDropdown,
  NavigationUserMenu,
} from './components';
import RoutingParams from './RoutingParams';
import hrLogo from '../../../assets/images/hr-logo.svg';

export default {
  name: 'NavigationApp',
  components: {
    NavigationItem,
    NavigationDropdownItem,
    NavigationHelpDropdown,
    NavigationHumanRightsDropdown,
    NavigationUserMenu,
  },
  provide() {
    return { RoutingParams: this.RoutingParams, user: this.user };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    pathParameters: {
      type: Object,
      required: true,
    },
    helplineEmail: {
      type: String,
      required: true,
    },
    productGroupTrainingId: {
      type: Number,
      required: true,
    },
    countryPortraitsDefaultPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      RoutingParams: new RoutingParams(this.pathParameters),
      hrCustomIcon: hrLogo,
    };
  },
  computed: {
    trainingsPath() {
      const trainingsFilters = {
        selectedProductGroups: [this.productGroupTrainingId],
        selectedSortDirection: 'published_at_desc',
      };
      return PathToNewsWithFilters(trainingsFilters);
    },
  },
  methods: {
    isCurrentPage(name) {
      return this.RoutingParams.matches(name);
    },
  },
};
</script>
<style lang="scss">
  .hr-nav-logo {
    margin-right: 10px;
    width: 14px;
    height: auto;
    transition: all 0.2s;
  }
</style>
