<template>
  <div class="bar">
    <StatusBreadcrumbsItem
      :is-active="!showNotations"
      :text="$t('apps.chart_config.status_bar.select_dashboard')"

      @click="$emit('back')"
    />
    <Icon
      class="bar-arrow"
      name="arrow-right"
      variant="regular"
    />
    <StatusBreadcrumbsItem
      :is-active="showNotations"
      :not-clickable="true"
      :text="selectNotationsTitle"
    />
  </div>
</template>

<script>
import i18n from '@/config/i18n';
import StatusBreadcrumbsItem from './StatusBreadcrumbsItem/Component.vue';

export default {
  name: 'StatusBreadcrumbs',
  components: {
    StatusBreadcrumbsItem,
  },
  props: {
    showNotations: {
      type: Boolean,
      required: true,
    },
    maxNotationsCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ['back'],
  computed: {
    selectNotationsTitle() {
      return this.maxNotationsCount > 1 ? i18n.t('apps.chart_config.status_bar.select_notations')
        : i18n.t('apps.chart_config.status_bar.select_notation');
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>