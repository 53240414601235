<template>
  <div class="default-header">
    <img
      :src="LogoImage"
      alt="HGS Research"
      class="default-header--logo"
    >
    <h1 class="default-header--caption">
      {{ caption }}
    </h1>

    <i18n-t
      keypath="components.print_header.exported_on"
      tag="p"
      class="default-header--meta"
    >
      <template #name>
        <a :href="$routes.Root()">HGS Research</a>
      </template>
      <template #date>
        {{ exportDate }}
      </template>
    </i18n-t>
  </div>

  <div class="default-header-content">
    <slot name="content"/>
  </div>
</template>

<script>
import moment from 'moment';
import LogoImage from '@/../assets/images/hgs-logo-with-name.svg';

export default {
  name: 'PrintLayoutInstanceDefaultHeader',
  props: {
    caption: {
      type: String,
      default: null,
    },
  },
  data() {
    return { LogoImage };
  },
  computed: {
    exportDate() {
      // Since the PDF process does not relay the user time zone currently
      // we just always print UTC and make that clear in the display string.
      const now = new Date();
      return `${moment(now).utc().format('LLL')} UTC`;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>