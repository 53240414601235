<template>
  <PrintLayout :header-only-first-page="true">
    <template #header>
      <PrintLayoutInstanceDefaultHeader class="low-margin-bottom" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayout>
  <div class="portraits__container">
    <div class="portraits__row mt-2">
      <h1>
        {{ t("apps.human_rights.sector_portraits.headline_one") }} |
        {{ t("apps.human_rights.sector_portraits.headline_two") }}
      </h1>
    </div>
    <div class="portraits__row hr-legend mt-2 justify-right">
      <HumanRightsRiskLegend :column-amount="2" wrapper-classes="w-100" />
    </div>
  </div>
  <div v-if="!initialLoad" class="portraits__container">
    <SpinningLoader class="mt-2" />
  </div>
  <div v-if="initialLoad" class="portraits__container portraits__container--scrollable">
    <div class="portraits__row portraits--sticky">
      <ExtendableTableHeader>
        <table class="data-table">
          <tbody class="data-table--body">
            <tr class="wide-combobox">
              <td class="data-table--body-cell">
                <SpinningLoader text="" v-if="tableController.isLoading" />
              </td>
              <td class="data-table--body-cell">
                <strong>{{ t("apps.sector_portraits.tables.header.cta_dropdowns") }} &rsaquo;</strong>
              </td>
              <td class="data-table--body-cell" v-for="(_, index) in selectedSectors" :key="index">
                <SimpleAutocomplete
                  endpoint="/api/classifications"
                  v-model="selectedSectors[index].value"
                  @update:model-value="getSectorData(index, $event)"
                  :description="t('apps.sector_portraits.sector')"
                  :static-filters="{ kind: 'nace' }"
                ></SimpleAutocomplete>
              </td>
            </tr>
          </tbody>
        </table>
      </ExtendableTableHeader>
    </div>
    <div class="portraits__row mb-2">
      <NotationList
        :sticky-margin-zero-top="true"
        :controller="tableController"
        class="portraits__data-table--head portraits__data-table--clickable portraits__data-table--highlighted"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { AutoComplete, SpinningLoader } from "@/components/generic";
import { HumanRightsRiskLegend, ExtendableTableHeader, NotationList } from "@/components";
import SectorPortraitsApi from "@/api/SectorPortraitsApi";
import PrintLayout from "../../components/PrintLayout/Component.vue";
import PrintLayoutInstanceDefaultHeader from "../../components/PrintLayout/Instance/DefaultHeader/Component.vue";
import PortraitsHRScoreTableController from "@/apps/Portraits/controllers/PortraitsHRScoreTableController";
import { usePortraitsDropdowns } from "./composables/usePortraitsDropdowns";
import Turbolinks from "turbolinks";
import SimpleAutocomplete from "../../components/SimpleAutocomplete.vue";

const initialLoad = ref(false);
const isLoading = ref(false);

const { t } = useI18n();

const { sectorsForSelect, hrDimensionsForTable, prefilledSectorIds } = defineProps({
  sectorsForSelect: {
    type: Array,
    default: () => [],
  },
  hrDimensionsForTable: {
    type: [Array, Object],
    default: () => [],
  },
  prefilledSectorIds: {
    type: Array,
    required: true,
  },
});

const sectorData = reactive({ sectors: {} });

const tableKey = reactive({ key: 0 });

const {
  selectedSubject01: selectedSector01,
  selectedSubject02: selectedSector02,
  selectedSubject03: selectedSector03,
  selectedSubject04: selectedSector04,
  selectedSubject05: selectedSector05,
  selectedSubject06: selectedSector06,
} = usePortraitsDropdowns(...prefilledSectorIds);

const tableController = new PortraitsHRScoreTableController(t);

const tableHRInit = () => {
  tableController.init({
    subjects: sectorData.sectors,
    hrDimensionsForTable,
    columnTranslationKey: "apps.sector_portraits.tables.hr_score",
  });
  initialLoad.value = true;
  tableController.isLoading = false;
};

const changeTables = () => {
  tableController.updateSubjects(sectorData.sectors);
  tableKey.key = Turbolinks.uuid();
  tableController.isLoading = false;
};

const getSectorData = (index, sectorId) => {
  tableController.isLoading = true;
  SectorPortraitsApi()
    .getSectorData(sectorId)
    .then((response) => {
      sectorData.sectors[index] = [sectorId, response];
      if (initialLoad.value === true) {
        changeTables();
      }
    });
};

const selectedSectors = [
  selectedSector01,
  selectedSector02,
  selectedSector03,
  selectedSector04,
  selectedSector05,
  selectedSector06,
];

selectedSectors.forEach((sector, index) => {
  getSectorData(index, sector.value);
});

watch(sectorData, () => {
  // TODO make debouncer from helper work
  if (Object.keys(sectorData.sectors).length === 6) {
    if (initialLoad.value === false) {
      tableHRInit();
    }
  }
}, { deep: true });
</script>

<style lang="scss">
@import "@/apps/Portraits/style.screen.scss";
.wide-combobox {
  .combobox--popup {
    left: auto;
    right: 0;
    width: 400px;
    min-width: 100%;
    .combobox--default-option {
      white-space: initial;
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/apps/Portraits/style-scoped.screen.scss";
.portraits__container--scrollable {
  max-height: none;
  height: 100%;
}
</style>
