<template>
  <thead class="subscriptions--table-head">
    <tr class="subscriptions--table-row has-border-bottom">
      <th
        colspan="2"
        class="subscriptions--table-header-noheader"
      />
      <th class="subscriptions--table-options-header has-border-left">
        {{ $t('apps.subscriptions.daily') }}
      </th>
      <th class="subscriptions--table-options-header">
        {{ $t('apps.subscriptions.weekly') }}
      </th>
      <th class="subscriptions--table-options-header">
        {{ $t('apps.subscriptions.monthly') }}
      </th>
      <th class="subscriptions--table-options-header">
        {{ $t('apps.subscriptions.never') }}
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'SubscriptionsTableHead',
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>