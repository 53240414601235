<template>
  <WithTooltip
    class="icon-tooltip"
    :tooltip="tooltip"
    :x-alignment="xAlignment"
    :y-alignment="yAlignment"
    :extra-wide="extraWide"
  >
    <Icon
      :name="name"
      :variant="variant"
      :fixed-width="fixedWidth"
    />
  </WithTooltip>
</template>

<script>
export default {
  name: 'IconTooltipComponent',
  props: {
    xAlignment: {
      type: String,
      default: 'auto',
    },
    yAlignment: {
      type: String,
      default: 'auto',
    },
    name: {
      type: String,
      default: 'info-circle',
    },
    variant: {
      type: String,
      default: 'regular',
    },
    tooltip: {
      type: String,
      required: true,
    },
    fixedWidth: {
      type: Boolean,
      default: true,
    },
    extraWide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>