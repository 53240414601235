const SORT_ASC = 'asc';
const SORT_DESC = 'desc';

const compareValues = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const compareBy = (accessor) => (a, b) => compareValues(
  accessor(a),
  accessor(b),
);

const compareTableValues = (array, accessor, direction) => [...array].sort((left, right) => {
  const leftValue = accessor(left);
  const rightValue = accessor(right);
  let multiplicator = 1;

  if (direction === SORT_DESC) {
    multiplicator = -1;
  }

  if (typeof leftValue === 'string') {
    return new Intl.Collator(navigator.language).compare(leftValue, rightValue) * multiplicator;
  }

  return compareValues(leftValue, rightValue) * multiplicator;
});

const onColumnSortClick = ({ clickedColumn, currentColumn, currentOrder, compare }) => {
  compare ||= function(item) { return item }

  if (compare(clickedColumn) != compare(currentColumn)) {
    return {
      order: 'asc',
      column: clickedColumn
    }
  } else if(currentOrder == 'asc') {
    return {
      order: 'desc',
      column: currentColumn
    }
  } else {
    return {
      order: 'unsorted',
      column: null
    }
  }
}

export {
  compareValues,
  compareBy,
  compareTableValues,
  onColumnSortClick,
  SORT_ASC,
  SORT_DESC,
};
