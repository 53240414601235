<template>
  <div class="placeholder">
    <p class="placeholder--text">
      {{ $t('components.notation.placeholder_text') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotationCardPlaceholder',
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>