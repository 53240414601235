<template>
  <!-- eslint-disable vue/no-v-html -->
  <!--
    This component must only be used with sanitized HTML content, as is returned
    by action text #to_s.

    We disable turbolinks for the content, to avoid file download links to break.
    We cannot use data-turbolinks inside /app/views/active_storabe/blobs/_blob.html.haml,
    since it is removed during sanitization, so we disable it for all links inside
    rich text instead.
  -->
  <div
    data-turbolinks="false"
    v-html="unsafeHtmlContent"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  name: 'RichTextContent',
  props: {
    unsafeHtmlContent: {
      type: String,
      default: null,
    },
  },
};
</script>
