<template>
  <div
    v-click-outside="hideOptions"
    class="combobox"
    :class="{'is-open': isOpen}"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`option-list-${uid}`"
  >
    <button
      ref="button"
      type="button"
      class="combobox--button"
      role="button"
      aria-autocomplete="list"
      :aria-controls="`option-list-${uid}`"
      :aria-expanded="isOpen"
      :aria-label="label"
      :aria-labelledby="ariaLabelledby"
      :disabled="disabled"
      aria-multiline="false"
      @click="toggleOptions"
      @keyup.up.down.prevent="showOptions"
      @keyup.up.prevent="focusPrevious"
      @keyup.down.prevent="focusNext"
    >
      <slot
        name="button"
        :value="currentOption"
      >
        <ComboBoxButton
          :description="description"
          :description-tooltip="descriptionTooltip"
        >
          {{ displayTextOverride || currentOption }}
        </ComboBoxButton>
      </slot>
    </button>

    <div
      v-if="isOpen"
      class="combobox--popup"
    >
      <ul
        :id="`option-list-${uid}`"
        ref="options"
        class="combobox--options-list"
        tabindex="-1"
        role="listbox"
        :aria-activedescendant="`option-${uid}-${focussedIndex}`"
        @keydown.down.up.prevent
        @keyup.up.prevent="focusPrevious"
        @keyup.down.prevent="focusNext"
        @keydown.enter.prevent="accept"
        @keydown.esc.prevent="hideOptionsAndFocus"
      >
        <li
          v-for="(option, index) in options"
          :key="`${index}-${option}`"
        >
          <slot
            name="option-button"
            :option="option"
          >
            <button
              :id="`$option-${uid}-${index}`"
              :ref="getRefNameForIndex(index)"
              type="button"
              class="combobox--option"
              :class="{'has-focus': focussedIndex === index}"
              :aria-labelledby="ariaLabelledby"
              :aria-selected="focussedIndex === index"
              role="option"
              @mousemove="focus(index)"
              @click="select(index)"
            >
              <slot
                name="option"
                :option="option"
              >
                <ComboBoxOption>
                  {{ option }}
                </ComboBoxOption>
              </slot>
            </button>
          </slot>
        </li>
      </ul>
      <div
        v-if="$slots.footer"
        class="combobox--footer"
      >
        <slot
          name="footer"
          :focussed-option="focussedOption"
          :current-option="currentOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';
import scrollToElement from '../../helpers/ScrollToElement';

export default {
  name: 'ComboBox',
  mixins: [HasUid],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayTextOverride: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    descriptionTooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      focussedIndex: this.modelValue,
    };
  },
  computed: {
    currentOption() {
      return this.options[this.modelValue];
    },
    focussedOption() {
      if (this.focussedIndex === null) return null;
      return this.options[this.focussedIndex];
    },
  },
  watch: {
    focussedIndex() {
      this.adjustScrollPosition();
    },
  },
  methods: {
    getRefNameForIndex(index) {
      return `option-${index}`;
    },
    adjustScrollPosition() {
      const focusedRef = this.getRefNameForIndex(this.focussedIndex);
      const focusItem = this.$refs[focusedRef];
      if (!focusItem) return;
      scrollToElement(this.$refs.options, focusItem[0]);
    },
    toggleOptions() {
      if (this.isOpen) {
        this.hideOptions();
      } else {
        this.showOptions();
      }
    },
    showOptions() {
      this.isOpen = true;
      // we defer the call to focus() because the popup is not
      // visible until the next tick
      this.$nextTick().then(() => this.$refs.options.focus());
    },
    hideOptions() {
      this.isOpen = false;
    },
    hideOptionsAndFocus() {
      this.hideOptions();
      // we defer the call to focus() to prevent the button
      // focus from getting randomly overwritten when the
      // popup closes in the next tick
      this.$nextTick().then(() => this.$refs.button.focus());
    },
    select(index) {
      if (this.options.length === 0) return;
      const value = Math.max(0, Math.min(this.options.length - 1, index));
      this.$emit('update:modelValue', value);
      this.hideOptionsAndFocus();
    },
    focus(index) {
      if (this.options.length === 0) return;
      this.focussedIndex = Math.max(0, Math.min(this.options.length - 1, index));
    },
    focusPrevious() {
      this.focus(this.focussedIndex - 1);
    },
    focusNext() {
      this.focus(this.focussedIndex + 1);
    },
    accept() {
      this.select(this.focussedIndex);
      this.hideOptionsAndFocus();
    },
  },
};
</script>
