import { BaseController } from '@/components/NotationListPage/BaseController';
import TopTermSearchApi from '@/api/TopTermSearchApi';
import i18n from '@/config/i18n';
import PaginationService from '@/services/PaginationService';
import ApiHelpers from '@/api/ApiHelpers';

const TopTermSearchControllerConfig = (topTerm) => ({
  topTerm,
  notationListApi: TopTermSearchApi(topTerm.id),
  i18nPrefix: 'search',
  hiddenColumns: [
    'is_hidden_for_customisation',
    'is_hidden_for_clients',
    'dashboard_name',
    'mom_percent',
    'last_month_value',
    'wow_percent',
    'last_week_value',
    'dod_percent',
    'last_day_value',
    'custom_dod_percent_over_min',
    'custom_dod_percent_over_max',
    'custom_dod_percent_over_avg',
    'current_value_range',
    'correlation_1m',
    'correlation_2m',
    'correlation_3m',
    'preview',
  ],
  pagination: new PaginationService({
    url: `/api/top_terms/${topTerm.id}/notations`,
    method: 'POST',
    transformEntry: (e) => ({ isChecked: false, ...e }),
    errorHandler: ApiHelpers.handleError,
  }),
});

class TopTermSearchController extends BaseController {
  constructor(topTerm, user, initialState, { isMailExport, media }) {
    const config = TopTermSearchControllerConfig(topTerm);
    super(config, user, initialState, {}, { isMailExport, media });
    this.showDefaultFilterActions = true;
  }

  get currentQuery() {
    return this.config.topTerm;
  }

  get caption() {
    if (this.isLoading) {
      return i18n.t('components.search.is_loading', { name: this.currentQuery.name });
    }
    const count = this.notations.length;
    if (this.hasMoreResults) {
      return i18n.t('components.search.result_count_plus', { name: this.currentQuery.name }, count);
    }
    return i18n.t('components.search.result_count', { name: this.currentQuery.name }, count);
  }
}

export default TopTermSearchController;
