<template>
  <div class="subscriptions--page page is-narrow">
    <SubscriptionsHeader
      :are-buttons-enabled="controller.areButtonsEnabled"
      @save="controller.saveSettings()"
      @reset="controller.reset()"
    />
    <SpinningLoader v-if="controller.isLoading" />
    <SubscriptionsTable
      v-else
      :general-subscriptions="controller.generalSubscriptions"
      :public-dashboard-subscriptions="controller.publicDashboardSubscriptions"
      :private-dashboard-subscriptions="controller.privateDashboardSubscriptions"
      :publication-subscriptions="controller.publicationSubscriptions"
      @set-interval="setInterval"
    />
  </div>
</template>

<script>
import SubscriptionsController from './controllers/SubscriptionsController';
import SubscriptionsHeader from './components/SubscriptionsHeader/Component.vue';
import SubscriptionsTable from './components/SubscriptionsTable/Component.vue';

export default {
  name: 'SubscriptionApp',
  components: { SubscriptionsHeader, SubscriptionsTable },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const controller = new SubscriptionsController();
    return {
      controller,
      setInterval: controller.setInterval.bind(controller),
    };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>