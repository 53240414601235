<template>
  <div class="header">
    <button
      class="header--title"
      data-test="publication-title"
      @click="$emit('action')"
    >
      {{ displayNameWithFallback }}
    </button>
    <WithTooltip
      v-if="isForExternal"
      :tooltip="$t('attributes.publication.for_external')"
    >
      <Icon
        name="users"
        class="header--external-marker"
        data-test="marker-icon-for-external"
      />
    </WithTooltip>
  </div>
</template>

<script>
import { computed } from 'vue';
import i18n from '@/config/i18n';

export default {
  name: 'PublicationListItemHeader',
  props: {
    displayName: {
      type: String,
      default: null,
    },
    isForExternal: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'action',
  ],
  setup(props) {
    const fallbackDisplayName = i18n.t('apps.publications.list.unnamed_publication');
    const displayNameWithFallback = computed(() => props.displayName || fallbackDisplayName);
    return { displayNameWithFallback };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>