<template>
  <tr class="sticky-head-row" v-if="controller.ready">
    <td class="data-table--body-cell label-cell" :colspan="firstMainDimensionIndex">
      <span v-if="prefix">{{ prefix }}: </span>

      <slot name="label" :options="labelOptions">
        <span v-html="displayLabel"/>
      </slot>
    </td>

    <template
      v-for="cell in cells"
      :key="cell.key"
    >
      <GenericColumn
        v-if="cell.visible"
        :value="cell.value"
        :column-type="cell.type"
        :data-role="cell.key"
        :column-key="cell.key"
        :event-listeners="cell.eventListeners"
        :additional-data="cell.additionalData"
        :classes="cell.classes"
      />
    </template>
  </tr>
</template>

<script>
import GenericColumn from './Columns/GenericColumn.vue';

export default {
  components: {
    GenericColumn
  },
  props: {
    controller: null,
    mainController: null,
    label: null,
    prefix: null
  },

  mounted() {
    // We don't want the sticky header to repeat on every printed page.pdf
    // To achieve that and also keep them sticky on the web, we'll just
    // move them inside the `tbody` for print
    if (this.$media == 'print') {
      this.$nextTick(()=>{
        let tbody = this.$el.parentNode.parentNode.querySelector('tbody')
        let lastStickyHeader = tbody.querySelector('.sticky-head-row')
        if (lastStickyHeader) {
          tbody.insertBefore(this.$el, lastStickyHeader.nextSibling)
        } else {
          tbody.prepend(this.$el)
        }
      })
    }
  },

  computed: {
    displayLabel() {
      if (this.label) {
        return this.label
      }
      return this.generatedLabel
    },
    cells() {
      return this.controller.notationListModel.createRow(this.controller.notations[0]).slice(this.firstDimensionIndex)
    },

    firstMainDimensionIndex() {
      for (let i = 0; i < this.mainController.notationListModel.visibleColumns.length; i++) {
        if (this.mainController.notationListModel.visibleColumns[i].key.indexOf('dimension') >= 0) {
          return i;
        }
      }
      return -1;
    },

    firstDimensionIndex() {
      for (let i = 0; i < this.controller.notationListModel.visibleColumns.length; i++) {
        if (this.controller.notationListModel.visibleColumns[i].key.indexOf('dimension') >= 0) {
          return i;
        }
      }
      return -1;
    },

    labelColumns() {
      return this.controller.notationListModel.visibleColumns.slice(0, this.firstDimensionIndex)
    },

    labelOptions() {
      let notation = this.controller.notations[0]
      let labelOptions = {}

      this.labelColumns.forEach((column, index)=> {
        labelOptions[column.key] = column.valueBuilder ? column.valueBuilder(notation, column.key) : notation[column.key]
      })

      return labelOptions
    },

    generatedLabel() {
      let notation = this.controller.notations[0]

      return this.labelColumns.map((column, index)=> {
        let value = column.valueBuilder ? column.valueBuilder(notation, column.key) : notation[column.key]
        return `${column.displayName}: ${value}`
      }).join("<br/>")
    }
  }
}
</script>


<style lang="scss">
.sticky-head-row {
  .label-cell {
    word-break: break-word;
    line-height: 1rem;
  }
}
</style>
