<template>
  <div class="action">
    <ButtonWithDropdown
      icon="ellipsis-v"
      variant="ternary"
      size="large"
      padding="none"
      :button-aria-label="$t('apps.product_group_reports.list.actions')"
      :icon-fixed-width="true"
      dropdown-alignment="right"
    >
      <template #dropdown>
        <DropdownMenu>
          <DropdownMenuButton
            icon="pencil"
            @click="controller.edit(entry)"
          >
            {{ $t('apps.product_group_reports.list.edit') }}
          </DropdownMenuButton>
          <DropdownMenuButton
            v-if="entry.published_at"
            icon="trash-alt"
            @click="controller.deleteAfterConfirmation(entry)"
          >
            {{ $t('apps.product_group_reports.list.delete') }}
          </DropdownMenuButton>
        </DropdownMenu>
      </template>
    </ButtonWithDropdown>
  </div>
</template>

<script>
export default {
  name: 'ProductGroupReportsListItemActions',
  props: {
    controller: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>