<template>
  <ul class="app-navigation--dropdown-list">
    <!-- Disabled
    <li class="app-navigation--dropdown-item">
      <a
        :href="instructionsHref"
        target="_blank"
        rel="noopener noreferrer"
        class="app-navigation--dropdown-link"
      >
        <Icon
          name="download"
          variant="regular"
          :fixed-width="true"
          class="app-navigation--dropdown-icon"
        />
        {{ $t('layouts.app_navigation.help.instructions') }}
      </a>
    </li>
    -->
    <li class="app-navigation--dropdown-item">
      <a
        :href="emailHref"
        class="app-navigation--dropdown-link"
      >
        <Icon
          name="envelope"
          variant="regular"
          :fixed-width="true"
          class="app-navigation--dropdown-icon"
        />
        {{ $t('layouts.app_navigation.help.contact') }}
      </a>
    </li>
    <li class="app-navigation--dropdown-item">
      <a
        href="/video-tutorials"
        class="app-navigation--dropdown-link"
      >
        <Icon
          name="video"
          variant="regular"
          :fixed-width="true"
          class="app-navigation--dropdown-icon"
        />
        {{ $t('layouts.app_navigation.help.video_tutorials') }}
      </a>
    </li>
  </ul>
</template>

<script>
import i18n from '@/config/i18n';

export default {
  name: 'NavigationHelpDropdown',
  props: {
    helplineEmail: {
      type: String,
      required: true,
    },
  },
  computed: {
    emailHref() {
      const subject = this.$t('email.helpline.subject');
      return `mailto:${this.helplineEmail}?subject=${subject}`;
    },
    instructionsHref() {
      return i18n.locale === 'de'
        ? '/docs/Ueber-HGS-Research.pdf'
        : '/docs/About-HGS-Research.pdf';
    },
  },
};
</script>
