<template>
  <div class="legend">
    <HumanRightsRiskLegendBox
      class="legend__risk"
      :title="segmentLegend.title"
      :items="segmentLegend.items"
      :column-amount="7"
      :wrapper-classes="wrapperClasses"
    />
    <HumanRightsRiskLegendBox
      class="legend__flags"
      v-if="includeFlagLegend"
      :title="flagLegend.title"
      :items="flagLegend.items"
      :column-amount="1"
      :wrapper-classes="wrapperClasses"
    />
  </div>
</template>

<script>
import redFlag from '@/../assets/images/topFiveCountry/redFlag.svg';
import yellowFlag from '@/../assets/images/topFiveCountry/yellowFlag.svg';
import HumanRightsRiskLegendBox from '../HumanRightsRiskLegendBox/Component.vue';
import { COUNTRY_COLOR_MAP } from '../../../services/MapService/MapService';

export default {
  name: 'HumanRightsRiskLegend',
  components: {
    HumanRightsRiskLegendBox,
  },
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    columnAmount: {
      type: Number,
      required: false,
      default: 1,
    },
    translationBase: {
      type: String,
      required: false,
      default: 'components.human_rights_risk_legend.segment_legend.',
    },
    flagTranslationBase: {
      type: String,
      required: false,
      default: 'components.human_rights_risk_legend.flag_legend.',
    },
    includeFlagLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    flagLegend() {
      return {
        title: this.$t(`${this.flagTranslationBase}title`),
        items: [
          {
            description: this.$t(`${this.flagTranslationBase}yellow_flag`),
            icon: yellowFlag,
          },
          {
            description: this.$t(`${this.flagTranslationBase}red_flag`),
            icon: redFlag,
          },
        ],
      };
    },
    segmentLegend() {
      return {
        title: this.$t(`${this.translationBase}title`),
        items: [
          {
            backgroundColor: COUNTRY_COLOR_MAP.A,
            grade: 'A',
            description: this.$t(`${this.translationBase}grades.a`),
            score: this.$t(`${this.translationBase}scores.a`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.B,
            grade: 'B',
            description: this.$t(`${this.translationBase}grades.b`),
            score: this.$t(`${this.translationBase}scores.b`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.C,
            grade: 'C',
            description: this.$t(`${this.translationBase}grades.c`),
            score: this.$t(`${this.translationBase}scores.c`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.D,
            grade: 'D',
            description: this.$t(`${this.translationBase}grades.d`),
            score: this.$t(`${this.translationBase}scores.d`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.E,
            grade: 'E',
            description: this.$t(`${this.translationBase}grades.e`),
            score: this.$t(`${this.translationBase}scores.e`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.F,
            grade: 'F',
            description: this.$t(`${this.translationBase}grades.f`),
            score: this.$t(`${this.translationBase}scores.f`),
          },
          {
            backgroundColor: COUNTRY_COLOR_MAP.G,
            grade: 'G',
            description: this.$t(`${this.translationBase}grades.g`),
            score: this.$t(`${this.translationBase}scores.g`),
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.legend {
  width: 100%;
  gap: 8px;
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: 1fr min-content;
  container-type: inline-size;
  &__flags {
    min-width: 320px;
    @container (min-width: 1250px) {
      min-width: 550px;
    }
  }
}
</style>
