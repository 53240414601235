<template>
  <ul
    ref="root"
    class="tag-list"
    :class="cssModifier"
  >
    <li
      v-for="tag in tags"
      :key="tag.id"
      class="tag-list--tag"
    >
      <DashboardSearchListTag
        :type="tag.type"
        :name="tag.name"
        :can-remove="true"
        @click="$emit('click-tag', tag)"
      />
    </li>
  </ul>
</template>

<script>
// TODO: don't reach into another component's directory
import DashboardSearchListTag from '../../DashboardSearchList/Tag/Component.vue';

export default {
  name: 'DashboardSearchInputTagList',
  components: {
    DashboardSearchListTag,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-tag'],
  data() {
    return {
      overflowObserver: new ResizeObserver(this.onOverflowChanged.bind(this)),
      isOverflowing: false,
    };
  },
  computed: {
    cssModifier() {
      const modifiers = [];
      if (this.wrap) modifiers.push('can-wrap');
      if (this.isOverflowing) modifiers.push('is-overflowing');
      return modifiers.join(' ');
    },
  },
  mounted() {
    this.overflowObserver.observe(this.$refs.root);
  },
  beforeUnmount() {
    this.overflowObserver.unobserve(this.$refs.root);
  },
  methods: {
    onOverflowChanged() {
      if (!this.$refs.root.firstElementChild) {
        this.isOverflowing = false;
        return;
      }
      const firstTagLeft = this.$refs.root.firstElementChild.getBoundingClientRect().left;
      const tagListLeft = this.$refs.root.getBoundingClientRect().left;
      this.isOverflowing = firstTagLeft < tagListLeft;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>