<template>
  <div
    v-click-outside="collapseOnClickOutside"
    class="list-card"
  >
    <div
      class="list-card--popup"
      :class="{'is-open': !isCollapsed}"
    >
      <div class="list-card--header">
        <div class="list-card--header-title-unread-container">
          <div class="list-card--header-title-container">
            <Icon
              class="list-card--header-icon"
              :name="headerIconName"
            />
            <h2 class="list-card--header-title">
              {{ translations.header.title }}
            </h2>
          </div>

          <template
            v-if="unreadCount > 0"
          >
            <a
              :href="pathToUnreadFirst"
              class="list-card--link-unread-button"
            >
              <div
                class="list-card--header-unread-container"
              >
                <span class="list-card--icon-unread-marker" />
                <p class="list-card--header-unread-description">
                  {{ $t('generic.unread_count', unreadCount) }}
                </p>
              </div>
            </a>
            <WithTooltip
              class="list-card--mark-all-as-read-button-container"
              :tooltip="$t('generic.mark_all_as_read')"
            >
              <ButtonComponent
                :aria-label="$t('generic.mark_all_as_read')"
                icon="check"
                variant="ternary"
                padding="none"
                @click="$emit('mark-all-as-read')"
              />
            </WithTooltip>
          </template>
        </div>
        <ButtonComponent
          v-if="canExpand"
          class="list-card--header-button"
          variant="secondary"
          @click="toggleExpansion"
        >
          {{ isCollapsed
            ? translations.header.button_show_more
            : translations.header.button_show_less
          }}
          <Icon
            class="list-card--caret"
            :name="isCollapsed ? 'chevron-down' : 'chevron-up'"
            variant="regular"
          />
        </ButtonComponent>
      </div>
      <CollapsedListCardScrollarea
        :translations="translations"
        :path-to-all="pathToAll"
        :item-loader="itemLoader"
        :collapsed-limit="collapsedLimit"
        :is-collapsed="isCollapsed"
      >
        <template #item="slotProps">
          <slot
            name="item"
            :item="slotProps.item"
          />
        </template>
      </CollapsedListCardScrollarea>
    </div>
  </div>
</template>

<script>
import CollapsedListCardScrollarea from '../CollapsedListCardScrollarea/Component.vue';

const validateTranslations = (translations) => translations?.header?.title
    && translations?.header?.button_show_more
    && translations?.header?.button_show_less
    && translations?.no_results
    && translations?.link_to_all;

export default {
  name: 'WelcomeBannerCollapsedListCard',
  components: {
    CollapsedListCardScrollarea,
  },
  props: {
    headerIconName: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
      validator: validateTranslations,
    },
    pathToAll: {
      type: String,
      required: true,
    },
    pathToUnreadFirst: {
      type: String,
      required: true,
    },
    itemLoader: {
      type: Object,
      required: true,
    },
    collapsedLimit: {
      type: Number,
      required: true,
    },
  },
  emits: ['mark-all-as-read'],
  data() {
    return {
      isCollapsed: true,
    };
  },
  computed: {
    items() {
      return this.itemLoader.data?.entries || [];
    },
    unreadCount() {
      return this.itemLoader.data?.unreadCount || 0;
    },
    canExpand() {
      const itemCountWithFooter = this.items.length + 1;
      return itemCountWithFooter > this.collapsedLimit;
    },
    isExpanded() {
      return !this.isCollapsed;
    },
  },
  methods: {
    collapse() {
      this.isCollapsed = true;
    },
    collapseOnClickOutside(event) {
      const targetIsDialog = event.target.closest('.dialog');
      if (!targetIsDialog) {
        this.collapse();
      }
    },
    toggleExpansion() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>