<template>
  <div class="line-chart">
    <canvas
      :id="uid"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import Chart from 'chart.js';
import HasUid from '@/mixins/HasUid';
import AnnotateChart from '@/plugins/AnnotateChart';

Chart.defaults.global.defaultFontColor = '#000000';

const mapClickToElement = (event) => {
  const rect = event.target.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

const mapClickToChart = (event, chart) => {
  const xscale = chart.scales['x-axis-1'];
  const yscale = chart.scales['y-axis-1'];
  const point = mapClickToElement(event);
  return {
    x: (point.x - xscale.left) / (xscale.right - xscale.left),
    y: (point.y - yscale.top) / (yscale.bottom - yscale.top),
  };
};

const timestampFromX = (x, chart) => {
  const xscale = chart.scales['x-axis-1'];
  return Math.round(xscale.min + x * (xscale.max - xscale.min));
};

const findClosestValue = (needle, haystack) => (
  haystack.reduce((best, curr) => (Math.abs(curr - needle) < Math.abs(best - needle) ? curr : best))
);

const alignTimestampToTicks = (timestamp, chart) => {
  const labels = chart.data.datasets.map((dataset) => dataset.data.map((elem) => elem.x)).flat();
  return findClosestValue(timestamp, labels);
};

const inRange = (val) => val >= 0 && val <= 1;
const inBounds = (point) => inRange(point.x) && inRange(point.y);

export default {
  name: 'LineChart',
  mixins: [HasUid],
  props: {
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    graphLimits: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['select-date'],
  computed: {
    chartOptions() {
      const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            type: 'time',
          }],
        },
        onClick: this.onClick.bind(this),
      };
      return { ...defaultOptions, ...this.options };
    },
  },
  watch: {
    annotations() {
      if (!this.chartInstance) return;
      this.chartInstance.config.data.annotations = this.annotations;
      this.chartInstance.update();
    },
    chartData() {
      this.renderChart();
    },
    options() {
      this.renderChart();
    },
  },
  beforeMount() {
    // The chartInstance has to be non-reactive to prevent
    // errors when the chart gets re-rendered. Therefore,
    // we don't use a data attribute.
    this.chartInstance = null;
  },
  beforeUnmount() {
    this.destroyChart();
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    destroyChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },
    renderChart() {
      this.destroyChart();
      const chartElement = document.getElementById(this.uid);
      if (!chartElement) return;
      const ctx = chartElement.getContext('2d');
      if (!ctx) return;
      this.chartInstance = new Chart(ctx, {
        type: 'scatter',
        data: this.chartData,
        options: this.chartOptions,
        plugins: [AnnotateChart],
      });
    },
    onClick(event) {
      const point = mapClickToChart(event, this.chartInstance);
      if (!inBounds(point)) return;
      const timestamp = alignTimestampToTicks(timestampFromX(point.x, this.chartInstance),
        this.chartInstance);

      this.$emit('select-date', new Date(timestamp));
    },
    compareDate() {
      const date = this.chartData.annotations[0].x;
      if (date > this.graphLimits.minDate && date < this.graphLimits.maxDate) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>