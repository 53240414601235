<template>
  <div class="item">
    <div class="item--header">
      <div class="item--title">
        <button
          class="item--title-button"
          @click="showNews"
        >
          <div
            v-if="item.unread"
            class="item--unread-marker"
            role="status"
            :aria-label="$t('generic.unread')"
          />
          {{ item.display_name }}
        </button>
      </div>
      <p class="item--date">
        {{ publishedAt }}
      </p>
    </div>
    <p class="item--teaser">
      {{ item.teaser_text }}
    </p>
  </div>
</template>

<script>
import { ViewNews, FormatPublicationDate } from '@/helpers/PublicationHelpers';

export default {
  name: 'WelcomeBannerNewsItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    publishedAt() {
      return FormatPublicationDate(this.item.published_at);
    },
  },
  methods: {
    showNews() {
      ViewNews(this.item);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>