<template>
  <PrintLayout
    :header-only-first-page="true"
  >
    <template #header>
      <PrintLayoutInstanceDefaultHeader class="low-margin-bottom" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </PrintLayout>
  <div class="risk-map__container">
    <div class="risk-map__row risk-map__headline">
      <h1>{{ caption }}</h1>
    </div>
    <div class="risk-map__row risk-map__dimensions-combobox" v-exclude-from-print>
      <ComboBox2
        v-model="selectedDimension"
        class="top-five-filter__combo-box risk-map__dimensions-select"
        :description="t('apps.intro.human_rights_risks.dropdown.dimensions')"
        :options="hrDimensionsForSelect"
      />
      <div class="top-five-table__additional-buttonbar">
        <CountryRiskMapExportButtonComponent v-exclude-from-print="true" :controller="tableController" />
      </div>
    </div>

    <div class="risk-map__row hr-legend">
      <!-- styles from /app/javascript/apps/CommodityRisksMap/App.vue apply here -->
      <HumanRightsRiskLegend :column-amount="2" wrapper-classes="w-100" />
    </div>

    <div class="risk-map__row risk-map__row--full-height">
      <div class="risk-map__column risk-map__map country-risk-map">
        <ButtonTabs :tab-classes="{0: 'button-tabs__minheight', 1: 'button-tabs_autoheight'}">
          <ButtonTab :title="t('apps.country_risk_map.button_tabs.map')" icon="globe-americas">
            <div ref="root" class="any-chart-map__container" />
            <CountryMapToolbar
              class="risk-map__toolbar"
              :zoom-level="zoomLevel"
              @mouse-up="stopMoveMap"
              @mouse-down="moveMap"
              @zoom-input="zoomInputChangeHandler"
            />
          </ButtonTab>
          <ButtonTab :title="t('apps.country_risk_map.button_tabs.table')" :with-padding="true" icon="table">
            <h1>{{ t("apps.country_risk_map.table.heading") }}</h1>
            <NotationList :sticky-margin-zero-top="true" :controller="tableController" class="country-data-table" />
          </ButtonTab>
        </ButtonTabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ButtonTab, ButtonTabs, CountryMapToolbar, HumanRightsRiskLegend, NotationList } from "@/components";
import { ComboBox2 } from "@/components/generic";
import { useHrScoreUtil } from "../../components/NotationList/NotationTable/Columns/TopFiveCountry/useHrScoreUtil";
import { MapService } from "../../services/MapService/MapService";
import StorageService from "../../services/StorageService/StorageService";
import { KEY_HUMAN_RIGHTS_RISKS_MAP_FILTERS } from "../../services/StorageService/StorageConst";
import CountryRiskMapTableController from "./controllers/CountryRiskMapTableController";
import CountryRiskMapExportButtonComponent from "./components/CountryRiskMapExportButton/Component.vue";
import { useCountryDropdowns } from "./composables/useCountryDropdowns";

import { setQueryParam } from "../../services/UrlStateStore";
import QUERY_PARAM from "../Intro/constants/commodity-query-params";
import PrintLayout from "../../components/PrintLayout/Component.vue";
import PrintLayoutInstanceDefaultHeader from "../../components/PrintLayout/Instance/DefaultHeader/Component.vue";

const { hrDimensionsForSelect, countriesForMap } = defineProps({
  hrDimensionsForSelect: {
    type: Array,
    default: () => [],
  },
  countriesForMap: {
    type: Array,
    default: () => [],
  },
});

const { t } = useI18n();

const root = ref(null);
const storage = new StorageService();
const mapService = new MapService(unref(root));
const tableController = new CountryRiskMapTableController(unref(countriesForMap), t);

const caption = `${t("apps.country_risk_map.title_one")} | ${t("apps.country_risk_map.title_two")}`;

const { selectedDimension } = useCountryDropdowns({ hrDimensionsForSelect });
//const selectedDimension = ref(storage.get(KEY_HUMAN_RIGHTS_RISKS_MAP_FILTERS)?.dimension || hrDimensionsForSelect[0]);

const zoomLevel = ref(1);

const moveMap = (direction) => mapService.moveMap(direction);
const stopMoveMap = () => mapService.stopMoveMap();
const zoomInputChangeHandler = (newZoomLevel) => {
  mapService.zoom(newZoomLevel);
  zoomLevel.value = newZoomLevel;
};

const getHumanRightsTooltipTemplate = function () {
  const { data, hrScoreFlagIcon, getFlagIconReactive } = useHrScoreUtil(this.value.hr_scores);
  const { dimensions, total } = toRaw(data);
  getFlagIconReactive();
  const flagIcon = unref(hrScoreFlagIcon);

  return mapService.humanRightsTooltipTemplate({
    flagIcon,
    total,
    dimensions,
    value: this.value,
    translator: t,
    tradeFlowPercent: this.value.trade_flow_percentage,
  });
};

const onZoomLevelChange = (newZoomLevel) => (zoomLevel.value = newZoomLevel);

watch(selectedDimension, (dimension) => {
  storage.merge(KEY_HUMAN_RIGHTS_RISKS_MAP_FILTERS, { dimension });
  mapService.updateMapItemColorsByDimension(dimension.value);
  setQueryParam(QUERY_PARAM.DIMENSION, dimension.value);
});

const initializeMap = () => {
  mapService.init({
    geoData: unref(countriesForMap),
    url: "",
    idFieldValue: "iso_a3",
    mapName: "world",
    zoomOnWheel: true,
    container: unref(root),
    withCustomTooltip: true,
    customTooltipData: {
      generateTooltipBody: getHumanRightsTooltipTemplate,
    },
    callback: () => {
      mapService.updateMapItemColorsByDimension(selectedDimension.value.value);
      mapService.mouseWheelZoomChangeAddListener(onZoomLevelChange);
      mapService.moveBtnMouseUpDocumentHandler();
    },
  });
};

onMounted(() => {
  document.addEventListener("geodata-loaded", initializeMap);
});

onUnmounted(() => {
  mapService.destroy();
  document.removeEventListener("geodata-loaded", initializeMap);
});
</script>

<style lang="scss">
@media print {
  .any-chart-map__zoom-input-wrapper {
    display: none;
  }

  .country-data-table .data-table--body-cell:first-child,
  .country-data-table .data-table--head-cell:first-child {
    display: table-cell;
  }

  .country-data-table .data-table--head-cell {
    white-space: initial;
    padding: 1mm;
  }

  .country-data-table .data-table--head {
    display: table-header-group;
    break-inside: avoid;
  }

  .country-data-table {
    font-size: 65%;

    .button-tabs__header {
      display: none;
    }
  }

  .hrr-legend-box {
    box-shadow: none;
  }
}

</style>

<style scoped lang="scss">
.risk-map {
  &__dimensions-combobox {
    display: flex;
    flex-wrap: wrap;
  }

  &__classification-select {
    width: 100%;
  }

  &__dimensions-select {
    max-width: fit-content;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    overflow: hidden;
  }

  &__toolbar {
    z-index: 1;
  }

  &__row {
    display: flex;
    gap: 12px;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &--full-height {
      height: 100%;
      position: relative;
      overflow-y: auto;
    }
  }

  &__column {
    flex-direction: column;
  }

  &__map {
    flex-grow: 1;
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 12px #e7e7e7;
    overflow: hidden;

    h1 {
      font-weight: 700;
      margin: 20px 0 20px 10px;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 0 0 262px;
  }

  &__headline {
    @include font-size("20/25");
  }
}

@media print {
  .risk-map__row {
    flex-direction: column-reverse;
    grid-gap: 0;
  }

  .risk-map__legend {
    flex-direction: row;
    flex: auto;
  }

  .risk-map__container {
    padding-top: 0;
  }
}

.any-chart-map {
  &__container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.anychart-tooltip {
  font-family: "Ubuntu";
  @include font-size("14/20");
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: $space-s $space-s;
  box-shadow: 0 0 2px rgba($color-background-dark, 0.2), 0 4px 4px rgba($color-background-dark, 0.15),
    0 8px 8px rgba($color-background-dark, 0.05);
  text-align: left;
  color: $color-text-unobtrusive;
  background-color: $color-background-light;
}

// just a tmp solution
.anychart-credits {
  display: none;
}

@media print {
  .risk-map__row {
    flex-direction: column-reverse;
  }

  .risk-map__map {
    overflow: visible;
  }

  .risk-map__legend {
    flex-direction: row;
    flex: auto;
  }
}

</style>
