<template>
  <div
    :class="wrapperClasses"
    class="hrr-legend-box"
  >
    <h1 class="hrr-legend-box__title">
      {{ title }}
    </h1>
    <div
      class="grid"
      :style="{'--columnAmount': columnAmount}"
    >
      <div
        v-for="(item, idx) of items"
        :key="idx"
        class="hrr-legend-box__item"
      >
        <div
          v-if="item.icon"
          class="hrr-legend-box__icon"
        >
          <img :src="item.icon">
        </div>
        <div
          v-if="item.grade"
          class="hrr-legend-box__grade"
        >
          <div
            class="hrr-legend-box__grade-letter"
            :style="{ backgroundColor: `${item.backgroundColor}`}"
          >
            {{ item.grade }}
          </div>
          <div
            class="hrr-legend-box__grade-description"
          >
            {{ item.description }} <br>
            <span class="hrr-legend-box__score">
              ({{ item.score }})
            </span>
          </div>
        </div>
        <div
          v-else
          class="hrr-legend-box__description"
          :class="item.cssClass"
          :style="{ backgroundColor: `${item.backgroundColor}` }"
          v-html="item.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Turbolinks from 'turbolinks';

export default {
  name: 'HumanRightRiskLegendBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    wrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    columnAmount: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  methods: {
    uuid: () => Turbolinks.uuid(),
  },
};
</script>

<style lang="scss">
@media print {
  .hrr-legend-box {
    zoom: 80%;
  }
}

.hrr-legend-box {
  overflow: hidden;
  padding: 8px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 12px #e7e7e7;
  container-type: inline-size;
  @include font-size('14/14');

  .grid{
    --columnAmount: 1; // default, overwritten by inline-style
    --minGradeWidth: 160px;
    display: grid;
    gap: 12px;

    // default to column
    grid-template-columns: repeat(1, 1fr);
    // 3 rows if enough space
    @container (min-width: 335px) {
    grid-template-columns: repeat(min(2, var(--columnAmount)), minmax(var(--minGradeWidth), 1fr));
    }
    // 2 rows if even more space
    @container (min-width: 675px) {
      grid-template-columns: repeat(min(4, var(--columnAmount)), minmax(var(--minGradeWidth), 1fr));
    }

    // all in one row if even more space
    @container (min-width: 1020px  ) {
      grid-template-columns: repeat(auto-fill, minmax(var(--minGradeWidth), 1fr));
    }

  }

  &__title {
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    flex: 1;
  }

  &__icon {
    padding: 0 0 3px 3px;
    margin-right: 12px;

    img {
      max-width: initial;
      width: 15px;
    }
  }

  &__grade {
    flex:1;
    padding-right: 12px;
    gap: 6px;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    background-color: #f8f8f8;
  }

  &__score{
    font-stretch: extra-condensed;
    font-weight: 200;
  }

  &__grade-letter {
    // align text to center
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFFFFF;
    padding: 4px 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;

  }

  &__grade-description {
    padding-block: 4px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      white-space: nowrap;
  }

  &__description {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    line-height: 1.4;

    & div {
      display: flex;
      flex-wrap: wrap;
      margin-left: 7px;
    }

    & span:first-child {
      font-weight: bold;
      margin-right: 5px;
    }

    & span:last-child {
      flex-shrink: 0;
    }

  }
}
</style>
