import uuid from '@/utils/uuid'
import { digJson } from '@/utils/digJson'
import { reactive } from 'vue';

export default class Column {
  static className = "Column"

  constructor({
    key,
    label,
    getValue,
    component,
    suffixComponent,
    getProps,
    getSortValue,
    cssClasses,
    hrTooltipData,
    tooltip,
    cellTooltip,
    onClick,
    showExpandableControls
  }) {
    this.uuid = uuid()

    // Set a specific header label
    this.label = label

    // automatic value lookup
    this.key = key

    // custom callback to get the value for a cell
    this.getValue = getValue

    // custom component for a cell
    this.component = component
    this.suffixComponent = suffixComponent

    // callback for props that will be passed to that component
    this.getProps = getProps

    // Custom callback to define a sorting balue
    this.getSortValue = getSortValue

    // callback that will be used to get additional css classes for a cell
    this.cssClasses = cssClasses

    // Tooltip on the column header
    this.tooltip = tooltip

    // Custom tooltip on a single cell
    this.cellTooltip = cellTooltip

    // shortcut for defining hr scores that will be shown in a tooltip
    this.hrTooltipData = hrTooltipData

    this._onClick = onClick

    this.sortable = true
    this.state = reactive({
      sortOrder: "unsorted" // refer to TableHeadCell component
    })

    this.showExpandableControls = showExpandableControls || false
  }

  get sortOrder() { return this.state.sortOrder }
  set sortOrder(value) {
    this.state.sortOrder = value || "unsorted"
  }

  get header() {
    return this.label || this.key
  }

  props(item) {
    if (!this.getProps) { return }
    return this.getProps(item)
  }

  render(item) {
    if (this.getValue) {
      return this.getValue(item)
    }
    return digJson(item, this.key) || '-'
  }

  onClick(item) {
    if (!this._onClick) { return }
    this._onClick(item)
  }

  sortValue(item) {
    if (this.getSortValue) {
      return this.getSortValue(item)
    }
    return this.render(item)
  }
}
