<template>
  <div class="combobox--default-button">
    <div
      v-if="description"
      class="combobox--description"
    >
      <span>
        {{ description }}
      </span>
      <span v-if="descriptionTooltip">
        <IconTooltipComponent :tooltip="descriptionTooltip" />
      </span>
    </div>
    <div class="button-label">
      <div class="combobox--default-button-label">
        <slot />
      </div>
      <Icon
        class="combobox--caret"
        name="chevron-down"
        variant="regular"
        :fixed-width="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComboBoxButton',
  components: {},

  props: {
    description: {
      type: String,
      default: null,
    },
    descriptionTooltip: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.combobox {
  div {
    width: 100%;
  }

  &--default-button {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
  }

  &--description {
    font-size: 10px;
    margin-bottom: 2px;
    color: #6B6B6B;

    display: flex;
    justify-content: space-between;
  }

  .button-label {
    display: flex;
    align-items: center;
  }
}
</style>
