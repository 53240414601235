import i18n from '@/config/i18n';
import axios from 'axios';

const getSubscriptionName = (subscription) => {
  const subscriptionType = subscription.type;
  switch (subscriptionType) {
    case 'IntroSubscription':
      return i18n.t('apps.subscriptions.general_subscription_names.intro');
    case 'IndicatorSubscription':
      return i18n.t('apps.subscriptions.general_subscription_names.indicator');
    case 'WatchListSubscription':
      return i18n.t('apps.subscriptions.general_subscription_names.watch_list');
    case 'PublicationsForFavoriteDashboardsSubscription':
      return i18n.t('apps.subscriptions.general_subscription_names.publications_for_favorite_dashboards');
    case 'PublicationsForIndicatorDashboardsSubscription':
      return i18n.t('apps.subscriptions.general_subscription_names.publications_for_indicator_dashboards');
    default:
      return subscription.dashboard.name;
  }
};

const getSubscriptionGroup = (subscription) => {
  const subscriptionType = subscription.type;
  switch (subscriptionType) {
    case 'DashboardSubscription':
      return subscription.dashboard.is_published ? 'public' : 'private';
    case 'PublicationsForFavoriteDashboardsSubscription':
    case 'PublicationsForIndicatorDashboardsSubscription':
      return 'publications';
    default:
      return 'general';
  }
};

const getAvailableIntervals = (subscriptionType) => {
  switch (subscriptionType) {
    case 'PublicationsForFavoriteDashboardsSubscription':
    case 'PublicationsForIndicatorDashboardsSubscription':
      return ['daily'];
    default:
      return null;
  }
};

const getRemovableBoolean = (subscriptionType) => {
  switch (subscriptionType) {
    case 'DashboardSubscription':
      return true;
    default:
      return false;
  }
};

export const formatSubscriptions = (subscriptions) => subscriptions.map((sub) => ({
  key: sub.key,
  type: sub.type,
  interval: sub.interval,
  dashboard_id: sub.dashboard?.id,
  name: getSubscriptionName(sub),
  group: getSubscriptionGroup(sub),
  availableIntervals: getAvailableIntervals(sub.type),
  removable: getRemovableBoolean(sub.type),
}));

export const fetchSubscriptions = () => axios.get('/api/subscriptions').then((response) => response.data);
