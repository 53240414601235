<template>
  <component
    is="tag"
    id="more"
    ref="root"
    class="infinite-scroll"
  />
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    tag: {
      type: String,
      default: "span"
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: [Number, Array],
      default: 0,
    },
  },
  emits: ['trigger'],
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    observerOptions() {
      return {
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      };
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$emit('trigger');
      }
    }, this.observerOptions);
    this.observer.observe(this.$refs.root);
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>