<script>
export default {}
</script>

<template>
  <div class="table-tools">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.table-tools {
  margin-top: 6px;
  margin-bottom: 20px;
}
</style>
