/**
 * Defines the columns shown in the supply chain table and the order
 * in which they are shown
 */

import Column from "../ExpandableColumnsTable/Column";
import ScoreColumn from "../ExpandableColumnsTable/ScoreColumn";
import ColumnGroup from "../ExpandableColumnsTable/ColumnGroup";
import importExportValuesTooltipContent from "@/components/HumanRightsRisk/ImportExportValuesTooltipContent"

let countryLabel = (country) => {
  if (!country) { return }
  return [country.iso_code, country.name].filter((n) => n).join(" | ");
};

let buildTop5CountryColumn = (title, key) => {
  return new Column({
    label: title,
    hrTooltipData: (supplyChainItem) => {
      let country = supplyChainItem.top_countries[key]
      if (!country) { return }
      return {
        hrScores: country.hr_scores,
        title: `${country.iso_code} | ${country.name}`,
      }
    },
    getSortValue: (supplyChainItem) => supplyChainItem.top_countries[key]?.trade_flow_percentage || 0,
    getValue: (supplyChainItem) => {
      let country = supplyChainItem.top_countries[key];
      if (!country) {
        return "-"
      }
      return `${country.iso_code} (${country.trade_flow_percentage?.toFixed(1)}%)`;
    },
  })
}

/**
 * Logic for the relevant country:
 * - if country_of_production is present, use it
 * - if country_of_demand.balance is export, use country_of_demand
 * - otherwise, use no country at all and show "all import partners"
 *
 * If a country is present, we'll show the hr_scores of that country
 * and the weighted_human_rights_scores otherwise
 */
let getRelevantCountry = (supplyChainItem) => {
  if (supplyChainItem.country_of_production) { return supplyChainItem.country_of_production }
  if (supplyChainItem.country_of_demand.balance == "export") {
    return supplyChainItem.country_of_demand
  }
}

const buildColumnDefinitions = (dimensions, translator) => {
  let relevantCountryLabel = (supplyChainItem) => {
    let country = getRelevantCountry(supplyChainItem)
    if (country) {
      return countryLabel(country)
    }
    if (supplyChainItem.country_of_demand.balance == "import") {
      return translator("apps.supply_chain.all_import_partners", {
        country: countryLabel(supplyChainItem.country_of_demand)
      });
    }
    return "-"
  }

  // return all column groups
  return [
    new ColumnGroup({
      id: "commodity_group",
      label: translator("apps.supply_chain.groups.commodity_group"),
      columns: [
        new Column({
          label: "",
          component: "EditSupplyChainItemCell",
          getProps: (supplyChainItem) => {
            return { supplyChainItem }
          }
        }),
        new Column({
          showExpandableControls: true,
          onClick: (supplyChainItem) => {
            supplyChainItem.expanded = !supplyChainItem.expanded
          },
          cssClasses: (supplyChainItem) => {
            return `expandable-cell ${supplyChainItem.children?.length ? 'depth-has-children' : ''} ${supplyChainItem.expanded ? 'depth-expanded' : ''}`
          },
          getValue: (supplyChainItem) => {
            return `${supplyChainItem.classification.code} | ${supplyChainItem.classification.short_name}`
          },
          label: translator("apps.supply_chain.columns.commodity_name")
        })
      ],
      expandable: false,
    }),

    new ColumnGroup({
      id: "sector",
      label: translator("apps.supply_chain.groups.sector"),
      shortValue: (supplyChainItem) => supplyChainItem.sector && `${supplyChainItem.sector.code} | ${supplyChainItem.sector.short_name}`,
      columns: [
        new Column({ key: "sector.code", label: "Code" }),
        new Column({ key: "sector.short_name", label: "Name" }),
        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          getScores: (supplyChainItem) => supplyChainItem.sector?.scores,
          tooltipTitle: (supplyChainItem) => supplyChainItem.sector && `${supplyChainItem.sector.code} | ${supplyChainItem.sector.short_name}`,
        })
      ],
    }),

    new ColumnGroup({
      id: "country_of_demand",
      label: translator("apps.supply_chain.groups.country_of_demand"),
      shortValue: (supplyChainItem) => supplyChainItem.country_of_demand.iso_code || supplyChainItem.country_of_demand.name,
      columns: [
        new Column({
          label: translator("apps.supply_chain.columns.name"),
          getValue: (supplyChainItem) => countryLabel(supplyChainItem.country_of_demand),
          cssClasses: (supplyChainItem) => supplyChainItem.country_of_demand.balance == "export" ? "is-bold" : "",
          cellTooltip: (supplyChainItem) => {
            return importExportValuesTooltipContent(
              supplyChainItem.country_of_demand.import_export_values,
              translator
            )
          }
        }),
        new Column({
          label: translator("apps.supply_chain.columns.trade_balance"),
          getValue: (supplyChainItem) => {
            if (!supplyChainItem.country_of_demand.balance) { return "-" }

            return translator(`apps.intro.human_rights_risks.table_column.balance.${supplyChainItem.country_of_demand.balance}`)
          }
        }),
        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          getScores: (supplyChainItem) => supplyChainItem.country_of_demand.hr_scores,
          tooltipTitle: (supplyChainItem) => {
            let suffix = ""
            if (supplyChainItem.country_of_demand.balance) {
              suffix = ` (${translator(`apps.intro.human_rights_risks.table_column.balance.${supplyChainItem.country_of_demand.balance}`)})`
            }

            return `${supplyChainItem.country_of_demand.iso_code} | ${supplyChainItem.country_of_demand.name}${suffix}`
          }
        })
      ],
    }),

    new ColumnGroup({
      id: "top_5_countries_of_supply",
      label: (columnGroup) => {
        if (columnGroup.expanded) {
          return translator("apps.supply_chain.groups.top_5_countries_of_supply")
        } else {
          return translator("apps.supply_chain.groups.top_country_of_supply")
        }
      },
      cellClasses: (supplyChainItem) => {
        if (supplyChainItem.country_of_demand.balance == "import") {
          return "is-bold"
        }
      },
      columns: [
        buildTop5CountryColumn("Top&nbsp;1", "top1"),
        buildTop5CountryColumn("Top&nbsp;2", "top2"),
        buildTop5CountryColumn("Top&nbsp;3", "top3"),
        buildTop5CountryColumn("Top&nbsp;4", "top4"),
        buildTop5CountryColumn("Top&nbsp;5", "top5"),
      ],
    }),

    new ColumnGroup({
      id: "weighted_risks",
      label: translator("apps.supply_chain.groups.weighted_risks"),
      columns: [
        new Column({
          label: "",
          getValue: (supplyChainItem) => {
            if (supplyChainItem.weighted_human_rights_scores?.length) {
              return translator("apps.supply_chain.all_import_partners", {
                country: countryLabel(supplyChainItem.country_of_demand)
              })
            }
            return "-"
          }
        }),
        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          getScores: (supplyChainItem) => supplyChainItem.weighted_human_rights_scores
        })
      ]
    }),

    new ColumnGroup({
      id: "country_of_production",
      label: translator("apps.supply_chain.groups.country_of_production"),
      shortValue: (supplyChainItem) => supplyChainItem.country_of_production?.iso_code || supplyChainItem.country_of_production?.name,
      columns: [
        new Column({
          label: translator("apps.supply_chain.columns.name"),
          getValue: (supplyChainItem) => countryLabel(supplyChainItem.country_of_production) || "-",
        }),
        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          tooltipTitle: (supplyChainItem) => `${supplyChainItem.country_of_production?.iso_code} | ${supplyChainItem.country_of_production?.name}`,
          getScores: (supplyChainItem) => supplyChainItem.country_of_production?.hr_scores
        })
      ],
    }),

    new ColumnGroup({
      id: "relevant_country_of_supply",
      label: translator("apps.supply_chain.groups.relevant_country_of_supply"),
      shortValue: (supplyChainItem) => {
        return relevantCountryLabel(supplyChainItem)
      },
      columns: [
        new Column({
          label: "Name",
          getValue: (supplyChainItem) => relevantCountryLabel(supplyChainItem)
        }),
        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          tooltipTitle: (supplyChainItem) => relevantCountryLabel(supplyChainItem),
          getScores: (supplyChainItem) => getRelevantCountry(supplyChainItem)?.hr_scores || supplyChainItem.weighted_human_rights_scores
        })
      ],
    }),

    new ColumnGroup({
      id: "commodity_risk",
      label: translator("apps.supply_chain.groups.commodity_risk"),
      shortValue: (supplyChainItem) => {
        let country = getRelevantCountry(supplyChainItem)
        if (country) {
          return country.iso_code || country.name
        }
      },

      // show total column as short column
      shortColumns: [2 + dimensions.length - 1],
      columns: [
        new Column({
          suffixComponent: "FlagsColumnContent",
          getProps: (supplyChainItem) =>{
            let flagCount = supplyChainItem.flag_counts.sector_human_rights_scores
            return {
              showNumber: flagCount > 0,
              flagCount: flagCount
            }
          },
          getValue: (supplyChainItem) => supplyChainItem.sector && `${supplyChainItem.sector.code} | ${supplyChainItem.sector.short_name}` || "-",
          label: translator("apps.supply_chain.columns.sector"),
          hrTooltipData: (supplyChainItem) => {
            if (!supplyChainItem.sector) { return }

            return {
              hrScores: supplyChainItem.sector.scores,
              title: `${supplyChainItem.sector.code} | ${supplyChainItem.sector.short_name}`
            }
          }
        }),
        new Column({
          suffixComponent: "FlagsColumnContent",
          getProps: (supplyChainItem) =>{
            let flagCount = supplyChainItem.flag_counts.relevant_country_scores
            return {
              showNumber: flagCount > 0,
              flagCount: flagCount
            }
          },
          label: translator("apps.supply_chain.columns.country_of_production"),
          getValue: (supplyChainItem) => relevantCountryLabel(supplyChainItem),
          hrTooltipData: (supplyChainItem) => {
            let country = getRelevantCountry(supplyChainItem)
            let scores = country?.hr_scores || supplyChainItem.weighted_human_rights_scores
            if (!scores.length) { return }

            let importExportInfo
            if (country) {
              importExportInfo = importExportValuesTooltipContent(
                country.import_export_values,
                translator
              )
            }

            return {
              hrScores: scores,
              title: relevantCountryLabel(supplyChainItem),
              text: importExportInfo
            }
          }
        }),

        ...ScoreColumn.buildCollection({
          dimensions: dimensions,
          tooltipTitle: (supplyChainItem) => relevantCountryLabel(supplyChainItem),
          getScores: (supplyChainItem) => supplyChainItem.calculated_scores,
          component: "CalculatedScoresColumn",
          getProps: function(supplyChainItem) {
            return {
              supplyChainItem: supplyChainItem,
              dimension: this.dimension
            }
          }
        })
      ],
    }),

    new ColumnGroup({
      id: "flags",
      expandable: false,
      label: "",
      columns: [
        new Column({
          tooltip: translator("apps.supply_chain.column_tooltips.flags"),
          label: translator("apps.supply_chain.columns.flags"),
          component: "FlagsColumn",
          getSortValue: (supplyChainItem) => supplyChainItem.flag_counts.total,
          getProps: (supplyChainItem) => {
            return {
              flagCount: supplyChainItem.flag_counts.total
            }
          }
        })
      ]
    })
  ];
};

export default buildColumnDefinitions;
