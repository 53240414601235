<template>
  <div class="filter-dialog">
    <div class="filter-dialog--head-section">
      <p class="filter-dialog--head-section-title">
        {{ $t('components.notation_filters.captions.name_contains') }}
      </p>
      <div class="filter-dialog--head-section-actions">
        <ButtonComponent
          class="filter-dialog--head-section-button"
          @click="clearSearch()"
        >
          {{ $t('components.notation_filters.buttons.clear') }}
        </ButtonComponent>
      </div>
    </div>
    <div class="filter-dialog--section no-border">
      <div class="filter-dialog--search-input-container">
        <!-- eslint-disable vue/no-mutating-props -->
        <input
          autofocus
          ref="searchInput"
          v-model="filterObject.query"
          type="text"
          class="filter-dialog--search-input"
          @input="onChange"
          @keypress.enter="onApply"
        >
        <!-- eslint-enable vue/no-mutating-props -->
        <Icon
          class="filter-dialog--search-icon"
          name="search"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';
export default {
  name: 'FilterByText',
  props: {
    filterObject: {
      type: Object,
      required: true,
    },
  },
  emits: ['change', 'apply'],
  mounted() {
    if (this.$refs.searchInput) {
      this.$refs.searchInput.focus()
    }
  },
  methods: {
    clearSearch() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filterObject.query = '';
      this.$refs.searchInput.focus();
      this.$emit('change');
    },
    onChange() {
      // search updates might come from the server, so we debounce the event
      if (!this.debouncedOnChange) {
        this.debouncedOnChange = debounce(()=>{
          this.$emit('change');
        }, 200)
      }
      this.debouncedOnChange()
    },
    onApply() {
      this.$emit('apply');
    },
  },
};
</script>
