class Cache {
  constructor() {
    this._cache = {};
    this._pendingCacheRequests = {};
  }

  _addPendingRequest(cacheKey, resolve, reject) {
    this._pendingCacheRequests[cacheKey] = this._pendingCacheRequests[cacheKey] || [];
    this._pendingCacheRequests[cacheKey].push({ resolve, reject });
  }

  _clearPendingRequests(cacheKey) {
    delete this._pendingCacheRequests[cacheKey];
  }

  _resolvePendingRequests(cacheKey, value) {
    (this._pendingCacheRequests[cacheKey] || []).forEach((promise) => promise.resolve(value));
  }

  _rejectPendingRequests(cacheKey, error) {
    (this._pendingCacheRequests[cacheKey] || []).forEach((promise) => promise.reject(error));
  }

  clear() {
    this._clearPendingRequests();
    this._cache = {};
  }

  store(cacheKey, value) {
    this._cache[cacheKey] = value;
  }

  fetch(cacheKey, fetchFunction) {
    if (this._cache[cacheKey] === Cache.Pending) {
      return new Promise((resolve, reject) => {
        this._addPendingRequest(cacheKey, resolve, reject);
      });
    }
    if (this._cache[cacheKey] !== undefined) {
      return Promise.resolve(this._cache[cacheKey]);
    }
    this._cache[cacheKey] = Cache.Pending;
    return fetchFunction().then((value) => {
      // console.log(value)
      this._cache[cacheKey] = value;
      this._resolvePendingRequests(cacheKey, value);
      this._clearPendingRequests(cacheKey);
      return value;
    }).catch((error) => {
      delete this._cache[cacheKey];
      this._rejectPendingRequests(cacheKey, error);
      this._clearPendingRequests(cacheKey);
      throw error;
    });
  }
}

Cache.Pending = Symbol.for('Cache.Pending');

export default Cache;
