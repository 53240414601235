<template>
  <li>
    <template v-if="disabled">
      <WithTooltip
        :tooltip="$t('layouts.app_navigation.human_rights.buy_subscription_notice')"
      >
        <span class="app-header--user-link disabled">
          <slot name="icon">
            <Icon
              class="icon"
              :name="icon"
              :fixed-width="true"
              variant="regular"
            />
          </slot>

          {{ text }}
        </span>
      </WithTooltip>
    </template>
    <component
      v-else
      :is="tagName"
      v-bind="tagAttributes"
      :href="href"
      class="app-header--user-link"
      data-turbolinks="false"
      @click="$emit('click', $event)"
    >
      <slot name="icon">
        <Icon
          class="icon"
          :name="icon"
          :fixed-width="true"
          variant="regular"
        />
      </slot>
      {{ text }}
    </component>
  </li>
</template>

<script>
export default {
  name: 'NavigationUserMenuItem',
  props: {
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    turbolinks: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    tagName() {
      return this.href ? 'a' : 'button';
    },
    tagAttributes() {
      const attributes = {};
      if (this.turbolinks === false) {
        attributes['data-turbolinks'] = false;
      }
      if (this.targetBlank) {
        attributes.target = '_blank';
        attributes.rel = 'noopener noreferrer';
      }
      return attributes;
    },
  },
};
</script>
