import slugify from 'slugify';
import i18n from '@/config/i18n';
import * as XLSX from 'xlsx';

const QuotesToExcel = (notation, quotes, unit) => {
  const headerLocale = 'en'; // header row should always be english
  const includedMetaData = ['class_system', 'class_code', 'name', 'reporter', 'partner', 'timescope', 'flow', 'indicator', 'source'];
  const metaColumns = includedMetaData.map((key) => i18n.t(`attributes.notation.${key}`, headerLocale));
  const columnNames = [
    ...metaColumns,
    i18n.t('attributes.notation.unit', headerLocale),
    i18n.t('attributes.quote.date', headerLocale),
    i18n.t('attributes.quote.value', headerLocale),
  ];
  const metaValues = includedMetaData.map((key) => notation[key]);
  const columnValues = quotes.map((quote) => [
    ...metaValues,
    unit,
    { t: 'd', v: quote.date.toISOString() },
    quote.value,
  ]);
  return [columnNames].concat(columnValues);
};

const filenameForNotation = (notation) => {
  const filenameParts = [
    notation.class_system,
    notation.class_code,
    notation.name.slice(0, 20),
    notation.reporter,
    notation.partner,
    notation.flow,
    notation.indicator,
    notation.source,
  ].filter((value) => !!value);
  return `${slugify(filenameParts.join('_'))}.xlsx`;
};

const SaveToExcel = ({
  notation, quotes, unit, whitelistedSources,
}) => {
  let rows = QuotesToExcel(notation, quotes, unit, i18n.locale);
  // Add hint for non whitelisted sources that report is only for internal use
  if (!whitelistedSources.includes(notation.source)) {
    rows = [[i18n.t('generic.for_internal_use_only')], ...rows];
  }
  const filename = filenameForNotation(notation);
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    ...rows,
  ]);
  XLSX.utils.book_append_sheet(wb, ws);
  XLSX.writeFile(wb, filename, { cellDates: true });
};

export default { QuotesToExcel, SaveToExcel };
