<template>
  <div class="dashboard-header--chart">
    <QuotesChart
      :selected-graph-limits="selectedGraphLimits"
      :primary-notation-details="primaryNotationDetails"
      :secondary-notation-details="secondaryNotationDetails"
      :show-date-slider="showDateSlider"
      :selected-date="selectedDate"
      @update:selectedGraphLimits="$emit('update:selectedGraphLimits', $event)"
      @change-date="setSelectedDate"
    />
  </div>

  <div class="dashboard-header--legend">
    <div
      v-if="primaryNotationDetails.notation && primaryNotationDetails.hasLoaded"
      class="body--column is-primary"
    >
      <NotationDetails
        slot-kind="primary"
        :notation="primaryNotationDetails.notation"
        :selected-date="selectedDate"
        :show-absolute-prices="showAbsolutePrices"
        v-bind="primaryNotationDetails.data"
      />
    </div>
    <div
      v-if="secondaryNotationDetails.notation && secondaryNotationDetails.hasLoaded"
      class="body--column is-secondary"
    >
      <NotationDetails
        slot-kind="secondary"
        class="is-secondary"
        :notation="secondaryNotationDetails.notation"
        :selected-date="selectedDate"
        :show-absolute-prices="showAbsolutePrices"
        v-bind="secondaryNotationDetails.data"
      />
    </div>
  </div>
</template>

<script>
import NotationDetails from '@/components/NotationDetails/Component.vue';
import QuotesChart from '@/components/QuotesChart/Component.vue';

export default {
  name: 'NotationCardOverview',
  components: {
    NotationDetails,
    QuotesChart,
  },
  props: {
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    showDateSlider: {
      type: Boolean,
      default: true,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    showDatePickerButton: {
      type: Boolean,
      default: true,
    },
    showAbsolutePrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change-date', 'update:selectedGraphLimits', 'close'],
  setup(_props, { emit }) {
    const setSelectedGraphLimits = (graphLimits) => {
      emit('update:selectedGraphLimits', graphLimits);
    };
    return {
      setSelectedGraphLimits,
    };
  },
  methods: {
    setSelectedDate(date) {
      this.$emit('change-date', date);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>