<template>
  <div class="autocomplete--footer">
    <template v-if="!controller.searchTermRequested">
      {{ $t('components.search_suggestions.search_term_request.description') }}
      <button
        class="autocomplete--footer-link"
        @mousedown="controller.requestSearchTerm()"
      >
        {{ $t('components.search_suggestions.search_term_request.link') }}
      </button>
    </template>
    <template v-else>
      <Icon
        class="autocomplete--footer-icon"
        name="check-circle"
        variant="light"
      />
      {{ $t('components.search_suggestions.search_term_request.on_success') }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'AutoCompleteFooter',
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
