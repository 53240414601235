<template>
  <div
    v-click-outside="hideOptions"
    class="combobox"
    :class="{'is-open': isOpen}"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`option-list-${uid}`"
  >
    <button
      ref="button"
      type="button"
      class="combobox--button"
      role="button"
      aria-autocomplete="list"
      :aria-controls="`option-list-${uid}`"
      :aria-expanded="isOpen"
      :aria-label="label"
      :aria-labelledby="ariaLabelledby"
      :disabled="disabled"
      aria-multiline="false"
      @click="toggleOptions"
      @keyup.up.down.prevent="showOptions"
      @keyup.up.prevent="focusPrevious"
      @keyup.down.prevent="focusNext"
    >
      <slot
        name="button"
        :value="currentOption"
      >
        <ComboBoxButton
          :description="description"
          :description-tooltip="descriptionTooltip"
        >
          {{ displayTextOverride || currentOption.label }}
        </ComboBoxButton>
      </slot>
    </button>

    <div
      v-if="isOpen"
      class="combobox--popup"
    >
      <ul
        :id="`option-list-${uid}`"
        ref="options"
        class="combobox--options-list"
        tabindex="-1"
        role="listbox"
        :aria-activedescendant="`option-${uid}-${focussedValue}`"
        @keydown.down.up.prevent
        @keyup.up.prevent="focusPrevious"
        @keyup.down.prevent="focusNext"
        @keydown.enter.prevent="accept"
        @keydown.esc.prevent="hideOptionsAndFocus"
      >
        <li
          v-for="(option, index) in options"
          :key="`${index}-${option}`"
        >
          <button
            :id="`$option-${uid}-${index}`"
            :ref="getRefNameForIndex(option.value)"
            type="button"
            class="combobox--option"
            :class="{'has-focus': focussedValue === option.value}"
            :aria-labelledby="ariaLabelledby"
            :aria-selected="focussedValue === option.value"
            role="option"
            @mousemove="focus(option.value)"
            @click="select(option)"
          >
            <slot
              name="option"
              :option="option"
            >
              <ComboBoxOption>
                {{ option.label }}
              </ComboBoxOption>
            </slot>
          </button>
        </li>
      </ul>
      <div
        v-if="$slots.footer"
        class="combobox--footer"
      >
        <slot
          name="footer"
          :focussed-option="focussedOption"
          :current-option="currentOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';
import scrollToElement from '../../helpers/ScrollToElement';

export default {
  name: 'ComboBox2',
  mixins: [HasUid],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayTextOverride: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    descriptionTooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      focussedValue: this.modelValue?.value,
    };
  },
  computed: {
    currentOption() {
      return this.options.find((o) => o.value === this.modelValue?.value);
    },
    focussedOption() {
      if (this.focussedValue === null) return null;
      return this.options.find((o) => o.value === this.focussedValue);
    },
  },
  watch: {
    focussedValue() {
      this.adjustScrollPosition();
    },
  },
  methods: {
    getRefNameForIndex(value) {
      return `option-${value}`;
    },
    adjustScrollPosition() {
      const focusedRef = this.getRefNameForIndex(this.focussedValue);
      const focusItem = this.$refs[focusedRef];
      if (!focusItem) return;
      scrollToElement(this.$refs.options, focusItem[0]);
    },
    toggleOptions() {
      if (this.isOpen) {
        this.hideOptions();
      } else {
        this.showOptions();
      }
    },
    showOptions() {
      this.isOpen = true;
      // we defer the call to focus() because the popup is not
      // visible until the next tick
      this.$nextTick().then(() => this.$refs.options.focus());
    },
    hideOptions() {
      this.isOpen = false;
    },
    hideOptionsAndFocus() {
      this.hideOptions();
      // we defer the call to focus() to prevent the button
      // focus from getting randomly overwritten when the
      // popup closes in the next tick
      this.$nextTick().then(() => this.$refs.button.focus());
    },
    select(option) {
      if (!option) return;
      this.$emit('update:modelValue', option);
      this.hideOptionsAndFocus();
    },
    focus(value) {
      if (this.options.length === 0) return;
      this.focussedValue = value;
    },
    focusPrevious() {
      const currentIndex = this.options.findIndex((o) => o.value === this.focussedValue);
      this.focus(this.options[currentIndex - 1].value);
    },
    focusNext() {
      const currentIndex = this.options.findIndex((o) => o.value === this.focussedValue);
      this.focus(this.options[currentIndex + 1].value);
    },
    accept() {
      this.select(this.options.find((o) => o.value === this.focussedValue));
      this.hideOptionsAndFocus();
    },
  },
};
</script>
