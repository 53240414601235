<script>
import { AutoComplete, SpinningLoader } from "@/components/generic";
import DropdownFooterTooltip from "@/components/DropdownFooterTooltip";
import axios from "axios";
import uuid from '@/utils/uuid'

export default {
  components: {
    AutoComplete,
    SpinningLoader,
    DropdownFooterTooltip,
  },

  props: {
    endpoint: {
      type: String,
      required: true,
    },

    value: {
      type: [String, Number],
    },

    inputName: {
      type: String,
    },

    includeBlank: {
      type: Boolean,
      default: false,
    },

    selectedLabel: {
      type: String,
      default: null,
    },

    description: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    noOptionsText: {
      type: String,
      default: null,
    },

    tooltip: {
      type: Boolean,
      default: false,
    },

    attributeName: {
      type: String,
      default: null,
    },

    modelValue: {
      type: [String, Number],
    },

    // Default/Static filters that will be passed to the autocomplete request
    staticFilters: {
      type: Object,
      default: null,
    },

    // Dynamic filters that will be rendered as radio buttons which can be
    // selected by a user. The selected value will be passed to the autocomplete
    // request
    userSelectedFilters: {
      type: Object,
      default: null
    },

    // Configuration for passing values from other inputs on the page
    // to the autocomplete request.
    // Examples:
    //
    // a) Passing the currently selected value of an input/select with the
    //     id "classification_id" as the parameter "sector_classification_id"
    //     to each autocomplete request:
    //
    //       sector_classification_id: {
    //         selector: "#classification_id"
    //       }
    //
    // b) First find the closest ".nested-item" node, then find the input with
    //    "[data-attribute-name=country_of_demand_id]" and pass its currently
    //    selected value as "partner_country_id" to each autocomplete request:
    //
    //      partner_country_id: {
    //        closest: ".nested-item",
    //        selector: "[data-attribute-name=country_of_demand_id]"
    //      }
    externalInputFilters: {
      type: Object,
      default: null
    },
  },

  data() {
    let selectedFilters = {}
    if (this.userSelectedFilters) {
      Object.keys(this.userSelectedFilters).forEach((key)=> selectedFilters[key] = null)
    }

    return {
      uuid: uuid(),
      preselectedRecord: null,
      options: [],
      currentQuery: "",
      selectedValue: this.value || this.modelValue,
      selectedFilters: selectedFilters,
      currentPage: null,
      lastPageReached: false,
      isLoading: false,
    }
  },

  mounted() {
    if (!this.selectedValue) {
      return
    }

    if (this.selectedLabel) {
      this.preselectedRecord = {
        id: this.selectedValue,
        label: this.selectedLabel,
      }
    } else {
      axios.get(this.endpoint, { params: { id: this.selectedValue } }).then((response) => {
        this.preselectedRecord = response.data[0]
      })
    }
  },

  methods: {
    additionalFilterValues() {
      if (!this.externalInputFilters) { return {} }

      let filters = {}
      for (let [paramName, options] of Object.entries(this.externalInputFilters)) {
        let parent = document
        if (options.closest) {
          parent = this.$el.closest(options.closest)
        }

        filters[paramName] = parent.querySelector(options.selector)?.value
      }

      return filters
    },

    onFocus() {
      if (this.options.length && !this.externalInputFilters) {
        // if we already have fetched options for the current query,
        // we don't need to fetch them again.
        // However, if we have externalInputFilters coming from other inputs,
        // we cannot guarantee that the results would be the same
        return
      }
      this.performSearch(this.currentQuery)
    },

    onInfiniteScroll() {
      if (!this.currentPage) { return }
      this.performSearch(this.currentQuery, this.currentPage + 1)
    },

    queryUpdated(query) {
      this.currentQuery = query

      if (this.$refs.autocomplete?.isOpen) {
        this.performSearch(this.currentQuery)
      }
    },

    performSearch(query, page=1) {
      this.isLoading = true

      let params = { query, page }
      params.filters = {}
      if (this.staticFilters) {
        params.filters = this.staticFilters
      }
      if (this.selectedFilters) {
        params.filters = Object.assign(params.filters, this.selectedFilters)
      }
      params.filters = Object.assign(params.filters, this.additionalFilterValues())

      axios.get(this.endpoint, { params: params }).then((response) => {
        this.currentPage = page
        if (page > 1) {
          // append if not on first page
          this.options.push(...response.data)
        } else {
          // if on first page, scroll to top and replace options
          this.scrollToTop()
          this.options = response.data
        }

        // infinite scroll will stop as soon as a page is reached which is not "full"
        this.lastPageReached = response.data.length < 20

        // setTimeout makes sure that isLoading will be set to false after
        // the new options have been rendered
        setTimeout(()=>{
          this.isLoading = false
        }, 0)
      })
    },
    modelValueUpdated(value) {
      // Update preselected value to make sure that the currently selected item does
      // not necessarily have to be in the options list. Otherwise the selected item
      // might get cleared when the filters change
      this.preselectedRecord = {
        id: value,
        label: this.options.find((option) => option.id == value)?.label
      }

      this.selectedValue = value
      this.$emit("update:modelValue", value)
    },

    scrollToTop() {
      let optionsNode = this.$refs.autocomplete.$refs.options
      optionsNode?.scrollTo(0, 0)
    }
  },

  computed: {
    infiniteScrollActive() {
      return this.currentPage && !this.isLoading && !this.lastPageReached
    }
  },

  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        this.performSearch(this.currentQuery)
      }
    }
  }
};
</script>

<template>
  <div>
    <AutoComplete
      ref="autocomplete"
      @update:query="queryUpdated($event)"
      @update:modelValue="modelValueUpdated($event)"
      @trigger:infiniteScroll="onInfiniteScroll"
      @focus="onFocus"
      :infiniteScroll="infiniteScrollActive"
      :selectedRecord="preselectedRecord"
      :modelValue="selectedValue"
      :options="options"
      :access-key="(c) => c.id"
      :access-value="(c) => c.label"
      :isAsync="true"
      :isFullWidth="true"
      :allowEmptyValue="includeBlank"
      :description="description"
      :placeholder="placeholder"
      :no-options-text="noOptionsText"
      :minLength="0"
    >
      <template #header>
        <div
          v-for="(filterOptions, key) in userSelectedFilters"
          :key="`user-selected-filter-${uuid}-${key}`"
          class="autocomplete-filters"
        >
          <label
            class="is-inline-block mr-m"
            v-for="inputOptions in filterOptions"
            :key="inputOptions[0]"
          >
            <input
              tabindex="1"
              type="radio"
              :value="inputOptions[0]"
              :name="`filter-${key}-${uuid}`"
              v-model="selectedFilters[key]"
            />
            {{ inputOptions[1] }}
          </label>
        </div>
      </template>
      <template #list-end>
        <li v-if="isLoading" class="p-s">
          <i>{{ $t("components.search_results.is_loading") }}</i>
        </li>
      </template>
      <template #footer="{ focussedOption }" v-if="tooltip">
        <DropdownFooterTooltip v-if="focussedOption">
          {{ focussedOption.label }}
        </DropdownFooterTooltip>
      </template>
    </AutoComplete>

    <input type="hidden"
      v-if="inputName"
      :name="inputName"
      :value="selectedValue"
      :data-attribute-name="attributeName"
    />
  </div>
</template>

<style lang="scss">
.autocomplete-filters {
  background: #f8f8f8;
  border-bottom: 2px solid #eaeaea;
  padding: 10px;
}
</style>
