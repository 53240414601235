<template>
  <div class="page-header">
    <SpinningLoader v-if="isLoading" />
    <div
      v-else-if="hasClassifications"
      class="page-header--row"
    >
      <label
        id="classification-label"
        class="visually-hidden"
      >
        {{ $t('components.trade_flows.classification_label') }}
      </label>
      <ClassificationComboBox
        :model-value="selectedClassification"
        class="page-header--row-item menu--classification-combobox"
        aria-labelledby="classification-label"
        :options="classifications"
        @update:modelValue="onClassificationChanged"
      />
      <ComboBox
        v-model="selectedSource"
        class="menu--source-combobox"
        :label="$t('components.trade_flows.source_label')"
        :options="availableSources"
        @update:modelValue="onChanged"
      >
        <template #footer="scope">
          <div class="menu--source-footer">
            <p class="menu--source-footer-text">
              {{ sourceDescriptionForScope(scope) }}
            </p>
          </div>
        </template>
      </ComboBox>
      <SpinBox
        v-model="selectedYear"
        class="page-header--row-item"
        :label="$t('components.trade_flows.year_label')"
        :min="1900"
        :max="new Date().getFullYear() - 1"
        @update:modelValue="onChanged"
      />
    </div>
  </div>
</template>

<script>
import UrlStateStore from '@/services/UrlStateStore';
import TradeFlowsApi from '@/apps/DashboardTradeFlows/api/TradeFlowsApi';
import ClassificationComboBox from '../ClassificationComboBox/Component.vue';

export default {
  name: 'DashboardTradeFlowsMenu',
  components: {
    ClassificationComboBox,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      api: TradeFlowsApi(),
      selectedYear: UrlStateStore.get('year', new Date().getFullYear() - 2),
      selectedClassification: 0,
      selectedSource: 0,
      classifications: [],
      isLoading: true,
    };
  },
  computed: {
    hasClassifications() {
      return this.classifications.length > 0;
    },
    selectedSourceIndex() {
      return this.availableSources.find(this.selectedSource) || 0;
    },
    currentClassification() {
      return this.classifications[this.selectedClassification];
    },
    availableSources() {
      return this.currentClassification?.sources || [];
    },
    currentSource() {
      return this.availableSources[this.selectedSource];
    },
    defaultClassificationIndex() {
      const uncomtradeClassifications = this.classifications.map((c, index) => ({ ...c, index }))
        .filter((c) => c.sources.includes('UNComtrade'));
      const index = uncomtradeClassifications.length - 1;
      return index < 0 ? 0 : uncomtradeClassifications[index].index;
    },
    defaultSourceIndex() {
      return Math.max(0, this.availableSources.indexOf('UNComtrade'));
    },
  },
  beforeMount() {
    this.fetchClassifications();
  },
  methods: {
    sourceDescriptionForScope(scope) {
      const source = (scope.focussedOption || scope.currentOption)?.toLowerCase();
      return this.$t(`apps.trade_flow_board.menu.source_tooltip.${source}`);
    },
    onClassificationChanged(index) {
      const source = this.currentSource;
      this.selectedClassification = index;
      const newSourceIndex = this.availableSources.indexOf(source);
      if (newSourceIndex < 0) {
        this.selectedSource = this.defaultSourceIndex;
      } else {
        this.selectedSource = newSourceIndex;
      }
      this.onChanged();
    },
    fetchClassifications() {
      this.isLoading = true;
      this.api.classifications(this.dashboard.id).then((response) => {
        this.classifications = response.data.map((c) => ({
          ...c.classification,
          sources: c.sources,
        }));
        this.selectedClassification = UrlStateStore.get('classification', this.defaultClassificationIndex);
        this.selectedSource = UrlStateStore.get('source', this.defaultSourceIndex);
        this.onChanged();
      }).finally(() => { this.isLoading = false; });
    },
    onChanged() {
      // TODO: don't store indices
      UrlStateStore.set('classification', this.selectedClassification);
      UrlStateStore.set('year', this.selectedYear);
      UrlStateStore.set('source', this.selectedSource);
      this.$emit('change', {
        classification: this.classifications[this.selectedClassification],
        year: this.selectedYear,
        source: this.currentSource,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>