<template>
  <div class="chart">
    <div class="chart--wrapper">
      <QuotesChartAxisLabel
        v-if="primaryNotationDetails.hasLoaded"
        :label="primaryNotationDetails.data.unit"
        :show-bullet="true"
        axis="primary"
      />
      <div class="chart--plot">
        <LineChart
          :chart-data="{ datasets: datasets, annotations: annotations }"
          :options="chartOptions"
          :graph-limits="graphLimits"
          @select-date="onSelectDate"
        />
      </div>
      <QuotesChartAxisLabel
        v-if="secondaryNotationDetails.hasLoaded"
        :label="secondaryNotationDetails.data.unit"
        :show-bullet="true"
        axis="secondary"
      />
    </div>
    <div
      v-if="slider"
      class="chart--date-range"
    >
      <VueSlider
        v-model="slider.value"
        class="chart--date-range-slider"
        :min="slider.minimumValue"
        :max="slider.maximumValue"
        :min-range="slider.minimumRange"
        :enable-cross="false"
        :tooltip-formatter="slider.tooltipFormatter"
      />
      <DateRangeButton
        class="chart--date-range-button"
        @select="changeStartDateTo"
        @select-max="changeStartDateToEarliestDate"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import LineChart from '@/components/LineChart/Component.vue';
import DateSlider from '@/models/DateSlider';
import { NotationChartDescription, NotationChartStyles } from '@/models/charts/NotationChartDescription';
import { reactive } from 'vue';
import moment from 'moment';
import { valuesForYAxis } from '@/helpers/ChartHelpers';
import QuotesChartAxisLabel from './AxisLabel/Component.vue';
import DateRangeButton from './DateRangeButton/Component.vue';
import ChartOptions from './ChartOptions';
import { DEFAULT_GRAPH_START_DATE } from './Defaults';

export default {
  name: 'QuotesChart',
  components: {
    LineChart,
    VueSlider,
    QuotesChartAxisLabel,
    DateRangeButton,
  },
  props: {
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: null,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
    showDateSlider: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change-date', 'update:selectedGraphLimits'],
  data() {
    const graphLimits = reactive({
      minDate: this.selectedGraphLimits.minDate,
      maxDate: this.selectedGraphLimits.maxDate,
      startDate: this.selectedGraphLimits.startDate,
      endDate: this.selectedGraphLimits.endDate,
    });
    return {
      graphLimits,
      slider: this.showDateSlider
        ? DateSlider(graphLimits) : null,
    };
  },
  computed: {
    notationDetails() {
      return [
        this.primaryNotationDetails,
        this.secondaryNotationDetails,
      ];
    },
    activeNotationDetails() {
      return this.notationDetails.filter((nD) => nD.hasSelectedNotation);
    },
    datasets() {
      return [
        new NotationChartDescription(
          {
            notationDetails: this.primaryNotationDetails,
            chartStyle: NotationChartStyles.PRIMARY,
          },
        ),
        new NotationChartDescription(
          {
            notationDetails: this.secondaryNotationDetails,
            chartStyle: NotationChartStyles.SECONDARY,
          },
        ),
      ].filter((chartDesc) => !chartDesc.empty())
        .map((chartDesc) => chartDesc.toDataSet());
    },
    annotations() {
      if (!this.selectedDate) return [];
      const result = [{
        type: 'VerticalLine',
        x: this.selectedDate,
      }];
      const [primaryCustomDateKPI, secondaryCustomDateKPI] = [
        this.primaryNotationDetails, this.secondaryNotationDetails,
      ].map((nD) => nD?.data?.keyPerformanceIndicators?.custom_date_avg?.value);

      if (primaryCustomDateKPI) {
        result.push({
          type: 'HorizontalLine',
          left: this.selectedDate,
          y: primaryCustomDateKPI,
          color: 'rgb(255, 184, 37)',
          yAxis: 'y-axis-1',
        });
      }
      if (secondaryCustomDateKPI) {
        result.push({
          type: 'HorizontalLine',
          left: this.selectedDate,
          y: secondaryCustomDateKPI,
          color: 'rgb(205, 212, 222)',
          yAxis: 'y-axis-2',
        });
      }
      return result;
    },
    chartOptions() {
      const yAxisOneValues = valuesForYAxis('y-axis-1', this.datasets, this.graphLimits);
      const yAxisTwoValues = valuesForYAxis('y-axis-2', this.datasets, this.graphLimits);
      const printing = this.$media === 'print';
      return ChartOptions({
        xMin: this.graphLimits.startDate,
        xMax: this.graphLimits.endDate,
        yAxisOneMin: yAxisOneValues.length > 0 ? Math.min(...yAxisOneValues) : null,
        yAxisOneMax: yAxisOneValues.length > 0 ? Math.max(...yAxisOneValues) : null,
        yAxisTwoMin: yAxisTwoValues.length > 0 ? Math.min(...yAxisTwoValues) : null,
        yAxisTwoMax: yAxisTwoValues.length > 0 ? Math.max(...yAxisTwoValues) : null,
        drawSecondaryYAxisLines: !this.primaryNotationDetails,
        fontSize: printing ? 10 : undefined,
        axisType: 'x',
      });
    },
    hasPendingNotations() {
      return this.activeNotationDetails.some(
        (notationDetails) => !notationDetails.notation,
      );
    },
    earliestQuoteDate() {
      if (this.hasPendingNotations) return null;

      const earliestQuoteTimestampsPerNotation = this.activeNotationDetails
        .map((notationDetails) => notationDetails.earliestQuoteDate.valueOf());

      if (earliestQuoteTimestampsPerNotation.length === 0) return null;

      return moment(Math.min(...earliestQuoteTimestampsPerNotation));
    },
  },
  watch: {
    earliestQuoteDate: {
      immediate: true,
      handler: function adjustGraphMinimum() {
        if (this.earliestQuoteDate === null) return;

        if (this.graphLimits.startDate.valueOf() < this.earliestQuoteDate.valueOf()) {
          this.graphLimits.startDate = this.earliestQuoteDate;
        }

        this.graphLimits.minDate = this.earliestQuoteDate;
      },
    },
    'graphLimits.startDate': {
      handler() {
        this.ensureAllQuotesAreLoaded(this.earliestQuoteDate);
      },
    },
    graphLimits: {
      deep: true,
      handler() {
        this.$emit('update:selectedGraphLimits', { ...this.graphLimits });
      },
    },
    selectedGraphLimits: {
      deep: true,
      handler() {
        this.graphLimits.minDate = this.selectedGraphLimits.minDate;
        this.graphLimits.maxDate = this.selectedGraphLimits.maxDate;
        this.graphLimits.startDate = this.selectedGraphLimits.startDate;
        this.graphLimits.endDate = this.selectedGraphLimits.endDate;
      },
    },
  },
  beforeMount() {
    if (this.selectedGraphLimits) {
      this.ensureAllQuotesAreLoaded(moment(this.selectedGraphLimits.startDate));
    }
  },
  methods: {
    onSelectDate(date) {
      this.$emit('change-date', date);
    },
    changeStartDateTo(newStartDate) {
      this.graphLimits.startDate = newStartDate;
      this.graphLimits.endDate = this.graphLimits.maxDate;
    },
    changeStartDateToEarliestDate() {
      this.graphLimits.startDate = this.graphLimits.minDate;
      this.graphLimits.endDate = this.graphLimits.maxDate;
    },
    ensureAllQuotesAreLoaded(earliestQuoteDate) {
      if (this.graphLimits.startDate.valueOf() < DEFAULT_GRAPH_START_DATE.valueOf()) {
        this.notationDetails.forEach(
          (nD) => nD.ensureQuotesAreLoaded({ startDate: earliestQuoteDate }),
        );
      }
    },

  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>