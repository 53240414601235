import EventBase from './EventBase';

class DashboardGroupFavoriteChangeEvent extends EventBase {
  constructor() {
    super();
    this._data = {};
  }

  static get EventName() {
    return 'HGS::DashboardGroupFavoriteChangeEvent';
  }
}

export default DashboardGroupFavoriteChangeEvent;
