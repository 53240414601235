<template>
  <li
    v-click-outside="hideOnClickOutside"
    class="dashboard-tab--item"
  >
    <button
      class="dashboard-tab--button"
      :class="{'is-open': showCard, 'is-active': isActive}"
      :disabled="tab.disabled || undefined"
      @click="toggleShowCard()"
    >
      <span class="dashboard-tab--content">
        {{ tab.name }}
        <UnreadCountIcon
          v-if="tab.unreadCount > 0"
          :unread-count="tab.unreadCount"
          class="dashboard-tab--unread-count-icon"
        />
      </span>
    </button>

    <CollapsedListCardScrollarea
      v-if="showCard"
      :translations="translations"
      :path-to-all="tab.href"
      :item-loader="itemLoader"
      :is-collapsed="false"
      class="dashboard-tab--pop-up"
    >
      <template #item="slotProps">
        <slot
          name="item"
          :item="slotProps.item"
        />
      </template>
    </CollapsedListCardScrollarea>
  </li>
</template>

<script>
import UnreadCountIcon from '@/components/UnreadCountIcon/Component.vue';
import CollapsedListCardScrollarea from '@/apps/Intro/components/WelcomeBanner/CollapsedListCardScrollarea/Component.vue';

export default {
  name: 'DashboardTab',
  components: {
    UnreadCountIcon,
    CollapsedListCardScrollarea,
  },
  props: {
    tab: {
      type: Object,
      required: true,
    },
    itemLoader: {
      type: Object,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showCard: false,
    };
  },
  methods: {
    hideOnClickOutside(event) {
      const targetIsDialog = event.target.closest('.dialog');
      if (!targetIsDialog) {
        this.showCard = false;
      }
    },
    toggleShowCard() {
      if (this.isActive === true) return;
      this.showCard = !this.showCard;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>