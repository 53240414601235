export const RequiredArg = (name) => {
  const error = name
    ? `Missing required parameter '${name}'`
    : 'Missing required parameter';
  throw new Error(error);
};

export const NullOrUndefined = (value) => value === undefined || value === null;

export const NotNullOrUndefined = (value) => !NullOrUndefined(value);

export const CaseInsensitiveTextSearch = (needle, haystack) => haystack.toLowerCase()
  .indexOf(needle.toLowerCase()) >= 0;

export const stringifyStable = (object) => {
  if (typeof object === 'object') {
    const pairStrings = Object.keys(object).sort()
      .map((key) => `${key}: ${stringifyStable(object[key])}`);
    return `{${pairStrings.join(',')}}`;
  }
  return JSON.stringify(object);
};
