import { getSelectedDimensionFromHrScore } from './getSelectedDimensionFromHrScore';
import { setMinimalRadiusSize } from './setMinimalRadiusSize';

/**
 * Transform BubbleChart Data
 * @description Transforms data for use in a Bubble Chart
 * @function
 * @param {Array} data - The data to be transformed
 * @param {Object} selectedDimension - An object representing the selected dimension, containing a label property.
 * @returns {Array|null} The transformed data or null if data is undefined
 */

export const transformBubbleChartData = (data, selectedDimension) => {
  if (typeof data !== 'undefined') {
    const transformData = data.map((entry) => {
      const {
        iso_code: isoCode,
        hr_scores: hrScores,
        price,
        flag,
        trade_flow_percentage: tradeFlowPercentage,
        is_country_of_demand: isCountryOfDemand,
      } = entry;

      if (!hrScores) {
        return null
      }

      return {
        label: isoCode,
        x: parseFloat(getSelectedDimensionFromHrScore(hrScores, selectedDimension).value),
        y: price,
        flag,
        r: setMinimalRadiusSize(tradeFlowPercentage),
        isCountryOfDemand,
      };
    });
    return transformData;
  }
  return null;
};

export default transformBubbleChartData;
