<template>
  <div class="trade-flow--tab">
    <SpinningLoader v-if="asyncRender || dataProvider.isLoading" />
    <TradeFlowsWithoutData v-else-if="!dataProvider.hasData" />
    <div
      v-else
      class="trade-flow--chart-grid"
    >
      <div class="trade-flow--chart-grid">
        <TradeFlowChart
          :caption="importCaption"
          :data="dataProvider.data"
          flow="import"
          :indicators="['value']"
          :top="showTopN"
        />
        <TradeFlowChart
          :caption="exportCaption"
          :data="dataProvider.data"
          flow="export"
          :indicators="['value']"
          :top="showTopN"
        />
        <TradeFlowChart
          :caption="netImportCaption"
          :tooltip="$t('components.trade_flows.tooltip_world_net_import')"
          :data="dataProvider.data"
          flow="balance"
          :indicators="['value']"
          :top="-showTopN"
          :include-rest-of-world="false"
        />
        <TradeFlowChart
          :caption="netExportCaption"
          :tooltip="$t('components.trade_flows.tooltip_world_net_export')"
          :data="dataProvider.data"
          flow="balance"
          :indicators="['value']"
          :top="showTopN"
          :include-rest-of-world="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TradeFlowChart from './TradeFlowChart.vue';
import TradeFlowsWithoutData from './TradeFlowsWithoutData.vue';

export default {
  name: 'TradeFlowsForWorld',
  components: {
    TradeFlowChart,
    TradeFlowsWithoutData,
  },
  props: {
    dataProvider: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTopN: 20,
      asyncRender: true,
    };
  },
  computed: {
    apiParams() {
      return {
        partnerId: this.world.id,
        classificationId: this.classificationId,
        year: this.year,
      };
    },
    exportCaption() {
      return this.$t('components.trade_flows.caption_world_top_exporters', {
        n: this.showTopN,
      });
    },
    netExportCaption() {
      return this.$t('components.trade_flows.caption_world_top_net_exporters', {
        n: this.showTopN,
      });
    },
    importCaption() {
      return this.$t('components.trade_flows.caption_world_top_importers', {
        n: this.showTopN,
      });
    },
    netImportCaption() {
      return this.$t('components.trade_flows.caption_world_top_net_importers', {
        n: this.showTopN,
      });
    },
  },
  mounted() {
    // Rendering the bar charts is slow, so in order to prevent
    // the app from visibly hanging while the bar charts are getting
    // rendered we initially show the loading spinner for a brief moment,
    // which will remain displayed until the render cycle has completed.
    setTimeout(() => { this.asyncRender = false; }, 1);
  },
};
</script>
