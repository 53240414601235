<template>
  <li
    class="item"
    :class="{'is-inactive': !entry.active}"
  >
    <div class="item--icon">
      <ProductGroupTrendIcon
        v-if="entry.trend"
        variant="list"
        :trend="entry.trend"
      />
      <Icon
        v-else
        name="question"
        :fixed-width="true"
      />
    </div>
    <div class="item--infos">
      <div class="item--header">
        <component
          :is="isActive ? 'button' : 'div'"
          class="item--title"
          :class="{'can-click': isActive}"
          @click="onTitleClick"
        >
          {{ entry.product_group.name }}
        </component>
      </div>
      <div class="item--details">
        <span
          v-for="(detail, index) in details"
          :key="index"
          class="item--details-portion"
        >
          {{ detail }}
        </span>
      </div>
    </div>
    <ProductGroupReportsListItemActions
      :controller="controller"
      :entry="entry"
    />
  </li>
</template>

<script>
import moment from 'moment';
import ProductGroupTrendIcon from '@/components/ProductGroupTrendIcon/Component.vue';
import { openProductGroupReportFor } from '@/helpers/ProductGroupHelpers';
import ProductGroupReportsListItemActions from './Actions/Component.vue';

export default {
  name: 'ProductGroupReportsListItem',
  components: {
    ProductGroupTrendIcon,
    ProductGroupReportsListItemActions,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    details() {
      if (!this.entry.published_at) return [this.$t('apps.product_group_reports.list.no_report_present')];
      const details = [`${moment(this.entry.published_at).format('LL')} – ${moment(this.entry.valid_to).format('LL')}`];
      if (!this.entry.active) details.push(this.$t('apps.product_group_reports.list.report_expired'));
      return details;
    },
    productGroup() {
      return {
        ...this.entry.product_group,
        product_group_report: {
          ...this.entry,
        },
      };
    },
    isActive() {
      return this.entry.active;
    },
  },
  methods: {
    onTitleClick() {
      if (this.isActive) {
        openProductGroupReportFor(this.productGroup, []);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>