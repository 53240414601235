import axios from 'axios';

const NotationAnalysesApi = () => ({
  create({ analysisParams }) {
    return axios.post('/api/notation_analyses', analysisParams)
      .then((response) => response.data);
  },
  update({ id, analysisParams }) {
    return axios.put(`/api/notation_analyses/${id}`, analysisParams)
      .then((response) => response.data);
  },
  loadSavedAnalysis(id) {
    return axios.get(`/api/notation_analyses/${id}`)
      .then((response) => response.data);
  },
  loadSavedAnalyses() {
    return axios.get('/api/notation_analyses')
      .then((response) => response.data);
  },
  deleteAnalysis(id) {
    return axios.delete(`/api/notation_analyses/${id}`)
      .then((response) => response.data);
  },
  duplicateAnalysis({ id, title }) {
    return axios.post(`/api/notation_analyses/${id}/duplicate`, { title })
      .then((response) => response.data);
  },
});

export default NotationAnalysesApi;
