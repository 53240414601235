import { exportCurrentPage, exportFromPath } from '@/helpers/ExportHelpers';
import { reactive } from 'vue';

class PdfExportController {
  constructor() {
    this._state = reactive({
      inProgress: false,
    });
  }

  get inProgress() {
    return this._state.inProgress;
  }

  async exportAsPdf(path) {
    try {
      this._state.inProgress = true;
      if (path) {
        await exportFromPath(path);
      } else {
        await exportCurrentPage();
      }
    } finally {
      this._state.inProgress = false;
    }
  }
}

export default new PdfExportController();
