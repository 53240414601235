<template>
  <DashboardPageLayout :separator="true">
    <template #top>
      <DashboardHeader
        :dashboard="reactiveDashboard"
        :user="user"
      />
      <NewsFilter
        :controller="newsFilterController"
      />
    </template>
    <template #bottom>
      <NewsList
        :pagination="newsPagination"
        @mark-all-as-read="markAllAsRead()"
      />
    </template>
  </DashboardPageLayout>
</template>

<script>
import { toRef } from 'vue';
import { DashboardPageLayout } from '@/components/Layouts';
import { DashboardHeader } from '@/components';
import PaginationService from '@/services/PaginationService';
import UrlStateStore from '@/services/UrlStateStore';
import {
  NewsPaginationConfig,
  useNewsUnreadCount,
  MarkAllNewsAsRead,
} from '@/helpers/PublicationHelpers';
// TODO: move to components folder
import NewsFilter from '@/apps/News/components/Filter/Component.vue';
import NewsList from '@/apps/News/components/List/Component.vue';
import NewsFilterController from '@/apps/News/components/Filter/Controller';
import debounce from '@/helpers/debounce';

export default {
  name: 'DashboardNewsApp',
  components: {
    DashboardPageLayout,
    DashboardHeader,
    NewsFilter,
    NewsList,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reactiveDashboard: this.dashboard,
      newsFilterController: new NewsFilterController({
        selectedSortDirection: 'is_unread_desc',
        ...this.initialState?.filters,
        forDashboardId: this.dashboard.id,
      }),
      newsPagination: new PaginationService(NewsPaginationConfig),
      unreadCountObserver: null,
      fetchFirstPageDebounced: debounce(() => {
        this.fetchFirstPage();
      }, 500),
    };
  },
  beforeMount() {
    this.fetchFirstPage();
    this.newsFilterController.on('change', () => this.fetchFirstPageDebounced());
  },
  beforeUnmount() {
    this.unreadCountObserver?.off();
  },
  methods: {
    async fetchFirstPage() {
      UrlStateStore.set('filters', this.newsFilterController.filtersForUrlState);
      await this.newsPagination.fetchFirstPage(this.newsFilterController.filtersForRequest);
      this.unreadCountObserver?.off();
      this.unreadCountObserver = useNewsUnreadCount(toRef(this.newsPagination.meta, 'unread_count'));
    },
    async markAllAsRead() {
      await MarkAllNewsAsRead(this.newsFilterController.filtersForRequest);
      this.newsPagination.entries.forEach((entry) => { entry.unread = false; });
    },
  },
};
</script>
