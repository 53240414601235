import axios from 'axios';
import { exportFromResponse } from '@/helpers/ExportHelpers';

const getExcelFromAPI = () => axios.get('/human-rights/country-risks.xlsx', {
  responseType: 'blob',
}).then((response) => exportFromResponse(response));

class CountryAppApi {
  async getExcelFile() {
    return getExcelFromAPI();
  }
}

export default CountryAppApi;
