<script>

export default {
  data() {
    return {
      exports: []
    }
  },

  provide() {
    return {
      exports: this.exports
    }
  }
}
</script>

<template>
  <div class="export-dropdown-wrapper print-hide">
    <Teaser class="export-dropdown" scope="export" user-attribute="can_export_human_rights_data">
      <ComboBox
        :options="exports"
        display-text-override="Export"
      >
        <template #option-button="{ option }">
          <a class="export-link" :href="option.path" target="_blank">{{ option.title }}</a>
        </template>

      </ComboBox>
    </Teaser>
  </div>
  <slot></slot>
</template>

<style lang="scss">
.export-dropdown-wrapper {
  display: inline-block;
  margin-right: 8px;
}

.export-link {
  display: block;
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
  padding: 10px;
  white-space: nowrap;
  color: #333840;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background-color: rgb(245, 247, 250);
    cursor: pointer;
  }
}

.export-dropdown {
  .combobox--button {
    width: auto;
  }

  .combobox--default-button {
    @include font-size('14/14');
    min-width: 225px;
    border-color: $color-primary;
    padding: ($button-padding-vertical - 1px) $button-padding-horizontal;
    text-transform: uppercase;
    color: $color-primary;
    background-color: transparent;
  }

  .combobox--option {
    @include font-size('14/14');

    &.has-focus .combobox--default-option {
      color: inherit;
      background-color: $color-background-near-light;
    }
  }

  .combobox--caret {
    color: $color-primary;
  }
}
</style>
