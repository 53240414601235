<template>
  <g class="chart--line">
    <path
      v-if="fill"
      class="chart--line-fill"
      :d="fillPath"
    />
    <path
      class="chart--line-stroke"
      :d="strokePath"
    />
  </g>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'PlotLine',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    xScale: {
      type: Function,
      required: true,
    },
    yScale: {
      type: Function,
      required: true,
    },
    interpolation: {
      type: Function,
      default: d3.curveLinear,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    strokePath() {
      const strokeFunction = d3.line()
        .curve(this.interpolation)
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y));
      return strokeFunction(this.data);
    },
    fillPath() {
      if (!this.fill) return null;
      const fillFunction = d3.area()
        .curve(this.interpolation)
        .x((d) => this.xScale(d.x))
        .y0(() => this.yScale(0))
        .y1((d) => this.yScale(d.y));
      return fillFunction(this.data);
    },
  },
};
</script>
