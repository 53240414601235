<template>
  <component
    v-if="component"
    :is="component"
    v-bind="propsToPass"
    :data-column-name="columnKey"
  />
</template>

<script>
import EmptyColumnHead from './EmptyColumnHead.vue';
import StaticColumnHead from './StaticColumnHead.vue';
import SortColumnHead from './SortColumnHead.vue';
import SearchFilterColumnHead from './SearchFilterColumnHead.vue';
import TextFilterColumnHead from './TextFilterColumnHead.vue';
import SortAndFilterColumnHead from './SortAndFilterColumnHead.vue';

const FilterOptions = {
  empty: 'EmptyColumnHead',
  none: 'StaticColumnHead',
  sort: 'SortColumnHead',
  enumFilter: 'SearchFilterColumnHead',
  searchFilter: 'SearchFilterColumnHead',
  textFilter: 'TextFilterColumnHead',
  sortAndFilter: 'SortAndFilterColumnHead',
};

const Filterables = ['enumFilter', 'searchFilter', 'textFilter', 'sortAndFilter'];

const validateColumnHeadType = (type) => Object.keys(FilterOptions).indexOf(type) !== -1;

export default {
  name: 'GenericColumnHead',
  components: {
    EmptyColumnHead,
    StaticColumnHead,
    SortColumnHead,
    SearchFilterColumnHead,
    TextFilterColumnHead,
    SortAndFilterColumnHead,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: String,
      default: 'none',
      validator: validateColumnHeadType,
    },
    filterPopupIsWide: {
      type: Boolean,
      default: false,
    },
    controller: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    propsToPass() {
      return {
        ...this.$attrs,
        ...this.$props,
      };
    },
    component() {
      const filters = this.controller?.filters;
      const isFilterable = Filterables.includes(this.filterOptions);
      const hasFilterValues = filters && filters[this.columnKey];

      if (isFilterable && !hasFilterValues) {
        return FilterOptions.none;
      }
      return FilterOptions[this.filterOptions];
    },
  },
};
</script>
