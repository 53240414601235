import axios from 'axios';

const apiUrl = '/api/human_rights/country_portraits/';

const CountryPortraitsApi = () => ({
  getCountryData(country = 240) {
    return axios.get(`${apiUrl}${country}`)
      .then((response) => response.data);
  },
});

export default CountryPortraitsApi;
