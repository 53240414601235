<template>
  <div class="trade-flow--tab">
    <TradeFlowsForCountryHeader
      v-if="!controller.isLoadingHeaderData"
      :controller="controller"
      :world-data="worldData"
      @change-reporter="onReporterSelected"
    />
    <SpinningLoader v-if="controller.isLoading" />
    <TradeFlowsWithoutData v-else-if="!controller.countryDataProvider.hasData" />
    <template v-else>
      <TradeFlowChart
        :caption="exportCaption"
        :data="controller.countryDataProvider.data"
        :indicators="['value', 'volume', 'price']"
        flow="export"
        :top="showTopN"
        :include-countries="controller.additionalCountryNames"
        :sort="sortCharts.export"
        @sort="onChartSorted('export', $event)"
      />
      <TradeFlowChart
        :caption="importCaption"
        :data="controller.countryDataProvider.data"
        :indicators="['value', 'volume', 'price']"
        flow="import"
        :top="showTopN"
        :include-countries="controller.additionalCountryNames"
        :sort="sortCharts.import"
        @sort="onChartSorted('import', $event)"
      />
      <div class="trade-flow--chart-grid">
        <TradeFlowChart
          class="net-export-value"
          :caption="netExportCaption"
          :tooltip="netExportTooltip"
          :data="controller.countryDataProvider.data"
          flow="balance"
          :indicators="['value']"
          :top="showTopN"
          :include-countries="controller.additionalCountryNames"
          :include-rest-of-world="false"
        />
        <TradeFlowChart
          class="net-import-value"
          :caption="netImportCaption"
          :tooltip="netImportTooltip"
          :data="controller.countryDataProvider.data"
          flow="balance"
          :indicators="['value']"
          :top="-showTopN"
          :include-countries="controller.additionalCountryNames"
          :include-rest-of-world="false"
        />
      </div>
    </template>
  </div>
</template>

<script>
import UrlStateStore from '@/services/UrlStateStore';
import TradeFlowChart from '../TradeFlowChart.vue';
import TradeFlowsWithoutData from '../TradeFlowsWithoutData.vue';
import TradeFlowsForCountryHeader from '../TradeFlowsForCountryHeader.vue';
import DashboardTradeFlowsForCountryController from './Controller';

export default {
  name: 'DashboardTradeFlowsForCountry',
  components: {
    TradeFlowChart,
    TradeFlowsWithoutData,
    TradeFlowsForCountryHeader,
  },
  props: {
    dashboardId: {
      type: Number,
      required: true,
    },
    classification: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    worldData: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['change-reporter'],
  data() {
    return {
      controller: new DashboardTradeFlowsForCountryController(this.dashboardId,
        this.classification,
        this.source,
        this.year,
        this.countries,
        this.worldData),
      showTopN: 20,
      sortCharts: {
        import: { indicator: 'value', order: 'desc' },
        export: { indicator: 'value', order: 'desc' },
      },
    };
  },
  computed: {
    exportCaption() {
      return this.$t('components.trade_flows.caption_country_top_exporters', {
        country: this.controller.currentReporter?.name,
        n: this.showTopN,
      });
    },
    netExportCaption() {
      return this.$t('components.trade_flows.caption_country_top_net_exporters', {
        country: this.controller.currentReporter?.name,
        n: this.showTopN,
      });
    },
    netExportTooltip() {
      return this.$t('components.trade_flows.tooltip_country_net_export', {
        country: this.controller.currentReporter?.name,
      });
    },
    importCaption() {
      return this.$t('components.trade_flows.caption_country_top_importers', {
        country: this.controller.currentReporter?.name,
        n: this.showTopN,
      });
    },
    netImportCaption() {
      return this.$t('components.trade_flows.caption_country_top_net_importers', {
        country: this.controller.currentReporter?.name,
        n: this.showTopN,
      });
    },
    netImportTooltip() {
      return this.$t('components.trade_flows.tooltip_country_net_import', {
        country: this.controller.currentReporter?.name,
      });
    },
  },
  watch: {
    classification() {
      this.controller.classification = this.classification;
    },
    year() {
      this.controller.year = this.year;
    },
    source() {
      this.controller.source = this.source;
    },
  },
  mounted() {
    this.sortCharts = UrlStateStore.get('sortCharts', this.sortCharts);
  },
  methods: {
    onChartSorted(chart, sort) {
      this.sortCharts[chart] = sort;
      UrlStateStore.set('sortCharts', this.sortCharts);
    },
    onReporterSelected(reporter) {
      this.$emit('change-reporter', reporter);
    },
  },
};
</script>
