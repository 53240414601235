<template>
  <div
    class="dialog--window"
    open
  >
    <div class="search-box">
      <AutoComplete
        v-if="autoCompleteController.isPopupVisible"
        :controller="autoCompleteController"
        @submit="onSubmit"
      />
      <SearchRegistry
        v-else
        :controller="searchRegistryController"
        @submit="onSubmit"
      />
      <input
        ref="input"
        v-model="autoCompleteController.currentQuery"
        v-focus
        :aria-label="$t('components.search_box.label')"
        class="search-box--input"
        type="text"
        :placeholder="$t('components.search_box.placeholder')"
        @focus="selectInputText"
        @keyup.enter="onSubmit"
        @keydown.up="scrollCurrentIndex(-1)"
        @keydown.down="scrollCurrentIndex(+1)"
      >
      <Icon
        class="search-box--icon"
        name="search"
        variant="light"
      />
    </div>
  </div>
</template>

<script>
import ModalWindowController from '@/controllers/ModalWindowController';
import { AutoComplete, SearchRegistry } from './components';
import { SearchBoxController, SearchRegistryController } from './controllers';

export default {
  name: 'TopTermSearchModal',
  components: {
    AutoComplete,
    SearchRegistry,
  },
  props: {
    currentQuery: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      autoCompleteController: SearchBoxController({ minimumQueryLength: 1 }),
      searchRegistryController: SearchRegistryController(),
    };
  },
  computed: {
    activeController() {
      return this.autoCompleteController.isPopupVisible
        ? this.autoCompleteController
        : this.searchRegistryController;
    },
  },
  beforeMount() {
    if (this.currentQuery) {
      this.autoCompleteController.currentQuery = this.currentQuery.name;
    }
  },
  methods: {
    selectInputText(event) {
      event.target.select();
    },
    scrollCurrentIndex(delta) {
      this.autoCompleteController.scrollCurrentIndex(delta);
      this.searchRegistryController.scrollCurrentIndex(delta);
    },
    onSubmit() {
      if (this.activeController.submit()) {
        ModalWindowController.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>