<template>
  <FontAwesomeIcon
    :icon="iconDefinition"
    :fixed-width="fixedWidth"
    :spin="spin"
    :size="size"
  />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const IconVariants = {
  regular: 'far',
  solid: 'fas',
  light: 'fal',
};
const isValidVariant = (variant) => IconVariants[variant] !== undefined;

const IconSizes = ['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x'];
const isValidSize = (size) => size === null || IconSizes.includes(size);

// We wrap another element with :key around the icon tag,
// in order to force vue to re-render the icon on change.
// This is required because font-awesome replaces the inner
// tag with svg elements, so vue won't know that the dom
// element requires an update.
export default {
  name: 'Icon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      validator: isValidVariant,
      default: 'light',
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: isValidSize,
    },
  },
  computed: {
    iconDefinition() {
      return [
        IconVariants[this.variant],
        this.name,
      ];
    },
  },
};
</script>
