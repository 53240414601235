<template>
  <div class="dashboard-dropdown">
    <SpinningLoader
      v-if="isLoading"
      class="dashboard-dropdown--loader"
    />
    <DashboardAndClusterListNotice
      v-else-if="isEmpty"
      :text="placeholderText"
      icon="info-circle"
    />
    <DashboardAndClusterListNotice
      v-else-if="hasNoSearchMatches"
      :text="$t('apps.dashboard_search.no_results_notice')"
      icon="search"
    />
    <div
      v-else
      class="dashboard-dropdown--columns"
    >
      <div class="dashboard-dropdown--scrollarea">
        <DashboardAndClusterListSection
          v-if="filteredDashboards.length"
          :title="$t('apps.dashboard_search.private_section_title')"
        >
          <DashboardAndClusterListLeafList
            :dashboards="filteredDashboards"
            :use-link-tags="useLinkTags"
            :selection="selection"
            :requires-write-permissions="requiresWritePermissions"
            @focus-item="blurClusterImmediate"
            @click-dashboard="onClickDashboard"
          />
        </DashboardAndClusterListSection>
        <DashboardAndClusterListSection
          v-if="favoriteClusters.length"
          :title="$t('apps.dashboard_search.favorites_section_title')"
        >
          <DashboardAndClusterListNodeList
            :items="favoriteClusters"
            :focussed-id="focussedClusterId"
            :selection="selection"
            :requires-write-permissions="requiresWritePermissions"
            @focus-item="focusCluster"
            @blur-item="blurCluster"
          />
        </DashboardAndClusterListSection>
        <DashboardAndClusterListSection
          v-if="regularClusters.length"
          :title="$t('apps.dashboard_search.published_section_title')"
        >
          <DashboardAndClusterListNodeList
            :items="regularClusters"
            :focussed-id="focussedClusterId"
            :selection="selection"
            :requires-write-permissions="requiresWritePermissions"
            @focus-item="focusCluster"
            @blur-item="blurCluster"
          />
        </DashboardAndClusterListSection>
      </div>
      <div
        v-if="showClusters"
        class="dashboard-dropdown--market-scrollarea"
        @mouseenter="refocusCluster"
        @mouseleave="blurCluster"
      >
        <DashboardAndClusterListLoadingLeafList
          v-if="focussedCluster"
          :cluster="focussedCluster"
          :show-favorites="showFavorites"
          :use-link-tags="useLinkTags"
          :selection="selection"
          :requires-write-permissions="requiresWritePermissions"
          @click-dashboard="onClickDashboard"
        />
        <DashboardAndClusterListNotice
          v-else
          :text="$t('apps.dashboard_search.market_placeholder')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import i18n from '@/config/i18n';
import { CaseInsensitiveTextSearch } from '@/helpers';
import DashboardCluster from './DashboardCluster';
import DashboardAndClusterListLeafList from './LeafList/Component.vue';
import DashboardAndClusterListLoadingLeafList from './LoadingLeafList/Component.vue';
import DashboardAndClusterListNodeList from './NodeList/Component.vue';
import DashboardAndClusterListSection from './Section/Component.vue';
import DashboardAndClusterListNotice from './Notice/Component.vue';

const prependFavorites = (clusters) => [
  ...clusters.filter((c) => c.has_favorites),
  ...clusters.filter((c) => !c.has_favorites),
];

export default {
  name: 'DashboardAndClusterList',
  components: {
    DashboardAndClusterListLeafList,
    DashboardAndClusterListLoadingLeafList,
    DashboardAndClusterListNodeList,
    DashboardAndClusterListSection,
    DashboardAndClusterListNotice,
  },
  props: {
    showClusters: {
      type: Boolean,
      default: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    showFavorites: {
      type: Boolean,
      default: true,
    },
    useLinkTags: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Object,
      default: () => null,
    },
    requiresWritePermissions: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: i18n.t('apps.dashboard_search.no_dashboards_notice'),
    },
  },
  emits: [
    'select',
  ],
  data() {
    return {
      isLoadingDashboards: true,
      dashboards: [],
      isLoadingClusters: true,
      clusters: [],
      blurClusterTimeout: null,
      focussedClusterId: null,
    };
  },
  computed: {
    isLoading() {
      return this.isLoadingDashboards || this.isLoadingClusters;
    },
    isEmpty() {
      return this.dashboards.length + this.clusters.length === 0;
    },
    hasNoSearchMatches() {
      return this.filteredDashboards.length
        + this.favoriteClusters.length
        + this.regularClusters.length === 0;
    },
    multiLangFilter() {
      return (item) => CaseInsensitiveTextSearch(this.searchText, item.name_en)
        || CaseInsensitiveTextSearch(this.searchText, item.name_de);
    },
    filteredDashboards() {
      return this.dashboards.filter(this.multiLangFilter);
    },
    filteredClusters() {
      return this.clusters.filter(this.multiLangFilter);
    },
    favoriteClusters() {
      return this.filteredClusters.filter((c) => c.has_favorites);
    },
    regularClusters() {
      return this.filteredClusters.filter((c) => !c.has_favorites);
    },
    focussedCluster() {
      if (this.focussedClusterId === null) {
        return this.filteredClusters.find((c) => c.id === this.selection?.cluster?.id);
      }
      return this.filteredClusters.find((c) => c.id === this.focussedClusterId);
    },
  },
  beforeMount() {
    this.fetchClusters();
    this.fetchDashboards();
  },
  methods: {
    focusCluster({ item }) {
      this.setClusterIdDebounced(item.id, 50);
    },
    refocusCluster() {
      clearTimeout(this.blurClusterTimeout);
    },
    blurCluster() {
      this.setClusterIdDebounced(null, 500);
    },
    blurClusterImmediate() {
      clearTimeout(this.blurClusterTimeout);
      this.focussedClusterId = null;
    },
    setClusterIdDebounced(id, debounce) {
      clearTimeout(this.blurClusterTimeout);
      this.blurClusterTimeout = setTimeout(() => {
        this.focussedClusterId = id;
      }, debounce);
    },
    fetchDashboards() {
      axios.get('/api/dashboards', { params: { is_published: false } })
        .then((response) => response.data)
        .then((body) => {
          this.dashboards = body
            .map((dashboard) => ({ ...dashboard, href: `/dashboards/${dashboard.id}` }));
        })
        .finally(() => { this.isLoadingDashboards = false; });
    },
    fetchClusters() {
      if (!this.showClusters) {
        this.clusters = [];
        this.isLoadingClusters = false;
        return;
      }

      axios.get('/api/dashboards/clusters')
        .then((response) => response.data)
        .then((body) => {
          this.clusters = prependFavorites(body.entries).map((c) => new DashboardCluster(c));
        })
        .finally(() => { this.isLoadingClusters = false; });
    },
    onClickDashboard(event) {
      this.$emit('select', event);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>