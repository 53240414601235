<template>
  <form
    action="/api/user/change_role"
    method="POST"
  >
    <button
      type="submit"
      class="app-header--user-link"
      name="preferred_role"
      :value="nextRole"
    >
      <Icon
        name="exchange"
        class="icon"
        :fixed-width="true"
        variant="regular"
      />
      {{ buttonLabel }}
    </button>
    <input
      type="hidden"
      :value="csrfToken"
      name="authenticity_token"
    >
  </form>
</template>

<script>
import { GetCSRFToken } from '@/helpers/FormHelpers';

export default {
  name: 'NavigationUserMenuChangeRoleButton',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    csrfToken() {
      return GetCSRFToken();
    },
    nextRole() {
      return this.user.editor_permissions ? 'user' : 'editor';
    },
    buttonLabel() {
      return this.user.editor_permissions
        ? this.$t('layouts.change_role_button.switch_to_user_mode')
        : this.$t('layouts.change_role_button.switch_to_editor_mode');
    },
  },
};
</script>
