<template>
  <SplitPageLayout screen-size="narrow">
    <template #top>
      <div
        v-exclude-from-print
        class="page-header"
      >
        <h1 class="page-header--title">
          {{ $t('page_titles.publications') }}
        </h1>

        <PublicationsHeaderActions :controller="controller" />
      </div>
      <PublicationsFilters :controller="controller" />
    </template>
    <template #bottom>
      <PublicationsList :controller="controller" />
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import PublicationsController from './Controller';
import PublicationsHeaderActions from './components/HeaderActions/Component.vue';
import PublicationsFilters from './components/Filters/Component.vue';
import PublicationsList from './components/List/Component.vue';

export default {
  name: 'PublicationsApp',
  components: {
    SplitPageLayout,
    PublicationsHeaderActions,
    PublicationsFilters,
    PublicationsList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      controller: new PublicationsController(this.user, {
        initialFilters: this.initialState?.filters,
      }),
    };
  },
  beforeUnmount() {
    this.controller.beforeUnmount();
  },
};
</script>
