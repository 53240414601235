<template>
  <h2 class="legend-item--title">
    <span class="legend-item--icon">
      <ChartLegendIcon
        :color="color"
        :is-selected="isSelected"
        @update:is-selected="(selectedYear) => $emit('update:is-selected', selectedYear)"
      />
    </span>
    <span class="legend-item--name">
      {{ title }}
    </span>
  </h2>
</template>

<script>
import { ChartLegendIcon } from '@/components';

export default {
  name: 'ChartLegendItem',
  components: {
    ChartLegendIcon,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:is-selected'],
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>