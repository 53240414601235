<template>
  <th
    ref="root"
    class="data-table--head-cell"
    :class="{'is-open': filterOpen}"
    scope="col"
    :colspan="colspan"
  >
    <component
        v-if="componentType"
        :is="componentType"
        :tooltip="tooltip"
        class="is-block center-rotated-wrapper"
      >

      <div v-if="$media == 'print'" class="rotation-wrapper-outer">
        <div class="rotation-wrapper-inner">
          <span class="data-table--head-cell-content-print" v-html="title"/>

        </div>
      </div>
      <component
        :is="linkTag"
        v-else
        class="data-table--head-cell-content"
        :class="{'is-clickable': isFilterable || sortDirection}"
        @click="onHeaderClick()"
      >
        <span class="data-table--head-cell-title" v-html="title"></span>

        <span>
          <span
            v-if="isFilterable"
            class="data-table--head-cell-filter"
            :class="{'is-active': filterActive}"
          >
            <Icon
              name="filter"
              variant="regular"
            />
          </span>
          <span
            v-if="sortDirection && (sortOnClick || !sortIcon.onlyOnHover)"
            class="data-table--head-cell-sort-indicator"
            :class="{'only-on-hover': sortIcon.onlyOnHover}"
          >
            <Icon
              class="data-table--head-cell-sort-icon"
              :name="sortIcon.name"
              :variant="sortIcon.variant"
            />
          </span>
        </span>
      </component>
    </component>
    <Modal
      v-if="isFilterable"
      :active="filterOpen"
      @close="close"
    >
      <slot name="filter" />
    </Modal>
  </th>
</template>

<script>
import TableHeadPopup from './TableHeadPopup.vue';

const iconForSortDirection = {
  unsorted: 'sort',
  asc: 'sort-up',
  desc: 'sort-down',
};

export default {
  name: 'TableHeadCell',
  components: {
    TableHeadPopup,
  },
  props: {
    colspan: {
      type: Number,
      default: null
    },
    linkTag: {
      type: String,
      default: "button"
    },
    title: {
      type: String,
      default: null,
    },
    filterActive: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: null,
    },
    sortOnClick: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: () => null,
    },
  },
  emits: ['click'],
  data() {
    return {
      filterOpen: false,
    };
  },
  computed: {
    isFilterable() {
      return !!this.$slots.filter;
    },
    sortIcon() {
      return {
        name: iconForSortDirection[this.sortDirection],
        variant: 'regular',
        onlyOnHover: this.sortDirection === 'unsorted',
      };
    },
    componentType() {
      if (this.tooltip) {
        return 'WithTooltip'
      }
      return 'div'
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClickListener);
  },
  unmounted() {
    document.removeEventListener('click', this.documentClickListener);
  },
  methods: {
    close() {
      this.filterOpen = false;
    },
    onHeaderClick() {
      if (this.isFilterable) {
        this.filterOpen = !this.filterOpen;
      } else {
        this.$emit('click');
      }
    },
    documentClickListener(event) {
      // TODO This currently uses data-closes-popup on nested buttons to also close the popup
      // It feels like there should be a more vue-like way to handle this.
      const isClickOutside = !this.$refs.root.contains(event.target);
      const isButtonClick = event.target.dataset.closesPopup;
      if (isClickOutside || isButtonClick) {
        this.filterOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">

.data-table--head-cell-content-print {
  max-width: 100%;
  overflow: hidden;

  padding: 10px 0;
  text-align: center;
  display: inline-block;
}

</style>
