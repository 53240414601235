<template>
  <SpinningLoader
    v-if="isLoading"
    class="chart--loading-spinner"
  />
  <div
    v-else-if="hasError"
    class="chart--not-found"
  >
    <h1 class="chart--not-found-title">
      {{ $t('apps.multi_line_chart_view.error_title') }}
    </h1>
  </div>

  <SplitPageLayout
    v-else
    screen-size="medium"
    :with-separator="$media !== 'print'"
  >
    <template #top>
      <PrintLayout :caption="title" />
      <ChartViewHeader
        v-exclude-from-print
        :title="title"
        :config="config"
        :saved-analysis-id="savedAnalysisId"
        :save-config-disabled="saveConfigDisabled"
        :is-saving="isSaving"
        :config-url="configUrl"
        @save="saveConfiguration"
      />
    </template>
    <template #bottom>
      <ChartView
        :datasets="datasetsForChart"
        :unit="unit"
      />
      <div class="card chart--legend">
        <ChartLegend
          :notation="notation"
          :quotes-per-year="quotesPerYear"
          :unit="unit"
          :unit-converted-from="unitConvertedFrom"
          @set-year-selected="setYearSelected"
        />
      </div>
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import { computed } from 'vue';
import UrlStateStore from '@/services/UrlStateStore';
import {
  ChartViewHeader,
  PrintLayout,
} from '@/components';
import Turbolinks from 'turbolinks';
import { useChartViewStore } from './store';
import ChartView from './ChartView/Component.vue';
import ChartLegend from './ChartLegend/Component.vue';

export default {
  name: 'SeasonLineChartViewApp',
  components: {
    SplitPageLayout,
    ChartViewHeader,
    PrintLayout,
    ChartView,
    ChartLegend,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useChartViewStore();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    Turbolinks.controller
      .replaceHistoryWithLocationAndRestorationIdentifier('?', Turbolinks.uuid());
    let savedAnalysisId = null;
    if (urlParams.get('saved_analysis_id') !== null) {
      savedAnalysisId = parseInt(urlParams.get('saved_analysis_id'), 10);
    } else {
      savedAnalysisId = UrlStateStore.get('savedAnalysisId', null);
    }
    UrlStateStore.set('savedAnalysisId', savedAnalysisId);
    const initialConfig = UrlStateStore.get('config', null);
    store.setConfiguration(initialConfig, savedAnalysisId);

    const saveConfiguration = (title) => {
      store.saveConfiguration(title);
    };

    const setYearSelected = (selectedYear) => {
      store.setYearSelected(selectedYear);
    };

    return {
      isLoading: computed(() => store.isLoading),
      hasError: computed(() => store.hasError),
      config: computed(() => store.config),
      savedAnalysisId: computed(() => store.savedAnalysisId),
      saveConfigDisabled: computed(() => store.saveConfigDisabled),
      isSaving: computed(() => store.isSaving),
      configUrl: computed(() => store.configUrl),
      title: computed(() => store.title),
      unit: computed(() => store.unit),
      unitConvertedFrom: computed(() => store.unitConvertedFrom),
      notation: computed(() => store.notation),
      quotesPerYear: computed(() => store.quotesPerYearForLegend),
      datasetsForChart: computed(() => store.datasetsForChart),
      saveConfiguration,
      setYearSelected,
    };
  },
  watch: {
    savedAnalysisId: {
      deep: true,
      handler() {
        UrlStateStore.set('savedAnalysisId', this.savedAnalysisId);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>