<template>
  <Teaser scope="export">
    <ButtonComponent
      variant="primary"
      icon="file-export"
      :is-disabled="isDisabled || isLoadingPdf"
      @click="onClicked"
    >
      <template v-if="isLoadingPdf">
        {{ $t('components.generic.pdf_export_button.in_progress_label') }}
      </template>
      <template v-else>
        {{ label }}
      </template>
    </ButtonComponent>
  </Teaser>
</template>

<script>
import Controller from './Controller';

export default {
  name: 'PdfExportButtonComponent',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  emits: ['click', 'finish'],
  data() {
    return {
      label: this.text || this.$t('components.generic.pdf_export_button.label'),
    };
  },
  computed: {
    isLoadingPdf() {
      return Controller.inProgress;
    },
  },
  methods: {
    async onClicked(event) {
      this.$emit('click', event);
      await Controller.exportAsPdf(this.path);
      this.$emit('finish');
    },
  },
};
</script>
