const isNotFirstOfMonth = (entry) => entry.x.getDate() !== 1;

const shiftTimeseriesMonthly = (X, months) => {
  if (!Array.isArray(X)) {
    throw new Error('X must be an array.');
  }
  if (X.some(isNotFirstOfMonth)) {
    throw new Error('All dates must fall on the first day of the month.');
  }
  if (!Number.isInteger(months)) {
    throw new Error('Second argument \'months\' must be an integer.');
  }
  return X.map((elem) => {
    const newDate = new Date(+elem.x);
    newDate.setMonth(newDate.getMonth() + months);
    return { ...elem, x: newDate };
  });
};

export default shiftTimeseriesMonthly;
