import { reactive, toRef } from 'vue';
import axios from 'axios';
import i18n from '@/config/i18n';
import ApiHelpers from '@/api/ApiHelpers';
import ModalWindowController from '@/controllers/ModalWindowController';
import { ProductGroupsApi } from '@/api/ProductGroupsApi';
import RegionsApi from '@/api/RegionsApi';
import UrlStateStore from '@/services/UrlStateStore';
import {
  DefaultPublicationsState,
  PathToPublicationsWithFilters,
  ViewNews,
  DownloadPublication,
  MarkAllPublicationsAsRead,
  usePublicationUnreadCount,
} from '@/helpers/PublicationHelpers';
import PaginationService from '@/services/PaginationService';
import SortOption from '@/models/SortOption';
import { removeUnavailableSelections } from '@/helpers/FilterHelpers';

const translateOptions = (fieldName, optionKeys) => {
  const result = optionKeys.map((key) => ({
    key,
    value: i18n.t(`apps.publications.filters.${fieldName}_options.${key}`),
  }));

  return result;
};

const wrapPublication = (publicationFromApi) => ({
  ...publicationFromApi,
  isBusy: false,
});

const createPublicationsPagination = () => new PaginationService({
  url: '/api/publications',
  method: 'GET',
  transformEntry: wrapPublication,
  errorHandler: ApiHelpers.handleError,
});

const buildSortOptions = () => SortOption
  .buildList('apps.publications.filters.sort_options', [
    ['display_name', 'asc'],
    ['display_name', 'desc'],
    ['published_at', 'desc'],
    ['published_at', 'asc'],
    ['is_unread', 'desc'],
  ]);

class PublicationsController {
  constructor(user, {
    forDashboardId,
    enableEditing = true,
    initialFilters = {},
  } = {}) {
    this._user = user;
    this._productGroupsApi = ProductGroupsApi();
    this._unreadCountObserver = null;
    this._state = reactive({
      pagination: createPublicationsPagination(),
      isLoadingFilters: true,
      filterOptions: {
        productGroups: [],
        regions: [],
        kind: translateOptions('kind', ['any', 'hgs_reports_tools', 'other']),
        sort: buildSortOptions(),
      },
      filters: {
        ...DefaultPublicationsState.filters,
        ...initialFilters,
      },
    });
    this.forDashboardId = forDashboardId;
    this.enableEditing = enableEditing;

    UrlStateStore.replaceState({
      filters: this.filters,
    });

    this.loadFilterOptions();
    this.loadPublications();
  }

  get isLoading() {
    return this._state.isLoadingFilters || this._state.pagination.isLoading;
  }

  get filterOptions() {
    return this._state.filterOptions;
  }

  get filters() {
    return this._state.filters;
  }

  get publications() {
    return this._state.pagination.entries;
  }

  get unreadCount() {
    return this._state.pagination.meta?.unread_count;
  }

  get canEdit() {
    return this.enableEditing && this._user.editor_permissions;
  }

  get hasMoreResults() {
    return this._state.pagination.hasNextPage;
  }

  filtersChanged() {
    UrlStateStore.set('filters', this.filters);
    this.loadPublications();
  }

  async loadFilterOptions() {
    this._state.isLoadingFilters = true;

    if (this._state.filterOptions.regions.length === 0) {
      await this.loadRegionFilterOptions();
    }
    await this.loadProductGroupFilterOptions();

    this._state.isLoadingFilters = false;
  }

  async loadRegionFilterOptions() {
    const regions = await RegionsApi().regions();
    this._state.filterOptions.regions = regions;
  }

  async loadProductGroupFilterOptions() {
    const params = {
      for_dashboard: this.forDashboardId,
    };
    const filterOptions = await axios.post('/api/publications/filters', params)
      .then((response) => response.data);
    this._state.filterOptions.productGroups = filterOptions.product_groups;
    this._state.filters.productGroups = removeUnavailableSelections({
      selectedKeys: this._state.filters.productGroups,
      availableOptions: this._state.filterOptions.productGroups,
      accessKey: (option) => option.id,
    });
  }

  async loadPublications() {
    await this._state.pagination.fetchFirstPage(this._filtersForRequest);

    this._unreadCountObserver?.off();
    this._unreadCountObserver = usePublicationUnreadCount(toRef(this._state.pagination.meta, 'unread_count'));
  }

  async loadNextPage() {
    this._state.pagination.fetchNextPage();
  }

  async markAllAsRead() {
    await MarkAllPublicationsAsRead(this._filtersForRequest);
    this._state.pagination.entries.forEach((entry) => { entry.unread = false; });
  }

  // eslint-disable-next-line class-methods-use-this
  async download(publication) {
    if (publication.isBusy) return;
    publication.isBusy = true;

    try {
      await DownloadPublication(publication);
    } finally {
      publication.isBusy = false;
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  view(publication) {
    ViewNews(publication);
  }

  edit(publication) {
    if (publication.isBusy) return;
    ModalWindowController.show('PublicationsEditModal', {
      props: {
        title: i18n.t('apps.publications.edit_modal.title'),
        publication,
      },
      events: {
        save: (updatedPublication) => { this.updatePublication(updatedPublication); },
        close: () => { this.loadFilterOptions(); },
      },
    });
  }

  updatePublication(newPublicationFromApi) {
    const newPublication = wrapPublication(newPublicationFromApi);
    const index = this._state.pagination.entries.findIndex((old) => old.id === newPublication.id);
    if (index >= 0) {
      this._state.pagination.entries[index] = newPublication;
    }
  }

  deleteAfterConfirmation(publication) {
    ModalWindowController.show('ConfirmationModal', {
      props: {
        text: i18n.t('apps.publications.list.delete_confirm_text', {
          name: publication.display_name || publication.file_name,
        }),
      },
      events: {
        confirm: () => {
          this._delete(publication);
          this.loadFilterOptions();
        },
      },
    });
  }

  beforeUnmount() {
    this._unreadCountObserver?.off();
  }

  async _delete(publication) {
    if (publication.isBusy) return;
    try {
      const url = `/api/publications/${publication.id}`;
      publication.isBusy = true;
      await axios.delete(url);
      this._removeFromList(publication);
    } catch (error) {
      ApiHelpers.handleError(error);
    } finally {
      publication.isBusy = false;
      this.loadFilterOptions();
      this.loadPublications();
    }
  }

  get _filtersForRequest() {
    return {
      product_group_ids: this.filters.productGroups,
      for_dashboard: this.forDashboardId,
      kind: this.filters.kind,
      region_ids: this.filters.regions,
      sort: this.filterOptions.sort.find((o) => o.key === this.filters.sort)?.forApi,
    };
  }

  _removeFromList(publication) {
    const index = this._state.pagination.entries.findIndex((entry) => entry.id === publication.id);
    if (index >= 0) {
      this._state.pagination.entries.splice(index, 1);
    }
  }

  static pathWithFilters(filters) {
    return PathToPublicationsWithFilters(filters);
  }
}

export default PublicationsController;
