<template>
  <div :style="{width: `${width}px`, height: `${height}px`}">
    <svg
      class="bar-chart"
      :width="width"
      :height="height"
    >
      <PlotLine
        v-if="showSecondaryZeroLine"
        class="mini-chart--horizontal-axis secondary"
        :data="horizontalAxisData"
        :x-scale="scaleX"
        :y-scale="scaleSecondaryY"
      />
      <PlotLine
        v-if="secondaryData"
        class="line-plot-1"
        :data="secondaryData"
        :x-scale="scaleX"
        :y-scale="scaleSecondaryY"
        :fill="true"
      />
      <PlotLine
        v-if="showPrimaryZeroLine"
        class="mini-chart--horizontal-axis primary"
        :data="horizontalAxisData"
        :x-scale="scaleX"
        :y-scale="scalePrimaryY"
      />
      <PlotLine
        class="line-plot-0"
        :data="primaryData"
        :x-scale="scaleX"
        :y-scale="scalePrimaryY"
        :fill="true"
      />
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';
import PlotLine from './Components/PlotLine.vue';

const PREFERRED_HEIGHT = 50;

const calculateDomainY = (data, height) => {
  const min = d3.min(data, (d) => d.y);
  const max = d3.max(data, (d) => d.y);
  if (height <= PREFERRED_HEIGHT) {
    return [min, max];
  }
  // We want the chart to fill the entire height without getting
  // distorted. We therefore calculate the range based on the
  // preferred height and then extend it to fill the element's
  // entire height.
  const preferredRange = max - min;
  const extendedRange = (height / PREFERRED_HEIGHT) * preferredRange;
  const addToMinMax = (extendedRange - preferredRange) / 2;
  const domainMin = min - addToMinMax;
  const domainMax = max + addToMinMax;
  // If all values are positive we align the chart to the bottom.
  if (min >= 0) {
    return domainMin >= 0
      ? [domainMin, domainMax]
      : [0, domainMax - domainMin];
  }
  // If all values are negative we align the chart to the top.
  if (max <= 0) {
    return domainMax <= 0
      ? [domainMin, domainMax]
      : [domainMin - domainMax, 0];
  }
  // If the domain contains positive and negative values we
  // vertically center the chart.
  return [domainMin, domainMax];
};

export default {
  name: 'MiniChart',
  components: {
    PlotLine,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Date,
      default: () => null,
    },
    endDate: {
      type: Date,
      default: () => null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    primaryData() {
      const val = this.data[0] || [];
      return val;
    },
    secondaryData() {
      return this.data[1] || [];
    },
    rangeX() {
      return [0, this.width];
    },
    rangeY() {
      return [this.height, 0];
    },
    domainX() {
      const primaryAndSecondaryData = this.primaryData.concat(this.secondaryData);
      const start = this.startDate || d3.min(primaryAndSecondaryData, (d) => d.x);
      const end = this.endDate || d3.max(primaryAndSecondaryData, (d) => d.x);
      return [start, end];
    },
    domainPrimaryY() {
      return calculateDomainY(this.primaryData, this.height);
    },
    domainSecondaryY() {
      return calculateDomainY(this.secondaryData, this.height);
    },
    scaleX() {
      return d3.scaleTime().domain(this.domainX).range(this.rangeX);
    },
    scalePrimaryY() {
      return d3.scaleLinear().domain(this.domainPrimaryY).nice().range(this.rangeY);
    },
    scaleSecondaryY() {
      return d3.scaleLinear().domain(this.domainSecondaryY).nice().range(this.rangeY);
    },
    showPrimaryZeroLine() {
      return this.domainPrimaryY[0] < 0 && this.domainPrimaryY[1] > 0;
    },
    showSecondaryZeroLine() {
      return this.domainSecondaryY[0] < 0 && this.domainSecondaryY[1] > 0;
    },
    horizontalAxisData() {
      return [
        { x: this.domainX[0], y: 0 },
        { x: this.domainX[1], y: 0 },
      ];
    },
  },
};
</script>

<style lang="scss">
// chart-line-* classes are used in PlotLine Component
.mini-chart--horizontal-axis {
  .chart--line-stroke {
    fill: none;
    stroke-width: 0.5;
  }

  &.primary .chart--line-stroke {
    stroke: rgba($color-selection-main, 0.5);
  }

  &.secondary .chart--line-stroke {
    stroke: $color-selection-comparison;
  }
}

.line-plot-0 {
  .chart--line-fill {
    fill: rgba($color-selection-main, 0.1);
  }

  .chart--line-stroke {
    stroke-width: 1;
    stroke: $color-selection-main;
  }
}

.line-plot-1 {
  .chart--line-fill {
    fill: rgba($color-selection-comparison, 0.1);
  }

  .chart--line-stroke {
    stroke-width: 1;
    stroke: $color-selection-comparison;
  }
}
</style>
