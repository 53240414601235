<template>
  <RichTextModal
    :title="mainTitle"
    :sub-title="$t('components.product_group_report_modal.subtitle')"
    :published-at="productGroup.product_group_report.published_at"
    :is-loading="isLoading"
    :url="$routes.ShowProductGroupReport(productGroup.product_group_report.id)"
    @close="$emit('close')"
  >
    <template #icon>
      <ProductGroupTrendIcon
        :trend="productGroup.product_group_report.trend"
        variant="title"
      />
    </template>
    <template
      v-if="showRelated"
      #sub-header
    >
      <div
        class="product-group-report-modal--related"
      >
        <div class="product-group-report-modal--related-text">
          {{ $t('components.product_group_report_modal.other_reports') }}
        </div>
        <ProductGroupBadge
          v-for="otherProductGroup in productGroupsWithReports"
          :key="otherProductGroup.id"
          :is-unobtrusive="otherProductGroup.id !== productGroup.id"
          :product-group="otherProductGroup"
          @click="openProductGroupReportFor(otherProductGroup, relatedProductGroups)"
        />
      </div>
    </template>
    <template #content>
      <RichTextContent
        :unsafe-html-content="report.content"
      />
    </template>
  </RichTextModal>
</template>

<script>
import ApiHelpers from '@/api/ApiHelpers';
import { ProductGroupsApi } from '@/api/ProductGroupsApi';
import RichTextContent from '@/components/RichTextContent/Component.vue';
import RichTextModal from '@/components/RichTextModal/Component.vue';
import ProductGroupBadge from '@/components/ProductGroupBadge/Component.vue';
import { openProductGroupReportFor } from '@/helpers/ProductGroupHelpers';
import ProductGroupTrendIcon from '@/components/ProductGroupTrendIcon/Component.vue';

export default {
  name: 'ProductGroupReportModal',
  components: {
    RichTextContent,
    RichTextModal,
    ProductGroupBadge,
    ProductGroupTrendIcon,
  },
  props: {
    productGroup: {
      type: Object,
      required: true,
    },
    relatedProductGroups: {
      type: Array,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      openProductGroupReportFor,
      isLoading: true,
      report: {},
    };
  },
  computed: {
    mainTitle() {
      return this.$t('components.product_group_report_modal.title', {
        product_group: this.productGroup.name,
      });
    },
    productGroupsWithReports() {
      return (this.relatedProductGroups || []).filter((group) => !!group.product_group_report);
    },
    showRelated() {
      return this.productGroupsWithReports.length > 1;
    },
  },
  watch: {
    productGroup() {
      this.loadReport();
    },
  },
  beforeMount() {
    this.loadReport();
  },
  methods: {
    async loadReport() {
      this.isLoading = true;
      try {
        this.report = await new ProductGroupsApi().report(this.productGroup.id);
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>