<script>
import { Tabs } from 'vue3-tabs-component'
import { getQueryParam, setQueryParam } from '@/services/UrlStateStore';

export default {
  components: { Tabs },

  data() {
    return {
      renderTabs: false
    }
  },

  mounted() {
    let tabPosition = getQueryParam('tab_position')

    // defer rendering the tabs until we get the query, it might have been overriden otherwise
    this.renderTabs = true

    if (!tabPosition ) { return }

    this.$nextTick(()=>{
      let tab = this.$refs.tabs.tabs[parseInt(tabPosition)]
      this.$refs.tabs.selectTab(tab.hash)
    })
  },

  methods: {
    tabChanged(event) {
      setQueryParam('tab_position', event.tab.index)
    }
  }
}
</script>

<!--
  For available props for Tabs, refer to https://www.npmjs.com/package/vue3-tabs-component#css
-->
<template>
  <Tabs
    ref="tabs"
    v-if="renderTabs"
    wrapper-class="tabs-wrapper risk-map__map"
    nav-class="button-tabs__header"
    nav-item-class="with-link"
    nav-item-active-class="selected-tab"
    panels-wrapper-class="vue-tabs button-tab-content button-tab-content--padded"
    @changed="tabChanged"
    cache-lifetime="0"
    :options="{ useUrlFragment: false }"
  >
    <slot></slot>
  </Tabs>
</template>

<style lang="scss">
.tabs-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  & > ul {
    flex-grow: 0;
  }
  .button-tabs__header {
    padding: 10px;
    border-bottom: 1px solid #b9b9b9;
  }
  .vue-tabs {
    flex-grow: 1;
    section {
      height: 100%;
      width: 100%;
      display: inline-block;
    }
  }
}
@media print {
  .tabs-wrapper {
    box-shadow: none;
    .vue-tabs section {
      display: initial;
    }
  }
}
</style>
