<template>
  <div class="chart-legend--wrapper">
    <h2
      v-if="showTitle()"
      class="chart-legend--title"
    >
      {{ axisDescription }}
    </h2>
    <ul>
      <li
        v-for="entry in entries"
        :key="entry"
        class="chart-legend--item"
      >
        <ChartLegendItem
          v-if="showItem(entry)"
          :notation="entry.notation"
          :notation-color="entry.color"
          :unit="entry.unit"
          :unit-converted-from="entry.unitConvertedFrom"
          :is-selected="entry.selected"
          :with-quotes="entry.withQuotes"
          :show-index-view="showIndexView"
          @update:is-selected="(selectedLine) => $emit('set-line-selected', {
            notationID: entry.notation.id,
            selected: selectedLine,
          })"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ChartLegendItem from './ChartLegendItem/Component.vue';

export default {
  name: 'ChartLegend',
  components: {
    ChartLegendItem,
  },
  props: {
    entries: {
      type: Array,
      default: null,
    },
    axisDescription: {
      type: String,
      default: null,
    },
    showIndexView: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['set-line-selected'],
  methods: {
    showTitle() {
      return this.entries.filter((entry) => this.showItem(entry)).length > 0;
    },
    showItem(entry) {
      return this.$media !== 'print' || (entry.withQuotes && entry.selected);
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>