import {
  ResampleTimeseries,
  InterpolateArrayLinear,
  AggregateTimeseriesByTimescope,
  ShiftTimeseriesMonthly,
  PearsonCorrelation,
} from './Algorithms';
import ForecastConfidence from './Confidence';
import ForecastTrend from './Trend';

const replaceTimeseriesValues = (X, values) => X.map((each, index) => ({
  x: each.x,
  y: values[index],
}));

const validateTimescope = (timescope) => {
  const ValidTimescopes = ['monthly', 'biweekly', 'weekly', 'daily'];
  if (!ValidTimescopes.includes(timescope)) {
    throw new Error(`Invalid timescope. Must be one of [${ValidTimescopes.join(', ')}].`);
  }
};

const dateDifferenceInMonths = (from, to) => {
  let months = (to.getFullYear() - from.getFullYear()) * 12;
  months -= from.getMonth();
  months += to.getMonth();
  return months <= 0 ? 0 : months;
};

const calculateCorrelation = ({
  X,
  Y,
  timescopeX,
  timescopeY,
}) => {
  const firstDate = new Date(Math.max(X[0].x, Y[0].x));
  const lastDate = new Date(Math.min(X[X.length - 1].x, Y[Y.length - 1].x));
  const spansTwoYears = dateDifferenceInMonths(firstDate, lastDate) >= 24;
  if (!spansTwoYears) {
    throw new Error('Timeseries intersection must span at least 24 months.');
  }
  validateTimescope(timescopeX);
  validateTimescope(timescopeY);

  // 1) Resample
  const xResampled = ResampleTimeseries(X, timescopeX);
  const yResampled = ResampleTimeseries(Y, timescopeY);
  // 2) Interpolate
  const xValues = xResampled.map((each) => each.y);
  const xInterpolatedValues = InterpolateArrayLinear(xValues);
  const xInterpolated = replaceTimeseriesValues(xResampled, xInterpolatedValues);
  const yValues = yResampled.map((each) => each.y);
  const yInterpolatedValues = InterpolateArrayLinear(yValues);
  const yInterpolated = replaceTimeseriesValues(yResampled, yInterpolatedValues);
  // 3) Aggregate monthly
  const xAggregated = AggregateTimeseriesByTimescope(xInterpolated, 'monthly');
  const yAggregated = AggregateTimeseriesByTimescope(yInterpolated, 'monthly');
  // 4) Apply time shift
  const yShifted = [1, 2, 3].map((shift) => ShiftTimeseriesMonthly(yAggregated, shift));
  // 5) Find timeseries intersection (start/end date)
  const intersections = yShifted.map((Y1) => {
    const startDate = new Date(Math.max(xAggregated[0].x, Y1[0].x));
    const endDate = new Date(Math.min(xAggregated[xAggregated.length - 1].x, Y1[Y1.length - 1].x));
    return {
      X: xAggregated.filter((x) => x.x >= startDate && x.x <= endDate),
      Y: Y1.filter((y) => y.x >= startDate && y.x <= endDate),
    };
  });
  // 6) Correlate
  const correlations = intersections.map((each) => ({
    X: each.X,
    Y: each.Y,
    correlation: PearsonCorrelation(each.X.map((d) => d.y), each.Y.map((d) => d.y)),
  }));
  return correlations;
};

const calculateForecast = ({
  X,
  Y,
  timescopeX,
  timescopeY,
}) => {
  const correlations = calculateCorrelation({
    X,
    Y,
    timescopeX,
    timescopeY,
  });
  // 7) Confidence
  const withConfidence = correlations.map((each, index) => ({
    ...each,
    timeShiftY: index + 1,
    confidence: ForecastConfidence.forCorrelation(each.correlation),
  }));

  const bestForecast = withConfidence.reduce((best, current) => {
    if (best === null) return current;
    if (current.confidence >= best.confidence) return current;
    return best;
  }, null);

  const trend = [];
  for (let i = 1; i <= bestForecast.timeShiftY; i += 1) {
    const lookbackMonths = i + 1;
    const slice = bestForecast.Y.slice(-lookbackMonths);
    const currentTrend = bestForecast.correlation < 0
      ? ForecastTrend.invert(ForecastTrend.forTimeseries(slice))
      : ForecastTrend.forTimeseries(slice);
    trend.push(currentTrend);
  }

  return {
    ...bestForecast,
    trend,
  };
};

const correlation = ({
  X,
  Y,
  timescopeX,
  timescopeY,
}) => calculateCorrelation({
  X,
  Y,
  timescopeX,
  timescopeY,
}).map((c) => c.correlation);

export default {
  call: calculateForecast,
  correlation,
  Confidence: ForecastConfidence,
  Trend: ForecastTrend,
};
