import { computed } from 'vue';
import moment from 'moment';

const ONE_WEEK_IN_DAYS = 7;
const BASE_DATE = moment('1990-01-01');

const DateSlider = (graphLimits) => {
  // The slider needs to keep track of the current date as
  // the number of days from a base date, because if the
  // timestamp is used instead, the numbers get too large
  // and the underlying vue-slider causes errors through
  // overflow and/or rounding errors.
  const daysSinceBaseDateToAbsoluteDate = (daysSinceMinDate) => BASE_DATE.clone().add(daysSinceMinDate, 'days');
  const absoluteDateToDaysSinceBaseDate = (absoluteDate) => absoluteDate.diff(BASE_DATE, 'days');

  const sliderValuesInDays = computed({
    get() {
      return [graphLimits.startDate, graphLimits.endDate].map(absoluteDateToDaysSinceBaseDate);
    },
    set(rangeInDaysSinceMinDate) {
      graphLimits.startDate = daysSinceBaseDateToAbsoluteDate(rangeInDaysSinceMinDate[0]);
      graphLimits.endDate = daysSinceBaseDateToAbsoluteDate(rangeInDaysSinceMinDate[1]);
    },
  });

  const minimumValue = computed(() => absoluteDateToDaysSinceBaseDate(graphLimits.minDate));
  const maximumValue = computed(() => absoluteDateToDaysSinceBaseDate(graphLimits.maxDate));

  const tooltipFormatter = computed(() => (daysSinceMinDate) => daysSinceBaseDateToAbsoluteDate(daysSinceMinDate)?.format('LL'));

  return {
    value: sliderValuesInDays,
    minimumValue,
    maximumValue,
    tooltipFormatter,
    get minimumRange() {
      return ONE_WEEK_IN_DAYS;
    },
  };
};

export default DateSlider;
