import axios from 'axios';
import { reactive } from 'vue';
import Turbolinks from 'turbolinks';
import ApiHelpers from '@/api/ApiHelpers';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

const isValidLetter = (letter) => ALPHABET.includes(letter);

const SearchRegistryApi = () => {
  let cancelToken;
  const fetch = (letter) => {
    if (!isValidLetter(letter)) {
      throw new TypeError('SearchRegistryApi only accepts letters as arguments.');
    }
    if (cancelToken) {
      cancelToken.cancel('Request cancelled.');
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(`/api/top_terms/registry/${letter}`, {
      cancelToken: cancelToken.token,
    }).then((response) => response.data)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });
  };
  return { fetch };
};

const SearchRegistryController = () => {
  const api = SearchRegistryApi();
  const state = reactive({
    currentIndex: 0,
    isLoading: false,
    currentLetter: null,
    currentEntries: [],
  });

  const resetState = () => {
    state.currentIndex = 0;
    state.isLoading = false;
    state.currentLetter = null;
    state.currentEntries = [];
  };

  const setCurrentLetter = (letter) => {
    if (letter === state.currentLetter) {
      return;
    }
    state.currentIndex = 0;
    state.currentEntries = [];
    state.isLoading = isValidLetter(letter);
    state.currentLetter = letter;
    if (state.isLoading) {
      api.fetch(state.currentLetter)
        .then((results) => {
          setTimeout(() => {
            state.currentEntries = results;
            state.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          ApiHelpers.handleError(error);
          state.isLoading = false;
        });
    }
  };

  setCurrentLetter('a');

  return {
    get indices() {
      return ALPHABET;
    },
    get isLoading() {
      return state.isLoading;
    },
    get currentEntries() {
      return state.currentEntries;
    },
    get currentIndex() {
      return state.currentIndex;
    },
    get currentLetter() {
      return state.currentLetter;
    },
    set currentLetter(letter) {
      setCurrentLetter(letter);
    },
    get selectedItem() {
      return state.currentEntries[state.currentIndex];
    },
    setCurrentIndexWithLimits(index) {
      const min = 0;
      const max = this.currentEntries.length - 1;
      state.currentIndex = Math.max(min, Math.min(max, index));
    },
    scrollCurrentIndex(delta) {
      this.setCurrentIndexWithLimits(this.currentIndex + delta);
    },
    submit() {
      if (this.currentEntries.length === 0) {
        return false;
      }
      const item = this.selectedItem;
      resetState();
      Turbolinks.visit(item.url);
      return true;
    },
  };
};

export default SearchRegistryController;
