<template>
  <hr class="vertical-separator">
</template>

<script>
export default {
  name: 'VerticalSeparator',
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>