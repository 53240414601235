<template>
  <div class="chart-config-form--name">
    <TextInput
      :model-value="title"
      :label="$t('apps.chart_config.name')"
      :required="true"
      @update:modelValue="(value) => $emit('update:title', value)"
    />
  </div>
  <div class="chart-config-form--subtitle">
    <div class="chart-config--axes-count">
      <h1 class="form--label">
        {{ $t('apps.chart_config.axes_count') }}
      </h1>
      <div class="chart-config-form--wrapper">
        <div
          v-for="axisCountOption in axesCountOptions"
          :key="axisCountOption"
        >
          <label class="form--label chart-config-form--label">
            <input
              type="radio"
              :value="axisCountOption"
              :checked="axisCountOption === selectedAxesCount"
              :name="`line_${axisCountOption}`"
              @input="selectAxesCount"
            >
            {{ $t(`apps.chart_config.${axisCountOption}_axis`) }}
          </label>
        </div>
      </div>
    </div>
    <div class="chart-config-form--wrapper">
      <AxisForm
        axis-type="left"
        :available-composition-categories="availableCompositionCategories"
        :available-currencies="availableCurrencies"
        :available-volume-units="availableVolumeUnits"
        :axis-unit="leftAxisCompositeUnit"
        :price-unit-categories="priceUnitCategories"
        :volume-unit-categories="volumeUnitCategories"
        @update:axis-composite-unit="(value) => $emit('update:left-axis-composite-unit', value)"
      />
      <AxisForm
        v-if="selectedAxesCount === 2"
        :available-composition-categories="availableCompositionCategories"
        :available-currencies="availableCurrencies"
        :available-volume-units="availableVolumeUnits"
        axis-type="right"
        :axis-unit="rightAxisCompositeUnit"
        :price-unit-categories="priceUnitCategories"
        :volume-unit-categories="volumeUnitCategories"
        @update:axis-composite-unit="(value) => $emit('update:right-axis-composite-unit', value)"
      />
    </div>
  </div>
</template>

<script>
import AxisForm from './AxisForm/Component.vue';

export default {
  name: 'ChartConfigurationForm',
  components: {
    AxisForm,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    leftAxisCompositeUnit: {
      type: Object,
      required: true,
    },
    rightAxisCompositeUnit: {
      type: Object,
      required: true,
    },
    selectedAxesCount: {
      type: Number,
      required: true,
    },
    availableCompositionCategories: {
      type: Array,
      required: true,
    },
    availableCurrencies: {
      type: Array,
      default: null,
    },
    availableVolumeUnits: {
      type: Object,
      default: null,
    },
    priceUnitCategories: {
      type: Array,
      default: null,
    },
    volumeUnitCategories: {
      type: Array,
      default: null,
    },
  },
  emits: [
    'update:title',
    'update:left-axis-composite-unit',
    'update:right-axis-composite-unit',
    'update:selected-axes-count',
  ],
  data() {
    return {
      axesCountOptions: [1, 2],
    };
  },
  methods: {
    selectAxesCount(event) {
      const selectedAxesCount = parseInt(event.target.value, 10);
      this.$emit('update:selected-axes-count', selectedAxesCount);
      if (selectedAxesCount === 1) {
        this.$emit('update:right-axis-composite-unit', {
          composition_category: null,
          currency_unit_id: null,
          unit: null,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>