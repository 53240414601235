<template>
  <div
    class="spinbox"
  >
    <button
      type="button"
      class="spinbox--control"
      :aria-label="decrementLabel"
      :disabled="modelValue == min"
      @click="setValueWithLimit(modelValue - step)"
    >
      <Icon
        name="chevron-left"
        variant="regular"
      />
    </button>
    <input
      ref="input"
      type="number"
      class="spinbox--input"
      :value="modelValue"
      size="4"
      :aria-label="label"
      @input="onInput"
      @focus="$emit('focus', $event)"
      @blur="syncValue"
    >
    <button
      type="button"
      class="spinbox--control"
      :aria-label="incrementLabel"
      :disabled="modelValue == max"
      @click="setValueWithLimit(modelValue + step)"
    >
      <Icon
        name="chevron-right"
        variant="regular"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'SpinBox',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
    'focus',
  ],
  computed: {
    incrementLabel() {
      return this.label
        ? this.$t('components.spinbox.increment_label_with_name', { label: this.label })
        : this.$t('components.spinbox.increment_label');
    },
    decrementLabel() {
      return this.label
        ? this.$t('components.spinbox.decrement_label_with_name', { label: this.label })
        : this.$t('components.spinbox.decrement_label');
    },
  },
  methods: {
    isValidValue(value) {
      return value >= this.min
          && value <= this.max;
    },
    syncValue() {
      this.$refs.input.value = this.modelValue;
    },
    setValueWithLimit(value) {
      const cappedValue = Math.max(this.min, Math.min(this.max, value));
      this.$emit('update:modelValue', cappedValue);
    },
    onInput(event) {
      const { value } = event.target;
      if (this.isValidValue(value)) {
        this.$emit('update:modelValue', parseInt(value, 10));
      }
    },
  },
};
</script>
