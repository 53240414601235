<template>
  <span>
    <input
      :id="uid"
      hidden
      type="file"
      :multiple="multiple"
      :disabled="isDisabled"
      @change="$emit('select', $event.target.files)"
    >
    <label
      :for="uid"
      :class="labelClass"
    >
      <slot name="button">
        <ButtonComponent
          tag="span"
          :padding="buttonPadding"
          v-bind="Object.assign({}, $attrs, $props)"
        >
          <slot />
        </ButtonComponent>
      </slot>
    </label>
  </span>
</template>

<script>
import HasUid from '@/mixins/HasUid';

export default {
  name: 'FileSelectButton',
  mixins: [HasUid],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    buttonPadding: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
  },
  emits: [
    'select',
  ],
};
</script>
