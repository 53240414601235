import axios from 'axios';

const onSearchSubmit = (queryId) => axios.post('/api/logs', {
  event_type: 'search/submit',
  payload: {
    query_id: queryId,
  },
});

const onSearchUpdate = (queryId, filters, sorting, comparisonDate) => axios.post('/api/logs', {
  event_type: 'search/update',
  payload: {
    query_id: queryId,
    filters,
    sorting,
    comparison_date: comparisonDate,
  },
});

const onSearchTermRequest = (searchTerm) => axios.post('/api/logs', {
  event_type: 'search/request-search-term',
  payload: {
    search_term: searchTerm,
  },
});

const onDashboardSearchSuggested = (searchText, selectedTags) => axios.post('/api/logs', {
  event_type: 'dashboard_search/request',
  payload: {
    search_term: searchText,
    selected_scopes: selectedTags.map((tag) => ({
      id: tag.id,
      type: tag.type,
      name: tag.name,
    })),
  },
});

export default {
  onSearchSubmit,
  onSearchUpdate,
  onSearchTermRequest,
  onDashboardSearchSuggested,
};
