import ModalWindowController from '@/controllers/ModalWindowController';
import ProductGroupReportStateController from '@/controllers/ProductGroupReportStateController';
import GetFeatureSwitches from '@/config/GetFeatureSwitches';

const relevantProductGroupsForUser = (allProductGroups, user) => {
  if (user.editor_permissions) return allProductGroups;
  if (!GetFeatureSwitches().ANALYST_CORNER) return [];
  return allProductGroups.filter((g) => !!g.product_group_report);
};

const openProductGroupReportFor = (productGroup, relatedProductGroups) => {
  if (!GetFeatureSwitches().ANALYST_CORNER) return;
  if (!productGroup.product_group_report) return;
  ProductGroupReportStateController.markReportRead(productGroup.product_group_report);
  ModalWindowController.show('ProductGroupReportModal', {
    props: {
      productGroup,
      relatedProductGroups,
    },
  });
};

export {
  relevantProductGroupsForUser,
  openProductGroupReportFor,
};
