import { ref, watch } from "vue";
import { getQueryParam, setQueryParam } from "../../../services/UrlStateStore";

export const usePortraitsDropdowns = (defaultId) => {
  const selectedSubject01 = ref(+getQueryParam("subject01", defaultId));
  const selectedSubject02 = ref(+getQueryParam("subject02", defaultId));
  const selectedSubject03 = ref(+getQueryParam("subject03", defaultId));
  const selectedSubject04 = ref(+getQueryParam("subject04", defaultId));
  const selectedSubject05 = ref(+getQueryParam("subject05", defaultId));
  const selectedSubject06 = ref(+getQueryParam("subject06", defaultId));

  watch(selectedSubject01, (subjects) => {
    setQueryParam("subject01", subjects);
  });
  watch(selectedSubject02, (subjects) => {
    setQueryParam("subject02", subjects);
  });
  watch(selectedSubject03, (subjects) => {
    setQueryParam("subject03", subjects);
  });
  watch(selectedSubject04, (subjects) => {
    setQueryParam("subject04", subjects);
  });
  watch(selectedSubject05, (subjects) => {
    setQueryParam("subject05", subjects);
  });
  watch(selectedSubject06, (subjects) => {
    setQueryParam("subject06", subjects);
  });

  return {
    selectedSubject01,
    selectedSubject02,
    selectedSubject03,
    selectedSubject04,
    selectedSubject05,
    selectedSubject06,
  };
};
