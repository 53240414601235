<template>
  <ComboBox
    class="mail-export-button"
    :model-value="currentIndex"
    :options="options"
    :label="ariaLabel"
    :disabled="isLoading || isSaving"
    :display-text-override="comboBoxDisplayTextOverride"
    @update:modelValue="setMailExportRythm"
  />
</template>

<script>
import i18n from '@/config/i18n';
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';

const EXPORT_RYTHMS = ['never', 'daily', 'weekly', 'monthly'];

export default {
  name: 'MailExportButtonComponent',
  props: {
    ariaLabel: {
      type: String,
      default: i18n.t('components.mail_export_button.options.default_label'),
    },
    mailExportType: {
      type: String,
      required: true,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      keys: EXPORT_RYTHMS,
      mailExportRythm: 'never',
    };
  },
  computed: {
    options() {
      return this.keys.map((key) => this.$t(`components.mail_export_button.options.${key}`));
    },
    currentIndex() {
      return this.keys.indexOf(this.mailExportRythm);
    },
    currentOption() {
      return this.options[this.currentIndex];
    },
    comboBoxDisplayTextOverride() {
      if (this.isLoading) {
        return this.$t('components.mail_export_button.is_loading');
      } if (this.isSaving) {
        return this.$t('components.mail_export_button.is_saving');
      }
      return null;
    },
  },
  beforeMount() {
    this.getMailExportRythm();
  },
  methods: {
    async getMailExportRythm() {
      this.isLoading = true;
      const params = { type: this.mailExportType, dashboard_id: this.dashboardId };
      const response = await axios.get('/api/subscriptions', { params });
      const subscription = response.data[0];
      this.mailExportRythm = subscription.interval;
      this.isLoading = false;
    },
    async setMailExportRythm(index) {
      try {
        this.isSaving = true;
        const interval = this.keys[index];
        await axios.post('/api/subscriptions/bulk_update', {
          subscriptions: [{
            type: this.mailExportType,
            interval,
            dashboard_id: this.dashboardId,
          }],
        });
        this.mailExportRythm = interval;
      } catch (error) {
        ApiHelpers.handleError(error);
        return { error };
      } finally {
        this.isSaving = false;
      }
      return {};
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>