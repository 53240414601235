import EventBase from './EventBase';

class DashboardFavoriteChangeEvent extends EventBase {
  constructor({ dashboardId, isFavorite }) {
    super();
    this._data = {
      dashboardId,
      isFavorite,
    };
  }

  static get EventName() {
    return 'HGS::DashboardFavoriteChangeEvent';
  }

  get dashboardId() {
    return this._data.dashboardId;
  }

  get isFavorite() {
    return this._data.isFavorite;
  }
}

export default DashboardFavoriteChangeEvent;
