<template>
  <td
    class="data-table--body-cell is-right-aligned is-tight"
    v-on="eventListeners"
  >
    <KeyPerformanceIndicator
      class="is-small"
      :value="percentGrowth"
      :base-value="baseValue"
      :base-value-precision="baseValuePrecision"
    />
  </td>
</template>

<script>
import { gradualPrecisionFor } from '@/helpers/NumberFormats';
import { KeyPerformanceIndicator } from '@/components';

export default {
  name: 'KpiColumn',
  components: {
    KeyPerformanceIndicator,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    kpi() {
      return this.value?.kpi;
    },
    latestValue() {
      return this.value?.latest_value;
    },
    baseValuePrecision() {
      return this.latestValue
        ? gradualPrecisionFor(Number.parseFloat(this.latestValue))
        : 2;
    },
    baseValue() {
      return Number.parseFloat(this.kpi?.value);
    },
    percentGrowth() {
      return Number.parseFloat(this.kpi?.growth);
    },
  },
};
</script>
