<template>
  <RichTextModal
    :title="publication.display_name"
    :sub-title="subTitle"
    :published-at="publication.published_at"
    icon="folders"
    :url="$routes.ShowPublication(publication.id)"
    @close="$emit('close')"
  >
    <template #content>
      <ButtonComponent
        variant="primary"
        icon="download"
        :disabled="isDownloading"
        @click="downloadItem"
      >
        <template v-if="isDownloading">
          {{ $t("apps.publications.publication_modal.download_button.in_progress_label") }}
        </template>
        <template v-else>
          {{ $t("apps.publications.publication_modal.download_button.options.default_label") }}
        </template>
      </ButtonComponent>
    </template>
  </RichTextModal>
</template>

<script>
import {
  RichTextModal
} from "@/components";
import { DownloadPublication } from "@/helpers/PublicationHelpers";
import ApiHelpers from "@/api/ApiHelpers";

export default {
  name: "PublicationModal",
  components: {
    RichTextModal
  },
  props: {
    publication: {
      type: Object,
      required: true
    }
  },
  emits: [
    "close"
  ],
  data() {
    return {
      isDownloading: false
    };
  },
  computed: {
    subTitle() {
      return this.publication.source
        ? this.subTitleWithSource
        : "";
    },
    subTitleWithSource() {
      return this.$t("apps.publications.view_news.source", {
        source: this.publication.source
      });
    }
  },
  methods: {
    async downloadItem() {
      this.isDownloading = true;
      try {
        await DownloadPublication(this.publication);
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isDownloading = false;
      }
    }
  }
};
</script>
