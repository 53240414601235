<template>
  <td
    class="data-table--body-cell"
    :class="classes"
  >
    <div class="country-on-demand__content-wrapper">
      <WithTooltip
        v-if="value.tooltip"
        y-alignment="top"
        :tooltip="value.tooltip"
      >
        <span>{{ value.text }}</span>
      </WithTooltip>
      <WithTooltip
          y-alignment="top"
      >
        <span class="country-on-demand__scores">
          <img
            v-if="hrScoreIcon"
            :src="hrScoreIcon"
            class="country-on-demand__score-img"
          >
          <span class="country-on-demand__score-value">{{ hrScore.value }}</span>
          <img
            v-if="hrScoreFlagIcon && withFlagIcon"
            :src="hrScoreFlagIcon"
            class="country-on-demand__score-img"
          >
        </span>
        <template
          v-if="hrScoreTooltipData"
          #custom
        >
          <TopFiveCountryCellTooltip :data="hrScoreTooltipData" />
        </template>
      </WithTooltip>
    </div>
  </td>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useHrScoreIcon } from '../TopFiveCountry/hrScoreIcon';
import useHrScoreFlagIcon from '../TopFiveCountry/hrScoreFlagIcon';
import TopFiveCountryCellTooltip from '../TopFiveCountry/TopFiveCountryCellTooltip.vue';
import WithTooltip from '../../../../generic/WithTooltip/Component.vue';

const props = defineProps(['value', 'additionalData', 'classes']);

const withFlagIcon = computed(() => props.value?.withFlagIcon);
const classes = computed(() => props.classes);

const selectedDimension = computed(() => props.additionalData?.selectedDimension);
const hrScore = computed(() => props.value.selectedCountryHrScores[selectedDimension.value]);
const grade = computed(() => hrScore.value.grade);
const hrScoreTooltipData = computed(() => ({
  hrScores: props.value.selectedCountryHrScores,
  currentCountry: props.value?.currentCountry,
}));

const { hrScoreIcon, getIconReactive } = useHrScoreIcon(grade);
const { hrScoreFlagIcon } = useHrScoreFlagIcon(props.value.selectedCountryHrScores);

watch(selectedDimension, getIconReactive);
</script>
<style scoped lang="scss">
.country-on-demand {
  &__content-wrapper {
    display: flex;
    @media (max-width: $page-width-narrow) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__scores {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 5px;
    @media (max-width: $page-width-narrow) {
      margin-top: 7px;
    }
  }

  &__score-value {
    padding: 0 5px;
  }

  &__score-img {
    flex-shrink: 0;
  }
}
</style>
