export default {
  namespace: "",

  get(key, options={}) {
    if (!window.localStorage) { return }
    let value = localStorage.getItem(`ColumnGroupVisibilityStore.${this.namespace}${key}`)
    if (!value) { return options.default }

    return value == '1'
  },

  set(key, value) {
    if (this.isDisabled) { return }
    if (!window.localStorage) { return }
    localStorage.setItem(`ColumnGroupVisibilityStore.${this.namespace}${key}`, value ? '1' : '0')
  },

  isDisabled: false,
  disabled(callback) {
    this.isDisabled = true
    callback()
    this.isDisabled = false
  }
}
