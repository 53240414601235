<template>
  <DashboardPageLayout :separator="true">
    <template #top>
      <div class="deep-styles-helper">
        <PrintLayout :caption="printHeaderCaption" />
        <div class="trade-flows--header">
          <DashboardTradeFlowsPrintMetaTable
            :dashboard-name="reactiveDashboard.full_name"
            :year="selectedYear"
            :source="selectedSource"
            :classification="selectedClassification"
            :reporter-name="printHeaderReporterName"
            :partner-name="printHeaderPartnerName"
            :balance="printHeaderBalance"
          />
          <DashboardHeader
            v-exclude-from-print
            :dashboard="reactiveDashboard"
            :user="user"
          />
          <DashboardTradeFlowsMenu
            v-exclude-from-print
            :dashboard="reactiveDashboard"
            @change="onMenuChanged"
          />
          <p
            v-if="isLastYearSelected"
            class="trade-flows--warning-text"
          >
            {{ lastYearDataWarning }}
          </p>
        </div>
      </div>
    </template>
    <template #bottom>
      <div
        v-if="headerFinishedLoading"
        class="deep-styles-helper"
      >
        <EmptyTradeFlows v-if="!hasData" />
        <Card
          v-else
          class="trade-flow--card"
        >
          <CardHeader
            v-exclude-from-print
            class="space-between"
          >
            <TabBar
              v-model="currentTab"
              :tabs="tabs"
              @update:modelValue="onTabChanged"
            />
            <ActionList>
              <ActionListItem>
                <PdfExportButtonComponent />
              </ActionListItem>
            </ActionList>
          </CardHeader>
          <CardBody>
            <SpinningLoader v-if="isLoadingWorldData" />
            <TradeFlowsForWorld
              v-else-if="currentTab === 'world'"
              :data-provider="worldDataProvider"
            />
            <DashboardTradeFlowsForCountry
              v-else
              :dashboard-id="reactiveDashboard.id"
              :classification="selectedClassification"
              :source="selectedSource"
              :year="selectedYear"
              :countries="countries"
              :world-data="worldDataProvider.data"
              @change-reporter="onChangeReporter"
            />
          </CardBody>
        </Card>
      </div>
    </template>
  </DashboardPageLayout>
</template>

<script>
import { DashboardPageLayout } from '@/components/Layouts';
import UrlStateStore from '@/services/UrlStateStore';
import { PrintLayout, DashboardHeader } from '@/components';
import TradeFlowDataProvider from './controllers/TradeFlowDataProvider';
import {
  DashboardTradeFlowsMenu,
  TradeFlowsForWorld,
  DashboardTradeFlowsForCountry,
  EmptyTradeFlows,
  DashboardTradeFlowsPrintMetaTable,
} from './components';

export default {
  name: 'DashboardTradeFlowsApp',
  components: {
    DashboardPageLayout,
    DashboardHeader,
    DashboardTradeFlowsMenu,
    TradeFlowsForWorld,
    DashboardTradeFlowsForCountry,
    EmptyTradeFlows,
    PrintLayout,
    DashboardTradeFlowsPrintMetaTable,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
    world: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reactiveDashboard: this.dashboard,
      selectedClassification: null,
      selectedYear: null,
      currentTab: null,
      headerFinishedLoading: false,
      worldDataProvider: null,
      selectedReporter: null,
      selectedSource: null,
    };
  },
  computed: {
    tabs() {
      const disableWorldTab = this.selectedSource === 'Eurostat';
      const worldTabTooltip = disableWorldTab ? this.$t('apps.trade_flow_board.world_tab_tooltip') : null;
      const worldTab = {
        value: 'world',
        name: this.$t('components.trade_flows.tab_world'),
        icon: 'globe',
        disabled: disableWorldTab,
        tooltip: worldTabTooltip,
      };
      const countryTab = {
        value: 'country',
        name: this.$t('components.trade_flows.tab_country'),
        icon: 'flag',
        disabled: false,
      };
      if (!this.world) {
        return [countryTab];
      }
      return [worldTab, countryTab];
    },
    hasData() {
      return this.selectedClassification?.Id !== null
          && this.selectedYear !== null;
    },
    printHeaderCaption() {
      return this.$t('components.trade_flows.print_header.caption', {
        dashboard: this.reactiveDashboard.full_name,
      });
    },
    printHeaderReporterName() {
      return this.currentTab === 'world' ? null : this.selectedReporter?.name;
    },
    printHeaderPartnerName() {
      return this.currentTab === 'world' ? this.world?.name : null;
    },
    printHeaderBalance() {
      if (!this.worldDataProvider?.data?.balance?.length) return null;
      const balance = this.worldDataProvider.data.balance
        .find((c) => c.country_id === this.selectedReporter?.id);
      return balance?.value;
    },
    isLastYearSelected() {
      return this.selectedYear === new Date().getFullYear() - 1;
    },
    lastYearDataWarning() {
      return this.$t('apps.trade_flow_board.menu.last_year_warning');
    },
    isLoadingWorldData() {
      return this.selectedSource === 'UNComtrade'
        ? this.worldDataProvider.isLoading
        : false;
    },
  },
  beforeMount() {
    UrlStateStore.replaceState(this.initialState);
    this.currentTab = UrlStateStore.get('tab', this.tabs[0].value);
    this.worldDataProvider = new TradeFlowDataProvider(this.reactiveDashboard.id);
  },
  methods: {
    onTabChanged(tab) {
      UrlStateStore.clear('reporter');
      UrlStateStore.clear('partner');
      UrlStateStore.set('tab', tab);
    },
    onMenuChanged(data) {
      if (data.classification && data.year) {
        this.selectedClassification = data.classification;
        this.selectedYear = data.year;
        this.selectedSource = data.source;
      }
      this.currentTab = this.selectedSource === 'Eurostat'
        ? 'country'
        : this.currentTab;
      this.headerFinishedLoading = true;
      this.fetchWorldData();
    },
    fetchWorldData() {
      this.worldDataProvider.clear();
      this.worldDataProvider.setApiParams({
        partnerId: this.world?.id,
        classificationId: this.selectedClassification?.id,
        source: 'UNComtrade',
        year: this.selectedYear,
      });
    },
    onChangeReporter(reporter) {
      this.selectedReporter = reporter;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.deep-styles-helper) {
  @media only screen {
    @import './style.screen.scss';
  }

  @media only print {
    @import './style.print.scss';
  }
}
</style>
