<template>
  <li class="config-table--row">
    <div class="config-table--axes-column">
      <div
        v-for="axisAssignmentOption in axisAssignmentOptions"
        :key="axisAssignmentOption"
        class="config-table--axis-label"
      >
        <input
          :id="`${axisAssignmentOption}${notation.id}`"
          type="radio"
          :value="axisAssignmentOption"
          :checked="axisAssignmentOption === notation.axis"
          :name="notation.id"
          class="config-table--radio-button"
          :disabled="!isAxisCompatible(axisAssignmentOption)"
          @input="selectAxisAssignmentOption"
        >
        <label
          :for="`${axisAssignmentOption}${notation.id}`"
        >
          {{ $t(`apps.chart_config.${axisAssignmentOption}_axis_assignment_option`) }}
        </label>
      </div>
    </div>

    <div
      v-if="notation.notFound === true"
      class="config-table--notations-column"
    >
      <div class="config-table--title-column">
        <span
          class="config-table--notation-icon"
          :style="{backgroundColor: notation.color}"
        />
        <h2 class="config-table--not-found">
          {{ $t('apps.chart_config.notation_not_found') }}
        </h2>
      </div>
    </div>
    <div
      v-else
      class="config-table--notations-column"
    >
      <div
        class="config-table--title-column"
      >
        <span
          class="config-table--notation-icon"
          :style="{backgroundColor: notation.color}"
        />
        <h2
          v-if="classPrefix !== null"
          class="config-table--class-code"
        >
          {{ classPrefix }}
        </h2>
        <h2 class="config-table--notation-title">
          {{ notation.name }}
        </h2>
      </div>
      <div class="config-table--notation-tags">
        <NotationDetailsTagList
          :notation="notation"
        />
      </div>
    </div>
    <div class="config-table--remove-button">
      <ButtonComponent
        icon="times"
        variant="ternary"
        padding="none"
        :aria-label="$t('apps.chart_config.remove_label')"
        @click="onRemoveNotationFromConfigClicked"
      />
    </div>
  </li>
</template>

<script>
import NotationDetailsTagList from '@/components/NotationDetails/TagList/Component.vue';
import { configureClassPrefix } from '@/helpers/ClassPrefixHelpers';
import ModalWindowController from '@/controllers/ModalWindowController';

export default {
  name: 'ChartNotationAssignmentTableRow',
  components: {
    NotationDetailsTagList,
  },
  props: {
    notation: {
      type: Object,
      required: true,
    },
    compatibleUnits: {
      type: Object,
      default: null,
    },
    leftAxisCompositeUnit: {
      type: Object,
      default: null,
    },
    rightAxisCompositeUnit: {
      type: Object,
      default: null,
    },
    units: {
      type: Object,
      default: null,
    },
    onlyLeftAxisCompatible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:axis-assignment-option', 'remove-notation-from-config'],
  data() {
    return {
      axisAssignmentOptions: ['left', 'right', 'none'],
    };
  },
  computed: {
    classPrefix() {
      return configureClassPrefix(
        this.notation.class_system,
        this.notation.class_code,
      );
    },
  },
  methods: {
    selectAxisAssignmentOption(e) {
      this.$emit('update:axis-assignment-option', e.target.value);
    },
    findCompositeUnit(axisAssignmentOption) {
      if (axisAssignmentOption === 'left') {
        return this.leftAxisCompositeUnit;
      }
      if (axisAssignmentOption === 'right') {
        return this.rightAxisCompositeUnit;
      }
      return null;
    },
    isAxisCompatible(axisAssignmentOption) {
      if (this.onlyLeftAxisCompatible === true) {
        if (axisAssignmentOption === 'left') {
          return true;
        }
        return false;
      }

      if (axisAssignmentOption === 'none') {
        return true;
      }

      const compositeUnit = this.findCompositeUnit(axisAssignmentOption);
      switch (compositeUnit?.composition_category) {
        case 'unknown':
          return this.compatibleUnits.unknown.notations.includes(this.notation.id);
        case 'value':
          if (compositeUnit.currency_unit_id !== null) {
            return this.compatibleUnits.value.notations.includes(this.notation.id);
          }
          return false;

        case 'volume':
          if (compositeUnit.volume_unit_id !== null) {
            return this.compatibleUnits?.volume
              .notations_per_volume_unit_categories[compositeUnit.volume_unit_category]
              .includes(this.notation.id);
          }
          return false;

        case 'price':
          if (compositeUnit.volume_unit_id !== null && compositeUnit.currency_unit_id !== null) {
            return this.compatibleUnits?.price
              .notations_per_volume_unit_categories[compositeUnit.volume_unit_category]
              .includes(this.notation.id);
          }
          return false;
        default:
          return false;
      }
    },
    onRemoveNotationFromConfigClicked() {
      ModalWindowController.show('ConfirmationModal', {
        props: {
          text: this.$t('apps.chart_config.remove_notation'),
        },
        events: {
          confirm: () => this.removeNotationFromConfig(),
        },
      });
    },
    removeNotationFromConfig() {
      this.$emit('remove-notation-from-config');
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>