const QUERY_PARAM = {
  COUNTRY: 'country',
  DIMENSION: 'dimension',
  YEAR: 'year',
  CLASSIFICATION: 'classification',
  SECTOR_CLASSIFICATION: 'sector_classification',
  LIMIT: 'limit',
};

export default QUERY_PARAM;
