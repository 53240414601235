<template>
  <h2
    ref="root"
    class="title"
    :class="cssModifierClass"
    @mousemove="$emit('mousemove', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <NotationColorIcon
      class="title--icon"
      :slot-kind="slotKind"
    />
    <span
      v-if="classPrefix"
      class="title--class"
    >
      {{ classPrefix }}
    </span>
    <span class="title--name">
      {{ name }}
    </span>
  </h2>
</template>

<script>
import NotationColorIcon from '@/components/NotationColorIcon/Component.vue';
import { configureClassPrefix } from '@/helpers/ClassPrefixHelpers';

export default {
  name: 'NotationDetailsTitle',
  components: {
    NotationColorIcon,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    classSystem: {
      type: String,
      default: null,
    },
    classCode: {
      type: String,
      default: null,
    },
    slotKind: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['mousemove', 'mouseleave'],
  data() {
    return {
      availableWidth: 0,
      resizeObserver: new ResizeObserver(this.updateAvailableWidth),
    };
  },
  computed: {
    notationCharacterCount() {
      return (this.classSystem || '').length
           + (this.classCode || '').length
           + this.name.length;
    },
    cssModifierClass() {
      const charsPerWidth = this.notationCharacterCount / this.availableWidth;
      if (charsPerWidth > 0.26) { // rough estimate
        return 'has-small-font';
      }
      if (charsPerWidth > 0.24) { // rough estimate
        return 'has-tight-letter-spacing';
      }
      return null;
    },
    classPrefix() {
      return configureClassPrefix(
        this.classSystem,
        this.classCode,
      );
    },
  },
  mounted() {
    this.updateAvailableWidth();
    this.resizeObserver.observe(this.$refs.root);
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  methods: {
    updateAvailableWidth() {
      const elem = this.$refs.root;
      this.availableWidth = elem.clientWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>