import axios from 'axios';
import { reactive } from 'vue';

class ProductGroupReportStateController {
  constructor() {
    this.reset();
  }

  reset() {
    this._loadingRequestId = null;
    this._state = reactive({
      readVersionTimestamps: {},
      isLoading: false,
    });
  }

  isReportRead(report) {
    this._lazyLoadData();
    if (this._state.isLoading) return true;
    const readTimestamp = this._state.readVersionTimestamps[report.id];
    return readTimestamp && readTimestamp === report.version_timestamp;
  }

  markReportRead(report) {
    this._state.readVersionTimestamps[report.id] = report.version_timestamp;
  }

  async _lazyLoadData() {
    if (this._loadingRequestId) return;
    this._state.isLoading = true;
    const requestId = `${new Date()}-${Math.random()}`;
    this._loadingRequestId = requestId;
    const response = await axios.get('/api/user/product_group_report_states');
    if (this._loadingRequestId !== requestId) return;
    response.data.forEach((state) => {
      this._updateReadVersionTimestampIfNewer(state.report_id, state.read_version_timestamp);
    });
    this._state.isLoading = false;
  }

  // Keep read version timestamps that are newer than those returned from API,
  // in case a user with slow internet already opened a report before the state
  // response is there.
  // In that case the read version will have been updated already and we do not
  // want to reset it back to a previous version from the API.
  _updateReadVersionTimestampIfNewer(reportId, updatedTimestamp) {
    const oldValue = this._state.readVersionTimestamps[reportId] || '0000-00-00';
    if (updatedTimestamp > oldValue) {
      this._state.readVersionTimestamps[reportId] = updatedTimestamp;
    }
  }
}

const singletonInstance = new ProductGroupReportStateController();

document.addEventListener('turbolinks:load', () => {
  singletonInstance.reset();
});

export default singletonInstance;
