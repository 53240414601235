<template>
  <div
    ref="root"
    class="data-table--head-popup"
    :class="extraClasses"
  >
    <slot />
  </div>
</template>

<script>

const POPUP_MAX_HEIGHT = 450;
const FOOTER_HEIGHT = 50;

export default {
  name: 'TableHeadPopup',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUpwards: false,
      isDownwards: false,
    };
  },
  computed: {
    extraClasses() {
      return {
        'is-open': this.isOpen,
        'is-upwards': this.isUpwards,
        'is-downwards': this.isDownwards,
      };
    },
  },
  watch: {
    isOpen() {
      const top = this.$refs.root.parentElement.getBoundingClientRect().y;
      const windowHeight = window.innerHeight;
      this.isDownwards = windowHeight - top < (POPUP_MAX_HEIGHT + FOOTER_HEIGHT);
      const zIndex = (this.isOpen && this.isDownwards) ? 50 : null;
      this.$refs.root.parentElement.style.zIndex = zIndex;
    },
  },
};
</script>
