<template>
  <tbody
    class="data-table--body"
    :class="getSelectionClass(controller.selection)"
  >
    <TableRow
      v-for="(notation, index) in controller.notations"
      :key="`${notation.sub_term || notation.dashboard_id}-${notation.id}`"
      :exclude-from-print="!controller.isNotationVisibleForPrint(notation)"
      :model="model"
      :hidden-rows-model="hiddenRowsModel"
      :notation="notation"
      :hidden-rows="controller.hiddenRows ? controller.hiddenRows[index] : []"
      :is-selected="isSelected(notation)"
      :is-selected-for-comparison="isSelectedForComparison(notation)"
      :can-click="controller.selection.canSelect"
      :index="index"
      @select="controller.selectNotation(notation)"
    />
  </tbody>
</template>

<script>
import TableRow from './TableRow.vue';

export default {
  name: 'TableBody',
  components: {
    TableRow,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    hiddenRowsModel: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    isSelected(item) {
      return this.controller.selection?.primary?.notation
          && this.controller.selection?.primary?.notation?.id === item.id;
    },
    isSelectedForComparison(item) {
      return this.controller.selection?.hasSecondarySelection
          && this.controller.selection?.secondary?.notation?.id === item.id;
    },
    getSelectionClass(selection) {
      if (selection) {
        return `is-selecting-${selection.selectionMode}`;
      }
      return '';
    },
  },
};
</script>
