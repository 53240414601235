<template>
  <div
    class="form--box"
    @focus="$refs.input.focus()"
  >
    <label
      v-if="label"
      class="form--label"
      :for="uid"
    >
      {{ label }}
    </label>
    <input
      :id="uid"
      ref="input"
      v-focus="autofocus"
      class="form--field"
      :value="modelValue"
      :placeholder="placeholder"
      :type="type"
      :name="name"
      :required="required"
      @input="onInput"
      @focus="$emit('focus', $event)"
    >
  </div>
</template>

<script>
import HasUid from '@/mixins/HasUid';

export default {
  name: 'TextInput',
  mixins: [HasUid],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'focus',
    'input',
  ],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onInput(event) {
      this.$emit('input', event);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
