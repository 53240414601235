/**
 * Is Type Number
 * @description Determines whether the input is a valid number.
 * @param {any} param - The input parameter to check for number type.
 * @returns {boolean} Whether the input is a valid number.
 *
 * @example
 * const isNumber = isTypeNumber('10');
 * console.log(isNumber); // true
 */
export const isTypeNumber = (param) => {
  const currentElement = Number.isNaN(parseFloat(param));
  return currentElement !== true;
};
