import axios from 'axios';
import { exportFromResponse } from '@/helpers/ExportHelpers';

const getExcelFromAPI = (params) => axios.get('/api/human_rights/commodity_risk_map.xlsx', params &&  {
  responseType: 'blob',
  params: {
    country_of_demand_id: params.country_of_demand_id,
    classification_id: params.classification_id,
    limit: params.limit,
    year: params.year,
  },
}).then((response) => exportFromResponse(response));

class CommodityAppApi {
  async getExcelFile(params) {
    return getExcelFromAPI(params);
  }
}

export default CommodityAppApi;
