<template>
  <div class="metadata">
    <span
      v-for="item in metadata"
      :key="item.value.name"
      class="metadata--item"
      :aria-label="item.value.name"
    >
      {{ item.value.value }}
    </span>
  </div>
</template>

<script>
import moment from 'moment';
import { computed } from 'vue';
import i18n from '@/config/i18n';

export default {
  name: 'PublicationListItemMetadata',
  props: {
    publishedAt: {
      type: [Date, String],
      default: null,
    },
    regions: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const publishedAt = computed(() => ({
      name: i18n.t('attributes.publication.published_at'),
      value: props.publishedAt ? moment(props.publishedAt).format('ll') : null,
    }));
    const regions = computed(() => ({
      name: i18n.t('attributes.publication.regions'),
      value: props.regions.map((region) => region.name).join(', '),
    }));
    const fileName = computed(() => ({
      name: i18n.t('attributes.publication.file_name'),
      value: props.fileName,
    }));
    const language = computed(() => ({
      name: i18n.t('attributes.publication.locale'),
      value: props.locale,
    }));
    const metadata = computed(() => [
      publishedAt,
      regions,
      fileName,
      language,
    ].filter((item) => !!item.value.value));

    return { metadata };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>