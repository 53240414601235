<template>
  <div class="dashboard-search-footer">
    <p class="dashboard-search-footer--text">
      <template v-if="hasError">
        {{ $t('components.dashboard_search_footer.error') }}
      </template>
      <template v-else-if="isSubmitted">
        {{ $t('components.dashboard_search_footer.success') }}
      </template>
      <template v-else>
        {{ $t('components.dashboard_search_footer.prompt') }}
        <button
          class="dashboard-search-footer--button"
          @click="submit"
        >
          {{ $t('components.dashboard_search_footer.prompt_button') }}
        </button>
      </template>
    </p>
  </div>
</template>

<script>
import LogApi from '@/api/LogApi';

export default {
  name: 'DashboardSearchFooter',
  props: {
    selectedTags: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSubmitted: false,
      hasError: false,
    };
  },
  watch: {
    selectedTags() { this.reset(); },
    searchText() { this.reset(); },
  },
  methods: {
    reset() {
      this.isSubmitted = false;
      this.hasError = false;
    },
    submit() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      LogApi.onDashboardSearchSuggested(this.searchText, this.selectedTags)
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>