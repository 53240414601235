<template>
  <div class="notice">
    <Icon
      class="notice--icon"
      :name="icon"
      :variant="iconVariant"
    />
    <p class="notice--text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DashboardAndClusterListNotice',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'info-circle',
    },
    iconVariant: {
      type: String,
      default: 'light',
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>