<template>
  <div class="trade-flow--option-list">
    <div class="trade-flow--option">
      <label
        id="reporter-label"
        class="trade-flow--reporter-label"
      >
        {{ $t('components.trade_flows.reporting_country_label') }}
      </label>
      <div>
        <AutoComplete
          class="trade-flow--reporter-combobox"
          aria-labelledby="reporter-label"
          :model-value="controller.currentReporterId"
          :options="reportingCountriesWithKeyValue"
          @update:modelValue="onReporterChanged"
        />
      </div>
    </div>

    <div class="trade-flow--option stretch">
      <span class="trade-flow--reporter-label">&nbsp;</span>
      <SurplusOrDeficitLabel
        :country="controller.currentReporter"
        :world-data="worldData"
        :classification="controller.classification"
      />
    </div>

    <div class="trade-flow--option">
      <label
        id="additional-country-label"
        class="trade-flow--reporter-label"
      >
        {{ $t('components.trade_flows.additional_country_label') }}
      </label>
      <AutoComplete
        class="trade-flow--country-combobox"
        aria-labelledby="additional-country-label"
        :model-value="controller.currentPartnerId"
        :options="availablePartnerCountriesWithKeyValue"
        :allow-empty-value="true"
        @update:modelValue="onPartnerChanged"
      />
    </div>
  </div>
</template>

<script>
import SurplusOrDeficitLabel from './SurplusOrDeficitLabel/Component.vue';

export default {
  name: 'TradeFlowsForCountryHeader',
  components: {
    SurplusOrDeficitLabel,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    worldData: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['change-reporter'],
  computed: {
    availablePartnerCountriesWithKeyValue() {
      return this.controller.partnerCountries
        .map((c) => ({ key: c.id, value: c.name }));
    },
    reportingCountriesWithKeyValue() {
      return this.controller.reportingCountries
        .map((c) => ({ key: c.id, value: c.name }));
    },
  },
  watch: {
    'controller.currentReporter': {
      immediate: true,
      handler(reporter) {
        this.$emit('change-reporter', reporter);
      },
    },
  },
  methods: {
    onReporterChanged(id) {
      this.controller.setCurrentReporterById(id);
    },
    onPartnerChanged(id) {
      this.controller.setCurrentPartnerById(id);
    },
  },
};
</script>
