<template>
  <div class="chart-config-table--header">
    <h1 class="chart-config-table--title">
      {{ $t('apps.season_line_chart_config.notation') }}
    </h1>
    <ButtonComponent
      variant="primary"
      :icon="icon"
      :disabled="selectNotationButtonDisabled"
      @click="onClickedChangeNotation"
    >
      {{ selectNotationButtonTitle }}
    </ButtonComponent>
  </div>
  <div class="chart-config-table">
    <ChartNotationAssignmentTableHead />
    <ul
      v-for="notation in notations"
      :key="notation.id"
    >
      <ChartNotationAssignmentTableRow
        :notation="notation"
        :only-left-axis-compatible="onlyLeftAxisAssignable"
        :compatible-units="compatibleUnits"
        :left-axis-composite-unit="leftAxisCompositeUnit"
        :right-axis-composite-unit="rightAxisCompositeUnit"
        :units="units"
        @update:axis-assignment-option="(assignedAxis) => $emit('update:assignedAxisForNotation', {
          notation_id: notation.id,
          axis: assignedAxis,
          color: notation.color
        })"
        @remove-notation-from-config="() => $emit(
          'remove-notation-from-config', {
            notationID: notation.id,
            axis: notation.axis,
          }
        )"
      />
    </ul>
  </div>
</template>

<script>
import ModalWindowController from '@/controllers/ModalWindowController';
import i18n from '@/config/i18n';
import ChartNotationAssignmentTableHead from './ChartNotationAssignmentTableHead/Component.vue';
import ChartNotationAssignmentTableRow from './ChartNotationAssignmentTableRow/Component.vue';

export default {
  name: 'ChartNotationAssignmentTable',
  components: {
    ChartNotationAssignmentTableHead,
    ChartNotationAssignmentTableRow,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    notations: {
      type: Array,
      required: true,
    },
    compatibleUnits: {
      type: Object,
      default: null,
    },
    leftAxisCompositeUnit: {
      type: Object,
      default: null,
    },
    rightAxisCompositeUnit: {
      type: Object,
      default: null,
    },
    units: {
      type: Object,
      default: null,
    },
    onlyLeftAxisAssignable: {
      type: Boolean,
      default: false,
    },
    dashboardIdOfLastAddedNotation: {
      type: Number,
      default: null,
    },
    maxNotationsCount: {
      type: Number,
      default: 5,
    },
  },
  emits: [
    'remove-notation-from-config',
    'update-notations',
    'update:assignedAxisForNotation'],
  computed: {
    notationsCount() {
      return this.notations.length;
    },
    selectNotationButtonTitle() {
      if (this.maxNotationsCount > 1) {
        return i18n.t('apps.chart_config.add_notation');
      }
      return this.notations.length === 0
        ? i18n.t('apps.season_line_chart_config.select_notation')
        : i18n.t('apps.season_line_chart_config.change_notation');
    },
    selectNotationButtonDisabled() {
      return this.maxNotationsCount > 1 && this.notationsCount >= this.maxNotationsCount;
    },
    icon() {
      return this.maxNotationsCount > 1 ? 'plus' : 'pen';
    },
  },
  methods: {
    onClickedChangeNotation() {
      ModalWindowController.show('AddNotationsToChartConfigModal', {
        props: {
          user: this.user,
          dashboardIdOfLastAddedNotation: this.dashboardIdOfLastAddedNotation,
          notationsCount: this.notationsCount,
          maxNotationsCount: this.maxNotationsCount,
        },
        events: {
          close: () => { ModalWindowController.close(); },
          'add-to-config': (changeNotations) => {
            this.$emit('update-notations', changeNotations);
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>