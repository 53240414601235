<template>
  <div class="chart-view-header">
    <h1 class="chart-view--title">
      {{ title }}
    </h1>
    <div class="chart-view--buttons">
      <ButtonComponent
        v-if="showKpis !== null"
        :icon="showKpis? 'toggle-on' : 'toggle-off'"
        :variant="showKpis ? 'secondary' : 'ternary'"
        :is-toggled="showKpis"
        @click="() => $emit('show-kpis', !showKpis)"
      >
        {{ $t('apps.multi_line_chart_view.show_kpis') }}
      </ButtonComponent>
      <ButtonComponent
        class="chart-view--action"
        icon="save"
        variant="primary"
        :disabled="saveConfigDisabled || isSaving"
        @click="onSaveAnalysis()"
      >
        {{ saveButtonText }}
      </ButtonComponent>
      <Teaser v-if="showKpis" scope="export" class="chart-view--action">
        <ButtonWithDropdown
          variant="primary"
          icon="caret-down"
        >
          {{ $t('components.generic.pdf_export_button.dropdown_label') }}
          <template #dropdown>
            <DropdownMenu>
              <DropdownMenuButton
                icon="chart-line"
                icon-variant="light"
                :disabled="isDownloading"
                :closes-popup="false"
                @click="onPdfExportClick(true)"
              >
                {{ exportInternalButton }}
              </DropdownMenuButton>
              <DropdownMenuButton
                icon="chart-line"
                icon-variant="light"
                :disabled="isDownloading"
                :closes-popup="false"
                @click="onPdfExportClick(false)"
              >
                {{ exportExternalButton }}
              </DropdownMenuButton>
            </DropdownMenu>
          </template>
        </ButtonWithDropdown>
      </Teaser>
      <PdfExportButtonComponent
        v-else
        class="chart-view--action"
      />
      <ButtonComponent
        class="chart-view--action"
        icon="cog"
        variant="primary"
        @click="openSettings()"
      >
        {{ $t('apps.multi_line_chart_view.settings') }}
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import Turbolinks from 'turbolinks';
import i18n from '@/config/i18n';
import ModalWindowController from '@/controllers/ModalWindowController';
import PdfExportController from '@/components/generic/PdfExportButton/Controller';

export default {
  name: 'ChartViewHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    savedAnalysisId: {
      type: Number,
      default: null,
    },
    saveConfigDisabled: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    configUrl: {
      type: String,
      required: true,
    },
    showKpis: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['save', 'show-kpis'],
  computed: {
    saveButtonText() {
      return this.savedAnalysisId === null ? i18n.t('apps.multi_line_chart_view.save') : i18n.t('apps.multi_line_chart_view.update');
    },
    isDownloading() {
      return PdfExportController.inProgress;
    },
    exportInternalButton() {
      return this.isDownloading ? this.$t('components.generic.pdf_export_button.in_progress_label') : this.$t('components.generic.pdf_export_button.for_internal_use');
    },
    exportExternalButton() {
      return this.isDownloading ? this.$t('components.generic.pdf_export_button.in_progress_label') : this.$t('components.generic.pdf_export_button.for_external_use');
    },
  },
  methods: {
    openSettings() {
      Turbolinks.visit(this.configUrl);
    },
    onSaveAnalysis() {
      if (this.savedAnalysisId === null) {
        ModalWindowController.show('NotationAnalysisNamePromptModal', {
          props: {
            initialTitle: this.config.title,
          },
          events: {
            save: (title) => {
              this.$emit('save', title);
            },
          },
        });
      } else {
        this.$emit('save', null);
      }
    },
    onPdfExportClick(forInternalUse = true) {
      const path = forInternalUse ? '' : `${window.location.pathname}?${this.forExternalPdfExport()}`;
      PdfExportController.exportAsPdf(path);
    },
    forExternalPdfExport() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('for_internal_use', '0');
      return searchParams.toString();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>