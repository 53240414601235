<template>
  <div
    class="dialog--window is-large"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ title }}
      </div>
      <div class="dashboard-header--buttons">
        <DatePickerButton
          class="dashboard-header--button"
          :comparison-date="comparisonDate"
          y-alignment="bottom"
          @update:comparisonDate="setSelectedDate"
        />
        <div class="search-header--action-list-item dashboard-header--button">
          <ButtonWithDropdown
            variant="primary"
            icon="caret-down"
          >
            {{ $t('components.notation.buttons.dashboard_overview_export.label') }}

            <template #dropdown>
              <DropdownMenu>
                <DropdownMenuButton
                  icon="chart-line"
                  icon-variant="light"
                  :disabled="isDownloading"
                  :closes-popup="false"
                  @click="exportDashboardOverview(true)"
                >
                  {{ exportButtonText(true) }}
                </DropdownMenuButton>
                <DropdownMenuButton
                  icon="chart-line"
                  icon-variant="light"
                  :disabled="isDownloading"
                  :closes-popup="false"
                  @click="exportDashboardOverview(false)"
                >
                  {{ exportButtonText(false) }}
                </DropdownMenuButton>
              </DropdownMenu>
            </template>
          </ButtonWithDropdown>
        </div>
        <ButtonComponent
          class="dashboard-header--close-button"
          icon="times"
          variant="ternary"
          :aria-label="$t('components.notation.buttons.close')"
          @click="close()"
        />
      </div>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <div class="dashboard-header--main-box">
          <NotationCardOverview
            :primary-notation-details="primaryNotationDetails"
            :secondary-notation-details="secondaryNotationDetails"
            :selected-date="comparisonDate"
            :selected-graph-limits="selectedGraphLimits"
            :title="title"
            @update:selectedGraphLimits="$emit('update:selectedGraphLimits', $event)"
            @change-date="setSelectedDate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotationCardOverview from '@/components/NotationCardOverview/Component.vue';
import { DatePickerButton } from '@/components';
import { ref } from 'vue';
import { exportFromPath } from '@/helpers/ExportHelpers';
import UrlStateStore, { serializeDateForUrl, serializeDatesForUrl } from '@/services/UrlStateStore';
import i18n from '@/config/i18n';
import DropdownMenu from '../generic/DropdownMenu/Component.vue';

export default {
  name: 'DashboardHeaderModal',
  components: {
    DropdownMenu,
    NotationCardOverview,
    DatePickerButton,
  },
  props: {
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    selectedGraphLimits: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    selectedCurrencyId: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['change-date', 'update:selectedGraphLimits', 'close'],
  setup(_props, { emit }) {
    const isDownloading = ref(false);
    const comparisonDate = ref(_props.selectedDate);
    const setSelectedDate = (date) => {
      comparisonDate.value = date;
      emit('change-date', date);
    };
    const close = () => {
      emit('close');
    };
    return {
      setSelectedDate,
      comparisonDate,
      isDownloading,
      close,
    };
  },
  methods: {
    exportButtonText(forInternalUse) {
      if (this.isDownloading) {
        return i18n.t('components.notation.buttons.dashboard_overview_downloading');
      }
      return forInternalUse
        ? i18n.t('components.notation.buttons.dashboard_overview_export.for_internal_use')
        : i18n.t('components.notation.buttons.dashboard_overview_export.for_external_use');
    },
    dashboardOverviewPath(forInternalUse) {
      const config = {
        selectedDate: serializeDateForUrl(this.comparisonDate),
        graphLimits: serializeDatesForUrl(this.selectedGraphLimits),
        secondaryNotationId: this.secondaryNotationDetails?.notation
          ? this.secondaryNotationDetails.notation.id
          : null,
        primaryNotationId: this.primaryNotationDetails.notation.id,
        selectedCurrencyId: this.selectedCurrencyId,
        forInternalUse,
      };
      return `/dashboard-overview-export${UrlStateStore.queryStringForState({ config })}&high_width=${this.comparisonDate !== null}`;
    },
    async exportDashboardOverview(forInternalUse) {
      try {
        this.isDownloading = true;
        await exportFromPath(this.dashboardOverviewPath(forInternalUse));
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>