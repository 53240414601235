<template>
  <div class="top-five-table-heading">
    <div class="top-five-table-heading__main">
      <div class="top-five-table-heading__filter-wrapper">
        <div class="top-five-filter">
          <ComboBox2
            v-model="controller.selectedDimension"
            class="top-five-filter__combo-box"
            :description="t('apps.intro.human_rights_risks.dropdown.dimensions')"
            :options="dimensions"
          />
          <AutoComplete
            v-model="controller.selectedCountry"
            :options="countries"
            :access-key="(c) => c.value"
            :access-value="(c) => c.label"
            :description="t('apps.intro.human_rights_risks.dropdown.countries')"
          />
          <ComboBox2
            v-model="controller.selectedYear"
            :description="t('apps.intro.human_rights_risks.dropdown.years')"
            :options="yearsForSelect"
          />
        </div>
      </div>
      <div class="top-five-table-heading__legend">
        <HumanRightsRiskLegend :wrapper-classes="'top-five-table-heading__legend-item'" />
      </div>
    </div>
  </div>
  <div class="top-five-table-heading__table-headline">
    <div class="top-five-table-heading__dynamic-headline">
      <h1>{{ tableHeading }}</h1>
      <IconTooltipComponent
        :tooltip="tableHeadingTooltip"
        :extra-wide="true"
      />
    </div>
    <HumanRightsRisksExportButtonComponent
      v-exclude-from-print="true"
      :controller="controller"
    />
  </div>
  <div class="top-five-table">
    <NotationList :sticky-margin-zero-top="true" :controller="controller" />
  </div>
  <p>
    {{ t("apps.human_rights.data_hint") }}
  </p>
</template>

<script setup>
import NotationList from "@/components/NotationList/NotationList.vue";
import { onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import HumanRightsRiskLegend from "@/components/HumanRightsRisk/HumanRightsRiskLegend/Component.vue";
import HumanRightsRisksExportButtonComponent from "../HumanRightsRisksExportButton/Component.vue";
import { ComboBox2 } from "@/components/generic";
import StorageService from "../../../../services/StorageService/StorageService";
import { KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS } from "../../../../services/StorageService/StorageConst";
import humanRightsRisksTableKey from "../../constants/human-rights-risks-table-keys";
import { WORLD_VALUE } from "../../constants/world-value";


const { t } = useI18n();

const { countries, controller, dimensions, yearsForSelect } = defineProps({
  countries: Array,
  dimensions: Array,
  controller: Object,
  yearsForSelect: Array,
});

const storageService = new StorageService();

watch(
  () => controller.selectedCountry,
  () => {
    controller.setURLSearchParam('country', controller.selectedCountry);
    storageService.merge(KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS, { country: controller.selectedCountry });

    if (controller.selectedCountry === WORLD_VALUE) {
      controller.toggleColumnVisible(humanRightsRisksTableKey.balance, false);
    } else {
      controller.toggleColumnVisible(humanRightsRisksTableKey.balance, true);
    }
    controller.fetchTopCountries();
    controller.updateColumnsTitle(countries);
  }
);

watch(
  () => controller.selectedDimension,
  () => {
    controller.setURLSearchParam('dimension', controller.selectedDimension.value);
    storageService.merge(KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS, { dimension: controller.selectedDimension.value });
  },
);

watch(
  () => controller.selectedYear,
  () => {
    controller.setURLSearchParam('year', controller.selectedYear.value);
    storageService.merge(KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS, { year: controller.selectedYear.value });
    controller.fetchTopCountries();
  }
);

const tableHeading = computed(() =>
  controller.selectedCountry === WORLD_VALUE
    ? t("apps.intro.human_rights_risks.table_heading.exporter")
    : t("apps.intro.human_rights_risks.table_heading.importer", {
        country: countries.find((c) => c.value === controller.selectedCountry).label,
      })
);

const tableHeadingTooltip = computed(() =>
  controller.selectedCountry === WORLD_VALUE
    ? t("apps.intro.human_rights_risks.table_heading_tooltip.exporter")
    : t("apps.intro.human_rights_risks.table_heading_tooltip.importer")
);

const getDefaultValuesForSelectFields = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const localStorageTableEntries = controller.getTableFilterSearchParamsFromCookie();

  const dimensionCookieEntry = localStorageTableEntries?.dimension || undefined;
  const countryCookieEntry = localStorageTableEntries?.country || undefined;
  const yearCookieEntry = localStorageTableEntries?.year || undefined;

  const dimension = searchParams.get('dimension') || dimensionCookieEntry;
  const year =  searchParams.get('year') || yearCookieEntry;
  const country = searchParams.get('country') || countryCookieEntry;

  const selectedYear = yearsForSelect.find((currentYear) => currentYear.value === Number(year));
  const selectedCountry = countries.find((currentCountry) => currentCountry.value === Number(country));

  const currentDefaultValues = {
    ...(selectedCountry !== undefined && { country: selectedCountry?.value }),
    ...(dimension !== null && { dimension: dimensions[dimension] }),
    ...(selectedYear !== undefined && { year: selectedYear }),
  };

  return currentDefaultValues;
};

const setDefaultValuesFromUrl = () => {
  controller.setDefaultSelectValues(getDefaultValuesForSelectFields());
};

const extractProxyValues = (proxyElements) => {
  const transformedValues = Object.entries(proxyElements).map((value) => {
    if (value[1]?.value) {
      return [value[0], value[1].value];
    }
    return value;
  });
  return Object.fromEntries(transformedValues);
};

const setDefaultValuesToUrl = () => {
  const currentDefaultValues = getDefaultValuesForSelectFields();
  controller.setManySearchParams(extractProxyValues(currentDefaultValues));
};

onMounted(() => {
  setDefaultValuesFromUrl();
  setDefaultValuesToUrl();
  controller.fetchTopCountries();
});

</script>

<style lang="scss">
.top-five {
  &-filter {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    &__combo-box {
      min-width: 200px;
    }
  }

  &-table {
    margin-bottom: 20px;

    &-heading {
      margin-bottom: 20px;
      justify-content: space-between;
      &__main {
        display: flex;
        flex-direction: column;
      }
      &__filter-wrapper {
        flex-grow: 1;
      }
      & h1 {
        font-weight: 700;
      }
      &__legend {
        flex-grow: 1;
      }

      &__table-headline{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-block: 8px;
      }

      &__dynamic-headline {
        display: flex;
        align-items: center;

        h1 {
          margin-right: 10px;
        }
      }
    }

    &__additional-buttonbar {
      margin-bottom: 5px;
    }

    [data-column-name~="weighted_hr_scores"]{
       @media only print {
         max-width: 100px;

         button {
          white-space: normal;
         }
       }
    }
  }
}

@media print {
  .top-five-table {
    .data-table--head-cell {
      padding: 2mm 0;
    }
    .data-table--body-cell {
      padding: 2mm 0;
    }
    .with-tooltip:has(.portrait-link,.map-link) {
      display: none;
    }
    .data-table--head-cell:not(:nth-child(2),:nth-child(3)) {
      text-align: center;
    }
  }
  .combobox--default-button-label,.combobox--input {
    text-align: left;
    font-size: unset;
  }
}
</style>
