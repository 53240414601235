import { ref } from 'vue';
import { EventBus } from '../../../events';

const selected = ref(null);
const eventBus = new EventBus();

export const useTreeComboBoxState = () => ({
  selected,
  eventBus,
});
