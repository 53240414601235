import moment from 'moment';
import i18n from '../config/i18n';

window.moment = moment;

// Using ISO-Week (WW) and ISO-Week-Year (GGGG) instead of localized week definition (ww and gggg),
// to be independent of locale for this. The ISO Definition is what is used in Germany.
const formatWeekly = (date) => moment(date).format(`[${i18n.t('formatting.calendar_week_abbr')}] WW GGGG`);

const formatBiWeekly = (date) => {
  const thisWeek = moment(date);
  const nextWeek = moment(date).add(moment.duration(1, 'w'));

  const formattedWeeks = `${thisWeek.format('WW')}/${nextWeek.format('WW')}`;
  let formattedYears = thisWeek.format('GGGG');
  if (thisWeek.isoWeekYear() !== nextWeek.isoWeekYear()) {
    formattedYears += `/${nextWeek.format('GG')}`;
  }

  return `${i18n.t('formatting.calendar_week_abbr')} ${formattedWeeks} ${formattedYears}`;
};

const formatCompactDateWithTimescope = (date, timescope) => {
  if (!date) return 'N/A';
  switch (timescope) {
    case 'yearly': return moment(date).format('YYYY');
    case 'quarterly': return moment(date).format('[Q]Q YYYY');
    case 'monthly': return moment(date).format('MMM YYYY');
    case 'biweekly': return formatBiWeekly(date);
    case 'weekly': return formatWeekly(date);
    default: return moment(date).format('ll');
  }
};

const formatDateWithTimescope = (date, timescope) => {
  if (!date) return 'N/A';
  switch (timescope) {
    case 'yearly': return moment(date).format('YYYY');
    case 'quarterly': return moment(date).format('[Q]Q YYYY');
    case 'monthly': return moment(date).format('MMMM YYYY');
    case 'biweekly': return formatBiWeekly(date);
    case 'weekly': return formatWeekly(date);
    default: return moment(date).format('LL');
  }
};

export {
  formatDateWithTimescope,
  formatCompactDateWithTimescope,
};
