import { reactive } from 'vue';
import { EventBus, DashboardGroupFavoriteChangeEvent, DashboardFavoriteChangeEvent } from '@/events';
import DashboardApi from '@/api/DashboardApi';
import UrlStateStore, { setAllQueriesAsParam, setQueryParam } from '../../services/UrlStateStore';
import IntroAppApi from './IntroAppApi';
import StorageService from '../../services/StorageService/StorageService';
import { KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS } from '../../services/StorageService/StorageConst';
import humanRightsRisksTableKey from './constants/human-rights-risks-table-keys';
import { WORLD_VALUE } from './constants/world-value';
import QUERY_PARAM from './constants/commodity-query-params';
import importExportValuesTooltipContent from "@/components/HumanRightsRisk/ImportExportValuesTooltipContent"

const SORT_ASC = 'asc';
const SORT_DESC = 'desc';

/*
* Create visibleColumns property, to keep table model
* consistency regarding to main model and components
* */
class HumanRightsRisksController {
  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filters() {
    return this._state.filters;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    return this._state.selection;
  }

  get notations() {
    return this._state.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    return this._state.notationListModel;
  }

  get selectedCountry() {
    return this._state.selectedCountry;
  }

  set selectedCountry(value) {
    this._state.selectedCountry = value;
  }

  get selectedDimension() {
    return this._state.selectedDimension;
  }

  set selectedDimension(value) {
    this._state.selectedDimension = value;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  get selectedCountryHrScores() {
    return this._state.selectedCountryHrScores;
  }

  set selectedCountryHrScores(value) {
    this._state.selectedCountryHrScores = value;
  }

  get selectedYear() {
    return this._state.selectedYear;
  }

  set selectedYear(value) {
    this._state.selectedYear = value;
  }

  /**
   * TODO: Remove yearsDefaultIndex when combobox gets refactored
   */
  constructor(countries, dimensions, years, translator, yearsDefault, commodityRiskMapPath) {
    const storage = new StorageService();
    const tableFilters = storage.get(KEY_HUMAN_RIGHTS_RISKS_TABLE_FILTERS);

    const currentCountry = tableFilters?.country ?? countries.find((c) => c.value === WORLD_VALUE).value;
    const eventBus = new EventBus("intro-app");
    this._notationsUnsorted = [];
    this._selectedCountryHrScores = null;
    this._countries = countries;
    this._removedDashboards = new Map();

    this.translator = translator;
    this._api = new IntroAppApi();

    this._columns = [
      {
        key: humanRightsRisksTableKey.favorite,
        visible: true,
        displayName: "",
        type: "icon",
        valueBuilder: (notation) => ({
          name: "star",
          variant: notation.favorite ? "solid" : "regular",
          modifiers: ["for-dashboard-favorite"],
        }),
        eventListenerBuilder: (notation) => ({
          click: async () => {
            if (notation.favorite) {
              await this._removeDashboardFromFavorites(notation);
            } else {
              await this._favoriseRemovedDashboards(notation);
            }

            this._toggleDashboardFavoriteIcon(notation.id);
            eventBus.emit(new DashboardGroupFavoriteChangeEvent());
          },
        }),
      },
      {
        key: humanRightsRisksTableKey.name,
        visible: true,
        displayName: "Name",
        type: "linkWithMapLink",
        valueBuilder: (notation) => ({
          text: notation.name,
          tooltip: notation.tooltip_text,
          url: notation.dashboard_path,
          mapUrl: notation.commodity_risks_link,
          portraitUrl: notation.country_portraits_link,
        }),
      },
      {
        key: humanRightsRisksTableKey.sectorScores,
        visible: true,
        classes: "data-table--head-cell-title-wrapper",
        displayName: this.translator(`apps.intro.human_rights_risks.table_column.sector_score`),
        filterOptions: "sort",
        type: "totalHrScoresColumn",
        valueBuilder: (notation) => {
          if (!notation.sector) { return }

          return {
            total: notation.sector.scores.find(
              (sectorScore) => sectorScore.key === this.selectedDimension.value
            ),
            dimensions: notation.sector.scores,
            title: `${notation.sector.code} | ${notation.sector.short_name}`,
            withTooltip: true,
            withFlagIcon: this.selectedDimension.value === 0,
          }
        }
      },
      {
        key: humanRightsRisksTableKey.balance,
        visible: currentCountry !== WORLD_VALUE,
        displayName: this.translator("apps.intro.human_rights_risks.table_column.country_of_demand", { country: this.getCountryItem(currentCountry, countries).label }),
        type: "countryOnDemand",
        valueBuilder: (notation) => {
          let tooltipHtml = notation.balance_text
          let additionalContent = importExportValuesTooltipContent(notation.import_export_values, translator)
          if (additionalContent) {
            tooltipHtml = `${tooltipHtml}<br>${additionalContent}`
          }

          return {
            text: notation.balance
              ? this.translator(`apps.intro.human_rights_risks.table_column.balance.${notation.balance}`)
              : "",
            tooltip: tooltipHtml,
            selectedCountryHrScores: this._selectedCountryHrScores,
            withFlagIcon: this.selectedDimension.value === 0,
          }
        },
      },
      {
        key: humanRightsRisksTableKey.allHrScores,
        visible: true,
        displayName: this.getAllHrScoresColumnTitle(currentCountry, countries),
        filterOptions: "sort",
        type: "totalHrScoresColumn",
        valueBuilder: (notation) => ({
          total: notation.weighted_hr_scores.find(
            (weightedHrScore) => weightedHrScore.key === this.selectedDimension.value
          ),
          dimensions: notation.weighted_hr_scores,
          title: this.getWeightedColumnTooltipCountryText(countries),
          withTooltip: true,
          withFlagIcon: this.selectedDimension.value === 0,
        }),
        classes: "data-table--head-cell-title-wrapper",
      },
      {
        key: humanRightsRisksTableKey.top1,
        visible: true,
        displayName: "Top 1",
        filterOptions: "sort",
        type: "topFiveCountry",
        classes: "",
      },
      {
        key: humanRightsRisksTableKey.top2,
        visible: true,
        displayName: "Top 2",
        filterOptions: "sort",
        type: "topFiveCountry",
        classes: "",
      },
      {
        key: humanRightsRisksTableKey.top3,
        visible: true,
        displayName: "Top 3",
        filterOptions: "sort",
        type: "topFiveCountry",
        classes: "",
      },
      {
        key: humanRightsRisksTableKey.top4,
        visible: true,
        displayName: "Top 4",
        filterOptions: "sort",
        type: "topFiveCountry",
        classes: "",
      },
      {
        key: humanRightsRisksTableKey.top5,
        visible: true,
        displayName: "Top 5",
        filterOptions: "sort",
        type: "topFiveCountry",
        classes: "",
      },
      {
        key: humanRightsRisksTableKey.calculatedScores,
        visible: true,
        classes: "data-table--head-cell-title-wrapper",
        displayName: this.translator("apps.intro.human_rights_risks.table_column.calculated_score"),
        filterOptions: "sort",
        type: "totalHrScoresColumn",
        tooltip: this.translator("apps.intro.human_rights_risks.tooltips.calculated_score"),
        valueBuilder: (notation) => {
          return {
            total: notation.calculated_scores.find(
              (score) => score.key === this.selectedDimension.value
            ),
            dimensions: notation.calculated_scores,
            title: this.translator("apps.intro.human_rights_risks.table_column.calculated_score"),
            withTooltip: true,
            withFlagIcon: this.selectedDimension.value === 0,
          }
        }
      },
      {
        key: humanRightsRisksTableKey.flags,
        visible: true,
        classes: "data-table--head-cell-title-wrapper",
        displayName: this.translator("apps.supply_chain.columns.flags"),
        filterOptions: "sort",
        type: "flagsColumn",
        tooltip: this.translator("apps.intro.human_rights_risks.tooltips.flags"),
        valueBuilder: (notation) => {
          return {
            flagCount: notation.flag_count || 0
          }
        }
      }
    ];

    this._state = reactive({
      selectedCountry: currentCountry,
      selectedDimension: dimensions.find((d) => d.value === tableFilters?.dimension) ?? dimensions[0],
      selectedYear: years.find((y) => y.value === tableFilters?.year) ?? yearsDefault,
      isLoading: false,
      filters: {},
      sorting: null,
      selection: {
        selectionMode: "none",
        canSelect: false,
      },
      notations: [],
      notationListModel: {
        columns: this._columns,
        visibleColumns: this._columns,
        createRow: (notation) => {
          return this._columns.map(({ key, type, eventListenerBuilder, ...column }) => ({
            key,
            type,
            ...(eventListenerBuilder && { eventListeners: eventListenerBuilder(notation) }),
            filterOptions: column.filterOptions,
            displayName: column.displayName,
            visible: this.defineColumnIsVisible(key),
            value: column.valueBuilder ? column.valueBuilder(notation, key) : notation[key],
            classes: {
              [humanRightsRisksTableKey.favorite]: "",
              [humanRightsRisksTableKey.name]: "",
              [humanRightsRisksTableKey.balance]: notation.balance === "export" ? "is-bold" : "",
              [humanRightsRisksTableKey.allHrScores]: "",
              [humanRightsRisksTableKey.top1]: notation.balance === "import" ? "is-bold" : "",
              [humanRightsRisksTableKey.top2]: notation.balance === "import" ? "is-bold" : "",
              [humanRightsRisksTableKey.top3]: notation.balance === "import" ? "is-bold" : "",
              [humanRightsRisksTableKey.top4]: notation.balance === "import" ? "is-bold" : "",
              [humanRightsRisksTableKey.top5]: notation.balance === "import" ? "is-bold" : "",
            },
            additionalData: {
              selectedDimension: this.selectedDimension.value,
            },
          }))
        }
      },
    });

    eventBus.on(DashboardFavoriteChangeEvent.EventName, () => {
      this.fetchTopCountries();
    });
  }

  _getBalancedHRScore(balancedHrScore) {
    const balancedScore = balancedHrScore.find(
      (balancedHrScore) => balancedHrScore.key === this.selectedDimension.value
    );
    return balancedScore;
  }
  getWeightedColumnTooltipCountryText(countries) {
    const countryName = this.getCountryItem(this.selectedCountry, countries).label;
    if (this.selectedCountry === WORLD_VALUE) return countryName;

    return this.translator("apps.intro.human_rights_risks.weighted_column_tooltip.country_text", {
      country: countryName,
    });
  }

  setManySearchParams(value) {
    setAllQueriesAsParam(UrlStateStore.queryString, value);
    return null;
  }

  setURLSearchParam(param, value) {
    setQueryParam(param, value);
    return null;
  }

  getTableFilterSearchParamsFromCookie() {
    const Storage = window.localStorage;
    const tableFilterItem = Storage.getItem("local::human_rights_risks-table_filters");
    return JSON.parse(tableFilterItem);
  }

  setDefaultSelectValues({ dimension, year, country }) {
    if (dimension) this.selectedDimension = dimension;
    if (year) this.selectedYear = year;
    if (country) this.selectedCountry = country;
  }

  getBalanceDependentCountryText(countries, notation) {
    const countryName = this.getCountryItem(this.selectedCountry, countries).label;

    if (notation.balance === "export") {
      return countryName;
    }
    return this.translator("apps.intro.human_rights_risks.weighted_column_tooltip.country_text", {
      country: countryName,
    });
  }

  toggleColumnVisible(key, isVisible) {
    this.visibleColumns = this.visibleColumns.map((column) => {
      if (column.key === key) {
        column.visible = isVisible;
      }
      return column;
    });
  }

  async _getExcelFileFromDashboard(params) {
    await this._api.getExcelFileFromDashboard(params);
  }

  async _removeDashboardFromFavorites(notation) {
    const dashboards = await this._api.removeDashboard(notation.remove_from_favorites_path);
    this._removedDashboards.set(notation.id, dashboards);
  }

  async _favoriseRemovedDashboards(notation) {
    if (!this._removedDashboards.has(notation.id)) return;

    const dashboards = this._removedDashboards.get(notation.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const dashboard of dashboards) {
      await DashboardApi(dashboard).addDashboardToFavorites();
    }

    this._removedDashboards.delete(notation.id);
  }

  _toggleDashboardFavoriteIcon(id) {
    this.notations = this.notations.map((notation) => {
      if (notation.id === id) {
        return {
          ...notation,
          favorite: !notation.favorite,
        };
      }

      return notation;
    });
  }

  defineColumnIsVisible(key) {
    const { balance, top1, top5, name, top4, top2, top3, allHrScores, favorite, sectorScores, calculatedScores, flags } =
      humanRightsRisksTableKey;

    const statementsMap = {
      [favorite]: true,
      [name]: true,
      [balance]: this.selectedCountry !== WORLD_VALUE,
      [allHrScores]: true,
      [sectorScores]: true,
      [calculatedScores]: true,
      [top1]: true,
      [top2]: true,
      [top3]: true,
      [top4]: true,
      [top5]: true,
      [flags]: true
    };

    return statementsMap[key];
  }

  updateColumnsTitle(countries) {
    this.columns = this.columns.map((column) => ({
      ...column,
      displayName: this.defineColumnsTitle(column.key, countries),
    }));
    this.visibleColumns = this.columns;
  }

  // TODO: Fix duplicate title definition
  defineColumnsTitle(key, countries) {
    const { favorite, balance, top1, top5, name, top4, top2, top3, allHrScores, sectorScores, calculatedScores, flags } =
      humanRightsRisksTableKey;

    const statementsMap = {
      [favorite]: "",
      [name]: "Name",
      [balance]: this.translator("apps.intro.human_rights_risks.table_column.country_of_demand", { country: this.getCountryItem(this.selectedCountry, countries).label }),
      [allHrScores]: this.getAllHrScoresColumnTitle(this.selectedCountry, countries),
      [sectorScores]: this.translator(`apps.intro.human_rights_risks.table_column.sector_score`),
      [calculatedScores]: this.translator("apps.intro.human_rights_risks.table_column.calculated_score"),
      [top1]: "Top 1",
      [top2]: "Top 2",
      [top3]: "Top 3",
      [top4]: "Top 4",
      [top5]: "Top 5",
      [flags]: this.translator("apps.supply_chain.columns.flags")
    };

    return statementsMap[key];
  }

  getAllHrScoresColumnTitle(countryValue, countries) {
    return countryValue !== WORLD_VALUE
      ? `
      ${this.translator("apps.intro.human_rights_risks.table_column.all_scores.country")} ${this.getCountryName(
          countryValue,
          countries
        )}`
      : this.translator("apps.intro.human_rights_risks.table_column.all_scores.world");
  }

  getCountryItem(countryValue, countries) {
    return countries.find((c) => c.value === countryValue);
  }

  getCountryName(countryValue, countries) {
    const countryItem = this.getCountryItem(countryValue, countries);

    if (countryItem) {
      return countryItem.label.split("|")[0].trim();
    }
    return "";
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  async fetchTopCountries() {
    this.isLoading = true;
    this.notations = [];

    const response = await this._api.topCountries({
      country: this.selectedCountry,
      year: this.selectedYear.value,
    });
    const dashboardNotations = response.dashboards.map((d) => ({ ...d, ...d.top_countries, favorite: true }));
    this.notations = [...dashboardNotations];
    this._notationsUnsorted = [...dashboardNotations];
    this._selectedCountryHrScores = response?.selected_country_hr_scores;
    this.isLoading = false;
  }

  updateNotationsAndUserState() {
    if (this.sorting) {
      this.sortColumn();
    } else {
      this.notations = [...this._notationsUnsorted];
    }
  }

  sortColumn() {
    const { direction, column: columnKey } = this.sorting;
    const getNotationValue = (notation) => {
      let value;
      switch (columnKey) {
        case humanRightsRisksTableKey.allHrScores:
          value = +notation[columnKey]?.find((dimension) => dimension.key === this.selectedDimension.value)?.value;
          break;
        case humanRightsRisksTableKey.sectorScores:
          value = +notation.sector?.scores?.find((dimension) => dimension.key === this.selectedDimension.value)?.value;
          break;
        case humanRightsRisksTableKey.calculatedScores:
          value = +notation.calculated_scores?.find((dimension) => dimension.key === this.selectedDimension.value)?.value;
          break;
        case humanRightsRisksTableKey.flags:
          value = notation.flag_count;
          break;
        default:
          value = +notation[columnKey]?.hr_scores?.find((dimension) => dimension.key === this.selectedDimension.value)
            ?.value;
          break;
      }

      return value || 0;
    };

    this.notations = this.notations.sort((a, b) => {
      const columnADimensionValue = getNotationValue(a);
      const columnBDimensionValue = getNotationValue(b);

      if (direction === SORT_ASC) {
        return columnADimensionValue - columnBDimensionValue;
      }

      return columnBDimensionValue - columnADimensionValue;
    });
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;

    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: SORT_ASC };
      return;
    }
    if (this._state?.sorting?.direction === SORT_ASC) {
      this._state.sorting.direction = SORT_DESC;
    } else {
      this._state.sorting = null;
    }
  }
}

export default HumanRightsRisksController;
