<template>
  <div
    class="dialog--window"
    :class="{'is-wide':showNotations}"
    open
  >
    <template v-if="isLoading">
      <div class="dialog--main is-loading">
        <SpinningLoader />
      </div>
    </template>
    <template v-else>
      <div class="dialog--header">
        <div class="dialog--heading">
          {{ $t('components.add_to_multi_line_chart_config_modal.title') }}
        </div>
        <StatusBreadcrumbs
          :max-notations-count="maxNotationsCount"
          :show-notations="showNotations"
          @back="onClickBack"
        />
      </div>
      <div
        v-if="!showNotations"
        class="dialog--main"
      >
        <div class="dialog--main-box">
          <SelectDashboardForm
            :show-clusters="true"
            :requires-write-permissions="false"
            @submit="onSelectDashboard"
            @cancel="onClickCancel"
          />
        </div>
      </div>
      <div
        v-else
        class="dialog--main"
      >
        <div class="dialog--main-box">
          <SelectNotationsForm
            :user="user"
            :dashboard="dashboard"
            :notations-count="notationsCount"
            :max-notations-count="maxNotationsCount"
            @submit="onAddNotation"
            @back="onClickBack"
            @cancel="onClickCancel"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import StatusBreadcrumbs from '@/components/StatusBreadcrumbs/Component.vue';
import SelectDashboardForm from '../SelectDashboardForm/Component.vue';
import SelectNotationsForm from './SelectNotationsForm/Component.vue';

export default {
  name: 'AddNotationsToChartConfigModal',
  components: {
    SelectDashboardForm,
    SelectNotationsForm,
    StatusBreadcrumbs,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    notationsCount: {
      type: Number,
      default: null,
    },
    dashboardIdOfLastAddedNotation: {
      type: [Number, null],
      required: true,
    },
    maxNotationsCount: {
      type: Number,
      default: 5,
    },
  },
  emits: [
    'add-to-config',
    'close',
  ],
  data() {
    return {
      dashboardSelection: null,
      showNotations: false,
      dashboardOfLastAddedNotation: null,
      isLoading: false,
    };
  },
  computed: {
    dashboard() {
      return this.dashboardSelection?.dashboard || this.dashboardOfLastAddedNotation;
    },
  },
  beforeMount() {
    this.fetchDashboardOfLastAddedNotation();
  },
  methods: {
    async fetchDashboardOfLastAddedNotation() {
      if (this.dashboardIdOfLastAddedNotation === null) return;
      this.isLoading = true;
      try {
        const response = await axios.get('/api/dashboards', { params: { ids: [this.dashboardIdOfLastAddedNotation] } });
        const dashboards = response.data;
        this.dashboardOfLastAddedNotation = dashboards.find((dashboard) => (
          dashboard.id === this.dashboardIdOfLastAddedNotation
        ));
        this.showNotations = this.dashboardOfLastAddedNotation !== null;
      } finally {
        this.isLoading = false;
      }
    },
    onSelectDashboard(selection) {
      this.dashboardSelection = selection;
      this.showNotations = true;
    },
    onAddNotation(addNotations) {
      this.$emit('add-to-config', addNotations);
      this.$emit('close');
    },
    onClickCancel() {
      this.$emit('close');
    },
    onClickBack() {
      this.showNotations = false;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>