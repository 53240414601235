import { reactive } from 'vue';

const DEFAULT_TIMEOUT = 5000;

const NotificationTypes = {
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
  Success: 'success',
};
Object.freeze(NotificationTypes);

const NotificationController = () => {
  const state = reactive({
    notifications: [],
  });

  const uid = () => Date.now() + Math.random();

  const removeNotification = (id) => {
    state.notifications = state.notifications.filter((n) => n.id !== id);
  };

  const addNotification = (type, message, timeout = null) => {
    const notification = {
      id: uid(),
      message,
      type,
    };
    state.notifications.push(notification);
    if (timeout) {
      setTimeout(() => removeNotification(notification.id), timeout);
    }
    return notification.id;
  };

  return {
    get notifications() {
      return state.notifications;
    },
    error(message, timeout = DEFAULT_TIMEOUT) {
      return addNotification(NotificationTypes.Error,
        message,
        timeout);
    },
    warning(message, timeout = DEFAULT_TIMEOUT) {
      return addNotification(NotificationTypes.Warning,
        message,
        timeout);
    },
    info(message, timeout = DEFAULT_TIMEOUT) {
      return addNotification(NotificationTypes.Info,
        message,
        timeout);
    },
    success(message, timeout = DEFAULT_TIMEOUT) {
      return addNotification(NotificationTypes.Success,
        message,
        timeout);
    },
    add(type, message, timeout = DEFAULT_TIMEOUT) {
      return addNotification(type, message, timeout);
    },
    remove(id) {
      removeNotification(id);
    },
    NotificationTypes,
  };
};

export default NotificationController();
