<template>
  <tr class="subscriptions--table-row">
    <slot />
    <th
      class="subscriptions--table-subheader"
    >
      {{ subscriptionName }}
    </th>

    <td
      v-for="value in allIntervals"
      :key="value"
      class="subscriptions--table-field"
    >
      <div
        v-if="isIntervalAvailable(value)"
        class="subscriptions--radio"
      >
        <label class="subscriptions--radio-label">
          <input
            class="subscriptions--radio-input"
            type="radio"
            :value="value"
            :checked="value === interval"
            :name="radioButtonName"
            @input="setInterval"
          >
        </label>
      </div>
    </td>

    <td
      v-if="removable"
      class="subscriptions--table-field"
    >
      <div class="subscriptions--radio">
        <label class="subscriptions--radio-label">
          <input
            class="subscriptions--radio-input is-trash"
            type="radio"
            :name="radioButtonName"
            value="never"
            @input="setInterval"
          >
          <Icon
            class="icon subscriptions--icon-trash"
            name="trash-alt"
            :class="{ 'is-active': interval === 'never' }"
          />
        </label>
      </div>
    </td>
    <td
      v-else
      class="subscriptions--table-field"
    >
      <div class="subscriptions--radio">
        <label class="subscriptions--radio-label">
          <input
            class="subscriptions--radio-input"
            type="radio"
            :name="radioButtonName"
            value="never"
            :checked="interval === 'never'"
            @input="setInterval"
          >
        </label>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'SubscriptionsTableRow',
  props: {
    subscriptionKey: {
      type: [String, Number],
      required: true,
    },
    subscriptionName: {
      type: String,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
    availableIntervals: {
      type: Array,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    subscriptionType: {
      type: String,
      required: true,
    },
  },
  emits: ['set-interval'],
  data() {
    return {
      allIntervals: ['daily', 'weekly', 'monthly'],
    };
  },
  computed: {
    radioButtonName() {
      return this.subscriptionKey;
    },
  },
  methods: {
    isIntervalAvailable(interval) {
      if (!this.availableIntervals) return true;
      return this.availableIntervals.includes(interval);
    },
    setInterval(e) {
      this.$emit('set-interval', {
        interval: e.target.value,
        subscriptionKey: this.subscriptionKey,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>