const interpolateArrayBetweenIndicesInplace = (X, fromIndex, toIndex) => {
  const fromValue = X[fromIndex];
  const toValue = X[toIndex];
  const N = toIndex - fromIndex;
  const stepSize = (toValue - fromValue) / N;
  for (let i = fromIndex + 1; i < toIndex; i += 1) {
    X[i] = X[i - 1] + stepSize;
  }
};

const notNumberOrNull = (x) => x !== null && typeof x !== 'number';

const interpolateArrayLinear = (X) => {
  if (X.some(notNumberOrNull)) {
    throw new Error('X may only contain numbers or null values.');
  }
  if (X[0] === null || X[X.length - 1] === null) {
    throw new Error('First and last element cannot be null.');
  }

  const output = [...X];
  for (let indexOfPreviousValue = 0, i = 1; i < output.length; i += 1) {
    if (output[i] !== null) {
      interpolateArrayBetweenIndicesInplace(output, indexOfPreviousValue, i);
      indexOfPreviousValue = i;
    }
  }
  return output;
};

export default interpolateArrayLinear;
