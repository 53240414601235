import { reactive } from 'vue';
import { compareTableValues } from '../../../helpers/SortHelpers';
import CommodityAppApi from "../../CommodityRisksMap/CommodityAppApi";

const SORT_ASC = 'asc';
const SORT_DESC = 'desc';
const COLUMN_TRANSLATION_KEY = 'apps.human_rights.commodity_risk_map.table.column';

class CountryRiskMapTableController {
  get isLoading() {
    return this._state.isLoading;
  }

  set isLoading(value) {
    this._state.isLoading = value;
  }

  get filters() {
    return this._state.filters;
  }

  set filters(value) {
    this._state.filters = value;
  }

  get selection() {
    return this._state.selection;
  }

  get notations() {
    return this._state.notations;
  }

  set notations(value) {
    this._state.notations = value;
  }

  get notationListModel() {
    return this._state.notationListModel;
  }

  get columns() {
    return this._state.notationListModel.columns;
  }

  get visibleColumns() {
    return this._state.notationListModel.visibleColumns;
  }

  get sorting() {
    return this._state.sorting;
  }

  set columns(value) {
    this._state.notationListModel.columns = value;
  }

  set visibleColumns(value) {
    this._state.notationListModel.visibleColumns = value;
  }

  constructor(translator) {
    this._translator = translator;
    this._api = new CommodityAppApi();
  }

  init({ countries, isWorldSelected }) {
    this._countries = this._getMappedCountries(countries);
    this._columns = this._getColumnDefinition(this._countries[0].hr_scores, isWorldSelected);

    this._state = reactive({
      isLoading: false,
      filters: {},
      sorting: null,
      selection: {
        selectionMode: 'none',
        canSelect: false,
      },
      notations: this._countries,
      notationListModel: {
        columns: this._columns,
        visibleColumns: this._columns,
        createRow: (notation) => this._columns.map(({ key, type, ...column }) => ({
          key,
          type,
          filterOptions: column.filterOptions,
          displayName: column.displayName,
          visible: true,
          value: column.valueBuilder ? column.valueBuilder(notation, key) : notation[key],
          classes: {},
          additionalData: {},
        })),
      },
    });
  }

  updateCountries({ countries, isWorldSelected }) {
    if (!this._countries) {
      return this.init({ countries, isWorldSelected })
    }
    this._countries = this._getMappedCountries(countries);
    this.columns = this._getColumnDefinition(this._countries[0].hr_scores, isWorldSelected);
    this.notations = this._countries;
  }

  isNotationVisibleForPrint() {
    return true;
  }

  selectNotation(notation) {}

  /**
     * Remaps country response structure
     *
     * @param countries { id, value: { name, iso_code, dimensions: {...} } }
     * @returns { name, iso_code, dimension-*: { ... }}
     * @private
     */
  _getMappedCountries(countries) {
    const countryValues = countries.map((c) => c.value);
    return countryValues.map((c) => c.hr_scores.reduce((acc, curr) => {
      acc[`dimension-${curr.key}`] = curr;
      return acc;
    }, c));
  }

  _getColumnDefinition(_dimensions, isWorldSelected) {
    const [total, ...dimensions] = _dimensions;
    const shareKey = isWorldSelected ? 'export_share' : 'import_share';
    return [
      {
        key: 'rank',
        visible: true,
        displayName: this._translator(`${COLUMN_TRANSLATION_KEY}.rank`),
        type: 'text',
        filterOptions: 'sort',
      },
      {
        key: 'trade_flow_percentage',
        visible: true,
        displayName: this._translator(`${COLUMN_TRANSLATION_KEY}.${shareKey}`),
        type: 'text',
        filterOptions: 'sort',
        classes: 'data-table--head-cell-title-wrapper',
        valueBuilder: (notation) => `${notation.trade_flow_percentage.toFixed(1)}%`,
      },
      {
        key: 'name',
        visible: true,
        displayName: this._translator(`${COLUMN_TRANSLATION_KEY}.coming_from`),
        classes: 'data-table--head-cell-min-width',
        filterOptions: 'sort',
        type: 'text',
        valueBuilder: (notation) => `${notation.iso_code} | ${notation.name}`,
      },
      {
        key: 'dimension-0',
        visible: true,
        displayName: total.name,
        classes: 'data-table--head-cell-title-wrapper',
        filterOptions: 'sort',
        type: 'totalHrScoresColumn',
        valueBuilder: (notation, key) => ({ dimensions: notation.hr_scores, total: notation[key], withFlagIcon: true, withTooltip: true }),
      },
      ...dimensions.map((d) => ({
        key: `dimension-${d.key}`,
        visible: true,
        filterOptions: 'sort',
        displayName: d.name,
        classes: 'data-table--head-cell-title-wrapper',
        type: 'hrScoresColumn',
      })),
    ];
  }

  updateNotationsAndUserState() {
    if (this.sorting) {
      this.sortColumn();
    } else {
      this.notations = [...this._countries];
    }
  }

  sortColumn() {
    const { direction, column: columnKey } = this.sorting;
    const notationValueAccessor = (notation) => {
      let value;
      switch (columnKey) {
        case 'trade_flow_percentage':
        case 'rank': value = +notation[columnKey];
          break;
        case 'name': value = notation[columnKey];
          break;
        default: value = +notation[columnKey]?.value;
          break;
      }

      return value || 0;
    };

    this.notations = compareTableValues(this.notations, notationValueAccessor, direction);
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;

    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: SORT_ASC };
      return;
    }
    if (this._state?.sorting?.direction === SORT_ASC) {
      this._state.sorting.direction = SORT_DESC;
    } else {
      this._state.sorting = null;
    }
  }

  async _getExcelFile(params) {
    await this._api.getExcelFile(params);
  }
}

export default CountryRiskMapTableController;
