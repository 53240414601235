<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <span class="dataset-column--id">
      {{ value.id }}
    </span>
    <span class="dataset-column--name">
      {{ value.name }}
    </span>
  </td>
</template>

<script>
export default {
  name: 'DatasetColumn',
  props: {
    value: {
      type: Object,
      required: true,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>