import axios from 'axios';

const exportFromResponse = (response) => {
  const url = window.URL.createObjectURL(response.data);

  const filenameRegex = /filename="([^"]*)";/;
  const filename = filenameRegex.exec(response.headers['content-disposition'])[1];

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  link.remove();
};

const exportFromPath = (path) => axios.get('/export/puppeteer', {
  responseType: 'blob',
  params: {
    path,
  },
}).then((response) => {
  exportFromResponse(response);
});

const exportCurrentPage = () => {
  const path = window.location.pathname + window.location.search;
  return exportFromPath(path);
};

export {
  exportFromPath,
  exportCurrentPage,
  exportFromResponse,
};
