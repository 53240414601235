import * as d3 from 'd3';

const validateTimescope = (timescope) => {
  const ValidTimescopes = ['yearly', 'monthly', 'daily'];
  if (!ValidTimescopes.includes(timescope)) {
    throw new Error(`Invalid timescope value '${timescope}'. Must be one of [${ValidTimescopes.join(', ')}].`);
  }
};

const dateToTimescope = (date, timescope) => {
  switch (timescope) {
    case 'yearly': return new Date(date.getFullYear(), 0, 1);
    case 'monthly': return new Date(date.getFullYear(), date.getMonth(), 1);
    case 'daily': return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    default: return date;
  }
};

const aggregateTimeseriesByTimescope = (X, timescope, aggregateFunction = d3.mean) => {
  validateTimescope(timescope);
  if (typeof aggregateFunction !== 'function') {
    throw new Error('aggregateFunction must be a function.');
  }
  if (!Array.isArray(X)) {
    throw new Error('X must be an array.');
  }

  const groupedByDate = X.reduce((out, elem) => {
    const date = dateToTimescope(elem.x, timescope);
    const key = date.toString();
    out[key] = out[key] || { date, values: [] };
    out[key].values.push(elem.y);
    return out;
  }, {});
  return Object.values(groupedByDate).map((elem) => ({
    x: elem.date,
    y: aggregateFunction(elem.values),
  }));
};

export default aggregateTimeseriesByTimescope;
