import { reactive } from 'vue';
import NotificationController from '@/controllers/NotificationController';
import i18n from '@/config/i18n';
import setTimeoutPromise from '@/helpers/setTimeoutPromise';
import TradeFlowsApi from '../api/TradeFlowsApi';

class TradeFlowDataProvider {
  constructor(dashboardId) {
    this._dashboardId = dashboardId;
    this._currentRequestId = null;
    this._apiParams = null;
    this._state = reactive({
      data: null,
      isLoading: true,
    });
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get data() {
    return this._state.data;
  }

  get hasData() {
    const hasImports = this.data?.import?.length > 0;
    const hasExports = this.data?.export?.length > 0;
    const hasBalance = this.data?.balance?.length > 0;
    return hasImports || hasExports || hasBalance;
  }

  clear() {
    this._state.data = null;
  }

  _hasValidApiParams() {
    return Object.values(this._apiParams).every((v) => v !== null && v !== undefined);
  }

  async _update() {
    this._state.isLoading = true;
    const uniqueRequestId = new Date() + Math.random();
    this._currentRequestId = uniqueRequestId;

    // ensure that the loading spinner is rendered for a brief
    // moment, so the user doesn't recognize a potential freeze
    // while the ui gets updated
    await setTimeoutPromise(10);

    if (!this._hasValidApiParams()) {
      this._state.data = null;
      this._state.isLoading = false;
      return;
    }

    TradeFlowsApi().quotes(this._dashboardId, this._apiParams)
      .then((response) => {
        if (this._currentRequestId !== uniqueRequestId) return;
        this._state.data = response.data;
      })
      .catch((error) => {
        if (this._currentRequestId !== uniqueRequestId) return;
        this._state.data = null;
        const message = error.response.data.error && error.response.data.error.message
          ? error.response.data.error.message
          : i18n.t('errors.api.generic');
        // TODO: show detailed error
        NotificationController.error(message);
      })
      .finally(() => {
        if (this._currentRequestId !== uniqueRequestId) return;
        this._state.isLoading = false;
      });
  }

  setApiParams(params) {
    this._apiParams = params;
    this._update();
  }
}

export default TradeFlowDataProvider;
