import { reactive } from 'vue';
import { NotNullOrUndefined } from '@/helpers';

const findById = (notations, id) => {
  const index = notations.findIndex((notation) => notation.id === id);
  return index >= 0 ? notations[index] : null;
};

const ValueSelection = (entries, parentState, notationApi) => {
  let currentApiRequestId = null;
  const state = reactive({
    notationIdForSerialization: -1,
    notation: null,
  });
  const cancelApiRequests = () => { currentApiRequestId = null; };
  const startApiRequest = () => {
    const id = new Date().getUTCMilliseconds();
    currentApiRequestId = id;
    return {
      get isCancelled() { return id !== currentApiRequestId; },
    };
  };

  return {
    get hasSelectedNotation() {
      return NotNullOrUndefined(state.notationIdForSerialization)
        && state.notationIdForSerialization >= 0;
    },
    get notation() {
      return state.notation;
    },
    set notation(notation) {
      cancelApiRequests();
      state.notationIdForSerialization = notation?.id === undefined ? -1 : notation.id;
      state.notation = notation ? { ...notation } : null;
    },
    get serializedState() {
      return state.notationIdForSerialization;
    },
    loadNotationFromApi(id) {
      const request = startApiRequest();
      notationApi.findNotation(id, { compare_to_date: parentState.comparisonDate })
        .then((notation) => {
          if (!request.isCancelled) {
            this.notation = notation;
          }
        });
    },
    loadFromNotationId(notationId) {
      this.notation = findById(entries, notationId);
      if (this.notation === null) {
        if (NotNullOrUndefined(notationId) && notationId >= 0) {
          state.notationIdForSerialization = notationId;
          this.loadNotationFromApi(notationId);
        }
      }
    },
    clear() {
      this.notation = null;
    },
  };
};

const SelectionController = (entries, parentState, notationApi) => {
  const state = reactive({
    selectionMode: 'none',
    primarySelection: ValueSelection(entries, parentState, notationApi),
    secondarySelection: ValueSelection(entries, parentState, notationApi),
  });

  const selectPrimaryNotation = (notation) => {
    state.primarySelection.notation = notation;
    const overwriteSecondaryNotation = notation
      && state.secondarySelection.notation
      && state.secondarySelection.notation.id === notation.id;
    if (overwriteSecondaryNotation) {
      state.secondarySelection.clear();
    }
  };
  const selectSecondaryNotation = (notation) => {
    if (notation.id !== state.primarySelection.notation.id) {
      state.secondarySelection.notation = notation;
    }
  };

  return {
    get selectionMode() {
      return state.selectionMode;
    },
    set selectionMode(value) {
      if (!['none', 'primary', 'secondary'].includes(value)) {
        throw TypeError(`Invalid selection mode '${value}'`);
      }
      state.selectionMode = value;
    },
    toggleSelectionMode(value) {
      if (!['primary', 'secondary'].includes(value)) {
        throw TypeError(`Invalid selection mode for toggling: '${value}'`);
      }
      if (this.selectionMode === value) {
        this.selectionMode = 'none';
      } else {
        this.selectionMode = value;
      }
    },
    get canSelect() {
      return this.selectionMode !== 'none';
    },
    get primary() {
      return state.primarySelection;
    },
    get secondary() {
      return state.secondarySelection;
    },
    get empty() {
      return state.primarySelection.notation === null;
    },
    get hasSecondarySelection() {
      return !!state.secondarySelection?.notation
          && state.primarySelection?.notation?.id !== state.secondarySelection?.notation?.id;
    },
    get serializedState() {
      return {
        primary: state.primarySelection.serializedState,
        secondary: state.secondarySelection.serializedState,
      };
    },
    loadSerializedState(serializedState) {
      state.primarySelection.loadFromNotationId(serializedState.primary);
      state.secondarySelection.loadFromNotationId(serializedState.secondary);
    },
    select(notation, { force = false } = {}) {
      if (!force && !this.canSelect) return;

      if (state.selectionMode === 'primary' || state.selectionMode === 'none') {
        selectPrimaryNotation(notation);
      } else if (state.selectionMode === 'secondary') {
        selectSecondaryNotation(notation);
      } else {
        throw TypeError(`Failed to select notation. Invalid selection mode '${state.selectionMode}'.`);
      }
    },
    get canClearSecondarySelection() {
      return state.selectionMode === 'secondary' && this.hasSecondarySelection;
    },
    clearSecondarySelection() {
      state.secondarySelection.clear();
      state.selectionMode = 'none';
    },
    clear() {
      state.primarySelection.clear();
      state.secondarySelection.clear();
      this.selectionMode = 'none';
    },
  };
};

export default SelectionController;
