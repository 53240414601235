<template>
  <div
    ref="root"
    class="button--dropdown-wrapper"
  >
    <ButtonComponent
      :variant="variant"
      :size="size"
      :padding="padding"
      :icon="icon"
      :icon-variant="iconVariant"
      :icon-fixed-width="iconFixedWidth"
      :is-disabled="isDisabled"
      :is-toggled="isOpen"
      :aria-label="buttonAriaLabel"
      @click="isOpen = !isOpen"
      :has-text="hasText"
    >
      <slot />
    </ButtonComponent>
    <div
      v-if="isOpen"
      class="button--dropdown"
      :class="dropdownCssModifiers"
    >
      <slot name="dropdown" />
    </div>
  </div>
</template>

<script>
const DROPDOWN_ALIGNMENTS = ['left', 'right'];
const validateDropdownAlignment = (value) => DROPDOWN_ALIGNMENTS.indexOf(value) !== -1;

export default {
  name: 'ButtonWithDropdown',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconVariant: {
      type: String,
      default: 'light',
    },
    iconFixedWidth: {
      type: Boolean,
      default: false,
    },
    buttonAriaLabel: {
      type: String,
      default: null,
    },
    dropdownAlignment: {
      type: String,
      default: 'left',
      validator: validateDropdownAlignment,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
    hasText: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    dropdownCssModifiers() {
      return [
        'is-open',
        this.dropdownAlignment === 'right' ? 'is-right-aligned' : null,
      ].join(' ');
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClickListener);
  },
  unmounted() {
    document.removeEventListener('click', this.documentClickListener);
  },
  methods: {
    documentClickListener(event) {
      // TODO This currently uses data-closes-popup on nested buttons to also close the popup
      // It feels like there should be a more vue-like way to handle this.
      const isClickOutside = !this.$refs.root.contains(event.target);
      const isButtonClick = event.target.dataset.closesPopup === 'true';
      if (isClickOutside || isButtonClick) {
        this.isOpen = false;
      }
    },
    // Is triggered from outside component
    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>
