<template>
  <div :class="`toast-notification is-${messageType}`">
    <div class="toast-notification--icon-wrapper">
      <Icon
        class="toast-notification--icon"
        :name="icon"
        variant="solid"
      />
    </div>
    <div class="toast-notification--text">
      <h2
        v-if="title"
        class="toast-notification--title"
      >
        {{ title }}
      </h2>
      <p class="toast-notification--message">
        {{ message }}
      </p>
    </div>
    <ButtonComponent
      class="toast-notification--close-button"
      icon="times"
      variant="ternary"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import NotificationController from '@/controllers/NotificationController';

const validateType = (type) => Object
  .values(NotificationController.NotificationTypes).includes(type);

export default {
  name: 'ToastNotification',
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    messageType: {
      type: String,
      required: true,
      validator: validateType,
    },
  },
  emits: ['close'],
  computed: {
    icon() {
      const icons = {
        error: 'exclamation',
        warning: 'exclamation',
        info: 'info',
        success: 'check',
      };
      return icons[this.messageType];
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>