import axios from 'axios';

const SourcesApi = () => ({
  sources() {
    return axios.get('/api/news/sources')
      .then((response) => response.data);
  },
});

export default SourcesApi;
