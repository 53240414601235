<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <WithTooltip
        :tooltip="value.tooltip"
    >
      {{ value.text }}
    </WithTooltip>
  </td>
</template>

<script>
export default {
  name: 'TextWithTooltipColumn',
  props: {
    value: {
      type: Object,
      default: null,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
