import { reactive } from 'vue';
import axios from 'axios';

const prependFavorites = (dashboards) => [
  ...dashboards.filter((d) => d.is_favorite),
  ...dashboards.filter((d) => !d.is_favorite),
];

const addHref = (dashboard) => ({ ...dashboard, href: `/dashboards/${dashboard.id}` });

export default class DashboardCluster {
  constructor(cluster) {
    this.dashboard_ids = cluster.dashboard_ids;
    this.name = cluster.name;
    this.name_en = cluster.name_en;
    this.name_de = cluster.name_de;
    this.updateTimeout = null;
    this._state = reactive({
      dashboards: null,
      requestPendingOrFinished: false,
    });
  }

  get id() {
    return `${this.name_de}-${this.name_en}`;
  }

  get isLoadingDashboards() {
    return this._state.dashboards === null;
  }

  get dashboards() {
    if (this._state.dashboards === null) {
      this.fetchDashboards();
      return [];
    }
    return this._state.dashboards;
  }

  async fetchDashboards() {
    if (this._state.requestPendingOrFinished) return;
    this._state.requestPendingOrFinished = true;
    axios.get('/api/dashboards', { params: { ids: this.dashboard_ids } })
      .then((response) => response.data)
      .then((dashboards) => dashboards.map(addHref))
      .then((dashboards) => prependFavorites(dashboards))
      .then((dashboards) => {
        this._state.dashboards = dashboards;
      });
  }
}
