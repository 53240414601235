<template>
  <div
    ref="root"
    class="item"
    :class="cssModifiers"
  >
    <div class="item--header">
      <h2 class="item--title">
        <div
          v-if="news.unread"
          class="item--unread-marker"
          role="status"
          :aria-label="$t('generic.unread')"
        />
        {{ news.display_name }}
      </h2>
      <div
        v-if="editorialController"
        class="item--actions"
      >
        <ButtonComponent
          class="item--header-button"
          variant="ternary"
          icon="pencil"
          :aria-label="$t('apps.news.edit_button_label')"
          @click="editorialController.showEditNewsModal(news)"
        />
        <ButtonComponent
          class="item--header-button"
          variant="ternary"
          icon="trash"
          :aria-label="$t('apps.news.delete_button_label')"
          @click="editorialController.deleteAfterConfirmation(news)"
        />
      </div>
    </div>
    <p class="item--meta">
      {{ metaData.join(' &bull; ') }}
      <span>
        &bull;
      </span>
      <CopyLinkButton
        :url="$routes.ShowNews(news.id)"
      />
    </p>
    <div
      :style="contentStyles"
      class="item--content"
    >
      <span ref="content">
        <RichTextContent
          :unsafe-html-content="news.content"
        />
      </span>
    </div>
    <div class="item--footer">
      <template v-if="canCollapse">
        <ButtonComponent
          v-if="isCollapsed"
          variant="secondary"
          icon-variant="regular"
          icon="caret-square-down"
          class="item--footer-button"
          @click="expand"
        >
          {{ $t('apps.news.show_more') }}
        </ButtonComponent>
        <ButtonComponent
          v-else
          variant="secondary"
          icon-variant="regular"
          icon="caret-square-up"
          class="item--footer-button"
          @click="collapse"
        >
          {{ $t('apps.news.show_less') }}
        </ButtonComponent>
      </template>
      <ButtonComponent
        v-else-if="news.unread"
        variant="secondary"
        icon="check"
        class="item--footer-button"
        @click="markAsRead"
      >
        {{ $t('generic.mark_as_read') }}
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { RichTextContent } from '@/components';
import { MarkAsRead, FormatPublicationDate } from '@/helpers/PublicationHelpers';

export default {
  name: 'NewsListItem',
  components: {
    RichTextContent,
  },
  props: {
    news: {
      type: Object,
      required: true,
    },
    editorialController: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      isCollapsed: true,
      contentHeight: null,
      collapsedHeight: 100,
      resizeObserver: new ResizeObserver(this.updateContentHeight.bind(this)),
    };
  },
  computed: {
    canCollapse() {
      return this.contentHeight > this.collapsedHeight;
    },
    cssModifiers() {
      return (this.isCollapsed && this.canCollapse) ? 'is-collapsed' : null;
    },
    contentStyles() {
      const maxHeightStyle = this.isCollapsed
        ? `max-height: ${this.collapsedHeight}px;`
        : `max-height: ${this.contentHeight}px;`;
      const animationSpeed = 0.025;
      const animationDuration = (this.contentHeight / this.collapsedHeight) * animationSpeed;
      const animationStyle = `transition: max-height ${animationDuration}s ease-in`;
      return [maxHeightStyle, animationStyle].join('; ');
    },
    metaData() {
      return [
        FormatPublicationDate(this.news.published_at),
        this.news.source,
        this.news.product_groups.map((pg) => pg.name).join(', '),
        this.news.regions.map((r) => r.name).join(', '),
      ].filter((item) => !!item);
    },
  },
  mounted() {
    this.updateContentHeight();
    this.observeContentHeight();
  },
  beforeUnmount() {
    this.unobserveContentHeight();
  },
  methods: {
    markAsRead() {
      MarkAsRead(this.news);
    },
    expand() {
      this.markAsRead();
      this.isCollapsed = false;
    },
    collapse() {
      this.isCollapsed = true;
      this.$refs.root.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    observeContentHeight() {
      const trixElement = this.$refs.content.querySelector('.trix-content');
      if (!trixElement) return;
      this.resizeObserver.observe(trixElement);
    },
    unobserveContentHeight() {
      const trixElement = this.$refs.content.querySelector('.trix-content');
      if (!trixElement) return;
      this.resizeObserver.unobserve(trixElement);
    },
    updateContentHeight() {
      this.contentHeight = this.$refs.content.getBoundingClientRect().height;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>