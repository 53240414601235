<template>
  <div>
    <!-- eslint-disable vue/no-mutating-props -->
    <TextInput
      v-if="!isNewsPublication"
      v-model="publication.file_name"
      :label="$t('attributes.publication.file_name')"
      :required="true"
      @focus="$event.target.select()"
    />
    <TextInput
      v-model="publication.display_name"
      v-focus
      :label="$t('attributes.publication.display_name')"
      :required="true"
      @focus="$event.target.select()"
    />
    <MultiSelectInput
      v-model="publication.product_group_ids"
      class="edit-form--product-groups-select"
      :label="$t('attributes.publication.product_groups')"
      :no-selection-hint="noProductGroupSelectionHint"
      :add-to-selection-prompt="addToProductGroupSelectionPrompt"
      :access-key="(o) => o.id"
      :access-value="(o) => o.name"
      :options="availableProductGroups"
      :required="true"
    />
    <MultiSelectInput
      v-model="publication.region_ids"
      class="edit-form--regions-select"
      :label="$t('attributes.publication.regions')"
      :no-selection-hint="noRegionSelectionHint"
      :add-to-selection-prompt="addToRegionSelectionPrompt"
      :access-key="(o) => o.id"
      :access-value="(o) => o.name"
      :options="availableRegions"
      :required="true"
    />
    <SelectInput
      v-if="!isNewsPublication"
      v-model="publication.kind"
      :label="$t('attributes.publication.kind')"
      :required="true"
      :options="optionsForKind"
    />
    <DateInput
      v-model="publication.published_at"
      :label="$t('attributes.publication.published_at')"
      :required="true"
    />
    <SelectInput
      v-model="publication.locale"
      :label="$t('attributes.publication.locale')"
      :required="true"
      :options="optionsForLocale"
    />
    <template v-if="isNewsPublication">
      <SourceCombobox
        v-model="publication.source"
        :options="availableSources"
        :select-source-prompt="selectSourcePrompt"
      />
      <RichTextInput
        v-model="publication.content"
        class="publications-edit-form--content"
        :label="$t('attributes.publication.content')"
        :content-url="contentUrl"
      />
    </template>
    <CheckboxInput
      v-model="publication.for_external"
      :label="$t('attributes.publication.for_external')"
    />
    <!-- eslint-enable vue/no-mutating-props -->
  </div>
</template>

<script>
import { RichTextInput } from '@/components';
import { optionsForEnumerize } from '@/helpers/I18nHelpers';
import { ProductGroupsApi } from '@/api/ProductGroupsApi';
import RegionsApi from '@/api/RegionsApi';
import SourcesApi from '@/api/SourcesApi';
import SourceCombobox from '../SourceCombobox/Component.vue';

export default {
  name: 'PublicationsEditForm',
  components: {
    RichTextInput,
    SourceCombobox,
  },
  props: {
    publication: {
      type: Object,
      required: true,
    },
    contentUrl: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      availableProductGroups: [],
      availableRegions: [],
      availableSources: [],
    };
  },
  computed: {
    isNewsPublication() {
      return this.publication.kind === 'news';
    },
    optionsForKind() {
      return optionsForEnumerize('enumerize.publication.kind')
        .filter((kind) => kind.id !== 'news');
    },
    optionsForLocale() {
      return optionsForEnumerize('enumerize.default.locale');
    },
    noProductGroupSelectionHint() {
      return this.$t('apps.publications.edit_form.product_groups.no_selection_hint');
    },
    addToProductGroupSelectionPrompt() {
      return this.$t('apps.publications.edit_form.product_groups.add_to_selection_prompt');
    },
    noRegionSelectionHint() {
      return this.$t('apps.publications.edit_form.regions.no_selection_hint');
    },
    addToRegionSelectionPrompt() {
      return this.$t('apps.publications.edit_form.regions.add_to_selection_prompt');
    },
    selectSourcePrompt() {
      return this.$t('apps.publications.edit_form.source.select_source');
    },
  },
  beforeMount() {
    new ProductGroupsApi().productGroups().then((productGroups) => {
      this.availableProductGroups = productGroups;
    });
    new RegionsApi().regions().then((regions) => {
      this.availableRegions = regions;
    });
    new SourcesApi().sources().then((sources) => {
      this.availableSources = sources;
    });
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>