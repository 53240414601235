import axios from 'axios';
import i18n from '@/config/i18n';
import ApiHelpers from '@/api/ApiHelpers';
import DashboardApi from '@/api/DashboardApi';
import NotificationController from '@/controllers/NotificationController';
import {
  EventBus,
  PreferredDashboardCurrencyChangeEvent,
} from '@/events';

export const ToggleDashboardFavorite = (dashboard, value) => {
  const api = DashboardApi(dashboard);
  const apiRoute = value === true
    ? api.addDashboardToFavorites
    : api.removeDashboardFromFavorites;
  return apiRoute().then(() => { dashboard.is_favorite = value; });
};

export const SetPreferredDashboardCurrency = async ({ dashboard, preferredCurrencyUnitId }) => {
  try {
    await axios.put(`/api/dashboards/${dashboard.id}/customisation`, {
      customisation: {
        preferred_currency_unit_id: preferredCurrencyUnitId,
      },
    });
    EventBus.emit(new PreferredDashboardCurrencyChangeEvent({
      dashboard,
      preferredCurrencyUnitId,
    }));
    NotificationController.success(i18n.t('components.currency_combobox.change_currency_success_message'));
  } catch (error) {
    ApiHelpers.handleError(error);
    return { error };
  }
  return {};
};
