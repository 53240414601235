import { reactive } from 'vue';
import axios from 'axios';
import SortOption from '@/models/SortOption';
import ApiHelpers from '@/api/ApiHelpers';
import RegionsApi from '@/api/RegionsApi';
import { removeUnavailableSelections } from '@/helpers/FilterHelpers';

const buildSortOptions = () => SortOption
  .buildList('apps.publications.filters.sort_options', [
    ['published_at', 'desc'],
    ['published_at', 'asc'],
    ['is_unread', 'desc'],
  ]);

const fetchProductGroupOptions = (forDashboardId) => {
  const params = { for_dashboard: forDashboardId };
  return axios.get('/api/news/filters', { params })
    .then((response) => response.data.product_groups)
    .catch((error) => {
      ApiHelpers.handleError(error);
      return [];
    });
};

export default class NewsFilterController {
  constructor({
    selectedProductGroups = [],
    selectedRegions = [],
    selectedSortDirection = 'published_at_desc',
    enteredFulltext = '',
    forDashboardId,
  } = {}) {
    this.listeners = {};
    this._state = reactive({
      filters: {
        productGroups: {
          options: [],
          value: selectedProductGroups,
        },
        sortDirection: {
          options: buildSortOptions(),
          value: selectedSortDirection,
        },
        regions: {
          options: [],
          value: selectedRegions,
        },
        fulltext: {
          value: enteredFulltext,
        },
      },
    });
    this.forDashboardId = forDashboardId;

    fetchProductGroupOptions(this.forDashboardId).then((options) => {
      this._state.filters.productGroups.options = options;
      this._state.filters.productGroups.value = removeUnavailableSelections({
        selectedKeys: this._state.filters.productGroups.value,
        availableOptions: this._state.filters.productGroups.options,
        accessKey: (option) => option.id,
      });
    });

    this.loadRegionFilterOptions();
  }

  loadRegionFilterOptions() {
    RegionsApi().regions().then((regions) => { this._state.filters.regions.options = regions; });
  }

  get filtersForRequest() {
    const sort = this._state.filters.sortDirection
      .options.find((o) => o.key === this.selectedSortDirection)?.forApi;
    return {
      for_dashboard: this.forDashboardId,
      product_group_ids: this.selectedProductGroups,
      region_ids: this.selectedRegions,
      query: this.enteredFulltext,
      sort,
    };
  }

  get filtersForUrlState() {
    return {
      selectedProductGroups: this.selectedProductGroups,
      selectedSortDirection: this.selectedSortDirection,
      selectedRegions: this.selectedRegions,
      enteredFulltext: this.enteredFulltext,
    };
  }

  get selectedProductGroups() {
    return this._state.filters.productGroups.value;
  }

  set selectedProductGroups(value) {
    this._state.filters.productGroups.value = value;
    this.emit('change');
  }

  get productGroupOptions() {
    return this._state.filters.productGroups.options;
  }

  get selectedSortDirection() {
    return this._state.filters.sortDirection.value;
  }

  set selectedSortDirection(value) {
    this._state.filters.sortDirection.value = value;
    this.emit('change');
  }

  get enteredFulltext() {
    return this._state.filters.fulltext.value;
  }

  set enteredFulltext(value) {
    this._state.filters.fulltext.value = value;
    this.emit('change');
  }

  get sortDirectionOptions() {
    return this._state.filters.sortDirection.options;
  }

  get selectedRegions() {
    return this._state.filters.regions.value;
  }

  set selectedRegions(value) {
    this._state.filters.regions.value = value;
    this.emit('change');
  }

  get regionOptions() {
    return this._state.filters.regions.options;
  }

  emit(eventName, payload) {
    if (this.listeners[eventName]) {
      this.listeners[eventName](payload);
    }
  }

  on(eventName, callback) {
    this.listeners[eventName] = callback;
  }
}
