<script>
import FlagsColumnContent from "./FlagsColumnContent"

export default {
  props: {
    value: {
      type: Object
    },

    flagCount: {
      type: Number,
      required: true
    }
  },

  components: {
    FlagsColumnContent
  },

  computed: {
    internalFlagCount() {
      if (this.flagCount != undefined) {
        return this.flagCount
      }
      return this.value?.flagCount
    }
  }
}
</script>

<template>
  <td class="data-table--body-cell data-table--flag-cell">
    <FlagsColumnContent :flagCount="internalFlagCount"/>
  </td>
</template>

<style lang="scss">
.data-table--flag-cell .flags-cell-content {
  margin: auto;
}
</style>
