import { reactive } from 'vue';
import UrlStateStore from '@/services/UrlStateStore';
import { setQueryParam } from "../../services/UrlStateStore";
import EventBus from '@/events/EventBus';
import { DashboardFavoriteChangeEvent } from '@/events';
import NotationFilters from '@/models/NotationFilters';
import { filterEmptyProperties } from '@/utils/ObjectUtilities';
import NullNotation from './components/ListView/NullNotation';
import IntroAppApi from './IntroAppApi';

export const DashboardTypes = Object.freeze({
  Favorites: 'favorites',
  Indicators: 'indicators',
  HumanRightsRisks: 'human_rights_risks',
});

const DefaultState = () => ({
  dashboardType: DashboardTypes.Favorites,
  showDetailColumns: false,
  showListView: true,
});

const Storage = window.localStorage;
const STORAGE_KEY_CURRENT_DASHBOARD_TYPE = 'INTRO_CURRENT_DASHBOARD_TYPE';

const storageState = () => filterEmptyProperties({
  dashboardType: Storage.getItem(STORAGE_KEY_CURRENT_DASHBOARD_TYPE),
});

class IntroAppController {
  constructor({ favoriteDashboardCount, state = {}, print = false } = {}) {
    const initialState = { ...DefaultState(favoriteDashboardCount), ...storageState(), ...state };
    if (print) {
      initialState.showListView = false;
    }

    this._eventBus = new EventBus('intro-app');
    this._api = new IntroAppApi();
    this._state = reactive({
      dashboardType: initialState.dashboardType,
      showDetailColumns: initialState.showDetailColumns,
      showListView: initialState.showListView,
      favoriteDashboardCount,
      dashboards: [],
      isLoading: false,
      filters: NotationFilters({
        dashboard_name: { query: initialState?.filters?.dashboard_name?.query || '' },
      }),
      sorting: initialState?.sorting || null,
    });

    UrlStateStore.replaceState({
      dashboardType: this._state.dashboardType,
      showDetailColumns: this._state.showDetailColumns,
      showListView: this._state.showListView,
      filters: this._state.filters?.forRequest,
      sorting: this._state.sorting,
    });
    setQueryParam("printOrientation", this._state.dashboardType === "human_rights_risks" ? "landscape" : "portrait");

    const onFavoriteChanged = () => {
      this.updateNotationsAndUserState();
    };
    this._favoriteChangeObserver = this._eventBus
      .on(DashboardFavoriteChangeEvent.EventName, onFavoriteChanged);
    this.updateNotationsAndUserState();
  }

  get showingFavorites() {
    return this.dashboardType === DashboardTypes.Favorites;
  }

  get showingIndicators() {
    return this.dashboardType === DashboardTypes.Indicators;
  }

  set dashboardType(value) {
    if (!Object.values(DashboardTypes).includes(value)) throw new Error('Invalid dashboard type');

    this._state.dashboardType = value;
    UrlStateStore.set('dashboardType', value);
    setQueryParam("printOrientation", this._state.dashboardType === "human_rights_risks" ? "landscape" : "portrait");
    Storage.setItem(STORAGE_KEY_CURRENT_DASHBOARD_TYPE, value);
    this.updateNotationsAndUserState();
  }

  get dashboardType() {
    return this._state.dashboardType;
  }

  get dashboards() {
    return this._state.dashboards;
  }

  get dashboardsForListView() {
    return this.dashboards.map((dashboard) => {
      const primaryNotation = dashboard.notations.primary || NullNotation();
      const additionalAttributes = {
        dashboard_id: dashboard.id,
        is_favorite: dashboard.is_favorite,
        dashboard_name: {
          name: dashboard.full_name,
          product_groups: dashboard.product_groups,
        },
        preferred_currency: dashboard.preferred_currency_unit,
        secondary_quotes_1y: dashboard.notations.secondary?.quotes_1y,
        own_view_active: dashboard.own_view_active,
      };
      return reactive({ ...primaryNotation, ...additionalAttributes });
    });
  }

  get dashboardsForTileView() {
    const dashboardHasNotations = (dashboard) => dashboard.notations.primary;
    return this.dashboards.filter(dashboardHasNotations);
  }

  get filters() {
    if (!this.showListView) {
      return null;
    }
    return this._state.filters;
  }

  get sorting() {
    if (!this.showListView) {
      return null;
    }
    return this._state.sorting;
  }

  set sorting(value) {
    this._state.sorting = value;
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get showFavouriteCreationHint() {
    // if a filter is applied we won't show the "blank slate" state
    if (this._state.filters.dashboard_name?.query) {
      return false
    }
    return this.showingFavorites && this._state.favoriteDashboardCount === 0;
  }

  get showListView() {
    return this._state.showListView;
  }

  set showListView(show) {
    const updateRequired = this.showListView !== show;
    this._state.showListView = show;
    UrlStateStore.set('showListView', show);
    if (updateRequired) {
      this.updateNotationsAndUserState();
    }
  }

  get showDetailColumns() {
    return this._state.showDetailColumns;
  }

  set showDetailColumns(value) {
    this._state.showDetailColumns = value;
    UrlStateStore.set('showDetailColumns', value);
  }

  async updateNotationsAndUserState() {
    // We call the api for the human rights risks from the pinia store
    if (this.dashboardType === DashboardTypes.HumanRightsRisks) return;

    this._state.isLoading = true;
    const requestId = `${new Date().getTime()}-${Math.random()}`;
    this._currentRequestId = requestId;
    this._state.dashboards = [];

    UrlStateStore.set('sorting', this._state.sorting);
    UrlStateStore.set('filters', this._state.filters?.forRequest);

    const requestedType = this.dashboardType;
    const dashboards = await this._api
      .dashboards(requestedType, this.filters?.forRequest, this.sorting);

    if (this._currentRequestId !== requestId) { return; }
    if (requestedType === DashboardTypes.Favorites) {
      this._state.favoriteDashboardCount = dashboards.length;
    }
    this._state.dashboards = dashboards;
    this._state.isLoading = false;
  }

  cycleColumnSort(column) {
    const sortOnDifferentColumn = this._state?.sorting?.column !== column;
    if (sortOnDifferentColumn) {
      this._state.sorting = { column, direction: 'asc' };
      return;
    }
    if (this._state?.sorting?.direction === 'asc') {
      this._state.sorting.direction = 'desc';
    } else {
      this._state.sorting = null;
    }
  }

  onFiltersChanged() {
    this.updateNotationsAndUserState()
  }

  destroy() {
    this._favoriteChangeObserver.off();
  }
}

export default IntroAppController;
