<script>
import { PrintLayout } from '@/components';

export default {
  components: {
    PrintLayout
  },

  props: {
    title: { type: String }
  }
}
</script>

<template>
  <PrintLayout :header-only-first-page="true"/>

  <div class="hrr-container">
    <div class="headline" v-if="title">
      <h1>{{ title }}</h1>
    </div>
    <slot/>
  </div>
</template>

<style lang="scss">
.hrr-container {
  .headline {
    @include font-size('20/25');
    margin-bottom: 14px;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  overflow: hidden;

  @media print {
    & {
      overflow: visible;
      padding-top: 0;
    }
  }
}
</style>
