import { BaseController, updateUrlState } from '@/components/NotationListPage/BaseController';
import NotificationController from '@/controllers/NotificationController';
import i18n from '@/config/i18n';
import DashboardApi from '@/api/DashboardApi';
import PaginationService from '@/services/PaginationService';
import ApiHelpers from '@/api/ApiHelpers';
import { ToggleDashboardFavorite } from '@/helpers/DashboardHelpers';
import {
  EventBus,
  OwnViewChangeEvent,
} from '@/events';
import DashboardNotationsRepository from './DashboardNotationRepository';

const serializePersistentState = (state) => ({
  filters: state.filters.forRequest,
  sorting: state.sorting,
  comparisonDate: state.comparisonDate,
  selection: state.selection.serializedState,
});

const hiddenColumnsForDashboard = (dashboard, user) => {
  const hiddenColumns = [
    'sub_term',
    'dashboard_name',
    'preview',
  ];

  if (!user.editor_permissions) {
    hiddenColumns.push('is_hidden_for_clients');
    hiddenColumns.push('correlation_1m');
    hiddenColumns.push('correlation_2m');
    hiddenColumns.push('correlation_3m');
  }

  return hiddenColumns;
};

const detailColumnsForUser = (user) => {
  const base = ['attribute_unit', 'attribute_economy', 'dataset'];
  return user.editor_permissions
    ? [...base, 'correlation_1m', 'correlation_2m', 'correlation_3m']
    : base;
};

const DashboardControllerConfig = (dashboard, user) => {
  const notationRepository = new DashboardNotationsRepository(dashboard);
  const requestFunction = (options) => (
    notationRepository.notations(options.data, options.cancelToken));
  return {
    dashboard,
    i18nPrefix: 'dashboard',
    notationListApi: DashboardApi(dashboard),
    printAllNotations: true,
    hiddenColumns: hiddenColumnsForDashboard(dashboard, user),
    detailColumns: detailColumnsForUser(user),
    pagination: new PaginationService({
      requestFunction,
      transformEntry: (e) => ({ isChecked: false, ...e }),
      errorHandler: ApiHelpers.handleError,
    }),
  };
};

class DashboardController extends BaseController {
  constructor(dashboard, showCustomised, user, initialState, {
    isMailExport,
    media,
    selectedCurrencyId,
  }) {
    const showCustomisedView = showCustomised && dashboard.can_have_own_view;
    const customState = showCustomisedView ? { ...dashboard.custom_state } : null;
    const state = initialState || customState || dashboard.state;
    const config = DashboardControllerConfig(dashboard, user);
    super(config, user, state, {
      showCustomised: showCustomisedView,
      customState: { ...dashboard.custom_state },
      hasOwnView: dashboard.has_own_view,
      selectedCurrencyId,
    }, { isMailExport, media });
  }

  get dashboard() {
    return this.config.dashboard;
  }

  onStateChangedByUser() {
    super.onStateChangedByUser();
    if (this.showCustomised) {
      this.saveCustomDashboardState();
    }
  }

  get selectedCurrencyId() {
    return this.state.selectedCurrencyId;
  }

  set selectedCurrencyId(value) {
    this.state.selectedCurrencyId = value;
    this.updateNotationsWithoutState();
  }

  get additionalPaginationParams() {
    return {
      to_currency_unit_id: this.selectedCurrencyId,
    };
  }

  saveCustomDashboardState() {
    const newState = serializePersistentState(this.state);
    this.config.notationListApi.saveCustomDashboardState(newState)
      .then(() => {
        this.state.customState = { ...newState };
        if (!this.showCustomised) {
          this.state.hasOwnView = true;
          this.state.showCustomised = true;
        }
        EventBus.emit(new OwnViewChangeEvent({
          hasOwnView: this.state.hasOwnView,
          ownViewActive: this.state.showCustomised,
        }));
      })
      .catch(() => (NotificationController
        .error(i18n.t('components.widget_configurator.notifications.save_state_error'))));
  }

  deleteDashboard() {
    return this.config.notationListApi.deleteDashboard()
      .then(() => { window.location.href = '/'; });
  }

  editDashboard(dashboard) {
    return this.config.notationListApi.editDashboard(dashboard)
      .then(() => { window.location.reload(); });
  }

  cloneDashboard(dashboard) {
    return this.config.notationListApi.cloneDashboard(dashboard)
      .then((clonedDashboard) => { window.location.href = `/dashboards/${clonedDashboard.id}`; });
  }

  publishDashboard(dashboard) {
    return this.config.notationListApi.publishDashboard(dashboard)
      .then(() => { window.location.reload(); });
  }

  unpublishDashboard() {
    return this.config.notationListApi.unpublishDashboard()
      .then(() => { window.location.reload(); });
  }

  saveDashboardState() {
    return this.config.notationListApi.saveDashboardState(serializePersistentState(this.state))
      .then(() => {
        NotificationController.success(i18n.t('components.widget_configurator.notifications.save_state_success'));
      })
      .catch(() => (NotificationController
        .error(i18n.t('components.widget_configurator.notifications.save_state_error'))));
  }

  saveDashboardFallbackState() {
    const newFallbackState = {
      selection: this.state.selection.serializedState,
    };

    return this.config.notationListApi.saveDashboardFallbackState(
      newFallbackState,
    ).then(() => {
      NotificationController.success(i18n.t('components.widget_configurator.notifications.save_fallback_state_success'));
      this.config.dashboard.fallback_state = newFallbackState;
    })
      .catch(() => (NotificationController
        .error(i18n.t('components.widget_configurator.notifications.save_state_error'))));
  }

  get canLoadDashboardFallbackState() {
    return this.config.dashboard.fallback_state?.selection;
  }

  loadDashboardFallbackState() {
    if (!this.canLoadDashboardFallbackState) return;
    this.state.selection.loadSerializedState(this.config.dashboard.fallback_state?.selection);
  }

  toggleDashboardFavorite(value) {
    return ToggleDashboardFavorite(this.config.dashboard, value);
  }

  toggleDashboardNotationForCustomisation(notation) {
    const isHiddenUpdated = !notation.is_hidden_for_customisation;
    const isVisibleUpdated = !isHiddenUpdated;
    this.config.notationListApi.updateNotationVisibilityForCustomisation(notation, isVisibleUpdated)
      .then(() => { notation.is_hidden_for_customisation = isHiddenUpdated; });
  }

  toggleDashboardNotationForUsers(notation) {
    const isHiddenUpdated = !notation.is_hidden_for_clients;
    const isVisibleUpdated = !isHiddenUpdated;
    this.config.notationListApi.updateNotationVisibilityForClients(notation, isVisibleUpdated)
      .then(() => { notation.is_hidden_for_clients = isHiddenUpdated; });
  }

  toggleShowCustomised() {
    this.state.showCustomised = !this.state.showCustomised;
    const customState = this.state.showCustomised ? this.state.customState : null;
    const dashboardState = customState || this.config.dashboard.state;
    this.loadState(dashboardState);
    // TODO: remove updateUrlState if updateNotationsWithoutState updates the UrlState
    updateUrlState(this.state);
    this.config.notationListApi.updateOwnViewActive(this.state.showCustomised);
    EventBus.emit(new OwnViewChangeEvent({
      hasOwnView: this.state.hasOwnView,
      ownViewActive: this.state.showCustomised,
    }));
  }

  deleteDashboardOwnView() {
    return this.config.notationListApi.deleteDashboardOwnView()
      .then(() => {
        this.state.hasOwnView = false;
        this.state.customState = null;
        this.state.showCustomised = false;
        this.loadState(this.config.dashboard.state);
        EventBus.emit(new OwnViewChangeEvent({
          hasOwnView: this.state.hasOwnView,
          ownViewActive: this.state.showCustomised,
        }));
      });
  }

  deleteCheckedNotationsFromDashboard() {
    this.notationApi.deleteFromDashboard(this.config.dashboard.id, this.checkedNotations)
      .then(() => this.updateNotationsAndUserState());
  }

  get hiddenColumns() {
    if (this.hasOwnView) {
      return this.config.hiddenColumns;
    }
    return this.config.hiddenColumns.concat(['is_hidden_for_customisation']);
  }

  get caption() {
    if (this.isLoading) {
      return i18n.t('components.dashboards.is_loading');
    }
    const count = this.notations.length;
    if (this.hasMoreResults) {
      return i18n.t('components.dashboards.result_count_plus', count);
    }
    return i18n.t('components.dashboards.result_count', count);
  }

  get hasOwnView() {
    return this.state.hasOwnView;
  }

  get showCustomised() {
    return this.state.showCustomised && this.config.dashboard.can_have_own_view;
  }
}

export default DashboardController;
