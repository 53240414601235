import i18n from '@/config/i18n';
import NotationListModel from '@/models/NotationListModel';
import { ToggleDashboardFavorite } from '@/helpers/DashboardHelpers';
import { ColumnConfiguration } from '@/models/Columns';
import { EventBus, DashboardFavoriteChangeEvent } from '@/events';

const FavoriteColumnConfiguration = (i18nPrefix) => {
  const valueCallback = (_column, dashboard) => ({
    name: 'star',
    variant: dashboard.is_favorite ? 'solid' : 'regular',
    ariaLabel: dashboard.is_favorite
      ? i18n.t('components.dashboard_list.intro_page.aria_labels.is_favorite_true', { dashboard: dashboard.dashboard_name.name })
      : i18n.t('components.dashboard_list.intro_page.aria_labels.is_favorite_false', { dashboard: dashboard.dashboard_name.name }),
    modifiers: ['for-dashboard-favorite'],
  });

  const clickCallback = (notationWithDashboardData, event) => {
    const dashboard = {
      get id() { return notationWithDashboardData.dashboard_id; },
      get is_favorite() { return notationWithDashboardData.is_favorite; },
      set is_favorite(value) { notationWithDashboardData.is_favorite = value; },
    };

    const eventBus = new EventBus('intro-app');
    ToggleDashboardFavorite(dashboard, !dashboard.is_favorite)
      .then(() => {
        const changeEvent = new DashboardFavoriteChangeEvent(dashboard.id, dashboard.is_favorite);
        eventBus.emit(changeEvent);
      });
    event.stopPropagation();
  };

  return ColumnConfiguration(
    'dashboard', 'is_favorite', 'icon', 'empty',
    { valueCallback, i18nPrefix },
    { click: clickCallback },
  );
};

const ListViewModel = (showDetails) => {
  const i18nPrefix = 'intro_page';
  const noSortOrFilter = false;
  const hiddenColumns = [
    'batch_select',
    'is_hidden_for_customisation',
    'is_hidden_for_clients',
    'watched',
    'dashboard_cluster_name',
    'sub_term',
    'custom_dod_percent',
    'custom_dod_percent_over_min',
    'custom_dod_percent_over_max',
    'custom_dod_percent_over_avg',
    'correlation_1m',
    'correlation_2m',
    'correlation_3m',
  ];
  const detailColumns = [
    'name',
    'reporter',
    'partner',
    'flow',
    'indicator',
    'timescope',
    'source',
    'attribute_economy',
    'dataset',
    'attribute_unit',
  ];
  const model = NotationListModel(i18nPrefix, noSortOrFilter);
  model.addColumn(FavoriteColumnConfiguration(i18nPrefix));
  detailColumns.forEach((key) => model.setColumnVisible(key, showDetails));
  hiddenColumns.forEach((key) => model.setColumnVisible(key, false));
  return model;
};

export default ListViewModel;
