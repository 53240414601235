<template>
  <div class="welcome-banner--wrapper">
    <template v-if="!showPublicationsAndNews">
      <div class="welcome-banner">
        <div class="welcome-banner--column is-hero-image" />
        <div class="welcome-banner--column">
          <h3 class="welcome-banner--title">
            HGS Research
          </h3>
          <h3 class="welcome-banner--subtitle">
            <div class="welcome-banner--vision">
              {{ $t('components.welcome_banner.vision') }}
            </div>
            <div class="welcome-banner--claim">
              {{ $t('components.welcome_banner.claim') }}
            </div>
          </h3>
        </div>
        <div
          class="welcome-banner--column"
        >
          <ul class="welcome-banner--bullet-list">
            <li
              v-for="bullet in bullets"
              :key="bullet"
              class="welcome-banner--bullet-item"
            >
              {{ bullet }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-if="showPublicationsAndNews">
      <div class="welcome-banner--card for-logo">
        <WelcomeBannerLogo />
      </div>
      <div class="welcome-banner--card">
        <WelcomeBannerCollapsedListCard
          header-icon-name="folders"
          :translations="$tm('components.welcome_banner.publications')"
          :path-to-all="publicationsPath"
          :path-to-unread-first="unreadPublicationsPath"
          :item-loader="publicationsLoader"
          :collapsed-limit="3"
          @mark-all-as-read="markAllPublicationsAsRead()"
        >
          <template #item="slotProps">
            <WelcomeBannerItemForPublication :item="slotProps.item" />
          </template>
        </WelcomeBannerCollapsedListCard>
      </div>
      <div class="welcome-banner--card">
        <WelcomeBannerCollapsedListCard
          header-icon-name="newspaper"
          :translations="$tm('components.welcome_banner.news')"
          :path-to-all="newsPath"
          :path-to-unread-first="unreadNewsPath"
          :item-loader="newsLoader"
          :collapsed-limit="2"
          @mark-all-as-read="markAllNewsAsRead()"
        >
          <template #item="slotProps">
            <WelcomeBannerItemForNews :item="slotProps.item" />
          </template>
        </WelcomeBannerCollapsedListCard>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import EventBus from '@/events/EventBus';
import { DashboardFavoriteChangeEvent } from '@/events';
import {
  PathToPublicationsWithFilters,
  PathToNewsWithFilters,
  useNewsUnreadCount,
  usePublicationUnreadCount,
  MarkAllPublicationsAsRead,
  MarkAllNewsAsRead,
} from '@/helpers/PublicationHelpers';
import LoadingService from '@/services/LoadingService';
import {
  fetchFavoriteProductGroups,
  fetchPublicationsForProductGroups,
} from '@/api/ProductGroupsApi';
import WelcomeBannerLogo from './Logo/Component.vue';
import WelcomeBannerCollapsedListCard from './CollapsedListCard/Component.vue';
import WelcomeBannerItemForNews from './Item/ForNews/Component.vue';
import WelcomeBannerItemForPublication from './Item/ForPublication/Component.vue';

export default {
  name: 'WelcomeBannerComponent',
  components: {
    WelcomeBannerLogo,
    WelcomeBannerCollapsedListCard,
    WelcomeBannerItemForNews,
    WelcomeBannerItemForPublication,
  },
  data() {
    return {
      favoriteDashboardsObserver: EventBus.on(DashboardFavoriteChangeEvent.EventName, () => {
        this.loadPublicationsAndNews();
      }),
      publicationsLoader: new LoadingService(),
      newsLoader: new LoadingService(),
      newsUnreadCountObserver: null,
      publicationUnreadCountObserver: null,
      productGroupIds: null,
    };
  },
  computed: {
    bullets() {
      return this.$tm('components.welcome_banner.bullets');
    },
    showPublicationsAndNews() {
      return this.$features.PUBLICATIONS && this.$media !== 'print';
    },
    publicationsPath() {
      return PathToPublicationsWithFilters();
    },
    unreadPublicationsPath() {
      const publicationsFilters = {
        productGroups: this.productGroupIds,
        sort: 'is_unread_desc',
      };
      return PathToPublicationsWithFilters(publicationsFilters);
    },
    newsPath() {
      return PathToNewsWithFilters();
    },
    unreadNewsPath() {
      const newsFilters = {
        selectedProductGroups: this.productGroupIds,
        selectedSortDirection: 'is_unread_desc',
      };
      return PathToNewsWithFilters(newsFilters);
    },
  },
  beforeMount() {
    this.loadPublicationsAndNews();
  },
  beforeUnmount() {
    this.favoriteDashboardsObserver.off();
    this.newsUnreadCountObserver?.off();
    this.publicationUnreadCountObserver?.off();
  },
  methods: {
    loadPublicationsAndNews() {
      if (!this.showPublicationsAndNews) return;
      const favoriteProductGroups = fetchFavoriteProductGroups();
      // Calling load immediately (not just after productGroups have loaded), to show loading state
      // in both publication and news cards while the productGroups are loading.
      this.publicationsLoader.load(favoriteProductGroups
        .then((productGroups) => this.loadReactivePublications(productGroups)));
      this.newsLoader.load(favoriteProductGroups
        .then((productGroups) => this.loadReactiveNews(productGroups)));
      favoriteProductGroups.then((productGroups) => {
        this.productGroupIds = productGroups.map((productGroup) => productGroup.id);
      });
    },
    async loadReactiveNews(productGroups) {
      const data = await fetchPublicationsForProductGroups('/api/news', productGroups);
      const unreadCountRef = ref(data.meta.unread_count);
      this.newsUnreadCountObserver?.off();
      this.newsUnreadCountObserver = useNewsUnreadCount(unreadCountRef);
      return {
        entries: data.entries,
        unreadCount: unreadCountRef,
      };
    },
    async loadReactivePublications(productGroups) {
      const data = await fetchPublicationsForProductGroups('/api/publications', productGroups);
      const unreadCountRef = ref(data.meta.unread_count);
      this.publicationUnreadCountObserver?.off();
      this.publicationUnreadCountObserver = usePublicationUnreadCount(unreadCountRef);
      return {
        entries: data.entries,
        unreadCount: unreadCountRef,
      };
    },
    async markAllPublicationsAsRead() {
      await MarkAllPublicationsAsRead({
        product_group_ids: this.productGroupIds,
      });
      this.publicationsLoader.data.unreadCount = 0;
      this.publicationsLoader.data.entries.forEach((entry) => { entry.unread = false; });
    },
    async markAllNewsAsRead() {
      await MarkAllNewsAsRead({
        product_group_ids: this.productGroupIds,
      });
      this.newsLoader.data.unreadCount = 0;
      this.newsLoader.data.entries.forEach((entry) => { entry.unread = false; });
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>