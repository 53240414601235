<template>
  <div>
    <DashboardSearchInput
      v-model="searchText"
      class="select-dashboard--input"
      :placeholder="$t('components.add_notations_to_dashboard_modal.input_placeholder')"
    />
    <DashboardAndClusterList
      :show-clusters="showClusters"
      :search-text="searchText"
      :show-favorites="false"
      :use-link-tags="false"
      :selection="selection"
      :requires-write-permissions="requiresWritePermissions"
      :placeholder-text="$t('components.add_to_dashboard_modal.no_dashboards_placeholder')"
      class="select-dashboard--list"
      @select="onClickDashboard"
    />
    <ul class="dialog--action-list">
      <li class="dialog--action-list-item">
        <ButtonComponent
          variant="ternary"
          @click="onClickCancel"
        >
          {{ $t('components.modals.cancel') }}
        </ButtonComponent>
      </li>
      <li class="dialog--action-list-item">
        <ButtonComponent
          variant="primary"
          :is-disabled="!canSubmit"
          @click="onSubmit"
        >
          {{ $t('components.add_to_dashboard_modal.select_button') }}
        </ButtonComponent>
      </li>
    </ul>
  </div>
</template>

<script>
import DashboardSearchInput from '../DashboardSearchInput/Component.vue';
import DashboardAndClusterList from '../DashboardAndClusterList/Component.vue';

export default {
  name: 'SelectDashboardForm',
  components: {
    DashboardSearchInput,
    DashboardAndClusterList,
  },
  props: {
    showClusters: {
      type: Boolean,
      default: false,
    },
    requiresWritePermissions: {
      type: Boolean,
      default: true,
    },
    dashboardSelection: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'submit',
    'cancel',
  ],
  data() {
    return {
      searchText: '',
      selection: this.dashboardSelection !== null ? this.dashboardSelection : null,
    };
  },
  computed: {
    canSubmit() {
      return this.selection?.dashboard;
    },
  },
  methods: {
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickDashboard({ dashboard, cluster }) {
      this.selection = { dashboard, cluster };
    },
    onSubmit() {
      if (this.canSubmit) {
        this.$emit('submit', this.selection);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>