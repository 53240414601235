<template>
  <NotationListPage
    v-model:selected-graph-limits="controller.graphLimits"
    :user="user"
    :controller="controller"
    :initial-state="initialState"
    :print-title="controller.currentQuery.name"
  />
</template>

<script>
import NotationListPage from '@/components/NotationListPage/Component.vue';
import TopTermSearchController from './controllers/TopTermSearchController';

export default {
  name: 'SearchApp',
  components: { NotationListPage },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    topTerm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      controller: null,
      isPrint: false,
    };
  },
  beforeMount() {
    this.controller = new TopTermSearchController(this.topTerm,
      this.user,
      this.initialState,
      { isMailExport: this.$isMailExport, media: this.$media });
  },
  provide() {
    return {
      user: this.user,
    };
  },
};
</script>
