<template>
  <td
    class="data-table--body-cell is-centered"
    :class="{'can-click': canClick }"
    v-on="eventListeners"
  >
    <Icon
      :name="value.name"
      :variant="value.variant"
      :fixed-width="true"
      class="notation-table--icon"
      :class="value.modifiers"
      :aria-label="value.ariaLabel"
    />
  </td>
</template>

<script>

const validateValue = (value) => ('name' in value && 'variant' in value);

export default {
  name: 'IconColumn',
  props: {
    value: {
      type: Object,
      required: true,
      validator: validateValue,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    canClick() {
      return this.eventListeners?.click;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>