<template>
  <div
    class="filter-dialog"
    :class="{'is-wide': isWide}"
  >
    <FilterSortSection
      v-if="isSortable"
      :direction="sortDirection"
      @change="$emit('apply-sorting', $event)"
    />
    <div class="is-sticky">
      <div class="filter-dialog--head-section">
        <p class="filter-dialog--head-section-title">
          {{ title }}
        </p>
        <div class="filter-dialog--head-section-actions">


          <span
            class="keyboard-key mr-s"
            :class="{'hidden': comboboxEnterAction != 'selectAll'}"
          >⏎</span>
          <button
            class="filter-dialog--head-section-button mr-m"
            data-action="select-all"
            @click="toggleAll(true)"
          >
            {{ $t('components.notation_filters.buttons.select_all') }}
          </button>

          <span
            class="keyboard-key mr-s"
            :class="{'hidden': comboboxEnterAction != 'deselectAll'}"
          >⏎</span>
          <button
            class="filter-dialog--head-section-button"
            data-action="deselect-all"
            @click="toggleAll(false)"
          >
            {{ $t('components.notation_filters.buttons.deselect_all') }}
          </button>
        </div>
      </div>

      <div class="filter-dialog--section no-border">
        <div class="filter-dialog--search-input-container">
          <input
            ref="searchInput"
            v-model="searchTerm"
            name="filter-search"
            type="text"
            class="filter-dialog--search-input"
            autofocus
            autofill="off"
            autocomplete="off"
            @focus="startCombobox"
            @blur="stopCombobox"
            @keydown.enter="comboboxOnEnter"
          >
          <Icon
            class="filter-dialog--search-icon"
            name="search"
          />
        </div>
        <div class="filter-dialog--input-hint">
          <span v-show="searchTerm">{{ $t('components.notation_filters.captions.search_results', filteredItems.length) }} | </span>
          {{ $t('components.notation_filters.captions.with_count', selectedItems.length) }}
        </div>
      </div>
    </div>
    <div class="filter-dialog--section no-vpadding no-hpadding">
      <FilterList
        :items="filteredItems"
        @click-item="toggleItem"
        ref="filterList"
      />
    </div>
  </div>
</template>

<script>

import FilterWithAriaCombobox from './FilterWithAriaCombobox.js';
import FilterList from './FilterList.vue';
import FilterSortSection from './FilterSortSection.vue';

export default {
  name: 'FilterWithSearch',
  mixins: [FilterWithAriaCombobox],
  components: {
    FilterList,
    FilterSortSection,
  },
  props: {
    isSortable: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: 'unsorted',
    },
    title: {
      type: String,
      default: null
    },
    filterValues: {
      type: Array,
      default: () => [],
    },
    isWide: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'apply-sorting'],
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    selectedItems() {
      return this.filterValues.filter((f) => f.selected && !f.implicitlySelected);
    },
    deselectedItems() {
      return this.filterValues.filter((f) => !f.selected);
    },
    filtersInDisplayOrder() {
      return this.filterValues.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    filteredItems() {
      if (this.searchTerm === '') return this.filtersInDisplayOrder;

      const matchSearch = (item) => item.name.toLowerCase().includes(this.searchTerm.toLowerCase());

      return this.filtersInDisplayOrder.filter(matchSearch);
    },

    comboboxInput() {
      return this.$refs.searchInput
    },

    comboboxList() {
      return this.$refs.filterList.$refs.listbox
    }
  }
};
</script>
