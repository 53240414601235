<template>
  <NotationListPage
    v-model:selected-graph-limits="controller.graphLimits"
    :user="user"
    :controller="controller"
    :initial-state="initialState"
    :print-title="$t('page_titles.watch_list')"
  />
</template>

<script>
import NotationListPage from '@/components/NotationListPage/Component.vue';
import WatchListController from './controllers/WatchListController';

export default {
  name: 'WatchListApp',
  components: { NotationListPage },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    forInternalUse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      controller: null,
    };
  },
  beforeMount() {
    this.controller = new WatchListController(this.user,
      this.initialState,
      { isMailExport: this.$isMailExport, media: this.$media, forInternalUse: this.forInternalUse });
  },
  provide() {
    return {
      user: this.user,
    };
  },
};
</script>
