<template>
  <button
    class="tag"
    :class="cssModifier"
    tabindex="-1"
    :aria-label="ariaLabel"
    data-test="dashboard-search-tag"
    @click="$emit('click', $event)"
    @mousemove="$emit('mousemove', $event)"
  >
    <Icon
      class="tag--icon"
      :name="iconForType"
      :fixed-width="true"
      variant="solid"
    />
    <span class="tag--text">
      {{ name }}
    </span>
    <Icon
      v-if="canRemove"
      class="tag--remove"
      name="times"
      :fixed-width="true"
    />
  </button>
</template>

<script>
export default {
  name: 'DashboardSearchListTag',
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'mousemove'],
  computed: {
    ariaLabel() {
      return this.label || this.name;
    },
    iconForType() {
      return this.type === 'market'
        ? 'map-marker-alt'
        : 'tag';
    },
    cssModifier() {
      return [
        this.isActive ? 'is-active' : null,
        `is-${this.type}`,
      ].join(' ');
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>