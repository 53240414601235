import { defineStore } from 'pinia';
import ApiHelpers from '@/api/ApiHelpers';
import NotationApi from '@/api/NotationApi';
import NotationAnalysesApi from '@/api/NotationAnalysesApi';
import UrlStateStore from '@/services/UrlStateStore';
import isEqual from 'lodash.isequal';
import NotificationController from '@/controllers/NotificationController';
import i18n from '@/config/i18n';
import { unitCompositionParams } from '@/helpers/UnitCompositionParamsHelpers';
import moment from 'moment';
import { defaultGraphColors } from '@/helpers/ChartHelpers';

export const useChartViewStore = defineStore('chartView', {
  state: () => ({
    config: {},
    savedConfig: {},
    isLoading: true,
    hasError: false,
    savedAnalysisId: null,
    quotesPerYear: [],
    notation: {},
    unit: null,
    unitConvertedFrom: null,
    unselectedYears: [],
    isSaving: false,
    colors: defaultGraphColors(),
  }),
  getters: {
    title() {
      return this.config.title;
    },
    saveConfigDisabled() {
      return isEqual(this.config, this.savedConfig);
    },
    configUrl() {
      return `season-line-chart-configuration${UrlStateStore.queryStringForState({ config: this.config, savedAnalysisId: this.savedAnalysisId })}`;
    },
    datasets() {
      return this.quotesPerYear.map((quotesPerYear, index) => {
        // We will assign the same two years to the dates from each quotes group, so that they are
        // shown across an x-axis spanning only 12 months (starting at the selected startMonthId)
        // Since some of the selected years might be leap years having 29 days in february,
        // we make sure that the common year we assign to the dates in february is a leap year.
        // So, if the startMonth is january or february we assign 2020 (a leap year) and than 2021.
        // Otherwise we assign 2019 and then 2020.
        const commonFirstYear = this.config.startMonthId <= 2 ? 2020 : 2019;
        const quotesStartYear = quotesPerYear.beginDate.getFullYear();
        const endOfFirstYear = new Date(quotesStartYear, 11, 31);

        const data = quotesPerYear.quotes.map((quote) => {
          const { date } = quote;
          const commonYear = date <= endOfFirstYear ? commonFirstYear : commonFirstYear + 1;
          date.setYear(commonYear);

          return {
            x: date,
            y: quote.value,
          };
        });

        return {
          title: quotesPerYear.title,
          data,
          fill: false,
          yAxisID: 'y-axis-1',
          borderColor: quotesPerYear.color,
          backgroundColor: quotesPerYear.color,
          borderWidth: index === 0 ? 3 : 2,
          lineTension: 0,
          showLine: true,
        };
      });
    },
    datasetsForChart() {
      return this.datasets.filter((dataset) => !this.unselectedYears.includes(dataset.title));
    },
    quotesPerYearForLegend() {
      return this.quotesPerYear.map((quotes) => ({
        ...quotes,
        selected: !this.unselectedYears.find((year) => year === quotes.title),
      }));
    },
    analysisParams() {
      return {
        analysis_type: 'season_line_chart',
        title: this.config.title,
        configuration: {
          ...this.config,
        },
      };
    },
  },
  actions: {
    fetchNotation(notationID) {
      NotationApi()
        .findNotation(notationID)
        .then((notation) => {
          this.notation = notation;
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchQuotesPerYear() {
      const toUnitComposition = unitCompositionParams(this.config.compositeUnit);
      return NotationApi()
        .quotesPerYear(this.config.notationID, {
          years: this.config.selectedYears,
          toUnitComposition,
          startMonthId: this.config.startMonthId,
        })
        .then((response) => {
          this.unit = response.unit;
          this.unitConvertedFrom = response.unitConvertedFrom;
          const currentDate = moment().toDate();
          const filteredQuotesPerYear = response.quotes
            .filter((quotes) => quotes.beginDate <= currentDate);
          this.quotesPerYear = filteredQuotesPerYear.reverse().map((quote, index) => ({
            ...quote,
            color: this.colors[index],
          }));
        });
    },
    setYearSelected(selectedYear) {
      if (selectedYear.selected === true) {
        this.unselectedYears = this.unselectedYears
          .filter((year) => year !== selectedYear.title);
      }
      if (selectedYear.selected === false && !this.unselectedYears
        .includes(selectedYear.title)) {
        this.unselectedYears.push(selectedYear.title);
      }
      UrlStateStore.set('unselected-years', this.unselectedYears);
    },
    async loadSavedConfiguration(savedAnalysisId) {
      const savedAnalysis = await NotationAnalysesApi().loadSavedAnalysis(savedAnalysisId);
      return savedAnalysis.configuration;
    },
    async setConfiguration(config, savedAnalysisId) {
      try {
        this.isLoading = true;
        this.savedAnalysisId = savedAnalysisId;
        if (savedAnalysisId !== null) {
          this.savedConfig = await this.loadSavedConfiguration(this.savedAnalysisId);
        }
        this.config = config !== null ? config : { ...this.savedConfig };
        const promises = [];
        promises.push(this.fetchNotation(this.config.notationID));
        promises.push(this.fetchQuotesPerYear());
        await Promise.all(promises);
        this.unselectedYears = UrlStateStore.get('unselected-years', []);
        this.hasError = false;
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async saveConfiguration(title) {
      try {
        this.isSaving = true;
        if (this.savedAnalysisId === null) {
          this.config.title = title;
          const savedAnalysis = await NotationAnalysesApi().create({
            analysisParams: this.analysisParams,
          });
          this.savedAnalysisId = savedAnalysis.id;
          NotificationController.success(i18n.t('apps.multi_line_chart_view.save_analysis'));
        } else {
          await NotationAnalysesApi().update({
            id: this.savedAnalysisId,
            analysisParams: this.analysisParams,
          });
          NotificationController.success(i18n.t('apps.multi_line_chart_view.update_analysis'));
        }
        this.savedConfig = { ...this.config };
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
});
