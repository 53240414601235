<template>
  <div
    class="form--box"
  >
    <label
      v-if="label"
      class="form--label"
      :for="uid"
    >
      {{ label }}
    </label>
    <TrixRails
      class="rich-text-input"
      :value="modelValue"
      :editor-id="uid"
      :disabled="isLoading"
      @input="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script>
import axios from 'axios';
import HasUid from '@/mixins/HasUid';
import TrixRails from '@/components/TrixRails/Component.vue';
import ApiHelpers from '@/api/ApiHelpers';

export default {
  name: 'RichTextInput',
  components: {
    TrixRails,
  },
  mixins: [HasUid],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    contentUrl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      currentRequestId: null,
      isLoading: false,
    };
  },
  watch: {
    contentUrl: {
      immediate: true,
      handler(url) {
        if (url) {
          this.fetchContent(url);
        } else {
          this.stopContentRequest();
        }
      },
    },
  },
  methods: {
    stopContentRequest() {
      this.currentRequestId = null;
      this.isLoading = false;
    },
    fetchContent(url) {
      const uid = `${new Date().getTime()}-${Math.random()}`;
      this.currentRequestId = uid;
      this.isLoading = true;
      axios.get(url)
        .then((response) => {
          if (uid === this.currentRequestId) {
            this.$emit('update:modelValue', response.data.content);
          }
        })
        .catch((error) => {
          ApiHelpers.handleError(error);
        })
        .finally(() => {
          if (uid === this.currentRequestId) {
            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>