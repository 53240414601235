// Parses a number and returns a string with the number formatted with the specified locale and options.
// If the number is NaN or Infinity, it will return the original value.
export const convertToLocale = (value, locale, options) => {
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue) || !isFinite(parsedValue)) {
    return value;
  }
  return parsedValue.toLocaleString(locale, options);
};

export default convertToLocale;
