<template>
  <Card>
    <div class="video-tutorial-card--header">
      <h2><a :href="videoFullPageURL">{{ title }}</a></h2>
    </div>
    <div class="video-tutorial-card--body">
      <iframe
        :src="videoURL"
        class="video-player"
        frameborder="0"
        allow="fullscreen"
        allowfullscreen
      />
    </div>
  </Card>
</template>

<script>
export default {
  name: 'VideoTutorialCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
    // see https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters
    embeddingParameters: {
      type: Object,
      default: () => ({
        title: 0,
        byline: 0,
        portrait: 0,
        badge: 0,
      }),
    },
  },
  computed: {
    urlParameters() {
      return Object.entries(this.embeddingParameters).map(([key, value]) => `${key}=${value}`).join('&');
    },
    videoURL() {
      return `https://player.vimeo.com/video/${this.videoId}?${this.urlParameters}`;
    },
    videoFullPageURL() {
      return `https://vimeo.com/${this.videoId}`;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>