<template>
  <IntroApp
    :user="user"
    :favorite-dashboard-count="favoriteDashboardCount"
    :initial-state="initialState"
    :countries="countries"
    :hr-dimensions="hrDimensions"
  />
</template>

<script>
import IntroApp from '@/apps/Intro/App.vue';
import { ViewNews } from '@/helpers/PublicationHelpers';

export default {
  name: 'ShowNewsApp',
  components: {
    IntroApp,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    favoriteDashboardCount: {
      type: Number,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    news: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    hrDimensions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    ViewNews(this.news);
  },
};
</script>
