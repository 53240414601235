class EventBase {
  static get EventName() {
    throw new Error(`Missing implementation for ${this.name}.EventName`);
  }

  get EventName() {
    return this.constructor.EventName;
  }
}

export default EventBase;
