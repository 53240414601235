import axios from 'axios';

const RegionsApi = () => ({
  regions() {
    return axios.get('/api/regions')
      .then((response) => response.data);
  },
});

export default RegionsApi;
