import { defineStore } from 'pinia';
import { defaultGraphLimits } from '@/components/QuotesChart/Defaults';
import ApiHelpers from '@/api/ApiHelpers';
import NotationApi from '@/api/NotationApi';
import NotationAnalysesApi from '@/api/NotationAnalysesApi';
import NotificationController from '@/controllers/NotificationController';
import moment from 'moment';
import UrlStateStore, { serializeDatesForUrl } from '@/services/UrlStateStore';
import i18n from '@/config/i18n';
import isEqual from 'lodash.isequal';
import { unitCompositionParams } from '@/helpers/UnitCompositionParamsHelpers';

export const useChartViewStore = defineStore('chartView', {
  state: () => ({
    config: {},
    savedConfig: {},
    isLoading: true,
    hasError: false,
    savedAnalysisId: null,
    quotesPerNotation: {},
    notations: {},
    unselectedNotationIDs: [],
    graphLimits: {},
    currentRange: null,
    earliestQuoteDate: null,
    isSaving: false,
    showKpis: false,
    showIndexView: false,
    isUpdating: false,
    index: {},
  }),
  getters: {
    datasets() {
      const quotesLeftAxis = this.quotesForAxis(this.config.axis.left, 'y-axis-1');
      const quotesRightAxis = this.quotesForAxis(this.config.axis.right, 'y-axis-2');
      return quotesLeftAxis.concat(quotesRightAxis);
    },
    title() {
      return this.config.title;
    },
    primaryNotationUnit() {
      return this.labelForAxis(this.config.axis.left, 'y-axis-1');
    },
    secondaryNotationUnit() {
      return this.labelForAxis(this.config.axis.right, 'y-axis-2');
    },
    notationsLeftAxis() {
      return this.notationsForAxis(this.config.axis.left);
    },
    notationsRightAxis() {
      return this.notationsForAxis(this.config.axis.right);
    },
    earliestQuoteDateYear() {
      const earliestQuoteDatesOfNotations = Object.values(this.notations).map(
        (notation) => new Date(notation.earliest_date),
      );
      const earliestQuoteDate = moment(Math.min(...earliestQuoteDatesOfNotations));
      return earliestQuoteDate.year();
    },
    initialGraphLimits() {
      const graphLimits = defaultGraphLimits();
      return {
        minDate: moment(Math.max(graphLimits.minDate, this.earliestQuoteDate)),
        maxDate: graphLimits.maxDate,
        startDate: moment(Math.max(graphLimits.startDate, this.earliestQuoteDate)),
        endDate: graphLimits.endDate,
      };
    },
    selectedGraphLimits() {
      return UrlStateStore.get('graph-limits', this.initialGraphLimits);
    },
    selectedEarliestQuoteDate() {
      const allSelectedQuotes = this.datasets
        .map((dataset) => dataset.data).flat().map((quote) => quote.x);
      return allSelectedQuotes.length > 0 ? moment(Math.min(...allSelectedQuotes)) : null;
    },
    saveConfigDisabled() {
      return isEqual(this.config, this.savedConfig);
    },
    configUrl() {
      return `multi-line-chart-configuration${UrlStateStore.queryStringForState({ config: this.config, savedAnalysisId: this.savedAnalysisId })}`;
    },
    analysisParams() {
      return {
        analysis_type: 'multi_line_chart',
        title: this.config.title,
        configuration: {
          ...this.config,
        },
      };
    },
    indexPeriod() {
      if (!this.showIndexView) return {};
      return {
        startDate: new Date(this.index.year, this.index.monthId - 1, 1),
        endDate: new Date(this.index.year, this.index.monthId, 1),
      };
    },
  },
  actions: {
    quotesForAxis(axis, yAxisID) {
      const selectedLines = axis.lines
        .filter((line) => !this.unselectedNotationIDs.includes(line.notation_id)
          && this.quotesPerNotation[line.notation_id].quotes.length !== 0);

      return selectedLines.map((line) => {
        const { quotes } = this.quotesPerNotation[line.notation_id];
        const quotesForGraph = quotes.map((quote) => ({ x: quote.date, y: quote.value }));

        return {
          data: quotesForGraph,
          fill: false,
          yAxisID,
          borderColor: line.color,
          backgroundColor: line.color,
          borderWidth: 2,
          lineTension: 0,
          showLine: true,
          steppedLine: true,
        };
      });
    },
    async updateIndexMonthId(monthId) {
      this.updateIndex({
        monthId,
      });
    },
    async updateIndexYear(year) {
      this.updateIndex({
        year,
      });
    },
    async changeIndexDate(indexDate) {
      this.updateIndex({
        monthId: indexDate.getMonth() + 1,
        year: indexDate.getFullYear(),
      });
    },
    async updateIndex({
      monthId = this.index.monthId,
      year = this.index.year,
    }) {
      try {
        this.isUpdating = true;
        this.index.monthId = monthId;
        this.index.year = year;
        await this.fetchQuotesForAxes();
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    notationsForAxis(axis) {
      return axis.lines.map((line) => ({
        color: line.color,
        unit: this.quotesPerNotation[line.notation_id].originalUnit
          || this.quotesPerNotation[line.notation_id].unit,
        unitConvertedFrom: this.quotesPerNotation[line.notation_id].unitConvertedFrom,
        notation: this.notations[line.notation_id],
        selected: !this.unselectedNotationIDs.find((id) => id === line.notation_id),
        withQuotes: this.quotesPerNotation[line.notation_id].quotes.length > 0,
      }));
    },
    toggleShowKpis(showKpis) {
      this.showKpis = showKpis;
    },
    labelForAxis(axis, yAxisID) {
      if (this.quotesForAxis(axis, yAxisID).length === 0) return null;
      const units = axis.lines.map((line) => this.quotesPerNotation[line.notation_id].unit)
        .filter((unit) => unit !== null);

      const uniqueUnits = [...new Set(units)];
      if (uniqueUnits.length === 0) {
        return null;
      } if (uniqueUnits.length === 1) {
        return uniqueUnits[0];
      }
      return i18n.t('apps.multi_line_chart_view.mixed_units');
    },
    async setIndexView(showIndexView) {
      try {
        this.isLoading = true;
        this.showIndexView = showIndexView;
        if (this.showIndexView === true) {
          const defaultIndexYear = moment().subtract(3, 'years').year();
          if (this.earliestQuoteDateYear > defaultIndexYear) {
            this.index.year = this.earliestQuoteDateYear;
          } else {
            this.index.year = defaultIndexYear;
          }
          this.index.monthId = 1;
          await this.fetchQuotesForAxes();
        } else {
          this.index.monthId = null;
          this.index.year = null;
          await this.fetchQuotesForAxes();
        }
        const updatedGraphLimits = this.initialGraphLimits;
        this.setGraphLimits(updatedGraphLimits);
        this.setCurrentRange(null);
        this.unselectedNotationIDs = UrlStateStore.get('unselected-notation-ids', []);
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchQuotesForAxes() {
      const promises = [];
      promises.push(...this.fetchQuotes('left'));
      promises.push(...this.fetchQuotes('right'));
      await Promise.all(promises);
    },
    fetchQuotes(axisName) {
      const axisObject = this.config.axis[axisName];
      const toUnitComposition = unitCompositionParams(axisObject.composite_unit);
      const indexPeriod = {
        month_id: this.index.monthId,
        year: this.index.year,
      };
      return axisObject?.lines.map((line) => NotationApi()
        .quotes(line.notation_id, { startDate: '1990-01-01', toUnitComposition, indexPeriod })
        .then((result) => result)
        .then((quotes) => {
          this.quotesPerNotation[line.notation_id] = {
            quotes: quotes.quotes,
            unit: quotes.unit,
            unitConvertedFrom: quotes.unitConvertedFrom || null,
            originalUnit: quotes.originalUnit || null,
          };
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.quotesPerNotation[line.notation_id] = {
              quotes: [],
              unit: null,
              unitConvertedFrom: null,
            };
          } else {
            throw error;
          }
        }));
    },
    fetchNotations(axisName) {
      const axisObject = this.config.axis[axisName];
      return axisObject.lines.map((line) => NotationApi()
        .findNotation(line.notation_id)
        .then((notation) => { this.notations[line.notation_id] = notation; })
        .catch((error) => {
          if (error.response.status === 404) {
            this.notations[line.notation_id] = {
              id: line.notation_id,
              notFound: true,
            };
          } else {
            throw error;
          }
        }));
    },
    async loadSavedConfiguration(savedAnalysisId) {
      const savedAnalysis = await NotationAnalysesApi().loadSavedAnalysis(savedAnalysisId);
      return savedAnalysis.configuration;
    },
    async setConfiguration(config, savedAnalysisId, index, showKpis) {
      try {
        this.isLoading = true;
        this.savedAnalysisId = savedAnalysisId;
        this.showKpis = showKpis;
        this.index = index;
        if (savedAnalysisId !== null) {
          this.savedConfig = await this.loadSavedConfiguration(this.savedAnalysisId);
        }
        this.config = config !== null ? config : { ...this.savedConfig };
        if (this.index.monthId !== null || this.index.year !== null) {
          this.showIndexView = true;
        }
        const promises = [];
        promises.push(...this.fetchQuotes('left'));
        promises.push(...this.fetchQuotes('right'));
        promises.push(...this.fetchNotations('left'));
        promises.push(...this.fetchNotations('right'));
        await Promise.all(promises);
        this.resetEarliestQuoteDate();
        this.currentRange = UrlStateStore.get('range', null);
        this.unselectedNotationIDs = UrlStateStore.get('unselected-notation-ids', []);
        this.hasError = false;
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    setGraphLimits(graphLimits) {
      this.graphLimits = graphLimits;
      UrlStateStore.set('graph-limits', serializeDatesForUrl(this.graphLimits));
    },
    setCurrentRange(range) {
      this.currentRange = range;
      UrlStateStore.set('range', this.currentRange);
    },
    setLineSelected(selectedLine) {
      if (selectedLine.selected === true
        && this.notations[selectedLine.notationID].notFound !== true) {
        this.unselectedNotationIDs = this.unselectedNotationIDs
          .filter((id) => id !== selectedLine.notationID);
      }
      if (selectedLine.selected === false && !this.unselectedNotationIDs
        .includes(selectedLine.notationID)) {
        this.unselectedNotationIDs.push(selectedLine.notationID);
      }
      UrlStateStore.set('unselected-notation-ids', this.unselectedNotationIDs);
    },
    resetEarliestQuoteDate() {
      const allQuotes = Object.values(this.quotesPerNotation).map(
        (quotesPerNotation) => quotesPerNotation.quotes,
      ).flat().map((quote) => quote.date);
      const minDate = moment(Math.min(...allQuotes));
      this.earliestQuoteDate = minDate.isValid() ? minDate : null;
    },
    async saveConfiguration(title) {
      try {
        this.isSaving = true;
        if (this.savedAnalysisId === null) {
          this.config.title = title;
          const savedAnalysis = await NotationAnalysesApi().create({
            analysisParams: this.analysisParams,
          });
          this.savedAnalysisId = savedAnalysis.id;
          NotificationController.success(i18n.t('apps.multi_line_chart_view.save_analysis'));
        } else {
          await NotationAnalysesApi().update({
            id: this.savedAnalysisId,
            analysisParams: this.analysisParams,
          });
          NotificationController.success(i18n.t('apps.multi_line_chart_view.update_analysis'));
        }
        this.savedConfig = { ...this.config };
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
});
