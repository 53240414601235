<template>
  <TableHeadCell
    :title="displayName"
    :tooltip="tooltip"
  />
</template>

<script>
import TableHeadCell from '../TableHeadCell.vue';

export default {
  name: 'StaticColumnHead',
  components: {
    TableHeadCell,
  },
  props: {
    displayName: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: () => null,
    },
  },
};
</script>
