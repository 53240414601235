<template>
  <div>
    <SpinningLoader v-if="controller.isLoading" />
    <ul v-else>
      <li
        v-for="dashboard in controller.dashboardsForTileView"
        :key="dashboard.id"
        class="intro-tile-view--tile"
      >
        <NotationCard
          class="intro-tile-view--card"
          :show-dashboard-header-modal-button="false"
          :primary-notation-loader="useLoadedNotationLoader(dashboard.notations.primary)"
          :secondary-notation-loader="useLoadedNotationLoader(dashboard.notations.secondary)"
          :show-date-slider="false"
          :selected-currency-id="preferredCurrencyUnitId(dashboard)"
          :selected-graph-limits="graphLimits"
        >
          <template #header>
            <IntroTileViewTileHeader :dashboard="dashboard" />
          </template>
        </NotationCard>
      </li>
    </ul>
  </div>
</template>

<script>
import { NotationCard } from '@/components';
import { defaultGraphLimits } from '@/components/QuotesChart/Defaults';
import IntroTileViewTileHeader from './TileHeader/Component.vue';

export default {
  name: 'IntroTileViewComponent',
  components: {
    NotationCard,
    IntroTileViewTileHeader,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      useLoadedNotationLoader(notation) {
        return {
          notation,
          get hasSelectedNotation() {
            return !!notation;
          },
        };
      },
      graphLimits: {
        ...defaultGraphLimits(),
      },
    };
  },
  methods: {
    preferredCurrencyUnitId(dashboard) {
      return dashboard.preferred_currency_unit?.id;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>