import { reactive } from 'vue';
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';

const lastModifiedDateForFile = (file) => {
  // Try to get last modified date from the selected file, falling back to current time.
  // IE only supports lastModifiedDate, while that is deemed deprecated by the others and
  // even unsupported by Firefox, so we try both.
  let lastModifiedDate = new Date();
  if (file.lastModified) {
    lastModifiedDate = new Date(file.lastModified);
  } else if (file.lastModifiedDate) {
    lastModifiedDate = file.lastModifiedDate;
  }
  return lastModifiedDate;
};

export default class PublicationsUploadModalController {
  constructor(closeModalCallback) {
    this._closeModalCallback = closeModalCallback;
    this._cancelToken = axios.CancelToken.source();
    this._state = reactive({
      files: [],
      isLoading: false,
      uploadStarted: false,
    });
  }

  get files() {
    return this._state.files;
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get uploadStarted() {
    return this._state.uploadStarted;
  }

  get uploadFinished() {
    return this.uploadStarted
      && this.files.every((file) => file.status === 'done' || file.status === 'failed');
  }

  get createStats() {
    return this.statsForAction('create');
  }

  get replaceStats() {
    return this.statsForAction('replace');
  }

  get canStartUpload() {
    return this.files.length > 0 && !this.isLoading && !this.uploadStarted;
  }

  statsForAction(action) {
    const groupFiles = this.files.filter((file) => file.action === action);
    return {
      total: groupFiles.length,
      done: groupFiles.filter((file) => file.status === 'done').length,
      failed: groupFiles.filter((file) => file.status === 'failed').length,
    };
  }

  cancel() {
    this._cancelToken.cancel('Cancelled by user');
    this._closeModalCallback();
  }

  addFiles(files) {
    [...files].forEach((file) => {
      if (this.fileIndexByFileName(file.name) < 0) {
        this.files.push(reactive({
          file,
          action: 'unknown',
          matchingPublication: null,
          status: null,
          progress: null,
        }));
      }
    });
    this._updateFileMatches();
  }

  async _updateFileMatches() {
    try {
      this._state.isLoading = true;
      const response = await axios.post('/api/publications/for_file_names', {
        file_names: this.files.map((file) => file.file.name),
      });
      this.files.forEach((file) => {
        file.matchingPublication = response.data.find((pub) => pub.file_name === file.file.name);
        if (file.matchingPublication) {
          file.action = 'replace';
        } else {
          file.action = 'create';
        }
      });
    } finally {
      this._state.isLoading = false;
    }
  }

  startUpload() {
    if (this.uploadStarted) return;
    this._state.uploadStarted = true;
    this.files.forEach((file) => {
      file.status = 'pending';
    });
    for (let i = 0; i < 5; i += 1) {
      this._uploadNextFile();
    }
  }

  async _uploadNextFile() {
    const file = this.files.find((f) => f.status === 'pending');
    if (!file) return;

    file.status = 'uploading';
    file.progress = 0;

    const formData = new FormData();
    formData.append('publication[file]', file.file);
    formData.append('publication[published_at]', lastModifiedDateForFile(file.file).toISOString());

    let route = '/api/publications';
    if (file.action === 'create') {
      formData.append('publication[file_name]', file.file.name);
    } else if (file.action === 'replace') {
      route = `/api/publications/${file.matchingPublication.id}/replace_file`;
    } else {
      throw new Error(`Tried to process file with incorrect action ${file.action}`);
    }

    try {
      await axios.post(route, formData, {
        cancelToken: this._cancelToken.token,
        onUploadProgress: (e) => {
          file.progress = (e.loaded * 100) / e.total;
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      file.status = 'done';
    } catch (error) {
      ApiHelpers.logUnexpectedError(error);
      file.status = 'failed';
    }

    this._uploadNextFile();
  }

  fileIndexByFileName(fileName) {
    return this.files.findIndex((file) => file.file.name === fileName);
  }

  removeFile(file) {
    const index = this.fileIndexByFileName(file.file.name);
    if (index < 0) return;
    this.files.splice(index, 1);
  }
}
