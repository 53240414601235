import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import Container from './Container';
import ExportWrapper from './ExportWrapper';
import Export from './Export';
import Table from './Table';
import TableTools from './TableTools';
import Column from './Column';

import WrappedTabs from './Tabs';
import WrappedTab from './Tab';
import ViewToggle from './ViewToggle';
import HumanRightsRiskLegend from '@/components/HumanRightsRisk/HumanRightsRiskLegend/Component';

export default {
  components: {
    'view-toggle': ViewToggle,
    'hrr-container': Container,
    'hrr-export': ExportWrapper,
    'hrr-table': Table,
    'table-tools': TableTools,
    column: Column,
    export: Export,

    tab: WrappedTab,
    tabs: WrappedTabs,

    'human-rights-risk-legend': HumanRightsRiskLegend,
  },
  setup() {
    const { t } = useI18n();
    const view = ref(undefined);

    const handleViewChanged = (newView) => {
      view.value = newView;
    };

    return { t, view, handleViewChanged };
  },
  provide() {
    return { t: this.t, view: this.view };
  },
};
