import {
  watchEffect,
  reactive,
  computed,
} from 'vue';
import moment from 'moment';
import NotationApi from '@/api/NotationApi';

class ReactiveNotationDetails {
  #currentRequestId = null;

  constructor(notationLoaderRef, currencyRef, customDateRef) {
    this.state = reactive({
      notationLoader: notationLoaderRef,
      toCurrency: currencyRef,
      customDate: customDateRef,
      hasLoaded: false,
      data: null,
      startDate: null,
    });
    watchEffect(() => {
      this.#fetchQuotes();
    });

    this.earliestQuoteDate = computed(() => moment(this.notation?.earliest_date));
  }

  get hasSelectedNotation() {
    return this.state.notationLoader.hasSelectedNotation;
  }

  get notation() {
    return this.state.notationLoader.notation;
  }

  get hasLoaded() {
    return this.state.hasLoaded;
  }

  get data() {
    return this.state.data;
  }

  ensureQuotesAreLoaded({ startDate }) {
    this.state.startDate = startDate;
  }

  #fetchQuotes() {
    const requestId = `${new Date().getTime()}-${Math.random()}`;
    this.#currentRequestId = requestId;

    if (!this.notation?.id) {
      this.state.hasLoaded = false;
      this.state.data = null;
    } else {
      NotationApi()
        .quotes(this.notation.id, {
          toCurrency: this.state.toCurrency,
          customDate: this.state.customDate,
          startDate: this.state.startDate,
        }).then(
          (result) => {
            if (!requestId === this.#currentRequestId) return;
            this.state.data = result;
            this.state.hasLoaded = true;
          },
        );
    }
  }
}

const useNotationDetails = (...params) => reactive(new ReactiveNotationDetails(...params));

export default useNotationDetails;
