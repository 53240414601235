<template>
  <div
    v-click-outside="() => { showDatePickerPopup = false }"
    class="form--box"
  >
    <label
      v-if="label"
      class="form--label"
      :for="uid"
    >
      {{ label }}
    </label>
    <input
      :id="uid"
      type="text"
      class="form--field"
      :value="valueFormatted"
      readonly
      :required="required"
      @focus="() => { showDatePickerPopup = true }"
    >

    <DatePickerPopup
      v-if="showDatePickerPopup"
      :date="modelValue"
      @select-date="onDateSelected"
    />
  </div>
</template>

<script>
import moment from 'moment';
import HasUid from '@/mixins/HasUid';
import DatePickerPopup from '@/components/DatePickerPopup/Component.vue';

export default {
  name: 'DateInput',
  components: {
    DatePickerPopup,
  },
  mixins: [HasUid],
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Date,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showDatePickerPopup: false,
    };
  },
  computed: {
    valueFormatted() {
      return moment(this.modelValue).format('LL');
    },
  },
  methods: {
    onDateSelected(newDate) {
      this.$emit('update:modelValue', newDate);
      this.showDatePickerPopup = false;
    },
  },
};
</script>
