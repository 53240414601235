<template>
  <g
    ref="root"
    :class="`chart--axis orientation-${orientation}`"
    :transform="`translate(${left}, ${top})`"
  >
    <g
      v-if="scale"
      ref="axis"
    />
  </g>
</template>

<script>
import * as d3 from 'd3';
import AxisConfig from '../../../models/charts/AxisConfig';

const validateOrientation = (value) => [
  'left', 'top', 'right', 'bottom',
].indexOf(value) !== -1;

export default {
  name: 'PlotAxis',
  props: {
    scale: {
      type: Function,
      default: null,
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    orientation: {
      type: String,
      default: 'left',
      validator: validateOrientation,
    },
    config: {
      type: Object,
      default: () => AxisConfig.default(),
    },
  },
  emits: ['resize'],
  computed: {
    isVertical() {
      return this.orientation === 'left' || this.orientation === 'right';
    },
    isHorizontal() {
      return !this.isVertical;
    },
  },
  watch: {
    scale() { this.render(); },
    top() { this.render(); },
    left() { this.render(); },
    orientation() { this.render(); },
    format() { this.render(); },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      if (this.scale) {
        const axis = this.createAxisForOrientation();
        this.config.createAxis(axis, this.$refs.axis);
      } else {
        d3.select(this.$refs.axis).selectAll('g').remove();
      }
      const requiredSize = this.isVertical
        ? this.requiredWidth()
        : this.requiredHeight();
      this.$emit('resize', requiredSize);
    },
    createAxisForOrientation() {
      switch (this.orientation) {
        case 'left': return d3.axisLeft(this.scale);
        case 'top': return d3.axisTop(this.scale);
        case 'right': return d3.axisRight(this.scale);
        case 'bottom': return d3.axisBottom(this.scale);
        default: return null;
      }
    },
    requiredWidth() {
      return d3.select(this.$refs.root).node().getBBox().width;
    },
    requiredHeight() {
      return d3.select(this.$refs.root).node().getBBox().height;
    },
  },
};
</script>
