<template>
  <SpinningLoader
    v-if="itemLoader.isLoading"
    class="list-card--spinning-loader"
  />
  <p
    v-else-if="showPlaceholder"
    class="list-card--placeholder"
  >
    {{ translations.no_results }}
  </p>
  <div
    v-else
    class="list-card--scrollarea"
  >
    <ul class="list-card--list">
      <li
        v-for="item in visibleItems"
        :key="item.id"
        class="list-card--list-item"
      >
        <slot
          name="item"
          :item="item"
        />
      </li>
    </ul>
    <div
      v-if="showFooter"
      class="list-card--footer"
    >
      <a
        class="button-secondary"
        :href="pathToAll"
      >
        {{ translations.link_to_all }}
        <Icon
          class="list-card--caret"
          name="arrow-right"
          variant="regular"
        />
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CollapsedListCardScrollarea',
  props: {
    translations: {
      type: Object,
      required: true,
    },
    pathToAll: {
      type: String,
      required: true,
    },
    itemLoader: {
      type: Object,
      required: true,
    },
    collapsedLimit: {
      type: Number,
      default: null,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      return this.itemLoader.data?.entries || [];
    },
    visibleItems() {
      if (this.isCollapsed) {
        return this.items.slice(0, this.collapsedLimit);
      }
      return this.items;
    },
    canExpand() {
      const itemCountWithFooter = this.items.length + 1;
      return itemCountWithFooter > this.collapsedLimit;
    },
    isExpanded() {
      return !this.isCollapsed;
    },
    showFooter() {
      return this.isExpanded || !this.canExpand;
    },
    showPlaceholder() {
      return this.items.length === 0;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>