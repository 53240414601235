/**
 * This is an extension for all of the javascript controller classes
 * to handle filtering by columns on the client side.
 * Usage:
 *
 *   import FilterableControllerMixin from "@/utils/FilterableControllerMixin"
 *   class SomeTableController {
 *     get rawCollection() {
 *       // return a raw, unfiltered collection of records
 *       return this._collection
 *     }
 *
 *     set collection(value) {
 *       // set a filtered collection
 *       this.state.notations = value;
 *     }
 *
 *     constructor () {
 *       this.filters = {}
 *       // define columns - note that columns with a filter should have the
 *       // "filterable" attribute set to true
 *       this.columns = [...]
 *       this.applyFilterOptions(this.columns, this.filters)
 *     }
 *   }
 *   FilterableControllerMixin.apply(SomeTableController)
 */

const Mixin = {
  onFiltersChanged() {
    let filteredCollection = this.rawCollection
    Object.keys(this.filters).forEach((attribute) => {
      filteredCollection = this.applyFilter(filteredCollection, attribute)
    })
    this.notations = filteredCollection
  },

  applyFilter(collection, attribute) {
    let filterOptions = this.filters[attribute]
    if (!filterOptions) { return collection }

    let selectedValues = filterOptions.values.filter(item => item.selected).map(item => item.value)
    if (!selectedValues?.length) {
      filterOptions.isActive = false
      return collection
    }

    filterOptions.isActive = true
    return collection.filter ((item)=>{
      return selectedValues.indexOf(item[attribute]) >= 0
    })
  },

  applyFilterOptions(columns, filters) {
    columns.forEach((columnOptions) => {
      if (!columnOptions.filterable) {
        return
      }

      let attribute = columnOptions.key
      filters[attribute] = {
        values: this.rawCollection.map((item)=>{
          let title = columnOptions.valueBuilder(item)
          return {
            name: title.text || title.title || title,
            value: item[attribute],
            selected: false
          }
        })
      }
    })
    this.afterFilterOptionsApplied()
  },

  afterFilterOptionsApplied() {
    // Callback after filters options are set.
    // Could be used to e.g. apply default filters
    // from the URL
  }
}

// By using a custom interface we could later on also define
// custom properties on the existing class
export default {
  apply(jsClass) {
    Object.assign(jsClass.prototype, Mixin);
  }
}
