<template>
  <div
    class="dialog--window"
    :class="{'is-wide': forPublishedDashboard}"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('components.edit_dashboard_modal.heading', { name: initialDashboard.full_name }) }}
      </div>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <form @submit="save($event)">
          <PublishedDashboardForm
            v-if="forPublishedDashboard"
            ref="form"
            :initial-dashboard="initialDashboard"
          />
          <PrivateDashboardForm
            v-else
            ref="form"
            :initial-dashboard="initialDashboard"
          />

          <ul class="dialog--action-list">
            <li class="dialog--action-list-item">
              <ButtonComponent
                variant="ternary"
                @click="cancel()"
              >
                {{ $t('components.modals.cancel') }}
              </ButtonComponent>
            </li>
            <li class="dialog--action-list-item">
              <ButtonComponent
                type="submit"
                variant="primary"
              >
                {{ $t('components.edit_dashboard_modal.save') }}
              </ButtonComponent>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PrivateDashboardForm from './PrivateDashboardForm.vue';
import PublishedDashboardForm from './PublishedDashboardForm.vue';

export default {
  name: 'EditDashboardModal',
  components: {
    PrivateDashboardForm,
    PublishedDashboardForm,
  },
  props: {
    initialDashboard: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'close',
    'save',
  ],
  computed: {
    forPublishedDashboard() {
      return this.initialDashboard.kind === 'published';
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    save(e) {
      e.preventDefault();
      this.$emit('save', this.$refs.form.dashboard);
    },
  },
};
</script>
