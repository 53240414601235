<template>
  <RichTextModal
    :title="publication.display_name"
    :sub-title="subTitle"
    :published-at="publication.published_at"
    :is-loading="isLoading"
    icon="newspaper"
    :url="$routes.ShowNews(publication.id)"
    @close="$emit('close')"
  >
    <template #content>
      <RichTextContent
        :unsafe-html-content="content"
      />
    </template>
  </RichTextModal>
</template>

<script>
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import {
  RichTextContent,
  RichTextModal,
} from '@/components';

export default {
  name: 'NewsModal',
  components: {
    RichTextContent,
    RichTextModal,
  },
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      content: null,
      isLoading: true,
    };
  },
  computed: {
    subTitle() {
      return this.publication.source
        ? this.subTitleWithSource
        : '';
    },
    subTitleWithSource() {
      return this.$t('apps.publications.view_news.source', {
        source: this.publication.source,
      });
    },
  },
  beforeMount() {
    this.loadReport();
  },
  methods: {
    async loadReport() {
      this.isLoading = true;
      try {
        this.content = await axios.get(`/api/publications/${this.publication.id}/content`)
          .then((response) => response.data.content);
      } catch (error) {
        ApiHelpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
