import {
  isRef, onMounted, ref, unref,
} from 'vue';
import gradeA from '../../../../../../assets/images/topFiveCountry/gradeA.svg';
import gradeB from '../../../../../../assets/images/topFiveCountry/gradeB.svg';
import gradeC from '../../../../../../assets/images/topFiveCountry/gradeC.svg';
import gradeD from '../../../../../../assets/images/topFiveCountry/gradeD.svg';
import gradeE from '../../../../../../assets/images/topFiveCountry/gradeE.svg';
import gradeF from '../../../../../../assets/images/topFiveCountry/gradeF.svg';
import gradeG from '../../../../../../assets/images/topFiveCountry/gradeG.svg';

export const useHrScoreIcon = (hrScoreGrade) => {
  const hrScoreIcon = ref(null);

  const getIcon = (grade) => {
    let _grade = grade;
    if (isRef(grade)) { _grade = unref(grade); }

    return {
      A: gradeA,
      B: gradeB,
      C: gradeC,
      D: gradeD,
      E: gradeE,
      F: gradeF,
      G: gradeG,
    }[_grade];
  };

  const getIconReactive = () => {
    hrScoreIcon.value = getIcon(hrScoreGrade);
  };

  onMounted(() => {
    if (hrScoreGrade) getIconReactive();
  });

  return { hrScoreIcon, getIconReactive, getIcon };
};
