<template>
  <div class="form--box">
    <label
      class="form--label"
      for="source-combobox"
    >
      {{ $t('attributes.publication.source') }}
    </label>
    <AutoCompleteFreeText
      id="source-combobox"
      :model-value="modelValue"
      :options="options"
      :is-full-width="true"
      :placeholder="selectSourcePrompt"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'SourceCombobox',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectSourcePrompt: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
};
</script>
