import * as d3 from 'd3';

const isNotNumber = (x) => typeof x !== 'number';

const pearsonCorrelation = (X, Y) => {
  if (!Array.isArray(X) || !Array.isArray(Y)) {
    throw new Error('Arguments must be of type Array');
  }
  if (X.length !== Y.length) {
    throw new Error('Arrays must have equal lengths');
  }
  if (X.length < 3 || Y.length < 3) {
    throw new Error('Arrays must have at least 3 elements');
  }
  if (X.some(isNotNumber) || Y.some(isNotNumber)) {
    throw new Error('Arrays must contain only numbers');
  }
  if (X.some(Number.isNaN) || Y.some(Number.isNaN)) {
    return NaN;
  }

  const meanX = d3.mean(X);
  const meanY = d3.mean(Y);
  const stdX = d3.deviation(X);
  const stdY = d3.deviation(Y);
  const N = X.length;
  const covXY = d3.sum(X.map((x, i) => {
    const y = Y[i];
    return (x - meanX) * (y - meanY);
  })) / (N - 1);
  return covXY / (stdX * stdY);
};

export default pearsonCorrelation;
