<template>
  <component
    :is="hasReport ? 'button' : 'div'"
    class="product-group-badge"
    :class="{'can-click': hasReport, 'is-unobtrusive': isUnobtrusive, 'is-unread': isUnread}"
    @click="$emit('click', productGroup)"
  >
    <ProductGroupTrendIcon
      v-if="hasReport"
      variant="badge"
      :trend="report.trend"
      :is-unread="isUnread"
    />
    <span
      class="product-group-badge--text"
    >
      {{ productGroup.name }}
    </span>
  </component>
</template>

<script>
import ProductGroupTrendIcon from '@/components/ProductGroupTrendIcon/Component.vue';
import ProductGroupReportStateController from '@/controllers/ProductGroupReportStateController';

export default {
  name: 'ProductGroupBadge',
  components: {
    ProductGroupTrendIcon,
  },
  props: {
    productGroup: {
      type: Object,
      required: true,
    },
    isUnobtrusive: {
      type: Boolean,
      default: false,
    },
    withUnreadMarker: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    hasReport() {
      return this.$features.ANALYST_CORNER && !!this.productGroup.product_group_report;
    },
    report() {
      return this.productGroup.product_group_report;
    },
    isReportUnread() {
      return this.hasReport && !ProductGroupReportStateController.isReportRead(this.report);
    },
    isUnread() {
      return this.withUnreadMarker && this.isReportUnread;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>