import moment from 'moment';
import i18n from '@/config/i18n';
import NotificationController from '@/controllers/NotificationController';

const transformNotationResponse = (response) => ({
  notations: response.data.notations,
  hasMoreResults: response.data.next_page !== null,
  nextPage: response.data.next_page,
});

const removeNullKeys = (object) => Object.keys(object).reduce((aggr, key) => {
  if (object[key] !== null) {
    aggr[key] = object[key];
  }
  return aggr;
}, {});

const dateToString = (date) => {
  if (!date) {
    return undefined;
  }
  return moment(date).format('YYYY-MM-DD');
};

const logUnexpectedError = (error) => {
  // If the error does not have a request, then it's an exception in our code.
  // We log it to the console for better debugging capabilities.
  if (!error.request) {
    console.error(error); // eslint-disable-line no-console
  }
};

const handleError = (error) => {
  logUnexpectedError(error);
  NotificationController.error(i18n.t('errors.api.generic'));
};

export default {
  transformNotationResponse,
  removeNullKeys,
  dateToString,
  logUnexpectedError,
  handleError,
};
