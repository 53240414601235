<template>
  <h1 class="tile-header">
    <a
      class="tile-header--title"
      :href="`/dashboards/${dashboard.id}`"
    >
      {{ dashboard.full_name }}
    </a>
    <span
      v-if="dashboard.own_view_active"
      class="tile-header--own-view-badge"
    >
      {{ $t('components.notation_list.columns.dashboard_name.own_view_active') }}
    </span>
    <div class="title-header--product-group-list">
      <ProductGroupBadge
        v-for="productGroup in relevantProductGroupsForUser(dashboard.product_groups || [], user)"
        :key="productGroup.id"
        :product-group="productGroup"
        @click="openProductGroupReportFor(productGroup, dashboard.product_groups)"
      />
    </div>
  </h1>
</template>

<script>
import ProductGroupBadge from '@/components/ProductGroupBadge/Component.vue';
import { relevantProductGroupsForUser, openProductGroupReportFor } from '@/helpers/ProductGroupHelpers';

export default {
  name: 'IntroTileViewTileHeader',
  components: {
    ProductGroupBadge,
  },
  inject: ['user'],
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relevantProductGroupsForUser,
      openProductGroupReportFor,
    };
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>