import { reactive } from 'vue';
import axios from 'axios';
import i18n from '@/config/i18n';
import { compareBy } from '@/helpers/SortHelpers';
import { formatSubscriptions, fetchSubscriptions } from '../helpers/SubscriptionsHelpers';

const clone = (obj) => JSON.parse(JSON.stringify(obj));

class SubscriptionsController {
  constructor() {
    this._state = reactive({
      isLoading: true,
      hasPendingRequest: false,
      subscriptions: [],
    });

    fetchSubscriptions().then((subscriptions) => {
      this._state.subscriptions = formatSubscriptions(subscriptions);
      this._state.isLoading = false;
      this._originalSubscriptions = clone(this._state.subscriptions);
    });
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get hasPendingChanges() {
    return this._state.subscriptions.some((sub) => sub.hasChanged);
  }

  get areButtonsEnabled() {
    return this.hasPendingChanges && !this.hasPendingRequest;
  }

  get generalSubscriptions() {
    return this._subscriptionsForGroup(
      'general',
      i18n.t('apps.subscriptions.table_headers.general_subscriptions'),
    );
  }

  get publicDashboardSubscriptions() {
    return this._subscriptionsForGroup(
      'public',
      i18n.t('apps.subscriptions.table_headers.public_subscriptions'),
    );
  }

  get privateDashboardSubscriptions() {
    return this._subscriptionsForGroup(
      'private',
      i18n.t('apps.subscriptions.table_headers.private_subscriptions'),
    );
  }

  get publicationSubscriptions() {
    return this._subscriptionsForGroup(
      'publications',
      i18n.t('apps.subscriptions.table_headers.publication_subscriptions'),
    );
  }

  _subscriptionsForGroup(groupName, header) {
    let subs = this._state.subscriptions.filter((sub) => sub.group === groupName);
    subs = subs.sort(compareBy((s) => s.name));
    if (subs.length > 0) {
      subs[0].header = header;
    }
    return subs;
  }

  setInterval(changeObject) {
    const subscription = this._state.subscriptions
      .find((sub) => sub.key === changeObject.subscriptionKey);
    const originalSubscription = this._originalSubscriptions
      .find((sub) => sub.key === changeObject.subscriptionKey);
    if (subscription) {
      subscription.interval = changeObject.interval;
      const hasChanged = changeObject.interval !== originalSubscription?.interval;
      subscription.hasChanged = hasChanged;
    }
  }

  reset() {
    this._state.subscriptions = clone(this._originalSubscriptions);
  }

  _updateSubscriptions() {
    const subscriptionsForApi = this._state.subscriptions.map((sub) => ({
      type: sub.type,
      interval: sub.interval,
      dashboard_id: sub.dashboard_id,
    }));
    return axios.post('/api/subscriptions/bulk_update', { subscriptions: subscriptionsForApi });
  }

  _overwriteOriginalSubscriptions() {
    const isDashboardSubscription = (sub) => sub.group === 'private' || sub.group === 'public';
    const hasBeenRemoved = (sub) => (isDashboardSubscription(sub) || sub.removable) && sub.interval === 'never';
    const updatedSubscriptions = this._state.subscriptions
      .filter((sub) => !hasBeenRemoved(sub))
      .map((sub) => ({ ...sub, hasChanged: false }));
    this._originalSubscriptions = clone(updatedSubscriptions);
    this._state.subscriptions = updatedSubscriptions;
  }

  async saveSettings() {
    this.hasPendingRequest = true;
    await this._updateSubscriptions();
    this._overwriteOriginalSubscriptions();
    this.hasPendingRequest = false;
  }
}

export default SubscriptionsController;
