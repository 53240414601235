import { defineStore } from 'pinia';
import NotationApi from '@/api/NotationApi';
import useNotationDetails from '@/models/NotationDetails';
import moment from 'moment';

export const useDashboardOverviewExportStore = defineStore('dashboardOverviewExport', {
  state: () => ({
    isLoading: true,
    hasError: false,
    config: {},
    primaryNotationDetails: {},
    secondaryNotationDetails: {},
    forInternalUse: false,
  }),
  getters: {
    selectedGraphLimits() {
      const { graphLimits } = this.config;
      return {
        minDate: moment(graphLimits.minDate),
        maxDate: moment(graphLimits.maxDate),
        startDate: moment(graphLimits.startDate),
        endDate: moment(graphLimits.endDate),
      };
    },
  },
  actions: {
    fetchNotation(notationId) {
      return NotationApi()
        .findNotation(notationId)
        .then((notation) => notation)
        .catch((error) => {
          throw error;
        });
    },
    useLoadedNotationLoader(notation) {
      return {
        notation,
        get hasSelectedNotation() {
          return !!notation;
        },
      };
    },
    async setConfiguration(config) {
      try {
        this.isLoading = true;
        this.config = config;
        this.config.selectedDate = this.config.selectedDate !== null
          ? new Date(this.config.selectedDate)
          : null;
        const primaryNotation = await this.fetchNotation(this.config.primaryNotationId);
        const primaryNotationLoader = this.useLoadedNotationLoader(primaryNotation);
        this.primaryNotationDetails = await useNotationDetails(
          primaryNotationLoader, this.config.selectedCurrencyId, this.config.selectedDate,
        );
        if (this.config.secondaryNotationId !== null) {
          const secondaryNotation = await this.fetchNotation(this.config.secondaryNotationId);
          const secondaryNotationLoader = this.useLoadedNotationLoader(secondaryNotation);
          this.secondaryNotationDetails = await useNotationDetails(
            secondaryNotationLoader, this.config.selectedCurrencyId, this.config.selectedDate,
          );
        }
        this.forInternalUse = this.config.forInternalUse;
        this.hasError = false;
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
