<template>
  <div class="footer">
    <Icon
      name="info-circle"
      variant="regular"
    />
    <div class="footer--text-box">
      <span class="footer--code">
        {{ option.code }}
      </span>
      <span class="footer--name">
        {{ option.name }}
      </span>
      <ul class="footer--sources">
        <li
          v-for="source in option.sources"
          :key="source"
          class="footer--source-item"
        >
          <p class="footer--source-ribbon">
            {{ source }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardTradeFlowsClassificationComboBoxFooter',
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>