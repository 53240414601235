export const COLORS = {
  import: '#d31010',
  export: '#30a412',
};

export const IMPORT_EXPORT_COLOR = {
  import: COLORS.import,
  export: COLORS.export,
};

export default COLORS;
