import Chart from 'chart.js';

const beforePrint = () => {
  Object.values(Chart.instances).forEach((each) => each.resize());
};

if (window.matchMedia) {
  const mediaQueryList = window.matchMedia('print');
  mediaQueryList.addListener((mql) => {
    if (mql.matches) {
      beforePrint();
    }
  });
}

window.onbeforeprint = beforePrint;
