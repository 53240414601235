<template>
  <div class="dialog--window" open>
    <div
        class="dialog--header modal--header"
    >
      <div class="modal--heading">
        <div class="modal--title">
          <div class="modal--main-title">
            {{ title }}
          </div>
        </div>
      </div>
      <button
          class="modal--close-button"
          :aria-label="$t('components.rich_text_modal.close_button')"
          @click="$emit('close')"
      >
        <Icon name="times"/>
      </button>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import RichTextModalIcon from "../RichTextModal/Icon/Component.vue";

export default {
  name: 'SourceDisclaimerModal',
  components: {RichTextModalIcon},
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.modal--header {
  justify-content: space-between;
  border: none;
  padding: $space-m $space-m;
  background-color: $color-background-near-dark;
}

.modal--heading {
  display: flex;
  flex-direction: row;
}

.modal--close-button {
  @include font-size('24/24');
  color: $color-text-light;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.modal--title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal--main-title {
  @include font-size('16/16');
  font-weight: 500;
  color: $color-text-light;
}

.modal--body {
  min-height: 100px;
  padding: ($space-m + $space-s) $space-l;
}

</style>
