<template>
  <div
    ref="root"
    class="page with-split-view"
    :class="cssModifier"
  >
    <div
      ref="topContainer"
      class="page--split-top"
    >
      <slot name="top" />
      <div
        v-if="separator"
        class="page--split-top-separator"
      />
    </div>

    <div
      ref="bottomContainer"
      class="page--split-bottom"
    >
      <div
        v-if="separator"
        class="page--split-bottom-separator"
      />
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardPageLayout',
  props: {
    separator: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      topResizeObserver: new ResizeObserver(this.onTopResize),
      scrollbarObserver: new ResizeObserver(this.updateScrollbarWidth),
    };
  },
  computed: {
    cssModifier() {
      return this.narrow ? 'is-narrow' : null;
    },
  },
  mounted() {
    this.topResizeObserver.observe(this.$refs.topContainer);
    this.scrollbarObserver.observe(this.$refs.bottomContainer);
    this.updateScrollbarWidth();
  },
  beforeUnmount() {
    this.topResizeObserver.unobserve(this.$refs.topContainer);
    this.scrollbarObserver.unobserve(this.$refs.bottomContainer);
  },
  methods: {
    onTopResize() {
      const topHeight = this.$refs.topContainer.offsetHeight;
      const bottom = this.$refs.bottomContainer;
      bottom.style.marginTop = `${-topHeight}px`;
      bottom.style.paddingTop = `${topHeight}px`;
    },
    updateScrollbarWidth() {
      // Calculate width of scrollbar to subtract from top part width.
      // Otherwise it would hide the scrollbar which is actually part of
      // the bottom part.
      const scrollContainer = this.$refs.bottomContainer;
      const scrollbarWidth = scrollContainer.offsetWidth - scrollContainer.clientWidth;
      this.$refs.root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    },
  },
};
</script>
