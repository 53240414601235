<template>
  <ComboBox
    :model-value="selectedView"
    :options="[t('apps.supply_chain.view_toggle.options.eu'),t('apps.supply_chain.view_toggle.options.de')]"
    :description="t('apps.supply_chain.view_toggle.label')"
    :description-tooltip="t('apps.supply_chain.view_toggle.tooltip')"
    @update:modelValue="setSelectedView"
  />
</template>

<script>
export default {
  inject: ['t'],
  props: {
    view: {
      type: String,
      required: true,
      default: 'EU',
    },
  },
  data() {
    return {
      options: ['EU', 'DE'],
    };
  },
  computed: {
    selectedView() {
      return this.options.indexOf(this.view);
    },
  },
  methods: {
    setSelectedView(index) {
      this.$emit('view-changed', this.options[index]);
    },
  },
};
</script>
