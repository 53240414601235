<template>
  <div class="search-results">
    <table
      ref="root"
      class="data-table"
      :class="{'is-loading': controller.isLoading, 'is-hidden': tableBodyCollapse, 'data-table--animated': controller.hiddenContent}"
    >
      <caption class="data-table--caption">
        <!-- {{ $t('components.search.result_count', controller.notations.length) }} -->
      </caption>
      <TableHead
        v-if="controller.filters"
        :controller="controller"
        :model="controller.notationListModel"
        :sticky-margin-zero-top="stickyMarginZeroTop"
      >
        <template #sticky-header>
          <slot name="sticky-header"></slot>
        </template>
      </TableHead>
      <TableBody
        :controller="controller"
        :model="controller.notationListModel"
        :hidden-rows-model="controller.hiddenRowsModel"
      />
      <button
        v-if="controller.hiddenContent"
        class="data-table__collapsable-button"
        @click="toggleTableBody"
      >
        <Icon :name="tableBodyCollapse ? 'chevron-down' : 'chevron-up'" />
      </button>
    </table>

    <p
      v-if="controller.isLoading"
      class="search-results--is-loading"
    >
      <Icon
        class="search-results--is-loading-icon"
        name="spinner"
        :spin="true"
      />
      {{ $t('components.search_results.is_loading') }}
    </p>

    <InfiniteScroll
      v-if="!controller.isLoading && controller.hasMoreResults"
      @trigger="controller.loadNextPage()"
    />
  </div>
</template>

<script>
import InfiniteScroll from '@/components/InfiniteScroll/Component.vue';
import TableHead from './NotationTable/TableHead.vue';
import TableBody from './NotationTable/TableBody.vue';

export default {
  name: 'NotationList',
  components: {
    TableHead,
    TableBody,
    InfiniteScroll,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    stickyMarginZeroTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { tableBodyCollapse: this.controller.initiallyHiddenContent };
  },
  methods: {
    toggleTableBody() {
      this.tableBodyCollapse = !this.tableBodyCollapse;
      // const container = this.$refs.root.closest("[class*='__container--scrollable']");
      // const tablePosition = this.$refs.root.getBoundingClientRect().top;

      this.$nextTick().then(() => {
        this.$refs.root.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      })
    },
  },
};
</script>
