const High = 2;
const Medium = 1;
const Low = 0;

const forCorrelation = (corr) => {
  if (typeof corr !== 'number') {
    throw new Error('Correlation must be a number.');
  }
  const absCorr = Math.abs(corr);
  if (absCorr >= 0.7) {
    return High;
  }
  if (absCorr >= 0.6) {
    return Medium;
  }
  return Low;
};

export default {
  forCorrelation,
  High,
  Medium,
  Low,
};
