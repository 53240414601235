<script>
import { Tab } from 'vue3-tabs-component'

import { h, render } from 'vue'
import Icon from '@/components/generic/Icon'

export default {
  components: { Tab },
  props: {
    name: String,
    icon: String
  },

  computed: {
    prefix() {
      if (!this.icon) { return }

      let vnode = h(Icon, { name: this.icon, variant: 'regular' })
      let div = document.createElement('div')
      render(vnode, div)

      return `${div.innerHTML} `
    }
  }
}
</script>

<template>
  <Tab :name="name" :prefix="prefix">
    <slot></slot>
  </Tab>
</template>
