<template>
  <div
    class="dialog--window"
    open
  >
    <div class="dialog--main">
      <div class="dialog--main-box">
        {{ text }}

        <ul class="dialog--action-list">
          <li class="dialog--action-list-item">
            <ButtonComponent
              variant="ternary"
              @click="cancel()"
            >
              {{ cancelText }}
            </ButtonComponent>
          </li>
          <li class="dialog--action-list-item">
            <ButtonComponent
              type="submit"
              variant="primary"
              @click="confirm()"
            >
              {{ confirmText }}
            </ButtonComponent>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/config/i18n';

export default {
  name: 'ConfirmationModal',
  props: {
    text: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      default: i18n.t('components.modals.confirm'),
    },
    cancelText: {
      type: String,
      default: i18n.t('components.modals.cancel'),
    },
  },
  emits: ['close', 'confirm'],
  methods: {
    cancel() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('close');
      this.$emit('confirm');
    },
  },
};
</script>
