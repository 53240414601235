<script>
/**
 * This component is used to restrict access to certain components for demo users.
 * If the current user has set can_export_raw_data to false, the component will show a disabled button with a tooltip. It will render the slot content otherwise.
 */
export default {
  props: {
    userAttribute: {
      type: String,
      default: "can_export_raw_data"
    },
    forceRestricted: { // Set this to true to force it to be restricted, e.g. if the decision to restrict is made in the erb template
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: "export"
    },

    slots: {
      type: Object,
      default: null
    },

    // Optionally set a custom label
    label: {
      type: String,
      required: false
    },

    // the disabled link can either be rendered as a "button" (default)
    // or as a "link"
    as: {
      type: String,
      default: "button"
    }
  },

  computed: {
    currentUser() {
      let currentUserJson = document.querySelector('meta[name="current-user"]')
      if (!currentUserJson) {
        return
      }

      return JSON.parse(currentUserJson.content)
    },

    accessGranted() {
      if (this.forceRestricted) {
        return false
      }

      return this.currentUser[this.userAttribute]
    }
  },

  methods: {
    noop(event) {
      event.preventDefault()
    }
  }
}
</script>

<template>
  <span v-if="accessGranted && slots" v-html="slots.default"></span>
  <span v-else-if="accessGranted">
    <slot></slot>
  </span>
  <WithTooltip
    v-else
    :tooltip="$t('layouts.app_navigation.human_rights.buy_subscription_notice')"
  >
    <div
      v-if="slots && slots.label"
      v-html="slots.label"
      @click="noop"
    />
    <a
      v-else
      class="is-disabled is-inline-block"
      :class="{'button-primary': as == 'button'}"
      href="#"
      @click="noop"
      disabled
    >
      {{ label || $t(`components.generic.demo_user_restricted.${scope}`) }}
    </a>
  </WithTooltip>
</template>

<style lang="scss">

</style>
