<template>
  <div class="form--box">
    <label
      v-if="label"
      class="form--label"
      :for="uid"
    >
      {{ label }}

      <IconTooltipComponent
        v-if="tooltip"
        :tooltip="tooltip"
      />
    </label>
    <div
      v-if="selectedOptions.length === 0"
      class="multi-select-input--no-selection"
    >
      {{ noSelectionHint }}
    </div>
    <ul
      v-else
      class="multi-select-input--selection-list"
    >
      <li
        v-for="option in selectedOptionsSorted"
        :key="accessKey(option)"
        class="multi-select-input--selection-list-item"
      >
        {{ accessValue(option) }}
        <ButtonComponent
          class="multi-select-input--delete-button"
          variant="ternary"
          icon="times"
          :aria-label="removeFromSelectionPrompt"
          @click="removeFromSelection(accessKey(option))"
        />
      </li>
    </ul>
    <AutoComplete
      class="multi-select-input--auto-complete"
      :options="nonSelectedOptions"
      :access-key="(o) => o.id"
      :access-value="(o) => o.name"
      :placeholder="addToSelectionPrompt"
      :required="required && selectedKeys.length === 0"
      @update:modelValue="addToSelection($event)"
    />
    <select
      :id="uid"
      ref="hiddenSelect"
      multiple
      class="multi-select-input--hidden-select"
      @change="updateSelectedKeysFromHiddenSelect()"
    >
      <option
        v-for="option in options"
        :key="accessKey(option)"
        :value="accessKey(option)"
        :selected="isKeySelected(accessKey(option))"
      >
        {{ accessValue(option) }}
      </option>
    </select>
  </div>
</template>

<script>
import i18n from '@/config/i18n';
import HasUid from '@/mixins/HasUid';
import { compareBy } from '@/helpers/SortHelpers';

export default {
  name: 'MultiSelectInput',
  mixins: [HasUid],
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    accessKey: {
      type: Function,
      default: (o) => o.key,
    },
    accessValue: {
      type: Function,
      default: (o) => o.value,
    },
    noSelectionHint: {
      type: String,
      default: i18n.t('components.generic.multi_select_input.no_selection_hint'),
    },
    addToSelectionPrompt: {
      type: String,
      default: i18n.t('components.generic.multi_select_input.add_to_selection_prompt'),
    },
    removeFromSelectionPrompt: {
      type: String,
      default: i18n.t('components.generic.multi_select_input.remove_from_selection_prompt'),
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    selectedKeys() {
      return this.modelValue;
    },
    selectedOptions() {
      return this.options.filter((o) => this.isKeySelected(this.accessKey(o)));
    },
    selectedOptionsSorted() {
      return this.selectedOptions.slice().sort(compareBy(this.accessValue));
    },
    nonSelectedOptions() {
      return this.options.filter((o) => !this.isKeySelected(this.accessKey(o)));
    },
  },
  methods: {
    isKeySelected(key) {
      return this.selectedKeys.indexOf(key) !== -1;
    },
    addToSelection(keyToAdd) {
      const newSelection = this.selectedKeys.concat([keyToAdd]);
      this.$emit('update:modelValue', newSelection);
    },
    removeFromSelection(keyToRemove) {
      const newSelection = this.selectedKeys.filter((key) => key !== keyToRemove);
      this.$emit('update:modelValue', newSelection);
    },
    updateSelectedKeysFromHiddenSelect() {
      const newStringKeys = Array.from(this.$refs.hiddenSelect.selectedOptions).map((o) => o.value);
      const newSelectedOptions = this.options.filter((option) => {
        const stringKey = String(this.accessKey(option));
        return newStringKeys.indexOf(stringKey) !== -1;
      });
      const newSelection = newSelectedOptions.map((o) => this.accessKey(o));
      this.$emit('update:modelValue', newSelection);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>