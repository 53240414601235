import axios from 'axios';
import ApiHelpers from './ApiHelpers';

const DashboardApi = (currentDashboard) => {
  const baseUrl = `/api/dashboards/${currentDashboard.id}`;
  const availableFilters = (selectedFilters, customised) => axios.post(`${baseUrl}/filters`, {
    selected_filters: selectedFilters,
    customised,
  }).then((response) => response.data);

  const notations = (filters, sorting, comparisonDate, customised, { page = 0 } = {}) => {
    const payload = ApiHelpers.removeNullKeys({
      filters,
      sorting,
      compare_to_date: ApiHelpers.dateToString(comparisonDate),
      customised,
      page,
    });
    return axios.post(`${baseUrl}/notations`, payload)
      .then(ApiHelpers.transformNotationResponse);
  };

  const deleteDashboard = () => axios.delete(baseUrl);

  const editDashboard = (dashboard) => axios
    .put(baseUrl, { dashboard });

  const cloneDashboard = (dashboard) => axios
    .post(`${baseUrl}/clone`, { dashboard })
    .then((response) => response.data);

  const publishDashboard = (dashboard) => axios
    .post(`${baseUrl}/publish`, { dashboard });

  const unpublishDashboard = () => axios
    .post(`${baseUrl}/unpublish`);

  const saveDashboardState = (state) => axios
    .put(`${baseUrl}/state`, { state });

  const saveDashboardFallbackState = (fallbackState) => axios
    .put(`${baseUrl}/fallback_state`, { fallback_state: fallbackState });

  const saveCustomDashboardState = (state) => axios
    .put(`${baseUrl}/customisation`, { customisation: { state, own_view_active: true } });

  const updateNotationVisibilityForCustomisation = (notation, isVisible) => axios
    .put(`${baseUrl}/customisation/notation_visibility`, {
      notation_id: notation.id,
      is_visible: isVisible,
    });

  const updateOwnViewActive = (ownViewActive) => axios
    .patch(`${baseUrl}/customisation/own_view`, { active: ownViewActive });

  const deleteDashboardOwnView = () => axios.delete(`${baseUrl}/customisation/own_view`);

  const addDashboardToFavorites = () => axios
    .post(`${baseUrl}/favorite`);

  const removeDashboardFromFavorites = () => axios
    .delete(`${baseUrl}/favorite`);

  const updateNotationVisibilityForClients = (notation, isVisible) => axios
    .put(`${baseUrl}/update_notation_visibility_for_clients`, {
      notation_ids: [notation.id],
      is_visible: isVisible,
    });

  return {
    availableFilters,
    notations,
    saveDefaultFilters: () => { },
    deleteDashboard,
    editDashboard,
    cloneDashboard,
    publishDashboard,
    unpublishDashboard,
    saveDashboardState,
    saveDashboardFallbackState,
    saveCustomDashboardState,
    deleteDashboardOwnView,
    updateNotationVisibilityForCustomisation,
    updateOwnViewActive,
    addDashboardToFavorites,
    removeDashboardFromFavorites,
    updateNotationVisibilityForClients,
  };
};

export default DashboardApi;
