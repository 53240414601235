const humanRightsRisksTableKey = {
  favorite: 'favorite',
  name: 'name',
  balance: 'balance',
  allHrScores: 'weighted_hr_scores',
  sectorScores: 'sector_scores',
  calculatedScores: 'calculated_scores',
  flags: 'flags',
  top1: 'top1',
  top2: 'top2',
  top3: 'top3',
  top4: 'top4',
  top5: 'top5'
};

export default humanRightsRisksTableKey;
