<template>
  <div class="hr-score" v-if="hrScore">
    <img
      v-if="hrScoreIcon"
      :src="hrScoreIcon"
      alt="grade_icon"
    >
    <span>{{ hrScore }}</span>
    <img v-if="withFlagIcon && hrScoreFlagIcon" :src="hrScoreFlagIcon"/>
  </div>
  <div v-else class="is-centered">-</div>
</template>
<script setup>
import { computed, watch } from 'vue';
import { useHrScoreIcon } from '../TopFiveCountry/hrScoreIcon';
import {useI18n} from "vue-i18n";
import { convertToLocale } from '@/utils/ConvertToLocaleString';
import useHrScoreFlagIcon from '../TopFiveCountry/hrScoreFlagIcon';

const { locale } = useI18n();

const props = defineProps(['value', 'dimensions', 'withFlagIcon']);

const hrScore = computed(() => {
  if (props.value) {
    return convertToLocale(props.value.value, locale, {minimumFractionDigits: 1, maximumFractionDigits: 1})
  }
  return '-'
});
const grade = computed(() => props.value?.grade || '');

const { hrScoreIcon, getIconReactive } = useHrScoreIcon(grade);
watch(grade, getIconReactive);

const dimensions = computed(() => props.dimensions);
let { hrScoreFlagIcon, getFlagIconReactive } = useHrScoreFlagIcon(dimensions);
watch(dimensions, getFlagIconReactive);
</script>
<style scoped lang="scss">
.hr-score {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  span {
    min-width: 25px;
    text-align: center;
  }
}
</style>

