import moment from 'moment';
import {
  generalOptions,
  tooltipOptions,
  xAxisOptions,
  yAxisOptions,
} from '@/helpers/ChartOptionsHelpers';

const ChartOptions = ({
  xMin,
  xMax,
  yAxisOneMin,
  yAxisOneMax,
  yAxisTwoMin,
  yAxisTwoMax,
  drawSecondaryYAxisLines,
  fontSize,
  axisType,
}) => ({
  ...generalOptions(),
  tooltips: tooltipOptions({
    axisType,
    formatDate: (date) => moment(date).format('Do MMMM'),
  }),
  scales: {
    xAxes: xAxisOptions({
      xMin,
      xMax,
      fontSize,
      time: {
        unit: 'month',
        displayFormats: {
          month: '   MMM   ',
        },
      },
    }),
    yAxes: yAxisOptions({
      yAxisOneMin,
      yAxisOneMax,
      yAxisTwoMin,
      yAxisTwoMax,
      drawSecondaryYAxisLines,
    }),
  },
});

export default ChartOptions;
