import { createApp, reactive } from 'vue';
import { i18nPlugin } from '@/config/i18n';
import Routes from '@/config/Routes';
import TurbolinksPlugin from '@/config/TurbolinksPlugin';
import { createPinia } from 'pinia';
import {
  ClickOutside,
  ExcludeFromPrint,
  Focus,
} from '@/directives';
import GetFeatureSwitches from '@/config/GetFeatureSwitches';
import * as GenericComponents from '@/components/generic';

const registerDirectives = (app) => {
  app.directive('click-outside', ClickOutside);
  app.directive('focus', Focus);
  app.directive('exclude-from-print', ExcludeFromPrint);
};

const setGlobalProperties = (app) => {
  const isMailExport = document.documentElement.getAttribute('mail_export') === 'true';
  app.config.globalProperties.$isMailExport = isMailExport;

  const media = document.documentElement.getAttribute('media');
  app.config.globalProperties.$media = media;
  app.config.globalProperties.$features = GetFeatureSwitches();
  app.config.globalProperties.$routes = Routes;

  const enableDevtoolsPerformanceTracing = process.env.NODE_ENV !== 'production';
  app.config.performance = enableDevtoolsPerformanceTracing;
};

const registerGlobalComponents = (app) => {
  Object.keys(GenericComponents).forEach((key) => {
    app.component(key, GenericComponents[key]);
  });
};

const mount = (customElementName, vueApp, parent=document) => {
  if (!parent.getElementsByTagName) {
    // skip for non dom nodes, e.g. "<!-- v-if -->"
    return
  }
  const nodes = parent.getElementsByTagName(customElementName);
  Array.prototype.forEach.call(nodes, (node) => {
    // Avoid attaching a node twice. This only happens via a direct property
    // and _not_ data attributes to prevent caching with turbo
    if (node.rendered) {
      return
    }
    node.rendered = true

    let props = {}
    if(window.VUE_PROPS && window.VUE_PROPS[customElementName]) {
      props = window.VUE_PROPS[customElementName]
    } else if(node.dataset.props) {
      props = JSON.parse(node.dataset.props);
    }

    /**
     * The createApp function does support slots, but only if the mounted
     * app doesn't have a template itself. In order to avoid rewriting/wrapping
     * components, we'll check the node for any slots and pass them to the app
     * as a prop
     */
    if (node.dataset.slots) {
      // reset innerHTML to original after turbo cache hit
      node.innerHTML = node.dataset.slots
      node.removeAttribute("data-slots")
    }
    if (node.innerHTML) {
      // Remember original slot for restoring it after a turbo cache hit
      node.dataset.slots = node.innerHTML

      let slotNodes = Array.from(node.querySelectorAll("[slot]"))
      props.slots = Object.fromEntries(slotNodes.map((slotNode)=>{
        return [slotNode.getAttribute("slot"), slotNode.innerHTML]
      }))
    }

    const app = createApp(vueApp, reactive(props))
      .use(i18nPlugin)
      .use(TurbolinksPlugin, { node });
    registerDirectives(app);
    setGlobalProperties(app);
    registerGlobalComponents(app);

    app.use(createPinia());
    node.vue = app.mount(node);

    setTimeout(()=>{
      let customEvent = new CustomEvent('X-Content-Injected', { detail: node })
      document.dispatchEvent(customEvent)
    })
  });
};

// Finds all html tags with customElementName and replaces them with an instance of the given vueApp
const mountVueApp = (customElementName, vueApp) => {
  // Directly execute code if the page gets loaded directly
  mount(customElementName, vueApp);
  // Add event listener for the case when switching pages with turbolinks
  document.addEventListener('turbolinks:load', () => {
    mount(customElementName, vueApp);
  });
  document.addEventListener('X-Content-Injected', (event) => {
    mount(customElementName, vueApp, event.detail);
  });
};

export default mountVueApp;
