<template>
  <td
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    {{ value }}
  </td>
</template>

<script>
export default {
  name: 'TextColumn',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
