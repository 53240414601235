<template>
  <div class="extendable-table-header">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ExtendableTableHeader',
  props: {
    ariaLabelledby: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.extendable-table-header {
  width: 100%;

  table {
    background-color: #f5f7fa;
  }

  table,
  tr,
  td,
  td:not(:last-child) {
    border-color: transparent;
  }

}
</style>
