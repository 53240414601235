import axios from 'axios';

export default class CommodityRisksApi {
  async fetchCountriesData(params) {
     return axios
       .get('/api/human_rights/commodity_risk_map', { params })
       .then((response) => response.data);
  }

  async fetchClassifications(params) {
    return axios
      .get('/api/human_rights/classification_search', { params })
      .then((response) => response.data)
  }

  async fetchCommodityBubbleChartData(params) {
    return axios
      .get('/api/human_rights/commodity_bubble_chart', { params })
      .then((response) => response.data);
  }

  async fetchSector(classification_id, params) {
    return axios
      .get(`/api/human_rights/sector_risks/${classification_id}`, { params })
      .then((response) => response.data);
  }
}
