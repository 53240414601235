<template>
  <span
    class="unread-count-icon"
    :class="{'font-size-small': unreadCountNumber > 99 }"
  >
    {{ unreadCountNumber }}
  </span>
</template>

<script>
export default {
  name: 'UnreadCountIcon',
  props: {
    unreadCount: {
      type: Number,
      default: null,
    },
  },
  computed: {
    unreadCountNumber() {
      return this.unreadCount > 99 ? '99+' : this.unreadCount;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>