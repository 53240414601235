<template>
  <button
    class="app-header--user-toggle"
    aria-haspopup="true"
    @click="$emit('click', $event)"
  >
    <span class="app-header--user-icon">
      <Icon
        class="icon"
        variant="regular"
        name="user"
      />
      <template v-if="user.editor_permissions">
        <label class="visually-hidden">
          {{ $t('layouts.app_header.editor_role_label') }}
        </label>
        <span class="app-header--user-icon-status-badge">
          <Icon
            name="pencil"
            variant="solid"
            size="xs"
          />
        </span>
      </template>
    </span>

    <span class="app-header--user-name">
      {{ user.display_name }}
    </span>

    <span class="app-navigation--caret">
      <Icon
        class="icon"
        name="caret-down"
        variant="solid"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'NavigationUserMenuButton',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
};
</script>
