<template>
  <div
    class="dialog--window"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('apps.publications.upload_modal.title') }}
      </div>
    </div>
    <div class="dialog--main">
      <PublicationsUploadModalFileList
        :controller="controller"
      />
    </div>
    <div class="dialog--footer">
      <PublicationsUploadModalSummary
        :controller="controller"
      />
      <PublicationsUploadModalFooterActions
        :controller="controller"
      />
    </div>
  </div>
</template>

<script>
import PublicationsUploadModalController from './Controller';
import PublicationsUploadModalFileList from './FileList/Component.vue';
import PublicationsUploadModalSummary from './Summary/Component.vue';
import PublicationsUploadModalFooterActions from './FooterActions/Component.vue';

export default {
  name: 'PublicationsUploadModal',
  components: {
    PublicationsUploadModalFileList,
    PublicationsUploadModalSummary,
    PublicationsUploadModalFooterActions,
  },
  emits: ['close'],
  data() {
    return {
      controller: null,
    };
  },
  beforeMount() {
    this.controller = new PublicationsUploadModalController(
      () => this.$emit('close'),
    );
  },
};
</script>
