<template>
  <div>
    <div class="search-results">
      <table
        class="data-table"
        :class="{'is-loading': controller.isLoading}"
      >
        <HeadComponent
          :columns="model.columns"
          :controller="controller"
          :sticky-margin-zero-top="true"
        />
        <BodyComponent
          :notations="controller.dashboardsForListView"
          :model="model"
          @select="onNotationSelect"
        />
      </table>
      <SpinningLoader
        v-if="controller.isLoading"
        class="search-results--is-loading"
      />
    </div>
  </div>
</template>

<script>
import Turbolinks from 'turbolinks';
import ListViewModel from './Model';
import HeadComponent from './Head/Component.vue';
import BodyComponent from './Body/Component.vue';

export default {
  name: 'ListViewComponent',
  components: {
    HeadComponent,
    BodyComponent,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: ListViewModel(this.showDetails),
      hiddenColumns: [],
      detailColumns: [],
    };
  },
  watch: {
    'controller.showDetailColumns': {
      handler(show) {
        this.model = ListViewModel(show);
      },
      immediate: true,
    },
  },
  methods: {
    onNotationSelect(notation) {
      Turbolinks.visit(`/dashboards/${notation.dashboard_id}`);
    },
    onToggleDetails(showDetails) {
      // eslint-disable-next-line vue/no-mutating-props
      this.controller.showDetailColumns = showDetails;
    },
  },
};
</script>
