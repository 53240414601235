<template>
  <component
    :is="tag"
    ref="root"
    :class="computedClass"
    @mouseenter="toggleTooltip(true)"
    @mouseleave="toggleTooltip(false)"
    @click="toggleTooltip(false)"
  >
    <slot />
    <transition name="tooltip--animation">
      <div
        v-if="(tooltip || hasCustomSlot) && showTooltip"
        ref="tooltipElement"
        class="tooltip"
        :class="{ 'tooltip__extra-wide': extraWide }"
      >
        <!-- trix-content allows us to render rich-text like stuff in tooltips (e.g. links) with a nice styling -->
        <div class="tooltip--popup trix-content">
          <span v-html="tooltip" />
          <slot name="custom" />
        </div>
      </div>
    </transition>
  </component>
</template>

<script>
export default {
  name: 'WithTooltip',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    delay: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: String,
      default: null,
    },
    xAlignment: {
      type: String,
      default: 'auto',
      validator: (value) => ['auto', 'left', 'center', 'right'].indexOf(value) !== -1,
    },
    yAlignment: {
      type: String,
      default: 'bottom',
      validator: (value) => ['auto', 'bottom', 'top'].indexOf(value) !== -1,
    },
    extraWide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      xAutoAlignment: 'left',
      yAutoAlignment: 'bottom',
      showTooltip: false,
      showTimeout: null,
    };
  },
  computed: {
    computedXAlignment() {
      return this.xAlignment === 'auto'
        ? this.xAutoAlignment
        : this.xAlignment;
    },
    computedYAlignment() {
      return this.yAlignment === 'auto'
        ? this.yAutoAlignment
        : this.yAlignment;
    },
    computedClass() {
      if (!this.tooltip && !this.hasCustomSlot) return null;
      const xAlignmentClass = `tooltip-${this.computedXAlignment}-aligned`;
      const yAlignmentClass = `tooltip-${this.computedYAlignment}-aligned`;
      return ['with-tooltip', xAlignmentClass, yAlignmentClass].join(' ');
    },
    hasCustomSlot() {
      return !!this.$slots.custom;
    },
  },
  watch: {
    showTooltip() {
      /**
       * With a lot of tooltips on a small space it can happen that quick
       * scrolling/mouse movements lead to the mouseleave event not being
       * triggered (at all or in time?) and a tooltip stays visible.
       * This workaround makes sure that if there is another tooltip still
       * opened when this one is opened, we close the other one.
       */
      if (this.showTooltip) {
        if (window.visibleTooltip && window.visibleTooltip.showTooltip) {
          window.visibleTooltip.showTooltip = false
        }
        window.visibleTooltip = this
      } else {
        window.visibleTooltip = null
      }
    }
  },
  methods: {
    toggleTooltip(show) {
      this.updateAutoAlignment();
      clearTimeout(this.showTimeout);
      const toggleAfter = show ? this.delay : 0;
      this.showTimeout = setTimeout(() => { this.showTooltip = show; }, toggleAfter);
    },
    findOverflowAncestor() {
      if (!this.$refs.root) {
        // might got unmounted
        return
      }
      let parent = this.$refs.root.parentElement;
      while (parent) {
        const style = window.getComputedStyle(parent, null);
        const overflow = style.getPropertyValue('overflow');
        if (overflow !== 'visible') {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    },
    updateAutoAlignment() {
      // parent is the first ancestor with overflow !== visible
      const parent = this.findOverflowAncestor();
      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const cellRect = this.$refs.root.getBoundingClientRect();
        const xCenter = (cellRect.right + cellRect.left) / 2;

        const maximumTooltipWidth = 500;
        const maximumTooltipHeight = 500;

        // calculate left-aligned and right-aligned overflows
        const leftAlignedOverflow = (xCenter + maximumTooltipWidth) - parentRect.right;
        const rightAlignedOverflow = parentRect.left - (xCenter - maximumTooltipWidth);
        // calculate center-aligned overflow
        const halfTooltipWidth = maximumTooltipWidth / 2;
        const centerXAlignedLeftOverflow = (xCenter + halfTooltipWidth) - parentRect.right;
        const centerXAlignedRightOverflow = parentRect.left - (xCenter - halfTooltipWidth);
        const centerXAlignedOverflow = Math.max(centerXAlignedLeftOverflow,
          centerXAlignedRightOverflow);
        // pick alignment with least overflow
        this.xAutoAlignment = 'center';
        const leftIsBest = leftAlignedOverflow < rightAlignedOverflow
                        && leftAlignedOverflow < centerXAlignedOverflow;
        if (leftIsBest) {
          if (leftAlignedOverflow < -100) {
            this.xAutoAlignment = 'max-left';
          } else {
            this.xAutoAlignment = 'left';
          }
        }
        const rightIsBest = rightAlignedOverflow < leftAlignedOverflow
                        && rightAlignedOverflow < centerXAlignedOverflow;
        if (rightIsBest) {
          this.xAutoAlignment = 'right';
        }

        /** Y-Axis Alignment */
        // TODO: move this to a separate method
        const topSafetyMargin = 55; // TODO: find a better way to calculate this
        const topDistance = cellRect.top - parentRect.top - topSafetyMargin;
        const bottomDistance = parentRect.bottom - cellRect.bottom;

        if (bottomDistance > maximumTooltipHeight) {
          this.yAutoAlignment = 'bottom';
        } else if (topDistance > maximumTooltipHeight) {
          this.yAutoAlignment = 'top';
        } else {
          this.yAutoAlignment = 'middle';
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>