<template>
  <li class="item">
    <div class="item--row">
      <div class="item--infos">
        <NotationAnalysesListItemHeader
          :title="analysis.title"
          @action="openChartView"
        />
        <NotationAnalysesListItemMetadata
          :created-at="analysis.created_at"
          :updated-at="analysis.updated_at"
        />
      </div>
      <NotationAnalysesListItemActions
        :analysis-title="analysis.title"
        @show-chart="openChartView"
        @delete-analysis="() => $emit('delete-analysis', analysis.id)"
        @duplicate-analysis="(title) => $emit('duplicate-analysis', {...analysis, title: title})"
      />
    </div>
  </li>
</template>

<script>
import Turbolinks from 'turbolinks';
import NotationAnalysesListItemHeader from './Header/Component.vue';
import NotationAnalysesListItemMetadata from './Metadata/Component.vue';
import NotationAnalysesListItemActions from './Actions/Component.vue';

export default {
  name: 'NotationAnalysesListItem',
  components: {
    NotationAnalysesListItemHeader,
    NotationAnalysesListItemMetadata,
    NotationAnalysesListItemActions,
  },
  props: {
    analysis: {
      type: Object,
      required: true,
    },
  },
  emits: ['delete-analysis', 'duplicate-analysis'],
  methods: {
    openChartView() {
      Turbolinks.visit(this.analysis.url);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>