<template>
  <div
    class="axis-label"
    :class="cssModifierClass"
  >
    <span class="axis-label--text">
      <NotationColorIcon
        v-if="showBullet"
        class="axis-label--icon"
        :slot-kind="axis"
      />
      {{ label }}
    </span>
  </div>
</template>

<script>
import NotationColorIcon from '@/components/NotationColorIcon/Component.vue';

const AXIS_TYPES = ['primary', 'secondary'];
const validateAxis = (value) => AXIS_TYPES.indexOf(value) !== -1;

export default {
  name: 'QuotesChartAxisLabel',
  components: {
    NotationColorIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    axis: {
      type: String,
      default: 'primary',
      validator: validateAxis,
    },
    showBullet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssModifierClass() {
      return `is-${this.axis}`;
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>