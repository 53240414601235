<template>
  <div class="search-input">
    <input
      v-focus="autofocus"
      name="filter-input"
      type="text"
      class="search-input--text-field"
      :placeholder="placeholder"
      :value="value"
      autocomplete="off"
      @input="onChange"
    >
    <Icon
      class="search-input--icon"
      name="search"
    />
  </div>
</template>

<script>
import './style.scss';

export default {
  name: 'SearchInputComponent',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
