<template>
  <div
    class="dialog--window"
    :class="{'is-wide': showClusters}"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('components.add_to_dashboard_modal.title', count) }}
      </div>
      <TabBar
        v-model="currentTab"
        :tabs="availableTabs"
      />
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <template v-if="currentTab === 'select'">
          <SelectDashboardForm
            :show-clusters="showClusters"
            @submit="onSelectDashboard"
            @cancel="onClickCancel"
          />
        </template>
        <template v-else>
          <CreateDashboardForm
            @submit="onCreateDashboard"
            @cancel="onClickCancel"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CreateDashboardForm from './CreateDashboardForm/Component.vue';
import SelectDashboardForm from '../SelectDashboardForm/Component.vue';

export default {
  name: 'AddNotationsToDashboardModal',
  components: {
    CreateDashboardForm,
    SelectDashboardForm,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    showClusters: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'add-to-dashboard',
    'create-dashboard',
    'close',
  ],
  data() {
    return {
      searchText: '',
      currentTab: 'select',
      availableTabs:
        [
          {
            value: 'select',
            name: this.$t('components.add_to_dashboard_modal.select_tab'),
            icon: 'plus-square',
          },
          {
            value: 'create',
            name: this.$t('components.add_to_dashboard_modal.create_tab'),
            icon: 'file',
          },
        ],
    };
  },
  methods: {
    onSelectDashboard(selection) {
      this.$emit('add-to-dashboard', selection.dashboard);
    },
    onCreateDashboard(dashboard) {
      this.$emit('create-dashboard', dashboard);
    },
    onClickCancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>