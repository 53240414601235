import axios from 'axios';
import { reactive } from 'vue';
import Turbolinks from 'turbolinks';
import LogApi from '@/api/LogApi';

const SuggestionsApi = () => {
  let cancelToken;
  const fetch = (currentQuery) => {
    if (cancelToken) {
      cancelToken.cancel('Request cancelled.');
    }
    cancelToken = axios.CancelToken.source();
    return axios.get('/api/top_terms/search', {
      params: { query: currentQuery },
      cancelToken: cancelToken.token,
    }).then((response) => response.data)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });
  };
  return { fetch };
};

const SearchBoxController = (config = {
  minimumQueryLength: 2,
}) => {
  // private data
  const api = SuggestionsApi();
  const state = reactive({
    currentIndex: 0,
    isLoading: false,
    currentQuery: '',
    suggestions: [],
    searchTermRequested: false,
  });

  // private methods
  const resetState = () => {
    state.suggestions = [];
    state.currentIndex = 0;
    state.isLoading = false;
  };

  const updateSuggestions = () => {
    state.isLoading = true;
    if (state?.currentQuery?.length < config.minimumQueryLength) {
      state.suggestions = [];
      state.isLoading = false;
    } else {
      api.fetch(state.currentQuery).then((results) => {
        state.suggestions = results;
        state.isLoading = false;
      });
    }
  };

  return {
    // getters and setters
    get searchTermRequested() {
      return state.searchTermRequested;
    },
    get currentIndex() {
      return state.currentIndex;
    },
    get isLoading() {
      return state.isLoading;
    },
    get suggestions() {
      return state.suggestions;
    },
    get currentQuery() {
      return state.currentQuery;
    },
    set currentQuery(value) {
      state.currentQuery = value;
      updateSuggestions();
      state.searchTermRequested = false;
    },
    get isPopupVisible() {
      return this.currentQuery.length >= config.minimumQueryLength;
    },
    get currentItem() {
      return this.suggestions[this.currentIndex];
    },

    // public methods
    setCurrentIndexWithLimits(index) {
      const min = 0;
      const max = this.suggestions.length - 1;
      state.currentIndex = Math.max(min, Math.min(max, index));
    },

    scrollCurrentIndex(delta) {
      this.setCurrentIndexWithLimits(this.currentIndex + delta);
    },

    submit() {
      if (this.suggestions.length === 0) {
        return false;
      }
      LogApi.onSearchSubmit(this.currentItem.id);
      const item = this.currentItem;
      resetState();
      Turbolinks.visit(item.url);
      return true;
    },

    requestSearchTerm() {
      state.searchTermRequested = true;
      LogApi.onSearchTermRequest(this.currentQuery);
    },
  };
};

export default SearchBoxController;
