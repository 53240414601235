<template>
  <Teaser class="humanrightsrisks-export-button" scope="export" user-attribute="can_export_human_rights_data">
    <ComboBox
      :model-value="currentIndex"
      :options="options"
      :label="ariaLabel"
      :disabled="isLoading || isSaving"
      :display-text-override="comboBoxDisplayTextOverride"
      :controller="controller"
      @update:modelValue="selectExportFormat"
    />
  </Teaser>
</template>

<script>
import i18n from '@/config/i18n';
import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import PdfExportController from '@/components/generic/PdfExportButton/Controller';

const EXPORT_FILEFORMATS = ['pdf', 'excel'];

export default {
  name: 'HumanRightsRisksExportButtonComponent',
  props: {
    ariaLabel: {
      type: String,
      default: i18n.t('apps.intro.human_rights_risks.export_button.options.default_label'),
    },
    mailExportType: {
      type: String,
      required: true,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
    controller: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      keys: EXPORT_FILEFORMATS,
    };
  },
  computed: {
    options() {
      return this.keys.map((key) => this.$t(`apps.intro.human_rights_risks.export_button.options.${key}`));
    },
    currentIndex() {
      return this.keys.indexOf(this.mailExportRythm);
    },
    currentOption() {
      return this.options[this.currentIndex];
    },
    comboBoxDisplayTextOverride() {
      if (this.isLoading) {
        return this.$t('apps.intro.human_rights_risks.export_button.is_loading');
      } if (this.isSaving) {
        return this.$t('apps.intro.human_rights_risks.export_button.is_saving');
      }
      return this.$t('apps.intro.human_rights_risks.export_button.options.default_label');
    },
  },
  beforeMount() {
  },
  methods: {
    selectExportFormat(index) {
      const currentExportFormat = EXPORT_FILEFORMATS[index];
      switch (currentExportFormat) {
        case 'pdf':
          this.exportPDF();
          break;
        case 'excel':
          this.exportXLSX();
          break;
      }
      // try {
      //   this.isSaving = true;
      //   const interval = this.keys[index];
      //   await axios.post('/api/subscriptions/bulk_update', {
      //     subscriptions: [{
      //       type: this.mailExportType,
      //       interval,
      //       dashboard_id: this.dashboardId,
      //     }],
      //   });
      //   this.mailExportRythm = interval;
      // } catch (error) {
      //   ApiHelpers.handleError(error);
      //   return { error };
      // } finally {
      //   this.isSaving = false;
      // }
      // return {};
    },
    exportPDF(forInternalUse = true) {
      const path = forInternalUse ? '' : `${window.location.pathname}?${this.forExternalPdfExport()}`;
      PdfExportController.exportAsPdf(path);
    },
    exportXLSX() {
      const params = {
        country_of_demand_id: this.controller.selectedCountry,
        dimension: this.controller.selectedDimension.value,
        year: this.controller.selectedYear.value,
      };
      this.controller._getExcelFileFromDashboard(params);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>