<template>
  <ul class="app-navigation--dropdown-list">
    <li
      v-for="item in menuItems"
      :key="item.href"
      class="app-navigation--dropdown-item"
      :class="{ 'app-header--user-menu-entry-with-separator': item.separator }"
    >
      <template v-if="item.isDisabled">
        <WithTooltip :tooltip="$t('layouts.app_navigation.human_rights.buy_subscription_notice')">
          <span class="app-navigation--dropdown-link disabled">
            {{ item.label }}
          </span>
        </WithTooltip>
      </template>
      <a v-else :href="item.href" class="app-navigation--dropdown-link" :download="item.isDownload ? '' : null">
        {{ item.label }}
      </a>
    </li>
  </ul>
</template>

<script>
import { WithTooltip } from "@/components/generic";

export default {
  name: "NavigationHumanRightsDropdown",
  components: {
    WithTooltip,
  },
  inject: ["user"],
  props: {
    countryPortraitsDefaultPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasHumanRightsSubsciption() {
      return this.user.has_human_rights_subscription;
    },

    isDemoUser() {
      return !this.user.can_export_raw_data;
    },

    excelToolItems() {
      if (!this.hasHumanRightsSubsciption) {
        return [];
      }
      return [
        {
          separator: true,
          href: "/docs/HGS-Tool_Information.pdf",
          label: this.$t("layouts.app_navigation.human_rights.hgs_tool_info"),
          isDownload: true,
        },
        {
          href: "/docs/Download-Hinweis_HGS-Tool.pdf",
          label: this.$t("layouts.app_navigation.human_rights.hgs_tool_download_note"),
          isDownload: true,
        },
        {
          href: "/docs/human-rights/HGS-Tool_Risk_analysis_abstract.xlsm",
          label: this.$t("layouts.app_navigation.human_rights.excel_tool.main_file"),
          isDownload: true,
          isDisabled: this.isDemoUser
        },
        {
          href: "/docs/human-rights/HGS-Tool_Risk_analysis_abstract_input_file.xlsx",
          label: this.$t("layouts.app_navigation.human_rights.excel_tool.input_file"),
          isDownload: true,
        },
        {
          href: "/docs/human-rights/HGS-Tool_HGS-Human-Rights-Ratings.zip",
          label: this.$t("layouts.app_navigation.human_rights.excel_tool.scores_file"),
          isDownload: true,
          isDisabled: this.isDemoUser
        },
      ];
    },

    menuItems() {
      return [
        {
          href: "/human-rights/intro",
          label: this.$t("layouts.app_navigation.human_rights.intro"),
          isDisabled: false,
          isDownload: false,
        },
        {
          href: "/human-rights/country-risk-score",
          label: this.$t("layouts.app_navigation.human_rights.country_risk_score"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: true,
        },
        {
          href: "/human-rights/sector-risks",
          label: this.$t("layouts.app_navigation.human_rights.sector_risks"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        {
          href: "/human-rights/country-risks",
          label: this.$t("layouts.app_navigation.human_rights.country_risk_map"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        {
          href: "/human-rights/commodity-risks",
          label: this.$t("layouts.app_navigation.human_rights.commodity_risk_map"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        {
          href: this.countryPortraitsDefaultPath,
          label: this.$t("layouts.app_navigation.human_rights.country_portraits"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        {
          href: "/human-rights/sector-portraits",
          label: this.$t("layouts.app_navigation.human_rights.sector_portraits"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        {
          href: "/supply_chains",
          label: this.$t("layouts.app_navigation.human_rights.supply_chain"),
          isDisabled: !this.hasHumanRightsSubsciption,
          isDownload: false,
        },
        ...this.excelToolItems,
      ];
    },
  },
};
</script>
