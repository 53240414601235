<template>
  <div
    class="dialog--window"
    open
  >
    <div class="dialog--header">
      <div class="dialog--heading">
        {{ $t('apps.multi_line_chart_view.prompt_modal.label') }}
      </div>
    </div>
    <div class="dialog--main">
      <div class="dialog--main-box">
        <form @submit="save($event)">
          <TextInput
            ref="nameInput"
            v-model="title"
            v-focus
            :label="$t('apps.notation_analyses.title')"
            :required="true"
            @focus="$event.target.select()"
          />

          <ul class="dialog--action-list">
            <li class="dialog--action-list-item">
              <ButtonComponent
                variant="ternary"
                @click="cancel()"
              >
                {{ $t('components.modals.cancel') }}
              </ButtonComponent>
            </li>
            <li class="dialog--action-list-item">
              <ButtonComponent
                type="submit"
                variant="primary"
              >
                {{ $t('apps.multi_line_chart_view.prompt_modal.confirm') }}
              </ButtonComponent>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotationAnalysisNamePromptModal',
  props: {
    initialTitle: {
      type: String,
      default: null,
    },
  },
  emits: [
    'save',
    'close',
  ],
  data() {
    return {
      title: this.initialTitle,
    };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    save(e) {
      e.preventDefault();
      this.$emit('close');
      this.$emit('save', this.title);
    },
  },
};
</script>
