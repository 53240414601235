import axios from 'axios';
import { reactive } from 'vue';
import ApiHelpers from '@/api/ApiHelpers';
import ModalWindowController from '@/controllers/ModalWindowController';
import i18n from '@/config/i18n';

const deleteNews = async (news) => {
  try {
    const url = `/api/publications/${news.id}`;
    await axios.delete(url);
  } catch (error) {
    ApiHelpers.handleError(error);
  }
};

export default class NewsEditorialController {
  constructor() {
    this.listeners = {};
    this._state = reactive({
      isLoading: false,
    });
  }

  showCreateNewsModal() {
    ModalWindowController.show('PublicationsEditModal', {
      props: {
        title: i18n.t('apps.news.create_modal_title'),
        publication: {
          product_groups: [],
          regions: [],
          kind: 'news',
        },
      },
      events: {
        save: () => { this.emit('require-reload'); },
      },
    });
  }

  showEditNewsModal(news) {
    ModalWindowController.show('PublicationsEditModal', {
      props: {
        title: i18n.t('apps.news.edit_modal_title'),
        publication: news,
      },
      events: {
        // TODO: don't require reload?
        save: () => { this.emit('require-reload'); },
      },
    });
  }

  deleteAfterConfirmation(news) {
    ModalWindowController.show('ConfirmationModal', {
      props: {
        text: i18n.t('apps.news.delete_confirmation_text', {
          name: news.display_name,
        }),
      },
      events: {
        confirm: async () => {
          await deleteNews(news);
          this.emit('require-reload');
        },
      },
    });
  }

  emit(eventName, payload) {
    if (this.listeners[eventName]) {
      this.listeners[eventName](payload);
    }
  }

  on(eventName, callback) {
    this.listeners[eventName] = callback;
  }
}
