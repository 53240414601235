<template>
  <td class="data-table--body-cell" v-on="eventListeners ? eventListeners : {}">
    <div class="hr-score" v-if="value !== null && Object.keys(value).length !== 0">
      <span>{{ value.value }}</span>
      <span class="context" v-if="value.context !== null">({{ value.context }})</span>
      <span class="context" v-else> </span> <!-- to keep the same height as the other rows -->
    </div>
  </td>
</template>
<style scoped lang="scss">
.hr-score {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  span {
    width: 30px;
    text-align: center;
  }
}
.context {
  font-size: 10px;
}
</style>
<script>

export default {
  name: 'ScoreWithContext',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
