import { defineStore } from 'pinia';
import NotationApi from '@/api/NotationApi';
import axios from 'axios';
import i18n from '@/config/i18n';
import UnitsApi from '@/api/UnitsApi';
import UrlStateStore from '@/services/UrlStateStore';
import { defaultGraphColors } from '@/helpers/ChartHelpers';

const addNotationToConfigAxisLines = (axisLines, notationID, color) => {
  const notationConfig = {
    notation_id: notationID,
    color,
  };
  if (!axisLines.some((entry) => entry.id === notationConfig.notation_id)) {
    axisLines.push(notationConfig);
  }
};

const deleteNotationFromConfigAxisLines = (
  axisLines,
  notationID,
) => axisLines.filter((line) => line.notation_id !== notationID);

export const useChartConfigurationStore = defineStore('chartConfiguration', {
  state: () => ({
    notations: {},
    compatibleUnits: {},
    units: [],
    colors: defaultGraphColors(),
    config: null,
    savedAnalysisId: null,
    isLoading: true,
    hasError: false,
    compositionCategories: [],
    categories: [],
    priceUnitCategories: [],
    volumeUnitCategories: [],
    quantityUnitCategories: [],
  }),
  getters: {
    notationsWithAxis() {
      return this.config.notationIDs.map((notationId) => {
        const notation = this.notations[notationId];
        return {
          ...notation,
          axis: this.axisForNotation(notation.id),
        };
      });
    },
    availableCompositionCategories() {
      return this.compositionCategories.map((category) => ({
        id: category,
        name: i18n.t(`apps.chart_config.composition_categories.${category}`),
      }));
    },
    availableCurrencies() {
      return this.units?.currency ? Object.values(this.units?.currency) : [];
    },
    availableVolumeUnits() {
      return this.units;
    },
    selectedAxesCount() {
      return this.config.selectedAxesCount;
    },
    validationError() {
      if (this.selectedAxesCount === 1) {
        if (this.config.axis.left.lines.length === 0) {
          return i18n.t('apps.chart_config.error_message.one_axis');
        }
      }
      if (this.selectedAxesCount === 2) {
        if (this.config.axis.left.lines.length === 0 || this.config.axis.right.lines.length === 0) {
          return i18n.t('apps.chart_config.error_message.two_axes');
        }
      }
      if (this.canApply === false) {
        return i18n.t('apps.chart_config.error_message.notation_not_found');
      }
      return null;
    },
    viewUrl() {
      return `multi-line-chart-view${UrlStateStore.queryStringForState({ config: this.config, savedAnalysisId: this.savedAnalysisId })}`;
    },
    canApply() {
      const axesOfUnconfigurableNotations = Object.values(this.notations)
        .filter((notation) => notation.notFound === true)
        .map((notation) => this.axisForNotation(notation.id));
      return !axesOfUnconfigurableNotations.includes('left') && !axesOfUnconfigurableNotations.includes('right');
    },
  },
  actions: {
    setSelectedAxesCount(selectedAxesCount) {
      this.config.selectedAxesCount = selectedAxesCount;
    },
    fetchNotations(notationIds) {
      return Promise.all(notationIds.map((id, index) => NotationApi()
        .findNotation(id)
        .then((notation) => {
          this.notations[id] = {
            ...notation,
            color: this.colors[index],
          };
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.notations[id] = {
              id,
              color: this.colors[index],
              notFound: true,
            };
          } else {
            throw error;
          }
        })));
    },
    fetchCompatibleUnits(notationIDs) {
      const queryString = notationIDs.map((id) => `notation_ids[]=${id}`).join('&');
      return axios.get(`/api/notations/compatible_units?${queryString}`)
        .then((response) => {
          this.compatibleUnits = response.data;
          this.compositionCategories = Object.keys(this.compatibleUnits);
        });
    },
    resetCategories() {
      if (this.compositionCategories.includes('price') || this.compositionCategories.includes('value')) {
        this.categories.push('currency');
      }
      if (this.compositionCategories.includes('price')) {
        const unitCategories = this.compatibleUnits.price.notations_per_volume_unit_categories;
        const units = Object.keys(unitCategories);
        this.priceUnitCategories.push(...units);
      }
      if (this.compositionCategories.includes('volume')) {
        const unitCategories = this.compatibleUnits.volume.notations_per_volume_unit_categories;
        const units = Object.keys(unitCategories);
        this.volumeUnitCategories.push(...units);
      }
      this.quantityUnitCategories = [
        ...new Set(this.priceUnitCategories.concat(this.volumeUnitCategories)),
      ];
      this.categories = this.categories.concat(this.quantityUnitCategories);
    },
    fetchUnits() {
      return UnitsApi.unitsGroupedByCategory({ categories: this.categories })
        .then((response) => {
          this.units = response;
        });
    },
    axisForNotation(notationID) {
      if (this.config.axis.left.lines.some((line) => line.notation_id === notationID)) {
        return 'left';
      }
      if (this.config.axis.right.lines.some((line) => line.notation_id === notationID)) {
        return 'right';
      }
      return 'none';
    },
    assignNotationToAxis(notationID, axis, color) {
      if (axis !== 'left') {
        this.config.axis.left.lines = deleteNotationFromConfigAxisLines(
          this.config.axis.left.lines,
          notationID,
        );
      }
      if (axis !== 'right') {
        this.config.axis.right.lines = deleteNotationFromConfigAxisLines(
          this.config.axis.right.lines,
          notationID,
        );
      }
      if (axis === 'left') {
        addNotationToConfigAxisLines(this.config.axis.left.lines, notationID, color);
      }
      if (axis === 'right') {
        addNotationToConfigAxisLines(this.config.axis.right.lines, notationID, color);
      }
    },
    updateCompositeUnit(compositeUnit, axis) {
      const configAxis = this.config.axis[axis];
      if (
        compositeUnit.composition_category !== configAxis.composite_unit.composition_category
        || compositeUnit.volume_unit_category !== configAxis.composite_unit.volume_unit_category
      ) {
        configAxis.lines = [];
      }
      configAxis.composite_unit = compositeUnit;
    },
    addNotationsToConfig(addNotations) {
      const notationIDsFromStore = Object.values(this.notations).map((notation) => notation.id);
      const notationIDsFromModal = addNotations.notations
        .map((notation) => notation.id);
      const newConfig = { ...this.config };
      newConfig.notationIDs = [...new Set(
        notationIDsFromStore.concat(notationIDsFromModal),
      )];
      newConfig.dashboardIdOfLastAddedNotation = addNotations.dashboardId;
      this.setConfiguration(newConfig);
    },
    removeNotationFromConfig(notationID, axis) {
      const newConfig = { ...this.config };
      if (axis === 'left' || axis === 'right') {
        newConfig.axis[axis].lines = deleteNotationFromConfigAxisLines(
          newConfig.axis[axis].lines,
          notationID,
        );
      }
      newConfig.notationIDs = Object.values(this.notations)
        .filter((notation) => notationID !== notation.id)
        .map((notation) => notation.id);
      this.setConfiguration(newConfig);
    },
    setDefaultState() {
      this.compatibleUnits = {};
      this.units = [];
      this.compositionCategories = [];
      this.categories = [];
      this.priceUnitCategories = [];
      this.volumeUnitCategories = [];
      this.quantityUnitCategories = [];
      this.notations = {};
    },
    updateConfigLineColor(axis) {
      this.config.axis[axis].lines = this.config.axis[axis].lines.map((line) => ({
        notation_id: this.notations[line.notation_id].id,
        color: this.notations[line.notation_id].color,
      }));
    },
    setSavedAnalysisId(savedAnalysisId) {
      this.savedAnalysisId = savedAnalysisId;
    },
    newConfiguration(notationIDs, dashboardId) {
      const config = {
        title: i18n.t('apps.chart_config.default_title'),
        notationIDs,
        dashboardIdOfLastAddedNotation: dashboardId,
        selectedAxesCount: 1,
        axis: {
          left: {
            composite_unit: {
              composition_category: null,
              currency_unit_id: null,
              volume_unit_id: null,
              volume_unit_category: null,
            },
            lines: [],
          },
          right: {
            composite_unit: {
              composition_category: null,
              currency_unit_id: null,
              volume_unit_id: null,
              volume_unit_category: null,
            },
            lines: [],
          },
        },
      };
      this.setConfiguration(config);
    },
    async setConfiguration(config) {
      try {
        this.isLoading = true;
        this.config = config;
        const { notationIDs } = this.config;
        this.setDefaultState();
        if (notationIDs.length > 0) {
          const firstPromises = [];
          firstPromises.push(this.fetchNotations(notationIDs));
          firstPromises.push(this.fetchCompatibleUnits(notationIDs));
          await Promise.all(firstPromises);
          const secondPromises = [];
          this.resetCategories();
          secondPromises.push(this.fetchUnits());
          await Promise.all(secondPromises);
        }
        const axes = ['left', 'right'];
        axes.forEach((axis) => {
          const compositeUnit = this.config.axis[axis].composite_unit;
          if (!this.compositionCategories.includes(compositeUnit.composition_category)) {
            compositeUnit.composition_category = null;
            compositeUnit.currency_unit_id = null;
            compositeUnit.volume_unit_id = null;
            compositeUnit.volume_unit_category = null;
          }
          if (!this.quantityUnitCategories.includes(compositeUnit.volume_unit_category)) {
            compositeUnit.volume_unit_id = null;
            compositeUnit.volume_unit_category = null;
          }
          this.updateConfigLineColor(axis);
        });
      } catch (e) {
        this.hasError = true;
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
