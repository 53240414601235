<template>
  <div class="search-header">
    <h3 class="search-header--caption">
      <Icon
        v-if="controller.isLoading"
        class="search-header--is-loading"
        name="spinner"
        :spin="true"
      />
      {{ caption }}
    </h3>
    <ul class="search-header--action-list">
      <li
        v-if="controller.checkedNotations.length > 0"
        class="search-header--action-list-item"
      >
        <ButtonWithDropdown
          variant="primary"
          icon="caret-down"
          :is-disabled="controller.isLoading"
        >
          {{ $t('components.search_header.batch_actions.button') }}
          <template #dropdown>
            <DropdownMenu>
              <AddNotationDoDashboardDropdownButton :controller="controller" />
              <DropdownMenuButton
                v-if="dashboard.can_update"
                icon="trash-alt"
                @click="controller.deleteCheckedNotationsFromDashboard($event)"
              >
                {{ $t('components.dashboard_header.remove_notations') }}
              </DropdownMenuButton>
              <MultiLineChartAnalysisDropdownButton
                :notation-ids="controller.checkedNotations.map((notation) => notation.id)"
                :dashboard-id="dashboard.id"
              />
              <SeasonLineChartAnalysisDropdownButton
                :notation-ids="controller.checkedNotations.map((notation) => notation.id)"
                :dashboard-id="dashboard.id"
              />
            </DropdownMenu>
          </template>
        </ButtonWithDropdown>
      </li>
      <li class="search-header--action-list-item">
        <ButtonComponent
          variant="primary"
          icon="columns"
          :is-disabled="controller.isLoading"
          @click="controller.toggleDetailColumns()"
        >
          {{
            controller.showDetailColumns
              ? $t('components.search_header.hide_details')
              : $t('components.search_header.show_details')
          }}
        </ButtonComponent>
      </li>
      <li class="search-header--action-list-item">
        <PdfExportButtonComponent
          :is-disabled="controller.isPdfExportButtonDisabled"
          :text="$t('components.generic.pdf_export_button.for_internal_use')"
        />
      </li>
      <DatePickerButton
        :comparison-date="controller.comparisonDate"
        :is-disabled="controller.isLoading"
        @update:comparisonDate="onDateSelect"
      />
      <li class="search-header--action-list-item">
        <ButtonComponent
          variant="primary"
          icon="times"
          :is-disabled="!controller.filtersActive || controller.isLoading"
          @click="controller.clearFilters()"
        >
          {{ $t('components.search_header.clear_filters') }}
        </ButtonComponent>
      </li>
      <li
        v-if="$features.DASHBOARD_SUBSCRIPTIONS"
        class="search-header--action-list-item"
      >
        <MailExportButtonComponent
          mail-export-type="DashboardSubscription"
          :dashboard-id="dashboard.id"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {
  DatePickerButton,
  AddNotationDoDashboardDropdownButton,
  MailExportButtonComponent,
  MultiLineChartAnalysisDropdownButton,
  SeasonLineChartAnalysisDropdownButton,
} from '@/components';

export default {
  name: 'DashboardListHeader',
  components: {
    AddNotationDoDashboardDropdownButton,
    MailExportButtonComponent,
    MultiLineChartAnalysisDropdownButton,
    SeasonLineChartAnalysisDropdownButton,
    DatePickerButton,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    controller: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onDateSelect(date) {
      // eslint-disable-next-line vue/no-mutating-props
      this.controller.comparisonDate = date;
    },
  },
};
</script>
