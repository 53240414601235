<template>
  <div class="dashboard-overview--hint">
    {{ $t('components.intro.no_data') }}
  </div>
</template>

<script>
export default {
  name: 'FavouriteCreationHint',
};
</script>
