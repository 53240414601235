<template>
  <span
    class="fa fa-question fa-fw icon-placeholder"
    aria-hidden="true"
  />
</template>

<script>
// Icon with fa-fw that is visually and semantically hidden.
// Use this when some list items have an icon and some don't and the text needs to be aligned.
// Workaround for: https://github.com/FortAwesome/Font-Awesome/issues/14339
export default {
  name: 'IconPlaceholder',
};
</script>
