<template>
  <div class="spinning-loader">
    <Icon
      class="spinning-loader--icon"
      name="spinner"
      :spin="true"
    />
    <span
      v-if="text"
      class="spinning-loader--text"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import i18n from '@/config/i18n';

export default {
  name: 'SpinningLoader',
  props: {
    text: {
      type: String,
      default: i18n.t('components.spinning_loader.default_text'),
    },
  },
};
</script>
