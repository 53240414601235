<template>
  <ul class="tag-list">
    <NotationDetailsTagListItem
      class="tag-list--item"
      :tooltip="`${$t('attributes.notation.reporter')} | ${$t('attributes.notation.partner')}`"
    >
      {{ notation.reporter }}
      |
      {{ notation.partner }}
    </NotationDetailsTagListItem>
    <NotationDetailsTagListItem
      v-for="attribute in attributes"
      :key="attribute.name"
      class="tag-list--item"
      :is-highlighted="attribute.isHighlighted"
      :tooltip="$t(`attributes.notation.${attribute.name}`)"
    >
      {{ attribute.value || '&ndash;' }}
    </NotationDetailsTagListItem>
    <NotationDetailsTagListUnitItem
      :unit="unit || notation.unit"
      :unit-converted-from="unitConvertedFrom"
    />
  </ul>
</template>

<script>
import NotationDetailsTagListItem from './Item/Component.vue';
import NotationDetailsTagListUnitItem from './UnitItem/Component.vue';

const ATTRIBUTES = [
  'flow',
  'indicator',
  'attribute_economy',
  'attribute_unit',
  'timescope',
  'source',
];

export default {
  name: 'NotationDetailsTagList',
  components: {
    NotationDetailsTagListItem,
    NotationDetailsTagListUnitItem,
  },
  props: {
    notation: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
    unitConvertedFrom: {
      type: String,
      default: null,
    },
  },
  computed: {
    attributes() {
      return [...ATTRIBUTES.map((attribute) => ({
        name: attribute,
        value: this.notation[attribute],
      }))];
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>