<template>
  <SplitPageLayout screen-size="narrow">
    <template #top>
      <NewsHeader
        :editorial-controller="editorialController"
      />
      <NewsFilter
        :controller="newsFilterController"
      />
    </template>
    <template #bottom>
      <NewsList
        :pagination="newsPagination"
        :editorial-controller="editorialController"
        @mark-all-as-read="markAllAsRead()"
      />
    </template>
  </SplitPageLayout>
</template>

<script>
import { toRef } from 'vue';
import { SplitPageLayout } from '@/components/Layouts';
import PaginationService from '@/services/PaginationService';
import UrlStateStore from '@/services/UrlStateStore';
import {
  NewsPaginationConfig,
  useNewsUnreadCount,
  MarkAllNewsAsRead,
} from '@/helpers/PublicationHelpers';
import debounce from '@/helpers/debounce';
import NewsHeader from './components/Header/Component.vue';
import NewsFilter from './components/Filter/Component.vue';
import NewsList from './components/List/Component.vue';
import NewsFilterController from './components/Filter/Controller';
import NewsEditorialController from './EditorialController';

export default {
  name: 'NewsApp',
  components: {
    SplitPageLayout,
    NewsHeader,
    NewsFilter,
    NewsList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
  },
  data() {
    const newsPagination = new PaginationService(NewsPaginationConfig);
    return {
      newsFilterController: new NewsFilterController({
        ...this.initialState?.filters,
      }),
      editorialController: this.user.editor_permissions ? new NewsEditorialController() : null,
      newsPagination,
      unreadCountObserver: null,
      fetchFirstPageDebounced: debounce(() => {
        this.fetchFirstPage();
      }, 500),
    };
  },
  beforeMount() {
    this.fetchFirstPage();
    this.newsFilterController.on('change', () => this.fetchFirstPageDebounced());
    this.editorialController?.on('require-reload', () => this.fetchFirstPage());
  },
  beforeUnmount() {
    this.unreadCountObserver?.off();
  },
  methods: {
    async fetchFirstPage() {
      UrlStateStore.set('filters', this.newsFilterController.filtersForUrlState);
      await this.newsPagination.fetchFirstPage(this.newsFilterController.filtersForRequest);
      this.unreadCountObserver?.off();
      this.unreadCountObserver = useNewsUnreadCount(toRef(this.newsPagination.meta, 'unread_count'));
    },
    async markAllAsRead() {
      await MarkAllNewsAsRead(this.newsFilterController.filtersForRequest);
      this.newsPagination.entries.forEach((entry) => { entry.unread = false; });
    },
  },
};
</script>
