import { DEFAULT_STORAGE, STORAGE_SPLITTER, storageMap } from './StorageConst';

const storageList = Object.values(storageMap);

class StorageService {
  getStorage(key) {
    const [keyStorage] = key.split(STORAGE_SPLITTER);
    return storageMap[keyStorage] || DEFAULT_STORAGE;
  }

  _parseString(str) {
    if (str) {
      return JSON.parse(str);
    }

    return str;
  }

  _dataToString(obj) {
    return JSON.stringify(obj);
  }

  get(key, _default = {}) {
    const strData = this.getStorage(key).getItem(key);
    const data = this._parseString(strData);
    const result = arguments.length === 1 ? data : data || _default;

    return result;
  }

  set(key, value) {
    this.getStorage(key).setItem(key, this._dataToString(value));
  }

  merge(key, value, _defaultValue = {}) {
    const oldValue = this.get(key, _defaultValue);
    const newValue = Object.assign(oldValue, value);

    this.getStorage(key).setItem(key, this._dataToString(newValue));
  }

  remove(key) {
    this.getStorage(key).removeItem(key);
  }

  clear() {
    storageList.forEach((storage) => {
      this.clearStorage(storage);
    });
  }

  clearStorage(storage) {
    storage.clear();
  }
}

export default StorageService;
