<template>
  <div class="footer">
    <ExportDropdownButton
      :disabled="!canExportRawData"
      :primary-notation-details="primaryNotationDetails"
      :secondary-notation-details="secondaryNotationDetails"
      :graph-limits="graphLimits"
      :selected-date="selectedDate"
      :selected-currency-id="selectedCurrencyId"
      :whitelisted-sources="whitelistedSources"
    />
    <ButtonComponent
      variant="secondary"
      :is-toggled="selection.selectionMode === 'primary'"
      @click="selection.toggleSelectionMode('primary')"
    >
      <template #icon>
        <NotationColorIcon
          class="button-icon"
          slot-kind="primary"
        />
      </template>
      {{ $t('components.notation.buttons.select_primary') }}
    </ButtonComponent>
    <ButtonComponent
      variant="secondary"
      icon-variant="regular"
      :is-toggled="selection.selectionMode === 'secondary'"
      @click="selection.toggleSelectionMode('secondary')"
    >
      <template #icon>
        <NotationColorIcon
          class="button-icon"
          slot-kind="secondary"
        />
      </template>
      {{ $t('components.notation.buttons.select_secondary') }}
    </ButtonComponent>
    <ButtonComponent
      v-if="selection.canClearSecondarySelection"
      variant="ternary"
      icon="times"
      icon-variant="regular"
      @click="$emit('clear-secondary-selection')"
    >
      {{ $t('components.notation.buttons.clear_secondary') }}
    </ButtonComponent>
  </div>
</template>

<script>
import NotationColorIcon from '@/components/NotationColorIcon/Component.vue';
import ExportDropdownButton from './ExportDropdownButton/Component.vue';

export default {
  name: 'NotationCardFooter',
  components: {
    NotationColorIcon,
    ExportDropdownButton,
  },
  props: {
    selection: {
      type: Object,
      required: true,
    },
    primaryNotationDetails: {
      type: Object,
      default: null,
    },
    secondaryNotationDetails: {
      type: Object,
      default: null,
    },
    graphLimits: {
      type: Object,
      default: null,
    },
    canExportRawData: {
      type: Boolean,
      required: true,
    },
    selectedDate: {
      type: Date,
      default: () => null,
    },
    selectedCurrencyId: {
      type: Number,
      default: null,
    },
    whitelistedSources: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['clear-secondary-selection'],
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>