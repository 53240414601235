import { ref, watch } from "vue";
import { getQueryParam, setQueryParam } from "../../../services/UrlStateStore";

export const usePortraitsDropdowns = (subjectId1, subjectId2, subjectId3, subjectId4, subjectId5, subjectId6) => {
  const selectedSubject01 = ref(+getQueryParam("subject01", subjectId1));
  const selectedSubject02 = ref(+getQueryParam("subject02", subjectId2));
  const selectedSubject03 = ref(+getQueryParam("subject03", subjectId3));
  const selectedSubject04 = ref(+getQueryParam("subject04", subjectId4));
  const selectedSubject05 = ref(+getQueryParam("subject05", subjectId5));
  const selectedSubject06 = ref(+getQueryParam("subject06", subjectId6));

  watch(selectedSubject01, (subjectId) => {
    setQueryParam("subject01", subjectId);
  });
  watch(selectedSubject02, (subjectId) => {
    setQueryParam("subject02", subjectId);
  });
  watch(selectedSubject03, (subjectId) => {
    setQueryParam("subject03", subjectId);
  });
  watch(selectedSubject04, (subjectId) => {
    setQueryParam("subject04", subjectId);
  });
  watch(selectedSubject05, (subjectId) => {
    setQueryParam("subject05", subjectId);
  });
  watch(selectedSubject06, (subjectId) => {
    setQueryParam("subject06", subjectId);
  });

  return {
    selectedSubject01,
    selectedSubject02,
    selectedSubject03,
    selectedSubject04,
    selectedSubject05,
    selectedSubject06,
  };
};
