<template>
  <SplitPageLayout screen-size="medium">
    <template v-if="hasError">
      <ChartConfigurationNotFound />
    </template>
    <template #top>
      <ChartConfigurationHeader
        :title="$t('apps.season_line_chart_config.title')"
        :view-url="viewUrl"
        :validation-error="validationError"
      />
    </template>
    <template #bottom>
      <SpinningLoader
        v-if="isLoading"
      />
      <div v-else>
        <SeasonLineChartConfigurationForm
          v-model:title="config.title"
          v-model:start-month-id="config.startMonthId"
          v-model:composite-unit="config.compositeUnit"
          :selected-years="config.selectedYears"
          :years-with-quotes="yearsWithQuotes"
          :available-volume-units="availableVolumeUnits"
          :available-currencies="availableCurrencies"
          @update:selected-years="updateSelectedYears"
        />
        <ChartNotationAssignmentTable
          :user="user"
          :only-left-axis-assignable="true"
          :max-notations-count="1"
          :notations="notationForAssignmentTable"
          :dashboard-id-of-last-added-notation="config.dashboardIdOfLastAddedNotation"
          @update-notations="updateNotation"
          @remove-notation-from-config="removeNotationFromConfig"
        />
      </div>
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import { computed } from 'vue';
import {
  ChartConfigurationHeader,
  ChartConfigurationNotFound,
} from '@/components';
import UrlStateStore from '@/services/UrlStateStore';
import Turbolinks from 'turbolinks';
import ChartNotationAssignmentTable from '@/components/ChartNotationAssignmentTable/Component.vue';
import { useSeasonLineChartConfigurationStore } from './store';
import SeasonLineChartConfigurationForm from './SeasonLineChartConfigurationForm/Component.vue';

export default {
  name: 'SeasonLineChartConfigurationApp',
  components: {
    SplitPageLayout,
    ChartConfigurationHeader,
    ChartConfigurationNotFound,
    SeasonLineChartConfigurationForm,
    ChartNotationAssignmentTable,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useSeasonLineChartConfigurationStore();

    const initialConfig = UrlStateStore.get('config', null);
    const savedAnalysisId = UrlStateStore.get('savedAnalysisId', null);

    if (savedAnalysisId !== null) {
      store.setSavedAnalysisId(savedAnalysisId);
    }

    if (initialConfig === null) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      Turbolinks.controller
        .replaceHistoryWithLocationAndRestorationIdentifier('?', Turbolinks.uuid());
      let notationID = parseInt(urlParams.get('notation_id'), 10);
      if (Number.isNaN(notationID)) {
        notationID = null;
      }
      let dashboardID = parseInt(urlParams.get('dashboard_id'), 10);
      if (Number.isNaN(dashboardID)) {
        dashboardID = null;
      }
      store.newConfiguration(notationID, dashboardID);
    } else {
      store.setConfiguration(initialConfig);
    }

    const updateSelectedYears = (year) => {
      store.updateSelectedYears(year);
    };

    const updateNotation = (changeNotation) => {
      const notationID = changeNotation.notations[0].id;
      const dashboardID = changeNotation.dashboardId;
      store.newConfiguration(notationID, dashboardID);
    };

    const removeNotationFromConfig = () => {
      store.removeNotationFromConfig();
    };

    return {
      isLoading: computed(() => store.isLoading),
      hasError: computed(() => store.hasError),
      validationError: computed(() => store.validationError),
      viewUrl: computed(() => store.viewUrl),
      config: computed(() => store.config),
      savedAnalysisId: computed(() => store.savedAnalysisId),
      yearsWithQuotes: computed(() => store.yearsWithQuotes),
      availableVolumeUnits: computed(() => store.availableVolumeUnits),
      availableCurrencies: computed(() => store.availableCurrencies),
      notationForAssignmentTable: computed(() => store.notationForAssignmentTable),
      updateSelectedYears,
      updateNotation,
      removeNotationFromConfig,
    };
  },

  watch: {
    config: {
      deep: true,
      handler() {
        UrlStateStore.set('config', this.config);
      },
    },
  },
};
</script>
