import i18n from '@/config/i18n';
import { compareBy } from '@/helpers/SortHelpers';
import { computed } from 'vue';

const optionsForEnumerize = (i18nKey) => Object.entries(i18n.tm(i18nKey)).map(([key, value]) => ({
  id: key,
  name: value,
})).sort(compareBy((o) => o.name));

const useLocalizedImage = (sources) => computed(() => sources[i18n.locale]);

const months = () => [
  {
    id: 1,
    name: i18n.t('generic.months.january'),
  },
  {
    id: 2,
    name: i18n.t('generic.months.february'),
  },
  {
    id: 3,
    name: i18n.t('generic.months.march'),
  },
  {
    id: 4,
    name: i18n.t('generic.months.april'),
  },
  {
    id: 5,
    name: i18n.t('generic.months.may'),
  },
  {
    id: 6,
    name: i18n.t('generic.months.june'),
  },
  {
    id: 7,
    name: i18n.t('generic.months.july'),
  },
  {
    id: 8,
    name: i18n.t('generic.months.august'),
  },
  {
    id: 9,
    name: i18n.t('generic.months.september'),
  },
  {
    id: 10,
    name: i18n.t('generic.months.october'),
  },
  {
    id: 11,
    name: i18n.t('generic.months.november'),
  },
  {
    id: 12,
    name: i18n.t('generic.months.december'),
  },
];

export {
  optionsForEnumerize,
  useLocalizedImage,
  months,
};
