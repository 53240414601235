<template>
  <div
    class="value-range-ribbon"
    :class="cssModifierClass"
  >
    {{ translatedValue }}
  </div>
</template>

<script>
const validateValue = (value) => ['low', 'medium', 'high', null].includes(value);

export default {
  name: 'ValueRangeRibbon',
  props: {
    value: {
      type: String,
      default: null,
      validator: validateValue,
    },
  },
  computed: {
    translatedValue() {
      return this.$t(`components.notation_list.columns.current_value_range.${this.value}`);
    },
    cssModifierClass() {
      return this.value ? `is-${this.value}` : 'is-null';
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>