import { reactive } from 'vue';
import axios from 'axios';
import { RequiredArg, NotNullOrUndefined } from '@/helpers';

export default class CursorPaginationService {
  constructor({
    url = RequiredArg('url'),
    transformEntry = (entry) => entry,
    errorHandler = (error) => { throw error; },
    params = {},
    cursor = null,
    entries = [],
  }) {
    this._url = url;
    this._transformEntry = transformEntry;
    this._cancelToken = axios.CancelToken.source();
    this._errorHandler = errorHandler;
    this._params = params;
    // NOTE: Never reassign entries. Then other services
    // and controllers can keep hold of them and will
    // be updated through Vue's reactivity system.
    this._state = reactive({
      isLoading: false,
      nextCursor: cursor,
      entries,
    });
  }

  get isLoading() {
    return this._state.isLoading;
  }

  get hasMoreResults() {
    return NotNullOrUndefined(this._state.nextCursor);
  }

  get entries() {
    return this._state.entries;
  }

  get _requestOptions() {
    const params = { ...this._params, cursor: this._state.nextCursor };
    // cursor pagination only works with POST requests because of cursor serialization
    const options = {
      url: this._url,
      method: 'POST',
      cancelToken: this._cancelToken.token,
      data: params,
    };
    return options;
  }

  _clearEntries() {
    // clear the array by setting its length to zero to keep references to the array intact
    this._state.entries.length = 0;
  }

  cancel() {
    this._cancelToken.cancel('Cancelled by user');
    this._cancelToken = axios.CancelToken.source();
    this._state.isLoading = false;
  }

  start(params) {
    this.cancel();
    if (params) {
      // remove any reactivity by converting to json and back
      this._params = JSON.parse(JSON.stringify(params));
    }
    this._state.isLoading = false;
    this._state.nextCursor = 0;
    return this.next({ clearEntries: true });
  }

  next({ clearEntries = false } = {}) {
    if (this.isLoading || !this.hasMoreResults) {
      return Promise.resolve(false);
    }
    this._state.isLoading = true;
    return axios(this._requestOptions)
      .then((response) => this._onResponse(response, { clearEntries }))
      .catch((error) => this._onError(error));
  }

  _onError(error) {
    if (axios.isCancel(error)) {
      return true;
    }
    this._state.nextCursor = null;
    this._errorHandler(error);
    this._state.isLoading = false;
    return false;
  }

  _onResponse(response, { clearEntries = false } = {}) {
    if (clearEntries) {
      this._clearEntries();
    }
    this._state.entries.push(...response.data.entries.map(this._transformEntry));
    this._state.nextCursor = response.data.next_cursor;
    this._state.isLoading = false;
    return true;
  }
}
