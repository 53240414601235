<template>
  <TableHeadCell
    :title="displayName"
    :filter-active="controller.filters[columnKey].isActive"
    :tooltip="tooltip"
  >
    <template #filter>
      <FilterWithSearch
        :is-wide="filterPopupIsWide"
        :title="displayName"
        :filter-values="controller.filters[columnKey].values"
        @change="controller.onFiltersChanged()"
      />
    </template>
  </TableHeadCell>
</template>

<script>
import TableHeadCell from '../TableHeadCell.vue';
import FilterWithSearch from '../FilterWithSearch.vue';

export default {
  name: 'SearchFilterColumnHead',
  components: {
    TableHeadCell,
    FilterWithSearch,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: () => null,
    },
    controller: {
      type: Object,
      required: true,
    },
    filterPopupIsWide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
