<template>
  <li class="section">
    <label class="section--title">
      <template v-if="!$slots.title">
        {{ title }}
      </template>
      <slot name="title" />
    </label>
    <slot />
  </li>
</template>

<script>
export default {
  name: 'DashboardSearchListSection',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>