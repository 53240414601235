<template>
  <SpinningLoader
    v-if="isLoading"
    class="dashboard-overview--spinning-loader"
  />
  <div
    v-else-if="hasError"
    class="dashboard-overview--not-found"
  >
    <h1 class="dashboard-overview--not-found-title">
      {{ $t('apps.dashboard_overview.error_title') }}
    </h1>
  </div>
  <div
    v-else
    class="dashboard-overview--card"
  >
    <p
      v-if="forInternalUse"
      class="internal-use-hint"
    >
      {{ $t('generic.for_internal_use_only') }}
    </p>
    <NotationCardOverview
      :primary-notation-details="primaryNotationDetails"
      :secondary-notation-details="secondaryNotationDetails"
      :selected-date="selectedDate"
      :selected-graph-limits="selectedGraphLimits"
      :show-date-slider="false"
      :show-date-picker-button="false"
      :show-absolute-prices="forInternalUse"
    />
  </div>
</template>

<script>
import NotationCardOverview from '@/components/NotationCardOverview/Component.vue';
import UrlStateStore from '@/services/UrlStateStore';
import { computed } from 'vue';
import { useDashboardOverviewExportStore } from './store';

export default {
  name: 'DashboardOverviewExportApp',
  components: {
    NotationCardOverview,
  },
  setup() {
    const store = useDashboardOverviewExportStore();

    const config = UrlStateStore.get('config');

    store.setConfiguration(config);

    return {
      selectedDate: computed(() => store.config.selectedDate),
      selectedGraphLimits: computed(() => store.selectedGraphLimits),
      primaryNotationDetails: computed(() => store.primaryNotationDetails),
      secondaryNotationDetails: computed(() => store.secondaryNotationDetails),
      isLoading: computed(() => store.isLoading),
      hasError: computed(() => store.hasError),
      forInternalUse: computed(() => store.forInternalUse),
    };
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>