<template>
  <ul
    v-exclude-from-print
    class="action-list"
  >
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'ActionList',
};
</script>
