<template>
  <SplitPageLayout screen-size="medium">
    <template v-if="hasError">
      <ChartConfigurationNotFound />
    </template>
    <template #top>
      <ChartConfigurationHeader
        :title="$t('apps.chart_config.title')"
        :view-url="viewUrl"
        :validation-error="validationError"
      />
    </template>
    <template #bottom>
      <SpinningLoader
        v-if="isLoading"
      />
      <div v-else>
        <ChartConfigurationForm
          v-model:title="config.title"
          :left-axis-composite-unit="config.axis.left.composite_unit"
          :right-axis-composite-unit="config.axis.right.composite_unit"
          :selected-axes-count="selectedAxesCount"
          :available-composition-categories="availableCompositionCategories"
          :available-currencies="availableCurrencies"
          :available-volume-units="availableVolumeUnits"
          :price-unit-categories="priceUnitCategories"
          :volume-unit-categories="volumeUnitCategories"
          @update:selected-axes-count="updateSelectedAxesCount"
          @update:left-axis-composite-unit="
            (compositeUnit) => updateCompositeUnit(compositeUnit, 'left')"
          @update:right-axis-composite-unit="
            (compositeUnit) => updateCompositeUnit(compositeUnit, 'right')"
        />
        <ChartNotationAssignmentTable
          :notations="notations"
          :compatible-units="compatibleUnits"
          :left-axis-composite-unit="config.axis.left.composite_unit"
          :right-axis-composite-unit="config.axis.right.composite_unit"
          :units="units"
          :user="user"
          :dashboard-id-of-last-added-notation="config.dashboardIdOfLastAddedNotation"
          @update:assignedAxisForNotation="updateAssignedNotationToAxis"
          @update-notations="addNotationsToConfig"
          @remove-notation-from-config="removeNotationFromConfig"
        />
      </div>
    </template>
  </SplitPageLayout>
</template>

<script>
import { SplitPageLayout } from '@/components/Layouts';
import { computed } from 'vue';
import Turbolinks from 'turbolinks';
import UrlStateStore from '@/services/UrlStateStore';
import {
  ChartConfigurationHeader,
  ChartConfigurationNotFound,
} from '@/components';
import ChartNotationAssignmentTable from '@/components/ChartNotationAssignmentTable/Component.vue';
import ChartConfigurationForm from './ChartConfigurationForm/Component.vue';
import { useChartConfigurationStore } from './store';

export default {
  name: 'MultiLineChartConfigurationApp',
  components: {
    ChartConfigurationForm,
    SplitPageLayout,
    ChartNotationAssignmentTable,
    ChartConfigurationHeader,
    ChartConfigurationNotFound,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useChartConfigurationStore();

    const initialConfig = UrlStateStore.get('config', null);
    const savedAnalysisId = UrlStateStore.get('savedAnalysisId', null);

    if (savedAnalysisId !== null) {
      store.setSavedAnalysisId(savedAnalysisId);
    }

    if (initialConfig === null) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      Turbolinks.controller
        .replaceHistoryWithLocationAndRestorationIdentifier('?', Turbolinks.uuid());
      const notationIDs = urlParams.getAll('notation_id[]').map((id) => parseInt(id, 10));
      let dashboardID = parseInt(urlParams.get('dashboard_id'), 10);
      if (Number.isNaN(dashboardID)) {
        dashboardID = null;
      }
      store.newConfiguration(notationIDs, dashboardID);
    } else {
      store.setConfiguration(initialConfig);
    }

    const updateSelectedAxesCount = (selectedAxesCount) => {
      store.setSelectedAxesCount(selectedAxesCount);
    };

    const updateAssignedNotationToAxis = (assignNotationToAxis) => {
      store.assignNotationToAxis(
        assignNotationToAxis.notation_id,
        assignNotationToAxis.axis,
        assignNotationToAxis.color,
      );
    };

    const updateCompositeUnit = (compositeUnit, axis) => {
      store.updateCompositeUnit(compositeUnit, axis);
    };

    const addNotationsToConfig = (addNotations) => {
      store.addNotationsToConfig(addNotations);
    };

    const removeNotationFromConfig = (removeNotation) => {
      store.removeNotationFromConfig(
        removeNotation.notationID,
        removeNotation.axis,
      );
    };

    return {
      notations: computed(() => store.notationsWithAxis),
      isLoading: computed(() => store.isLoading),
      config: computed(() => store.config),
      selectedAxesCount: computed(() => store.selectedAxesCount),
      availableCompositionCategories: computed(() => store.availableCompositionCategories),
      compatibleUnits: computed(() => store.compatibleUnits),
      availableCurrencies: computed(() => store.availableCurrencies),
      availableVolumeUnits: computed(() => store.availableVolumeUnits),
      priceUnitCategories: computed(() => store.priceUnitCategories),
      volumeUnitCategories: computed(() => store.volumeUnitCategories),
      savedAnalysisId: computed(() => store.savedAnalysisId),
      hasError: computed(() => store.hasError),
      units: computed(() => store.units),
      validationError: computed(() => store.validationError),
      viewUrl: computed(() => store.viewUrl),
      updateSelectedAxesCount,
      updateAssignedNotationToAxis,
      addNotationsToConfig,
      removeNotationFromConfig,
      updateCompositeUnit,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        UrlStateStore.set('config', this.config);
      },
    },
  },
};
</script>
