<template>
  <div class="print-title">
    <p class="print-title--addressee">
      {{ title }}
    </p>
    <i18n-t
      tag="p"
      keypath="apps.intro.print_export_date"
      class="print-title--export-date"
    >
      <template #date>
        {{ exportDate }}
      </template>
      <template #name>
        <a :href="$routes.Root()">HGS Research</a>
      </template>
    </i18n-t>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'IntroPrintTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    exportDate() {
      return moment().format('LL');
    },
  },
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>