<template>
  <div class="intro-card">
    <LocalizedBannerLogo
      class="intro-logo"
    />
  </div>
</template>

<script>
import LocalizedBannerLogo from '../../LocalizedBannerLogo/Component.vue';

export default {
  name: 'WelcomeBannerLogo',
  components: {
    LocalizedBannerLogo,
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>