<template>
  <component
    :is="component"
    v-if="component"
    :value="value"
    :additional-data="additionalData"
    :event-listeners="eventListeners"
    :classes="additionalClasses"
  />
</template>

<script>
// comment this out for dynamic imports
import NameColumn from './NameColumn/Component.vue';
import ValueRangeColumn from './ValueRangeColumn/Component.vue';
import TextColumn from './TextColumn.vue';
import DateColumn from './DateColumn.vue';
import NumberColumn from './NumberColumn.vue';
import KpiColumn from './KpiColumn.vue';
import LinkColumn from './LinkColumn.vue';
import IconColumn from './IconColumn/Component.vue';
import SourceColumn from './SourceColumn.vue';
import DatasetColumn from './DatasetColumn/Component.vue';
import MiniChartColumn from './MiniChartColumn.vue';
import CorrelationColumn from './CorrelationColumn/Component.vue';
import DashboardNameColumn from './DashboardNameColumn/Component.vue';
import UnitColumn from './UnitColumn.vue';
import TopFiveCountry from './TopFiveCountry/TopFiveCountry.vue';
import HrScoresColumn from './HrScoresColumn/HrScoresColumn.vue';
import TotalHrScoreColumn from './TotalHrScoreColumn/TotalHrScoreColumn.vue';
import FlagsColumn from '@/components/ExpandableColumnsTable/FlagsColumn.vue';
import CountryOnDemand from './CountryOnDemand/CountryOnDemand.vue';
import LinkColumnWithMapLink from './LinkColumnWithMapLink.vue';
import ValueWithContext from './ValueWithContext.vue';
import ScoreWithContext from './ScoreWithContext.vue';
import TextColumnWithIcon from './TextColumnWithIcon.vue';
import TextWithTooltipColumn from './TextWithTooltipColumn.vue';

const ColumnTypes = {
  text: 'TextColumn',
  name: 'NameColumn',
  date: 'DateColumn',
  number: 'NumberColumn',
  kpi: 'KpiColumn',
  link: 'LinkColumn',
  icon: 'IconColumn',
  source: 'SourceColumn',
  dataset: 'DatasetColumn',
  valueRange: 'ValueRangeColumn',
  chart: 'MiniChartColumn',
  correlation: 'CorrelationColumn',
  dashboardName: 'DashboardNameColumn',
  unit: 'UnitColumn',
  topFiveCountry: 'TopFiveCountry',
  hrScoresColumn: 'HrScoresColumn',
  countryOnDemand: 'CountryOnDemand',
  totalHrScoresColumn: 'TotalHrScoreColumn',
  flagsColumn: 'FlagsColumn',
  linkWithMapLink: 'LinkColumnWithMapLink',
  valueWithContext: 'ValueWithContext',
  scoreWithContext: 'ScoreWithContext',
  textWithTooltip: 'TextWithTooltipColumn',
  textColumnWithIcon: 'TextColumnWithIcon',
};

const validateColumnType = (type) => Object.keys(ColumnTypes).indexOf(type) !== -1;

export default {
  name: 'GenericColumn',
  components: { // comment these out for dynamic imports
    TextColumn,
    NameColumn,
    DateColumn,
    NumberColumn,
    KpiColumn,
    LinkColumn,
    IconColumn,
    SourceColumn,
    DatasetColumn,
    ValueRangeColumn,
    MiniChartColumn,
    CorrelationColumn,
    DashboardNameColumn,
    UnitColumn,
    TopFiveCountry,
    HrScoresColumn,
    CountryOnDemand,
    TotalHrScoreColumn,
    FlagsColumn,
    LinkColumnWithMapLink,
    ValueWithContext,
    ScoreWithContext,
    TextWithTooltipColumn,
    TextColumnWithIcon,
  },
  props: {
    columnType: {
      type: String,
      default: 'text',
      validator: validateColumnType,
    },
    columnKey: {
      type: [String, Boolean],
      default: '',
    },
    classes: {
      type: Object,
      default: null,
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    additionalData: {
      type: Object,
      default: null,
      required: false,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    component() {
      const componentName = ColumnTypes[this.columnType];
      return componentName;
      // uncomment this for dynamic imports
      // return () => import(`./${componentName}.vue`);
    },
    additionalClasses() {
      return this.classes ? this.classes[this.columnKey] : '';
    },
  }
};
</script>
