<template>
  <ComboBox
    class="classification-combobox"
    :model-value="modelValue"
    :options="options"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #button="scope">
      <ComboBoxButton>
        <template v-if="scope.value">
          <span class="classification-combobox--code">
            {{ scope.value.code }}
          </span>
          {{ scope.value.name }}
        </template>
      </ComboBoxButton>
    </template>

    <template #option="{ option }">
      <ComboBoxOption class="classification-combobox--option">
        <span class="classification-combobox--code">
          {{ option.code }}
        </span>
        {{ option.name }}
      </ComboBoxOption>
    </template>

    <template #footer="scope">
      <DashboardTradeFlowsClassificationComboBoxFooter
        :option="scope.focussedOption || scope.currentOption"
      />
    </template>
  </ComboBox>
</template>

<script>
import DashboardTradeFlowsClassificationComboBoxFooter from './Footer/Component.vue';

export default {
  name: 'DashboardTradeFlowsClassificationComboBox',
  components: {
    DashboardTradeFlowsClassificationComboBoxFooter,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>