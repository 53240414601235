<template>
  <td
    @click="copyId"
    class="data-table--body-cell"
    v-on="eventListeners"
  >
    <WithTooltip
      v-if="value !== null && 'id' in value && user.editor_permissions"
      :tooltip="`HGS_ID: ${value.id}`"
      y-alignment="top">
      <div>
        <span
          v-if="classPrefix"
          class="is-unobtrusive"
        >
          {{ classPrefix }}
        </span>
        {{ value.name }}
      </div>

    </WithTooltip>
    <div
      v-else>
      <span
        v-if="classPrefix"
        class="is-unobtrusive"
      >
        {{ classPrefix }}
      </span>
      {{ value.name }}
    </div>

  </td>
</template>

<script>
import { configureClassPrefix } from '@/helpers/ClassPrefixHelpers';
import WithTooltip from "../../../../generic/WithTooltip/Component.vue";

const validateValue = (value) => ('name' in value);

export default {
  name: 'NameColumn',
  components: {WithTooltip},
  props: {
    value: {
      type: Object,
      required: true,
      validator: validateValue,
    },
    eventListeners: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ['user'],
  computed: {
    classPrefix() {
      return configureClassPrefix(
        this.value.class_system,
        this.value.class_code,
      );
    },
  },
  methods: {
    async copyId() {
      if (!this.user.editor_permissions) {
        return false;
      }
      try {
        await navigator.clipboard.writeText(this.value.id);
      } catch($e) {
        return false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
@media only print { @import './style.print.scss'; }
@media only screen { @import './style.screen.scss'; }
</style>