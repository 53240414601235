import i18n from '@/config/i18n';
import { formatGradualPrecision } from '@/helpers/NumberFormats';
import moment from 'moment';

const MAX_DECIMAL_POINTS_FOR_SMALL_VALUES = 4;

const countDecimalPoints = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const formatTicksWithEqualDecimals = (value, index, values) => {
  const maxDecimalPoints = Math.max(...values.map(countDecimalPoints));
  const maxDecimalPointsCapped = Math.min(MAX_DECIMAL_POINTS_FOR_SMALL_VALUES, maxDecimalPoints);
  return i18n.n(value, 'decimal', {
    minimumFractionDigits: maxDecimalPointsCapped,
    maximumFractionDigits: maxDecimalPointsCapped,
  });
};

// When setting custom min and max for the y axis in chart.js, it simply sets the outer most ticks
// to these exact values regardless of the nice tick spacing it computes for the rest of the axis.
// So you might end up with labels like [8.2534, 10, 20, 30, 32.1239].
// This method hooks into the tick placement step of chart.js and snaps the outer ticks to the
// next interval.
// In the example above, we would end up with [0, 10, 20, 30, 40] instead.
//
// Now the ticks would leave the visible area of the chart, so we adjust min and max as well.
// In the example they would be set to 0 and 40 respectively.
//
// Finally, if the actual minimum or maximum is very close to the outer edge of the chart, we
// add some padding to min and/or max to allow the user to clearly see the entire chart.
const formatYAxisMinAndMaxTicks = (axis) => {
  // Compute the distance between two inner ticks.
  // Since the ticks are sorted max to min, this gives us a positive spacing.
  const spacing = axis.ticks[1] - axis.ticks[2];

  // Align first and last tick to be the same distance from their neighbors as all other ticks
  axis.ticks[0] = axis.ticks[1] + spacing;
  axis.ticks[axis.ticks.length - 1] = axis.ticks[axis.ticks.length - 2] - spacing;

  // Update min and max of chart to include at least the moved ticks...
  const originalMin = axis.min;
  const originalMax = axis.max;

  const max = axis.ticks[0];
  axis.max = max;
  axis.min = axis.ticks[axis.ticks.length - 1];

  // ...and move them out even further, if the data is closer to the edge than paddingThreshold
  const padding = spacing * 0.3;
  const paddingThreshold = spacing * 0.2;

  if (originalMin - axis.min < paddingThreshold) {
    axis.min -= padding;
  }
  if (axis.max - originalMax < paddingThreshold) {
    axis.max += padding;
  }
};

const generalOptions = () => ({
  animation: {
    duration: 0,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
});

const defaultTooltipDateFormat = (date) => moment(date).format('LL');

const tooltipOptions = ({
  axisType,
  formatDate = defaultTooltipDateFormat,
}) => ({
  mode: 'nearest',
  axis: axisType,
  intersect: false,
  position: 'nearest',
  callbacks: {
    title(tooltipItems, data) {
      const date = data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
      return `${formatDate(date)}`;
    },
    label(tooltipItem, data) {
      const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
      return formatGradualPrecision(value);
    },
  },
  backgroundColor: '#FFFFFF',
  titleFontColor: '#333840',
  borderColor: '#DDE3ED',
  bodyFontColor: '#8F9AAB',
  bodyAlign: 'right',
  titleAlign: 'center',
  borderWidth: 1,
  yPadding: 8,
  xPadding: 12,
});

const defaultXAxisTime = {
  minUnit: 'day',
  displayFormats: {
    day: '   MMM Do   ',
    month: '   MMM YY   ',
    quarter: '   [Q]Q YY   ',
    year: '   YYYY   ',
  },
};

const xAxisOptions = ({
  xMin,
  xMax,
  fontSize = 12,
  time = defaultXAxisTime,
}) => ([
  {
    type: 'time',
    ticks: {
      autoSkip: true,
      maxRotation: 0,
      minRotation: 0,
      min: xMin,
      max: xMax,
      font: {
        size: fontSize,
      },
    },
    time,
  },
]);

const yAxisOptions = ({
  yAxisOneMin = undefined,
  yAxisOneMax = undefined,
  yAxisTwoMin = undefined,
  yAxisTwoMax = undefined,
  drawSecondaryYAxisLines = false,
}) => {
  const hasCustomMinMax = yAxisOneMin !== undefined
    || yAxisOneMax !== undefined
    || yAxisTwoMin !== undefined
    || yAxisTwoMax !== undefined;

  return [{
    id: 'y-axis-1',
    display: 'auto',
    gridLines: {
      tickMarkLength: 5,
    },
    ticks: {
      callback: formatTicksWithEqualDecimals,
      min: yAxisOneMin,
      max: yAxisOneMax,
    },
    afterBuildTicks: (axis) => {
      if (hasCustomMinMax) {
        formatYAxisMinAndMaxTicks(axis);
      }
    },
  }, {
    id: 'y-axis-2',
    position: 'right',
    display: 'auto',
    gridLines: {
      drawOnChartArea: drawSecondaryYAxisLines,
      tickMarkLength: 5,
    },
    ticks: {
      callback: formatTicksWithEqualDecimals,
      min: yAxisTwoMin,
      max: yAxisTwoMax,
    },
    afterBuildTicks: (axis) => {
      if (hasCustomMinMax) {
        formatYAxisMinAndMaxTicks(axis);
      }
    },
  }];
};

export {
  generalOptions,
  tooltipOptions,
  xAxisOptions,
  yAxisOptions,
};
