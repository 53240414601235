import { ref, watch } from 'vue';
import { getQueryParam, setQueryParam } from '../../../services/UrlStateStore';
import QUERY_PARAM from '../../Intro/constants/commodity-query-params';

const getOptionByValue = (options, value, fallback = null) => options.find((o) => o.value === value) || fallback;

export const useCountryDropdowns = ({
  hrDimensionsForSelect
}) => {
  const selectedDimension = ref(getOptionByValue(hrDimensionsForSelect, +getQueryParam(QUERY_PARAM.DIMENSION), hrDimensionsForSelect[0]));

  watch(selectedDimension, (dimension) => setQueryParam(QUERY_PARAM.DIMENSION, dimension.value));

  return {
    selectedDimension
  };
};
