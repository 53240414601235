<template>
  <div
    ref="root"
    class="dialog--window is-wide"
    :style="style"
    open
  >
    <div
      class="dialog--header rich-text-modal--header"
      @mousedown="onDragStart"
    >
      <div class="rich-text-modal--heading">
        <slot name="icon">
          <RichTextModalIcon :name="icon" />
        </slot>
        <div class="rich-text-modal--title">
          <div
            v-if="subTitle"
            class="rich-text-modal--subtitle"
          >
            {{ subTitle }}
          </div>
          <div class="rich-text-modal--main-title">
            {{ title }}
          </div>
        </div>
      </div>
      <button
        class="rich-text-modal--close-button"
        :aria-label="$t('components.rich_text_modal.close_button')"
        @click="$emit('close')"
      >
        <Icon name="times" />
      </button>
    </div>
    <slot name="sub-header" />
    <div class="dialog--main">
      <div class="dialog--main-box rich-text-modal--body">
        <div class="rich-text-modal--meta">
          {{ publishedAtFormatted }}
          <span>
            &bull;
          </span>
          <CopyLinkButton
            :url="url"
          />
        </div>
        <SpinningLoader v-if="isLoading" />
        <slot
          v-else
          name="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import RichTextModalIcon from './Icon/Component.vue';

export default {
  name: 'RichTextModal',
  components: {
    RichTextModalIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'newspaper',
    },
    publishedAt: {
      type: [Date, String],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      offset: null,
      basePosition: null,
      resizeObserver: new ResizeObserver(this.resetPosition.bind(this)),
    };
  },
  computed: {
    publishedAtFormatted() {
      return moment(this.publishedAt).format('LL');
    },
    currentPosition() {
      if (!this.basePosition) return null;
      if (!this.offset) return this.basePosition;
      return {
        x: this.basePosition.x + this.offset.x,
        y: this.basePosition.y + this.offset.y,
      };
    },
    style() {
      if (!this.currentPosition) return null;
      const maxHeight = window.innerHeight - this.currentPosition.y;
      return `left: ${this.currentPosition.x}px; top: ${this.currentPosition.y}px; transform: none; max-height: min(90%, ${maxHeight}px);`;
    },
  },
  beforeMount() {
    this.resizeObserver.observe(document.body);
  },
  beforeUnmount() {
    this.resizeObserver.unobserve(document.body);
  },
  methods: {
    resetPosition() {
      this.basePosition = null;
      this.offset = null;
    },
    onDragStart(mouseDownEvent) {
      const { left, top } = this.$refs.root.getBoundingClientRect();
      this.offset = null;
      this.basePosition = { x: left, y: top };

      const mouseStart = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
      const eventListener = (mouseMoveEvent) => {
        mouseMoveEvent.preventDefault();
        const isLeftButtonDown = mouseMoveEvent.buttons === 1;
        if (!isLeftButtonDown) {
          document.removeEventListener('mousemove', eventListener);
          return;
        }
        const mouseDelta = {
          x: mouseMoveEvent.pageX - mouseStart.x,
          y: mouseMoveEvent.pageY - mouseStart.y,
        };
        if (this.offset === null) {
          this.offset = mouseDelta;
        } else {
          this.offset.x += mouseDelta.x;
          this.offset.y += mouseDelta.y;
        }
        mouseStart.x += mouseDelta.x;
        mouseStart.y += mouseDelta.y;
      };
      document.addEventListener('mousemove', eventListener);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>