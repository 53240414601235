import { reactive } from 'vue';
import { useHrScoreIcon } from './hrScoreIcon';
import useHrScoreFlagIcon from './hrScoreFlagIcon';

export const useHrScoreUtil = (hrScores) => {
  const { getIcon } = useHrScoreIcon();

  const data = reactive({
    hrScores: null,
    total: null,
    dimensions: null,
  });

  data.hrScores = hrScores.map((score) => ({
    ...score,
    icon: getIcon(score.grade),
  }));
  data.total = data.hrScores[0];
  data.dimensions = data.hrScores.slice(1);
  const { hrScoreFlagIcon, getFlagIconReactive } = useHrScoreFlagIcon(data.dimensions);

  return {
    data,
    hrScoreFlagIcon,
    getFlagIconReactive,
  };
};
