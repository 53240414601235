<template>
  <div
    class="file-list"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <div
      v-if="controller.isLoading"
      class="file-list--overlay"
    >
      <div class="file-list--overlay-content">
        <SpinningLoader
          :text="null"
          class="file-list--overlay-icon"
        />
      </div>
    </div>
    <div
      v-else-if="canDrop && hasDragover"
      ref="dropzone"
      class="file-list--overlay"
    >
      <div class="file-list--overlay-content">
        <div class="file-list--overlay-icon">
          <Icon name="file-upload" />
        </div>
        <div class="file-list--overlay-text">
          {{ $t('apps.publications.upload_modal.drop_prompt') }}
        </div>
      </div>
    </div>
    <div
      v-else-if="controller.files.length === 0"
      class="file-list--overlay"
    >
      <FileSelectButton
        :multiple="true"
        @select="controller.addFiles($event)"
      >
        <template #button>
          <div class="file-list--overlay-content is-clickable">
            <div class="file-list--overlay-icon">
              <Icon name="file-upload" />
            </div>
            <div class="file-list--overlay-text">
              {{ $t('apps.publications.upload_modal.add_prompt') }}
            </div>
            <div class="file-list--overlay-hint">
              {{ $t('apps.publications.upload_modal.hint_procedure') }}
              <br>
              {{ $t('apps.publications.upload_modal.hint_final') }}
            </div>
          </div>
        </template>
      </FileSelectButton>
    </div>
    <ul class="file-list--content">
      <PublicationsUploadModalFileListEntry
        v-for="file in controller.files"
        :key="file.file.name"
        :file="file"
        @remove="controller.removeFile($event)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';
import PublicationsUploadModalFileListEntry from './Entry/Component.vue';

export default {
  name: 'PublicationsUploadModalFileList',
  components: {
    PublicationsUploadModalFileListEntry,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasDragover: false,
      // The dragleave and dragenter events are also triggered on child elements such as
      // text or the icon. This causes flickering between hasDragover true and false states.
      // To avoid flickering, we debounce dragLeave and cancel it if another dragEnter happens
      // in the meantime.
      dragLeaveTimeout: null,
      dragLeaveDebounced: debounce(() => {
        this.hasDragover = false;
      }, 200),
    };
  },
  computed: {
    canDrop() {
      return !this.controller.isLoading && !this.controller.uploadStarted;
    },
    matchName() {
      return this.file.matchingPublication?.id;
    },
  },
  methods: {
    onDragover(e) {
      clearTimeout(this.dragLeaveTimeout);
      if (this.canDrop) {
        // Prevents default reset of drop mode, resulting in appropriate cursor.
        e.preventDefault();
        this.hasDragover = true;
      }
    },
    onDragleave(e) {
      e.preventDefault();
      this.dragLeaveTimeout = this.dragLeaveDebounced();
    },
    onDrop(e) {
      e.preventDefault();
      this.hasDragover = false;
      if (this.canDrop) {
        this.controller.addFiles(e.dataTransfer.files);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>