<template>
  <li class="item">
    <div class="item--row">
      <div class="item--icon">
        <img :src="iconSource">
        <div
          v-if="publication.unread"
          class="item--icon-unread-marker"
          role="status"
          :aria-label="$t('generic.unread')"
        />
      </div>
      <div class="item--infos">
        <PublicationListItemHeader
          :display-name="publication.display_name"
          :is-for-external="publication.for_external"
          @action="showPublication"
        />
        <PublicationListItemMetadata
          :published-at="publication.published_at"
          :regions="publication.regions"
          :file-name="publication.file_name"
          :locale="publication.locale"
        />
      </div>
      <div class="item--tags">
        {{ sortedProductGroupString }}
      </div>
      <PublicationsListItemActions
        :controller="controller"
        :publication="publication"
      />
    </div>
  </li>
</template>

<script>
import { compareBy } from '@/helpers/SortHelpers';
import { ViewPublication, IconForKind } from '@/helpers/PublicationHelpers';
import PublicationsListItemActions from './Actions/Component.vue';
import PublicationListItemMetadata from './Metadata/Component.vue';
import PublicationListItemHeader from './Header/Component.vue';

export default {
  name: 'PublicationsListItem',
  components: {
    PublicationListItemMetadata,
    PublicationsListItemActions,
    PublicationListItemHeader,
  },
  props: {
    controller: {
      type: Object,
      required: true,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iconSource() {
      return IconForKind(this.publication.kind);
    },
    sortedProductGroupString() {
      const productGroups = this.publication.product_groups.slice().sort(compareBy((g) => g.name));
      return productGroups.map((productGroup) => productGroup.name).join(', ');
    },
  },
  methods: {
    showPublication() {
      ViewPublication(this.publication);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen { @import './style.screen.scss'; }
</style>