import axios from 'axios';
import ApiHelpers from '@/api/ApiHelpers';
import { exportFromResponse } from '@/helpers/ExportHelpers';
import Cache from '@/services/Cache';
import {
  EventBus,
  DashboardFavoriteChangeEvent,
  PreferredDashboardCurrencyChangeEvent,
  OwnViewChangeEvent,
  DashboardGroupFavoriteChangeEvent,
} from '@/events';

const IntroCache = new Cache();

const LocalEventBus = new EventBus();
LocalEventBus.on(DashboardFavoriteChangeEvent.EventName, () => {
  IntroCache.clear();
});
LocalEventBus.on(PreferredDashboardCurrencyChangeEvent.EventName, () => {
  IntroCache.clear();
});
LocalEventBus.on(OwnViewChangeEvent.EventName, () => {
  IntroCache.clear();
});

LocalEventBus.on(DashboardGroupFavoriteChangeEvent.EventName, () => {
  IntroCache.clear();
});

const fetchDashboards = (payload) => axios.post('/api/intro/dashboards', payload)
  .then((response) => response.data);

const fetchDashboardsFromCache = (payload) => {
  const cacheKey = JSON.stringify(payload);
  return IntroCache.fetch(cacheKey, () => fetchDashboards(payload));
};

const topCountries = (params) => axios.get('/api/human_rights/top_countries', params && {
  params: {
    country_of_demand_id: params.country,
    year: params.year,
  },
}).then((response) => response.data);

const fetchTopCountriesFromCache = (params) => IntroCache.fetch(`top_countries-${params.country}-year${params.year}`, () => topCountries(params));

const getExcelFromAPI = (params) => axios.get('/api/human_rights/top_countries.xlsx', params && {
  responseType: 'blob',
  params: {
    country_of_demand_id: params.country_of_demand_id,
    dimension_number: params.dimension,
    year: params.year,
  },
}).then((response) => exportFromResponse(response));

const removeDashboardFromFavourite = (url) => axios.delete(url)
  .then((response) => response.data.defavorisedDashboardIds);

class IntroAppApi {
  // eslint-disable-next-line class-methods-use-this
  async dashboards(dashboardType, filters, sorting) {
    const payload = ApiHelpers.removeNullKeys({ dashboardType, filters, sorting });
    const dashboards = await fetchDashboardsFromCache({ ...payload });
    return dashboards;
  }

  async topCountries(params = null) {
    return fetchTopCountriesFromCache(params);
  }

  async removeDashboard(url) {
    return removeDashboardFromFavourite(url);
  }

  async getExcelFileFromDashboard(params) {
    return getExcelFromAPI(params);
  }
}

export default IntroAppApi;
